import React from 'react';
import { InputText } from 'primereact/inputtext';
import { useForm } from '../../hooks/useForm';
import { FormField } from '../FormField/FormField';
import classNames from 'classnames';
import { FisiofitGdprDocumentPayload } from '../../types/signedDocument';
import { SignatureInput } from '../SignatureInput/SignatureInput';
import { BranchesSelect } from '../BranchesSelect/BranchesSelect';
import { Branch } from '../../types/branch';
import { RadioButtonGroup } from '../RadioButtonGroup/RadioButtonGroup';
import { SignedDocumentTemplate } from './SignedDocumentTemplate';
import { Divider } from 'primereact/divider';

export interface FisiofitGdprForm extends Omit<FisiofitGdprDocumentPayload, 'consentImages' | 'consentReceiveInformation'> {
  signature?: string;
  signingLocationBranch?: Branch;
  consentImages?: boolean;
  consentReceiveInformation?: boolean;
}

const INITIAL_GDPR_CONSENT_FORM: FisiofitGdprForm = {
  fullName: '',
  nationalId: '',
};

const getFormValidators = () => ({
  fullName: (form: Partial<FisiofitGdprForm>): string | undefined => (!form.fullName ? 'El nombre es obligatorio' : undefined),
  nationalId: (form: Partial<FisiofitGdprForm>): string | undefined => (!form.nationalId ? 'El DNI es obligatorio' : undefined),
  signature: (form: Partial<FisiofitGdprForm>): string | undefined => (!form.signature ? 'La firma es obligatoria' : undefined),
  signingLocationBranch: (form: Partial<FisiofitGdprForm>): string | undefined =>
    !form.signingLocationBranch?.id ? 'Debes elegir la sucursal en la que se firma' : undefined,
  consentImages: (form: Partial<FisiofitGdprForm>): string | undefined =>
    form.consentImages === undefined ? 'Debes indicar si autorizas el uso de imágenes' : undefined,
  consentReceiveInformation: (form: Partial<FisiofitGdprForm>): string | undefined =>
    form.consentReceiveInformation === undefined ? 'Debes indicar si autorizas recibir información' : undefined,
});

interface Props {
  visible: boolean;
  onHide: () => void;
  onSave: (data: FisiofitGdprDocumentPayload, signature: string, signingLocation: string) => Promise<boolean>;
}

const GdprDocumentText = () => (
  <>
    <section>
      <p>
        Nos comprometemos con usted a trabajar continuamente para garantizarle la privacidad en el tratamiento de sus datos
        personales y ofrecerle en cada momento la información más completa y clara que podamos.
      </p>
      <p>Le animamos a leer detenidamente esta sección antes de facilitarnos sus datos personales:</p>
    </section>

    <section>
      <ul>
        <li>
          <strong>Responsable:</strong> El responsable del tratamiento es PABLO CASTILLO QUINTANA, CALLE JUAN CARLOS I, 73, 29130,
          ALHAURÍN DE LA TORRE (MÁLAGA).
        </li>
        <li>
          <strong>Finalidad:</strong> Elaborar su historial clínico y un seguimiento posterior, prestarle nuestros servicios,
          enviarle información, responder a sus consultas y peticiones mientras dure nuestra relación y/o tengamos su
          consentimiento.
        </li>
        <li>
          <strong>Legitimación:</strong> Relación contractual, interés legítimo del Responsable, y, en su caso, consentimiento del
          interesado.
        </li>
        <li>
          <strong>Plazo de Supresión:</strong> Los datos se conservarán durante el tiempo necesario para cumplir con la finalidad
          para la que se recabaron y para determinar las posibles responsabilidades que se pudieran derivar de dicha finalidad y
          del tratamiento de los datos, conforme a la Ley 58/2003, de 17 de diciembre, General Tributaria.
        </li>
        <li>
          <strong>Destinatarios:</strong> Los datos solicitados no se cederán a terceros salvo obligación legal.
        </li>
        <li>
          <strong>Derechos:</strong> Puede ejercer los derechos de acceso, rectificación, supresión y portabilidad de sus datos, y
          la limitación u oposición al tratamiento utilizando nuestro PORTAL DE EJERCICIO DE DERECHOS, en{' '}
          <a href='https://www.adelopd.com/portalderechos/fisio-fit' target='_blank' rel='noreferrer'>
            https://www.adelopd.com/portalderechos/fisio-fit
          </a>
          . En caso de disconformidad con el tratamiento, también tiene derecho a presentar una reclamación ante la Agencia
          Española de Protección de Datos.
        </li>
      </ul>
    </section>

    <section>
      <p>
        La presente hoja informativa la hemos redactado teniendo en cuenta las exigencias de la actual legislación de protección
        de datos:
      </p>
      <ul>
        <li>
          Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las
          personas físicas (RGPD).
        </li>
        <li>
          Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos de Carácter Personal y garantía de los derechos digitales
          (LOPD).
        </li>
      </ul>
    </section>

    <section>
      <p>
        Si se produce alguna modificación de los datos personales, comuníquenoslo, para mantener sus datos actualizados, asimismo,
        si nos facilita datos de terceras personas se obliga a informar de los presentes tratamientos.
      </p>
    </section>
  </>
);

export const FisiofitGdpr = ({ visible, onHide, onSave }: Props) => {
  const { form, setFormFields, isSaving, setIsSaving, hasChanged, validate, validationErrors } = useForm<FisiofitGdprForm>(
    INITIAL_GDPR_CONSENT_FORM,
    !visible,
    getFormValidators(),
  );

  const handleSubmit = async (): Promise<boolean> => {
    const errors = validate();
    const { signature, signingLocationBranch, consentImages, consentReceiveInformation, ...rest } = form ?? {};
    if (!form || errors || !signature || !signingLocationBranch?.city || !consentImages || !consentReceiveInformation) {
      return false;
    }
    const payload = { ...rest, consentImages, consentReceiveInformation };
    setIsSaving(true);
    const success = await onSave(payload, signature, signingLocationBranch.city);
    setIsSaving(false);
    return success;
  };

  const handleClearSignature = () => {
    setFormFields({ signature: undefined });
  };

  const handleSignatureChange = (signatureImage: string) => {
    setFormFields({ signature: signatureImage });
  };

  return (
    <SignedDocumentTemplate
      title='Consentimiento de protección de datos'
      visible={visible}
      onHide={onHide}
      onSave={handleSubmit}
      isSaving={isSaving}
      hasChanged={hasChanged}
      documentText={<GdprDocumentText />}
    >
      <h3>Consentimientos</h3>
      <FormField elementId='consents' fullWidth>
        <div className='consent-options'>
          <div className='consent-option'>
            <label>
              <b>IMÁGENES:</b> Autorizo a PABLO CASTILLO QUINTANA a utilizar las fotografías y/o videos, en los que aparezca mi
              imagen.
            </label>
            <RadioButtonGroup
              name='consent-images'
              value={form?.consentImages}
              onChange={(value) => setFormFields({ consentImages: value })}
              error={typeof validationErrors?.consentImages === 'string' ? validationErrors.consentImages : undefined}
              options={[
                { id: 'consent-images-yes', value: true, label: 'Sí Autorizo' },
                { id: 'consent-images-no', value: false, label: 'No Autorizo' },
              ]}
            />
            <p className='consent-description'>
              Las imágenes podrán ser utilizadas para promocionar a PABLO CASTILLO QUINTANA: En la página web, redes sociales y/o
              en la documentación promocional de PABLO CASTILLO QUINTANA.
            </p>
          </div>

          <div className='consent-option'>
            <label>
              <b>RECIBIR INFORMACIÓN:</b> Autorizo y consiento recibir información que PABLO CASTILLO QUINTANA considere de mi
              interés por correo electrónico, Apps de mensajería (Whastapp, Telegram...) u otros medios.
            </label>
            <RadioButtonGroup
              name='consent-info'
              value={form?.consentReceiveInformation}
              onChange={(value) => setFormFields({ consentReceiveInformation: value })}
              error={validationErrors?.consentReceiveInformation}
              options={[
                { id: 'consent-info-yes', value: true, label: 'Sí Autorizo' },
                { id: 'consent-info-no', value: false, label: 'No Autorizo' },
              ]}
            />
          </div>
        </div>
      </FormField>

      <Divider />

      <h3>Datos del firmante</h3>
      <FormField labelTitle='Nombre y apellidos' elementId='fullName' required error={validationErrors?.fullName}>
        <InputText
          id='fullName'
          value={form?.fullName}
          onChange={(e) => setFormFields({ fullName: e.target.value })}
          placeholder='Nombre completo'
          className={classNames({ 'p-invalid': validationErrors?.fullName })}
        />
      </FormField>

      <FormField labelTitle='DNI' elementId='nationalId' required error={validationErrors?.nationalId}>
        <InputText
          id='nationalId'
          value={form?.nationalId}
          onChange={(e) => setFormFields({ nationalId: e.target.value })}
          placeholder='DNI'
          className={classNames({ 'p-invalid': validationErrors?.nationalId })}
        />
      </FormField>

      <FormField
        labelTitle='Ubicación de la firma'
        elementId='signing-location-select'
        required
        error={validationErrors?.signingLocationBranch}
      >
        <BranchesSelect
          id='signing-location-select'
          value={form?.signingLocationBranch?.id}
          onChange={(value) => setFormFields({ signingLocationBranch: value })}
          placeholder='Sucursal donde se firma'
          error={validationErrors?.signingLocationBranch}
          isSingle={true}
          onlyAuthUserBranches
        />
      </FormField>

      <SignatureInput
        id='signature'
        isEditing
        error={validationErrors?.signature}
        onSignatureChange={handleSignatureChange}
        onClearSignature={handleClearSignature}
        hasChanged={form.signature !== undefined}
        required={true}
        noteText='Al firmar, declaro que he sido informado y doy mi consentimiento para el tratamiento de datos por parte de la empresa.'
      />
    </SignedDocumentTemplate>
  );
};
