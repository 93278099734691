import React from 'react';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { BlogPosts } from './BlogPosts/BlogPosts';
import { PostEditor } from './PostEditor/PostEditor';
import { NavElement } from '../../types/navigation';
import { Route } from 'react-router-dom';

const blogRoutes: NavElement[] = [
  {
    label: 'Artículos',
    element: <BlogPosts />,
    url: '/',
  },
  {
    label: 'Crear artículo',
    element: <PostEditor />,
    url: '/post/new',
  },
  {
    label: 'Editar artículo',
    element: <PostEditor isEditing />,
    url: '/post/:id',
  },
];

export const Blog = () => {
  return (
    <PageTemplate title='Blog' parentRoute='/blog' routes={blogRoutes} withPageLocaleContext>
      {blogRoutes.map(({ element, url }) => (
        <Route key={url} path={url} element={element} />
      ))}
    </PageTemplate>
  );
};
