import React from 'react';
import { Feature } from '../../config/features';
import { useFeaturesToggle } from '../../hooks/useFeaturesToggle';

interface Props {
  featuresToCheck: Feature[] | string[];
  children: React.ReactNode;
}

export const FeatureToggle = ({ featuresToCheck, children }: Props) => {
  const userHasFeature = useFeaturesToggle(featuresToCheck);

  if (!userHasFeature) return <></>;

  return <>{children}</>;
};
