interface DownloadFileOptions {
  url: string;
  fileName: string;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

export async function downloadFile({ url, fileName, onSuccess, onError }: DownloadFileOptions): Promise<void> {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error al descargar: ${response.statusText}`);
    }

    const blob = await response.blob();
    const objectUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = objectUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(objectUrl);

    onSuccess?.();
  } catch (error) {
    console.error('Error al descargar el archivo:', error);
    onError?.(error as Error);
  }
}
