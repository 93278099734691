import React, { useEffect, useMemo, useState } from 'react';
import { Sidebar } from './layout/Sidebar/Sidebar';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider, useAuthContext } from './context/AuthContext';
import { DragProvider } from './context/DragContext';
import { PullToRefreshProvider } from './context/PullToRefreshContext';
import { WebsiteProvider } from './context/WebsiteContext';
import { LocaleProvider } from './context/LocaleContext';
import { PrimeReactProvider } from 'primereact/api';
import { ERROR_PAGES, LOGIN_PAGES, PAGE_PRIORITY_ORDER, getPages } from './config/pages';
import { PopupProvider } from './context/PopupContext';
import { SWRConfig } from 'swr';
import { getRouterPath } from './utils/routerUtils';
import { NavGroup } from './types/navigation';
import { SplashScreen } from '@capacitor/splash-screen';
import { LoadingScreen } from './components/LoadingScreen/LoadingScreen';
import { OrganizationProvider } from './context/OrganizationContext';
import { hasFeaturesPermission } from './utils/featureUtils';
import { NotificationProvider, useNotificationContext } from './context/NotificationContext';
import { PullToRefreshComponent } from './components/PullToRefresh/PullToRefresh';
import { useSWRConfig } from 'swr';
import { mutate } from 'swr';

const renderRoutesGroup = (routesGroup: NavGroup[]) => {
  return routesGroup.flatMap((navGroup) => {
    return navGroup.elements.map(({ element, url, hasNestedRoutes }) => (
      <Route key={url} path={getRouterPath(url, hasNestedRoutes)} element={element} />
    ));
  });
};

const AppContent = () => {
  const { user, isExpectedLoggedIn, featuresLoading, features, isAuthenticating, userLoading } = useAuthContext() ?? {};
  const { notifications } = useNotificationContext() ?? {};
  const location = useLocation();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { cache } = useSWRConfig();

  const notLoggedIn = !user && !isExpectedLoggedIn;
  const isLoading = isAuthenticating || userLoading || featuresLoading;

  useEffect(() => {
    if (notLoggedIn || user) SplashScreen.hide();
  }, [notLoggedIn, user]);

  useEffect(() => {
    const loadingScreen = document.querySelector('.loading-screen') as HTMLElement;
    if (!loadingScreen) return;

    if (isLoading) {
      loadingScreen.classList.add('loading-screen-show');
    } else {
      setTimeout(() => {
        loadingScreen.classList.remove('loading-screen-show');
      }, 300);
    }
  }, [isLoading, location.pathname]);

  const allPages = useMemo(
    () =>
      featuresLoading || !features
        ? getPages(notifications)
        : getPages(
            notifications,
            features.filter((f) => f.includes('rt-')),
          ),
    [features, featuresLoading, notifications],
  );

  const filterFeaturePages = useMemo(() => {
    return allPages.map((navGroup) => {
      const filteredElements = navGroup.elements.filter((element) => {
        if (!element.featureCodes) return true;
        if (hasFeaturesPermission(element.featureCodes, { features, featuresLoading })) {
          return true;
        }

        return false;
      });

      return {
        ...navGroup,
        elements: filteredElements,
      };
    });
  }, [features, featuresLoading, allPages]);

  const getFirstAvailablePage = () => {
    const allElements = filterFeaturePages.flatMap((page) => page.elements);
    return PAGE_PRIORITY_ORDER.find((route: string) => allElements.some((element) => element.url === route)) ?? '/analytics';
  };

  const hasPermissionForCurrentRoute = useMemo(() => {
    if (isLoading) return true;

    const currentPath = location.pathname;

    const isPathMatch = (elementUrl: string, hasNestedRoutes?: boolean) => {
      const routePath = getRouterPath(elementUrl, hasNestedRoutes);
      const cleanPath = routePath.replace(/\/\*$/, '');

      if (hasNestedRoutes) {
        return currentPath === cleanPath || currentPath.startsWith(cleanPath + '/');
      }

      const routeRegex = new RegExp(`^${routePath.replace(/:\w+/g, '[\\w.-]+')}$`);
      return routeRegex.test(currentPath);
    };

    const isLoginPage = LOGIN_PAGES.some((group) =>
      group.elements.some((element) => isPathMatch(element.url, element.hasNestedRoutes)),
    );

    const isErrorPage = ERROR_PAGES.some((group) =>
      group.elements.some((element) => isPathMatch(element.url, element.hasNestedRoutes)),
    );

    if (isLoginPage || isErrorPage) return true;

    if (featuresLoading || !features) return true;

    const allElements = filterFeaturePages.flatMap((page) => page.elements);
    const currentPageElement = allElements.find((element) => isPathMatch(element.url, element.hasNestedRoutes));

    if (!currentPageElement) return false;
    if (!currentPageElement.featureCodes) return true;

    return hasFeaturesPermission(currentPageElement.featureCodes, { features, featuresLoading });
  }, [location.pathname, filterFeaturePages, features, featuresLoading, isLoading]);

  const handleRefresh = async (): Promise<void> => {
    if (isRefreshing) return;

    setIsRefreshing(true);

    const keys = cache.keys();
    const promises = Array.from(keys).map((key) => mutate(key));

    await Promise.all(promises);

    setIsRefreshing(false);
  };

  const renderLoginPagesWrapper = () => {
    if (isLoading) return <Outlet />;
    if (user) return <Navigate to={getFirstAvailablePage()} replace />;
    return <Outlet />;
  };

  const renderPagesWrapper = () => {
    if (isLoading) return <Outlet />;
    if (notLoggedIn) return <Navigate to={'/'} replace />;
    if (!hasPermissionForCurrentRoute) return <Navigate to={'/not-found'} replace />;
    return (
      <>
        <Sidebar />
        <main className='content'>
          <Outlet />
        </main>
      </>
    );
  };

  const shouldEnablePullToRefresh = !!user && !notLoggedIn && !isLoading;

  const renderAppContent = () => (
    <>
      <LoadingScreen show={false} />
      {isRefreshing && (
        <div className='refresh-overlay'>
          <div className='refresh-spinner'>
            <i className='pi pi-spin pi-spinner'></i>
          </div>
        </div>
      )}
      <Routes>
        <Route element={renderLoginPagesWrapper()}>{renderRoutesGroup(LOGIN_PAGES)}</Route>
        <Route
          element={
            shouldEnablePullToRefresh ? (
              <PullToRefreshProvider>
                <PullToRefreshComponent
                  onRefresh={handleRefresh}
                  backgroundColor='var(--background)'
                  containerClassName={isRefreshing ? 'refreshing' : ''}
                  pullDownThreshold={150}
                >
                  {renderPagesWrapper()}
                </PullToRefreshComponent>
              </PullToRefreshProvider>
            ) : (
              renderPagesWrapper()
            )
          }
        >
          {renderRoutesGroup(allPages)}
        </Route>
        <Route>{renderRoutesGroup(ERROR_PAGES)}</Route>
        <Route path={'/*'} element={<Navigate to={'not-found'} replace />} />
      </Routes>
    </>
  );

  return renderAppContent();
};

const App = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

export default () => (
  <SWRConfig
    value={{
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }}
  >
    <PrimeReactProvider>
      <PopupProvider>
        <AuthProvider>
          <OrganizationProvider>
            <WebsiteProvider>
              <LocaleProvider>
                <DragProvider>
                  <NotificationProvider>
                    <App />
                  </NotificationProvider>
                </DragProvider>
              </LocaleProvider>
            </WebsiteProvider>
          </OrganizationProvider>
        </AuthProvider>
      </PopupProvider>
    </PrimeReactProvider>
  </SWRConfig>
);
