const css = `.post-editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.post-editor-header .draft-tag.p-tag-warning {
  background-color: var(--orange-50);
  border: 1px solid var(--orange-700);
  padding: 4px;
}
.post-editor-header .draft-tag.p-tag-warning .p-tag-value {
  font-size: 14px;
  line-height: 1;
}

.post-editor .post-image {
  margin: 16px auto;
  height: 300px;
  display: flex;
}
.post-editor .post-image img {
  max-height: 300px;
  width: 100%;
}
.post-editor .post-editor-buttons {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 8px;
}
.post-editor .post-editor-buttons button[type=submit] {
  margin-left: 8px;
}
.post-editor .post-editor-buttons .delete-post-button {
  margin-right: auto;
}

.editor-error-fallback {
  padding: 20px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #f9f9f9;
  text-align: center;
  color: #666;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL3BhZ2VzL0Jsb2cvUG9zdEVkaXRvciIsInNvdXJjZXMiOlsiUG9zdEVkaXRvci5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBOztBQUNBO0VBQ0U7RUFDQTs7O0FBTUo7RUFDRTtFQUNBO0VBQ0E7O0FBQ0E7RUFDRTtFQUNBOztBQUdKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTs7QUFHRjtFQUNFOzs7QUFLTjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5wb3N0LWVkaXRvci1oZWFkZXIge1xuICBkaXNwbGF5OiBmbGV4O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG5cbiAgLmRyYWZ0LXRhZy5wLXRhZy13YXJuaW5nIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1vcmFuZ2UtNTApO1xuICAgIGJvcmRlcjogMXB4IHNvbGlkIHZhcigtLW9yYW5nZS03MDApO1xuICAgIHBhZGRpbmc6IDRweDtcbiAgICAucC10YWctdmFsdWUge1xuICAgICAgZm9udC1zaXplOiAxNHB4O1xuICAgICAgbGluZS1oZWlnaHQ6IDE7XG4gICAgfVxuICB9XG59XG5cbi5wb3N0LWVkaXRvciB7XG4gIC5wb3N0LWltYWdlIHtcbiAgICBtYXJnaW46IDE2cHggYXV0bztcbiAgICBoZWlnaHQ6IDMwMHB4O1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgaW1nIHtcbiAgICAgIG1heC1oZWlnaHQ6IDMwMHB4O1xuICAgICAgd2lkdGg6IDEwMCU7XG4gICAgfVxuICB9XG4gIC5wb3N0LWVkaXRvci1idXR0b25zIHtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogZW5kO1xuICAgIG1hcmdpbi10b3A6IDhweDtcblxuICAgIGJ1dHRvblt0eXBlPSdzdWJtaXQnXSB7XG4gICAgICBtYXJnaW4tbGVmdDogOHB4O1xuICAgIH1cblxuICAgIC5kZWxldGUtcG9zdC1idXR0b24ge1xuICAgICAgbWFyZ2luLXJpZ2h0OiBhdXRvO1xuICAgIH1cbiAgfVxufVxuXG4uZWRpdG9yLWVycm9yLWZhbGxiYWNrIHtcbiAgcGFkZGluZzogMjBweDtcbiAgYm9yZGVyOiAxcHggc29saWQgI2YwZjBmMDtcbiAgYm9yZGVyLXJhZGl1czogNHB4O1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZjlmOWY5O1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gIGNvbG9yOiAjNjY2O1xuICBtaW4taGVpZ2h0OiAyMDBweDtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIGdhcDogMTVweDtcbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
