const css = `.preview-container {
  margin: 16px 0;
  width: 100%;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--surface-ground);
}
.preview-container.placeholder {
  border: 2px dashed var(--surface-border);
}
.preview-container.placeholder .placeholder-text {
  color: var(--text-color-secondary);
  font-style: italic;
}
.preview-container .preview-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: black;
}
.preview-container .preview-video:not(video) {
  border: none;
  background-color: transparent;
}
.preview-container .preview-image {
  max-width: 100%;
  max-height: 100%;
}
.preview-container .preview-image img {
  max-width: 100%;
  object-fit: contain;
}

.description-text {
  white-space: pre-line;
  margin: 8px 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvRXhlcmNpc2VQYW5lbCIsInNvdXJjZXMiOlsiRXhlcmNpc2VQYW5lbC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBRUE7RUFDRTtFQUNBOztBQUlKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUlKO0VBQ0U7RUFDQTs7QUFDQTtFQUNFO0VBQ0E7OztBQUtOO0VBQ0U7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5wcmV2aWV3LWNvbnRhaW5lciB7XG4gIG1hcmdpbjogMTZweCAwO1xuICB3aWR0aDogMTAwJTtcbiAgaGVpZ2h0OiA0MDBweDtcbiAgYm9yZGVyLXJhZGl1czogOHB4O1xuICBvdmVyZmxvdzogaGlkZGVuO1xuICBkaXNwbGF5OiBmbGV4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tc3VyZmFjZS1ncm91bmQpO1xuXG4gICYucGxhY2Vob2xkZXIge1xuICAgIGJvcmRlcjogMnB4IGRhc2hlZCB2YXIoLS1zdXJmYWNlLWJvcmRlcik7XG5cbiAgICAucGxhY2Vob2xkZXItdGV4dCB7XG4gICAgICBjb2xvcjogdmFyKC0tdGV4dC1jb2xvci1zZWNvbmRhcnkpO1xuICAgICAgZm9udC1zdHlsZTogaXRhbGljO1xuICAgIH1cbiAgfVxuXG4gIC5wcmV2aWV3LXZpZGVvIHtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBoZWlnaHQ6IDEwMCU7XG4gICAgb2JqZWN0LWZpdDogY29udGFpbjtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiBibGFjaztcblxuICAgICY6bm90KHZpZGVvKSB7XG4gICAgICBib3JkZXI6IG5vbmU7XG4gICAgICBiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudDtcbiAgICB9XG4gIH1cblxuICAucHJldmlldy1pbWFnZSB7XG4gICAgbWF4LXdpZHRoOiAxMDAlO1xuICAgIG1heC1oZWlnaHQ6IDEwMCU7XG4gICAgaW1nIHtcbiAgICAgIG1heC13aWR0aDogMTAwJTtcbiAgICAgIG9iamVjdC1maXQ6IGNvbnRhaW47XG4gICAgfVxuICB9XG59XG5cbi5kZXNjcmlwdGlvbi10ZXh0IHtcbiAgd2hpdGUtc3BhY2U6IHByZS1saW5lO1xuICBtYXJnaW46IDhweCAwO1xufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
