import { Button } from 'primereact/button';
import React, { FormEvent } from 'react';
import { NoAuthPageTemplate } from '../../layout/NoAuthPageTemplate/NoAuthPageTemplate';
import { useForm } from '../../hooks/useForm';
import { LinkButton } from '../../components/LinkButton/LinkButton';
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';
import { useClient } from '../../hooks/useClient';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { PASSWORD_RESET_VALIDATORS } from './validators';
import { FormElementError } from '../../components/FormElementError/FormElementError';

const INITIAL_PASSWORD_RESET_FORM: PasswordResetForm = {
  confirmPassword: '',
  password: '',
};

interface VerifyResetTokenResponse {
  email: string;
}

export interface PasswordResetForm {
  password: string;
  confirmPassword: string;
}

export const PasswordReset = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const { post, get } = useClient();
  const { form, setFormField, isSaving, setIsSaving, hasChanged, validate, validationErrors } =
    useForm<PasswordResetForm>(INITIAL_PASSWORD_RESET_FORM, true, PASSWORD_RESET_VALIDATORS);
  const { confirmPassword, password } = form ?? {};

  const fetcher = async (url: string) => {
    const response = await get<VerifyResetTokenResponse>(url, {
      errorMessages: {
        summary: 'Error de enlace de validación',
        defaultDetail: 'No se ha podido validar el enlace de recuperación.',
        [404]: 'El enlace de recuperación no es válido.',
        [403]: 'El enlace de recuperación ha caducado.',
      },
      handlers: {
        defaultError: () => navigate('/not-found'),
        [404]: () => navigate('/invalid-token'),
        [403]: () => navigate('/expired-token'),
      },
    });

    return response?.email;
  };

  useSWR(token ? `/verify-reset-token/${token}` : null, fetcher);

  const recoverPassword = async () => {
    await post(`/password-reset`, {
      body: { passwordResetToken: token, ...form },
      errorMessages: {
        summary: 'Error al reiniciar contraseña',
        defaultDetail: 'No se ha podido reiniciar la contraseña.',
        [404]: 'El enlace de recuperación no es válido.',
        [403]: 'El enlace de recuperación ha caducado.',
      },
      successMessage: {
        summary: 'Reiniciar contraseña',
        detail: 'Se ha reiniciado la contraseña. Puedes iniciar sesión con ella.',
      },
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const newErrors = validate();
    if (!form || newErrors) return;
    setIsSaving(true);
    await recoverPassword();
    setIsSaving(false);
    navigate('/');
  };

  return (
    <NoAuthPageTemplate>
      <h2>Reiniciar contraseña</h2>
      <p>Una vez que reinicies tu contraseña, tu contraseña antigua se perderá para siempre.</p>
      <form
        className={classNames({
          'form-loading': isSaving,
        })}
        onSubmit={handleSubmit}
      >
        <div className='form-field'>
          <label htmlFor='password'>Nueva contraseña</label>
          <Password
            id='password'
            feedback={false}
            value={password}
            placeholder='Introduce la nueva contraseña'
            onChange={(e) => setFormField({ password: e.target.value })}
            toggleMask
            className={classNames({ 'p-invalid': validationErrors?.password })}
          />
          <small
            className={classNames('p-info', {
              'field-error static': validationErrors?.password,
            })}
          >
            La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número
            y un caracter especial.
          </small>
        </div>
        <div className='form-field'>
          <label htmlFor='confirmPassword'>Confirmar contraseña</label>
          <Password
            id='confirmPassword'
            feedback={false}
            value={confirmPassword}
            placeholder='Vuelve a introducir la nueva contraseña'
            onChange={(e) => setFormField({ confirmPassword: e.target.value })}
            toggleMask
            className={classNames({ 'p-invalid': validationErrors?.confirmPassword })}
          />
          <FormElementError position='static'>{validationErrors?.confirmPassword}</FormElementError>
        </div>
        <Button
          type='submit'
          label='Cambiar contraseña'
          className='full-width large'
          disabled={!hasChanged}
          loading={isSaving}
        />
        <LinkButton to='/' icon='pi pi-arrow-left'>
          Volver a inicio de sesión
        </LinkButton>
      </form>
    </NoAuthPageTemplate>
  );
};
