import React, { PropsWithChildren, createContext, useContext } from 'react';
import { Organization } from '../types/organization';
import useSWR from 'swr';
import { useClient } from '../hooks/useClient';
import { useAuthContext } from './AuthContext';

interface OrganizationContextValue {
  setOrganization: (organization: Organization) => void;
  organization: Organization | undefined;
  organizationLoading: boolean;
}

const OrganizationContext = createContext<OrganizationContextValue | null>(
  null
);

export const OrganizationProvider = ({ children }: PropsWithChildren) => {
  const { get } = useClient();
  const { user, userLoading } = useAuthContext() ?? {};

  const {
    data: organization,
    isLoading: organizationLoading,
    error: organizationError,
    mutate,
  } = useSWR(
    user ? `/organizations/${user.organizationId}` : null,
    get<Organization>
  );
  const setOrganization = (newOrganization: Organization) => {
    mutate(newOrganization, false);
  };

  return (
    <OrganizationContext.Provider
      value={{
        setOrganization,
        organization,
        organizationLoading: organizationLoading || !!userLoading,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganizationContext = () => {
  return useContext(OrganizationContext);
};
