import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { BranchesSelect } from '../BranchesSelect/BranchesSelect';
import { FormField } from '../FormField/FormField';
import { Checkbox } from 'primereact/checkbox';
import './CopyHoursDialog.scss';

interface CopyOptions {
  copyReservationHours: boolean;
  copyClosedDays: boolean;
  copyBreakHours: boolean;
}

interface Props {
  visible: boolean;
  onHide: () => void;
  onCopy: (sourceBranchId: string, options: CopyOptions) => void;
  currentBranchId: string;
}

export const CopyHoursDialog = ({ visible, onHide, onCopy, currentBranchId }: Props) => {
  const [sourceBranchId, setSourceBranchId] = useState<string>();
  const [copyOptions, setCopyOptions] = useState<CopyOptions>({
    copyReservationHours: true,
    copyClosedDays: true,
    copyBreakHours: true,
  });

  const handleConfirm = () => {
    if (!sourceBranchId) return;
    onCopy(sourceBranchId, copyOptions);
    onHide();
  };

  const handleHide = () => {
    setSourceBranchId(undefined);
    setCopyOptions({
      copyReservationHours: true,
      copyClosedDays: true,
      copyBreakHours: true,
    });
    onHide();
  };

  const renderFooter = () => {
    return (
      <div className='dialog-footer'>
        <Button label='Cancelar' icon={PrimeIcons.TIMES} onClick={handleHide} text />
        <Button label='Copiar' icon={PrimeIcons.CHECK} onClick={handleConfirm} disabled={!sourceBranchId} />
      </div>
    );
  };

  return (
    <Dialog
      header='Copiar horarios'
      visible={visible}
      onHide={handleHide}
      footer={renderFooter()}
      className='copy-hours-dialog big-modal'
      dismissableMask
      draggable={false}
      resizable={false}
    >
      <div className='copy-hours-content'>
        <p className='dialog-message'>Selecciona una sucursal para copiar sus horarios a la surcursal actual.</p>
        <FormField elementId='source-branch' labelTitle='Sucursal origen' required fullWidth>
          <BranchesSelect
            id='source-branch'
            isSingle
            value={sourceBranchId}
            onChange={(branch) => branch && setSourceBranchId(branch.id)}
            placeholder='Sucursal de la que se copiarán los horarios'
            excludeBranchIds={[currentBranchId]}
          />
        </FormField>

        <div className='copy-options'>
          <p className='copy-options-label'>¿Qué quieres copiar?</p>
          <div className='options-container'>
            <div className='p-field-checkbox'>
              <Checkbox
                inputId='copy-reservation-hours'
                checked={copyOptions.copyReservationHours}
                onChange={(e) => setCopyOptions({ ...copyOptions, copyReservationHours: e.checked ?? false })}
              />
              <label htmlFor='copy-reservation-hours'>Horarios de reserva</label>
            </div>
            <div className='p-field-checkbox'>
              <Checkbox
                inputId='copy-break-hours'
                checked={copyOptions.copyBreakHours}
                onChange={(e) => setCopyOptions({ ...copyOptions, copyBreakHours: e.checked ?? false })}
              />
              <label htmlFor='copy-break-hours'>Horarios de descanso</label>
            </div>
            <div className='p-field-checkbox'>
              <Checkbox
                inputId='copy-closed-days'
                checked={copyOptions.copyClosedDays}
                onChange={(e) => setCopyOptions({ ...copyOptions, copyClosedDays: e.checked ?? false })}
              />
              <label htmlFor='copy-closed-days'>Días de cierre</label>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
