import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { useLocaleContext } from '../../context/LocaleContext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ReservationTypeWithColor } from '../../hooks/AppData/useReservationTypes';
import './HolidaysDialog.scss';
import { ReservationHoursForm } from '../../pages/Settings/validators';
import { Capacitor } from '@capacitor/core';

interface Props {
  visible: boolean;
  onHide: () => void;
  value: Date[];
  onChange: (dates: Date[]) => void;
  title?: string;
  otherReservationTypes?: ReservationTypeWithColor[];
  reservationHours?: ReservationHoursForm;
}

export const HolidaysDialog = ({
  visible,
  onHide,
  value,
  onChange,
  title = 'Seleccionar días de cierre',
  otherReservationTypes = [],
  reservationHours,
}: Props) => {
  const { locale } = useLocaleContext();
  const currentYear = new Date().getFullYear();
  const months = Array.from({ length: 12 }, (_, i) => i);
  const [viewDates, setViewDates] = useState<Record<number, Date>>(
    months.reduce(
      (acc, month) => {
        acc[month] = new Date(currentYear, month, 1);
        return acc;
      },
      {} as Record<number, Date>,
    ),
  );

  const [selectedReservationType, setSelectedReservationType] = useState<ReservationTypeWithColor>();

  const handleCopyHolidays = () => {
    if (selectedReservationType && reservationHours?.[selectedReservationType.id]?.closedDays) {
      onChange(reservationHours[selectedReservationType.id].closedDays);
      setSelectedReservationType(undefined);
    }
  };

  return (
    <Dialog
      draggable={false}
      resizable={false}
      header={title}
      visible={visible}
      onHide={onHide}
      modal
      className='holidays-dialog big-modal'
      dismissableMask
      closeOnEscape
    >
      {otherReservationTypes.length > 0 && (
        <div className='copy-holidays-section'>
          <Dropdown
            value={selectedReservationType}
            onChange={(e) => setSelectedReservationType(e.value)}
            options={otherReservationTypes}
            optionLabel='name'
            placeholder='Copiar días de cierre de...'
            className='copy-holidays-dropdown'
          />
          <Button
            type='button'
            icon='pi pi-copy'
            onClick={handleCopyHolidays}
            disabled={!selectedReservationType}
            tooltip='Copiar días de cierre'
          />
        </div>
      )}
      <div className='holidays-calendar-grid'>
        {months.map((month) => (
          <div key={month} className='month-calendar'>
            <Calendar
              inline
              value={value}
              onChange={(e) => {
                const newHolidays = Array.isArray(e.value) ? e.value : [];
                onChange(newHolidays);
              }}
              selectionMode='multiple'
              dateFormat='dd/mm/yy'
              viewDate={viewDates[month]}
              onViewDateChange={(e) => {
                const newDate = new Date(e.value);
                newDate.setMonth(month);
                setViewDates((prev) => ({
                  ...prev,
                  [month]: newDate,
                }));
              }}
              maxDate={new Date(currentYear, 11, 31)}
              locale={locale}
              showOtherMonths={false}
              className='holiday-calendar'
              touchUI={Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches}
            />
          </div>
        ))}
      </div>
    </Dialog>
  );
};
