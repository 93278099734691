const css = `.p-datatable .p-column-title {
  font-weight: bold;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 16px;
}

.font-bold {
  font-weight: 600;
}

thead {
  border-radius: 8px;
}
thead tr th {
  padding: 16px;
  color: var(--secondary);
  background-color: var(--disruptive);
}
thead tr th:first-child {
  border-top-left-radius: 8px;
}
thead tr th:last-child {
  border-top-right-radius: 8px;
}

.name-template {
  display: flex;
  gap: 16px;
  align-items: center;
}

.members-state-tag {
  padding: 8px;
  letter-spacing: 0.04cap;
}

.p-menuitem-link {
  font-size: 16px !important;
  padding: 8px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL3BhZ2VzL01lbWJlcnMvTWVtYmVyc0xpc3QiLCJzb3VyY2VzIjpbIk1lbWJlcnNMaXN0LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSTs7O0FBR0Y7RUFDRTs7O0FBR0Y7RUFDRTs7O0FBR0Y7RUFDRTs7QUFFSTtFQUNJO0VBQ0E7RUFDQTs7QUFFQTtFQUNJOztBQUVKO0VBQ0k7OztBQU1kO0VBQ0U7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5wLWRhdGF0YWJsZSAucC1jb2x1bW4tdGl0bGUge1xuICAgIGZvbnQtd2VpZ2h0OiBib2xkO1xuICB9XG4gIFxuICAucC1kYXRhdGFibGUgLnAtZGF0YXRhYmxlLXRib2R5ID4gdHIgPiB0ZCB7XG4gICAgcGFkZGluZzogMTZweDtcbiAgfVxuICBcbiAgLmZvbnQtYm9sZCB7XG4gICAgZm9udC13ZWlnaHQ6IDYwMDtcbiAgfVxuICBcbiAgdGhlYWQge1xuICAgIGJvcmRlci1yYWRpdXM6IDhweDtcbiAgICB0ciB7XG4gICAgICAgIHRoIHtcbiAgICAgICAgICAgIHBhZGRpbmc6IDE2cHg7XG4gICAgICAgICAgICBjb2xvcjogdmFyKC0tc2Vjb25kYXJ5KTtcbiAgICAgICAgICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWRpc3J1cHRpdmUpO1xuXG4gICAgICAgICAgICAmOmZpcnN0LWNoaWxkIHtcbiAgICAgICAgICAgICAgICBib3JkZXItdG9wLWxlZnQtcmFkaXVzOiA4cHg7XG4gICAgICAgICAgICB9XG4gICAgICAgICAgICAmOmxhc3QtY2hpbGQge1xuICAgICAgICAgICAgICAgIGJvcmRlci10b3AtcmlnaHQtcmFkaXVzOiA4cHg7XG4gICAgICAgICAgICB9XG4gICAgICAgIH1cbiAgICB9XG4gIH1cblxuICAubmFtZS10ZW1wbGF0ZSB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBnYXA6IDE2cHg7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgfVxuXG4gIC5tZW1iZXJzLXN0YXRlLXRhZyB7XG4gICAgcGFkZGluZzogOHB4O1xuICAgIGxldHRlci1zcGFjaW5nOiAwLjA0Y2FwO1xuICB9XG5cbiAgLnAtbWVudWl0ZW0tbGluayB7XG4gICAgZm9udC1zaXplOiAxNnB4ICFpbXBvcnRhbnQ7XG4gICAgcGFkZGluZzogOHB4O1xuICB9Il19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
