import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { LOCALES } from '../config/locales';

interface PageLocaleContextValue {
  pageLocale: LOCALES | undefined;
  setPageLocale: (locale: LOCALES) => void;
}

const PageLocaleContext = createContext<PageLocaleContextValue | null>(null);

export const PageLocaleProvider = ({ children }: PropsWithChildren) => {
  const [pageLocale, setPageLocale] = useState<LOCALES | undefined>();

  return (
    <PageLocaleContext.Provider value={{ pageLocale, setPageLocale }}>
      {children}
    </PageLocaleContext.Provider>
  );
};

export const usePageLocaleContext = () => {
  return useContext(PageLocaleContext);
};
