import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

interface DraggingContextValue {
  isDragging: boolean;
}
const initialValue: DraggingContextValue = { isDragging: false };

const DragContext = createContext<DraggingContextValue>(initialValue);

export const DragProvider = ({ children }: PropsWithChildren) => {
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    let dragCounter = 0;
    window.addEventListener('dragenter', () => {
      if (dragCounter === 0) {
        setIsDragging(true);
      }
      dragCounter++;
    });

    window.addEventListener('dragleave', () => {
      dragCounter--;
      if (dragCounter === 0) {
        setIsDragging(false);
      }
    });

    window.addEventListener('drop', () => {
      dragCounter = 0;
      setIsDragging(false);
    });

    window.addEventListener('dragover', (event) => {
      event.preventDefault();
    });
  }, []);

  return <DragContext.Provider value={{ isDragging }}>{children}</DragContext.Provider>;
};

export const useDragContext = () => {
  return useContext(DragContext);
};
