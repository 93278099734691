import React from 'react';
import './FeaturePicker.scss';
import { Feature } from '../../config/features';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { FeaturesResponse } from './types';
import useSWR from 'swr';
import { useClient } from '../../hooks/useClient';
import { classNames } from 'primereact/utils';
import { Checkbox } from 'primereact/checkbox';
import { Skeleton } from 'primereact/skeleton';
import { PrimeIcons } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { InfoIcon } from '../InfoIcon/InfoIcon';

interface Props {
  featureSet?: Set<Feature>;
  onChange?: (features: Set<Feature>) => void;
  disabled?: boolean;
  elementId?: string;
  className?: string;
}

const FeaturePickerSkeleton = () => {
  return (
    <div className='feature-picker'>
      {Array(6)
        .fill(null)
        .map((_, index) => (
          <div key={index} className='feature-node'>
            <div className='node-header'>
              <div className='node-title'>
                <Skeleton width='200px' height='24px' className='skeleton-title' />
              </div>
            </div>
            <div className='node-children'>
              <div className='feature-item'>
                <Skeleton width='180px' height='24px' className='skeleton-label' />
                <Skeleton shape='rectangle' width='1.5rem' height='1.5rem' className='skeleton-checkbox' />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export const FeaturePicker = ({ featureSet = new Set(), onChange, disabled, elementId, className }: Props) => {
  const { organization } = useOrganizationContext() ?? {};
  const { get } = useClient();

  const { data: featuresData, isLoading } = useSWR(
    organization ? `/organizations/${organization.id}/features` : null,
    get<FeaturesResponse>,
  );

  const handleFeatureToggle = (feature: Feature) => {
    if (disabled || !onChange || !featuresData) return;

    const newFeatures = new Set(featureSet);
    if (newFeatures.has(feature)) {
      if (featuresData.FEATURE_PRECEDENCE) {
        const found = Object.keys(featuresData.FEATURE_PRECEDENCE).find(
          (precedence) => featuresData.FEATURE_PRECEDENCE[precedence].includes(feature) && newFeatures.has(precedence),
        );
        if (!found) {
          newFeatures.delete(feature);
        }
      }
    } else {
      newFeatures.add(feature);
      if (featuresData.FEATURE_PRECEDENCE[feature]) {
        featuresData.FEATURE_PRECEDENCE[feature].forEach((f) => {
          newFeatures.add(f);
        });
      }
    }

    onChange(newFeatures);
  };

  if (isLoading) {
    return <FeaturePickerSkeleton />;
  }

  if (!featuresData?.FEATURE_GROUPS) return null;

  return (
    <div id={elementId} className={classNames('feature-picker', className, { disabled })}>
      <Tooltip target='.feature-help' />
      {featuresData.FEATURE_GROUPS.map((group) => (
        <div key={group.title} className='feature-node'>
          <div className='node-header'>
            <div className='node-title'>
              <span>{group.title}</span>
            </div>
          </div>
          {group.features && (
            <div className='node-children'>
              {group.features.map((feature) => {
                const isDisabled =
                  disabled ||
                  (featuresData.FEATURE_PRECEDENCE &&
                    !!Object.keys(featuresData.FEATURE_PRECEDENCE).find(
                      (p) => featuresData.FEATURE_PRECEDENCE[p].includes(feature) && featureSet.has(p),
                    ));

                return (
                  <div key={feature} className='feature-item'>
                    <div className='feature-info'>
                      <span className='feature-label'>{featuresData.FEATURE_LABELS[feature]}</span>
                      {featuresData.FEATURE_DESCRIPTIONS[feature] && (
                        <InfoIcon content={featuresData.FEATURE_DESCRIPTIONS[feature]} />
                      )}
                    </div>
                    {disabled ? (
                      <i className={classNames('feature-status', PrimeIcons[featureSet.has(feature) ? 'CHECK' : 'TIMES'])} />
                    ) : (
                      <Checkbox
                        checked={featureSet.has(feature)}
                        onChange={() => handleFeatureToggle(feature)}
                        disabled={isDisabled}
                        tooltip={isDisabled ? 'Este permiso depende de otros permisos' : undefined}
                        tooltipOptions={{ position: 'left' }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
