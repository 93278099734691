import React, { FormEvent } from 'react';
import { useAuthContext } from '../../context/AuthContext';
import { useForm } from '../../hooks/useForm';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { NoAuthPageTemplate } from '../../layout/NoAuthPageTemplate/NoAuthPageTemplate';
import { LinkButton } from '../../components/LinkButton/LinkButton';
import { LOGIN_VALIDATORS } from './validators';
import { classNames } from 'primereact/utils';
import { FormElementError } from '../../components/FormElementError/FormElementError';
import { LoadingScreen } from '../../components/LoadingScreen/LoadingScreen';
import { Capacitor } from '@capacitor/core';

const isNativePlatform = Capacitor.isNativePlatform();

const EMPTY_LOGIN_FORM: LoginForm = {
  password: '',
  email: '',
};

export interface LoginForm {
  email: string;
  password: string;
}

export const Login = () => {
  const { login, isExpectedLoggedIn } = useAuthContext() ?? {};
  const {
    form,
    setFormField,
    hasChanged,
    isSaving: isSigningIn,
    setIsSaving: setIsSigningIn,
    validate,
    validationErrors,
  } = useForm<LoginForm>(EMPTY_LOGIN_FORM, true, LOGIN_VALIDATORS);
  const { email, password } = form;
  const navigate = useNavigate();

  const handleSignIn = async (e: FormEvent) => {
    e.preventDefault();
    const newErrors = validate();
    if (!email || !password || newErrors) return;
    setIsSigningIn(true);
    await login?.(email, password);
    navigate('/analytics');
  };

  return (
    <NoAuthPageTemplate>
      <h2>Te damos la bienvenida</h2>
      <p>Inicia sesión para continuar</p>

      <form onSubmit={handleSignIn}>
        <div className='form-field'>
          <label htmlFor='email'>Email</label>
          <InputText
            placeholder='Dirección de correo electrónico'
            id='email'
            name='email'
            value={email}
            autoComplete='username'
            onChange={(e) => setFormField({ email: e.target.value })}
            className={classNames({ 'p-invalid': validationErrors?.email })}
          />
          <FormElementError>{validationErrors?.email}</FormElementError>
        </div>
        <div className='form-field'>
          <label htmlFor='password'>Contraseña</label>
          <Password
            toggleMask
            feedback={false}
            placeholder='Contraseña'
            id='password'
            name='password'
            value={password}
            type='password'
            inputMode='text'
            autoComplete='current-password'
            onChange={(e) => setFormField({ password: e.target.value })}
            className={classNames({ 'p-invalid': validationErrors?.password })}
          />
          <FormElementError>{validationErrors?.password}</FormElementError>
        </div>
        <LinkButton to='/password-recovery' type='button' link>
          Recordar contraseña
        </LinkButton>
        <Button className='large' type='submit' loading={isSigningIn}>
          Iniciar sesión
        </Button>
      </form>
    </NoAuthPageTemplate>
  );
};
