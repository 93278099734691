import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Paginator } from 'primereact/paginator';
import './Notifications.scss';
import { Link } from 'react-router-dom';
import { MarkAsReadRequest, NOTIFICATION_TYPES } from '../../types/notificationTypes';
import classNames from 'classnames';
import { format } from 'date-fns';
import { DeleteNotificationButton } from './DeleteNotificationButton';
import { Card } from 'primereact/card';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { Skeleton } from 'primereact/skeleton';
import { useNotificationContext } from '../../context/NotificationContext';
import { useClient } from '../../hooks/useClient';

const ITEMS_PER_PAGE = 10;

const getTypeLabel = (type: NOTIFICATION_TYPES) => {
  const types = {
    [NOTIFICATION_TYPES.RESERVATION_CANCELLED]: 'Cancelación',
    [NOTIFICATION_TYPES.ROUTINE_REMINDER]: 'Alerta de rutina',
  };
  return types[type as keyof typeof types];
};

const getTypeClass = (type: NOTIFICATION_TYPES) =>
  classNames({
    error: type === NOTIFICATION_TYPES.RESERVATION_CANCELLED,
    warning: type === NOTIFICATION_TYPES.ROUTINE_REMINDER,
  });

const NotificationSkeleton = () => (
  <div className='notification-item'>
    <div className='notification-content'>
      <div className='notification-header'>
        <Skeleton width='100px' height='24px' className='type-badge' />
        <Skeleton width='180px' height='20px' />
      </div>
      <Skeleton width='60%' height='28px' className='mb-2' />
      <Skeleton width='80%' height='20px' className='mb-2' />
      <Skeleton width='60px' height='20px' />
    </div>
  </div>
);

export const Notifications = () => {
  const { post } = useClient();
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { notifications, notificationsLoading, mutateNotifications } = useNotificationContext() ?? {};
  const paginatedNotifications = useMemo(
    () => notifications?.slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE) ?? [],
    [notifications, currentPage],
  );

  const markAsRead = useCallback(
    async (notificationIds: string[]) => {
      const body: MarkAsReadRequest = {
        notificationIds,
      };
      await post('/notifications/mark-as-read', {
        body,
        handlers: {
          defaultSuccess: () => {
            mutateNotifications?.();
          },
        },
      });
    },
    [mutateNotifications, post],
  );

  useEffect(() => {
    if (!notifications?.length) return;
    const unreadNotifications = notifications.filter((notification) => !notification.isRead);
    if (!unreadNotifications.length) return;
    markAsRead(unreadNotifications.map((notification) => notification.id));
  }, [markAsRead, notifications]);

  const handlePageChange = (event: any) => {
    setCurrentPage(event.page);
  };

  return (
    <PageTemplate className='notifications-page' title='Notificaciones'>
      {notificationsLoading ? (
        <Card className='notifications-list'>
          {[...Array(3)].map((_, index) => (
            <NotificationSkeleton key={index} />
          ))}
          <Paginator first={0} rows={ITEMS_PER_PAGE} totalRecords={ITEMS_PER_PAGE * 2} onPageChange={handlePageChange} />
        </Card>
      ) : notifications?.length ? (
        <Card className='notifications-list'>
          {paginatedNotifications.map((notification) => (
            <div key={notification.id} className='notification-item'>
              <DeleteNotificationButton notificationId={notification.id} />
              <div className='notification-content'>
                <div className='notification-header'>
                  <span className={`type-badge ${getTypeClass(notification.type)}`}>{getTypeLabel(notification.type)}</span>
                  <span className='date'>{format(notification.createdAt, "d 'de' MMMM 'a las' HH:mm")}</span>
                </div>
                <h3>{notification.title}</h3>
                <p className='message'>{notification.body}</p>
                <Link to={notification.link} className='user-link'>
                  Ver
                </Link>
              </div>
            </div>
          ))}
          <Paginator
            first={currentPage * ITEMS_PER_PAGE}
            rows={ITEMS_PER_PAGE}
            totalRecords={notifications?.length ?? 0}
            onPageChange={handlePageChange}
          />
        </Card>
      ) : (
        <div className='notifications-list-empty'>
          <p>No hay notificaciones</p>
        </div>
      )}
    </PageTemplate>
  );
};
