import React, { PropsWithChildren, createContext, useContext, useState } from 'react';

interface PullToRefreshContextValue {
  disabledPullToRefresh: boolean;
  setDisabledPullToRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}
const initialValue: PullToRefreshContextValue = { disabledPullToRefresh: false, setDisabledPullToRefresh: () => {} };

const PullToRefreshContext = createContext<PullToRefreshContextValue>(initialValue);

export const PullToRefreshProvider = ({ children }: PropsWithChildren) => {
  const [disabledPullToRefresh, setDisabledPullToRefresh] = useState(false);

  return (
    <PullToRefreshContext.Provider value={{ disabledPullToRefresh, setDisabledPullToRefresh }}>
      {children}
    </PullToRefreshContext.Provider>
  );
};

export const usePullToRefreshContext = () => {
  return useContext(PullToRefreshContext);
};
