import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { ExternalUserShort } from '../../types/externalUser';
import { ROUTINE_TYPES, RoutineExercise, ExerciseDay } from '../../types/Fisiofit/routine';
import { validateLettersSpacesAndNumbers } from '../../utils/validationUtils';
import { DeepPartial } from '../../types/utils';

const { NOT_NAME, ONLY_LETTERS_AND_SPACES, NOT_EXTERNAL_USER, NOT_START_DATE, NOT_EXERCISES, NOT_DURATION, NOT_EXERCISE_ID } = {
  ...VALIDATION_MESSAGES,
  NOT_EXERCISE_ID: 'Debes seleccionar un ejercicio',
};

export interface RoutineForm {
  name?: string;
  exerciseDays: (ExerciseDay & { id?: string })[];
  description?: string;
  localStartDate?: string;
  duration: number | null;
  externalUser?: ExternalUserShort;
  routineType: ROUTINE_TYPES;
}

export const EXERCISE_VALIDATORS: FormValidators<RoutineExercise> = {
  exerciseId: (exercise: DeepPartial<RoutineExercise>) => {
    if (!exercise.exerciseId) return NOT_EXERCISE_ID;
    return undefined;
  },
};

export const ROUTINE_FORM_VALIDATORS: FormValidators<RoutineForm> = {
  name: (routine) => {
    if (!routine.name && routine.routineType !== ROUTINE_TYPES.USER) return NOT_NAME;
    if (routine.name && routine.routineType === ROUTINE_TYPES.USER && !validateLettersSpacesAndNumbers(routine.name))
      return ONLY_LETTERS_AND_SPACES;
    return undefined;
  },
  duration: (routine) => {
    if (routine.routineType === ROUTINE_TYPES.USER && !routine.duration) return NOT_DURATION;
    return undefined;
  },
  externalUser: (routine) => {
    if (routine.routineType === ROUTINE_TYPES.USER && !routine.externalUser) return NOT_EXTERNAL_USER;
    return undefined;
  },
  localStartDate: (routine) => {
    if (routine.routineType === ROUTINE_TYPES.USER && !routine.localStartDate) return NOT_START_DATE;
    return undefined;
  },
  exerciseDays: (routine) => {
    if (!routine.exerciseDays?.length) return NOT_EXERCISES;

    const hasExercises = routine.exerciseDays.every((day) => {
      return day?.exercises && Array.isArray(day.exercises) && day.exercises.length > 0;
    });

    if (!hasExercises) return NOT_EXERCISES;
    return undefined;
  },
};
