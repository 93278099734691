import { ChartData, ChartOptions } from 'chart.js';
import { DimensionWithMetric, UsersOverTime } from '../types/analytics';

const CHART_COLORS = ['#6366f1', '#7073e9', '#8c90df', '#a5a8d5', '#c1c4e3', '#d8daf2', '#f0f1fa'];

const getPieColors = () => {
  const documentStyle = getComputedStyle(document.documentElement);

  return [
    documentStyle.getPropertyValue('--primary-400'),
    documentStyle.getPropertyValue('--green-300'),
    documentStyle.getPropertyValue('--yellow-300'),
    documentStyle.getPropertyValue('--blue-300'),
    documentStyle.getPropertyValue('--orange-300'),
    documentStyle.getPropertyValue('--cyan-300'),
    documentStyle.getPropertyValue('--red-300'),
  ];
};

const getColors = () => {
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue('--text-color');
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
  const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

  return { textColor, textColorSecondary, surfaceBorder };
};

export const lineAggregatedData = (data: UsersOverTime[]) => {
  const { surfaceBorder, textColorSecondary } = getColors();

  // TODO refactorizar y hacer en un solo loop
  const chartData: ChartData = {
    labels: data.map((item) => item.date),
    datasets: [
      {
        label: '',
        data: data.map((item) => item.activeUsers),
        borderColor: CHART_COLORS[0],
        pointBorderColor: CHART_COLORS[0],
        pointBackgroundColor: CHART_COLORS[0],
        backgroundColor: 'transparent',
        pointStyle: false,
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options: ChartOptions = {
    interaction: {
      intersect: false, // Tooltip appears even when not directly over a point
      mode: 'index',
    },
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false,
        // labels: {
        //   color: textColor,
        // },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
          autoSkip: true,
        },
        grid: {
          color: (ctx) => {
            const index = ctx.tick.value;
            return index % 3 === 0 ? surfaceBorder : 'transparent'; // Show grid line for every 3 ticks
          },
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: (ctx) => {
            const index = ctx.tick.value;
            return index % 3 === 0 ? surfaceBorder : 'transparent'; // Show grid line for every 3 ticks
          },
        },
      },
    },
  };

  return { chartData, chartOptions: options };
};

export const aggregatedData = (data: DimensionWithMetric[]) => {
  const { surfaceBorder, textColorSecondary } = getColors();

  // TODO refactorizar y hacer en un solo loop
  const colors = data?.map((_: any, index) => CHART_COLORS[index] ?? CHART_COLORS[0]) || [];
  const chartData = {
    labels: data?.map((item: any) => `${item.dimension} (${item.metric})`) || [],
    datasets: [
      {
        label: '',
        data: data?.map((item: any) => item.metric) || [],
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions: ChartOptions = {
    interaction: {
      intersect: false, // Tooltip appears even when not directly over a point
      mode: 'index',
    },
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false,
        // labels: {
        //   fontColor: textColor,
        // },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
          font: {
            weight: 500,
          },
          callback: function (value) {
            const label = this.getLabelForValue(Number(value));
            return label?.length > 16 ? label.substring(0, 16) + '...' : label;
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
    },
  };

  return { chartData, chartOptions };
};

export const pieAggregatedData = (data: DimensionWithMetric[]) => {
  const { textColor } = getColors();

  const pieColors = getPieColors();

  // TODO refactorizar y hacer en un solo loop
  const colors = data?.map((_: any, index) => pieColors[index] ?? pieColors[0]) || [];
  const chartData = {
    labels: data?.map((item: any) => `${item.dimension} (${item.metric})`) || [],
    datasets: [
      {
        label: '',
        data: data?.map((item: any) => item.metric) || [],
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions: ChartOptions = {
    interaction: {
      intersect: false, // Tooltip appears even when not directly over a point
      mode: 'nearest',
    },
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: textColor,
        },
      },
    },
  };

  return { chartData, chartOptions };
};

export interface GetBusinessChartOptionsOptions {
  valueSuffix?: string;
  largeValueChart?: boolean;
}

export const getBusinessChartOptions = (opts?: GetBusinessChartOptionsOptions) => {
  const { valueSuffix, largeValueChart = false } = opts ?? {};
  const { textColorSecondary } = getColors();

  const baseOptions: ChartOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    interaction: {
      intersect: false, // Tooltip appears even when not directly over a point
      mode: 'index',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {},
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
          autoSkip: true,
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: largeValueChart ? false : true,
        ticks: {
          color: textColorSecondary,
          stepSize: 1,
          maxTicksLimit: 5,
          precision: 0,
        },
        ...(largeValueChart ? {} : { suggestedMin: 0, suggestedMax: 4 }),
      },
    },
  };

  if (valueSuffix && baseOptions.scales?.y?.ticks && baseOptions.plugins?.tooltip?.callbacks) {
    baseOptions.scales.y.ticks.callback = (value: any) => `${value} ${valueSuffix}`;
    baseOptions.plugins.tooltip.callbacks.label = (context: any) => `${context.dataset.label}: ${context.raw} ${valueSuffix}`;
  }

  return baseOptions;
};
