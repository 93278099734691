import storage from '../storage/storage';

export const fetchWithCache = async (key: string, fetcher: () => Promise<any>, ttl: number) => {
  const now = new Date().getTime();
  const cachedData = await storage.getItem(key);

  if (cachedData) {
    const parsedCache = JSON.parse(cachedData);
    if (now - parsedCache.timestamp < ttl && parsedCache.data) {
      return parsedCache.data;
    }
    await storage.removeItem(key);
  }

  const data = await fetcher();
  await storage.setItem(key, JSON.stringify({ data, timestamp: now }));
  return data;
};
