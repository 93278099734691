import { classNames } from 'primereact/utils';
import React from 'react';

interface Props {
  value: string | undefined;
  maxChar: number;
}

export const CharacterCount = ({ value, maxChar }: Props) => {
  const length = value?.length ?? 0;
  return (
    <small className='p-info floating-helper'>
      (
      <span
        className={classNames({
          'p-error': length > maxChar,
        })}
      >
        {length}
      </span>
      / {maxChar})
    </small>
  );
};
