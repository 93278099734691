import React from 'react';
import { UserTableMetric } from '../../types/business-metrics';
import { Avatar } from 'primereact/avatar';
import './MetricsUserTable.scss';
import { Card } from 'primereact/card';
import { getUserAvatarProps } from '../../utils/avatarUtils';
import classNames from 'classnames';
import { CardEmptyState } from '../../pages/BusinessAnalytics/components/CardEmptyState';
import { useNavigateScroll } from '../../hooks/useNavigateScroll';

interface Props {
  data: UserTableMetric[];
  title: string;
  className?: string;
  isEmpty?: boolean;
  emptyStateText?: string;
}

export const MetricsUserTable = ({ data, title, className, isEmpty, emptyStateText }: Props) => {
  const navigate = useNavigateScroll();

  const handleUserClick = (id: string) => {
    navigate(`/users/profile/${id}`);
  };

  return (
    <Card title={title} className={classNames('metrics-user-table', className)}>
      {isEmpty ? (
        <CardEmptyState text={emptyStateText} />
      ) : (
        <div className='table-content'>
          {data.map((user, index) => (
            <div key={user.externalUserId} className='table-row' onClick={() => handleUserClick(user.externalUserId)}>
              <div className='user-info'>
                <span className='rank'>{index + 1}</span>
                <Avatar {...getUserAvatarProps(user)} shape='circle' size='normal' />
                <span className='name'>
                  {user.name} {user.surnames}
                </span>
              </div>
              <span className='value'>{user.value}</span>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
};
