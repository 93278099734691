import { FormValidators, VALIDATION_MESSAGES } from '../../../hooks/useForm';
import { LocalePost } from '../../../types/post';
import { validateKebabCase } from '../../../utils/validationUtils';

export const TITLE_MAX_CHAR = 60;
export const DESCRIPTION_MAX_CHAR = 270;
export const URL_MAX_CHAR = 50;

const { EMPTY, MAX_LENGTH, EMPTY_IMAGE } = VALIDATION_MESSAGES;

export const POST_EDITOR_VALIDATORS: FormValidators<LocalePost> = {
  title: (post) => {
    const length = post.title?.length ?? 0;
    if (!length) return EMPTY;
    if (length > TITLE_MAX_CHAR) return MAX_LENGTH(TITLE_MAX_CHAR);
  },
  description: (post) => {
    const length = post.description?.length ?? 0;
    if (!length) return EMPTY;
    if (length > DESCRIPTION_MAX_CHAR) return MAX_LENGTH(DESCRIPTION_MAX_CHAR);
  },
  content: (post) => {
    if (!post.content?.length) return EMPTY;
  },
  image: {
    alt: (image) => {
      if (!image.alt?.length) return EMPTY;
    },
    dashboardUrl: (image) => {
      if (!image.dashboardUrl?.length) return EMPTY_IMAGE;
      // TODO mejorar validacion chequeando que sea una imagen? A lo mejor el fileupload de prime ya lo hace?
    },
  },
  meta: {
    url: (meta) => {
      const value = meta.url;
      const length = value?.length ?? 0;
      if (!length) return EMPTY;
      if (value && !validateKebabCase(value))
        return 'La URL solo puede tener minúsculas sin tilde, números y guiones';
      if (length > URL_MAX_CHAR) return MAX_LENGTH(URL_MAX_CHAR);
    },
    title: (meta) => {
      if (!meta.title?.length) return EMPTY;
    },
    description: (meta) => {
      if (!meta.description?.length) return EMPTY;
    },
  },
};
