import React from 'react';
import './Coupons.scss';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { ResponsiveTable } from '../../components/ResponsiveTable/ResponsiveTable';
import { TableColumn, TableAction } from '../../types/responsiveTable';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import useSWR, { mutate } from 'swr';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { useResourcePanel } from '../../hooks/useResourcePanel';
import { Coupon, CouponWithUsers } from '../../types/coupon';
import { CouponPanel } from '../../components/CouponPanel/CouponPanel';
import { resourceDeleter } from '../../utils/resourceMutation';
import { showDeleteDialog } from '../../utils/dialogUtils';
import { hasFeaturesPermission } from '../../utils/featureUtils';
import { ALL_FEATURES } from '../../config/features';
import { useAuthContext } from '../../context/AuthContext';

export const getCouponDeleteConfirmationDialogProps = (coupon: Coupon | undefined) => {
  return {
    message: (
      <>
        ¿Estás seguro de que quieres eliminar el bono <b>{coupon?.name}</b>?
      </>
    ),
    header: 'Eliminar bono',
  };
};

export const Coupons = () => {
  const { get, delete: del } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};
  const { features, featuresLoading } = useAuthContext() ?? {};
  const { data, isLoading } = useSWR(organization ? `/organizations/${organization.id}/coupons` : null, get<CouponWithUsers[]>);
  const {
    isEditing,
    setIsEditing,
    panelVisible,
    closePanel,
    selectedResource,
    openPanel,
    isDeleting,
    setIsDeleting,
    getResourceById,
  } = useResourcePanel(data);

  const handleDelete = async (id: string | undefined) => {
    if (!id || !organization?.id) return false;
    setIsDeleting(true);
    const url = `/coupons/${id}`;
    const response = await del<Coupon>(url, {
      errorMessages: {
        summary: 'No se pudo eliminar el bono',
        defaultDetail: 'Hubo un error al intentar borrar el bono',
        RESOURCE_IN_USE: 'El bono no se puede eliminar porque está siendo usado',
      },
      successMessage: {
        summary: 'Bono eliminado',
        detail: 'El bono se ha eliminado correctamente',
      },
      handlers: {
        defaultSuccess: () => {
          mutate(`/organizations/${organization.id}/coupons`, resourceDeleter(id));
        },
      },
    });

    setIsDeleting(false);
    return !!response;
  };

  const tableHeaders: TableColumn<Coupon>[] = [
    {
      label: 'Nombre',
      accessor: 'name',
      type: 'string',
      isTitle: true,
    },
    {
      label: 'Precio',
      accessor: 'price',
      type: 'number',
      valueFromCallBack: (value) => {
        return new Intl.NumberFormat('es-ES', {
          style: 'currency',
          currency: 'EUR',
        }).format(value);
      },
      sortable: true,
      isCurrency: true,
      currencyLocale: 'es-ES',
      currencyCode: 'EUR',
    },
    {
      label: 'Usos',
      accessor: 'usageLimit',
      type: 'number',
    },
  ];

  const userHasEditFeature = hasFeaturesPermission([ALL_FEATURES.BONUSES], { features, featuresLoading });

  const options: TableAction[] = [
    {
      label: 'Ver',
      onClick: (id) => {
        openPanel(false, id);
      },
      hasPermission: userHasEditFeature,
    },
    {
      label: 'Editar',
      onClick: (id) => {
        openPanel(true, id);
      },
      hasPermission: userHasEditFeature,
    },
    {
      label: 'Eliminar',
      onClick: (id) => {
        const coupon = getResourceById(id);
        showDeleteDialog(() => handleDelete(id), getCouponDeleteConfirmationDialogProps(coupon));
      },
      hasPermission: userHasEditFeature,
    },
  ];

  return (
    <PageTemplate className='coupones-page' title='Bonos'>
      <ResponsiveTable
        disabled={isDeleting}
        data={data || []}
        columns={tableHeaders}
        options={options}
        loading={organizationLoading || isLoading}
        headerOptions={
          <Button
            label='Añadir bono'
            outlined
            rounded
            icon={PrimeIcons.PLUS}
            onClick={() => {
              openPanel(false);
            }}
          />
        }
        onRowClick={(row) => {
          openPanel(false, row.id);
        }}
      />
      <CouponPanel
        resource={selectedResource}
        visible={panelVisible}
        onHide={closePanel}
        onIsEditingChange={(value) => setIsEditing(value)}
        isEditing={isEditing}
        onDeleteResource={handleDelete}
        isDeleting={isDeleting}
      />
    </PageTemplate>
  );
};
