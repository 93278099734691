const css = `@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.loading-screen {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background);
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  flex-direction: column;
  transition: opacity 0.3s ease-out;
  position: fixed;
  top: 0;
  left: 0;
}
.loading-screen.loading-screen-show {
  display: flex;
}
.loading-screen.loading-screen-show {
  opacity: 1;
  pointer-events: all;
  display: flex;
}
.loading-screen:not(.loading-screen-show) {
  animation: fadeOut 0.3s ease-out forwards;
}
.loading-screen .loading-logo img {
  width: 90vw;
  max-width: 500px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvTG9hZGluZ1NjcmVlbiIsInNvdXJjZXMiOlsiTG9hZGluZ1NjcmVlbi5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7SUFDRTs7RUFFRjtJQUNFOzs7QUFJSjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7QUFHRjtFQUNFOztBQUlBO0VBQ0U7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIkBrZXlmcmFtZXMgZmFkZU91dCB7XG4gIGZyb20ge1xuICAgIG9wYWNpdHk6IDE7XG4gIH1cbiAgdG8ge1xuICAgIG9wYWNpdHk6IDA7XG4gIH1cbn1cblxuLmxvYWRpbmctc2NyZWVuIHtcbiAgaGVpZ2h0OiAxMDB2aDtcbiAgd2lkdGg6IDEwMHZ3O1xuICBkaXNwbGF5OiBmbGV4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgYmFja2dyb3VuZDogdmFyKC0tYmFja2dyb3VuZCk7XG4gIHotaW5kZXg6IDk5OTtcbiAgb3BhY2l0eTogMDtcbiAgcG9pbnRlci1ldmVudHM6IG5vbmU7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIHRyYW5zaXRpb246IG9wYWNpdHkgMC4zcyBlYXNlLW91dDtcbiAgcG9zaXRpb246IGZpeGVkO1xuICB0b3A6IDA7XG4gIGxlZnQ6IDA7XG5cbiAgJi5sb2FkaW5nLXNjcmVlbi1zaG93IHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICB9XG5cbiAgJi5sb2FkaW5nLXNjcmVlbi1zaG93IHtcbiAgICBvcGFjaXR5OiAxO1xuICAgIHBvaW50ZXItZXZlbnRzOiBhbGw7XG4gICAgZGlzcGxheTogZmxleDtcbiAgfVxuXG4gICY6bm90KC5sb2FkaW5nLXNjcmVlbi1zaG93KSB7XG4gICAgYW5pbWF0aW9uOiBmYWRlT3V0IDAuM3MgZWFzZS1vdXQgZm9yd2FyZHM7XG4gIH1cblxuICAubG9hZGluZy1sb2dvIHtcbiAgICBpbWcge1xuICAgICAgd2lkdGg6IDkwdnc7XG4gICAgICBtYXgtd2lkdGg6IDUwMHB4O1xuICAgIH1cbiAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
