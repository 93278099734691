import React, { PropsWithChildren, createContext, useContext, useRef, useState } from 'react';
import { Toast, ToastMessage } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';

interface NotificationContextValue {
  showToast: (message: ToastMessage | ToastMessage[]) => void;
}

const NotificationContext = createContext<NotificationContextValue | null>(null);

export const NotificationProvider = ({ children }: PropsWithChildren) => {
  const toast = useRef<Toast>(null);

  const showToast = (message: ToastMessage | ToastMessage[]) => {
    toast.current?.show({ life: 5000, ...message });
  };

  return (
    <NotificationContext.Provider value={{ showToast }}>
      <Toast ref={toast} />
      <ConfirmDialog dismissableMask />
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  return useContext(NotificationContext);
};
