import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import './PostEditor.scss';
import { FileUploadButton } from '../../../components/FileUploadButton/FileUploadButton';
import { useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { useWebsiteContext } from '../../../context/WebsiteContext';
import { EMPTY_POST } from './const';
import { useForm } from '../../../hooks/useForm';
import { RequestFeedbackMessages, useClient } from '../../../hooks/useClient';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { RichTextEditor } from '../../../components/RichTextEditor/RichTextEditor';
import { Image } from 'primereact/image';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { confirmDialog } from 'primereact/confirmdialog';
import { classNames } from 'primereact/utils';
import { Skeleton } from 'primereact/skeleton';
import { IMAGE_PLACEHOLDER } from '../../../const/images';
import { DESCRIPTION_MAX_CHAR, POST_EDITOR_VALIDATORS, TITLE_MAX_CHAR, URL_MAX_CHAR } from './validators';
import { CharacterCount } from '../../../components/CharacterCount/CharacterCount';
import { resourceMutator } from '../../../utils/resourceMutation';
import { LocalePost, Post } from '../../../types/post';
import { usePageLocaleContext } from '../../../context/PageLocaleContext';
import { getLocalePost } from '../../../utils/postUtils';
import { LOCALES } from '../../../config/locales';
import { PageLocalePicker } from '../../../components/PageLocalePicker.tsx/PageLocalePicker';
import { removeFroalaHtml } from '../../../utils/textUtils';
import { FormField } from '../../../components/FormField/FormField';
import { useFeaturesToggle } from '../../../hooks/useFeaturesToggle';
import { ALL_FEATURES } from '../../../config/features';
import { FeatureToggle } from '../../../components/FeatureToggle/FeatureToggle';
import { ErrorBoundary } from '../../../components/ErrorBoundary/ErrorBoundary';
import { useNavigateScroll } from '../../../hooks/useNavigateScroll';
import { Tag } from 'primereact/tag';

const getToastMessages = (isEditing: boolean, isDraft: boolean): RequestFeedbackMessages => {
  // this needs to account for editing and creating, but also for draft and published

  if (isDraft) {
    return {
      successMessage: {
        summary: isEditing ? 'Borrador actualizado' : 'Borrador creado',
        detail: isEditing ? 'El borrador del artículo se ha actualizado correctamente' : 'Se ha creado el artículo como borrador',
      },
      errorMessages: {
        summary: isEditing ? 'Error actualizando borrador' : 'Error creando borrador',
        defaultDetail: isEditing
          ? 'No se ha podido actualizar el borrador del artículo'
          : 'No se ha podido crear el artículo como borrador',
        RESOURCE_WITH_SAME_NAME_EXISTS: 'La URL elegida ya existe para otro artículo',
      },
    };
  }
  return {
    successMessage: {
      summary: isEditing ? 'Cambios guardados' : 'Artículo publicado',
      detail: isEditing ? 'El artículo se ha guardado correctamente' : 'Se ha publicado el nuevo artículo',
    },
    errorMessages: {
      summary: isEditing ? 'Error guardando artículo' : 'Error al publicar el artículo',
      defaultDetail: isEditing ? 'No se han podido guardar los cambios' : 'No se ha podido publicar el artículo',
      RESOURCE_WITH_SAME_NAME_EXISTS: 'La URL elegida ya existe para otro artículo',
    },
  };
};

const getPostDto = (form: LocalePost, locale: LOCALES, isDraft = false) => {
  const {
    readTime,
    locale: _,
    image: { alt },
    content,
    ...restForm
  } = form;

  return {
    ...restForm,
    locale,
    image: { alt },
    content: removeFroalaHtml(content),
    isDraft,
  };
};

interface Props {
  isEditing?: boolean;
}

export const PostEditor = ({ isEditing = false }: Props) => {
  const { pageLocale, setPageLocale } = usePageLocaleContext() ?? {};
  const client = useClient();
  const navigate = useNavigateScroll();
  const { website } = useWebsiteContext() ?? {};
  const { id } = useParams();
  const [imageFile, setImageFile] = useState<File>();
  const [isDeleting, setIsDeleting] = useState(false);

  const userHasCreatePostsFeature = useFeaturesToggle([ALL_FEATURES.BLOG_WRITE]);

  useEffect(() => {
    if (!pageLocale && website) {
      setPageLocale?.(website?.locales[0]);
    }
  }, [pageLocale, setPageLocale, website]);

  const fetcher = async (url: string) => {
    return await client.get<Post>(url, {
      errorMessages: {
        summary: 'Error buscando artículo',
        defaultDetail: 'No sé pudo encontrar el artículo',
        [404]: 'El artículo indicado no existe',
      },
      handlers: {
        defaultError: () => {
          navigate('../');
        },
      },
    });
  };

  const {
    data,
    isLoading,
    mutate: mutatePostData,
  } = useSWR(isEditing && website ? `/websites/${website?.id}/posts/${id}` : null, fetcher);
  const savedLocalePost = useMemo(() => {
    if (!data) return undefined;
    return getLocalePost(data, pageLocale);
  }, [data, pageLocale]);

  const { form, setForm, setFormFields, isSaving, setIsSaving, hasChanged, validationErrors, validate } = useForm<LocalePost>(
    savedLocalePost ?? EMPTY_POST,
    false,
    POST_EDITOR_VALIDATORS,
  );

  const isEditingDraft = isEditing && savedLocalePost?.isDraft;
  const isEditingPublishedPost = savedLocalePost && !savedLocalePost.isDraft;

  const savePost = async (isDraft = false) => {
    const errors = validate();
    if (!form || errors || !pageLocale) return;
    setIsSaving(true);
    const formData = new FormData();
    const postDto = getPostDto(form, pageLocale, isDraft);
    formData.append('data', JSON.stringify(postDto));
    if (imageFile) {
      formData.append('image', imageFile);
    }
    const url = `/websites/${website?.id}/posts${isEditing ? `/${id}` : ''}`;

    const { errorMessages, successMessage } = getToastMessages(isEditingPublishedPost ?? false, isDraft);

    const savedPostData = await (isEditing ? client.put : client.post)<Post>(url, {
      body: formData,
      successMessage,
      errorMessages,
    });
    setIsSaving(false);

    if (!savedPostData) return;
    const newLocalePost = getLocalePost(savedPostData, pageLocale);
    if (!newLocalePost) return;
    setForm(newLocalePost);
    mutatePostData(savedPostData, false);
    if (!isEditing) {
      navigate(`/blog/post/${savedPostData.id}`);
      mutate(`/websites/${website?.id}/posts`, undefined);
    } else {
      mutate(`/websites/${website?.id}/posts`, resourceMutator(savedPostData));
    }
  };

  const deletePost = async () => {
    setIsDeleting(true);
    const url = `/websites/${website?.id}/posts/${id}/${pageLocale}`;
    await client.delete<Post>(url, {
      errorMessages: {
        summary: isEditingDraft ? 'No se pudo eliminar el borrador' : 'No se pudo eliminar el artículo',
        defaultDetail: isEditingDraft
          ? 'Hubo un error al intentar borrar el borrador'
          : 'Hubo un error al intentar borrar el artículo',
      },
      successMessage: {
        summary: isEditingDraft ? 'Borrador eliminado' : 'Artículo eliminado',
        detail: isEditingDraft ? 'El borrador se ha eliminado correctamente' : 'El artículo se ha eliminado correctamente',
      },
    });
    setIsDeleting(false);
    mutate(`/websites/${website?.id}/posts`);
    navigate('../');
  };

  const handleDelete = () => {
    confirmDialog({
      message: 'Seguro que deseas borrar este artículo?',
      header: 'Borrar artículo',
      icon: PrimeIcons.EXCLAMATION_CIRCLE,
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: deletePost,
      acceptLabel: 'Sí',
      draggable: false,
      resizable: false,
    });
  };

  const submitPost = async (e: FormEvent) => {
    e.preventDefault();
    savePost(false);
  };

  const dataLoading = isLoading || (isEditing && !data);
  const hasRequiredData = !dataLoading && !!form && !!website;
  const [editorReady, setEditorReady] = useState(false);

  useEffect(() => {
    if (data && pageLocale && !form) {
      const localePost = getLocalePost(data, pageLocale);
      if (localePost) {
        setForm(localePost);
      }
    }
  }, [data, pageLocale, form, setForm]);

  useEffect(() => {
    if (hasRequiredData && form?.content !== undefined) {
      const timer = setTimeout(() => {
        setEditorReady(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [hasRequiredData, form?.content]);

  return (
    <>
      <div className='post-editor-header'>
        {isEditingDraft && <Tag severity='warning' className='draft-tag' value='Borrador'></Tag>}
        <PageLocalePicker />
      </div>
      <Card>
        <form
          className={classNames('post-editor', {
            'form-loading': dataLoading || isSaving || isDeleting,
          })}
          onSubmit={submitPost}
        >
          <FormField
            fullWidth
            labelTitle='Título'
            elementId='title'
            error={validationErrors?.title}
            helper={<CharacterCount value={form?.title} maxChar={TITLE_MAX_CHAR} />}
            required
          >
            <InputText
              className={classNames({ 'p-invalid': validationErrors?.title })}
              id='title'
              name='title'
              placeholder={`Título (máximo ${TITLE_MAX_CHAR} caracteres)`}
              value={form?.title}
              disabled={!userHasCreatePostsFeature}
              onChange={(e: any) =>
                setFormFields({
                  title: e.target.value,
                })
              }
            />
          </FormField>

          <FormField
            fullWidth
            labelTitle='Descripción'
            elementId='description'
            error={validationErrors?.description}
            helper={<CharacterCount value={form?.description} maxChar={DESCRIPTION_MAX_CHAR} />}
            required
          >
            <InputTextarea
              id='description'
              name='description'
              disabled={!userHasCreatePostsFeature}
              className={classNames({
                'p-invalid': validationErrors?.description,
              })}
              placeholder={`Descripción (máximo ${DESCRIPTION_MAX_CHAR} caracteres)`}
              autoResize
              value={form?.description}
              onChange={(e: any) =>
                setFormFields({
                  description: e.target.value,
                })
              }
              rows={3}
            />
          </FormField>

          <FormField labelTitle='Imágen' elementId='image' error={validationErrors?.image?.dashboardUrl} required>
            <FileUploadButton
              disabled={!userHasCreatePostsFeature}
              label='Subir imagen'
              accept='image/*'
              fileName={`blog_post_${form?.title?.toLowerCase().replace(/ /g, '_')}_image.png`}
              invalid={!!validationErrors?.image?.dashboardUrl}
              onFileUpload={(encodedImage, file) => {
                setImageFile(file);
                setFormFields({
                  image: { ...form?.image, dashboardUrl: encodedImage },
                });
              }}
            />
          </FormField>

          <FormField
            labelTitle='Texto alternativo'
            elementId='image-alt'
            error={validationErrors?.image?.alt}
            infoTooltip='El texto alternativo describe la imagen en caso de que no pueda cargarse. También ayuda a los motores de búsqueda a entender el contenido de la imagen y es importante para la accesibilidad. Describe brevemente lo que se muestra en la imagen.'
            required
          >
            <InputText
              id='image-alt'
              name='image-alt'
              disabled={!userHasCreatePostsFeature}
              placeholder='Texto alternativo de la imagen'
              value={form?.image.alt}
              className={classNames({
                'p-invalid': validationErrors?.image?.alt,
              })}
              onChange={(e) =>
                setFormFields({
                  image: { ...form?.image, alt: e.target.value },
                })
              }
            />
          </FormField>

          {dataLoading && isEditing ? (
            <Skeleton className='post-image' height='300px' width='50%' />
          ) : (
            <Image
              className={classNames('post-image', {
                'placeholder-image': form && !form.image.dashboardUrl,
              })}
              src={form?.image.dashboardUrl || IMAGE_PLACEHOLDER}
              preview={!!form?.image.dashboardUrl}
            />
          )}

          <FormField fullWidth labelTitle='Contenido' elementId='content' error={validationErrors?.content} required>
            {dataLoading ? (
              <Skeleton height='300px' />
            ) : (
              form && (
                <ErrorBoundary
                  fallback={
                    <div className='editor-error-fallback'>
                      <p>No se pudo cargar el editor de texto. Por favor, intenta recargar la página.</p>
                      <Button
                        label='Recargar'
                        icon={PrimeIcons.REFRESH}
                        onClick={() => window.location.reload()}
                        className='p-button-outlined p-button-sm'
                      />
                    </div>
                  }
                >
                  {editorReady ? (
                    <RichTextEditor
                      id='content'
                      disabled={!userHasCreatePostsFeature}
                      value={form.content}
                      className={classNames({ 'p-invalid': validationErrors?.content })}
                      onChange={(value) => setFormFields({ content: value })}
                      placeholder='Contenido del artículo'
                      hideFontSize
                    />
                  ) : (
                    <Skeleton height='300px' />
                  )}
                </ErrorBoundary>
              )
            )}
          </FormField>

          <FormField
            fullWidth
            labelTitle='URL del artículo'
            elementId='url'
            error={validationErrors?.meta?.url}
            helper={<CharacterCount value={form?.meta.url} maxChar={URL_MAX_CHAR} />}
            infoTooltip='Esta es la URL personalizada para tu artículo. Usa solo letras minúsculas sin tilde, números y guiones (ej.: esta-es-la-url).'
            required
          >
            <InputText
              id='url'
              name='url'
              disabled={!userHasCreatePostsFeature}
              autoCapitalize='off'
              value={form?.meta.url}
              placeholder='ejemplo-de-url'
              className={classNames({
                'p-invalid': validationErrors?.meta?.url,
              })}
              onChange={(e: any) =>
                setFormFields({
                  meta: { ...form?.meta, url: e.target.value },
                })
              }
            />
          </FormField>

          <FormField
            labelTitle='Título meta'
            elementId='meta-title'
            error={validationErrors?.meta?.title}
            infoTooltip='Este es el título que se mostrará en los resultados de búsqueda de Google y otras plataformas. Asegúrate de que sea claro, atractivo y que describa de forma breve el contenido del artículo.'
            required
          >
            <InputText
              id='meta-title'
              name='meta-title'
              placeholder='Título meta'
              disabled={!userHasCreatePostsFeature}
              className={classNames({
                'p-invalid': validationErrors?.meta?.title,
              })}
              value={form?.meta.title}
              onChange={(e) =>
                setFormFields({
                  meta: { ...form?.meta, title: e.target.value },
                })
              }
            />
          </FormField>

          <FormField
            labelTitle='Descripción meta'
            elementId='meta-description'
            error={validationErrors?.meta?.description}
            infoTooltip='Aquí puedes escribir una breve descripción que aparecerá en los resultados de búsqueda de Google. Este texto ayuda a los usuarios a entender de qué trata tu contenido y a decidir si hacen clic para leer más.'
            required
          >
            <InputText
              id='meta-description'
              name='meta-description'
              placeholder='Descripción meta'
              disabled={!userHasCreatePostsFeature}
              value={form?.meta.description}
              className={classNames({
                'p-invalid': validationErrors?.meta?.description,
              })}
              onChange={(e) =>
                setFormFields({
                  meta: { ...form?.meta, description: e.target.value },
                })
              }
            />
          </FormField>

          <div className='post-editor-buttons'>
            {isEditing && (
              <FeatureToggle featuresToCheck={[ALL_FEATURES.BLOG_WRITE]}>
                <Button
                  className='delete-post-button'
                  loading={isDeleting}
                  icon='pi pi-trash'
                  type='button'
                  onClick={handleDelete}
                  severity='danger'
                  outlined
                  disabled={!savedLocalePost}
                >
                  Eliminar
                </Button>
              </FeatureToggle>
            )}
            <FeatureToggle featuresToCheck={[ALL_FEATURES.BLOG_WRITE]}>
              {!isEditingPublishedPost && (
                <Button loading={isSaving} disabled={!hasChanged} icon='pi pi-save' onClick={() => savePost(true)} outlined>
                  {isEditingDraft ? 'Actualizar borrador' : 'Guardar borrador'}
                </Button>
              )}
              <Button loading={isSaving} disabled={!hasChanged && !isEditingDraft} type='submit' icon='pi pi-save'>
                {isEditingPublishedPost ? 'Guardar cambios' : 'Publicar'}
              </Button>
            </FeatureToggle>
          </div>
        </form>
      </Card>
    </>
  );
};
