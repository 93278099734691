import React from 'react';
import { Blog } from '../pages/Blog/Blog';
import Analytics from '../pages/Analytics/Analytics';
import { Profile } from '../pages/Profile/Profile';
import { NavGroup } from '../types/navigation';
import { Login } from '../pages/NoAuthPages/Login';
import { PasswordRecovery } from '../pages/NoAuthPages/PasswordRecovery';
import { PasswordReset } from '../pages/NoAuthPages/PasswordReset';
import { ErrorPage } from '../pages/NoAuthPages/ErrorPage';
import { Users } from '../pages/Users/Users';
import { Employees } from '../pages/Employees/Employees';
import { Join } from '../pages/NoAuthPages/Join';
import { Exercises } from '../pages/Exercises/Exercises';
import { Routines } from '../pages/Routines/Routines';
import { Reservations } from '../pages/Reservations/Reservations';
import { Settings } from '../pages/Settings/Settings';
import { Promotions } from '../pages/Promotions/Promotions';
import { PrimeIcons } from 'primereact/api';
import { Notifications } from '../pages/Notifications/Notifications';
import { BusinessAnalytics } from '../pages/BusinessAnalytics/BusinessAnalytics';
import { Coupons } from '../pages/Coupons/Coupons';
import { Support } from '../pages/Support/Support';
import { ALL_FEATURES } from './features';
import { Notification } from '../types/notificationTypes';

export const PAGE_PRIORITY_ORDER = [
  '/reservations',
  '/routines',
  '/users',
  '/business-metrics',
  '/exercises',
  '/employees',
  '/analytics',
  '/user',
  '/blog',
  '/business-config',
  '/notifications',
  '/promotions',
  '/coupons',
  '/support',
];

export const FORCED_LOGOUT_ROUTES = ['/join/:userId', '/password-recovery/:token'];

export const getPages = (notifications: Notification[] | undefined, reservationTypeFeatures?: string[]): NavGroup[] => {
  const notificationsCount = notifications?.filter((notification) => !notification.isRead).length;
  const notificationsBadge = notificationsCount ? notificationsCount.toString() : undefined;
  return [
    {
      label: 'Web',
      elements: [
        {
          label: 'Analíticas',
          url: '/analytics',
          element: <Analytics />,
          icon: 'pi pi-chart-bar',
          featureCodes: [ALL_FEATURES.ANALYTICS],
        },
        {
          label: 'Blog',
          url: '/blog',
          element: <Blog />,
          hasNestedRoutes: true,
          icon: 'pi pi-book',
          featureCodes: [ALL_FEATURES.BLOG],
        },
        {
          label: 'Usuarios',
          url: '/users',
          element: <Users />,
          hasNestedRoutes: true,
          icon: 'pi pi-users',
          featureCodes: [ALL_FEATURES.EXTERNAL_USERS],
        },
      ],
    },
    {
      label: 'Empresa',
      elements: [
        {
          label: 'Métricas',
          url: '/business-metrics',
          element: <BusinessAnalytics />,
          icon: 'pi pi-chart-pie',
          featureCodes: [ALL_FEATURES.BUSINESS_ANALYTICS],
        },
        {
          label: 'Reservas',
          url: '/reservations',
          element: <Reservations></Reservations>,
          icon: 'pi pi-calendar',
          featureCodes: reservationTypeFeatures,
        },
        {
          label: 'Bonos',
          url: '/coupons',
          element: <Coupons></Coupons>,
          icon: 'pi pi-star',
          featureCodes: [ALL_FEATURES.BONUSES],
        },
        {
          label: 'Rutinas',
          url: '/routines',
          element: <Routines></Routines>,
          icon: 'pi pi-table',
          featureCodes: [ALL_FEATURES.ROUTINES],
        },
        {
          label: 'Ejercicios',
          url: '/exercises',
          element: <Exercises></Exercises>,
          icon: 'pi pi-hammer',
          featureCodes: [ALL_FEATURES.EXERCISES],
        },
        {
          label: 'Empleados',
          url: '/employees',
          element: <Employees />,
          hasNestedRoutes: true,
          icon: 'pi pi-building-columns',
          featureCodes: [ALL_FEATURES.USERS],
        },
        {
          label: 'Promociones',
          url: '/promotions',
          element: <Promotions></Promotions>,
          icon: 'pi pi-envelope',
          featureCodes: [ALL_FEATURES.PROMOTIONS],
        },
        {
          label: 'Configuración',
          url: '/business-config',
          element: <Settings></Settings>,
          icon: 'pi pi-cog',
          featureCodes: [ALL_FEATURES.BUSINESS_CONFIG],
        },
      ],
    },
    {
      label: 'Ajustes',
      elements: [
        {
          label: 'Perfil',
          url: '/user',
          element: <Profile />,
          icon: 'pi pi-user',
        },
        {
          label: 'Notificaciones',
          url: '/notifications',
          element: <Notifications />,
          icon: PrimeIcons.BELL,
          iconBadge: notificationsBadge,
        },
        {
          label: 'Soporte',
          url: '/support',
          element: <Support />,
          icon: PrimeIcons.QUESTION_CIRCLE,
        },
      ],
    },
  ];
};

export const LOGIN_PAGES: NavGroup[] = [
  {
    label: 'Páginas sin login',
    elements: [
      {
        url: '/',
        element: <Login />,
      },
      {
        url: '/password-recovery',
        element: <PasswordRecovery />,
      },
      {
        url: '/password-recovery/:token',
        element: <PasswordReset />,
      },
      {
        url: '/join/:userId',
        element: <Join />,
      },
    ],
  },
];

export const ERROR_PAGES: NavGroup[] = [
  {
    label: 'Páginas de error',
    elements: [
      {
        url: '/not-found',
        element: <ErrorPage />,
      },
      {
        url: '/expired-token',
        element: (
          <ErrorPage
            title='Enlace caducado'
            message='El enlace de recuperación tiene más de una hora de antigüedad y ha caducado. Puedes solicitar uno nuevo.'
          />
        ),
      },
      {
        url: '/invalid-token',
        element: (
          <ErrorPage title='Enlace inválido' message='El enlace de recuperación no existe. Puedes solicitar un enlace nuevo.' />
        ),
      },
      {
        url: '/invalid-invitation',
        element: <ErrorPage title='Enlace inválido' message='El enlace de invitación no es válido.' />,
      },
    ],
  },
];
