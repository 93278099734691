import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Avatar } from 'primereact/avatar';
import { TabView, TabPanel } from 'primereact/tabview';
import { Tag } from 'primereact/tag';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Calendar, Views, EventProps } from 'react-big-calendar';
import { getDay, parseISO, format, addDays, isAfter } from 'date-fns';
import { RoutinePanel } from '../../../components/RoutinePanel/RoutinePanel';
import { useResourcePanel } from '../../../hooks/useResourcePanel';
import { Routine } from '../../../types/Fisiofit/routine';
import { useClient } from '../../../hooks/useClient';
import { useOrganizationContext } from '../../../context/OrganizationContext';
import useSWR from 'swr';
import './UserProfile.scss';
import { useParams, useSearchParams } from 'react-router-dom';
import { ReservationPanel } from '../../../components/ReservationPanel/ReservationPanel';
import { EXTERNAL_USER_GENDERS, EXTERNAL_USER_STATUSES, ExternalUser, USER_ROUTINE_EVENTS } from '../../../types/externalUser';
import { PrimeIcons } from 'primereact/api';
import { getUserAvatarProps } from '../../../utils/avatarUtils';
import { calendarLocalizer, calendarMessages } from '../../../utils/calendarUtils';
import { Reservation, RESERVATION_STATUSES } from '../../../types/reservation';
import { useReservationTypes } from '../../../hooks/AppData/useReservationTypes';
import {
  checkIfCurrentUserMissedAttendance,
  getReservationEventColor,
  ReservationCalendarEvent,
} from '../../../components/ReservationCalendarEvent/ReservationCalendarEvent';
import { RoutineCalendarEvent } from '../../../components/RoutineCalendarEvent/RoutineCalendarEvent';
import { ExpenseCalendarEvent, getExpenseEventColor } from '../../../components/ExpenseCalendarEvent/ExpenseCalendarEvent';
import { CalendarToolbar } from '../../../components/CalendarToolbar/CalendarToolbar';
import {
  createReservationSummaryItems,
  createRoutineSummaryItems,
  createExpenseSummaryItems,
} from '../../../utils/calendarToolbarUtils';
import { CouponDisplay } from '../../../components/CouponDisplay/CouponDisplay';
import classNames from 'classnames';
import { ALL_FEATURES, SPECIAL_FEATURES } from '../../../config/features';
import { AllowedFeatures } from '../../../types/features';
import { Skeleton } from 'primereact/skeleton';
import { hasFeaturesPermission } from '../../../utils/featureUtils';
import { useAuthContext } from '../../../context/AuthContext';
import { DiagnosticField } from './DiagnosticField';
import { ExternalUserSignedDocuments } from '../../../components/ExternalUserSignedDocuments/ExternalUserSignedDocuments';
import { confirmDialog } from 'primereact/confirmdialog';
import { mutate } from 'swr';
import { UserProfileAppInvitation } from '../../../components/UserProfileAppInvitation/UserProfileAppInvitation';
import { EditExternalUserModal, EditExternalUserForm } from '../../../components/EditExternalUserModal/EditExternalUserModal';
import { useNavigateScroll } from '../../../hooks/useNavigateScroll';
import { getCommonRestrictions } from '../../Reservations/Reservations';

const getGenderDisplay = (gender: EXTERNAL_USER_GENDERS | undefined) => {
  if (!gender) return;
  if (gender === EXTERNAL_USER_GENDERS.MALE) return 'Hombre';
  if (gender === EXTERNAL_USER_GENDERS.FEMALE) return 'Mujer';
  return 'Otro';
};

const getRoutineEventDescription = (eventType: USER_ROUTINE_EVENTS, fieldsUpdated?: string[]) => {
  if (eventType === USER_ROUTINE_EVENTS.NEXT_ROUTINE) return 'Próxima rutina';
  if (eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE) return 'Rutina actual';
  if (eventType === USER_ROUTINE_EVENTS.PAST_ROUTINE) return 'Rutina pasada';
  if (eventType === USER_ROUTINE_EVENTS.EXPIRED_ROUTINE) return 'Rutina caducada';
  if (eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE_NOT_STARTED) return 'Comienza nueva rutina';
  if (eventType === USER_ROUTINE_EVENTS.UPDATE) {
    if (fieldsUpdated?.includes('exercises') && fieldsUpdated?.includes('duration')) return `Ejercicios y duración actualizados`;
    if (fieldsUpdated?.includes('exercises')) return `Ejercicios actualizados`;
    if (fieldsUpdated?.includes('duration')) return `Duración actualizada`;
    return 'Rutina modificada';
  }
  return '';
};

const getRoutineEventIcon = (eventType: USER_ROUTINE_EVENTS) => {
  if (eventType === USER_ROUTINE_EVENTS.NEXT_ROUTINE) return PrimeIcons.CALENDAR_PLUS;
  if (eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE) return PrimeIcons.CALENDAR;
  if (eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE_NOT_STARTED) return PrimeIcons.CALENDAR_PLUS;
  if (eventType === USER_ROUTINE_EVENTS.PAST_ROUTINE) return PrimeIcons.CALENDAR_MINUS;
  if (eventType === USER_ROUTINE_EVENTS.EXPIRED_ROUTINE) return PrimeIcons.CALENDAR_MINUS;
  if (eventType === USER_ROUTINE_EVENTS.UPDATE) return PrimeIcons.COG;
  return '';
};

export const getUserStatusDisplay = (status: EXTERNAL_USER_STATUSES) => {
  if (status === EXTERNAL_USER_STATUSES.ACTIVE) return 'Activo';
  if (status === EXTERNAL_USER_STATUSES.INACTIVE) return 'Inactivo';
  return '';
};

interface HistoryItem {
  id: string;
  date: Date | string;
  description: string;
  icon: string;
  hideViewButton?: boolean;
  isMinor?: boolean;
}

interface RoutineHistoryItem extends HistoryItem {
  eventType: USER_ROUTINE_EVENTS;
  routineId: string;
  fieldsUpdated?: string[];
}

export enum PaymentType {
  CASH = 'cash',
  COUPON_PURCHASE = 'coupon-purchase',
  COUPON_USE = 'coupon-use',
}

interface ExpenseEventDto {
  id: string;
  title: string;
  start: string;
  amount: number;
  paymentType: PaymentType;
  reservationId: string;
  employee?: {
    id: string;
    name: string;
    surnames: string;
  };
}

interface ExternalUserExpensesResponse {
  expenses: ExpenseEventDto[];
  totalAmount: number;
  totalCashAmount: number;
  totalBonusAmount: number;
}

const UserProfileSkeleton = () => {
  return (
    <div className='user-profile-page user-profile-skeleton'>
      <Card title='Información' className='profile-header'>
        <div className='user-profile'>
          <div className='profile-info'>
            <div className='profile-info-top'>
              <Skeleton shape='circle' className='profile-avatar-skeleton' />
              <div className='profile-info-top-container'>
                <div className='profile-info-name'>
                  <Skeleton width='200px' height='28px' />
                  <Skeleton width='100px' height='28px' />
                </div>
                <Skeleton width='180px' height='20px' />
              </div>
            </div>
            <Skeleton className='profile-info-line-skeleton' width='400px' height='20px' />
            <Skeleton className='profile-info-line-skeleton' width='400px' height='20px' />
            <Skeleton className='profile-info-line-skeleton' width='400px' height='20px' />
            <Skeleton className='profile-info-line-skeleton' width='400px' height='20px' />
            <Skeleton className='profile-info-line-skeleton' width='400px' height='20px' />
            <Skeleton className='profile-info-line-skeleton' width='400px' height='22px' />
            <Skeleton className='profile-info-line-skeleton' width='400px' height='20px' />
            <Skeleton className='profile-info-line-skeleton' width='400px' height='30px' />
            <Skeleton className='profile-info-line-skeleton' width='400px' height='20px' />
          </div>
          <div className='user-profile-extra'>
            <div>
              <Skeleton className='diagnostic-header-skeleton' width='120px' height='20px' />
              <Skeleton className='diagnostic-text-skeleton' height='300px' itemType='text' />
            </div>
          </div>
        </div>
        <Skeleton className='external-user-signed-documents-skeleton' width='100%' height='40px' />
        <div className='profile-actions'>
          <Skeleton width='120px' height='40px' />
          <Skeleton width='120px' height='40px' />
          <Skeleton width='120px' height='40px' />
        </div>
      </Card>

      <Card title='Actividad'>
        <div className='p-tabview tabview-skeleton'>
          <ul className='p-tabview-nav'>
            <li>
              <Skeleton className='tabview-nav-item-skeleton' width='80px' height='20px' />
            </li>
            <li>
              <Skeleton className='tabview-nav-item-skeleton' width='80px' height='20px' />
            </li>
            <li>
              <Skeleton className='tabview-nav-item-skeleton' width='80px' height='20px' />
            </li>
          </ul>
          <div className='p-tabview-panels'>
            <div className='calendar-container'>
              <div className='calendar-toolbar calendar-toolbar-skeleton'>
                <Skeleton width='200px' height='32px' />
                <div className='calendar-toolbar-buttons'>
                  <Skeleton width='90px' height='24px' />
                  <Skeleton width='90px' height='24px' />
                  <Skeleton width='90px' height='24px' />
                </div>
              </div>
              <Skeleton height='600px' />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export const UserProfile = () => {
  const navigate = useNavigateScroll();
  const { get, patch } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};
  const { externalUserId } = useParams();
  const [searchParams] = useSearchParams();
  const { reservationTypeFromId, reservationTypeFeatures, reservationTypes, getMainReservationType } = useReservationTypes();

  const { features, featuresLoading } = useAuthContext() ?? {};
  const userHasEditFeature = hasFeaturesPermission([ALL_FEATURES.EXTERNAL_USERS_WRITE], { features, featuresLoading });
  const userHasViewRoutinesFeature = hasFeaturesPermission([ALL_FEATURES.ROUTINES], { features, featuresLoading });
  const userHasCreateRoutinesFeature = hasFeaturesPermission([ALL_FEATURES.ROUTINES_WRITE], { features, featuresLoading });
  const userHasCreateFisioReservationsFeature = hasFeaturesPermission([SPECIAL_FEATURES.FISIO_RESERVATIONS_WRITE], {
    features,
    featuresLoading,
  });
  const hasViewFisioReservationsFeature = hasFeaturesPermission([SPECIAL_FEATURES.FISIO_RESERVATIONS_READ], {
    features,
    featuresLoading,
  });
  const hasAnyReservationTypeFeature = hasFeaturesPermission(reservationTypeFeatures ?? [], { features, featuresLoading });

  const {
    data: reservations,
    mutate: mutateReservations,
    isLoading: reservationsLoading,
  } = useSWR(
    organization && externalUserId ? `/organizations/${organization.id}/reservations?externalUserId=${externalUserId}` : null,
    get<Reservation[]>,
  );
  const {
    isEditing: editingReservation,
    setIsEditing: setEditingReservation,
    panelVisible: reservationPanelVisible,
    closePanel: closeReservationPanel,
    selectedResource: selectedReservation,
    openPanel: openReservationPanel,
  } = useResourcePanel(reservations);

  const externalUserFetcher = useCallback(
    (url: string) => {
      return get<ExternalUser>(url, {
        handlers: {
          [404]: () => {
            navigate(`/not-found`);
          },
        },
      });
    },
    [get, navigate],
  );
  const {
    data: user,
    mutate: mutateUser,
    isLoading: userLoading,
  } = useSWR(
    organization && externalUserId ? `/organizations/${organization.id}/external-users/${externalUserId}` : null,
    externalUserFetcher,
  );

  const { data: userExpenses, isLoading: expensesLoading } = useSWR(
    organization && externalUserId ? `/organizations/${organization.id}/external-users/${externalUserId}/expenses` : null,
    get<ExternalUserExpensesResponse>,
  );

  const {
    isEditing: editingRoutine,
    setIsEditing: setEditingRoutine,
    panelVisible: routinePanelVisible,
    closePanel: closeRoutinePanel,
    selectedResource: selectedRoutine,
    openPanel: openRoutinePanel,
  } = useResourcePanel<Routine>();
  const [overrideNextRoutine, setOverrideNextRoutine] = useState(false);

  const [activeTabIndex, setActiveTabIndex] = useState<number | undefined>(undefined);

  const mainReservationType = useMemo(() => {
    return getMainReservationType();
  }, [getMainReservationType]);

  const calendarRestrictions = useMemo(() => {
    return getCommonRestrictions(reservationTypes, null, mainReservationType ?? null);
  }, [reservationTypes, mainReservationType]);

  useEffect(() => {
    if (hasAnyReservationTypeFeature) {
      setActiveTabIndex(0);
    } else {
      setActiveTabIndex(1);
    }
  }, [hasAnyReservationTypeFeature]);

  useEffect(() => {
    const routineId = searchParams.get('routineId');
    if (!routinePanelVisible && routineId && organization?.id) {
      // Cambiar a la pestaña de rutinas (índice 1)
      setActiveTabIndex(1);
      // Cargar y abrir el panel con la rutina
      const loadRoutine = async () => {
        const routine = await get<Routine>(`/routines/${routineId}`);
        if (routine) {
          openRoutinePanel(false, routine);
        }
      };
      loadRoutine();
    }
  }, [searchParams, organization?.id, get, openRoutinePanel, routinePanelVisible]);

  const handleCloseRoutinePanel = () => {
    if (searchParams.get('routineId')) {
      navigate(`/users/profile/${externalUserId}`, { replace: true });
    }
    closeRoutinePanel();
    setOverrideNextRoutine(false);
  };

  const handleViewHistoryRoutine = async (item: RoutineHistoryItem) => {
    if (!organization) return;
    if (item.routineId === user?.currentRoutine?.id) {
      openRoutinePanel(false, user?.currentRoutine);
      return;
    }
    if (item.routineId === user?.currentRoutine?.nextRoutine?.id) {
      openRoutinePanel(false, user?.currentRoutine);
      setOverrideNextRoutine(true);
      return;
    }
    const historyRoutine = await get<Routine>(`/routines/${item.routineId}`);

    openRoutinePanel(false, historyRoutine);
  };

  const minTime = new Date();
  minTime.setHours(7, 0, 0);
  const maxTime = new Date();
  maxTime.setHours(22, 0, 0);

  const defaultDate = new Date();

  const eventPropGetter = useCallback(
    (reservation: Reservation) => {
      const reservationType = reservationTypeFromId(reservation.reservationTypeId);
      const { currentUserDidNotAttend } = checkIfCurrentUserMissedAttendance(reservation, externalUserId, reservationType);
      const color = getReservationEventColor(reservation, reservationType, currentUserDidNotAttend);
      const isInQueue = reservation.externalUserStatus === RESERVATION_STATUSES.IN_QUEUE;
      return {
        style: {
          backgroundColor: `var(--${color}-100)`,
          border: `1px solid var(--${color}-300)`,
          opacity: isInQueue ? 0.6 : 1,
        },
      };
    },
    [reservationTypeFromId, externalUserId],
  );

  const dayPropGetter = useCallback((date: Date) => {
    const day = getDay(date);
    if (day === 0 || day === 6) {
      return {
        className: 'disabled-day',
        style: { backgroundColor: '#f0f0f0', color: '#ccc' },
      };
    }
    return {};
  }, []);

  const expenseEventPropGetter = useCallback((event: ExpenseEventDto) => {
    const color = getExpenseEventColor(event.paymentType);
    return {
      style: {
        backgroundColor: `var(--${color}-100)`,
        border: `1px solid var(--${color}-300)`,
      },
    };
  }, []);

  const renderCurrentRoutineSummaryItem = () => {
    return (
      <>
        <i
          className={classNames('pi', {
            'pi-check-circle': user?.currentRoutine,
            'pi-times-circle': !user?.currentRoutine,
            success: user?.currentRoutine,
            error: !user?.currentRoutine,
          })}
        />
        <span>{user?.currentRoutine ? 'Rutina activa' : 'Sin rutina'}</span>
      </>
    );
  };

  const allowedRoutineFeatures: AllowedFeatures = {
    edit: [ALL_FEATURES.ROUTINES_WRITE],
    delete: [ALL_FEATURES.ROUTINES_WRITE],
    create: [ALL_FEATURES.ROUTINES_WRITE],
  };

  const [isTogglingStatus, setIsTogglingStatus] = useState(false);

  const handleToggleStatus = async () => {
    if (!organization?.id || !externalUserId) return;

    setIsTogglingStatus(true);
    const isActive = user?.status === EXTERNAL_USER_STATUSES.ACTIVE;
    const action = isActive ? 'baja' : 'alta';

    await patch(`/external-users/${externalUserId}/toggle-status`, {
      successMessage: {
        summary: `Usuario dado de ${action}`,
        detail: `El usuario ha sido dado de ${action} correctamente`,
      },
      errorMessages: {
        summary: `Error al dar de ${action} al usuario`,
        defaultDetail: `No se ha podido dar de ${action} al usuario`,
      },
      handlers: {
        defaultSuccess: () => {
          // Actualizamos todos los datos del usuario
          mutateUser();
          mutateReservations();
          mutate(`/organizations/${organization.id}/external-users/${externalUserId}/expenses`);
        },
      },
    });

    setIsTogglingStatus(false);
  };

  const showToggleStatusDialog = () => {
    const isActive = user?.status === EXTERNAL_USER_STATUSES.ACTIVE;
    const action = isActive ? 'baja' : 'alta';
    const futureReservations = reservations?.filter(
      (reservation) =>
        reservation.externalUserStatus === RESERVATION_STATUSES.CONFIRMED &&
        isAfter(new Date(reservation.localStartDate), new Date()),
    );
    const queuedReservations = reservations?.filter(
      (reservation) => reservation.externalUserStatus === RESERVATION_STATUSES.IN_QUEUE,
    );
    const hasNextRoutine = user?.currentRoutine?.nextRoutine;
    const hasActiveCoupon = user?.userCoupon && user.userCoupon.usesRemaining > 0;

    const hasAnyResources = futureReservations?.length || user?.currentRoutine || hasActiveCoupon || queuedReservations?.length;

    const futureReservationsText = futureReservations?.length ? (
      <>
        - Se cancelarán sus <b>{futureReservations.length > 1 ? `${futureReservations.length} reservas` : 'una reserva'}</b>.
        <br />
      </>
    ) : (
      ''
    );
    const queuedReservationsText = queuedReservations?.length ? (
      <>
        - Se le quitará de la cola de{' '}
        <b>{queuedReservations.length > 1 ? `${queuedReservations.length} reservas` : 'una reserva'}</b>.
        <br />
      </>
    ) : (
      ''
    );
    const routinesText = user?.currentRoutine ? (
      <>
        - Se <b>terminará su rutina</b> actual{hasNextRoutine ? ` y se borrará su próxima rutina` : ''}.<br />
      </>
    ) : (
      ''
    );
    const couponText =
      hasActiveCoupon && user?.userCoupon ? (
        <>
          - Se cancelará su bono con{' '}
          <b>{user?.userCoupon.usesRemaining > 1 ? `${user?.userCoupon.usesRemaining} usos restantes` : '1 uso restante'}</b>
          .
          <br />
        </>
      ) : (
        ''
      );

    const resourcesText = (
      <>
        {futureReservationsText}
        {queuedReservationsText}
        {routinesText}
        {couponText}
        {hasAnyResources ? (
          <>
            <br />
          </>
        ) : (
          <></>
        )}
      </>
    );

    confirmDialog({
      message: (
        <>
          ¿Estás seguro de que quieres dar de {action} a{' '}
          <b>
            {user?.name} {user?.surnames}
          </b>
          ?
          {isActive ? (
            <>
              <br />
              <br />
              {resourcesText}
              No podrá acceder a la aplicación hasta que se le dé de alta de nuevo.
            </>
          ) : (
            ''
          )}
        </>
      ),
      header: `Confirmar dar de ${action}`,
      icon: PrimeIcons.EXCLAMATION_CIRCLE,
      acceptLabel: `Sí, dar de ${action}`,
      rejectLabel: 'No, volver',
      accept: handleToggleStatus,
      acceptClassName: classNames('p-button-outlined', {
        'p-button-danger': isActive,
        'p-button-success': !isActive,
      }),
      defaultFocus: 'reject',
      draggable: false,
      resizable: false,
    });
  };

  const [showEditModal, setShowEditModal] = useState(false);

  const handleSaveUser = async (updatedUser: EditExternalUserForm) => {
    if (!organization?.id || !externalUserId) return;
    await patch(`/external-users/${externalUserId}`, {
      body: updatedUser,
      successMessage: {
        summary: 'Usuario actualizado',
        detail: 'El usuario se ha actualizado correctamente',
      },
      errorMessages: {
        summary: 'Error al actualizar el usuario',
        defaultDetail: 'No se ha podido actualizar el usuario',
        USER_EMAIL_EXISTS: 'Ya existe un usuario con ese correo electrónico',
      },
      handlers: {
        defaultSuccess: () => {
          mutateUser();
        },
      },
    });
  };

  if (userLoading || reservationsLoading || expensesLoading || organizationLoading) {
    return <UserProfileSkeleton />;
  }

  return (
    <div className='user-profile-page'>
      <Card title='Información' className='profile-header'>
        <div className='user-profile'>
          <div className='profile-info'>
            <div className='profile-info-top'>
              <Avatar {...getUserAvatarProps(user)} shape='circle' size='xlarge' />
              <div className='profile-info-top-container'>
                <div className='profile-info-name'>
                  <h2>
                    {user?.name} {user?.surnames}
                  </h2>
                  <Tag
                    value={getUserStatusDisplay(user?.status ?? EXTERNAL_USER_STATUSES.INACTIVE)}
                    severity={user?.status === EXTERNAL_USER_STATUSES.ACTIVE ? 'success' : 'danger'}
                  />
                  {userHasEditFeature && (
                    <Button
                      icon='pi pi-pencil'
                      rounded
                      text
                      onClick={() => setShowEditModal(true)}
                      className='edit-button-medium'
                    />
                  )}
                </div>
                {(user?.age || user?.gender) && (
                  <div className='profile-info-age-gender'>
                    <span>{user?.age ? `${user.age} años` : ''}</span>
                    {user?.age && user?.gender && <span className='separator'>•</span>}
                    <span>{user?.gender ? getGenderDisplay(user.gender) : ''}</span>
                  </div>
                )}
              </div>
            </div>
            <p>
              <i className='pi pi-calendar'></i> <strong>Cliente desde:</strong>{' '}
              {user?.createdAt ? format(new Date(user.createdAt), "d 'de' MMMM 'de' yyyy") : ''}
            </p>
            {(user?.deactivationDate || user?.reactivationDate) && (
              <p>
                <i className={user?.deactivationDate ? PrimeIcons.CALENDAR_MINUS : PrimeIcons.CALENDAR_PLUS}></i>{' '}
                <strong>Fecha de {user?.deactivationDate ? 'baja' : 'alta'}:</strong>{' '}
                {user?.deactivationDate
                  ? format(new Date(user?.deactivationDate), "d 'de' MMMM 'de' yyyy")
                  : user?.reactivationDate
                    ? format(new Date(user.reactivationDate), "d 'de' MMMM 'de' yyyy")
                    : ''}
              </p>
            )}
            {user?.latestReservationDate && (
              <p>
                <i className='pi pi-clock'></i> <strong>Última visita:</strong>{' '}
                {format(new Date(user?.latestReservationDate), "d 'de' MMMM 'de' yyyy")}
              </p>
            )}
            <p>
              <i className='pi pi-envelope'></i> <strong>Email:</strong> {user?.email || '-'}
            </p>
            <p>
              <i className='pi pi-phone'></i> <strong>Teléfono:</strong> {user?.phone || '-'}
            </p>
            <p>
              <i className='pi pi-id-card'></i> <strong>DNI:</strong> {user?.nationalId || '-'}
            </p>
            <p>
              <i className='pi pi-map-marker'></i> <strong>Dirección:</strong> {user?.address || '-'}
            </p>
            {user?.profession && (
              <p>
                <i className='pi pi-briefcase'></i> <strong>Profesión:</strong> {user.profession}
              </p>
            )}

            {user?.userCoupon && <CouponDisplay coupon={user.userCoupon} />}
            <p>
              <i className='pi pi-building'></i> <strong>Sucursal:</strong>{' '}
              {organization?.branches?.find((branch) => branch.id === user?.branchId)?.name || '-'}
            </p>

            <div className='profile-info-item'>
              <i className='pi pi-user'></i> <strong>Supervisor:</strong>{' '}
              {user?.supervisor ? (
                <span className='supervisor-info' style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Avatar {...getUserAvatarProps(user.supervisor)} shape='circle' size='normal' />
                  <span>{`${user.supervisor.name} ${user.supervisor.surnames}`}</span>
                </span>
              ) : (
                '-'
              )}
            </div>

            <UserProfileAppInvitation user={user} hasEditFeature={userHasEditFeature} />
          </div>
          <div className='user-profile-extra'>
            {organization?.config?.hasUserDiagnosticField && (
              <DiagnosticField
                diagnostic={user?.diagnostic}
                externalUserId={externalUserId}
                hasEditFeature={userHasEditFeature}
              />
            )}
          </div>
        </div>
        <ExternalUserSignedDocuments externalUser={user} />
        <div className='profile-actions'>
          {userHasCreateFisioReservationsFeature && (
            <Button
              outlined
              label='Crear cita'
              icon='pi pi-calendar-plus'
              onClick={() => {
                openReservationPanel(false);
              }}
            />
          )}
          {userHasViewRoutinesFeature && (user?.currentRoutine || userHasCreateRoutinesFeature) && (
            <Button
              outlined
              label={user?.currentRoutine ? 'Ver rutina' : 'Crear rutina'}
              icon='pi pi-list'
              onClick={() => {
                const { name, surnames, email, id, avatarUrl } = user ?? {};
                const panelRoutine =
                  user?.currentRoutine ??
                  ({
                    name: `Rutina de ${name} ${surnames}`,
                    organizationId: organization?.id,
                    externalUser: {
                      name,
                      surnames,
                      email,
                      id,
                      avatarUrl,
                    },
                  } as Routine);
                openRoutinePanel(false, panelRoutine);
              }}
            />
          )}
          {userHasEditFeature && (
            <Button
              label={user?.status === EXTERNAL_USER_STATUSES.ACTIVE ? 'Dar de baja' : 'Dar de alta'}
              icon='pi pi-user-minus'
              className={classNames('p-button-text', {
                'p-button-danger': user?.status === EXTERNAL_USER_STATUSES.ACTIVE,
                'p-button-success': user?.status === EXTERNAL_USER_STATUSES.INACTIVE,
              })}
              onClick={showToggleStatusDialog}
              loading={isTogglingStatus}
            />
          )}
        </div>
      </Card>

      {(hasAnyReservationTypeFeature || userHasViewRoutinesFeature) && (
        <Card className='user-profile-activity-card' title='Actividad'>
          <TabView activeIndex={activeTabIndex ?? 0} onTabChange={(e) => setActiveTabIndex(e.index)}>
            {hasAnyReservationTypeFeature && (
              <TabPanel header='Reservas'>
                <div className='calendar-container'>
                  <Calendar
                    className='user-reservations-calendar'
                    dayLayoutAlgorithm='no-overlap'
                    localizer={calendarLocalizer}
                    events={reservations ?? []}
                    startAccessor={(reservation) => parseISO(reservation.localStartDate)}
                    endAccessor={(reservation) => parseISO(reservation.localEndDate)}
                    step={30}
                    timeslots={1}
                    messages={calendarMessages}
                    views={[Views.MONTH, Views.WEEK, Views.DAY]}
                    max={maxTime}
                    min={minTime}
                    defaultDate={defaultDate}
                    scrollToTime={(() => {
                      const adjustedTime = new Date(new Date().setHours(new Date().getHours() - 1, 0, 0, 0));
                      return adjustedTime < minTime ? minTime : adjustedTime;
                    })()}
                    eventPropGetter={eventPropGetter}
                    components={{
                      event: (props: EventProps<Reservation>) => {
                        const reservationType = reservationTypeFromId(props.event.reservationTypeId);
                        if (!reservationType) return null;
                        return (
                          <ReservationCalendarEvent
                            {...props}
                            reservationType={reservationType}
                            showAttendance
                            externalUserId={externalUserId}
                          />
                        );
                      },
                      toolbar: (props) => (
                        <CalendarToolbar
                          {...props}
                          summaryItems={createReservationSummaryItems(reservations ?? [])}
                          showTodayButton={false}
                          showViewSelector={false}
                        />
                      ),
                      showMore: ({ count }) => (
                        <div className='rbc-show-more' style={{ cursor: 'default' }}>
                          +{count} más
                        </div>
                      ),
                    }}
                    onSelectEvent={(event) => {
                      openReservationPanel(false, event);
                    }}
                    dayPropGetter={dayPropGetter}
                  />
                </div>
              </TabPanel>
            )}
            {userHasViewRoutinesFeature && (
              <TabPanel header='Rutinas'>
                <div className='calendar-container'>
                  <Calendar
                    dayLayoutAlgorithm='no-overlap'
                    localizer={calendarLocalizer}
                    events={(() => {
                      if (!user?.routineHistory) return [];

                      // Primero, filtramos y ordenamos el historial para tener solo las rutinas principales
                      const mainRoutines = user.routineHistory
                        .filter(
                          (item) =>
                            item.eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE ||
                            item.eventType === USER_ROUTINE_EVENTS.NEXT_ROUTINE ||
                            item.eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE_NOT_STARTED ||
                            item.eventType === USER_ROUTINE_EVENTS.PAST_ROUTINE ||
                            item.eventType === USER_ROUTINE_EVENTS.EXPIRED_ROUTINE,
                        )
                        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

                      // Creamos los eventos de rutinas principales
                      const routineEvents = mainRoutines.map((item) => {
                        const startDate = new Date(item.date);
                        // Have to add one day so that it shows as inclusive in react big calendar
                        const endDate = addDays(startDate, item.duration ? item.duration + 1 : 0);
                        return {
                          id: item.routineId,
                          title: getRoutineEventDescription(item.eventType),
                          start: startDate,
                          end: endDate,
                          routineId: item.routineId,
                          eventType: item.eventType,
                        };
                      });

                      /* const updates = user.routineHistory.filter((item) => item.eventType === USER_ROUTINE_EVENTS.UPDATE);
                       */
                      /* 
                  const updateEvents = updates.map((item) => ({
                    id: `${item.routineId}-update-${item.date}`,
                    title: getRoutineEventDescription(item.eventType, item.fieldsUpdated),
                    start: new Date(item.date),
                    end: new Date(item.date),
                    routineId: item.routineId,
                    eventType: item.eventType,
                    fieldsUpdated: item.fieldsUpdated,
                  })); */

                      return [...routineEvents /* ...updateEvents */];
                    })()}
                    startAccessor='start'
                    endAccessor='end'
                    step={30}
                    timeslots={1}
                    messages={calendarMessages}
                    views={[Views.MONTH]}
                    defaultView={Views.MONTH}
                    toolbar={true}
                    view={Views.MONTH}
                    onView={() => {}}
                    max={maxTime}
                    min={minTime}
                    defaultDate={defaultDate}
                    scrollToTime={(() => {
                      const adjustedTime = new Date(new Date().setHours(new Date().getHours() - 1, 0, 0, 0));
                      return adjustedTime < minTime ? minTime : adjustedTime;
                    })()}
                    eventPropGetter={(event) => {
                      let color = 'primary';
                      if (event.eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE) color = 'green';
                      if (event.eventType === USER_ROUTINE_EVENTS.NEXT_ROUTINE) color = 'blue';
                      if (event.eventType === USER_ROUTINE_EVENTS.PAST_ROUTINE) color = 'teal';
                      if (event.eventType === USER_ROUTINE_EVENTS.EXPIRED_ROUTINE) color = 'pink';
                      if (event.eventType === USER_ROUTINE_EVENTS.UPDATE) color = 'yellow';

                      return {
                        style: {
                          backgroundColor: `var(--${color}-100)`,
                          border: `1px solid var(--${color}-300)`,
                        },
                      };
                    }}
                    components={{
                      event: (props) => <RoutineCalendarEvent {...props} />,
                      toolbar: (props) => (
                        <CalendarToolbar
                          {...props}
                          summaryItems={createRoutineSummaryItems(
                            user?.routineHistory?.map((item) => ({
                              eventType: item.eventType,
                            })) ?? [],
                          )}
                          customSummaryItems={[renderCurrentRoutineSummaryItem()]}
                          showViewSelector={false}
                          showTodayButton={false}
                        />
                      ),
                      showMore: ({ count }) => (
                        <div className='rbc-show-more' style={{ cursor: 'default' }}>
                          +{count} más
                        </div>
                      ),
                    }}
                    onSelectEvent={(event: any) => {
                      handleViewHistoryRoutine({
                        id: event.id,
                        date: event.start.toISOString(),
                        description: event.title,
                        icon: getRoutineEventIcon(event.eventType),
                        eventType: event.eventType,
                        routineId: event.routineId,
                        fieldsUpdated: event.fieldsUpdated,
                      });
                    }}
                    dayPropGetter={dayPropGetter}
                  />
                </div>
              </TabPanel>
            )}
            {hasViewFisioReservationsFeature && (
              <TabPanel header='Pagos'>
                <div className='calendar-container'>
                  <Calendar
                    dayLayoutAlgorithm='no-overlap'
                    localizer={calendarLocalizer}
                    events={userExpenses?.expenses ?? []}
                    startAccessor={(event) => parseISO(event.start)}
                    endAccessor={(event) => parseISO(event.start)}
                    step={30}
                    timeslots={1}
                    messages={calendarMessages}
                    views={[Views.MONTH]}
                    defaultView={Views.MONTH}
                    toolbar={true}
                    view={Views.MONTH}
                    onView={() => {}}
                    max={maxTime}
                    min={minTime}
                    defaultDate={defaultDate}
                    eventPropGetter={expenseEventPropGetter}
                    components={{
                      event: (props) => <ExpenseCalendarEvent {...props} />,
                      toolbar: (props) => (
                        <CalendarToolbar
                          {...props}
                          summaryItems={createExpenseSummaryItems(userExpenses?.expenses ?? [], {
                            totalAmount: userExpenses?.totalAmount ?? 0,
                            totalCashAmount: userExpenses?.totalCashAmount ?? 0,
                            totalBonusAmount: userExpenses?.totalBonusAmount ?? 0,
                          })}
                          showViewSelector={false}
                          showTodayButton={false}
                        />
                      ),
                      showMore: ({ count }) => (
                        <div className='rbc-show-more' style={{ cursor: 'default' }}>
                          +{count} más
                        </div>
                      ),
                    }}
                    dayPropGetter={dayPropGetter}
                  />
                </div>
              </TabPanel>
            )}
          </TabView>
        </Card>
      )}

      <RoutinePanel
        resource={selectedRoutine}
        visible={routinePanelVisible}
        onHide={handleCloseRoutinePanel}
        onIsEditingChange={(value) => setEditingRoutine(value)}
        onSaveSuccess={mutateUser}
        isEditing={editingRoutine}
        isDeleting={false}
        overrideNextRoutine={overrideNextRoutine}
        creatingFromUserProfile
        allowedFeatures={allowedRoutineFeatures}
      />

      <ReservationPanel
        calendarRestrictions={calendarRestrictions}
        visible={reservationPanelVisible}
        onHide={() => {
          closeReservationPanel();
        }}
        onIsEditingChange={(value) => setEditingReservation(value)}
        initialHours={undefined}
        clearInitialTemporaryEvent={() => {}}
        isEditing={editingReservation}
        resource={selectedReservation}
        isDeleting={false}
        externalUserProfileId={externalUserId}
        createModeReservationTypeId={selectedReservation ? undefined : mainReservationType?.id}
        preselectedExternalUser={selectedReservation ? undefined : user}
        mutateReservations={mutateReservations}
      />

      {user && (
        <EditExternalUserModal
          visible={showEditModal}
          onHide={() => setShowEditModal(false)}
          user={user}
          onSave={handleSaveUser}
        />
      )}
    </div>
  );
};

export default UserProfile;
