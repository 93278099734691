import React, { useEffect } from 'react';
import { Sidebar } from './layout/Sidebar/Sidebar';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuthContext } from './context/AuthContext';
import { DragProvider } from './context/DragContext';
import { WebsiteProvider } from './context/WebsiteContext';
import { LocaleProvider } from './context/LocaleContext';
import { PrimeReactProvider } from 'primereact/api';
import { ERROR_PAGES, LOGIN_PAGES, PAGES } from './config/pages';
import { NotificationProvider } from './context/NotificationContext';
import { SWRConfig } from 'swr';
import { getRouterPath } from './utils/routerUtils';
import { NavGroup } from './types/navigation';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import { LoadingScreen } from './components/LoadingScreen/LoadingScreen';

const isNativePlatform = Capacitor.isNativePlatform();

const renderRoutesGroup = (routesGroup: NavGroup[]) => {
  return routesGroup.flatMap((navGroup) => {
    return navGroup.elements.map(({ element, url, hasNestedRoutes }) => (
      <Route key={url} path={getRouterPath(url, hasNestedRoutes)} element={element} />
    ));
  });
};

const App = () => {
  const { user, isExpectedLoggedIn } = useAuthContext() ?? {};

  const notLoggedIn = !user && !isExpectedLoggedIn;
  useEffect(() => {
    if (notLoggedIn || user) SplashScreen.hide();
  }, [notLoggedIn, user]);

  // TODO creo que podemos quitar la condicion native, probar en mobile
  if (isExpectedLoggedIn && !isNativePlatform) {
    return <LoadingScreen />;
  }

  const renderPagesWrapper = () => {
    if (notLoggedIn) return <Navigate to={'/'} replace />;
    return (
      <>
        <Sidebar />
        <main className='content'>
          <Outlet />
        </main>
      </>
    );
  };

  const renderLoginPagesWrapper = () => {
    // TODO navigate to first available feature instead of analytics
    if (user) return <Navigate to={'/analytics'} replace />;
    return <Outlet />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route element={renderLoginPagesWrapper()}>{renderRoutesGroup(LOGIN_PAGES)}</Route>
        <Route element={renderPagesWrapper()}>{renderRoutesGroup(PAGES)}</Route>
        <Route>{renderRoutesGroup(ERROR_PAGES)}</Route>
        <Route path={'/*'} element={<Navigate to={'not-found'} replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default () => (
  <SWRConfig
    value={{
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }}
  >
    <PrimeReactProvider>
      <NotificationProvider>
        <AuthProvider>
          <WebsiteProvider>
            <LocaleProvider>
              <DragProvider>
                <App />
              </DragProvider>
            </LocaleProvider>
          </WebsiteProvider>
        </AuthProvider>
      </NotificationProvider>
    </PrimeReactProvider>
  </SWRConfig>
);
