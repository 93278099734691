const css = `.external-user-panel .external-user-form .avatar-container {
  display: flex;
  align-items: center;
  gap: 16px;
}
.external-user-panel .external-user-form .preview-avatar {
  border: 1px solid var(--surface-border);
  background-color: var(--surface-ground);
}

.consent-dialog {
  width: 90vw;
  max-width: 800px;
}
.consent-dialog .consent-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.consent-dialog .consent-content .pdf-viewer {
  height: 400px;
  border: 1px solid var(--primary200);
  border-radius: 4px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}
.consent-dialog .consent-content .pdf-viewer .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.consent-dialog .consent-content .pdf-viewer .pdf-controls {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 16px;
}
.consent-dialog .consent-content .pdf-viewer .pdf-controls span {
  min-width: 100px;
  text-align: center;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvRXh0ZXJuYWxVc2VyUGFuZWwiLCJzb3VyY2VzIjpbIkV4dGVybmFsVXNlclBhbmVsLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUk7RUFDRTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBOzs7QUFLTjtFQUNFO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLmV4dGVybmFsLXVzZXItcGFuZWwge1xuICAuZXh0ZXJuYWwtdXNlci1mb3JtIHtcbiAgICAuYXZhdGFyLWNvbnRhaW5lciB7XG4gICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICAgIGdhcDogMTZweDtcbiAgICB9XG5cbiAgICAucHJldmlldy1hdmF0YXIge1xuICAgICAgYm9yZGVyOiAxcHggc29saWQgdmFyKC0tc3VyZmFjZS1ib3JkZXIpO1xuICAgICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tc3VyZmFjZS1ncm91bmQpO1xuICAgIH1cbiAgfVxufVxuXG4uY29uc2VudC1kaWFsb2cge1xuICB3aWR0aDogOTB2dztcbiAgbWF4LXdpZHRoOiA4MDBweDtcblxuICAuY29uc2VudC1jb250ZW50IHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgZ2FwOiAyNHB4O1xuXG4gICAgLnBkZi12aWV3ZXIge1xuICAgICAgaGVpZ2h0OiA0MDBweDtcbiAgICAgIGJvcmRlcjogMXB4IHNvbGlkIHZhcigtLXByaW1hcnkyMDApO1xuICAgICAgYm9yZGVyLXJhZGl1czogNHB4O1xuICAgICAgb3ZlcmZsb3c6IGF1dG87XG4gICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgICBwYWRkaW5nOiAxNnB4O1xuXG4gICAgICAucmVhY3QtcGRmX19Eb2N1bWVudCB7XG4gICAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgICB9XG5cbiAgICAgIC5wZGYtY29udHJvbHMge1xuICAgICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgICBnYXA6IDE2cHg7XG4gICAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgICAgIG1hcmdpbi10b3A6IDE2cHg7XG5cbiAgICAgICAgc3BhbiB7XG4gICAgICAgICAgbWluLXdpZHRoOiAxMDBweDtcbiAgICAgICAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gICAgICAgIH1cbiAgICAgIH1cbiAgICB9XG4gIH1cbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
