import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import './PostEditor.scss';
import { FileUpload } from '../../../components/FileUpload/FileUpload';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { useWebsiteContext } from '../../../context/WebsiteContext';
import { EMPTY_POST } from './const';
import { useForm } from '../../../hooks/useForm';
import { RequestErrorMessages, useClient } from '../../../hooks/useClient';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { RichTextEditor } from '../../../components/RichTextEditor/RichTextEditor';
import { Image } from 'primereact/image';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { NotificationMessage } from '../../../types/notificationMessage';
import { PrimeIcons } from 'primereact/api';
import { confirmDialog } from 'primereact/confirmdialog';
import { classNames } from 'primereact/utils';
import { Skeleton } from 'primereact/skeleton';
import { IMAGE_PLACEHOLDER } from '../../../const/images';
import {
  DESCRIPTION_MAX_CHAR,
  POST_EDITOR_VALIDATORS,
  TITLE_MAX_CHAR,
  URL_MAX_CHAR,
} from './validators';
import { FormElementError } from '../../../components/FormElementError/FormElementError';
import { CharacterCount } from '../../../components/CharacterCount/CharacterCount';
import { Tooltip } from 'react-tooltip';
import {
  resourceDeleter,
  resourceMutator,
} from '../../../utils/resourceMutation';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { LocalePost, Post } from '../../../types/post';
import { usePageLocaleContext } from '../../../context/PageLocaleContext';
import { getLocalePost } from '../../../utils/postUtils';
import { LOCALES } from '../../../config/locales';
import { PageLocalePicker } from '../../../components/PageLocalePicker.tsx/PageLocalePicker';
import { removeFroalaHtml } from '../../../utils/textUtils';

const getPostDto = (form: LocalePost, locale: LOCALES) => {
  const {
    readTime,
    locale: _,
    image: { alt },
    content,
    ...restForm
  } = form;

  return {
    ...restForm,
    locale,
    image: { alt },
    content: removeFroalaHtml(content),
  };
};

interface Props {
  isEditing?: boolean;
}

export const PostEditor = ({ isEditing = false }: Props) => {
  const { pageLocale, setPageLocale } = usePageLocaleContext() ?? {};
  const client = useClient();
  const navigate = useNavigate();
  const { website } = useWebsiteContext() ?? {};
  const { id } = useParams();
  const [imageFile, setImageFile] = useState<File>();
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (!pageLocale && website) {
      setPageLocale?.(website?.locales[0]);
    }
  }, [pageLocale, setPageLocale, website]);

  const fetcher = async (url: string) => {
    return await client.get<Post>(url, {
      errorMessages: {
        summary: 'Error buscando artículo',
        defaultDetail: 'No sé pudo encontrar el artículo',
        [404]: 'El artículo indicado no existe',
      },
      handlers: {
        defaultError: () => {
          navigate('../');
        },
      },
    });
  };

  const {
    data,
    isLoading,
    error,
    mutate: mutatePostData,
  } = useSWR(
    isEditing && website ? `/websites/${website?.id}/posts/${id}` : null,
    fetcher
  );
  const savedLocalePost = useMemo(() => {
    if (!data) return undefined;
    return getLocalePost(data, pageLocale);
  }, [data, pageLocale]);

  const {
    form,
    setForm,
    setFormField,
    isSaving,
    setIsSaving,
    hasChanged,
    validationErrors,
    validate,
  } = useForm<LocalePost>(
    savedLocalePost ?? EMPTY_POST,
    false,
    POST_EDITOR_VALIDATORS
  );

  const savePost = async () => {
    const errors = validate();
    if (!form || errors || !pageLocale) return;
    setIsSaving(true);
    const formData = new FormData();
    const postDto = getPostDto(form, pageLocale);
    formData.append('data', JSON.stringify(postDto));
    if (imageFile) {
      formData.append('image', imageFile);
    }
    const url = `/websites/${website?.id}/posts${isEditing ? `/${id}` : ''}`;

    const { errorMessages, successMessage } = getToastMessages(isEditing);

    const savedPostData = await (isEditing ? client.put : client.post)<Post>(
      url,
      {
        body: formData,
        successMessage,
        errorMessages,
      }
    );
    setIsSaving(false);

    if (!savedPostData) return;
    const newLocalePost = getLocalePost(savedPostData, pageLocale);
    if (!newLocalePost) return;
    setForm(newLocalePost);
    mutatePostData(savedPostData, false);
    if (!isEditing) {
      navigate(`/blog/post/${savedPostData.id}`);
      mutate(`/websites/${website?.id}/posts`, undefined, false);
    } else {
      mutate(`/websites/${website?.id}/posts`, resourceMutator(savedPostData));
    }
  };

  const deletePost = async () => {
    setIsDeleting(true);
    const url = `/websites/${website?.id}/posts/${id}/${pageLocale}`;
    await client.delete<Post>(url, {
      errorMessages: {
        summary: 'No se pudo eliminar el artículo',
        defaultDetail: 'Hubo un error al intentar borrar el artículo',
      },
      successMessage: {
        summary: 'Artículo eliminado',
        detail: 'El artículo se ha eliminado correctamente',
      },
    });
    setIsDeleting(false);
    mutate(`/websites/${website?.id}/posts`, resourceDeleter(id ?? ''));
    navigate('../');
  };

  const handleDelete = () => {
    confirmDialog({
      message: 'Seguro que deseas borrar este artículo?',
      header: 'Borrar artículo',
      icon: PrimeIcons.EXCLAMATION_CIRCLE,
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: deletePost,
      acceptLabel: 'Sí',
    });
  };

  const submitPost = async (e: FormEvent) => {
    e.preventDefault();
    savePost();
  };

  const dataLoading = isLoading || (isEditing && !data);

  return (
    <>
      <PageLocalePicker />
      <Card>
        <form
          className={classNames('post-editor', {
            'form-loading': dataLoading || isSaving || isDeleting,
          })}
          onSubmit={submitPost}
        >
          <div className='form-field full-width'>
            <label htmlFor='title'>Título</label>
            <InputText
              className={classNames({ 'p-invalid': validationErrors?.title })}
              id='title'
              name='title'
              placeholder={`Título (máximo ${TITLE_MAX_CHAR} caracteres)`}
              value={form?.title}
              onChange={(e: any) =>
                setFormField({
                  title: e.target.value,
                })
              }
            />
            <FormElementError>{validationErrors?.title}</FormElementError>
            <CharacterCount value={form?.title} maxChar={TITLE_MAX_CHAR} />
          </div>

          <div className='form-field full-width'>
            <label htmlFor='description'>Descripción</label>
            <InputTextarea
              id='description'
              name='description'
              className={classNames({
                'p-invalid': validationErrors?.description,
              })}
              placeholder={`Descripción (máximo ${DESCRIPTION_MAX_CHAR} caracteres)`}
              autoResize
              value={form?.description}
              onChange={(e: any) =>
                setFormField({
                  description: e.target.value,
                })
              }
              rows={3}
            />
            <FormElementError>{validationErrors?.description}</FormElementError>
            <CharacterCount
              value={form?.description}
              maxChar={DESCRIPTION_MAX_CHAR}
            />
          </div>

          <div className='form-field'>
            <label htmlFor='image'>Imágen</label>
            <FileUpload
              chooseLabel='Subir imagen'
              mode='basic'
              accept='image/*'
              value={form?.image.dashboardUrl}
              invalid={!!validationErrors?.image?.dashboardUrl}
              onImageUpload={(encodedImage, file) => {
                setImageFile(file);
                setFormField({
                  image: { ...form?.image, dashboardUrl: encodedImage },
                });
              }}
            />
            <FormElementError>
              {validationErrors?.image?.dashboardUrl}
            </FormElementError>
          </div>
          <div className='form-field tooltip'>
            <label htmlFor='image-alt'>Texto alternativo</label>
            <i id='image-alt-help' className={PrimeIcons.QUESTION_CIRCLE} />
            <Tooltip
              anchorSelect='#image-alt-help'
              content='El texto alternativo describe la imagen en caso de que no pueda cargarse. También ayuda a los motores de búsqueda a entender el contenido de la imagen y es importante para la accesibilidad. Describe brevemente lo que se muestra en la imagen.'
            />
            <InputText
              id='image-alt'
              name='image-alt'
              placeholder='Texto alternativo de la imagen'
              value={form?.image.alt}
              className={classNames({
                'p-invalid': validationErrors?.image?.alt,
              })}
              onChange={(e) =>
                setFormField({
                  image: { ...form?.image, alt: e.target.value },
                })
              }
            />
            <FormElementError>{validationErrors?.image?.alt}</FormElementError>
          </div>
          {dataLoading && isEditing ? (
            <Skeleton className='post-image' height='300px' width='50%' />
          ) : (
            <Image
              className={classNames('post-image', {
                'placeholder-image': form && !form.image.dashboardUrl,
              })}
              src={form?.image.dashboardUrl || IMAGE_PLACEHOLDER}
              preview={!!form?.image.dashboardUrl}
            />
          )}
          <div className='form-field full-width'>
            <label htmlFor='content'>Contenido</label>
            <RichTextEditor
              value={form?.content}
              className={classNames({ 'p-invalid': validationErrors?.content })}
              onChange={(value) => setFormField({ content: value })}
              placeholder='Contenido del artículo'
            />
            <FormElementError>{validationErrors?.content}</FormElementError>
          </div>

          <div className='form-field full-width tooltip'>
            <label htmlFor='url'>URL del artículo</label>
            <i id='url-help' className={PrimeIcons.QUESTION_CIRCLE} />
            <Tooltip
              anchorSelect='#url-help'
              content='Esta es la URL personalizada para tu artículo. Usa solo letras minúsculas sin tilde, números y guiones (ej.: esta-es-la-url).'
            />
            <InputText
              id='url'
              name='url'
              autoCapitalize='off'
              value={form?.meta.url}
              placeholder='ejemplo-de-url'
              className={classNames({
                'p-invalid': validationErrors?.meta?.url,
              })}
              onChange={(e: any) =>
                setFormField({
                  meta: { ...form?.meta, url: e.target.value },
                })
              }
            />
            <FormElementError>{validationErrors?.meta?.url}</FormElementError>
            <CharacterCount value={form?.meta.url} maxChar={URL_MAX_CHAR} />
          </div>

          <div className='form-field tooltip'>
            <label htmlFor='meta-title'>Título meta</label>
            <i id='meta-title-help' className={PrimeIcons.QUESTION_CIRCLE} />
            <Tooltip
              anchorSelect='#meta-title-help'
              content='Este es el título que se mostrará en los resultados de búsqueda de Google y otras plataformas. Asegúrate de que sea claro, atractivo y que describa de forma breve el contenido del artículo.'
            />
            <InputText
              id='meta-title'
              name='meta-title'
              placeholder='Título meta'
              className={classNames({
                'p-invalid': validationErrors?.meta?.title,
              })}
              value={form?.meta.title}
              onChange={(e) =>
                setFormField({
                  meta: { ...form?.meta, title: e.target.value },
                })
              }
            />
            <FormElementError>{validationErrors?.meta?.title}</FormElementError>
          </div>
          <div className='form-field tooltip'>
            <label htmlFor='meta-description'>Descripción meta</label>
            <i
              id='meta-description-help'
              className={PrimeIcons.QUESTION_CIRCLE}
            />
            <Tooltip
              anchorSelect='#meta-description-help'
              content='Aquí puedes escribir una breve descripción que aparecerá en los resultados de búsqueda de Google. Este texto ayuda a los usuarios a entender de qué trata tu contenido y a decidir si hacen clic para leer más.'
            />
            <InputText
              id='meta-description'
              name='meta-description'
              placeholder='Descripción meta'
              value={form?.meta.description}
              className={classNames({
                'p-invalid': validationErrors?.meta?.description,
              })}
              onChange={(e) =>
                setFormField({
                  meta: { ...form?.meta, description: e.target.value },
                })
              }
            />
            <FormElementError>
              {validationErrors?.meta?.description}
            </FormElementError>
          </div>
          <div className='post-editor-buttons'>
            {isEditing && (
              <Button
                className='delete-post-button'
                loading={isDeleting}
                icon='pi pi-trash'
                type='button'
                onClick={handleDelete}
                severity='danger'
                outlined
                disabled={!savedLocalePost}
              >
                Eliminar
              </Button>
            )}
            <Button
              loading={isSaving}
              disabled={!hasChanged}
              type='submit'
              icon='pi pi-save'
            >
              Guardar
            </Button>
          </div>
        </form>
      </Card>
    </>
  );
};

const getToastMessages = (
  isEditing: boolean
): {
  successMessage: NotificationMessage;
  errorMessages: RequestErrorMessages;
} => {
  return {
    successMessage: {
      summary: isEditing ? 'Cambios guardados' : 'Artículo creado',
      detail: isEditing
        ? 'El artículo se ha guardado correctamente'
        : 'Se ha creado el nuevo artículo',
    },
    errorMessages: {
      summary: isEditing
        ? 'Error guardando artículo'
        : 'Error al crear el artículo',
      defaultDetail: isEditing
        ? 'No se han podido guardar los cambios'
        : 'No se ha podido crear el artículo',
      409: 'La URL elegida ya existe para otro artículo',
    },
  };
};
