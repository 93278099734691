import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { Website } from '../../types/website';
import React from 'react';
import { usePageLocaleContext } from '../../context/PageLocaleContext';
import { useWebsiteContext } from '../../context/WebsiteContext';
import './PageLocalePicker.scss';

const ALL_LOCALES = 'all';

interface LocaleSelectOption {
  label: string;
  value: string;
  code: string;
}

const getPageLocaleSelectOptions = (website: Website | undefined, canSelectAll = false) => {
  if (!website) return;
  return [
    ...(canSelectAll ? [{ label: 'Todos', value: ALL_LOCALES }] : []),
    ...website.locales.map((locale) => ({
      label: locale.toLocaleUpperCase(),
      value: locale,
      code: locale.toLocaleLowerCase() === 'en' ? 'gb' : locale.toLocaleLowerCase(),
    })),
  ];
};

interface Props {
  canSelectAll?: boolean;
}

export const PageLocalePicker = ({ canSelectAll = false }: Props) => {
  const { pageLocale, setPageLocale } = usePageLocaleContext() ?? {};
  const { website } = useWebsiteContext() ?? {};

  const handleLocaleChange = (event: DropdownProps) => {
    const { value } = event;
    setPageLocale?.(value === ALL_LOCALES ? undefined : value);
  };

  const localeItemTemplate = (option: LocaleSelectOption, props?: DropdownProps) => {
    if (!option) {
      return <span>{props?.placeholder}</span>;
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {option.code && (
          <img
            alt={option.label}
            src='https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png'
            className={`mr-2 flag flag-${option.code}`}
            style={{ width: '18px' }}
          />
        )}
        <div>{option.label}</div>
      </div>
    );
  };

  return (
    <Dropdown
      options={getPageLocaleSelectOptions(website, canSelectAll)}
      value={pageLocale}
      onChange={handleLocaleChange}
      placeholder={'Idioma'}
      className='post-locale-dropdown'
      itemTemplate={localeItemTemplate}
      valueTemplate={localeItemTemplate}
    />
  );
};
