import React from 'react';
import './KpiCard.scss';
import { Card } from 'primereact/card';
import { classNames } from 'primereact/utils';
import { PrimeIcons } from 'primereact/api';
import { InfoIcon } from '../InfoIcon/InfoIcon';
import { formatNumber } from '../../utils/numberUtils';

export interface KpiProps {
  icon: string;
  title: string;
  iconColor: string;
  iconBkgColor: string;
  value: string | number | undefined;
  previousPercentage?: number | string;
  tooltip?: string;
}

const getIcon = (previousPercentage: number) => {
  if (previousPercentage > 0) {
    return PrimeIcons.ARROW_UP;
  }

  return PrimeIcons.ARROW_DOWN;
};

export const KpiCard = ({ icon, title, iconColor, iconBkgColor, value, previousPercentage, tooltip }: KpiProps) => {
  // todo change this so that it only shows 2 decimal places if needed, if not it shows the whole number
  const roundedValue = typeof value === 'number' ? formatNumber(value) : value;
  const previousPercentageNumber =
    previousPercentage !== undefined
      ? typeof previousPercentage === 'string'
        ? parseFloat(previousPercentage)
        : previousPercentage
      : undefined;
  const roundedPreviousPercentage = previousPercentageNumber ? formatNumber(previousPercentageNumber) : previousPercentageNumber;

  return (
    <Card className='kpi-container'>
      <div className='kpi-value-container'>
        <div className='kpi-title-container'>
          <span>{title}</span>
          {tooltip && <InfoIcon content={tooltip} />}
        </div>
        <span className='bold'>
          {roundedValue}
          {previousPercentageNumber !== undefined && (
            <div
              className={classNames('previous', {
                increase: previousPercentageNumber > 0,
                decrease: previousPercentageNumber < 0,
              })}
            >
              <span className={getIcon(previousPercentageNumber)} style={{ marginRight: '2px' }}></span>
              <span>{roundedPreviousPercentage}%</span>
            </div>
          )}
        </span>
      </div>
      <span className={icon} style={{ color: iconColor, backgroundColor: iconBkgColor, padding: '8px' }}></span>
    </Card>
  );
};
