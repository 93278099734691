const css = `.signature-input .signature-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.signature-input .signature-section .signature-pad-container {
  border: 1px solid var(--primary300);
  border-radius: 4px;
}
.signature-input .signature-section .signature-pad-container:has(.signature-image) {
  border-color: transparent;
}
.signature-input .signature-section .signature-pad-container .signature-pad {
  width: 100%;
  height: 200px;
}
.signature-input .signature-section .signature-pad-container .signature-image {
  height: 248px;
}
.signature-input .signature-section .signature-actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}
.signature-input .signature-note {
  color: var(--primary500);
  font-style: italic;
  padding-left: 0;
  min-height: unset;
}
.signature-input.invalid-form-field .signature-pad-container {
  border-color: var(--pink-500);
}
.signature-input .field-error {
  top: calc(100% - 64px) !important;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvU2lnbmF0dXJlSW5wdXQiLCJzb3VyY2VzIjpbIlNpZ25hdHVyZUlucHV0LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUVBO0VBQ0U7O0FBR0Y7RUFDRTtFQUNBOztBQUdGO0VBQ0U7O0FBSUo7RUFDRTtFQUNBO0VBQ0E7O0FBSUo7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFJQTtFQUNFOztBQUlKO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuc2lnbmF0dXJlLWlucHV0IHtcbiAgLnNpZ25hdHVyZS1zZWN0aW9uIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgZ2FwOiAxNnB4O1xuXG4gICAgLnNpZ25hdHVyZS1wYWQtY29udGFpbmVyIHtcbiAgICAgIGJvcmRlcjogMXB4IHNvbGlkIHZhcigtLXByaW1hcnkzMDApO1xuICAgICAgYm9yZGVyLXJhZGl1czogNHB4O1xuXG4gICAgICAmOmhhcyguc2lnbmF0dXJlLWltYWdlKSB7XG4gICAgICAgIGJvcmRlci1jb2xvcjogdHJhbnNwYXJlbnQ7XG4gICAgICB9XG5cbiAgICAgIC5zaWduYXR1cmUtcGFkIHtcbiAgICAgICAgd2lkdGg6IDEwMCU7XG4gICAgICAgIGhlaWdodDogMjAwcHg7XG4gICAgICB9XG5cbiAgICAgIC5zaWduYXR1cmUtaW1hZ2Uge1xuICAgICAgICBoZWlnaHQ6IDI0OHB4O1xuICAgICAgfVxuICAgIH1cblxuICAgIC5zaWduYXR1cmUtYWN0aW9ucyB7XG4gICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgZ2FwOiA4cHg7XG4gICAgICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtZW5kO1xuICAgIH1cbiAgfVxuXG4gIC5zaWduYXR1cmUtbm90ZSB7XG4gICAgY29sb3I6IHZhcigtLXByaW1hcnk1MDApO1xuICAgIGZvbnQtc3R5bGU6IGl0YWxpYztcbiAgICBwYWRkaW5nLWxlZnQ6IDA7XG4gICAgbWluLWhlaWdodDogdW5zZXQ7XG4gIH1cblxuICAmLmludmFsaWQtZm9ybS1maWVsZCB7XG4gICAgLnNpZ25hdHVyZS1wYWQtY29udGFpbmVyIHtcbiAgICAgIGJvcmRlci1jb2xvcjogdmFyKC0tcGluay01MDApO1xuICAgIH1cbiAgfVxuXG4gIC5maWVsZC1lcnJvciB7XG4gICAgdG9wOiBjYWxjKDEwMCUgLSA2NHB4KSAhaW1wb3J0YW50O1xuICB9XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
