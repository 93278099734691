import React, { PropsWithChildren } from 'react';

import { Card } from 'primereact/card';
import { Image } from 'primereact/image';

import './NoAuthPageTemplate.scss';
import { classNames } from 'primereact/utils';

interface Props {
  icon?: string;
  color?: 'theme' | 'info' | 'error';
}

export const NoAuthPageTemplate = ({
  children,
  icon,
  color = 'theme',
}: PropsWithChildren<Props>) => {
  return (
    <main
      className={classNames('no-auth-page-template', {
        info: color === 'info',
        error: color === 'error',
      })}
    >
      <Image
        src='https://no-gravity.s3.eu-north-1.amazonaws.com/Dashboard/no-gravity-logo-black.webp'
        alt='No Gravity logo'
        className='logo'
      />
      <div className='gradient-border'>
        <Card>
          {icon ? (
            <div className='no-auth-page-icon'>
              <i className={icon}></i>
            </div>
          ) : (
            <></>
          )}
          {children}
        </Card>
      </div>
    </main>
  );
};
