const css = `.feature-picker-container {
  width: 100%;
  background: transparent;
  border: unset;
}
.feature-picker-container .p-checkbox {
  margin-right: 8px;
}
.feature-picker-container.p-tree .p-treenode-children {
  padding: 0 0 0 16px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvRmVhdHVyZVBpY2tlciIsInNvdXJjZXMiOlsiRmVhdHVyZVBpY2tlci5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0k7RUFDQTtFQUNBOztBQUVBO0VBQ0k7O0FBR0o7RUFDSSIsInNvdXJjZXNDb250ZW50IjpbIi5mZWF0dXJlLXBpY2tlci1jb250YWluZXIge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGJhY2tncm91bmQ6IHRyYW5zcGFyZW50O1xuICAgIGJvcmRlcjogdW5zZXQ7XG5cbiAgICAucC1jaGVja2JveCB7XG4gICAgICAgIG1hcmdpbi1yaWdodDogOHB4O1xuICAgIH1cblxuICAgICYucC10cmVlIC5wLXRyZWVub2RlLWNoaWxkcmVuIHtcbiAgICAgICAgcGFkZGluZzogMCAwIDAgMTZweDtcbiAgICB9XG59Il19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
