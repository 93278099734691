import React from 'react';
import './Chip.scss';

interface Props {
  children: React.ReactNode;
}

export const Chip = ({ children }: Props) => {
  return <small className='chip'>{children}</small>;
};
