import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { LOCALES } from '../config/locales';
import * as dateFnsLocales from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import { addLocale } from 'primereact/api';

export const DEFAULT_LOCALE = LOCALES.ES;

interface LocaleContextValue {
  locale: LOCALES;
  setLocale: (locale: LOCALES) => void;
}

const initialLocaleContextValue: LocaleContextValue = {
  locale: DEFAULT_LOCALE,
  setLocale: () => {},
};

const LocaleContext = createContext<LocaleContextValue>(initialLocaleContextValue);

export const LocaleProvider = ({ children }: PropsWithChildren) => {
  // TODO get available locales and initial value from user config
  const [locale, setLocale] = useState(DEFAULT_LOCALE);

  useEffect(() => {
    const dateFnsLocale = dateFnsLocales[locale as keyof typeof dateFnsLocales];
    if (dateFnsLocale) {
      setDefaultOptions({ locale: dateFnsLocale });
    }
  }, [locale]);

  return <LocaleContext.Provider value={{ locale, setLocale }}>{children}</LocaleContext.Provider>;
};

export const useLocaleContext = () => {
  return useContext(LocaleContext);
};

// SET UP PRIME LOCALES
addLocale(LOCALES.ES, {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
  today: 'Hoy',
  clear: 'Limpiar',
  dateFormat: 'mm/yy',
  weekHeader: 'Sm',
});
