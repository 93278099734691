import React, { useState } from 'react';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import './Routines.scss';

import { Button } from 'primereact/button';
import { ResponsiveTable } from '../../components/ResponsiveTable/ResponsiveTable';
import { TableColumn, TableAction } from '../../types/responsiveTable';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import useSWR, { mutate } from 'swr';
import { Routine } from '../../types/Fisiofit/routine';
import { differenceInDays, startOfDay } from 'date-fns';
import { PrimeIcons } from 'primereact/api';
import { RoutinePanel } from '../../components/RoutinePanel/RoutinePanel';
import { useResourcePanel } from '../../hooks/useResourcePanel';
import { resourceDeleter } from '../../utils/resourceMutation';
import { showDeleteDialog } from '../../utils/dialogUtils';
import { useFeaturesToggle } from '../../hooks/useFeaturesToggle';
import { ALL_FEATURES } from '../../config/features';
import { AllowedFeatures } from '../../types/features';
import { useAuthContext } from '../../context/AuthContext';
import { Switch } from '../../components/Switch/Switch';

export const getRoutineDeleteConfirmationDialogProps = (routine: Routine | undefined) => {
  return {
    message: (
      <>
        ¿Estás seguro de que quieres eliminar la rutina{' '}
        {routine?.externalUser ? (
          <>
            de{' '}
            <b>
              {routine?.externalUser?.name} {routine?.externalUser?.surnames}
            </b>
            ?<br />
            <br />
            La rutina se borrará por completo, y no aparecerá en ningún historial.
            {routine?.nextRoutine && ' También se eliminará la próxima rutina.'}
          </>
        ) : (
          <>
            <b>{routine?.name}</b>?
          </>
        )}
      </>
    ),
    header: 'Eliminar rutina',
  };
};

export const getRoutineDays = (startDate: string | undefined, duration: number | null) => {
  const daysDifference = startDate ? differenceInDays(startOfDay(new Date()), startOfDay(new Date(startDate))) : null;
  const elapsedDays = Math.max(0, Math.min(daysDifference ?? 0, duration ?? 0));
  const hasStarted = daysDifference !== null && daysDifference >= 0;
  const remainingDays = duration ? duration - elapsedDays : null;
  return { daysDifference, elapsedDays, hasStarted, remainingDays };
};

export const getRoutineDaysSeverity = (
  startDate: string | undefined,
  duration: number | null,
  hasNextRoutine: boolean,
  isExpired: boolean,
): 'warning' | 'error' | null => {
  const { remainingDays, hasStarted } = getRoutineDays(startDate, duration);
  if (isExpired) return 'error';
  if (!hasStarted) return null;
  if (hasNextRoutine) return null;
  if (remainingDays !== null && remainingDays <= 3) return 'error';
  if (remainingDays !== null && remainingDays <= 5) return 'warning';
  return null;
};

const getRowDaysRemainingSeverity = (_: any, row: Routine) => {
  return getRoutineDaysSeverity(row.localStartDate, row.duration, !!row.nextRoutine, !!row.isExpired);
};

export const Routines = () => {
  const { get, delete: del } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};
  const { user: authUser } = useAuthContext() ?? {};
  const userHasEditFeature = useFeaturesToggle([ALL_FEATURES.ROUTINES_WRITE]);
  const [showOnlySupervisedUsers, setShowOnlySupervisedUsers] = useState(false);

  const { data, isLoading } = useSWR(organization ? `/organizations/${organization.id}/routines` : null, get<Routine[]>);

  const processedData = React.useMemo(() => {
    if (!data) return [];

    return data.map((routine) => {
      const { elapsedDays, daysDifference } = getRoutineDays(routine.localStartDate, routine.duration);
      const hasStarted = daysDifference !== null && daysDifference >= 0;

      const progress = hasStarted ? (elapsedDays / routine.duration) * 100 : null;

      return {
        ...routine,
        progress,
      };
    });
  }, [data]);

  const {
    isEditing,
    setIsEditing,
    panelVisible,
    closePanel,
    selectedResource,
    openPanel,
    isDeleting,
    setIsDeleting,
    getResourceById,
  } = useResourcePanel(processedData);

  const filteredData = React.useMemo(() => {
    if (!processedData) return [];
    if (!showOnlySupervisedUsers) return processedData;

    return processedData.filter((routine) => routine.externalUser?.supervisor?.id === authUser?.id);
  }, [processedData, showOnlySupervisedUsers, authUser?.id]);

  const handleDelete = async (id: string | undefined): Promise<boolean> => {
    if (!id || !organization?.id) return false;
    setIsDeleting(true);
    const url = `/routines/${id}`;
    const response = await del<Routine>(url, {
      errorMessages: {
        summary: 'No se pudo eliminar la rutina',
        defaultDetail: 'Hubo un error al intentar borrar la rutina',
      },
      successMessage: {
        summary: 'Rutina eliminada',
        detail: 'La rutina se ha eliminado correctamente',
      },
      handlers: {
        defaultSuccess: () => {
          mutate(`/organizations/${organization.id}/routines`, (currentData: Routine[] | undefined) => {
            if (!currentData) return currentData;
            return currentData.filter((routine) => routine.id !== id);
          });
        },
      },
    });
    setIsDeleting(false);
    return !!response;
  };

  const tableHeaders: TableColumn<Routine>[] = [
    {
      label: 'Nombre',
      accessor: 'name',
      type: 'string',
      isTitle: true,
    },
    {
      label: 'Tipo',
      accessor: 'externalUser',
      colorFromCallBack: (_, row) => {
        return row.externalUser?.id ? 'primary' : 'contrast';
      },
      textKeyFromCallBack: (_, row) => {
        return row.externalUser?.id ? 'usuario' : 'plantilla';
      },
      type: 'tag',
    },
    {
      label: 'Duración (días)',
      accessor: 'duration',
      type: 'number',
    },
    {
      label: 'Días restantes',
      accessor: 'localStartDate',
      type: 'number',
      valueFromCallBack: (_, row) => {
        if (!row.localStartDate || !row.duration) return '-';

        const { elapsedDays, hasStarted } = getRoutineDays(row.localStartDate, row.duration);

        if (!hasStarted || row.isExpired) return '-';

        return (row.duration - elapsedDays).toString();
      },
      severityCallback: (_, row) => {
        if (row.isExpired) return null;
        return getRowDaysRemainingSeverity(_, row);
      },
    },
    {
      label: 'Progreso',
      accessor: 'progress',
      type: 'progress',
      truncate: true,
      percentageValueFromCallBack: (progress) => progress,
      severityCallback: (_, row) => {
        const { hasStarted } = getRoutineDays(row.localStartDate, row.duration);
        if (!hasStarted) return null;
        return getRowDaysRemainingSeverity(_, row) ?? 'success';
      },
      overrideMessage: (_, row) => {
        if (!row.externalUser) return null;
        const { hasStarted, daysDifference } = getRoutineDays(row.localStartDate, row.duration);
        if (!hasStarted && daysDifference !== null) {
          const daysAbs = Math.abs(daysDifference);
          if (daysAbs === 1) return 'Empieza mañana';
          return 'Empieza en ' + daysAbs + ' días';
        }
        if (row.isExpired) {
          return 'Rutina caducada';
        }
        return null;
      },
    },
    {
      label: 'Próxima rutina',
      accessor: 'nextRoutine',
      type: 'boolean',
      valueFromCallBack: (nextRoutine, row) => {
        if (!row.localStartDate) return null;
        return !!nextRoutine;
      },
      trueText: 'Sí',
      falseText: 'No',
      trueIcon: PrimeIcons.CHECK_CIRCLE,
      falseIcon: PrimeIcons.TIMES_CIRCLE,
    },
  ];

  const options: TableAction[] = [
    {
      label: 'Ver',
      onClick: (id) => {
        openPanel(false, id);
      },
      hasPermission: userHasEditFeature,
    },
    {
      label: 'Editar',
      onClick: (id) => {
        openPanel(true, id);
      },
      hasPermission: userHasEditFeature,
    },
    {
      label: 'Eliminar',
      onClick: (id) => {
        const routine = getResourceById(id);
        showDeleteDialog(() => handleDelete(id), getRoutineDeleteConfirmationDialogProps(routine));
      },
      hasPermission: userHasEditFeature,
    },
  ];

  const allowedFeatures: AllowedFeatures = {
    edit: [ALL_FEATURES.ROUTINES_WRITE],
    delete: [ALL_FEATURES.ROUTINES_WRITE],
    create: [ALL_FEATURES.ROUTINES_WRITE],
  };

  return (
    <PageTemplate className='employees-page' title='Rutinas'>
      <ResponsiveTable
        disabled={isDeleting}
        data={filteredData}
        columns={tableHeaders}
        options={options}
        loading={organizationLoading || isLoading}
        headerOptions={
          <div className='routines-header-options'>
            {userHasEditFeature && (
              <>
                <Button
                  label='Añadir rutina'
                  outlined
                  rounded
                  icon={PrimeIcons.PLUS}
                  onClick={() => {
                    openPanel(false);
                  }}
                />
                <Switch
                  id='show-supervised'
                  label='Mis pacientes'
                  primeSwitchProps={{
                    checked: showOnlySupervisedUsers,
                    onChange: (e) => setShowOnlySupervisedUsers(e.value),
                  }}
                />
              </>
            )}
          </div>
        }
        onRowClick={(row) => {
          openPanel(false, row.id);
        }}
      ></ResponsiveTable>
      <RoutinePanel
        resource={selectedResource}
        visible={panelVisible}
        onHide={closePanel}
        onIsEditingChange={(value) => setIsEditing(value)}
        isEditing={isEditing}
        onDeleteResource={handleDelete}
        isDeleting={isDeleting}
        allowedFeatures={allowedFeatures}
      ></RoutinePanel>
    </PageTemplate>
  );
};
