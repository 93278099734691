import React, { FormEvent, useMemo } from 'react';
import './RolePanel.scss';

import { Role, RolesTableEntry } from '../../types/role';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { FeaturePicker } from '../FeaturePicker/FeaturePicker';
import { useForm } from '../../hooks/useForm';
import { FormField } from '../FormField/FormField';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { RequestFeedbackMessages, useClient } from '../../hooks/useClient';
import { mutate } from 'swr';
import { Panel, ResourcePanelImplProps } from '../Panel/Panel';
import { ROLE_FORM_VALIDATORS, RoleForm } from './validators';
import { useAuthContext } from '../../context/AuthContext';
import { getRoleDeleteConfirmationDialogProps } from '../../pages/Roles/Roles';
import { UserDisplay } from '../UserDisplay/UserDisplay';

const getToastMessages = (isEditing: boolean): RequestFeedbackMessages => {
  return {
    successMessage: {
      summary: isEditing ? 'Rol modificado' : 'Rol creado',
      detail: isEditing ? 'El rol se ha modificado correctamente' : 'Se ha creado el nuevo rol',
    },
    errorMessages: {
      summary: isEditing ? 'Error modificando rol' : 'Error al crear rol',
      defaultDetail: isEditing ? 'No se ha podido modificar el rol' : 'No se ha podido crear el rol',
      LAST_ORG_ADMIN: 'La organización debe tener al menos un Administrador de la empresa',
      RESOURCE_WITH_SAME_NAME_EXISTS: 'Ya existe un rol con ese nombre',
    },
  };
};

const getInitialRoleForm = (role?: Role): RoleForm | undefined => {
  const { features, name } = role ?? {};
  return {
    name,
    features: new Set(features),
  };
};

export const RolePanel = ({
  resource,
  visible,
  onHide,
  onIsEditingChange,
  onDeleteResource,
  isEditing = false,
  isDeleting = false,
}: ResourcePanelImplProps<RolesTableEntry>) => {
  const { organization } = useOrganizationContext() ?? {};
  const { post, patch } = useClient();
  const { user } = useAuthContext() ?? {};
  const isCreating = !resource;

  const initialRoleForm = useMemo(() => getInitialRoleForm(resource), [resource]);
  const { form, setFormFields, isSaving, setIsSaving, hasChanged, validationErrors, validate, resetForm } = useForm<
    RoleForm | undefined
  >(initialRoleForm, !visible, ROLE_FORM_VALIDATORS);

  const handleSave = async (e?: FormEvent): Promise<boolean> => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const newErrors = validate();
    const { name, features } = form ?? {};
    if (!organization || !name || !features || features.size === 0 || newErrors) {
      return false;
    }

    setIsSaving(true);

    const { successMessage, errorMessages } = getToastMessages(isEditing);
    const request = {
      body: {
        name,
        features: Array.from(features),
      },
      successMessage,
      errorMessages,
    };
    const response =
      isEditing && resource
        ? await patch<Role>(`/roles/${resource.id}`, request)
        : await post<Role>(`/organizations/${organization.id}/roles`, request);

    if (response) {
      mutate(`/organizations/${organization.id}/roles-table`);
      mutate(`/organizations/${organization.id}`);
      if (user) {
        mutate(`/users/${user.id}/role`);
      }
    }
    setIsSaving(false);
    return !!response;
  };

  return (
    <Panel
      panelType='resourcePanel'
      resourceName='rol'
      visible={visible}
      onHide={onHide}
      onDelete={() => onDeleteResource(resource?.id)}
      isDeleting={isDeleting}
      onSave={handleSave}
      onIsEditingChange={onIsEditingChange}
      hasChanged={hasChanged}
      isSaving={isSaving}
      isEditing={isEditing}
      isCreating={isCreating}
      resetForm={resetForm}
      customDeleteConfirmationDialogProps={getRoleDeleteConfirmationDialogProps(resource)}
    >
      {!isCreating && !isEditing && (
        <div className='readonly-form'>
          <FormField labelTitle='Nombre'>
            <p>{resource?.name}</p>
          </FormField>
          {resource?.roleUsers && resource.roleUsers.length > 0 && (
            <FormField labelTitle='Empleados usando este rol' fullWidth>
              <div className='role-users-list'>
                {resource.roleUsers.map((user) => (
                  <UserDisplay key={user.id} name={user.name} surnames={user.surnames} avatarUrl={user.avatarUrl} />
                ))}
              </div>
            </FormField>
          )}
          <FormField labelTitle='Permisos' fullWidth elementId='features'>
            <FeaturePicker featureSet={form?.features} disabled={true} />
          </FormField>
        </div>
      )}
      {(isEditing || isCreating) && (
        <form
          className={classNames({
            'form-loading': isSaving,
          })}
          onSubmit={handleSave}
        >
          <FormField labelTitle='Nombre del rol' elementId='role-name' required error={validationErrors?.name}>
            <InputText
              id='role-name'
              className={classNames({
                'p-invalid': validationErrors?.name,
              })}
              placeholder={`Escribe el nombre del rol`}
              value={form?.name ?? ''}
              onChange={(e: any) =>
                setFormFields({
                  name: e.target.value,
                })
              }
            />
          </FormField>
          {!isCreating && resource?.roleUsers && resource.roleUsers.length > 0 && (
            <FormField labelTitle='Empleados usando este rol' fullWidth>
              <div className='role-users-list'>
                {resource.roleUsers.map((user) => (
                  <UserDisplay key={user.id} name={user.name} surnames={user.surnames} avatarUrl={user.avatarUrl} />
                ))}
              </div>
            </FormField>
          )}
          <FormField labelTitle='Permisos' fullWidth required elementId='features' error={validationErrors?.features}>
            <FeaturePicker
              elementId='features'
              onChange={(newFeatures) =>
                setFormFields({
                  features: newFeatures,
                })
              }
              featureSet={form?.features}
              className={classNames({
                'p-invalid': validationErrors?.features,
              })}
            />
          </FormField>
        </form>
      )}
    </Panel>
  );
};
