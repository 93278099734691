import React from 'react';
import './CalendarFilters.scss';
import { User } from '../../types/user';
import { Branch } from '../../types/branch';
import { ReservationType } from '../../types/reservationType';
import { CompactEmployeeSelect } from '../CompactEmployeeSelect/CompactEmployeeSelect';
import { PrimeIcons } from 'primereact/api';
import { useOrganizationContext } from '../../context/OrganizationContext';
import classNames from 'classnames';
import { CompactBranchSelect } from '../CompactBranchSelect/CompactBranchSelect';
import { CompactReservationTypeSelect } from '../CompactReservationTypeSelect/CompactReservationTypeSelect';

interface Props {
  selectedBranchIds: string[];
  setSelectedBranchIds: (ids: string[]) => void;
  selectedEmployees: User[];
  setSelectedEmployees: (employees: User[]) => void;
  selectedReservationTypes: ReservationType[];
  setSelectedReservationTypes: (types: ReservationType[]) => void;
  reservationTypes: ReservationType[];
  hasAppliedFilters: boolean;
  clearFilters: () => void;
}

export const CalendarFilters = ({
  selectedBranchIds,
  setSelectedBranchIds,
  selectedEmployees,
  setSelectedEmployees,
  selectedReservationTypes,
  setSelectedReservationTypes,
  reservationTypes,
  hasAppliedFilters,
  clearFilters,
}: Props) => {
  const { orgBranches } = useOrganizationContext() ?? {};
  const selectedReservationTypesHasNoAssignedUsers = selectedReservationTypes.some((type) => !type.hasAssignedUsers);

  const getSelectedBranchName = () => {
    if (!selectedBranchIds.length) return 'Sucursales';
    if (selectedBranchIds.length === 1) {
      const branch = orgBranches?.find((b: Branch) => b.id === selectedBranchIds[0]);
      return branch?.name || 'Sucursal';
    }
    return `${selectedBranchIds.length} sucursales`;
  };

  const getSelectedEmployeeName = () => {
    if (!selectedEmployees.length) return 'Empleados';
    if (selectedEmployees.length === 1) {
      return `${selectedEmployees[0].name} ${selectedEmployees[0].surnames}`;
    }
    return `${selectedEmployees.length} empleados`;
  };

  const getSelectedReservationTypeName = () => {
    if (!selectedReservationTypes.length) return 'Reservas';
    if (selectedReservationTypes.length === 1) {
      return selectedReservationTypes[0].name.charAt(0).toUpperCase() + selectedReservationTypes[0].name.slice(1);
    }
    return `${selectedReservationTypes.length} tipos`;
  };

  return (
    <div className='calendar-filters'>
      <div className='filters-container'>
        <div
          className={`filter-label ${hasAppliedFilters ? 'active' : ''}`}
          onClick={hasAppliedFilters ? clearFilters : undefined}
        >
          <i className={hasAppliedFilters ? PrimeIcons.FILTER_SLASH : PrimeIcons.FILTER}></i>
          <span>{hasAppliedFilters ? 'Limpiar' : 'Filtros'}</span>
        </div>
        <div className='filter-buttons'>
          <div className='filter-item'>
            <i className={classNames('filter-icon', PrimeIcons.BUILDING, { active: selectedBranchIds.length > 0 })}></i>
            <span className={classNames('filter-text', { active: selectedBranchIds.length > 0 })}>{getSelectedBranchName()}</span>
            <CompactBranchSelect
              value={selectedBranchIds}
              onChange={(selected) => {
                if (selected === null) {
                  setSelectedBranchIds([]);
                } else if (Array.isArray(selected)) {
                  setSelectedBranchIds(selected);
                }
              }}
              branches={orgBranches || []}
              multiple={true}
              className={`filter-dropdown branch-select ${selectedBranchIds.length > 0 ? 'active' : ''}`}
              isFilter={true}
            />
          </div>

          <div className='filter-item'>
            <i className={classNames('filter-icon', PrimeIcons.CALENDAR, { active: selectedReservationTypes.length > 0 })}></i>
            <span className={classNames('filter-text', { active: selectedReservationTypes.length > 0 })}>
              {getSelectedReservationTypeName()}
            </span>
            <CompactReservationTypeSelect
              value={selectedReservationTypes}
              onChange={(selected) => {
                if (selected === null) {
                  setSelectedReservationTypes([]);
                } else if (Array.isArray(selected)) {
                  setSelectedReservationTypes(selected);

                  if (selected.some((type) => !type.hasAssignedUsers)) {
                    setSelectedEmployees([]);
                  }
                }
              }}
              reservationTypes={reservationTypes}
              multiple={true}
              className={`filter-dropdown type-select ${selectedReservationTypes.length > 0 ? 'active' : ''}`}
              isFilter={true}
            />
          </div>

          {!selectedReservationTypesHasNoAssignedUsers && (
            <div className='filter-item'>
              <i className={classNames('filter-icon', PrimeIcons.USER, { active: selectedEmployees.length > 0 })}></i>
              <span className={classNames('filter-text', { active: selectedEmployees.length > 0 })}>
                {getSelectedEmployeeName()}
              </span>
              <CompactEmployeeSelect
                onChange={(selected) => {
                  if (selected === null) {
                    setSelectedEmployees([]);
                  } else if (Array.isArray(selected)) {
                    setSelectedEmployees(selected);
                  }
                }}
                value={selectedEmployees}
                multiple={true}
                className={`filter-dropdown employee-select ${selectedEmployees.length > 0 ? 'active' : ''}`}
                isFilter={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
