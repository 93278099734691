import React, { PropsWithChildren, ReactElement } from 'react';

import './PageTemplate.scss';
import { NavElement } from '../../types/navigation';
import { LinkButton } from '../../components/LinkButton/LinkButton';
import { matchPath, Route, Routes, useLocation } from 'react-router-dom';
import { getRouteSteps } from '../../utils/navigation';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';
import { classNames } from 'primereact/utils';
import { PageLocaleProvider } from '../../context/PageLocaleContext';

const getStepUrl = (currentPageIndex: number, steps: string[]) => {
  if (currentPageIndex === 0) return '/';
  let url = '';
  for (let i = 0; i < currentPageIndex; i++) {
    url = '/' + steps[currentPageIndex - i] + url;
  }
  return url;
};

const getBreadcrumbs = (parentRoute: string, steps: string[], routes: NavElement[] = []): MenuItem[] => {
  const breadcrumbRoutes = steps.flatMap((step, index) => {
    const stepUrl = getStepUrl(index, steps);
    const matchedRoutes = routes.filter((route) => {
      const match = matchPath(route.url, stepUrl);
      return !!match;
    });

    if (matchedRoutes.length === 1) {
      return matchedRoutes;
    }

    const exactMatch = matchedRoutes.find((route) => route.url === stepUrl);
    if (exactMatch) {
      return [exactMatch];
    }

    const routeWithoutParams = matchedRoutes.find((route) => !route.url.includes(':'));
    return routeWithoutParams ? [routeWithoutParams] : [];
  });

  return breadcrumbRoutes.map(({ url, label }, index) => {
    return {
      disabled: index === breadcrumbRoutes.length - 1,
      label,
      template: <LinkButton key={url} to={`${parentRoute}${url}`} label={label} />,
    };
  });
};

interface Props {
  title?: string;
  routes?: NavElement[];
  parentRoute?: string;
  resourceSelectors?: ReactElement[];
  className?: string;
  withPageLocaleContext?: boolean;
}

export const PageTemplate = ({
  children,
  routes,
  title,
  parentRoute,
  className,
  withPageLocaleContext = false,
}: PropsWithChildren<Props>) => {
  const location = useLocation();
  const steps = getRouteSteps(location);
  const breadcrumbs = getBreadcrumbs(parentRoute ?? '', steps, routes);

  const renderContent = () => {
    return (
      <div className={classNames('page-template-content', className)}>
        {routes ? (
          <Routes>
            {routes.map(({ element, url }) => (
              <Route key={url} path={url} element={element} />
            ))}
          </Routes>
        ) : (
          children
        )}
      </div>
    );
  };

  const renderTemplate = () => {
    return (
      <>
        <div className='page-template-header'>
          <BreadCrumb model={breadcrumbs} home={{ label: title, disabled: true }} />
          <div className='resource-selects'></div>
        </div>
        {renderContent()}
      </>
    );
  };

  return withPageLocaleContext ? <PageLocaleProvider>{renderTemplate()}</PageLocaleProvider> : renderTemplate();
};
