import React from 'react';
import { EventProps } from 'react-big-calendar';
import { Tooltip } from 'primereact/tooltip';
import { parseISO, format } from 'date-fns';
import { es } from 'date-fns/locale';
import { CANCELED_BY_OPTIONS, Reservation, RESERVATION_STATUSES } from '../../types/reservation';
import { ReservationType } from '../../types/reservationType';
import { capitalize } from '../../utils/textUtils';
import './ReservationCalendarEvent.scss';
import { Capacitor } from '@capacitor/core';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { PrimeIcons } from 'primereact/api';
import classNames from 'classnames';
import { ReservationTypeWithColor } from '../../hooks/AppData/useReservationTypes';
import { Avatar } from 'primereact/avatar';
import { getUserAvatarProps } from '../../utils/avatarUtils';

interface Props extends EventProps<Reservation> {
  reservationType: ReservationTypeWithColor;
  showAttendance?: boolean;
  externalUserId?: string;
}

const getSingleEventTitle = (reservation: Reservation) => {
  const eventExternalUser = reservation.confirmedExternalUsers?.[0] ?? reservation.unregisteredClient;
  return `${eventExternalUser?.name} ${eventExternalUser?.surnames}`;
};

const getGroupEventTitle = (reservation: Reservation, reservationType: ReservationType) => {
  return `${reservation.confirmedExternalUsers?.length ?? 0}/${reservationType.groupSize}`;
};

export const getReservationEventColor = (
  reservation: Reservation,
  reservationType: ReservationTypeWithColor | undefined,
  missedAttendance: boolean = false,
) => {
  if (!reservationType) return 'primary';
  const userCanceled = reservation.externalUserStatus === RESERVATION_STATUSES.CANCELED;
  if (missedAttendance || userCanceled) return 'pink';
  return reservationType.color;
};

export const checkIfCurrentUserMissedAttendance = (
  reservation: Reservation,
  externalUserId: string | undefined,
  reservationType: ReservationTypeWithColor | undefined,
) => {
  if (!externalUserId || !reservationType) return { hasAttendanceRecords: false, currentUserDidNotAttend: false };
  const isPast = new Date(reservation.localEndDate) <= new Date();
  const hasAttendanceRecords =
    isPast && reservation.externalUsers?.some((user) => user.id === externalUserId && user.attendance !== undefined);
  const currentUser = reservation.externalUsers?.find((user) => user.id === externalUserId);
  const currentUserDidNotAttend = currentUser && currentUser.attendance === false;

  return { hasAttendanceRecords, currentUserDidNotAttend };
};

export const ReservationCalendarEvent: React.FC<Props> = ({
  event: reservation,
  reservationType,
  showAttendance = false,
  externalUserId,
}) => {
  const { organization } = useOrganizationContext() ?? {};
  const tooltipId = `event-${reservation.id}`;
  const isGroup = reservationType.groupSize > 1;
  const isTouchDevice = Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches;
  const userCanceled = reservation.externalUserStatus === RESERVATION_STATUSES.CANCELED;
  const canceledByAdmin = reservation.canceledBy === CANCELED_BY_OPTIONS.CANCELED_BY_ADMIN;
  const isInQueue = reservation.externalUserStatus === RESERVATION_STATUSES.IN_QUEUE;
  const canceledString = canceledByAdmin ? `Cancelada por ${capitalize(organization?.name)}` : 'Cancelada';

  const title = isGroup ? null : getSingleEventTitle(reservation);
  const capacity = isGroup ? getGroupEventTitle(reservation, reservationType) : null;

  const time = (
    <>
      <span>{format(parseISO(reservation.localStartDate), 'HH:mm', { locale: es })}</span>{' '}
      <span className='event-time-end'>- {format(parseISO(reservation.localEndDate), 'HH:mm', { locale: es })}</span>
    </>
  );

  const { hasAttendanceRecords, currentUserDidNotAttend } = checkIfCurrentUserMissedAttendance(
    reservation,
    externalUserId,
    reservationType,
  );
  const calendarReservationDidNotAttend =
    !externalUserId && !isGroup && reservationType.hasAttendance && reservation.externalUsers?.every((user) => !user.attendance);
  const color = getReservationEventColor(reservation, reservationType, currentUserDidNotAttend);

  return (
    <div id={tooltipId} className='calendar-event-container'>
      <div className='calendar-event-inside' style={{ borderColor: `var(--${color}-500)` }}>
        <div
          className='event-type'
          style={{
            backgroundColor: `var(--${color || 'primary'}-500)`,
            border: `1px solid var(--${color || 'primary'}-500)`,
            color: 'var(--surface-0)',
          }}
        >
          {capitalize(reservationType.name)}
        </div>
        <div
          className={classNames('event-title', {
            'reservation-calendar-did-not-attend': calendarReservationDidNotAttend,
          })}
        >
          {title}
        </div>
        {reservation.user && !externalUserId && (
          <div className='event-professional'>
            <Avatar shape='circle' {...getUserAvatarProps(reservation.user)} className='event-professional-avatar' />{' '}
            {reservation.user.name}
          </div>
        )}
        {capacity && <div className='event-capacity'>{capacity}</div>}
        {!userCanceled && !currentUserDidNotAttend && !isInQueue && <div className='event-time'>{time}</div>}
        {userCanceled && (
          <div className='event-status' style={{ color: `var(--${color || 'primary'}-700)` }}>
            {canceledString}
          </div>
        )}
        {currentUserDidNotAttend && (
          <div className='event-status' style={{ color: `var(--${color || 'primary'}-700)` }}>
            No asistió
          </div>
        )}
        {isInQueue && (
          <div className='event-status' style={{ color: 'var(--primary600)' }}>
            En cola
          </div>
        )}
      </div>
      {!isTouchDevice && (
        <Tooltip target={`#${tooltipId}`} position='right' showDelay={50} className='calendar-event-tooltip' hideDelay={0}>
          <div className='event-tooltip'>
            <strong>{capitalize(reservationType.name)}</strong>
            <div className='event-details'>
              {userCanceled && (
                <div>
                  <i className={classNames(PrimeIcons.TIMES_CIRCLE, 'error')} />
                  <span>{canceledString}</span>
                </div>
              )}
              {showAttendance && hasAttendanceRecords && (
                <div>
                  <i
                    className={`pi ${currentUserDidNotAttend ? 'pi-user-minus' : 'pi-user-plus'}`}
                    style={{ color: currentUserDidNotAttend ? 'var(--pink-600)' : 'var(--green-600)' }}
                  />
                  <span>{currentUserDidNotAttend ? 'No asistió' : 'Asistió'}</span>
                </div>
              )}
              {isInQueue && (
                <div>
                  <i className='pi pi-stopwatch' style={{ color: 'var(--disruptive)' }} />
                  <span>Esperando en cola</span>
                </div>
              )}
              <div>
                <i className='pi pi-calendar' />
                <span>{capitalize(format(parseISO(reservation.localStartDate), "EEEE, d 'de' MMMM", { locale: es }))}</span>
              </div>
              <div>
                <i className='pi pi-clock' />
                <span>{time}</span>
              </div>
              <div>
                <i className='pi pi-map-marker' />
                <span>{reservation.branch.name}</span>
              </div>
              {isGroup ? (
                <>
                  <div>
                    <i className='pi pi-users' />
                    <span>Sesión de grupo ({capacity})</span>
                  </div>
                  <div className='event-tooltip-attendees'>
                    {reservation.confirmedExternalUsers?.map((user) => {
                      return (
                        <span
                          className={classNames({
                            'reservation-calendar-did-not-attend': user.attendance === false,
                          })}
                          key={user.id}
                        >
                          {user.name} {user.surnames}
                          {user.bookedFromApp ? ' (app)' : ''}
                        </span>
                      );
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={classNames({
                      'reservation-calendar-did-not-attend': calendarReservationDidNotAttend || currentUserDidNotAttend,
                    })}
                  >
                    <i className='pi pi-user' />
                    <span>{title}</span>
                  </div>
                </>
              )}
              {reservation.user && (
                <div>
                  <Avatar
                    shape='circle'
                    size='normal'
                    {...getUserAvatarProps(reservation.user)}
                    className='event-professional-avatar'
                  />
                  <span>
                    Profesional: {reservation.user.name} {reservation.user.surnames}
                  </span>
                </div>
              )}
            </div>
          </div>
        </Tooltip>
      )}
    </div>
  );
};
