import React from 'react';
import { EventProps } from 'react-big-calendar';
import { Tooltip } from 'primereact/tooltip';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { Capacitor } from '@capacitor/core';
import './ExpenseCalendarEvent.scss';
import { capitalize } from '../../utils/textUtils';

export enum PaymentType {
  CASH = 'cash',
  COUPON_PURCHASE = 'coupon-purchase',
  COUPON_USE = 'coupon-use',
}

interface ExpenseEventDto {
  id: string;
  title: string;
  start: string;
  amount: number;
  paymentType: PaymentType;
  reservationId: string;
  employee?: {
    id: string;
    name: string;
    surnames: string;
  };
}

export const getExpenseEventColor = (paymentType: PaymentType) => {
  if (paymentType === PaymentType.CASH) return 'green';
  if (paymentType === PaymentType.COUPON_PURCHASE) return 'blue';
  if (paymentType === PaymentType.COUPON_USE) return 'teal';
  return 'gray';
};

const getPaymentTypeLabel = (paymentType: PaymentType) => {
  if (paymentType === PaymentType.CASH) return 'Pago directo';
  if (paymentType === PaymentType.COUPON_PURCHASE) return 'Adquisición de bono';
  if (paymentType === PaymentType.COUPON_USE) return 'Uso de bono';
  return 'Pago';
};

export const ExpenseCalendarEvent: React.FC<EventProps<ExpenseEventDto>> = ({ event }) => {
  const color = getExpenseEventColor(event.paymentType);
  const tooltipId = `expense-${event.id}`;
  const isTouchDevice = Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches;
  return (
    <>
      <div id={tooltipId} className='expense-calendar-event' style={{ borderColor: `var(--${color}-500)` }}>
        <div className='event-type' style={{ backgroundColor: `var(--${color}-500)` }}>
          <i className={event.paymentType === PaymentType.CASH ? 'pi pi-money-bill' : 'pi pi-ticket'} />
        </div>
        <div className='event-content'>
          <span>{event.amount}€</span>
        </div>
      </div>
      {!isTouchDevice && (
        <Tooltip target={`#${tooltipId}`} position='right' showDelay={50} hideDelay={0} className='calendar-event-tooltip'>
          <div className='event-tooltip'>
            <strong>{getPaymentTypeLabel(event.paymentType)}</strong>
            <div className='event-details'>
              <div>
                <i className='pi pi-calendar' />
                <span>{capitalize(format(parseISO(event.start), "EEEE, d 'de' MMMM", { locale: es }))}</span>
              </div>
              {event.employee && (
                <div>
                  <i className='pi pi-user' />
                  <span>{`${event.employee.name} ${event.employee.surnames}`}</span>
                </div>
              )}
              <div>
                <i className='pi pi-euro' />
                <span>{event.amount}€</span>
              </div>
              <div>
                <i className={event.paymentType === PaymentType.CASH ? 'pi pi-money-bill' : 'pi pi-ticket'} />
                <span>{getPaymentTypeLabel(event.paymentType)}</span>
              </div>
              <div>
                <i className='pi pi-info-circle' />
                <span>{capitalize(event.title)}</span>
              </div>
            </div>
          </div>
        </Tooltip>
      )}
    </>
  );
};
