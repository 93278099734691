import { useOrganizationContext } from '../../context/OrganizationContext';
import React, { useEffect, useMemo, useState } from 'react';
import { Tree } from 'primereact/tree';
import { ALL_FEATURES, Feature } from '../../config/features';
import './FeaturePicker.scss';
import { TreeNode } from 'primereact/treenode';
import { FormField } from '../FormField/FormField';

export interface Props {
  featureSet?: Set<Feature>;
  onChange?: (featureSet: Set<Feature>) => void;
  disabled?: boolean;
}

export const FeaturePicker = ({ featureSet, onChange, disabled }: Props) => {
  const { organization } = useOrganizationContext() ?? {};
  const { groupReservationName, singleReservationName } = organization?.config ?? {};
  const organizationFeaturesSet = new Set(organization?.features);
  const [selectedKeys, setSelectedKeys] = useState();

  // TODO simplify
  const singleReservations = singleReservationName ? `de ${singleReservationName}` : 'individuales';
  const groupReservations = groupReservationName || 'grupo';

  const nodes = useMemo(() => {
    const createNode = (feature: Feature, label: string, children?: TreeNode[]): TreeNode => ({
      key: feature,
      label,
      selectable: organizationFeaturesSet.has(feature),
      children,
    });

    return [
      organizationFeaturesSet.has(ALL_FEATURES.ANALYTICS) ? createNode(ALL_FEATURES.ANALYTICS, 'Ver página de analíticas') : {},

      organizationFeaturesSet.has(ALL_FEATURES.BLOG)
        ? createNode(ALL_FEATURES.BLOG, 'Ver página de blog', [
            createNode(ALL_FEATURES.BLOG_WRITE, 'Publicar y editar artículos'),
          ])
        : {},

      organizationFeaturesSet.has(ALL_FEATURES.EXTERNAL_USERS)
        ? createNode(ALL_FEATURES.EXTERNAL_USERS, 'Ver página de usuarios', [
            createNode(ALL_FEATURES.EXTERNAL_USERS_WRITE, 'Crear usuarios'),
          ])
        : {},

      organizationFeaturesSet.has(ALL_FEATURES.RESERVATIONS)
        ? createNode(ALL_FEATURES.RESERVATIONS, 'Ver página de reservas', [
            organizationFeaturesSet.has(ALL_FEATURES.SINGLE_RESERVATIONS)
              ? createNode(ALL_FEATURES.SINGLE_RESERVATIONS, `Ver reservas ${singleReservations}`, [
                  createNode(ALL_FEATURES.SINGLE_RESERVATIONS_WRITE, `Crear y editar reservas ${singleReservations}`),
                ])
              : {},
            organizationFeaturesSet.has(ALL_FEATURES.GROUP_RESERVATIONS)
              ? createNode(ALL_FEATURES.GROUP_RESERVATIONS, `Ver reservas ${groupReservations}`, [
                  createNode(ALL_FEATURES.GROUP_RESERVATIONS_WRITE, `Crear y editar reservas ${groupReservations}`),
                ])
              : {},
          ])
        : {},

      organizationFeaturesSet.has(ALL_FEATURES.ROUTINES)
        ? createNode(ALL_FEATURES.ROUTINES, 'Ver página de rutinas', [
            createNode(ALL_FEATURES.ROUTINES_WRITE, 'Crear, editar y asignar rutinas'),
          ])
        : {},

      organizationFeaturesSet.has(ALL_FEATURES.EXERCISES)
        ? createNode(ALL_FEATURES.EXERCISES, 'Ver página de ejercicios', [
            createNode(ALL_FEATURES.EXERCISES_WRITE, 'Crear y editar ejercicios'),
          ])
        : {},

      organizationFeaturesSet.has(ALL_FEATURES.PROMOTIONS)
        ? createNode(ALL_FEATURES.PROMOTIONS, 'Enviar correos de promociones')
        : {},

      organizationFeaturesSet.has(ALL_FEATURES.BUSINESS_CONFIG)
        ? createNode(ALL_FEATURES.BUSINESS_CONFIG, 'Ver página de configuración', [
            createNode(ALL_FEATURES.BUSINESS_CONFIG_WRITE, 'Editar la configuración'),
          ])
        : {},

      organizationFeaturesSet.has(ALL_FEATURES.USERS)
        ? createNode(ALL_FEATURES.USERS, 'Ver página de empleados', [
            createNode(ALL_FEATURES.USERS_WRITE, 'Crear empleados y modificar roles'),
          ])
        : {},
    ].filter(Boolean);
  }, [organizationFeaturesSet, featureSet, groupReservations, singleReservations]);

  const expandedKeys = useMemo(() => {
    const keys: any = {};
    organizationFeaturesSet?.forEach((feature) => {
      keys[feature] = { checked: true };
    });
    return keys;
  }, [organizationFeaturesSet]);

  useEffect(() => {
    const keys: any = {};
    featureSet?.forEach((feature) => {
      keys[feature] = { checked: true };
    });
    setSelectedKeys(keys);
  }, [featureSet]);

  const handleSelectionChange = (event: any) => {
    const updatedFeatures = new Set<Feature>();
    Object.keys(event.value).forEach((key) => {
      updatedFeatures.add(key as Feature);
    });
    setSelectedKeys(event.value);
    onChange && onChange(updatedFeatures);
  };

  return (
    <FormField labelTitle='Permisos' elementId='features' fullWidth>
      <Tree
        id='features'
        value={nodes}
        selectionMode='checkbox'
        selectionKeys={selectedKeys}
        expandedKeys={expandedKeys}
        onSelectionChange={handleSelectionChange}
        className='feature-picker-container'
        disabled={disabled}
      />
    </FormField>
  );
};
