import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import './RoutinePanel.scss';

import { Button } from 'primereact/button';
import { useForm } from '../../hooks/useForm';
import { FormField } from '../FormField/FormField';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { RequestFeedbackMessages, useClient } from '../../hooks/useClient';
import {
  Routine,
  ROUTINE_TYPES,
  ExerciseDay,
  RoutineRepetitionExercise,
  RoutineDurationExercise,
  RoutineExercise,
} from '../../types/Fisiofit/routine';
import { PrimeIcons } from 'primereact/api';
import { add, addDays, format } from 'date-fns';
import { InputNumber } from 'primereact/inputnumber';
import { mutate } from 'swr';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { Panel, ResourcePanelImplProps } from '../Panel/Panel';
import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from 'primereact/calendar';
import { ExternalUsersSelect } from '../ExternalUsersSelect/ExternalUsersSelect';
import { ExternalUserShort } from '../../types/externalUser';
import { CopyRoutineButton } from '../CopyRoutineButton/CopyRoutineButton';
import { ROUTINE_FORM_VALIDATORS, RoutineForm } from './validators';
import { Tag } from 'primereact/tag';
import { UserDisplay } from '../UserDisplay/UserDisplay';
import { confirmDialog } from 'primereact/confirmdialog';
import { getRoutineDays, getRoutineDaysSeverity, getRoutineDeleteConfirmationDialogProps } from '../../pages/Routines/Routines';
import { Progress } from '../Progress/Progress';
import { unCapitalize } from '../../utils/textUtils';
import { useFeaturesToggle } from '../../hooks/useFeaturesToggle';
import { InputTextarea } from 'primereact/inputtextarea';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ExerciseDayModal } from '../ExerciseDayModal/ExerciseDayModal';
import { OrderList } from 'primereact/orderlist';
import { Capacitor } from '@capacitor/core';
import { ExerciseMediaModal } from '../ExerciseMediaModal/ExerciseMediaModal';

export const MAX_DURATION = 365;

const getToastMessages = (isEditing: boolean): RequestFeedbackMessages => {
  return {
    successMessage: {
      summary: isEditing ? 'Rutina modificada' : 'Rutina creada',
      detail: isEditing ? 'La rutina se ha modificado correctamente' : 'Se ha creado la nueva rutina',
    },
    errorMessages: {
      summary: isEditing ? 'Error modificando la rutina' : 'Error al crear la rutina',
      defaultDetail: isEditing ? 'No se ha podido modificar la rutina' : 'No se ha podido crear la rutina',
      RESOURCE_WITH_SAME_NAME_EXISTS: 'Ya existe una plantilla de rutina con ese nombre',
    },
  };
};

const getRoutinePanelTitle = (isCreating: boolean, isEditing: boolean): string => {
  if (!isCreating && isEditing) {
    return 'Editar rutina';
  }
  if (!isCreating && !isEditing) {
    return 'Detalle de rutina';
  }
  if (isCreating && !isEditing) {
    return 'Crear nueva rutina';
  }
  return 'Rutina';
};

const getUserRoutineName = (externalUser: ExternalUserShort): string => {
  const fullName = `${externalUser.name} ${externalUser.surnames}`;
  return `Rutina de ${fullName}`;
};

const getRoutineDisplayName = (name: string | undefined, isNextRoutine = false): string => {
  if (!name) return '-';
  if (isNextRoutine) return `Próxima ${unCapitalize(name)}`;
  return name;
};

const getInitialRoutineForm = (routine?: Routine): RoutineForm => {
  const { name, exerciseDays, localStartDate, duration, externalUser, description } = routine ?? {};
  return {
    name,
    exerciseDays: exerciseDays?.map((day) => ({ ...day, id: `day-${day.dayNumber}` })) || [],
    duration: duration ?? null,
    localStartDate: localStartDate ?? new Date().toISOString(),
    externalUser,
    description,
    routineType: routine ? (externalUser ? ROUTINE_TYPES.USER : ROUTINE_TYPES.TEMPLATE) : ROUTINE_TYPES.USER,
  };
};

const ROUTINE_TYPE_OPTIONS = [
  { label: 'Rutina de usuario', value: ROUTINE_TYPES.USER },
  { label: 'Plantilla', value: ROUTINE_TYPES.TEMPLATE },
];

interface Props extends Omit<ResourcePanelImplProps<Routine>, 'onDeleteResource'> {
  onDeleteResource?: (id: string | undefined) => Promise<boolean>;
  overrideNextRoutine?: boolean;
  onSaveSuccess?: () => void;
  creatingFromUserProfile?: boolean;
}

export const RoutinePanel = ({
  resource,
  visible,
  onHide,
  onIsEditingChange,
  onDeleteResource,
  onSaveSuccess,
  isEditing = false,
  isDeleting = false,
  overrideNextRoutine = false,
  creatingFromUserProfile = false,
  allowedFeatures,
}: Props) => {
  const [currentRoutine, setCurrentRoutine] = useState<Routine | undefined>(resource);
  const [creatingNewRoutineForExpired, setCreatingNewRoutineForExpired] = useState(false);
  const { post, patch } = useClient();
  const { organization } = useOrganizationContext() ?? {};
  const isCreating = !currentRoutine?.id;
  const isNextRoutine = currentRoutine !== resource && !creatingNewRoutineForExpired;
  const hasNextRoutine = !!currentRoutine?.nextRoutine;
  const userHasEditFeature = allowedFeatures?.edit ? useFeaturesToggle(allowedFeatures?.edit) : true;

  const initialRoutineForm = useMemo(() => getInitialRoutineForm(currentRoutine), [currentRoutine]);
  const { form, setFormFields, setForm, isSaving, setIsSaving, hasChanged, validationErrors, resetForm, validate } =
    useForm<RoutineForm>(initialRoutineForm, !visible, ROUTINE_FORM_VALIDATORS);

  const { daysDifference, elapsedDays, hasStarted } = getRoutineDays(form?.localStartDate, form?.duration);
  const formattedStartDate = form?.localStartDate ? format(new Date(form.localStartDate), "d 'de' MMMM") : null;
  const formattedEndDate =
    form?.localStartDate && form?.duration
      ? format(add(new Date(form.localStartDate), { days: form.duration }), "d 'de' MMMM")
      : null;
  const hasEnded = !!currentRoutine?.duration && daysDifference !== null && daysDifference > currentRoutine?.duration;
  const canEditStartDate = !isNextRoutine && (isCreating || (isEditing && !hasStarted));
  const nextRoutineDate = resource?.localStartDate
    ? addDays(new Date(resource.localStartDate), resource.duration + 1)
    : undefined;
  const minDuration = isEditing && !isNextRoutine ? elapsedDays : 1;
  const daysRemainingSeverity =
    getRoutineDaysSeverity(form?.localStartDate, form?.duration, !!currentRoutine?.nextRoutine, !!currentRoutine?.isExpired) ??
    'success';
  const isEndingSoon = hasStarted && !hasEnded && daysRemainingSeverity !== 'success';

  const title = useMemo(() => getRoutinePanelTitle(isCreating, isEditing), [isCreating, isEditing]);

  const [selectedDay, setSelectedDay] = useState<ExerciseDay | null>(null);
  const [isExerciseDayModalOpen, setIsExerciseDayModalOpen] = useState(false);
  const [isMediaModalVisible, setIsMediaModalVisible] = useState(false);
  const [selectedExerciseData, setSelectedExerciseData] = useState<{
    exerciseId: string;
    videoUrl?: string;
    imageUrl?: string;
    name: string;
  } | null>(null);

  useEffect(() => {
    if (visible) {
      if (overrideNextRoutine) {
        setCurrentRoutine(resource?.nextRoutine);
      } else {
        setCurrentRoutine(resource);
      }
    }
  }, [resource, visible, overrideNextRoutine]);

  const handleSave = async (e?: FormEvent): Promise<boolean> => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const errors = validate();
    if (!form || !organization || errors) return false;
    setIsSaving(true);

    const { externalUser, exerciseDays, routineType, localStartDate, duration, ...restForm } = form;
    const parsedExerciseDays = exerciseDays?.map((day) => {
      const { exercises, dayNumber, id, ...restDay } = day;
      return {
        ...restDay,
        dayNumber,
        exercises: exercises.map(({ name, imageUrl, videoUrl, exerciseType, ...exercise }) => exercise),
      };
    });
    const body = {
      ...restForm,
      exerciseDays: parsedExerciseDays,
      ...(routineType === ROUTINE_TYPES.USER ? { localStartDate, duration } : {}),
      ...(externalUser ? { externalUserId: externalUser.id } : {}),
      ...(isCreating ? { organizationId: organization?.id } : {}),
      ...(isCreating && isNextRoutine ? { previousRoutineId: resource?.id } : {}),
    };

    const { successMessage, errorMessages } = getToastMessages(isEditing);
    const request = {
      body,
      successMessage,
      errorMessages,
    };
    const response =
      isEditing && currentRoutine
        ? await patch<Routine>(`/routines/${currentRoutine.id}`, request)
        : await post<Routine>(`/routines`, request);
    if (response) {
      mutate(`/organizations/${organization?.id}/routines`);
      onSaveSuccess?.();
    }

    setIsSaving(false);
    return !!response;
  };

  const handleCopyRoutine = (routine: Routine) => {
    setFormFields({
      exerciseDays: routine.exerciseDays.map((day) => ({
        ...day,
        id: `day-${day.dayNumber}`,
      })),
    });
  };

  const handleExternalUserChange = (externalUser: ExternalUserShort) => {
    setFormFields({ externalUser, name: getUserRoutineName(externalUser) });
  };

  const goBack = () => {
    onIsEditingChange(false);
    setCurrentRoutine(resource);
    setCreatingNewRoutineForExpired(false);
  };

  const handleGoBack = () => {
    if (hasChanged) {
      confirmDialog({
        message: '¿Deseas regresar a la rutina anterior? Perderás los cambios no guardados.',
        header: 'Regresar a la rutina anterior',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sí, regresar',
        rejectLabel: 'No, continuar editando',
        accept: goBack,
        acceptClassName: 'p-button p-button-text',
        rejectClassName: 'p-button-secondary p-button-text',
        draggable: false,
        resizable: false,
      });
    } else {
      goBack();
    }
  };

  const renderDaysElapsed = () => {
    if (!form?.duration || daysDifference === null) return <></>;
    if (!hasStarted) {
      return (
        <div className='days-info'>
          <i className='pi pi-stopwatch' />
          Aún no ha comenzado
        </div>
      );
    }

    if (currentRoutine?.isExpired) {
      return (
        <div className='days-info'>
          <span className={classNames('days-elapsed-message', 'error')}>
            <i className={PrimeIcons.EXCLAMATION_CIRCLE} />
            Rutina caducada
          </span>
        </div>
      );
    }

    if (hasEnded) {
      return (
        <div className='days-info'>
          <i className='pi pi-trophy' />
          Rutina terminada
        </div>
      );
    }

    return (
      <div className='days-info'>
        <span className='days-elapsed'>
          {elapsedDays} de {form?.duration} días
        </span>
        {currentRoutine && (
          <Progress color={daysRemainingSeverity} value={(elapsedDays / form?.duration) * 100} showValue truncate />
        )}
      </div>
    );
  };

  const handleEditDay = (day: ExerciseDay) => {
    setSelectedDay(day);
    setIsExerciseDayModalOpen(true);
  };

  const handleAddDay = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const newDayNumber = form.exerciseDays?.length + 1 || 1;

    setFormFields({
      exerciseDays: [
        ...(form.exerciseDays || []),
        {
          dayNumber: newDayNumber,
          exercises: [],
          id: `day-${newDayNumber}`,
        },
      ],
    });
  };

  const handleRemoveDay = (dayNumber: number) => {
    confirmDialog({
      message: '¿Estás seguro de que quieres eliminar este día? Se perderán todos los ejercicios asociados.',
      header: 'Confirmar eliminación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sí, eliminar',
      rejectLabel: 'No, volver',
      accept: () => {
        const filteredDays = form.exerciseDays.filter((d) => d.dayNumber !== dayNumber);

        const renumberedDays = filteredDays.map((day, index) => ({
          ...day,
          dayNumber: index + 1,
          id: `day-${index + 1}`,
        }));

        setFormFields({
          exerciseDays: renumberedDays,
        });
      },
      acceptClassName: 'p-button-danger p-button-text',
      rejectClassName: 'p-button-text',
      draggable: false,
      resizable: false,
    });
  };

  const handleSaveExercises = (updatedDays: ExerciseDay[]) => {
    setFormFields({ exerciseDays: updatedDays });
  };

  const renderExerciseDetails = (exercise: RoutineRepetitionExercise | RoutineDurationExercise) => {
    const isRepetitionExercise = (ex: RoutineRepetitionExercise | RoutineDurationExercise): ex is RoutineRepetitionExercise => {
      return 'repetitions' in ex && ex.repetitions !== undefined;
    };

    if (isRepetitionExercise(exercise)) {
      return (
        <>
          {exercise.sets && <span>{exercise.sets} series</span>}
          {exercise.repetitions && <span>{exercise.repetitions} repeticiones</span>}
          {exercise.weight ? <span>{exercise.weight} kg</span> : ''}
          {exercise.rir && <span>{exercise.rir} rir</span>}
        </>
      );
    }
    return (
      <>
        {exercise.sets && <span>{exercise.sets} series</span>}
        {exercise.duration && <span>{exercise.duration} segundos</span>}
        {exercise.weight ? <span>{exercise.weight} kg</span> : ''}
        {exercise.rir && <span>{exercise.rir} rir</span>}
      </>
    );
  };

  const handleReorderDays = (e: { value: (ExerciseDay & { id: string })[] }) => {
    const reorderedDays = e.value.map((day, index) => ({
      ...day,
      dayNumber: index + 1,
    }));

    setFormFields({ exerciseDays: reorderedDays });
  };

  const getVideoEmbedUrl = (url: string) => {
    // YouTube Shorts
    const shortsRegExp = /^.*youtube\.com\/shorts\/([^/?]+)/;
    const shortsMatch = url.match(shortsRegExp);
    if (shortsMatch && shortsMatch[1]) {
      return `https://www.youtube.com/embed/${shortsMatch[1]}`;
    }

    // YouTube
    const youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const youtubeMatch = url.match(youtubeRegExp);
    if (youtubeMatch && youtubeMatch[2].length === 11) {
      return `https://www.youtube.com/embed/${youtubeMatch[2]}`;
    }

    // Google Drive
    const driveRegExp = /^https:\/\/drive\.google\.com\/file\/d\/([^/]+)/;
    const driveMatch = url.match(driveRegExp);
    if (driveMatch) {
      return `https://drive.google.com/file/d/${driveMatch[1]}/preview`;
    }

    // Si no es YouTube, Shorts ni Drive, devolver la URL original
    return url;
  };

  const isEmbeddableVideo = (url: string) => {
    return url.includes('youtube') || url.includes('youtu.be') || url.includes('drive.google.com');
  };

  const handleViewExerciseMedia = async (exercise: RoutineExercise, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (exercise.videoUrl || exercise.imageUrl) {
      setSelectedExerciseData({
        exerciseId: exercise.exerciseId,
        videoUrl: exercise.videoUrl,
        imageUrl: exercise.imageUrl,
        name: exercise.name,
      });
      setIsMediaModalVisible(true);
      return;
    }

    setSelectedExerciseData({
      exerciseId: '',
      name: exercise.name,
    });
    setIsMediaModalVisible(true);
  };

  const renderDayItem = (day: ExerciseDay) => {
    const hasError = day.exercises.length === 0 && !!validationErrors?.exerciseDays;
    return (
      <div className={classNames('exercise-day-item', { 'p-invalid': hasError })}>
        <div className='exercise-day-header'>
          <span className='day-number'>Día {day.dayNumber}</span>
          <span className='exercise-count'>
            {day.exercises.length} ejercicio{day.exercises.length !== 1 ? 's' : ''}
          </span>
          {(isEditing || isCreating) && (
            <div className='day-actions'>
              <Button
                icon='pi pi-pencil'
                className='small-button'
                rounded
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleEditDay(day);
                }}
                text
              />
              <Button
                icon='pi pi-trash'
                rounded
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleRemoveDay(day.dayNumber);
                }}
                text
                className='p-button-danger small-button'
              />
            </div>
          )}
        </div>
        <div className='exercise-day-content'>
          {day.exercises.length > 0 ? (
            day.exercises.map((exercise, index) => (
              <div key={`${exercise.exerciseId}-${index}`} className='exercise-item'>
                <div className='exercise-view-icon'>
                  <Button
                    icon='pi pi-eye'
                    text
                    rounded
                    className='exercise-media-button'
                    onClick={(e) => handleViewExerciseMedia(exercise, e)}
                  />
                </div>
                <div className='exercise-info'>
                  <span className='exercise-name'>{exercise.name}</span>
                  <div className='exercise-details'>
                    {renderExerciseDetails(exercise as RoutineRepetitionExercise | RoutineDurationExercise)}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className='no-exercises error'>
              <i className={PrimeIcons.EXCLAMATION_CIRCLE} />
              <span>Debes añadir al menos un ejercicio</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleFinishRoutine = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!currentRoutine?.id || !organization?.id) return;

    confirmDialog({
      message: `¿Estás seguro de que quieres finalizar esta rutina?\n\n La rutina se terminará, pero se podrá ver en el historial.${hasNextRoutine ? ` La próxima rutina pasará a ser la rutina actual.` : ''}`,
      header: 'Confirmar finalización',
      icon: PrimeIcons.EXCLAMATION_CIRCLE,
      acceptLabel: 'Sí, finalizar',
      rejectLabel: 'Volver',
      draggable: false,
      resizable: false,
      accept: async () => {
        const response = await post(`/routines/${currentRoutine.id}/finish`, {
          successMessage: {
            summary: 'Rutina finalizada',
            detail: 'La rutina se ha finalizado correctamente',
          },
          errorMessages: {
            summary: 'Error al finalizar la rutina',
            defaultDetail: 'No se ha podido finalizar la rutina',
          },
          handlers: {
            defaultSuccess: () => {
              mutate(`/organizations/${organization.id}/routines`);
              onSaveSuccess?.();
              onHide();
              resetForm();
            },
          },
        });
        return !!response;
      },
      acceptClassName: 'p-button-danger p-button-text',
      rejectClassName: 'p-button-text',
    });
  };

  const renderContent = () => {
    return (
      <form
        className={classNames('routine-form', {
          'form-loading': isSaving,
        })}
      >
        <FormField elementId='routineType' labelTitle='Tipo de rutina' fullWidth>
          {isCreating && !isNextRoutine && !isEditing && !creatingFromUserProfile && !creatingNewRoutineForExpired ? (
            <SelectButton
              id='routineType'
              value={form?.routineType}
              options={ROUTINE_TYPE_OPTIONS}
              onChange={(e) => setFormFields({ routineType: e.value, name: undefined, externalUser: undefined })}
              disabled={isEditing}
            />
          ) : (
            <Tag
              value={form?.routineType === ROUTINE_TYPES.USER ? 'Rutina de usuario' : 'Plantilla'}
              {...(form?.routineType === ROUTINE_TYPES.USER ? {} : { severity: 'contrast' })}
            />
          )}
        </FormField>

        <FormField
          labelTitle='Nombre de la rutina'
          elementId='routine-name'
          fullWidth
          error={validationErrors?.name}
          required={(isCreating || isEditing) && form?.routineType === ROUTINE_TYPES.TEMPLATE}
        >
          {(isCreating || isEditing) && form?.routineType === ROUTINE_TYPES.TEMPLATE ? (
            <InputText
              id='routine-name'
              invalid={!!validationErrors?.name}
              placeholder={`Nombre de la plantilla`}
              value={form?.name ?? ''}
              onChange={(e) => setFormFields({ name: e.target.value })}
            />
          ) : (
            <p>{getRoutineDisplayName(form?.name, isNextRoutine)}</p>
          )}
        </FormField>

        {form?.routineType === ROUTINE_TYPES.USER && (
          <FormField
            labelTitle='Usuario asignado'
            elementId='routine-user'
            error={validationErrors?.externalUser}
            required={isCreating}
          >
            {isCreating && !isNextRoutine && !creatingFromUserProfile && !creatingNewRoutineForExpired ? (
              <ExternalUsersSelect
                id='routine-user'
                placeholder='Elige un usuario sin rutina'
                searchPlaceholder='Busca usuarios sin rutina'
                value={form?.externalUser?.id}
                onChange={handleExternalUserChange}
                isSingle
                error={validationErrors?.externalUser}
                filterAvailableForNewRoutine
              />
            ) : (
              <div className='routine-user-container'>
                <UserDisplay
                  name={currentRoutine?.externalUser?.name}
                  surnames={currentRoutine?.externalUser?.surnames}
                  avatarUrl={currentRoutine?.externalUser?.avatarUrl}
                  {...(!isEditing && { navigateTo: `/users/profile/${currentRoutine?.externalUser?.id}` })}
                />
                {!isEditing &&
                  !isCreating &&
                  !isNextRoutine &&
                  !hasEnded &&
                  currentRoutine === resource &&
                  (!hasNextRoutine ? userHasEditFeature : true) && (
                    <Button
                      link
                      className='next-routine-button'
                      label={hasNextRoutine ? 'Ver próxima rutina' : 'Crear próxima rutina'}
                      onClick={() => {
                        if (!hasNextRoutine && currentRoutine?.externalUser) {
                          const partialRoutine = {
                            externalUser: currentRoutine?.externalUser,
                            name: getUserRoutineName(currentRoutine?.externalUser),
                            localStartDate: nextRoutineDate?.toISOString(),
                          } as Routine;
                          setCurrentRoutine(partialRoutine);
                          setForm(getInitialRoutineForm(partialRoutine));
                        } else {
                          setCurrentRoutine(currentRoutine.nextRoutine);
                          setForm(getInitialRoutineForm(currentRoutine.nextRoutine));
                        }
                      }}
                    />
                  )}
                {currentRoutine?.isExpired && userHasEditFeature && (
                  <Button
                    link
                    className='next-routine-button'
                    label='Asignar nueva rutina'
                    onClick={() => {
                      const { organizationId, externalUser, name } = currentRoutine ?? {};
                      const partialRoutine = { organizationId, externalUser, name } as Routine;
                      setCurrentRoutine(partialRoutine);
                      setForm(getInitialRoutineForm(partialRoutine));
                      setCreatingNewRoutineForExpired(true);
                    }}
                  />
                )}
              </div>
            )}
          </FormField>
        )}

        {form?.routineType === ROUTINE_TYPES.USER && (
          <>
            <FormField
              elementId='start-date'
              labelTitle='Fecha de inicio'
              fullWidth
              error={validationErrors?.localStartDate}
              required={canEditStartDate}
            >
              {canEditStartDate ? (
                <Calendar
                  id='start-date'
                  value={form?.localStartDate ? new Date(form.localStartDate) : undefined}
                  onChange={(e) => setFormFields({ localStartDate: e.value?.toISOString() ?? undefined })}
                  dateFormat='dd/mm/yy'
                  placeholder='dd/mm/aaaa'
                  minDate={new Date()}
                  showIcon
                  selectOtherMonths
                  invalid={!!validationErrors?.localStartDate}
                  touchUI={Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches}
                />
              ) : (
                <p>{formattedStartDate}</p>
              )}
            </FormField>
          </>
        )}

        {form?.routineType === ROUTINE_TYPES.USER && (
          <FormField labelTitle='Duración (días)' fullWidth error={validationErrors?.duration} required={isCreating || isEditing}>
            {isCreating || isEditing ? (
              <InputNumber
                value={form?.duration}
                onChange={(e) => {
                  setFormFields({ duration: e.value !== null ? (e.value < minDuration ? minDuration : e.value) : null });
                }}
                allowEmpty={!isEditing}
                invalid={!!validationErrors?.duration}
                placeholder='Ej: 14'
                showButtons
                min={minDuration}
                max={MAX_DURATION}
              />
            ) : (
              <p>{currentRoutine?.duration} días</p>
            )}
          </FormField>
        )}

        {form.routineType === ROUTINE_TYPES.USER && (
          <FormField labelTitle='Estado de la rutina' fullWidth>
            {daysDifference !== null && form?.duration ? (
              <>
                <div className='days-elapsed-container'>{renderDaysElapsed()}</div>

                {!isCreating && isEndingSoon && (
                  <div className={classNames('ending-soon-container', daysRemainingSeverity)}>
                    <i className={classNames('cell-severity-icon', PrimeIcons.EXCLAMATION_CIRCLE)} />
                    <small>
                      {form.duration - elapsedDays === 0
                        ? 'Termina hoy'
                        : form.duration - elapsedDays === 1
                          ? 'Termina mañana'
                          : `Termina en ${form.duration - elapsedDays} días`}
                    </small>
                  </div>
                )}
                <small className='routine-dates'>
                  <i className='pi pi-calendar' />
                  <span>
                    Desde el {formattedStartDate} hasta el {formattedEndDate}
                  </span>
                </small>
              </>
            ) : (
              <p>Introduce fecha y duración para ver el estado de la rutina</p>
            )}
            {isEditing && !isNextRoutine && !hasEnded && !currentRoutine?.isExpired && (
              <Button
                className='finish-routine-button'
                label='Finalizar rutina'
                icon={PrimeIcons.POWER_OFF}
                severity='danger'
                text
                onClick={handleFinishRoutine}
              />
            )}
          </FormField>
        )}

        {form?.routineType === ROUTINE_TYPES.USER && (
          <FormField className='routine-description-field' elementId='routine-description' labelTitle='Indicaciones' fullWidth>
            {isEditing || isCreating ? (
              <InputTextarea
                id='routine-description'
                autoResize
                value={form?.description}
                onChange={(e) => setFormFields({ description: e.target.value })}
                rows={2}
                placeholder={`Escribe indicaciones sobre la rutina para el usuario`}
              />
            ) : (
              <p>{form?.description}</p>
            )}
          </FormField>
        )}

        {(isCreating || isEditing) && (
          <FormField labelTitle='Copiar ejercicios de otra rutina' fullWidth>
            <div className='copy-routine'>
              <small>Puedes copiar todos los ejercicios de otra rutina y usarlos como base para crear esta rutina.</small>
              <CopyRoutineButton onSelectRoutine={handleCopyRoutine} showConfirmation={isEditing} />
            </div>
          </FormField>
        )}

        <FormField
          labelTitle='Días de ejercicios'
          fullWidth
          error={typeof validationErrors?.exerciseDays === 'string' ? validationErrors.exerciseDays : undefined}
          dontShowErrorMessage
          required={isCreating || isEditing}
        >
          <div className='exercise-days-container'>
            {isEditing || isCreating ? (
              <>
                {form.exerciseDays.length > 0 ? (
                  <OrderList
                    value={form.exerciseDays}
                    onChange={handleReorderDays}
                    itemTemplate={renderDayItem}
                    header='Usa los controles para reordenar los días'
                    dataKey='id'
                    dragdrop={false}
                    listStyle={{ maxHeight: 'none' }}
                    autoOptionFocus={false}
                    focusOnHover={false}
                  />
                ) : (
                  <div className={classNames('no-days-message', { 'invalid-form-field': !!validationErrors?.exerciseDays })}>
                    <i className={PrimeIcons.EXCLAMATION_CIRCLE} />
                    <p>Debes añadir al menos un día de ejercicios</p>
                  </div>
                )}
              </>
            ) : (
              <Accordion className='exercise-days-accordion' multiple>
                {form.exerciseDays?.map((day) => (
                  <AccordionTab
                    key={day.dayNumber}
                    header={
                      <div className='exercise-day-header'>
                        <div className='day-info'>
                          <span className='day-number'>Día {day.dayNumber}</span>
                          <span className='exercise-count'>
                            {day.exercises.length} ejercicio{day.exercises.length !== 1 ? 's' : ''}
                          </span>
                        </div>
                      </div>
                    }
                  >
                    <div className='exercise-day-content'>
                      {day.exercises.length > 0 ? (
                        day.exercises.map((exercise, index) => (
                          <div key={`${exercise.exerciseId}-${index}`} className='exercise-item'>
                            <div className='exercise-view-icon'>
                              <Button
                                icon='pi pi-eye'
                                text
                                rounded
                                className='exercise-media-button'
                                onClick={(e) => handleViewExerciseMedia(exercise, e)}
                              />
                            </div>
                            <div className='exercise-info'>
                              <span className='exercise-name'>{exercise.name}</span>
                              <div className='exercise-details'>
                                {renderExerciseDetails(exercise as RoutineRepetitionExercise | RoutineDurationExercise)}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className='no-exercises'>
                          <i className={PrimeIcons.INFO_CIRCLE} />
                          <span>No hay ejercicios asignados para este día</span>
                        </div>
                      )}
                    </div>
                  </AccordionTab>
                ))}
              </Accordion>
            )}

            {(isEditing || isCreating) && (
              <Button
                label='Añadir día'
                icon='pi pi-plus'
                onClick={handleAddDay}
                text={form.exerciseDays.length > 0}
                className={classNames('add-day-button', { 'p-button-outlined': form.exerciseDays.length === 0 })}
              />
            )}
          </div>
        </FormField>
      </form>
    );
  };

  const renderHeaderContent = () => (
    <div className='routine-panel-header'>
      {(isNextRoutine || creatingNewRoutineForExpired) && (
        <Button icon={PrimeIcons.ARROW_LEFT} className='p-button-text' onClick={handleGoBack} />
      )}
      <h4>{title}</h4>
      {hasEnded && !currentRoutine?.isExpired && <Tag value='Rutina pasada' severity='contrast' />}
      {isNextRoutine && <Tag value='Próxima rutina' severity='info' />}
      {!isCreating && isEndingSoon && (
        <Tag value='Termina pronto' severity={daysRemainingSeverity === 'error' ? 'danger' : daysRemainingSeverity} />
      )}
      {currentRoutine?.isExpired && <Tag value='Rutina caducada' severity='danger' />}
    </div>
  );

  const handleHide = () => {
    onHide();
    setCreatingNewRoutineForExpired(false);
  };

  return (
    <Panel
      panelType='resourcePanel'
      resourceName='rutina'
      HeaderComponent={renderHeaderContent()}
      visible={visible}
      onIsEditingChange={onIsEditingChange}
      onHide={handleHide}
      onDelete={onDeleteResource ? () => onDeleteResource(currentRoutine?.id) : undefined}
      isDeleting={isDeleting}
      onSave={handleSave}
      hasChanged={hasChanged}
      isSaving={isSaving}
      isEditing={isEditing}
      isCreating={isCreating}
      className='routine-panel'
      resetForm={resetForm}
      disableButtons={hasEnded}
      allowedFeatures={allowedFeatures}
      customDeleteConfirmationDialogProps={getRoutineDeleteConfirmationDialogProps(resource)}
    >
      {renderContent()}
      {selectedDay && (
        <ExerciseDayModal
          visible={isExerciseDayModalOpen}
          onHide={() => setIsExerciseDayModalOpen(false)}
          day={selectedDay}
          allDays={form.exerciseDays || []}
          onSave={handleSaveExercises}
          isEditing={isEditing || isCreating}
        />
      )}
      {selectedExerciseData && (
        <ExerciseMediaModal
          visible={isMediaModalVisible}
          onHide={() => setIsMediaModalVisible(false)}
          exercise={selectedExerciseData}
          getVideoEmbedUrl={getVideoEmbedUrl}
          isEmbeddableVideo={isEmbeddableVideo}
        />
      )}
    </Panel>
  );
};
