import React from 'react';
import { Blog } from '../pages/Blog/Blog';
import Analytics from '../pages/Analytics/Analytics';
import { Profile } from '../pages/Profile/Profile';
import { NavGroup } from '../types/navigation';
import { COMMON_FEATURES, FISIOFIT_FEATURES } from './features';
import { Login } from '../pages/NoAuthPages/Login';
import { PasswordRecovery } from '../pages/NoAuthPages/PasswordRecovery';
import { PasswordReset } from '../pages/NoAuthPages/PasswordReset';
import { ErrorPage } from '../pages/NoAuthPages/ErrorPage';
import { Users } from '../pages/Users/Users';
import { Employees } from '../pages/Employees/Employees';
import { Join } from '../pages/NoAuthPages/Join';
import { Exercises } from '../pages/Exercises/Exercises';
import { Routines } from '../pages/Routines/Routines';
import { Reservations } from '../pages/Reservations/Reservations';
import { Settings } from '../pages/Settings/Settings';
import { Promotions } from '../pages/Promotions/Promotions';
import { PrimeIcons } from 'primereact/api';

export const PAGES: NavGroup[] = [
  {
    label: 'Web',
    elements: [
      {
        label: 'Analíticas',
        url: '/analytics',
        element: <Analytics />,
        icon: 'pi pi-chart-bar',
        featureCode: COMMON_FEATURES.ANALYTICS,
      },
      {
        label: 'Blog',
        url: '/blog',
        element: <Blog />,
        hasNestedRoutes: true,
        icon: 'pi pi-book',
        featureCode: COMMON_FEATURES.BLOG,
      },
      {
        label: 'Usuarios',
        url: '/users',
        element: <Users />,
        hasNestedRoutes: true,
        icon: 'pi pi-users',
        featureCode: COMMON_FEATURES.EXTERNAL_USERS,
      },
    ],
  },
  {
    label: 'Negocio',
    elements: [
      {
        label: 'Reservas',
        url: '/reservations',
        element: <Reservations></Reservations>,
        icon: 'pi pi-calendar',
        featureCode: COMMON_FEATURES.RESERVATIONS,
      },
      {
        label: 'Rutinas',
        url: '/rutines',
        element: <Routines></Routines>,
        icon: 'pi pi-table',
        featureCode: FISIOFIT_FEATURES.ROUTINES,
      },
      {
        label: 'Ejercicios',
        url: '/exercises',
        element: <Exercises></Exercises>,
        icon: 'pi pi-hammer',
        featureCode: FISIOFIT_FEATURES.EXERCISES,
      },
      {
        label: 'Promociones',
        url: '/promotions',
        element: <Promotions></Promotions>,
        icon: 'pi pi-envelope',
        featureCode: COMMON_FEATURES.PROMOTIONS,
      },
      {
        label: 'Configuración',
        url: '/business-config',
        element: <Settings></Settings>,
        icon: 'pi pi-cog',
        featureCode: COMMON_FEATURES.BUSINESS_CONFIG,
      },
    ],
  },
  {
    label: 'Ajustes',
    elements: [
      {
        label: 'Perfil',
        url: '/user',
        element: <Profile />,
        icon: 'pi pi-user',
      },
      {
        label: 'Empleados',
        url: '/employees',
        element: <Employees />,
        hasNestedRoutes: true,
        icon: 'pi pi-building-columns',
        featureCode: COMMON_FEATURES.USERS,
      },
      {
        label: 'Notificaciones',
        url: '/notifications',
        element: <></>,
        icon: PrimeIcons.BELL,
        featureCode: COMMON_FEATURES.NOTIFICATIONS,
      },
    ],
  },
];

export const LOGIN_PAGES: NavGroup[] = [
  {
    label: 'Páginas sin login',
    elements: [
      {
        url: '/',
        element: <Login />,
      },
      {
        url: '/password-recovery',
        element: <PasswordRecovery />,
      },
      {
        url: '/password-recovery/:token',
        element: <PasswordReset />,
      },
      {
        url: '/join/:userId',
        element: <Join />,
      },
    ],
  },
];

export const ERROR_PAGES: NavGroup[] = [
  {
    label: 'Páginas de error',
    elements: [
      {
        url: '/not-found',
        element: <ErrorPage />,
      },
      {
        url: '/expired-token',
        element: (
          <ErrorPage
            title='Enlace caducado'
            message='El enlace de recuperación tiene más de una hora de antigüedad y ha caducado. Puedes solicitar uno nuevo.'
          />
        ),
      },
      {
        url: '/invalid-token',
        element: (
          <ErrorPage
            title='Enlace inválido'
            message='El enlace de recuperación no existe. Puedes solicitar un enlace nuevo.'
          />
        ),
      },
      {
        url: '/invalid-invitation',
        element: (
          <ErrorPage
            title='Enlace inválido'
            message='El enlace de invitación no es válido.'
          />
        ),
      },
    ],
  },
];
