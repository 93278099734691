import React, { useEffect, useState } from 'react';
import { FormField } from '../FormField/FormField';
import { Calendar } from 'primereact/calendar';
import './TimeRange.scss';
import { classNames } from 'primereact/utils';
import { format, parse, set, addHours } from 'date-fns';
import { Switch } from '../Switch/Switch';
import { TimeSlot } from '../../types/reservationType';
import { Capacitor } from '@capacitor/core';

const MIN_START_TIME = '00:00';
const MIN_END_TIME = '01:00';

const DEFAULT_START_TIME = '08:00';
const DEFAULT_END_TIME = '18:00';

const getHoursValue = (date: Date): string => {
  return format(date, 'HH:mm');
};

const parseTimeToDate = (timeString: string | undefined): Date | undefined => {
  if (!timeString) return;
  const baseDate = new Date();
  const parsedTime = parse(timeString, 'HH:mm', baseDate);
  return set(baseDate, {
    hours: parsedTime.getHours(),
    minutes: parsedTime.getMinutes(),
    seconds: 0,
    milliseconds: 0,
  });
};

// Función para validar y ajustar el rango de tiempo
const validateTimeRange = (start: string | undefined, end: string | undefined): { start: string; end: string } => {
  const startDate = start ? parseTimeToDate(start) : parseTimeToDate(MIN_START_TIME);
  const endDate = end ? parseTimeToDate(end) : parseTimeToDate(MIN_END_TIME);

  if (!startDate || !endDate) {
    return { start: MIN_START_TIME, end: MIN_END_TIME };
  }

  // Si end es menor o igual que start, ajustamos end a start + 1 hora
  if (endDate <= startDate) {
    return {
      start: getHoursValue(startDate),
      end: getHoursValue(addHours(startDate, 1)),
    };
  }

  return {
    start: getHoursValue(startDate),
    end: getHoursValue(endDate),
  };
};

interface TimeRangeProps {
  value: Partial<TimeSlot> | undefined;
  onChange: (value: Partial<TimeSlot> | undefined) => void;
  toggleLabel?: string;
  label?: string;
  isEditable?: boolean;
  labelAsTitle?: boolean;
}

const TimeRange: React.FC<TimeRangeProps> = ({
  toggleLabel,
  value,
  onChange,
  label,
  isEditable = true,
  labelAsTitle = false,
}) => {
  const [enabled, setEnabled] = useState(!!value);

  useEffect(() => {
    if (value && !enabled) {
      setEnabled(true);
    }
  }, [enabled, value]);

  const toggleEnabled = () => {
    if (enabled) {
      onChange(undefined);
    } else {
      onChange({
        start: DEFAULT_START_TIME,
        end: DEFAULT_END_TIME,
      });
    }
    setEnabled(!enabled);
  };

  const handleStartTimeChange = (newStartDate: Date | null) => {
    if (!newStartDate) return;

    const newStart = getHoursValue(newStartDate);
    const { start, end } = validateTimeRange(newStart, value?.end);

    onChange({
      ...value,
      start,
      end,
    });
  };

  const handleEndTimeChange = (newEndDate: Date | null) => {
    if (!newEndDate) return;

    const newEnd = getHoursValue(newEndDate);
    const { start, end } = validateTimeRange(value?.start, newEnd);

    onChange({
      ...value,
      start,
      end,
    });
  };

  const getMinMaxTime = (isStart: boolean): Date => {
    const baseDate = new Date();
    if (isStart) {
      return set(baseDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    }
    return set(baseDate, { hours: 1, minutes: 0, seconds: 0, milliseconds: 0 });
  };

  const renderSwitch = () => (
    <Switch
      id={toggleLabel || label || 'time-range'}
      label={toggleLabel || label || ''}
      primeSwitchProps={{
        checked: enabled,
        onChange: toggleEnabled,
        disabled: !isEditable,
      }}
    />
  );

  return (
    <div className={classNames('time-range', { 'time-range-with-title': labelAsTitle })}>
      {labelAsTitle && label && <h6 className='time-range-title'>{label}</h6>}
      <div className='time-range-content'>
        {renderSwitch()}
        <FormField>
          <Calendar
            placeholder='HH:MM'
            value={parseTimeToDate(value?.start)}
            stepMinute={5}
            onChange={(e) => handleStartTimeChange(e.value as Date)}
            timeOnly
            disabled={!enabled || !isEditable}
            minDate={getMinMaxTime(true)}
            maxDate={value?.end ? parseTimeToDate(value.end) : undefined}
            touchUI={Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches}
          />
        </FormField>
        <FormField>
          <Calendar
            placeholder='HH:MM'
            value={parseTimeToDate(value?.end)}
            stepMinute={5}
            onChange={(e) => handleEndTimeChange(e.value as Date)}
            timeOnly
            disabled={!enabled || !isEditable}
            minDate={value?.start ? addHours(parseTimeToDate(value.start)!, 1) : getMinMaxTime(false)}
            touchUI={Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches}
          />
        </FormField>
      </div>
    </div>
  );
};

export default TimeRange;
