import React, { useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { useClient } from '../../hooks/useClient';
import { ExternalUser, EXTERNAL_USER_STATUSES } from '../../types/externalUser';
import { mutate } from 'swr';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { FormValidators, useForm } from '../../hooks/useForm';
import { FormField } from '../FormField/FormField';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Chip } from '../Chip/Chip';
import './UserProfileAppInvitation.scss';
import { validateEmail } from '../../utils/validationUtils';

interface Props {
  user: ExternalUser | undefined;
  hasEditFeature: boolean;
}

interface InvitationForm {
  email: string;
}

const INITIAL_APP_INVITATION_FORM = {
  email: '',
};

const getAppInvitationFormValidators = (initialEmail: string | undefined): FormValidators<InvitationForm> => ({
  email: (form: Partial<InvitationForm>) => {
    if (!initialEmail && !form.email) return 'El correo es obligatorio';
    if (!initialEmail && form.email && !validateEmail(form.email)) return 'El correo no es válido';
  },
});

export const UserProfileAppInvitation = ({ user, hasEditFeature }: Props) => {
  const { post } = useClient();
  const { organization } = useOrganizationContext() ?? {};
  const [showDialog, setShowDialog] = useState(false);

  const validators = useMemo(() => getAppInvitationFormValidators(user?.email), [user?.email]);
  const { form, setFormFields, validationErrors, validate, hasChanged, isSaving, setIsSaving } = useForm<InvitationForm>(
    INITIAL_APP_INVITATION_FORM,
    !showDialog,
    validators,
  );

  const handleSendInvitation = async () => {
    const errors = validate();
    if (errors || !user?.id || !organization?.id) return;

    setIsSaving(true);

    const response = await post(`/external-users/${user.id}/send-invitation`, {
      body: user.email ? undefined : { email: form.email },
      successMessage: {
        summary: 'Invitación enviada',
        detail: 'La invitación se ha enviado correctamente',
      },
      errorMessages: {
        summary: 'Error al enviar la invitación',
        defaultDetail: 'No se ha podido enviar la invitación',
        USER_EMAIL_EXISTS: 'Ya existe un usuario con este email',
      },
      handlers: {
        defaultSuccess: () => {
          mutate(`/organizations/${organization.id}/external-users/${user.id}`);
          setShowDialog(false);
        },
      },
    });

    setIsSaving(false);
    return !!response;
  };

  const handleResendInvitation = async () => {
    if (!user?.id || !organization?.id) return;

    setIsSaving(true);

    const response = await post(`/external-users/${user.id}/resend-invitation`, {
      successMessage: {
        summary: 'Invitación reenviada',
        detail: 'La invitación se ha reenviado correctamente',
      },
      errorMessages: {
        summary: 'Error al reenviar la invitación',
        defaultDetail: 'No se ha podido reenviar la invitación',
      },
      handlers: {
        defaultSuccess: () => {
          mutate(`/organizations/${organization.id}/external-users/${user.id}`);
        },
      },
    });

    setIsSaving(false);
    return !!response;
  };

  const renderFooter = () => {
    return (
      <div className='dialog-footer'>
        <Button
          label='Cancelar'
          icon={PrimeIcons.TIMES}
          onClick={() => setShowDialog(false)}
          className='p-button-text'
          disabled={isSaving}
        />
        <Button
          label='Enviar invitación'
          icon={PrimeIcons.ENVELOPE}
          onClick={handleSendInvitation}
          loading={isSaving}
          disabled={!user?.email && !hasChanged}
        />
      </div>
    );
  };

  return (
    <div className='app-invitation'>
      <div className='app-invitation-header'>
        <i className='pi pi-mobile'></i>
        <strong>Invitado a app:</strong>{' '}
        <i
          className={classNames('pi', {
            'pi-check-circle': user?.isAppUser,
            'pi-times-circle': !user?.isAppUser,
            success: user?.isAppUser,
            error: !user?.isAppUser,
          })}
        ></i>
        <p>{user?.isAppUser ? 'Sí' : 'No'}</p>
        {user?.pendingInvitation && <Chip>Pendiente</Chip>}
        {user?.isAppUser && hasEditFeature && user?.pendingInvitation && user?.status === EXTERNAL_USER_STATUSES.ACTIVE && (
          <Button icon={PrimeIcons.REPLAY} onClick={handleResendInvitation} className='p-button-text' loading={isSaving} />
        )}
        {!user?.isAppUser && hasEditFeature && user?.status === EXTERNAL_USER_STATUSES.ACTIVE && (
          <Button
            label='Invitar a app'
            icon={PrimeIcons.ENVELOPE}
            onClick={() => setShowDialog(true)}
            className='p-button-text'
          />
        )}
      </div>

      <Dialog
        header='Invitar usuario a la app'
        visible={showDialog}
        onHide={() => setShowDialog(false)}
        footer={renderFooter()}
        className='invitation-dialog'
        dismissableMask
        style={{ width: '450px' }}
        draggable={false}
        resizable={false}
      >
        <p className='dialog-message'>
          {user?.email
            ? '¿Estás seguro de que quieres enviar la invitación a este usuario?'
            : 'El usuario no tiene un correo asignado, es necesario añadirlo para poder enviar la invitación.'}
        </p>
        <form className={classNames({ 'form-loading': isSaving })}>
          {!user?.email && (
            <FormField fullWidth labelTitle='Email' elementId='email' required error={validationErrors?.email}>
              <InputText
                id='email'
                value={form?.email}
                onChange={(e) => setFormFields({ email: e.target.value })}
                placeholder='Email del usuario'
                className={classNames({ 'p-invalid': validationErrors?.email })}
              />
            </FormField>
          )}
        </form>
      </Dialog>
    </div>
  );
};
