import React from 'react';
import './UserPanel.scss';

import { Sidebar } from 'primereact/sidebar';
import { mutate } from 'swr';
import { User } from '../../types/user';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { FormField } from '../FormField/FormField';
import { UserForm } from '../UserForm/UserForm';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { Tag } from 'primereact/tag';
import { resourceMutator } from '../../utils/resourceMutation';

const getUserPanelTitle = (isCreating: boolean, isEditing: boolean) => {
  if (!isCreating && isEditing) {
    return 'Editar empleado';
  }
  if (!isCreating && !isEditing) {
    return 'Detalles de empleado';
  }
  if (isCreating && !isEditing) {
    return 'Añadir empleado';
  }
};

interface Props {
  user: User | undefined;
  visible: boolean;
  onHide: () => void;
  onIsEditingChange: (isEditing: boolean) => void;
  isEditing?: boolean;
}

export const UserPanel = ({ user, visible, onHide, onIsEditingChange, isEditing = false }: Props) => {
  const { organization } = useOrganizationContext() ?? {};
  const isCreating = !user;

  const handleUserFormSave = (user: User) => {
    mutate(`/organizations/${organization?.id}/users`, resourceMutator(user), false);
    onHide();
  };

  return (
    <Sidebar className='user-panel' position='right' visible={visible} onHide={onHide}>
      <h3>{getUserPanelTitle(isCreating, isEditing)}</h3>
      {!isCreating && (
        <div className='readonly-form'>
          <FormField className='align-center' fullWidth>
            <div style={{ position: 'relative' }}>
              <Avatar image={user?.avatarUrl} shape='circle' className='big-avatar' />
            </div>
          </FormField>

          <FormField labelTitle='Nombre'>
            <p>{user?.name}</p>
          </FormField>

          <FormField labelTitle='Apellidos'>
            <p>{user?.surnames}</p>
          </FormField>

          <FormField labelTitle='Correo electrónico'>
            <p>{user?.email}</p>
          </FormField>

          <FormField labelTitle='Fecha de nacimiento'>
            <p>{user?.dateOfBirth ? format(new Date(user.dateOfBirth), "d 'de' LLLL, yyyy") : '-'}</p>
          </FormField>

          <FormField labelTitle='Teléfono'>
            <p>{user?.phone ?? '-'}</p>
          </FormField>

          <FormField labelTitle='Sucursales'>
            <p>{user?.branches?.map((branch) => user?.branches?.find((_) => _.id === branch.id)?.name).join(', ') ?? '-'}</p>
          </FormField>

          <FormField labelTitle='Rol'>
            <p>{user.role?.name ?? '-'}</p>
          </FormField>
        </div>
      )}
      {user?.isInvitation && (
        <>
          <Tag className='user-invited-tag' value='Invitación enviada' />
          <p>El usuario debe aceptar la invitación para unirse a No Gravity.</p>
        </>
      )}
      {(isEditing || isCreating) && <UserForm onSave={handleUserFormSave} user={user} />}
      {!isEditing && !isCreating && !user?.isInvitation && (
        <Button className='pull-right pull-down' label='Editar usuario' onClick={() => onIsEditingChange(true)} />
      )}
    </Sidebar>
  );
};
