const css = `.numeric-filter-container {
  display: flex;
  gap: 8px;
  width: 150px;
  align-items: center;
}
.numeric-filter-container .p-inputtext {
  width: 100% !important;
  min-width: unset;
}
.numeric-filter-container input.p-checkbox-input {
  width: inherit !important;
  min-width: unset;
}

@media screen and (max-width: 768px) {
  .numeric-filter-container {
    width: 100%;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvTnVtZXJpY0ZpbHRlciIsInNvdXJjZXMiOlsiTnVtZXJpY0ZpbHRlci5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0k7RUFDQTtFQUNBO0VBQ0E7O0FBQ0E7RUFDSTtFQUNBOztBQUVKO0VBQ0k7RUFDQTs7O0FBSVI7RUFDSTtJQUNJIiwic291cmNlc0NvbnRlbnQiOlsiLm51bWVyaWMtZmlsdGVyLWNvbnRhaW5lciB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBnYXA6IDhweDtcbiAgICB3aWR0aDogMTUwcHg7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICAucC1pbnB1dHRleHQge1xuICAgICAgICB3aWR0aDogMTAwJSAhaW1wb3J0YW50O1xuICAgICAgICBtaW4td2lkdGg6IHVuc2V0XG4gICAgfVxuICAgIGlucHV0LnAtY2hlY2tib3gtaW5wdXQge1xuICAgICAgICB3aWR0aDogaW5oZXJpdCAhaW1wb3J0YW50O1xuICAgICAgICBtaW4td2lkdGg6IHVuc2V0O1xuICAgIH1cbn1cblxuQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogNzY4cHgpIHtcbiAgICAubnVtZXJpYy1maWx0ZXItY29udGFpbmVyIHtcbiAgICAgICAgd2lkdGg6IDEwMCU7XG4gICAgfVxufSJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
