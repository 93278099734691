import { confirmDialog, ConfirmDialogProps } from 'primereact/confirmdialog';
import { PrimeIcons } from 'primereact/api';

export const showDeleteDialog = (onDelete: () => void, dialogProps?: ConfirmDialogProps) => {
  const {
    message,
    header,
    icon,
    acceptLabel,
    rejectLabel,
    acceptClassName,
    rejectClassName,
    defaultFocus,
    acceptIcon,
    rejectIcon,
  } = dialogProps ?? {};
  confirmDialog({
    message: message || '¿Deseas confirmar la eliminación de este elemento?',
    header: header || 'Confirmar eliminación',
    icon: icon || PrimeIcons.EXCLAMATION_CIRCLE,
    acceptLabel: acceptLabel || 'Sí, eliminar',
    rejectLabel: rejectLabel || 'Volver',
    accept: onDelete,
    acceptClassName: acceptClassName || 'p-button-danger',
    rejectClassName: rejectClassName,
    defaultFocus: defaultFocus || 'reject',
    rejectIcon: rejectIcon || PrimeIcons.TIMES,
    acceptIcon: acceptIcon || PrimeIcons.CHECK,
    draggable: false,
    resizable: false,
  });
};

export const showCancelDialog = (onCancel: () => void) => {
  confirmDialog({
    message: '¿Deseas cancelar la edición? Perderás los cambios no guardados.',
    header: 'Confirmar cancelación',
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: 'Sí, cancelar',
    rejectLabel: 'No, continuar editando',
    accept: onCancel,
    acceptClassName: 'p-button p-button-text',
    rejectClassName: 'p-button-secondary p-button-text',
    draggable: false,
    resizable: false,
  });
};
