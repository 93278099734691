import React from 'react';
import { RadioButton } from 'primereact/radiobutton';
import classNames from 'classnames';
import './RadioButtonGroup.scss';
import { FormFieldError } from '../../hooks/useForm';
import { FormElementError } from '../FormElementError/FormElementError';

export interface RadioOption<T> {
  value: T;
  label: string;
  id: string;
}

interface Props<T> {
  options: RadioOption<T>[];
  value: T | undefined;
  onChange: (value: T) => void;
  name: string;
  error?: FormFieldError;
  className?: string;
}

export const RadioButtonGroup = <T extends string | number | boolean>({
  options,
  value,
  onChange,
  name,
  error,
  className,
}: Props<T>) => {
  return (
    <div className={classNames('radio-button-group', className)}>
      <div className={classNames('radio-options', { 'p-invalid': error })}>
        {options.map((option) => (
          <div key={option.id} className='radio-option'>
            <RadioButton
              inputId={option.id}
              name={name}
              value={option.value}
              onChange={(e) => onChange(e.value)}
              checked={value === option.value}
              className={classNames({ 'p-invalid': error })}
            />
            <label htmlFor={option.id}>{option.label}</label>
          </div>
        ))}
      </div>
      <FormElementError>{typeof error === 'string' ? error : null}</FormElementError>
    </div>
  );
};
