import React, { FormEvent } from 'react';
import './Promotions.scss';

import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { Card } from 'primereact/card';
import { FormField } from '../../components/FormField/FormField';
import { RichTextEditor } from '../../components/RichTextEditor/RichTextEditor';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { useForm } from '../../hooks/useForm';
import { classNames } from 'primereact/utils';
import { ExternalUsersSelect } from '../../components/ExternalUsersSelect/ExternalUsersSelect';
import { useClient } from '../../hooks/useClient';
import { Email } from '../../types/email';
import { ExternalUserShort } from '../../types/externalUser';

interface PromotionsForm {
  users: ExternalUserShort[];
  subject: string;
  content: string;
}

const EMPTY_PROMOTIONS_FORM = {
  users: [],
  subject: '',
  content: '',
};

export const Promotions = () => {
  const { post } = useClient();

  const {
    form,
    setForm,
    setFormField,
    hasChanged,
    isSaving,
    setIsSaving,
    validate,
    validationErrors,
  } = useForm<PromotionsForm>(EMPTY_PROMOTIONS_FORM, true);

  const sendPromotionEmail = async () => {
    const errors = validate();
    if (!form || errors) return;
    setIsSaving(true);
    const { content, subject, users } = form;

    const email: Email = {
      recipients: users.map((_) => _.email),
      subject,
      html: content,
    };

    await post('/email', {
      body: email,
      successMessage: {
        summary: 'Mensaje enviado',
        detail: 'El mensaje se envió correctamente a todos los destinatarios',
      },
    });

    setForm(EMPTY_PROMOTIONS_FORM);
    setIsSaving(false);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    sendPromotionEmail();
  };

  return (
    <PageTemplate className='promotions-page' title='Promociones'>
      <Card>
        <form
          className={classNames({
            'form-loading': isSaving,
          })}
          onSubmit={handleSubmit}
        >
          <FormField labelTitle='Usuarios' elementId='users-dropdown'>
            <ExternalUsersSelect
              id='users-dropdown'
              placeholder='Elige a qué usuarios contactar'
              value={form.users}
              error={validationErrors?.users}
              onChange={(value) => setFormField({ users: value })}
            ></ExternalUsersSelect>
          </FormField>
          <FormField fullWidth labelTitle='Asunto' elementId='subject'>
            <InputText
              id='subject'
              placeholder='Asunto del correo de la promoción'
              value={form.subject}
              onChange={(e) =>
                setFormField({
                  subject: e.target.value,
                })
              }
              className={classNames({ 'p-invalid': validationErrors?.subject })}
            />
          </FormField>
          <FormField fullWidth labelTitle='Contenido' elementId='content'>
            <RichTextEditor
              id='content'
              value={form.content}
              onChange={(value) => setFormField({ content: value })}
              placeholder='Contenido de la promoción'
              className={classNames({ 'p-invalid': validationErrors?.content })}
            />
          </FormField>
          <Button
            className='pull-right'
            label='Enviar'
            icon={PrimeIcons.SEND}
            disabled={!hasChanged}
            loading={isSaving}
            type='submit'
          />
        </form>
      </Card>
    </PageTemplate>
  );
};
