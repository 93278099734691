import React, { useState } from 'react';

import './Roles.scss';
import { Button } from 'primereact/button';
import { ResponsiveTable } from '../../components/ResponsiveTable/ResponsiveTable';
import { TableColumn, TableAction } from '../../types/responsiveTable';
import { PrimeIcons } from 'primereact/api';
import { RolesTableEntry } from '../../types/role';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import useSWR from 'swr';
import { UserShort } from '../../types/user';
import { RolePanel } from '../../components/RolePanel/RolePanel';
import { useResourcePanel } from '../../hooks/useResourcePanel';

const columns: TableColumn<RolesTableEntry>[] = [
  {
    label: 'Nombre',
    accessor: 'name',
    type: 'string',
    isTitle: true,
  },
  {
    label: 'Usuarios usando',
    accessor: 'roleUsers',
    type: 'number',
    valueFromCallBack: (users: UserShort[] | undefined) =>
      users?.length.toString() ?? '0',
  },
  {
    label: 'Fecha de creacion',
    accessor: 'creationDate',
    type: 'calendar',
  },
];

export const Roles = () => {
  const { get } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};

  const { data, isLoading, error } = useSWR(
    organization ? `/organizations/${organization.id}/roles-table` : null,
    get<RolesTableEntry[]>
  );

  const {
    isEditing,
    setIsEditing,
    panelVisible,
    setPanelVisible,
    selectedResource,
    openPanel,
  } = useResourcePanel(data);

  const options: TableAction[] = [
    {
      label: 'Ver',
      onClick: (id) => {
        openPanel(false, id);
      },
    },
    {
      label: 'Editar',
      onClick: (id) => {
        openPanel(true, id);
      },
    },
    {
      label: 'Eliminar',
    },
  ];

  return (
    <>
      <ResponsiveTable
        data={data || []}
        columns={columns}
        options={options}
        loading={organizationLoading || isLoading}
        headerOptions={
          <Button
            label='Añadir rol'
            outlined
            rounded
            icon={PrimeIcons.PLUS}
            onClick={() => {
              openPanel(false);
            }}
          />
        }
        onRowClick={(row) => {
          openPanel(false, row.id);
        }}
      />
      <RolePanel
        role={selectedResource}
        visible={panelVisible}
        onHide={() => {
          setPanelVisible(false);
        }}
        onIsEditingChange={(value) => setIsEditing(value)}
        isEditing={isEditing}
      ></RolePanel>
    </>
  );
};
