import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { USER_ID_STORAGE_KEY } from '../context/AuthContext';

// Solo importamos Capacitor si estamos en nativo
const isNative = Capacitor.isNativePlatform();
let PushNotifications: any;
let LocalNotifications: any;

if (isNative) {
  import('@capacitor/push-notifications').then((module) => {
    PushNotifications = module.PushNotifications;
  });
  import('@capacitor/local-notifications').then((module) => {
    LocalNotifications = module.LocalNotifications;
  });
}

// Configuración de Firebase
const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

class NotificationService {
  private isNative = Capacitor.isNativePlatform();
  private hasPermission = false;
  private firebaseApp;
  private messaging: any;
  private instanceId = Math.random().toString(36).substring(7);
  private updateInterval: NodeJS.Timeout | null = null;
  private isInitialized = false;
  private userId: string | null = null;

  constructor() {
    try {
      this.firebaseApp = initializeApp(firebaseConfig);

      if (this.isNative) {
        // Set up local notification click handler
        if (LocalNotifications) {
          LocalNotifications.addListener(
            'localNotificationActionPerformed',
            (notification: { notification: any; actionData: any }) => {
              this.handleNotificationAction(notification);
            },
          );
        }
        return;
      }
      this.messaging = getMessaging(this.firebaseApp);
      console.log('Firebase messaging initialized');
    } catch (error) {
      console.error('Error initializing Firebase:', error);
    }
  }

  public init(userId: string) {
    if (!this.userId) {
      return null;
    }
    if (this.isInitialized && this.userId === userId) return;

    this.userId = userId;
    if (!this.isNative && this.firebaseApp) {
      try {
        // Escuchar cambios en localStorage
        window.addEventListener('storage', (e) => {
          if (e.key === `dashboard_activeNotificationTab_${userId}` && !e.newValue) {
            // La pestaña activa se cerró, intentar tomar el control
            this.tryBecomeActive(userId);
          }
        });

        this.isInitialized = true;
        // Intentar inicializar las notificaciones
        /*    this.initialize(); */
      } catch (error) {
        console.error('Error initializing messaging:', error);
      }
    }
  }

  private async tryBecomeActive(userId: string) {
    // Esperar un tiempo aleatorio para evitar que todas las pestañas intenten activarse a la vez
    await new Promise((resolve) => setTimeout(resolve, Math.random() * 1000));

    const activeTab = localStorage.getItem(`dashboard_activeNotificationTab_${userId}`);
    if (!activeTab) {
      // No hay pestaña activa, intentar inicializar
      this.initialize();
    }
  }

  private startUpdateInterval(userId: string) {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }

    this.updateInterval = setInterval(() => {
      localStorage.setItem(
        `dashboard_activeNotificationTab_${userId}`,
        JSON.stringify({
          id: this.instanceId,
          timestamp: Date.now(),
        }),
      );
    }, 2000);
  }

  private stopUpdateInterval() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }
  }

  async initialize(): Promise<string | null> {
    return this.isNative ? this.initializeNative() : this.initializeWeb();
  }

  private async initializeWeb(): Promise<string | null> {
    try {
      const userId = localStorage.getItem(USER_ID_STORAGE_KEY);
      this.userId = userId;
      if (!this.userId) {
        console.log('NotificationService: Trying to initialize web notifications but there is no user id');
        return null;
      }

      const activeTab = localStorage.getItem(`dashboard_activeNotificationTab_${this.userId}`);
      const now = Date.now();

      if (activeTab) {
        const { timestamp } = JSON.parse(activeTab);
        if (now - timestamp < 5000) {
          console.log('Ya existe una instancia activa de notificaciones');
          return null;
        }
      }

      localStorage.setItem(
        `dashboard_activeNotificationTab_${this.userId}`,
        JSON.stringify({
          id: this.instanceId,
          timestamp: now,
        }),
      );

      // Iniciar el intervalo de actualización
      this.startUpdateInterval(this.userId);

      // Limpiar cuando la pestaña se cierre
      window.addEventListener('beforeunload', () => {
        this.stopUpdateInterval();
        const activeTab = localStorage.getItem(`dashboard_activeNotificationTab_${this.userId}`);
        if (activeTab) {
          const { id } = JSON.parse(activeTab);
          if (id === this.instanceId) {
            localStorage.removeItem(`dashboard_activeNotificationTab_${this.userId}`);
          }
        }
      });

      if (!('Notification' in window)) {
        console.log('Este navegador no soporta notificaciones');
        return null;
      }

      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        return null;
      }

      this.hasPermission = true;

      if (!('serviceWorker' in navigator)) {
        console.log('Este navegador no soporta Service Workers');
        return null;
      }

      try {
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
          scope: '/',
        });

        if (registration.installing) {
          console.log('Service Worker instalando...');
          await new Promise<void>((resolve) => {
            registration.installing?.addEventListener('statechange', (e) => {
              if ((e.target as ServiceWorker).state === 'activated') {
                resolve();
              }
            });
          });
        }

        await navigator.serviceWorker.ready;

        let currentToken = localStorage.getItem(`fcm_token_${this.userId}`);
        if (!currentToken) {
          localStorage.setItem('fcm_token_requested', 'true');
          currentToken = await getToken(this.messaging, {
            vapidKey: process.env.FIREBASE_VAPID_KEY,
            serviceWorkerRegistration: registration,
          });
          localStorage.setItem(`fcm_token_${this.userId}`, currentToken);
        }

        if (currentToken) {
          onMessage(this.messaging, (payload) => {
            this.handleNotification(payload);
          });

          return currentToken;
        }
        console.log('No se pudo obtener el token de FCM');
        return null;
      } catch (error) {
        console.error('Error al obtener el token de FCM:', error);
        return null;
      }
    } catch (error) {
      console.error('Error initializing web notifications:', error);
      return null;
    }
  }

  private async initializeNative(): Promise<string | null> {
    if (!PushNotifications) {
      console.error('PushNotifications no está disponible');
      return null;
    }

    try {
      const permissionStatus = await PushNotifications.checkPermissions();

      if (permissionStatus.receive === 'prompt') {
        const permission = await PushNotifications.requestPermissions();
        if (permission.receive !== 'granted') {
          return null;
        }
      } else if (permissionStatus.receive !== 'granted') {
        return null;
      }

      this.hasPermission = true;
      await PushNotifications.register();

      return new Promise((resolve) => {
        const registrationListener = PushNotifications.addListener('registration', (token: any) => {
          console.log('FCM registration success:', token.value);
          registrationListener.remove();
          resolve(token.value);
        });

        // Configurar otros listeners
        PushNotifications.addListener('pushNotificationReceived', (notification: any) => this.handleNotification(notification));

        PushNotifications.addListener('pushNotificationActionPerformed', (notification: any) =>
          this.handleNotificationAction(notification),
        );

        // Timeout después de 5 segundos si no recibimos el token
        setTimeout(() => {
          registrationListener.remove();
          resolve(null);
        }, 5000);
      });
    } catch (error) {
      console.error('Error initializing push notifications:', error);
      return null;
    }
  }

  private handleNotification(notification: any) {
    if (this.isNative && LocalNotifications) {
      try {
        LocalNotifications.schedule({
          notifications: [
            {
              title: notification.title || notification.notification?.title,
              body: notification.body || notification.notification?.body,
              id: notification.id || Date.now(),
            },
          ],
        });
      } catch (error) {
        console.error('Error scheduling local notification:', error);
      }
    } else {
      const title = notification.notification?.title || notification.title;
      const options = {
        body: notification.notification?.body || notification.body,
        icon: 'https://s3.eu-north-1.amazonaws.com/cdn.no-gravity.es/dashboard/icon512.webp',
        badge: 'https://s3.eu-north-1.amazonaws.com/cdn.no-gravity.es/dashboard/icon512.webp',
        data: notification.data,
      };
      console.log('Recibido mensaje en foreground: ', title, options);

      const webNotification = new Notification(title, options);

      webNotification.onclick = () => {
        const urlToOpen = notification.data?.link || '/';
        window.focus();
        window.open(urlToOpen, '_blank');
        webNotification.close();
      };
    }
  }

  private handleNotificationAction(actionData: any) {
    console.log('Notification action:', actionData);

    // Extract URL from notification data
    const notification = actionData.notification || actionData.actionData;
    const data = notification.data || {};
    const url = data.url || data.link || '/';

    console.log('Navigation URL:', url);

    // Handle navigation
    if (this.isNative) {
      // For native app, use window.location to ensure proper routing
      window.location.href = url;
    } else {
      window.open(url, '_blank');
    }
  }
}

export const notificationService = new NotificationService();
