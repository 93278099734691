import React from 'react';
import { Blog } from '../pages/Blog/Blog';
import Analytics from '../pages/Analytics/Analytics';
import { Profile } from '../pages/Profile/Profile';
import { NavGroup } from '../types/navigation';
import { COMMON_FEATURES, Feature, FISIOFIT_FEATURES } from './features';
import { Login } from '../pages/NoAuthPages/Login';
import { PasswordRecovery } from '../pages/NoAuthPages/PasswordRecovery';
import { PasswordReset } from '../pages/NoAuthPages/PasswordReset';
import { ErrorPage } from '../pages/NoAuthPages/ErrorPage';
import { Members } from '../pages/Members/Members';

export const PAGES: NavGroup[] = [
  {
    label: 'Web',
    elements: [
      {
        label: 'Analíticas',
        url: '/analytics',
        element: <Analytics />,
        icon: 'pi pi-chart-bar',
        featureCode: COMMON_FEATURES.ANALYTICS,
      },
      {
        label: 'Blog',
        url: '/blog',
        element: <Blog />,
        hasNestedRoutes: true,
        icon: 'pi pi-book',
        featureCode: COMMON_FEATURES.BLOG,
      },
      {
        label: 'Miembros',
        url: '/members',
        element: <Members/>,
        hasNestedRoutes: true,
        icon: 'pi pi-users',
        featureCode: COMMON_FEATURES.USERS,
      },
    ],
  },
  {
    label: 'Negocio',
    elements: [
      {
        label: 'Reservas',
        url: '/reservations',
        element: <></>,
        icon: 'pi pi-calendar',
        featureCode: COMMON_FEATURES.RESERVATIONS,
      },
      {
        label: 'Rutinas',
        url: '/rutines',
        element: <></>,
        icon: 'pi pi-table',
        featureCode: FISIOFIT_FEATURES.ROUTINES,
      },
      {
        label: 'Citas fisio',
        url: '/fisio',
        element: <></>,
        icon: 'pi pi-wave-pulse',
        featureCode: FISIOFIT_FEATURES.FISIO_RESERVATIONS,
      }
    ],
  },
  {
    label: 'Ajustes',
    elements: [
      {
        label: 'Perfil',
        url: '/user',
        element: <Profile />,
        icon: 'pi pi-user',
      },
      {
        label: 'Empleados',
        url: '/employees',
        element: <></>,
        icon: 'pi pi-building-columns',
        featureCode: COMMON_FEATURES.EMPLOYEES,
      },
    ],
  },
];

export const LOGIN_PAGES: NavGroup[] = [
  {
    label: 'Páginas sin login',
    elements: [
      {
        url: '/',
        element: <Login />,
      },
      {
        url: '/password-recovery',
        element: <PasswordRecovery />,
      },
      {
        url: '/password-recovery/:token',
        element: <PasswordReset />,
      },
    ],
  },
];

export const ERROR_PAGES: NavGroup[] = [
  {
    label: 'Páginas de error',
    elements: [
      {
        url: '/not-found',
        element: <ErrorPage />,
      },
      {
        url: '/expired-token',
        element: (
          <ErrorPage
            title='Enlace caducado'
            message='El enlace de recuperación tiene más de una hora de antigüedad y ha caducado. Puedes solicitar uno nuevo.'
          />
        ),
      },
      {
        url: '/invalid-token',
        element: (
          <ErrorPage
            title='Enlace inválido'
            message='El enlace de recuperación no existe. Puedes solicitar un enlace nuevo.'
          />
        ),
      },
    ],
  },
];
