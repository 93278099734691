import { classNames } from 'primereact/utils';
import React, { PropsWithChildren } from 'react';

export type FormElementErrorPosition = 'static' | 'absolute';

interface Props {
  position?: FormElementErrorPosition;
  messageOverride?: string;
}

// TODO extends this so that it will work for all form helper texts
export const FormElementError = ({
  children,
  position = 'absolute',
  messageOverride,
}: PropsWithChildren<Props>) => {
  return (
    <small
      className={classNames({
        static: position === 'static' || !!messageOverride,
        'p-info': !!messageOverride,
        'field-error': !messageOverride || !!children,
      })}
    >
      {messageOverride || children}
    </small>
  );
};
