import React, { PropsWithChildren, createContext, useContext } from 'react';
import { Notification } from '../types/notificationTypes';
import useSWR, { KeyedMutator, useSWRConfig } from 'swr';
import { useClient } from '../hooks/useClient';
import { useAuthContext } from './AuthContext';

interface NotificationContextValue {
  notifications: Notification[] | undefined;
  notificationsLoading: boolean;
  mutateNotifications: KeyedMutator<Notification[] | undefined>;
}

const NotificationContext = createContext<NotificationContextValue | null>(null);

export const NotificationProvider = ({ children }: PropsWithChildren) => {
  const { get } = useClient();
  const { user, userLoading } = useAuthContext() ?? {};
  const { cache } = useSWRConfig();

  const {
    data: notifications,
    isLoading,
    mutate: mutateNotifications,
  } = useSWR(user ? `/notifications/user/${user.id}` : null, get<Notification[]>, {
    refreshInterval: 20000, // Poll every 20 seconds
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    provider: () => cache, // Usa el cache global pero con la configuración concreta para esta llamada
  });

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        notificationsLoading: isLoading || !!userLoading,
        mutateNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};
