import React, { useRef, useState, useMemo, useCallback } from 'react';
import { Calendar, Views, EventProps } from 'react-big-calendar';
import { getDay, parseISO, set, isSameDay, differenceInMinutes } from 'date-fns';
import './Reservations.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Card } from 'primereact/card';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import useSWR from 'swr';
import { Reservation } from '../../types/reservation';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { useClient } from '../../hooks/useClient';
import { useAuthContext } from '../../context/AuthContext';
import { RESERVATION_COLORS, useReservationTypes } from '../../hooks/AppData/useReservationTypes';
import { ReservationPanel, ReservationEvent } from '../../components/ReservationPanel/ReservationPanel';
import { useResourcePanel } from '../../hooks/useResourcePanel';
import { User } from '../../types/user';
import { Toast } from 'primereact/toast';
import { ReservationType } from '../../types/reservationType';
import { calendarLocalizer, calendarMessages } from '../../utils/calendarUtils';
import { ReservationCalendarEvent } from '../../components/ReservationCalendarEvent/ReservationCalendarEvent';
import { SplitButton } from 'primereact/splitbutton';
import { Skeleton } from 'primereact/skeleton';
import { TimeSlot } from '../../types/reservationType';
import { useWindowSize } from '../../hooks/useWindowSize';
import { CalendarFilters } from '../../components/CalendarFilters/CalendarFilters';
import { hasFeaturesPermission } from '../../utils/featureUtils';
import { Capacitor } from '@capacitor/core';
import { usePullToRefreshContext } from '../../context/PullToRefreshContext';
import classNames from 'classnames';

const isNativePlatform = Capacitor.isNativePlatform();

const FALLBACK_DEFAULT_DURATION = 60;

export interface InitialHours {
  start: string;
  end: string;
}

export interface CalendarRestrictions {
  minTime: Date;
  maxTime: Date;
  closedDays: number[];
  closedDates: string[];
  restHour?: TimeSlot;
}

interface RestHourEvent extends ReservationEvent {
  isRestHour: boolean;
}

type CalendarEvent = Reservation | RestHourEvent;

const parseTimeString = (timeStr: string | undefined): Date | undefined => {
  if (!timeStr) return undefined;
  const [hours, minutes] = timeStr.split(':').map(Number);
  const date = new Date(2000, 0, 1); // Usar una fecha fija
  return set(date, { hours, minutes, seconds: 0, milliseconds: 0 });
};

export const getCommonRestrictions = (
  reservationTypes: ReservationType[] | undefined,
  selectedBranchId: string | null,
  selectedReservationType: ReservationType | null,
): CalendarRestrictions => {
  if (!reservationTypes || !reservationTypes.length) {
    const defaultDate = new Date();
    return {
      minTime: set(defaultDate, { hours: 7, minutes: 0, seconds: 0 }),
      maxTime: set(defaultDate, { hours: 22, minutes: 0, seconds: 0 }),
      closedDays: [],
      closedDates: [],
    };
  }

  // Filtrar tipos de reserva según los filtros aplicados
  let filteredTypes = [...reservationTypes];
  if (selectedBranchId) {
    filteredTypes = filteredTypes.filter((type) => type.branchId === selectedBranchId);
  }
  if (selectedReservationType) {
    filteredTypes = filteredTypes.filter((type) => type.name === selectedReservationType.name);
  }

  // Obtener horas de apertura y cierre
  const openingHours = filteredTypes
    .map((type) => {
      const hours = type.hours;
      const weekHours = [
        hours.monday,
        hours.tuesday,
        hours.wednesday,
        hours.thursday,
        hours.friday,
        hours.saturday,
        hours.sunday,
      ];
      return weekHours.filter(Boolean).map((slot) => slot?.start);
    })
    .flat()
    .filter(Boolean)
    .map(parseTimeString)
    .filter(Boolean) as Date[];

  const closingHours = filteredTypes
    .map((type) => {
      const hours = type.hours;
      const weekHours = [
        hours.monday,
        hours.tuesday,
        hours.wednesday,
        hours.thursday,
        hours.friday,
        hours.saturday,
        hours.sunday,
      ];
      return weekHours.filter(Boolean).map((slot) => slot?.end);
    })
    .flat()
    .filter(Boolean)
    .map(parseTimeString)
    .filter(Boolean) as Date[];

  // Encontrar la hora de apertura más temprana y la de cierre más tardía
  const minTime = new Date(Math.min(...openingHours.map((d) => d.getTime())));
  const maxTime = new Date(Math.max(...closingHours.map((d) => d.getTime())));

  // Encontrar días cerrados comunes
  const closedDays = Array.from({ length: 7 }, (_, i) => i).filter((day) => {
    // Un día está cerrado si:
    // 1. Está marcado como cerrado en closedDays
    // 2. O no tiene horas definidas para ese día
    return filteredTypes.every((type) => {
      const daysClosed = type.closedDays?.map((d) => Number(d)) || [];
      const dayHours = (() => {
        switch (day) {
          case 0:
            return type.hours.sunday;
          case 1:
            return type.hours.monday;
          case 2:
            return type.hours.tuesday;
          case 3:
            return type.hours.wednesday;
          case 4:
            return type.hours.thursday;
          case 5:
            return type.hours.friday;
          case 6:
            return type.hours.saturday;
        }
      })();

      return daysClosed.includes(day) || !dayHours;
    });
  });

  // Verificar si todos los tipos tienen el mismo horario de descanso
  const restHours = filteredTypes.map((type) => type.hours.restTime).filter(Boolean);
  const restHour =
    restHours.length === filteredTypes.length &&
    restHours.every((rest) => rest?.start === restHours[0]?.start && rest?.end === restHours[0]?.end)
      ? restHours[0]
      : undefined;

  // Encontrar fechas específicas cerradas (usando closedDays temporalmente hasta que tengamos closedDates)
  const closedDates = filteredTypes
    .map((type) => type.closedDays ?? [])
    .reduce((acc: string[], dates) => {
      if (acc.length === 0) return dates;
      return acc.filter((date) => dates.includes(date));
    }, []);

  return {
    minTime,
    maxTime,
    closedDays,
    closedDates,
    restHour,
  };
};

const getReservationsUrl = (userId: string | undefined) => {
  if (!userId) return null;
  return `/users/${userId}/reservations-calendar`;
};

const ReservationsCalendarSkeleton = () => {
  return (
    <div className='calendar-skeleton-container'>
      <div className='calendar-header' style={{ padding: '0' }}>
        <div className='calendar-toolbar' style={{ flex: '1' }}>
          <Skeleton width='100%' height='40px' />
        </div>
      </div>
      <div className='calendar-content'>
        <Skeleton height='900px' />
      </div>
    </div>
  );
};

const adjustEndTimeToMaxLimit = (start: Date, maxTime: Date, durationMinutes: number = FALLBACK_DEFAULT_DURATION): Date => {
  // Crear una copia de la fecha de inicio
  const end = new Date(start);

  // Añadir la duración deseada en minutos
  end.setMinutes(end.getMinutes() + durationMinutes);

  // Crear una fecha con el mismo día que start pero con la hora de maxTime
  const maxTimeForDay = new Date(start);
  maxTimeForDay.setHours(maxTime.getHours(), maxTime.getMinutes(), 0, 0);

  // Si el tiempo final excede el límite máximo, ajustarlo al límite máximo
  if (end > maxTimeForDay) {
    return maxTimeForDay;
  }

  return end;
};

export const Reservations = () => {
  const toast = useRef<Toast>(null);
  const { get } = useClient();
  const { user, userLoading } = useAuthContext() ?? {};
  const { organizationLoading, orgBranches } = useOrganizationContext() ?? {};
  const { disabledPullToRefresh, setDisabledPullToRefresh } = usePullToRefreshContext();
  const { isMobile } = useWindowSize(true);

  const [selectedBranchIds, setSelectedBranchIds] = useState<string[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<User[]>([]);
  const [selectedReservationTypes, setSelectedReservationTypes] = useState<ReservationType[]>([]);

  const [createButtonReservationType, setCreateButtonReservationType] = useState<ReservationType | null>(null);
  const [initialHours, setInitialHours] = useState<InitialHours | undefined>();
  const [initialTemporaryEvent, setInitialTemporaryEvent] = useState<ReservationEvent | undefined>();

  const {
    reservationTypesLoading,
    reservationTypeFromId,
    uniqueReservationTypes: allUniqueReservationTypes,
    reservationTypes: allReservationTypes,
  } = useReservationTypes();
  const { features, featuresLoading } = useAuthContext() ?? {};

  const uniqueReservationTypes = useMemo(() => {
    return allUniqueReservationTypes.filter((type) =>
      hasFeaturesPermission([type.features.manage], { features, featuresLoading }),
    );
  }, [allUniqueReservationTypes, features, featuresLoading]);
  const reservationTypes = useMemo(() => {
    return allReservationTypes?.filter((type) => hasFeaturesPermission([type.features.manage], { features, featuresLoading }));
  }, [allReservationTypes, features, featuresLoading]);

  const reservationsUrl = useMemo(() => getReservationsUrl(user?.id), [user?.id]);
  const { data, isLoading: reservationsLoading, mutate: mutateReservations } = useSWR(reservationsUrl, get<Reservation[]>);
  const { isEditing, setIsEditing, panelVisible, closePanel, selectedResource, openPanel, isDeleting } = useResourcePanel(data);

  const createButtonItems = useMemo(() => {
    return uniqueReservationTypes.map((type) => ({
      label: type.name,
      icon: PrimeIcons.PLUS,
      command: () => {
        setCreateButtonReservationType(type);
      },
    }));
  }, [uniqueReservationTypes]);

  const handleCreateButtonClick = () => {
    if (!createButtonReservationType) {
      setCreateButtonReservationType(uniqueReservationTypes[0]);
    }
    openPanel(false);
  };

  const onView = useCallback(
    (reservation: Reservation | undefined) => {
      if (reservation) openPanel(false, reservation.id);
    },
    [openPanel],
  );

  // Actualizar la lógica de filtrado para manejar múltiples selecciones
  const filteredReservations = useMemo(() => {
    if (!data) return [];

    let filtered = [...data];

    // Filtro de sucursal (múltiples)
    if (selectedBranchIds.length > 0) {
      filtered = filtered.filter((reservation) => selectedBranchIds.includes(reservation.branch?.id));
    }

    // Filtro de tipo de reserva (múltiples)
    if (selectedReservationTypes.length > 0) {
      filtered = filtered.filter((reservation) => {
        const reservationType = reservationTypeFromId(reservation.reservationTypeId);
        return selectedReservationTypes.some((type) => type.name === reservationType?.name);
      });
    }

    // Filtro de empleado (múltiples)
    if (selectedEmployees.length > 0) {
      filtered = filtered.filter((reservation) => selectedEmployees.some((emp) => emp.id === reservation.user?.id));
    }

    return filtered;
  }, [data, selectedBranchIds, selectedEmployees, selectedReservationTypes, reservationTypeFromId]);

  const calendarRestrictions = useMemo(() => {
    // Para las restricciones del calendario, usamos el primer tipo de reserva seleccionado o ninguna restricción específica
    const selectedType = selectedReservationTypes.length > 0 ? selectedReservationTypes[0] : null;
    // Para las sucursales, usamos la primera sucursal seleccionada o ninguna restricción específica
    const selectedBranchId = selectedBranchIds.length > 0 ? selectedBranchIds[0] : null;

    return getCommonRestrictions(reservationTypes, selectedBranchId, selectedType);
  }, [reservationTypes, selectedBranchIds, selectedReservationTypes]);

  const events = useMemo(() => {
    return filteredReservations;
  }, [filteredReservations]);

  const eventPropGetter = useCallback(
    (event: CalendarEvent) => {
      if ('isRestHour' in event) {
        return {
          style: {
            backgroundColor: 'var(--surface-200)',
            border: '1px solid var(--surface-400)',
            cursor: 'not-allowed',
            pointerEvents: 'none' as const,
          },
        };
      }

      const reservation = event as Reservation;
      const reservationType = reservationTypeFromId(reservation.reservationTypeId);
      const branchIndex = orgBranches?.findIndex((b) => b.id === reservation.branch.id) ?? 0;
      const color = reservationType?.color ?? RESERVATION_COLORS[branchIndex % RESERVATION_COLORS.length];
      return {
        style: {
          backgroundColor: `var(--${color}-100)`,
          border: `1px solid var(--${color}-300)`,
        },
      };
    },
    [reservationTypeFromId, orgBranches],
  );

  const slotPropGetter = useCallback(
    (date: Date) => {
      // Comprobar si la hora está en el periodo de descanso
      if (calendarRestrictions.restHour) {
        const [restStartHour, restStartMinute] = calendarRestrictions.restHour.start.split(':').map(Number);
        const [restEndHour, restEndMinute] = calendarRestrictions.restHour.end.split(':').map(Number);
        const slotHour = date.getHours();
        const slotMinute = date.getMinutes();

        const isInRestHour =
          (slotHour > restStartHour || (slotHour === restStartHour && slotMinute >= restStartMinute)) &&
          (slotHour < restEndHour || (slotHour === restEndHour && slotMinute < restEndMinute));

        if (isInRestHour) {
          return {
            className: 'disabled-day',
            style: { backgroundColor: '#f0f0f0', color: '#ccc' },
          };
        }
      }
      return {};
    },
    [calendarRestrictions.restHour],
  );

  const dayPropGetter = useCallback(
    (date: Date) => {
      const day = getDay(date);
      const isClosedDay = calendarRestrictions.closedDays.includes(day);
      const isClosedDate = calendarRestrictions.closedDates.some((closedDate) => {
        return isSameDay(date, closedDate);
      });

      if (isClosedDay || isClosedDate) {
        return {
          className: 'disabled-day',
          style: { backgroundColor: '#f0f0f0', color: '#ccc' },
        };
      }
      return {};
    },
    [calendarRestrictions.closedDays, calendarRestrictions.closedDates],
  );

  // Actualizar clearFilters para limpiar arrays
  const clearFilters = useCallback(() => {
    setSelectedBranchIds([]);
    setSelectedEmployees([]);
    setSelectedReservationTypes([]);
  }, []);

  // Actualizar la lógica para verificar si hay filtros aplicados
  const hasAppliedFilters = useMemo(
    () => selectedBranchIds.length > 0 || selectedEmployees.length > 0 || selectedReservationTypes.length > 0,
    [selectedBranchIds, selectedEmployees, selectedReservationTypes],
  );

  const handleClose = () => {
    setInitialHours(undefined);
    setInitialTemporaryEvent(undefined);
    closePanel();
  };

  const CalendarEventComponent = useCallback(
    (props: EventProps<CalendarEvent>) => {
      if ('isRestHour' in props.event) {
        return (
          <div style={{ height: '100%', padding: '2px' }}>
            <div style={{ height: '100%', backgroundColor: 'var(--surface-200)', padding: '4px' }}>{props.event.title}</div>
          </div>
        );
      }

      const reservation = props.event as Reservation;
      const reservationType = reservationTypeFromId(reservation.reservationTypeId);
      if (!reservationType) return null;
      return <ReservationCalendarEvent {...(props as EventProps<Reservation>)} reservationType={reservationType} />;
    },
    [reservationTypeFromId],
  );

  const hasBigSlots =
    createButtonReservationType?.fixedDuration || (!createButtonReservationType && uniqueReservationTypes[0]?.fixedDuration);

  const calendarLoading = userLoading || organizationLoading || reservationTypesLoading || reservationsLoading;

  return (
    <PageTemplate className='reservation-page' title='Reservas'>
      <Toast ref={toast} />
      <Card
        className='reservation-card'
        header={
          <div className='reservation-header'>
            <div className='header-left'>
              {!reservationTypesLoading &&
                uniqueReservationTypes &&
                (uniqueReservationTypes.length > 1 ? (
                  <SplitButton
                    label={`Nueva reserva de ${createButtonReservationType?.name ?? uniqueReservationTypes[0]?.name}`}
                    outlined
                    rounded
                    onClick={handleCreateButtonClick}
                    model={createButtonItems}
                  />
                ) : uniqueReservationTypes.length === 1 ? (
                  <Button
                    label={`Nueva reserva de ${createButtonReservationType?.name ?? uniqueReservationTypes[0]?.name}`}
                    outlined
                    rounded
                    onClick={handleCreateButtonClick}
                    icon={PrimeIcons.PLUS}
                  />
                ) : null)}
              {reservationTypesLoading && <Skeleton width='280px' height='40px' />}
            </div>
          </div>
        }
      >
        <CalendarFilters
          selectedBranchIds={selectedBranchIds}
          setSelectedBranchIds={setSelectedBranchIds}
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
          selectedReservationTypes={selectedReservationTypes}
          setSelectedReservationTypes={setSelectedReservationTypes}
          reservationTypes={uniqueReservationTypes}
          hasAppliedFilters={hasAppliedFilters}
          clearFilters={clearFilters}
        />

        {calendarLoading ? (
          <ReservationsCalendarSkeleton />
        ) : (
          <Calendar<CalendarEvent>
            className={classNames('reservation-calendar no-event-label', {
              'big-slots': hasBigSlots,
            })}
            dayLayoutAlgorithm='no-overlap'
            localizer={calendarLocalizer}
            events={events}
            formats={{
              dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
                const startMonth = localizer?.format(start, 'MM', culture);
                const endMonth = localizer?.format(end, 'MM', culture);
                if (startMonth !== endMonth) {
                  return `${localizer?.format(start, "dd 'de' MMMM", culture)} a ${localizer?.format(end, "dd 'de' MMMM", culture)}`;
                }
                return `${localizer?.format(start, 'dd', culture)} a ${localizer?.format(end, "dd 'de' MMMM", culture)}`;
              },
            }}
            startAccessor={(event) => ('localStartDate' in event ? parseISO(event.localStartDate) : event.start)}
            endAccessor={(event) => ('localEndDate' in event ? parseISO(event.localEndDate) : event.end)}
            step={hasBigSlots ? (createButtonReservationType?.defaultDuration ?? FALLBACK_DEFAULT_DURATION) : 30}
            timeslots={1}
            messages={calendarMessages}
            defaultView={isMobile ? Views.DAY : Views.WEEK}
            views={[Views.MONTH, Views.WEEK, Views.DAY]}
            max={calendarRestrictions.maxTime}
            min={calendarRestrictions.minTime}
            defaultDate={new Date()}
            scrollToTime={(() => {
              const adjustedTime = new Date(new Date().setHours(new Date().getHours() - 1, 0, 0, 0));
              return adjustedTime < calendarRestrictions.minTime ? calendarRestrictions.minTime : adjustedTime;
            })()}
            eventPropGetter={eventPropGetter}
            components={{
              event: CalendarEventComponent,
            }}
            onSelectEvent={(event) => {
              if (!('isRestHour' in event)) {
                onView(event);
              }
            }}
            slotPropGetter={slotPropGetter}
            dayPropGetter={dayPropGetter}
            selectable
            onSelectSlot={(slotInfo) => {
              if (disabledPullToRefresh && isNativePlatform) {
                setDisabledPullToRefresh(false);
              }
              if (!createButtonReservationType) {
                setCreateButtonReservationType(uniqueReservationTypes[0]);
              }

              const start = new Date(slotInfo.start);
              const day = getDay(start);

              // Comprobar si el día está cerrado (por día de la semana o fecha específica)
              const isClosedDay = calendarRestrictions.closedDays.includes(day);
              const isClosedDate = calendarRestrictions.closedDates.some((closedDate) => {
                return isSameDay(start, closedDate);
              });

              if (isClosedDay || isClosedDate) {
                return;
              }

              // Comprobar si la hora está en periodo de descanso
              if (calendarRestrictions.restHour) {
                const [restStartHour, restStartMinute] = calendarRestrictions.restHour.start.split(':').map(Number);
                const [restEndHour, restEndMinute] = calendarRestrictions.restHour.end.split(':').map(Number);
                const slotHour = start.getHours();
                const slotMinute = start.getMinutes();

                const isInRestHour =
                  (slotHour > restStartHour || (slotHour === restStartHour && slotMinute >= restStartMinute)) &&
                  (slotHour < restEndHour || (slotHour === restEndHour && slotMinute < restEndMinute));

                if (isInRestHour) {
                  return;
                }
              }

              // Cuando es un click, ajustar la duración a 1 hora sin exceder el límite máximo
              if (slotInfo.action === 'click') {
                const endTime = adjustEndTimeToMaxLimit(start, calendarRestrictions.maxTime);
                slotInfo.end = endTime;
              }

              start.setMilliseconds(0);
              const end = new Date(slotInfo.end);
              end.setMilliseconds(0);

              setInitialHours({ start: start.toISOString(), end: end.toISOString() });
              setInitialTemporaryEvent({
                id: 'temporary',
                title: 'Nueva reserva',
                start,
                end,
              });
              openPanel(false);
            }}
            onSelecting={(event) => {
              if (hasBigSlots) {
                const duration = differenceInMinutes(event.end, event.start);
                if (duration > (createButtonReservationType?.defaultDuration ?? FALLBACK_DEFAULT_DURATION)) {
                  return false;
                }
              }

              if (!disabledPullToRefresh && isNativePlatform) {
                setDisabledPullToRefresh(true);
              }
              return true;
            }}
          />
        )}
      </Card>
      <ReservationPanel
        resource={selectedResource}
        visible={panelVisible}
        onHide={handleClose}
        onIsEditingChange={setIsEditing}
        clearInitialTemporaryEvent={() => setInitialTemporaryEvent(undefined)}
        isDeleting={isDeleting}
        isEditing={isEditing}
        createModeReservationTypeId={createButtonReservationType?.id}
        initialHours={initialHours}
        initialTempEventOverride={initialTemporaryEvent}
        mutateReservations={mutateReservations}
        calendarRestrictions={calendarRestrictions}
      />
    </PageTemplate>
  );
};
