const css = `.fr-box .fr-toolbar {
  border: 1px solid var(--input-border-color) !important;
  border-bottom: unset !important;
}
.fr-box .fr-wrapper {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border: 1px solid var(--input-border-color) !important;
}
.fr-box .fr-wrapper > div:first-child:not(.fr-element) {
  display: none !important;
}
.fr-box .fr-wrapper .fr-element.fr-view {
  font-size: 16px;
}
.fr-box .fr-second-toolbar {
  display: none !important;
}
.fr-box .fr-popup {
  transform: translateX(-40px);
}
.fr-box .fr-view img {
  max-width: 900px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvUmljaFRleHRFZGl0b3IiLCJzb3VyY2VzIjpbIlJpY2hUZXh0RWRpdG9yLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRTtFQUNBOztBQUVGO0VBQ0U7RUFDQTtFQUNBOztBQUNBO0VBQ0U7O0FBRUY7RUFDRTs7QUFHSjtFQUNFOztBQUdGO0VBQ0U7O0FBSUE7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5mci1ib3gge1xuICAuZnItdG9vbGJhciB7XG4gICAgYm9yZGVyOiAxcHggc29saWQgdmFyKC0taW5wdXQtYm9yZGVyLWNvbG9yKSAhaW1wb3J0YW50O1xuICAgIGJvcmRlci1ib3R0b206IHVuc2V0ICFpbXBvcnRhbnQ7XG4gIH1cbiAgLmZyLXdyYXBwZXIge1xuICAgIGJvcmRlci1ib3R0b20tbGVmdC1yYWRpdXM6IDEwcHggIWltcG9ydGFudDtcbiAgICBib3JkZXItYm90dG9tLXJpZ2h0LXJhZGl1czogMTBweCAhaW1wb3J0YW50O1xuICAgIGJvcmRlcjogMXB4IHNvbGlkIHZhcigtLWlucHV0LWJvcmRlci1jb2xvcikgIWltcG9ydGFudDtcbiAgICAmID4gZGl2OmZpcnN0LWNoaWxkOm5vdCguZnItZWxlbWVudCkge1xuICAgICAgZGlzcGxheTogbm9uZSAhaW1wb3J0YW50O1xuICAgIH1cbiAgICAuZnItZWxlbWVudC5mci12aWV3IHtcbiAgICAgIGZvbnQtc2l6ZTogMTZweDtcbiAgICB9XG4gIH1cbiAgLmZyLXNlY29uZC10b29sYmFyIHtcbiAgICBkaXNwbGF5OiBub25lICFpbXBvcnRhbnQ7XG4gIH1cblxuICAuZnItcG9wdXAge1xuICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlWCgtNDBweCk7XG4gIH1cblxuICAuZnItdmlldyB7XG4gICAgaW1nIHtcbiAgICAgIG1heC13aWR0aDogOTAwcHg7XG4gICAgfVxuICB9XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
