import { PrimeIcons } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { MultiStateCheckbox } from "primereact/multistatecheckbox";
import React, { useEffect, useState } from "react";
import './NumericFilter.scss';
import { InputNumber } from "primereact/inputnumber";

interface NumericFilterValue {
    mode: string;
    value: number;
}
interface Props {
  onChange?: (value: NumericFilterValue) => void;
  value: NumericFilterValue;
}

export const NumericFilter = ({ onChange, value }: Props) => {
  const [mode, setMode] = useState<string | undefined>(value.mode ?? 'equal');
  const [newValue, setNewValue] = useState<any>(value.value ?? null);

  const options = [
    { value: "equal", icon: "pi pi-equals" },
    { value: "greater-than", icon: PrimeIcons.CHEVRON_RIGHT },
    { value: "less-than", icon: PrimeIcons.CHEVRON_LEFT },
  ];

  const handleOnChange = (mode?: string, value?: number | null) => {
    setMode(mode);
    setNewValue(value);
    onChange && onChange({ mode, value } as NumericFilterValue);
  };

  return (
    <div className="numeric-filter-container">
      <MultiStateCheckbox
        value={mode}
        onChange={(e) => handleOnChange(e.value, newValue)}
        options={options}
        empty={false}
        optionValue="value"
      />
      <InputNumber
        value={newValue}
        useGrouping={false}
        onChange={(e) => handleOnChange(mode, e.value)}
      />
    </div>
  );
};
