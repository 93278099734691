import axios, { AxiosRequestConfig, Axios, AxiosError } from 'axios';
import { ACCESS_TOKEN_STORAGE_KEY } from '../context/AuthContext';
import { HOST } from '../config/client';
import { useNotifications } from '../context/NotificationContext';
import storage from '../storage/storage';
import { NotificationMessage } from '../types/notificationMessage';
import { Capacitor } from '@capacitor/core';
import { REQUEST_SOURCE_HEADER, REQUEST_SOURCES } from '../config/http';

const AXIOS_METHODS = ['get', 'put', 'post', 'patch', 'delete'];

const isNativePlatform = Capacitor.isNativePlatform();

interface ClientRequestConfig extends AxiosRequestConfig<any> {
  body?: any;
  errorMessages?: RequestErrorMessages;
  successMessage?: NotificationMessage;
  handlers?: StatusHandlers;
}

type StatusHandler = (...args: any) => void;
interface StatusHandlers {
  defaultError?: StatusHandler;
  defaultSuccess?: StatusHandler;
  [key: number]: StatusHandler;
}

export interface RequestErrorMessages {
  summary: string;
  defaultDetail: string;
  [key: number]: string;
}

type AxiosRequest = Axios['get'] | Axios['put'] | Axios['post'] | Axios['patch'] | Axios['delete'];
type ClientRequest = <T>(
  url: string,
  config?: ClientRequestConfig | undefined
) => Promise<T | undefined>;
type Client = {
  get: ClientRequest;
  put: ClientRequest;
  post: ClientRequest;
  patch: ClientRequest;
  delete: ClientRequest;
};

interface ServerResponse<T> {
  data?: T;
  message?: string;
  success: boolean;
}

export const useClient = () => {
  const { showToast } = useNotifications() ?? {};

  const client: Client = AXIOS_METHODS.reduce((acc, method) => {
    return {
      ...acc,
      [method]: async <T,>(url: string, config?: ClientRequestConfig | undefined) => {
        const axiosMethod = axios[method as keyof Axios] as AxiosRequest;
        const { errorMessages, successMessage, handlers, body, ...restConfig } = config ?? {};

        const requestConfig = {
          ...restConfig,
          headers: {
            ...restConfig?.headers,
            Authorization: `Bearer ${await storage.getItem(ACCESS_TOKEN_STORAGE_KEY)}`,
            [REQUEST_SOURCE_HEADER]: isNativePlatform ? REQUEST_SOURCES.MOBILE_APP : '',
          },
          withCredentials: true,
        };
        const finalUrl = `${HOST}${url}`;

        try {
          const { data: serverResponse, status } = body
            ? await axiosMethod<ServerResponse<T>>(finalUrl, body, requestConfig)
            : await axiosMethod<ServerResponse<T>>(finalUrl, requestConfig);

          if (successMessage) {
            showToast?.({
              severity: 'success',
              summary: successMessage.summary,
              detail: successMessage.detail,
            });
          }

          if (handlers?.[status]) {
            handlers[status]();
          } else {
            handlers?.defaultSuccess?.();
          }

          return serverResponse.data;
        } catch (err) {
          if (err instanceof AxiosError && err.status) {
            console.error(err);

            if (err.response?.data.message === 'Invalid token') {
              window.location.assign('/');
              return;
            }

            if (errorMessages) {
              showToast?.({
                summary: errorMessages.summary,
                detail: errorMessages[err.status] ?? errorMessages.defaultDetail,
                severity: 'error',
              });
            }

            if (handlers?.[err.status]) {
              handlers[err.status]();
            } else {
              handlers?.defaultError?.();
            }
          }
        }
      },
    };
  }, {} as Client);

  return client;
};
