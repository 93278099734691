import React, { useState } from 'react';
import { PAGES } from '../../config/pages';
import { SidebarButton } from './SidebarButton/SidebarButton';

import './Sidebar.scss';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { PrimeIcons } from 'primereact/api';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import { Skeleton } from 'primereact/skeleton';
import { Feature } from '../../config/features';
import { Sidebar as SidebarPrime } from 'primereact/sidebar';
import { AVATAR_PLACEHOLDER_URL } from '../../const';

export const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const { signOut, user, features, featuresLoading } = useAuthContext() ?? {};

  const handleSignOut = async () => {
    await signOut?.();
    navigate('/');
  };

  const hasFeature = (feature: Feature | undefined): boolean => {
    if (!feature) return true;
    return !!features?.includes(feature);
  };

  const getAvatarSize = () => {
    return isOpen ? '75px' : '40px';
  };

  const getSidebarContent = (closeOnClick?: boolean) => {
    return (
      <>
        <div className='sidebar-image-container'>
          <img src={user?.avatarUrl || AVATAR_PLACEHOLDER_URL}></img>
        </div>
        <ul>
          {PAGES.map(({ label, elements }) => {
            const hasActiveFeatures = elements.some((_) => hasFeature(_.featureCode));
            if (!hasActiveFeatures) return;
            return (
              <li className='sidebar-section' key={label}>
                <p className='sidebar-section-title'>{label}</p>
                <div className='section-divider'></div>
                <ul>
                  {elements.map(({ url, label = '', icon, featureCode }) => (
                    <li key={url} onClick={() => closeOnClick && setIsOpen(true)}>
                      {hasFeature(featureCode) ? (
                        <SidebarButton href={url} label={label} icon={icon}></SidebarButton>
                      ) : (
                        <></>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
            );
          })}
          <li>
            <SidebarButton
              key='sign-out'
              onClick={handleSignOut}
              label={'Cerrar sesión'}
              icon={PrimeIcons.SIGN_OUT}
              href='/sign-out'
            ></SidebarButton>
          </li>
        </ul>
      </>
    );
  };

  return (
    <nav
      className={classNames('sidebar', {
        open: isOpen,
      })}
    >
      <Button onClick={() => setIsOpen(!isOpen)} icon='pi pi-bars'></Button>
      {featuresLoading ? (
        <>
          <Skeleton
            height={getAvatarSize()}
            width={getAvatarSize()}
            className='avatar-skeleton'
            shape='circle'
          />
          {Array.from({ length: 12 }).map((_, index) => (
            <Skeleton key={index} width='unset' height='20px' className='nav-skeleton' />
          ))}
        </>
      ) : (
        <>{getSidebarContent()}</>
      )}
      <SidebarPrime
        className='mobile-sidebar'
        visible={!isOpen}
        onHide={() => setIsOpen(true)}
        position='left'
      >
        {getSidebarContent(true)}
      </SidebarPrime>
    </nav>
  );
};
