import React, { useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { PrimeIcons } from 'primereact/api';
import { useClient } from '../../../hooks/useClient';
import { ExternalUser } from '../../../types/externalUser';
import { mutate } from 'swr';
import { useOrganizationContext } from '../../../context/OrganizationContext';
import { useForm } from '../../../hooks/useForm';
import { ConfirmDialog } from 'primereact/confirmdialog';

const MAX_DIAGNOSTIC_CHARS = 900;

interface DiagnosticFieldProps {
  diagnostic: string | undefined;
  externalUserId: string | undefined;
  hasEditFeature: boolean;
}

interface DiagnosticForm {
  diagnostic: string;
}

const getInitialForm = (diagnostic: string | undefined): DiagnosticForm => ({
  diagnostic: diagnostic ?? '',
});

export const DiagnosticField = ({ diagnostic, externalUserId, hasEditFeature }: DiagnosticFieldProps) => {
  const { patch } = useClient();
  const { organization } = useOrganizationContext() ?? {};
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [wasOpenedInEditMode, setWasOpenedInEditMode] = useState(false);
  const [showConfirmClose, setShowConfirmClose] = useState(false);
  const [closeAction, setCloseAction] = useState<() => void>(() => () => {});

  const initialForm = useMemo(() => getInitialForm(diagnostic), [diagnostic]);
  const { form, setFormFields, isSaving, setIsSaving, hasChanged, resetForm } = useForm<DiagnosticForm>(initialForm, !showModal);

  const truncatedText = useMemo(() => {
    if (!diagnostic) return '';
    if (diagnostic.length <= MAX_DIAGNOSTIC_CHARS) return diagnostic;
    return diagnostic.slice(0, MAX_DIAGNOSTIC_CHARS) + '...';
  }, [diagnostic]);

  const needsTruncation = diagnostic && diagnostic.length > MAX_DIAGNOSTIC_CHARS;

  const handleSave = async () => {
    if (!organization || !externalUserId || !form) return;
    setIsSaving(true);

    const response = await patch<ExternalUser>(`/external-users/${externalUserId}`, {
      body: { diagnostic: form.diagnostic },
      successMessage: {
        summary: 'Diagnóstico actualizado',
        detail: 'El diagnóstico ha sido actualizado correctamente',
      },
      errorMessages: {
        summary: 'Error al actualizar el diagnóstico',
        defaultDetail: 'No se ha podido actualizar el diagnóstico',
      },
    });

    if (response) {
      mutate(`/organizations/${organization.id}/external-users/${externalUserId}`);
      setIsEditing(false);
      setShowModal(false);
    }
    setIsSaving(false);
  };

  const handleOpenModal = (startEditing: boolean = false) => {
    resetForm();
    setIsEditing(startEditing);
    setWasOpenedInEditMode(startEditing);
    setShowModal(true);
  };

  const handleCancelEdit = () => {
    const action = () => {
      if (wasOpenedInEditMode) {
        setShowModal(false);
      } else {
        setIsEditing(false);
      }
      resetForm();
    };

    if (hasChanged) {
      setCloseAction(() => action);
      setShowConfirmClose(true);
    } else {
      action();
    }
  };

  const handleHideModal = () => {
    const action = () => {
      setShowModal(false);
      resetForm();
    };

    if (hasChanged && isEditing) {
      setCloseAction(() => action);
      setShowConfirmClose(true);
    } else {
      action();
    }
  };

  const renderModalFooter = () => {
    if (!isEditing) {
      return (
        <div className='dialog-footer'>
          <Button key='close-button' label='Cerrar' icon={PrimeIcons.TIMES} onClick={handleHideModal} className='p-button-text' />
          {hasEditFeature && (
            <Button
              key='edit-button'
              label='Editar'
              icon={PrimeIcons.PENCIL}
              onClick={() => setIsEditing(true)}
              className='p-button-primary'
            />
          )}
        </div>
      );
    }

    return (
      <div className='dialog-footer'>
        <Button
          key='cancel-button'
          label='Cancelar'
          severity='secondary'
          onClick={handleCancelEdit}
          className='p-button-text'
          disabled={isSaving}
        />
        <Button
          key='save-button'
          label='Guardar'
          icon={PrimeIcons.CHECK}
          onClick={handleSave}
          loading={isSaving}
          disabled={!hasChanged || isSaving}
          className='p-button-primary'
        />
      </div>
    );
  };

  return (
    <div className='diagnostic-field'>
      <div className='diagnostic-header'>
        <strong>Diagnóstico</strong>
        {hasEditFeature && (
          <Button
            icon={PrimeIcons.PENCIL}
            onClick={() => handleOpenModal(true)}
            text
            rounded
            className='p-button-text edit-button-small'
          />
        )}
      </div>
      <p>
        {truncatedText}
        {needsTruncation && <Button label='Ver más' onClick={() => handleOpenModal(false)} link className='see-more-button' />}
      </p>

      <Dialog
        header='Diagnóstico'
        visible={showModal}
        onHide={handleHideModal}
        className='diagnostic-dialog big-modal'
        footer={renderModalFooter()}
        draggable={false}
        resizable={false}
        dismissableMask
      >
        {isEditing ? (
          <InputTextarea
            value={form?.diagnostic ?? ''}
            onChange={(e) => setFormFields({ diagnostic: e.target.value })}
            rows={5}
            autoResize
            className='w-full'
          />
        ) : (
          <p className='diagnostic-text'>{diagnostic}</p>
        )}
      </Dialog>

      <ConfirmDialog
        visible={showConfirmClose}
        onHide={() => setShowConfirmClose(false)}
        message='¿Quieres cerrar la ventana? Perderás los cambios sin guardar.'
        header='Confirmar cierre'
        icon='pi pi-exclamation-triangle'
        accept={() => {
          closeAction();
          setShowConfirmClose(false);
        }}
        reject={() => setShowConfirmClose(false)}
        acceptLabel='Sí'
        rejectLabel='No'
      />
    </div>
  );
};
