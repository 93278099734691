import React from 'react';
import './ExerciseMediaModal.scss';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { classNames } from 'primereact/utils';

interface ExerciseMediaProps {
  visible: boolean;
  onHide: () => void;
  exercise: {
    exerciseId: string;
    videoUrl?: string;
    imageUrl?: string;
    name: string;
  };
  getVideoEmbedUrl: (url: string) => string;
  isEmbeddableVideo: (url: string) => boolean;
}

export const ExerciseMediaModal: React.FC<ExerciseMediaProps> = ({
  visible,
  onHide,
  exercise,
  getVideoEmbedUrl,
  isEmbeddableVideo,
}) => {
  const hasMedia = exercise.videoUrl || exercise.imageUrl;

  const renderContent = () => {
    if (!hasMedia) {
      return (
        <div className='no-media-message'>
          <i className='pi pi-image'></i>
          <p>Este ejercicio no tiene vídeo ni imagen asociada</p>
        </div>
      );
    }

    if (exercise.videoUrl) {
      return (
        <div className={classNames('preview-container', 'video-container')}>
          {isEmbeddableVideo(exercise.videoUrl) ? (
            <iframe
              className='preview-video'
              src={getVideoEmbedUrl(exercise.videoUrl)}
              title={`Vídeo de ${exercise.name}`}
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          ) : (
            <video className='preview-video' controls>
              <source src={exercise.videoUrl} type='video/mp4' />
              <source src={exercise.videoUrl} type='video/webm' />
              <source src={exercise.videoUrl} type='video/ogg' />
              Tu navegador no soporta el elemento de vídeo.
            </video>
          )}
        </div>
      );
    }

    if (exercise.imageUrl) {
      return (
        <div className='preview-container'>
          <Image
            className='preview-image'
            src={exercise.imageUrl}
            preview={true}
            alt={`Imagen de ${exercise.name}`}
            imageStyle={{ maxHeight: '70vh' }}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <Dialog
      header={`${exercise.name}`}
      visible={visible}
      onHide={onHide}
      className={classNames('exercise-media-modal', 'big-modal')}
      dismissableMask
      closeOnEscape
      draggable={false}
      resizable={false}
      contentStyle={{ overflow: 'visible' }}
    >
      <div className='exercise-media-content'>{renderContent()}</div>
    </Dialog>
  );
};
