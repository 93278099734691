import React, { ReactNode, useEffect, useRef } from 'react';
import { PullToRefresh } from 'react-js-pull-to-refresh';
import './PullToRefresh.scss';
import { Capacitor } from '@capacitor/core';
import { usePullToRefreshContext } from '../../context/PullToRefreshContext';
import classNames from 'classnames';

const isNativePlatform = Capacitor.isNativePlatform();

interface PullToRefreshProps {
  children: ReactNode;
  onRefresh: () => Promise<any>;
  pullDownThreshold?: number;
  backgroundColor?: string;
  containerClassName?: string;
}

export function PullToRefreshComponent({
  children,
  onRefresh,
  pullDownThreshold = 150,
  backgroundColor = 'var(--background)',
  containerClassName = '',
}: PullToRefreshProps) {
  const { disabledPullToRefresh } = usePullToRefreshContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLElement | null>(null);
  const breadcrumbsRef = useRef<HTMLElement | null>(null);

  // Encontramos los elementos header y breadcrumbs
  useEffect(() => {
    if (isNativePlatform) {
      headerRef.current = document.querySelector('.page-template-header');
      breadcrumbsRef.current = document.querySelector('.p-breadcrumb');
    }
  }, []);

  // Función para manejar el refresco con feedback visual
  const handleRefresh = async () => {
    try {
      const ptrContainer = document.querySelector('#ptr-container');
      if (ptrContainer && ptrContainer instanceof HTMLElement) {
        ptrContainer.style.transform = 'none';
      }
      return await onRefresh();
    } catch (error) {
      console.error('Error al refrescar:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (!isNativePlatform) return;

    const preventDefaultForScrolling = (e: TouchEvent) => {
      const target = e.target as Node;
      const header = headerRef.current;
      const breadcrumbs = breadcrumbsRef.current;

      if (
        document.scrollingElement &&
        document.scrollingElement.scrollTop <= 0 &&
        ((header && (header === target || header.contains(target))) ||
          (breadcrumbs && (breadcrumbs === target || breadcrumbs.contains(target))))
      ) {
        e.preventDefault();
      }
    };

    if (headerRef.current) {
      headerRef.current.addEventListener('touchmove', preventDefaultForScrolling, { passive: false });
    }

    if (breadcrumbsRef.current) {
      breadcrumbsRef.current.addEventListener('touchmove', preventDefaultForScrolling, { passive: false });
    }

    return () => {
      if (headerRef.current) {
        headerRef.current.removeEventListener('touchmove', preventDefaultForScrolling);
      }

      if (breadcrumbsRef.current) {
        breadcrumbsRef.current.removeEventListener('touchmove', preventDefaultForScrolling);
      }
    };
  }, []);

  useEffect(() => {
    if (!isNativePlatform) return;

    const handleTouchStart = (e: TouchEvent) => {
      const target = e.target as Node;
      const header = headerRef.current;
      const breadcrumbs = breadcrumbsRef.current;

      if (
        document.scrollingElement &&
        document.scrollingElement.scrollTop <= 0 &&
        ((header && (header === target || header.contains(target))) ||
          (breadcrumbs && (breadcrumbs === target || breadcrumbs.contains(target))))
      ) {
        document.body.classList.add('pull-to-refresh-active');
      }
    };

    const handleTouchEnd = () => {
      document.body.classList.remove('pull-to-refresh-active');
    };

    if (headerRef.current) {
      headerRef.current.addEventListener('touchstart', handleTouchStart);
      headerRef.current.addEventListener('touchend', handleTouchEnd);
      headerRef.current.addEventListener('touchcancel', handleTouchEnd);
    }

    if (breadcrumbsRef.current) {
      breadcrumbsRef.current.addEventListener('touchstart', handleTouchStart);
      breadcrumbsRef.current.addEventListener('touchend', handleTouchEnd);
      breadcrumbsRef.current.addEventListener('touchcancel', handleTouchEnd);
    }

    return () => {
      if (headerRef.current) {
        headerRef.current.removeEventListener('touchstart', handleTouchStart);
        headerRef.current.removeEventListener('touchend', handleTouchEnd);
        headerRef.current.removeEventListener('touchcancel', handleTouchEnd);
      }

      if (breadcrumbsRef.current) {
        breadcrumbsRef.current.removeEventListener('touchstart', handleTouchStart);
        breadcrumbsRef.current.removeEventListener('touchend', handleTouchEnd);
        breadcrumbsRef.current.removeEventListener('touchcancel', handleTouchEnd);
      }

      document.body.classList.remove('pull-to-refresh-active');
    };
  }, []);

  const PullDownContent = ({ height = '200px', background = 'none', label = 'Desliza hacia abajo para actualizar' }) => (
    <div className='ptr-pull-down-content' style={{ background, height }}>
      <div className='ptr-arrow-down' />
      <span>{label}</span>
    </div>
  );

  const ReleaseContent = ({ height = '200px', background = 'none', label = 'Suelta para actualizar' }) => (
    <div className='ptr-release-content' style={{ background, height }}>
      <div className='ptr-arrow-up' />
      <span>{label}</span>
    </div>
  );

  const containerClassNames = classNames('pull-to-refresh-container', containerClassName, {
    'ptr-disabled': disabledPullToRefresh,
  });

  if (!isNativePlatform) {
    return <div className={containerClassNames}>{children}</div>;
  }

  return (
    <div ref={containerRef} className={containerClassNames}>
      <PullToRefresh
        pullDownContent={<PullDownContent />}
        releaseContent={<ReleaseContent />}
        refreshContent={<></>}
        pullDownThreshold={disabledPullToRefresh ? 10000 : pullDownThreshold}
        onRefresh={handleRefresh}
        triggerHeight='auto'
        backgroundColor={backgroundColor}
      >
        {children}
      </PullToRefresh>
    </div>
  );
}
