const css = `.time-slot-picker {
  overflow: scroll;
}
.time-slot-picker::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.time-slot-picker::-webkit-scrollbar-track {
  background: transparent;
}
.time-slot-picker::-webkit-scrollbar-thumb {
  background: transparent;
}
.time-slot-picker .time-slot-buttons {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
}
.time-slot-picker .time-slot-buttons .p-button-label {
  font-size: 12px;
}
.time-slot-picker .time-slot-buttons .p-button {
  height: unset;
}
.time-slot-picker .time-slot-buttons .p-button.p-button-primary {
  background-color: var(--disruptive);
  border-color: var(--disruptive);
  color: #fff;
}
.time-slot-picker .time-slot-buttons .p-button.p-button-outlined {
  border-color: #ddd;
  color: #555;
  background-color: transparent;
}
.time-slot-picker .time-slot-buttons .p-button.p-button-outlined:hover {
  background-color: #f5f5f5;
}
.time-slot-picker .time-slot-buttons .p-button:active, .time-slot-picker .time-slot-buttons .p-button:focus {
  box-shadow: none;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvVGltZVNsb3RQaWNrZXIiLCJzb3VyY2VzIjpbIlRpbWVTbG90UGlja2VyLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTs7QUFDQTtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFOztBQUVGO0VBQ0U7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBR0Y7RUFDRTs7QUFDQTtFQUNFO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOztBQUdKO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIudGltZS1zbG90LXBpY2tlciB7XG4gIG92ZXJmbG93OiBzY3JvbGw7XG4gICY6Oi13ZWJraXQtc2Nyb2xsYmFyIHtcbiAgICB3aWR0aDogMHB4O1xuICAgIGhlaWdodDogMHB4O1xuICB9XG5cbiAgJjo6LXdlYmtpdC1zY3JvbGxiYXItdHJhY2sge1xuICAgIGJhY2tncm91bmQ6IHRyYW5zcGFyZW50O1xuICB9XG5cbiAgJjo6LXdlYmtpdC1zY3JvbGxiYXItdGh1bWIge1xuICAgIGJhY2tncm91bmQ6IHRyYW5zcGFyZW50O1xuICB9XG4gIC50aW1lLXNsb3QtYnV0dG9ucyB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LXdyYXA6IG5vd3JhcDtcbiAgICBnYXA6IDEwcHg7XG5cbiAgICAucC1idXR0b24tbGFiZWwge1xuICAgICAgZm9udC1zaXplOiAxMnB4O1xuICAgIH1cblxuICAgIC5wLWJ1dHRvbiB7XG4gICAgICBoZWlnaHQ6IHVuc2V0O1xuICAgICAgJi5wLWJ1dHRvbi1wcmltYXJ5IHtcbiAgICAgICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tZGlzcnVwdGl2ZSk7XG4gICAgICAgIGJvcmRlci1jb2xvcjogdmFyKC0tZGlzcnVwdGl2ZSk7XG4gICAgICAgIGNvbG9yOiAjZmZmO1xuICAgICAgfVxuXG4gICAgICAmLnAtYnV0dG9uLW91dGxpbmVkIHtcbiAgICAgICAgYm9yZGVyLWNvbG9yOiAjZGRkO1xuICAgICAgICBjb2xvcjogIzU1NTtcbiAgICAgICAgYmFja2dyb3VuZC1jb2xvcjogdHJhbnNwYXJlbnQ7XG5cbiAgICAgICAgJjpob3ZlciB7XG4gICAgICAgICAgYmFja2dyb3VuZC1jb2xvcjogI2Y1ZjVmNTtcbiAgICAgICAgfVxuICAgICAgfVxuICAgICAgJjphY3RpdmUsICY6Zm9jdXMge1xuICAgICAgICBib3gtc2hhZG93OiBub25lO1xuICAgIH1cbiAgICB9XG4gIH1cbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
