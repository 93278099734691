import React, { useMemo } from 'react';
import { Branch } from '../../types/branch';
import './CompactBranchSelect.scss';
import { AdaptiveSelect } from '../AdaptiveSelect/AdaptiveSelect';

interface Props {
  value: string[] | string | null;
  onChange: (branchIds: string[] | string | null) => void;
  branches: Branch[];
  multiple?: boolean;
  className?: string;
  appendTo?: HTMLElement | 'self' | (() => HTMLElement) | null;
  isFilter?: boolean;
}

interface BranchOption {
  label: string;
  value: string;
}

export const CompactBranchSelect = ({
  value,
  onChange,
  branches,
  multiple = false,
  className,
  appendTo = 'self',
  isFilter = false,
}: Props) => {
  const branchOptions = useMemo(() => {
    return branches.map((branch) => ({
      label: branch.name,
      value: branch.id,
    }));
  }, [branches]);

  const itemTemplate = (option: BranchOption | null) => {
    if (!option) return null;

    return (
      <div className='compact-branch-item'>
        <span className='compact-branch-name'>{option.label}</span>
      </div>
    );
  };

  const selectedItemTemplate = (option: BranchOption | null) => {
    if (!option) return null;

    if (isFilter) {
      return null;
    }

    return (
      <div className='compact-branch-item'>
        <span className='compact-branch-name'>{option.label}</span>
      </div>
    );
  };

  const handleChange = (selectedValue: string | string[] | null) => {
    onChange(selectedValue);
  };

  return (
    <AdaptiveSelect
      value={value}
      onChange={handleChange}
      options={branchOptions}
      className={className}
      itemTemplate={itemTemplate}
      selectedItemTemplate={selectedItemTemplate}
      isMultiple={multiple}
      emptyFilterMessage='No se encontraron sucursales'
      placeholder='Buscar sucursal...'
      modalTitle='Seleccionar sucursal'
      searchPlaceholder='Buscar sucursal'
      isFilter={isFilter}
    />
  );
};
