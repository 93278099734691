import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { classNames } from 'primereact/utils';
import { TableColumn, SortState } from '../../types/responsiveTable';

interface Props<T extends Record<string, any>> {
  visible: boolean;
  onHide: () => void;
  columns: TableColumn<T>[];
  sortState: SortState;
  onSort: (field: string) => void;
}

export const SortMenu = <T extends Record<string, any>>({ visible, onHide, columns, sortState, onSort }: Props<T>) => {
  const getSortIcon = (column: TableColumn<T>) => {
    const isSorted = sortState.field === column.accessor;
    const direction = isSorted ? sortState.direction : 'none';

    return (
      <i
        className={classNames('sort-icon pi', {
          [PrimeIcons.SORT_ALT]: direction === 'none',
          [PrimeIcons.SORT_AMOUNT_UP_ALT]: direction === 'asc',
          [PrimeIcons.SORT_AMOUNT_DOWN]: direction === 'desc',
        })}
      />
    );
  };

  return (
    <Sidebar className='sort-sidebar' position='right' visible={visible} onHide={onHide}>
      <div className='sort-sidebar-header'>
        <h3>Ordenar por</h3>
      </div>
      <div className='sort-sidebar-container'>
        {columns.map(
          (column) =>
            column.type !== 'avatar' &&
            column.sortable !== false && (
              <Button
                key={column.accessor}
                className={classNames('sort-option', {
                  active: sortState.field === column.accessor,
                })}
                onClick={() => onSort(column.accessor)}
                text
              >
                <span className='sort-option-label'>{column.label}</span>
                {getSortIcon(column)}
              </Button>
            ),
        )}
      </div>
    </Sidebar>
  );
};
