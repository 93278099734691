import React from 'react';
import { Avatar } from 'primereact/avatar';
import { Tag } from 'primereact/tag';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { TableColumn } from '../../types/responsiveTable';
import { format } from 'date-fns';
import { classNames } from 'primereact/utils';

interface TableRowsProps<T extends Record<any, string>> {
  data: any[];
  columns: TableColumn<T>[];
  loading?: boolean;
  onOptionsClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void;
  onRowClick?: (row: any) => void;
}

export const TableRows = <T extends Record<any, string>>({
  data,
  columns: headers,
  onOptionsClick,
  loading,
  onRowClick,
}: TableRowsProps<T>) => {
  const createRow = (row: T, columns: TableColumn<T>[]) => {
    const tableRow: any[] = [];
    columns.forEach((column, index) => {
      switch (column.type) {
        case 'avatar':
          tableRow.push(
            <td className='avatar' key={`image-${index}`} style={{ justifyContent: 'center' }}>
              <Avatar image={row[column.accessor]?.toString()} shape='circle' size='large' />
            </td>,
          );
          return;
        case 'dropdown': {
          const value = column.valueFromCallBack ? column.valueFromCallBack(row[column.accessor], row) : row[column.accessor];
          tableRow.push(
            <td className='dropdown' key={`dropdown-${index}`}>
              <span className='cell-header'>{columns[index].label}:</span>
              {Array.isArray(value) ? (
                <ul>
                  {value.map((v: string) => (
                    <li
                      key={`dropdown-${index}-${v}`}
                      className={classNames('list-item', { 'no-decorated': column.hideBulletPoints })}
                    >
                      {v}
                    </li>
                  ))}
                </ul>
              ) : (
                <span>{value?.toString() || '-'}</span>
              )}
            </td>,
          );
          return;
        }
        case 'string': {
          const stringValue = column.valueFromCallBack
            ? column.valueFromCallBack(row[column.accessor], row)
            : row[column.accessor];
          tableRow.push(
            <td className='string' key={`string-${index}`}>
              {!column.isTitle && <span className='cell-header'>{columns[index].label}:</span>}
              <span className={column.isTitle ? 'cell-header-text' : ''}>{stringValue?.toString() || '-'}</span>
            </td>,
          );
          return;
        }
        case 'calendar': {
          const date = column.valueFromCallBack ? column.valueFromCallBack(row[column.accessor], row) : row[column.accessor];
          tableRow.push(
            <td className='calendar' key={`string-${index}`}>
              <span className='cell-header'>{columns[index].label}:</span>
              <span>{date ? format(new Date(date?.toString()), 'dd/MM/yyyy') : '-'}</span>
            </td>,
          );
          return;
        }
        case 'tag': {
          const tagLabel = column.textKeyFromCallBack ? column.textKeyFromCallBack(row[column.accessor]) : row[column.accessor];
          tableRow.push(
            <td className='tag' key={`tag-${index}`}>
              <span className='cell-header'>{columns[index].label}:</span>
              {tagLabel ? (
                <Tag value={tagLabel?.toString()} className={column.colorFromCallBack(tagLabel?.toString())}></Tag>
              ) : (
                '-'
              )}
            </td>,
          );
          return;
        }
        case 'number': {
          const numberValue = column.valueFromCallBack
            ? column.valueFromCallBack(row[column.accessor], row)
            : row[column.accessor];
          tableRow.push(
            <td className='number' key={`string-${index}`}>
              <span className='cell-header'>{columns[index].label}:</span>
              <span>{numberValue?.toString() || '-'}</span>
            </td>,
          );
          return;
        }
        default:
          return;
      }
    });
    return tableRow;
  };

  return (
    <>
      {loading &&
        Array.from({ length: 5 }).map((_, rowIndex) => (
          <tr key={rowIndex}>
            {Array.from({ length: 4 }).map((_, colIndex) => (
              <td key={colIndex}>
                <Skeleton width='100%' height='1.5rem' />
              </td>
            ))}
          </tr>
        ))}
      {!loading &&
        data.map((row: any, index: number) => (
          <tr onClick={() => onRowClick && onRowClick(row)} key={'key-' + index} style={{ cursor: 'pointer' }}>
            {createRow(row, headers)}
            <td className='option'>
              <Button
                icon={PrimeIcons.ELLIPSIS_V}
                text
                rounded
                className='option-button-desktop'
                onClick={(event) => {
                  event.stopPropagation();
                  onOptionsClick(event, row.id);
                }}
              ></Button>
              <Button
                icon={PrimeIcons.ELLIPSIS_H}
                text
                rounded
                className='option-button-mobile'
                onClick={(event) => {
                  event.stopPropagation();
                  onOptionsClick(event, row.id);
                }}
              ></Button>
            </td>
          </tr>
        ))}
    </>
  );
};
