import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { confirmDialog, ConfirmDialogProps } from 'primereact/confirmdialog';
import React, { ReactElement, ReactNode } from 'react';
import './Panel.scss';
import classNames from 'classnames';
import { PrimeIcons } from 'primereact/api';
import { showCancelDialog, showDeleteDialog } from '../../utils/dialogUtils';
import { useFeaturesToggle } from '../../hooks/useFeaturesToggle';
import { AllowedFeatures } from '../../types/features';
import { SplitButton } from 'primereact/splitbutton';
import { useState, useEffect } from 'react';

// If no title or HeaderComponent is passed, it will be generated from the resourceName
const getPanelTitle = (isCreating: boolean, isEditing: boolean, resourceName: string | undefined): string => {
  if (!resourceName) {
    return '';
  }
  if (!isCreating && isEditing) {
    return `Editar ${resourceName}`;
  }
  if (!isCreating && !isEditing) {
    return `Detalle de ${resourceName}`;
  }
  if (isCreating && !isEditing) {
    return `Añadir ${resourceName}`;
  }
  return `${resourceName}`;
};

export interface ResourcePanelImplProps<T> {
  resource: T | undefined;
  visible: boolean;
  onHide: () => void;
  onIsEditingChange: (isEditing: boolean) => void;
  onDeleteResource: (id: string | undefined) => Promise<boolean>;
  isDeleting: boolean;
  isEditing: boolean;
  deleteConfirmation?: boolean;
  allowedFeatures?: AllowedFeatures;
}

export type PanelType = 'resourcePanel' | 'readonlyPanel';

interface BaseProps {
  // Common props
  title?: string;
  HeaderComponent?: ReactElement;
  visible: boolean;
  panelType: PanelType;
  onHide: () => void;
  children: ReactNode;
  className?: string;
  isSaving?: boolean;

  // ResourcePanel props
  resourceName?: string;
  resetForm?: () => void;
  onDelete?: () => Promise<boolean>;
  onSave?: () => Promise<boolean>;
  onIsEditingChange?: (isEditing: boolean) => void;
  hasChanged?: boolean;
  isEditing?: boolean;
  isCreating?: boolean;
  isDeleting?: boolean;
  disableButtons?: boolean;
  hasCustomDelete?: boolean;
  editButtonText?: string;
  hideEditButton?: boolean;
  hideDeleteButton?: boolean;
  showAttendanceButton?: boolean;
  onAttendanceClick?: (isAttendanceMode: boolean) => void;
  allowedFeatures?: AllowedFeatures;
  customDeleteLabel?: string;
  attendanceButtonLabel?: string;
  customDeleteConfirmationDialogProps?: ConfirmDialogProps;
}

interface ReadonlyPanelProps extends BaseProps {
  panelType: 'readonlyPanel';
}

interface ResourcePanelProps extends BaseProps {
  panelType: 'resourcePanel';
  resourceName: string;
  resetForm: () => void;
  onDelete?: () => Promise<boolean>;
  onSave: () => Promise<boolean>;
  onIsEditingChange: (isEditing: boolean) => void;
  hasChanged: boolean;
  isEditing: boolean;
  isCreating: boolean;
  isDeleting: boolean;
  isSaving: boolean;
  disableButtons?: boolean;
  hasCustomDelete?: boolean;
}

type Props = ReadonlyPanelProps | ResourcePanelProps;

export const Panel = ({
  HeaderComponent,
  panelType,
  title,
  resourceName,
  visible,
  resetForm,
  onHide,
  onDelete,
  onSave,
  onIsEditingChange,
  hasChanged,
  children,
  className,
  isSaving = false,
  isEditing = false,
  isCreating = false,
  isDeleting = false,
  disableButtons = false,
  hasCustomDelete = false,
  editButtonText = 'Editar',
  hideEditButton = false,
  hideDeleteButton = false,
  showAttendanceButton = false,
  onAttendanceClick,
  allowedFeatures,
  customDeleteLabel,
  attendanceButtonLabel,
  customDeleteConfirmationDialogProps,
}: Props) => {
  const isResourcePanel = panelType === 'resourcePanel';
  const showSave = (isEditing || isCreating) && isResourcePanel;
  const showEdit = !isCreating && !isEditing && isResourcePanel;
  const showDelete = !!onDelete && !isCreating && !isEditing && isResourcePanel && !hideDeleteButton;
  const disableAllButtons = isDeleting || isSaving || disableButtons;
  const [isAttendanceMode, setIsAttendanceMode] = useState(true);

  const saveLabel = isCreating ? `Crear ${resourceName}` : `Guardar ${resourceName}`;

  const userHasEditFeature = allowedFeatures?.edit ? useFeaturesToggle(allowedFeatures.edit) : true;
  const userHasCreateFeature = allowedFeatures?.create ? useFeaturesToggle(allowedFeatures.create) : true;
  const userHasDeleteFeature = allowedFeatures?.delete ? useFeaturesToggle(allowedFeatures.delete) : true;

  useEffect(() => {
    setIsAttendanceMode(true);
  }, [visible]);

  const confirmDelete = () => {
    if (!userHasDeleteFeature || !onDelete) return;
    if (hasCustomDelete) {
      onDelete();
    } else {
      showDeleteDialog(async () => {
        const success = await onDelete();
        if (success) {
          onHide();
          setIsAttendanceMode(true);
        }
      }, customDeleteConfirmationDialogProps);
    }
  };

  const handleCancel = () => {
    if (hasChanged) {
      showCancelDialog(() => {
        onIsEditingChange?.(false);
        resetForm?.();
      });
    } else {
      onIsEditingChange?.(false);
      resetForm?.();
    }
  };

  const resetFormWithDelay = () => {
    // Small delay so that the form does not reset before the panel close animation ends
    setTimeout(() => {
      resetForm?.();
    }, 100);
  };

  const handleClose = () => {
    if (hasChanged) {
      confirmDialog({
        message: '¿Deseas cerrar el panel? Perderás los cambios no guardados.',
        header: 'Confirmar cierre',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sí, cerrar',
        rejectLabel: 'No, continuar editando',
        accept: () => {
          onHide();
          setIsAttendanceMode(true);
          resetFormWithDelay();
        },
        acceptClassName: 'p-button p-button-text',
        rejectClassName: 'p-button-secondary p-button-text',
        dismissableMask: false,
        draggable: false,
        resizable: false,
      });
    } else {
      resetForm?.();
      onHide();
      setIsAttendanceMode(true);
    }
  };

  const handleSave = async () => {
    if ((isCreating && !userHasCreateFeature) || (isEditing && !userHasEditFeature) || !onSave) return;
    const success = await onSave();
    if (success) {
      onHide();
      setIsAttendanceMode(true);
      if (isCreating) {
        resetFormWithDelay();
      }
    }
  };

  const renderEditButton = () => {
    if (isCreating || !userHasEditFeature || !onIsEditingChange || hideEditButton) return null;
    return (
      <Button
        key='edit'
        icon={isEditing ? PrimeIcons.TIMES : 'pi pi-file-edit'}
        label={isEditing ? 'Cancelar' : editButtonText}
        onClick={() => {
          if (isEditing) {
            resetForm?.();
          }
          onIsEditingChange(!isEditing);
        }}
        disabled={disableAllButtons}
        className={classNames('pull-right', {
          'p-button-text p-button-danger': isEditing,
        })}
      />
    );
  };

  const header = (
    <div className='panel-header'>
      {HeaderComponent ?? <h4>{title ? title : getPanelTitle(isCreating, isEditing, resourceName)}</h4>}
      <div className='header-right'>
        <Button icon='pi pi-times' className='p-button-text' onClick={handleClose} rounded />
      </div>
    </div>
  );

  const renderFooter = () =>
    (showSave && userHasCreateFeature) || (showEdit && userHasEditFeature) ? (
      <div className='panel-footer'>
        {showSave && userHasCreateFeature ? (
          <>
            {!isCreating && (
              <Button
                label='Cancelar'
                key='cancel'
                className='p-button-text'
                severity='secondary'
                onClick={handleCancel}
                disabled={disableAllButtons}
              />
            )}
            <Button
              className='pull-right'
              key='save'
              label={saveLabel}
              onClick={handleSave}
              loading={isSaving}
              disabled={disableAllButtons || !hasChanged}
              icon={PrimeIcons.SAVE}
            />
          </>
        ) : (
          <>
            {showAttendanceButton ? (
              <SplitButton
                label={isAttendanceMode ? (attendanceButtonLabel ?? 'Asistencias') : 'Cancelar'}
                icon={isAttendanceMode ? 'pi pi-file-edit' : PrimeIcons.TRASH}
                onClick={() => onAttendanceClick?.(isAttendanceMode)}
                className={classNames('attendance-split-button', {
                  'p-button-primary': isAttendanceMode,
                  'p-button-danger': !isAttendanceMode,
                })}
                outlined
                model={[
                  {
                    label: 'Asistencias',
                    icon: PrimeIcons.CHECK,
                    command: () => setIsAttendanceMode(true),
                  },
                  {
                    label: 'Cancelar',
                    icon: PrimeIcons.TRASH,
                    className: 'p-menuitem-danger',
                    command: () => setIsAttendanceMode(false),
                  },
                ]}
                disabled={disableAllButtons}
              />
            ) : (
              showDelete &&
              userHasDeleteFeature && (
                <Button
                  icon='pi pi-trash'
                  label={customDeleteLabel ?? 'Eliminar'}
                  className='p-button-text p-button-danger panel-delete-button'
                  onClick={confirmDelete}
                  loading={isDeleting}
                  disabled={disableAllButtons}
                />
              )
            )}
            {renderEditButton()}
          </>
        )}
      </div>
    ) : null;

  return (
    <Sidebar
      className={classNames('base-panel', className)}
      position='right'
      visible={visible}
      onHide={handleClose}
      header={header}
      modal
      showCloseIcon={false}
    >
      <div className='panel-content'>{children}</div>
      {renderFooter()}
    </Sidebar>
  );
};
