import { classNames } from 'primereact/utils';
import React, { ReactElement, ReactNode, useId, useRef } from 'react';
import {
  FormElementError,
  FormElementErrorPosition,
} from '../FormElementError/FormElementError';
import { PrimeIcons } from 'primereact/api';
import { Tooltip } from 'react-tooltip';
import { InfoIcon } from '../InfoIcon/InfoIcon';

interface Props {
  children?: ReactNode;
  className?: string;
  elementId?: string;
  labelTitle?: string;
  helper?: ReactElement;
  infoTooltip?: string;
  error?: string;
  errorPosition?: FormElementErrorPosition;
  errorMessageOverride?: string;
  // TODO make this one prop
  fullWidth?: boolean;
  twoThirds?: boolean;
}

export const FormField = ({
  className,
  children = <></>,
  elementId: labelFor,
  labelTitle,
  helper,
  infoTooltip,
  error,
  errorPosition,
  errorMessageOverride,
  fullWidth = false,
  twoThirds = false,
}: Props) => {
  const renderTooltip = () => {
    if (!infoTooltip) return <></>;
    return <InfoIcon content={infoTooltip} />;
  };

  return (
    <div
      className={classNames('form-field', className, {
        'full-width': fullWidth,
        'two-thirds': twoThirds,
      })}
    >
      {labelTitle && (
        <label htmlFor={labelFor}>
          {labelTitle}
          {renderTooltip()}
        </label>
      )}
      {children}
      <FormElementError
        messageOverride={errorMessageOverride}
        position={errorPosition}
      >
        {error}
      </FormElementError>
      {helper}
    </div>
  );
};
