import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { UpdatePassword, User } from '../../types/user';
import { validateEmail, validateLettersAndSpaces, validatePassword, validatePhone } from '../../utils/validationUtils';

const { NOT_EMAIL, INVALID_PASSWORD, ONLY_LETTERS_AND_SPACES, NOT_NAME, NOT_SURNAMES, INVALID_PHONE } = VALIDATION_MESSAGES;

export interface SignatureForm {
  nationalId?: string;
  signatureUrl?: string;
}

export interface ProfileForm {
  id?: string;
  name?: string;
  surnames?: string;
  email?: string;
  dateOfBirth?: string;
  phone?: string;
  avatarUrl?: string;
}

export const USER_FORM_VALIDATORS: FormValidators<User> = {
  name: (user) => {
    if (!user.name?.length) return NOT_NAME;
    if (!validateLettersAndSpaces(user.name)) return ONLY_LETTERS_AND_SPACES;
  },
  surnames: (user) => {
    if (!user.surnames?.length) return NOT_SURNAMES;
    if (!validateLettersAndSpaces(user.surnames)) return ONLY_LETTERS_AND_SPACES;
  },
  email: (user) => {
    if (!user.email || !validateEmail(user.email)) return NOT_EMAIL;
  },
  phone: (user) => {
    if (user.phone && !validatePhone(user.phone)) return INVALID_PHONE;
  },
  // No hace falta hacer validación de fecha porque el calendario ya bloquea fechas inválidas
};

export const PASSWORD_FORM_VALIDATORS: FormValidators<UpdatePassword> = {
  oldPassword: (form) => {
    if (!form.oldPassword || !validatePassword(form.oldPassword)) {
      return 'Introduce tu contraseña antigua';
    }
  },
  newPassword: (form) => {
    if (!form.newPassword || !validatePassword(form.newPassword)) {
      return INVALID_PASSWORD;
    }
  },
};

export const SIGNATURE_FORM_VALIDATORS: FormValidators<SignatureForm> = {
  nationalId: (form) => {
    if (!form.nationalId) return 'Introduce tu DNI';
  },
  signatureUrl: (form) => {
    if (!form.signatureUrl) return 'Introduce tu firma';
  },
};
