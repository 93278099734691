import { ReservationTypeWithColor } from '../../hooks/AppData/useReservationTypes';
import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { Branch } from '../../types/branch';
import { Organization } from '../../types/organization';
import { ReservationHours } from '../../types/reservationType';
import { validateEmail } from '../../utils/validationUtils';

const { NOT_EMAIL, NOT_PROMOTIONS_EMAIL_PASSWORD } = VALIDATION_MESSAGES;

// Branch settings form
export interface BranchSettingsForm {
  name?: string;
  city?: string;
}
export const getBranchSettingsFormValidators = (hasSignedDocumentsFeature: boolean): FormValidators<BranchSettingsForm> => {
  return {
    name: (form) => {
      if (!form.name) return 'El nombre es requerido';
    },
    city: (form) => {
      if (hasSignedDocumentsFeature && !form.city) return 'La ubicación de los documentos firmados es requerida';
    },
  };
};
export const getInitialBranchSettingsForm = (branch: Branch | undefined): BranchSettingsForm => {
  const { name, city } = branch ?? {};
  return {
    name: name ?? '',
    city: city ?? '',
  };
};

// Reservation hours form
export interface BranchReservationHours {
  hours: ReservationHours;
  closedDays: Date[];
}
export interface ReservationHoursForm {
  [key: string]: BranchReservationHours;
}
export const getInitialReservationHoursForm = (
  branchReservationTypes: ReservationTypeWithColor[] | undefined,
): ReservationHoursForm | undefined => {
  return branchReservationTypes?.reduce<ReservationHoursForm>((acc, reservationType) => {
    acc[reservationType.id] = {
      hours: reservationType.hours,
      closedDays: reservationType.closedDays.map((date) => new Date(date)) || [],
    };
    return acc;
  }, {});
};
export const RESERVATION_HOURS_FORM_VALIDATORS: FormValidators<ReservationHoursForm> = {};

// Org email settings form
export interface OrgEmailSettingsForm {
  email?: string;
  emailServerPassword?: string;
}
export const ORG_EMAIL_SETTINGS_FORM_VALIDATORS: FormValidators<OrgEmailSettingsForm> = {
  email: (form) => {
    if (!form.email) return NOT_EMAIL;
    if (!validateEmail(form.email)) return NOT_EMAIL;
  },
  emailServerPassword: (form) => {
    if (!form.emailServerPassword) return NOT_PROMOTIONS_EMAIL_PASSWORD;
  },
};
export const getInitialOrgEmailSettingsForm = (org?: Organization): OrgEmailSettingsForm => {
  const { email, emailServerPassword } = org ?? {};
  return {
    email,
    emailServerPassword,
  };
};
