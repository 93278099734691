import React, { FormEvent } from 'react';
import './Support.scss';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useForm } from '../../hooks/useForm';
import { SUPPORT_FORM_VALIDATORS, SupportForm } from './validators';
import { FormField } from '../../components/FormField/FormField';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Divider } from 'primereact/divider';
import { useClient } from '../../hooks/useClient';

const INITIAL_FORM: SupportForm = {
  message: '',
};

// Preguntas frecuentes
const FAQ_ITEMS = [
  {
    question: '¿Cómo puedo crear una nueva reserva?',
    answer:
      'Para crear una nueva reserva, ve a la sección "Reservas" y elige un tipo de reserva. Ahora puedes hacer clic en el botón "Nueva reserva", clickar en una hora del calendario o seleccionar horas en el calendario arrastrando. Aparecerá el panel de reservas, donde puedes rellenar los datos de la reserva.',
  },
  {
    question: '¿Cómo puedo cancelar una reserva?',
    answer:
      'Para cancelar una reserva, ve a la sección "Reservas", encuentra la reserva que deseas cancelar y haz clic en ella. En el panel de detalles, haz clic en el botón "Cancelar reserva" y confirma la acción.',
  },
  {
    question: '¿Cómo puedo gestionar mis clientes?',
    answer:
      'Para gestionar tus clientes, ve a la sección "Clientes" donde podrás ver, añadir, editar y eliminar clientes. También puedes ver el historial de reservas y rutinas de cada cliente.',
  },
  {
    question: '¿Cómo puedo crear una rutina para un cliente?',
    answer:
      'Para crear una rutina, ve a la sección "Rutinas", haz clic en "Nueva rutina" y selecciona el cliente. Luego, añade los ejercicios deseados, configura las series y repeticiones, y guarda la rutina.',
  },
  {
    question: '¿Cómo puedo ver las analíticas de mi negocio?',
    answer:
      'Para ver las analíticas de tu negocio, ve a la sección "Analíticas" donde encontrarás gráficos y datos sobre reservas, ingresos, clientes activos y más información relevante para tu negocio.',
  },
];

export const Support = () => {
  const { post } = useClient();
  const { form, setFormFields, validationErrors, validate, isSaving, setIsSaving, hasChanged, resetForm } = useForm<SupportForm>(
    INITIAL_FORM,
    false,
    SUPPORT_FORM_VALIDATORS,
  );

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const newErrors = validate();
    if (newErrors) return;

    setIsSaving(true);

    await post('/support-email', {
      body: form,
      successMessage: {
        summary: 'Mensaje enviado',
        detail: 'Gracias por escribirnos. Nos pondremos en contacto contigo lo antes posible.',
      },
      errorMessages: {
        summary: 'Error al enviar el mensaje',
        defaultDetail: 'No se ha podido enviar el mensaje',
      },
    });

    setIsSaving(false);
    resetForm();
  };

  return (
    <PageTemplate title='Contacta con nosotros' className='support-page'>
      <div className='support-content'>
        <div className='form-section'>
          <Card className='form-card'>
            <div className='card-header'>
              <i className='pi pi-comments card-icon'></i>
              <h4>Envíanos un mensaje</h4>
            </div>
            <Divider />
            <p className='subtitle'>
              ¿Tienes alguna pregunta sobre nuestras herramientas o sobre cómo escalar en nuestra plataforma? ¿Tienes algún
              problema o has encontrado un error? Estamos aquí para ayudarte. Escríbenos un mensaje aquí y nos pondremos en
              contacto a través de correo electrónico.
            </p>

            <form onSubmit={handleSubmit} className={classNames({ 'form-loading': isSaving })}>
              <FormField labelTitle='Mensaje' error={validationErrors?.message} fullWidth required>
                <InputTextarea
                  value={form.message}
                  onChange={(e) => setFormFields({ message: e.target.value })}
                  invalid={!!validationErrors?.message}
                  rows={7}
                  autoResize
                  placeholder='Escribe tu mensaje...'
                />
              </FormField>

              <Button
                disabled={!hasChanged}
                label='Enviar mensaje'
                type='submit'
                className='send-message-button'
                loading={isSaving}
                icon='pi pi-send'
              />
            </form>
          </Card>

          <Card className='faq-card'>
            <div className='card-header'>
              <i className='pi pi-question-circle card-icon'></i>
              <h4>Preguntas frecuentes</h4>
            </div>
            <Divider />
            <p className='subtitle'>Encuentra respuestas a las preguntas más comunes sobre nuestra plataforma.</p>

            <Accordion multiple>
              {FAQ_ITEMS.map((item, index) => (
                <AccordionTab key={index} header={item.question}>
                  <p>{item.answer}</p>
                </AccordionTab>
              ))}
            </Accordion>
          </Card>
        </div>

        <div className='contact-section'>
          <Card className='contact-method'>
            <div className='card-header'>
              <i className='pi pi-envelope card-icon'></i>
              <h4>Correo electrónico</h4>
            </div>
            <Divider />
            <p>Envíanos un correo y nos pondremos en contacto contigo lo antes posible.</p>
            <Button
              className='contact-button'
              label='info@no-gravity.es'
              text
              icon='pi pi-envelope'
              onClick={() => window.open('https://mail.google.com/mail/?view=cm&fs=1&to=info@no-gravity.es')}
            />
            <Button
              className='contact-button'
              label='info@no-gravity.net'
              text
              icon='pi pi-envelope'
              onClick={() => window.open('https://mail.google.com/mail/?view=cm&fs=1&to=info@no-gravity.net')}
            />
          </Card>

          <Card className='contact-method'>
            <div className='card-header'>
              <i className='pi pi-whatsapp card-icon'></i>
              <h4>Whatsapp</h4>
            </div>
            <Divider />
            <p>Escríbenos a través de WhatsApp si quieres conversar sobre algo o necesitas ayuda.</p>
            <Button
              className='contact-button'
              label='+34 611 70 75 26'
              text
              icon='pi pi-whatsapp'
              onClick={() => window.open('https://wa.me/34611707526')}
            />
            <Button
              className='contact-button'
              label='+34 611 70 81 63'
              text
              icon='pi pi-whatsapp'
              onClick={() => window.open('https://wa.me/34611708163')}
            />
          </Card>

          <Card className='contact-method'>
            <div className='card-header'>
              <i className='pi pi-phone card-icon'></i>
              <h4>Teléfono</h4>
            </div>
            <Divider />
            <p>
              Si necesitas hablar con nosotros de forma inmediata, puedes llamar a nuestro equipo por teléfono de lunes a viernes
              de 9:00 a 21:00.
            </p>
            <Button
              className='contact-button'
              label='+34 611 70 75 26'
              text
              icon='pi pi-phone'
              onClick={() => window.open('tel:+34611707526')}
            />
            <Button
              className='contact-button'
              label='+34 611 70 81 63'
              text
              icon='pi pi-phone'
              onClick={() => window.open('tel:+34611708163')}
            />
          </Card>
        </div>
      </div>
    </PageTemplate>
  );
};
