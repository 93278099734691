import React from 'react';
import { InputText, InputTextProps } from 'primereact/inputtext';

interface Props extends Omit<InputTextProps, 'onChange'> {
  onChange: (value: string) => void;
}

export const PhoneInput = ({ value, onChange, placeholder, id = 'phone', name = 'phone', ...props }: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Permitir campo vacío
    if (value === '') {
      onChange?.(value);
      return;
    }

    // Si es solo un +, permitirlo
    if (value === '+') {
      onChange?.(value);
      return;
    }

    // Si tiene + en cualquier posición que no sea el inicio, rechazar
    if (value.includes('+') && !value.startsWith('+')) {
      return;
    }

    // Para el resto de casos, validar que después del + (si existe) solo haya números y espacios
    // y que haya al menos un número
    const valueWithoutPlus = value.startsWith('+') ? value.slice(1) : value;
    if (/^\d[\d\s]*$/.test(valueWithoutPlus)) {
      onChange?.(value);
    }
  };

  return (
    <InputText
      {...props}
      placeholder={placeholder || 'Ej: +34 626 635 346'}
      value={value ?? ''}
      onChange={handleChange}
      id={id}
      name={name}
    />
  );
};
