import { Image } from 'primereact/image';
import { classNames } from 'primereact/utils';
import React from 'react';

import './LoadingScreen.scss';

type Props = {
  show: boolean;
};

export const LoadingScreen = ({ show }: Props) => {
  return (
    <div className={classNames('loading-screen', { 'loading-screen-show': show })}>
      <Image
        src='https://no-gravity.s3.eu-north-1.amazonaws.com/Dashboard/no-gravity-logo-black.webp'
        alt='No Gravity logo'
        className='loading-logo'
      />
    </div>
  );
};
