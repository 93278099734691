import React from 'react';
import { Avatar } from 'primereact/avatar';
import { TabView, TabPanel } from 'primereact/tabview';
import { Tag } from 'primereact/tag';
import { Card } from 'primereact/card';
import { Timeline } from 'primereact/timeline';
import './UserProfile.scss';

const UserProfile = () => {
  const activity = [
    {
      date: '9:00 AM, Abr 8 2022',
      content: 'Cita de fisioterapia con Pablo Castillo',
      icon: 'pi pi-wave-pulse',
    },
    {
      date: '4:50 PM, Mar 30 2022',
      content: 'Cita de fisioterapia con Sergio Donaire',
      icon: 'pi pi-wave-pulse',
    },
    {
      date: '10:33 AM, Mar 25 2022',
      content: 'Sesión en centro de fuerza',
      icon: 'pi pi-wrench',
    },
    {
      date: '11:00 AM, Mar 23 2022',
      content: 'Cita de fisioterapia con Pablo Castillo',
      icon: 'pi pi-wave-pulse',
    },
    {
      date: '8:27 PM, Mar 20 2022',
      content: 'Sesión en centro de fuerza',
      icon: 'pi pi-wrench',
    },
  ];

  return (
    <div className='user-profile-page'>
      <Card title='Información' className='profile-header'>
        <div className='profile-info'>
          <div className='profile-info-top'>
            <Avatar label='C' shape='circle' size='xlarge' />
            <div className='profile-info-top-container'>
              <div className='profile-info-name'>
                <h2>Antonio Jesús</h2>
                <span>activo</span>
              </div>
              <p>Edad 22 • Hombre • Calle Toneleros 13, Alhaurin de la torre</p>
            </div>
          </div>
          <div className='profile-info-top'>
            <p>
              <i className='pi pi-calendar'></i> Se unió en Mar 2022
            </p>
            <p>
              <i className='pi pi-clock'></i> Ultima vez que vino hace 7 días
            </p>
          </div>

          <div className='tags'>
            <Tag value='Centro de fuerza' className='mr-2' />
            <Tag value='Fisioterapia' />
          </div>
          <p>
            <i className='pi pi-envelope'></i> antonio-del-campo@gmail.com
          </p>
          <p>
            <i className='pi pi-phone'></i> +34 098 765 432
          </p>
        </div>
      </Card>

      <Card title='Actividad'>
        <Timeline
          value={activity}
          align='left'
          className='custom-timeline'
          marker={(item) => <i className={item.icon}></i>}
          content={(item) => (
            <div>
              <small className='p-text-secondary'>{item.date}</small>
              <p>{item.content}</p>
            </div>
          )}
        >
          {activity.map((event, index) => (
            <div key={index} className='p-d-flex p-ai-center'>
              <i className={event.icon + ' p-mr-2'}></i>
            </div>
          ))}
        </Timeline>
      </Card>
    </div>
  );
};

export default UserProfile;
