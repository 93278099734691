import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ExternalUserShort } from '../../types/externalUser';
import { useClient } from '../../hooks/useClient';
import { PrimeIcons } from 'primereact/api';
import { useOrganizationContext } from '../../context/OrganizationContext';
import useSWR from 'swr';
import { UserDisplay } from '../UserDisplay/UserDisplay';
import { getHashMap } from '../../utils/objectTransformers';
import { getRequestUrl } from '../../utils/urlUtils';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Capacitor } from '@capacitor/core';
import { useDropdownPosition } from '../../hooks/useDropdownPosition';
import './ExternalUsersDropdownButton.scss';

interface Props {
  onSelect: (user: ExternalUserShort) => void;
  excludedUserIds?: string[];
  disabled?: boolean;
  filterOnlyInvitedToApp?: boolean;
}

interface UserSelectItem extends ExternalUserShort {
  label: string;
  value: string;
}

const getUserOptions = (users?: ExternalUserShort[]): UserSelectItem[] | undefined => {
  if (!users) return undefined;

  return users
    .sort((a, b) => {
      const nameA = `${a.name} ${a.surnames}`.toLowerCase();
      const nameB = `${b.name} ${b.surnames}`.toLowerCase();
      return nameA.localeCompare(nameB);
    })
    .map((user) => ({
      label: `${user.name} ${user.surnames}`,
      value: user.id,
      ...user,
    }));
};

const userTemplate = (option: UserSelectItem) => {
  return <UserDisplay fullName={option.label} avatarUrl={option.avatarUrl} />;
};

export const ExternalUsersDropdownButton = ({ onSelect, excludedUserIds, disabled, filterOnlyInvitedToApp = false }: Props) => {
  const { get } = useClient();
  const { organization } = useOrganizationContext() ?? {};
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isTouchDevice = Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches;
  const dropdownPosition = useDropdownPosition(buttonRef, dropdownVisible);

  const url = useMemo(
    () =>
      organization
        ? getRequestUrl({
            url: `/organizations/${organization.id}/external-users`,
            booleanParams: {
              short: true,
              onlyAppUsers: filterOnlyInvitedToApp,
            },
          })
        : null,
    [organization, filterOnlyInvitedToApp],
  );
  const { data: externalUsers, isLoading } = useSWR(url, get<ExternalUserShort[]>);

  const filteredExternalUsers = useMemo(() => {
    if (!externalUsers) return [];
    return externalUsers.filter((user) => !excludedUserIds?.includes(user.id));
  }, [externalUsers, excludedUserIds]);

  const usersHashMap = useMemo(() => getHashMap(filteredExternalUsers ?? [], 'id'), [filteredExternalUsers]);

  const options = useMemo(() => getUserOptions(filteredExternalUsers), [filteredExternalUsers]);

  const filteredOptions = useMemo(() => {
    return options?.filter((option) => {
      if (!searchValue) return true;
      return option.label?.toLowerCase().includes(searchValue.toLowerCase());
    });
  }, [options, searchValue]);

  // Cerrar el dropdown cuando se hace clic fuera
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Solo cerrar si el clic fue fuera del botón y del dropdown
      const clickedElement = event.target as Node;
      const isOutsideButton = buttonRef.current && !buttonRef.current.contains(clickedElement);
      const isOutsideDropdown = dropdownRef.current && !dropdownRef.current.contains(clickedElement);

      if (dropdownVisible && !isTouchDevice && isOutsideButton && isOutsideDropdown) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownVisible, isTouchDevice]);

  const handleChange = useCallback(
    (userId: string) => {
      const user = usersHashMap[userId];
      if (!user) return;
      onSelect(user);
      setIsModalVisible(false);
      setDropdownVisible(false);
    },
    [usersHashMap, onSelect],
  );

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (isTouchDevice) {
      setIsModalVisible(true);
    } else {
      setDropdownVisible(!dropdownVisible);
    }
  };

  const handleOptionClick = (option: UserSelectItem, e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Usar setTimeout para asegurarnos de que el evento de clic se procese completamente
    // antes de cerrar el dropdown
    setTimeout(() => {
      handleChange(option.value);
      setIsModalVisible(false);
      setDropdownVisible(false);
    }, 10);
  };

  return (
    <div className='external-users-dropdown-button'>
      <div ref={buttonRef} className='button-container'>
        <Button
          label='Añadir cliente'
          icon={PrimeIcons.PLUS}
          text
          onClick={handleButtonClick}
          disabled={disabled || isLoading}
          loading={isLoading}
          className='add-button'
        />
      </div>

      {isTouchDevice ? (
        <Dialog
          visible={isModalVisible}
          onHide={() => {
            setIsModalVisible(false);
            setSearchValue('');
          }}
          header='Seleccionar cliente'
          className='external-users-select-modal'
          draggable={false}
          resizable={false}
        >
          <div className='user-select-content'>
            <div
              className='user-select-search'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <InputText
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder='Buscar cliente...'
                disabled={isLoading}
              />
            </div>

            <div
              className='user-options-list'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {filteredOptions && filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <div
                    key={option.value}
                    className='user-option-wrapper'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleOptionClick(option, e);
                    }}
                  >
                    {userTemplate(option)}
                  </div>
                ))
              ) : (
                <div className='empty-message'>
                  {searchValue ? 'No se han encontrado clientes' : 'No hay clientes disponibles'}
                </div>
              )}
            </div>
          </div>
        </Dialog>
      ) : (
        dropdownVisible && (
          <div
            className='custom-dropdown-container'
            style={{
              top: dropdownPosition.top,
              left: dropdownPosition.left,
              right: dropdownPosition.right,
              bottom: dropdownPosition.bottom,
              marginBottom: dropdownPosition.marginBottom,
              marginTop: dropdownPosition.marginTop,
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div
              className='custom-dropdown'
              ref={dropdownRef}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div
                className='dropdown-search'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <InputText
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder='Buscar cliente...'
                  disabled={isLoading}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </div>
              <div
                className='dropdown-options-list'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {filteredOptions && filteredOptions.length > 0 ? (
                  filteredOptions.map((option) => (
                    <div
                      key={option.value}
                      className='dropdown-option-wrapper'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleOptionClick(option, e);
                      }}
                    >
                      {userTemplate(option)}
                    </div>
                  ))
                ) : (
                  <div className='empty-message'>
                    {searchValue ? 'No se han encontrado clientes' : 'No hay clientes disponibles'}
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};
