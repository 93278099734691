const css = `.post-locale-dropdown {
  width: fit-content;
  min-width: 128px;
  margin-left: auto;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvUGFnZUxvY2FsZVBpY2tlci50c3giLCJzb3VyY2VzIjpbIlBhZ2VMb2NhbGVQaWNrZXIuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5wb3N0LWxvY2FsZS1kcm9wZG93biB7XG4gIHdpZHRoOiBmaXQtY29udGVudDtcbiAgbWluLXdpZHRoOiAxMjhweDtcbiAgbWFyZ2luLWxlZnQ6IGF1dG87XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
