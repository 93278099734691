import React from 'react';
import { NavElement } from '../../types/navigation';
import { Route } from 'react-router-dom';
import { EmployeesList } from './EmployeesList/EmployeesList';
import { Roles } from '../Roles/Roles';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { ALL_FEATURES } from '../../config/features';
import { useFeaturesToggle } from '../../hooks/useFeaturesToggle';

export const Employees = () => {
  const employeeRoutes: NavElement[] = [
    {
      label: 'Lista',
      element: <EmployeesList />,
      url: '/',
    },
  ];
  const userHasRolesFeature = useFeaturesToggle([ALL_FEATURES.ROLES]);

  if (userHasRolesFeature) {
    employeeRoutes.push({
      label: 'Roles',
      element: <Roles />,
      url: '/roles',
    });
  }

  return (
    <PageTemplate title='Empleados' parentRoute='/employees' routes={employeeRoutes} withPageLocaleContext>
      {employeeRoutes.map(({ element, url }) => (
        <Route key={url} path={url} element={element} />
      ))}
    </PageTemplate>
  );
};
