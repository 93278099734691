import React, { useMemo } from 'react';
import { ValidationErrors } from '../../hooks/useForm';

import { ALL_SELECT_OPTION, getReservationTypeOptions } from '../../utils/selectOptions';
import { AdaptiveSelect } from '../AdaptiveSelect/AdaptiveSelect';
import { classNames } from 'primereact/utils';
import { useAuthContext } from '../../context/AuthContext';
import { ALL_FEATURES } from '../../config/features';
import { ReservationType } from '../../types/reservationType';
import { useReservationTypes } from '../../hooks/AppData/useReservationTypes';

interface BaseProps {
  onChange: ((reservationTypeNames: string[]) => void) | ((reservationTypeName: string | undefined) => void);
  value?: string[] | string;
  id?: string;
  error?: string | (ValidationErrors<ReservationType> | undefined)[];
  className?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  isSingle: boolean;
  selectAllOption?: boolean;
  excludeReservationTypeIds?: string[];
  onlyAuthUserReservationTypes?: boolean;
}

interface MultiProps extends BaseProps {
  isSingle: false;
  onChange: (reservationTypeNames: string[]) => void;
  value?: string[];
  selectAllOption?: never;
}

interface SingleSelectProps extends BaseProps {
  isSingle: true;
  onChange: (reservationTypeName: string | undefined) => void;
  value?: string;
  selectAllOption?: boolean;
}

type Props = MultiProps | SingleSelectProps;

export const ReservationTypeSelect = ({
  onChange,
  id = 'reservation-type-dropdown',
  value,
  error,
  className,
  placeholder,
  searchPlaceholder = 'Buscar reservas',
  isSingle,
  selectAllOption = false,
  excludeReservationTypeIds: excludeReservationTypeIds = [],
  onlyAuthUserReservationTypes: onlyAuthUserReservationTypes = false,
}: Props) => {
  const { uniqueReservationTypes, reservationTypesLoading } = useReservationTypes();
  const { features } = useAuthContext() ?? {};

  const finalPlaceholder = placeholder ?? (isSingle ? 'Elige tipo de reserva' : 'Elige tipos de reserva');

  const options = useMemo(() => {
    const filteredReservationTypes =
      excludeReservationTypeIds.length > 0
        ? uniqueReservationTypes?.filter((reservationType) => !excludeReservationTypeIds.includes(reservationType.id))
        : uniqueReservationTypes;
    const shouldRestrictReservationTypes = onlyAuthUserReservationTypes && !features?.includes(ALL_FEATURES.ORG_ADMIN);
    const reservationTypesAvailableToUser = shouldRestrictReservationTypes
      ? filteredReservationTypes?.filter((reservationType) =>
          features?.some((feature) => Object.values(reservationType.features)?.includes(feature)),
        )
      : filteredReservationTypes;
    return getReservationTypeOptions(reservationTypesAvailableToUser, selectAllOption);
  }, [excludeReservationTypeIds, uniqueReservationTypes, onlyAuthUserReservationTypes, features, selectAllOption]);

  const handleMultiChange = (values: string[]) => {
    if (isSingle) return;
    onChange(values);
  };

  const handleSingleChange = (value: string) => {
    if (!isSingle) return;
    if (value === ALL_SELECT_OPTION) {
      onChange(undefined);
      return;
    }
    onChange(value);
  };

  const reservationTypeTemplate = (option: any) => {
    return <span>{option.label}</span>;
  };

  return (
    <AdaptiveSelect
      id={id}
      isMultiple={!isSingle}
      value={value}
      onChange={(newValue) => (isSingle ? handleSingleChange(newValue) : handleMultiChange(newValue))}
      options={options}
      itemTemplate={reservationTypeTemplate}
      placeholder={finalPlaceholder}
      modalTitle={isSingle ? 'Seleccionar tipo de reserva' : 'Seleccionar tipos de reserva'}
      searchPlaceholder={searchPlaceholder}
      className={classNames(className, { 'p-invalid': error })}
      invalid={!!error}
      loading={reservationTypesLoading}
      emptyMessage='No hay tipos de reserva'
      emptyFilterMessage='No se han encontrado tipos de reserva'
    />
  );
};
