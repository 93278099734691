import React, { PropsWithChildren, createContext, useContext, useRef } from 'react';
import { Toast, ToastMessage } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';

interface PopupContextValue {
  showToast: (message: ToastMessage | ToastMessage[]) => void;
}

const PopupContext = createContext<PopupContextValue | null>(null);

export const PopupProvider = ({ children }: PropsWithChildren) => {
  const toast = useRef<Toast>(null);

  const showToast = (message: ToastMessage | ToastMessage[]) => {
    toast.current?.show({ life: 5000, ...message });
  };

  return (
    <PopupContext.Provider value={{ showToast }}>
      <Toast ref={toast} />
      <ConfirmDialog dismissableMask />
      {children}
    </PopupContext.Provider>
  );
};

export const usePopups = () => {
  return useContext(PopupContext);
};
