import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonProps } from 'primereact/button';

interface Props extends ButtonProps {
  to: string;
}

export const LinkButton = ({ to, ...restProps }: Props) => {
  const navigate = useNavigate();

  return <Button {...restProps} link onClick={() => navigate(to)}></Button>;
};
