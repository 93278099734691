import { isWithinInterval, startOfDay } from 'date-fns';
import { TableColumn, TagColor, TagColumn, SortState } from '../../types/responsiveTable';

const characterMap: Record<string, string> = {
  æ: 'ae',
  œ: 'oe',
  ß: 'ss',
  ñ: 'n',
  ç: 'c',
};

const normalizeString = (str: string): string => {
  // Primero convertimos a minúsculas
  let normalized = str.toLowerCase();

  // Normalizamos los diacríticos y los eliminamos
  normalized = normalized.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // Eliminamos caracteres no alfanuméricos y espacios múltiples
  normalized = normalized
    .replace(/[^a-z0-9\s]/g, ' ')
    .replace(/\s+/g, ' ')
    .trim();

  return normalized;
};

const createSearchPattern = (str: string): string => {
  let normalized = normalizeString(str);

  // Creamos patrones para caracteres especiales
  Object.entries(characterMap).forEach(([special, normal]) => {
    normalized = normalized.replace(new RegExp(normal, 'g'), `(${special}|${normal})`);
  });

  return normalized;
};

export const getProgressTruncatedValue = (value: number | null, truncate = false) => {
  if (value === null) return null;
  return truncate ? Math.round(value) : value;
};

export const filterData = <T extends Record<any, string>>(data: T[], columns: TableColumn<T>[], filters: Record<string, any>) => {
  return data.filter((row) =>
    columns.every((column, index: number) => {
      const filterValue: any[] = filters[`${column.accessor}-${index}`];

      if (!filterValue || filterValue.length === 0) return true;

      switch (column.type) {
        case 'dropdown': {
          const dropdownValue = (
            column.valueFromCallBack
              ? (column.valueFromCallBack(row[column.accessor], row) ?? ['-'])
              : (row[column.accessor] ?? ['-'])
          ) as string[];
          return filterValue.every((v) => dropdownValue.includes(v));
        }
        case 'boolean': {
          const boolValue = column.valueFromCallBack ? column.valueFromCallBack(row[column.accessor], row) : row[column.accessor];
          if (!filterValue || filterValue.length === 0) return true;
          return filterValue.includes(boolValue);
        }
        case 'string': {
          const stringValue = column.valueFromCallBack
            ? (column.valueFromCallBack(row[column.accessor], row) ?? '-')
            : (row[column.accessor] ?? '-');
          return filterValue.every((filterVal: string) => {
            const searchPattern = createSearchPattern(filterVal);
            const normalizedValue = normalizeString(stringValue?.toString());
            return new RegExp(searchPattern, 'i').test(normalizedValue);
          });
        }
        case 'tag': {
          return filterValue.includes(
            column.textKeyFromCallBack ? column.textKeyFromCallBack(row[column.accessor], row) : (row[column.accessor] ?? '-'),
          );
        }
        case 'number': {
          // Obtenemos el valor raw del row
          const rawValue = row[column.accessor];

          // Obtenemos el valor formateado si hay un callback
          const formattedValue = column.valueFromCallBack ? column.valueFromCallBack(rawValue, row) : rawValue;

          // Convertimos a string para procesarlo
          const stringValue = formattedValue !== null && formattedValue !== undefined ? String(formattedValue) : '';

          // Si el valor está vacío o es '-', tratamos como 0
          if (stringValue === '-' || stringValue === '') {
            return filterValue.every((filterVal: { mode: string; value: string | number | null }) => {
              if (filterVal.value === '' || filterVal.value === null) return true;

              const numericFilterValue =
                typeof filterVal.value === 'string' ? parseFloat(filterVal.value.replace(/[^\d.-]/g, '')) : filterVal.value;

              if (isNaN(numericFilterValue)) return true;

              // Comparamos con 0
              if (filterVal.mode === 'equal') return Math.abs(numericFilterValue) < 0.001;
              if (filterVal.mode === 'greater-than') return 0 > numericFilterValue;
              if (filterVal.mode === 'less-than') return 0 < numericFilterValue;
              return true;
            });
          }

          // Extraemos el valor numérico
          let numericValue: number;

          // Si es una columna de moneda o el valor tiene formato de moneda
          if (('isCurrency' in column && column.isCurrency) || (typeof stringValue === 'string' && /[€$£¥]/.test(stringValue))) {
            // Limpiamos cualquier símbolo de moneda y separadores
            // Primero reemplazamos las comas por puntos para manejar el formato europeo
            const cleanedValue = stringValue
              .replace(/[€$£¥]/g, '') // Eliminar símbolos de moneda
              .replace(/\s/g, '') // Eliminar espacios
              .replace(/\./g, '') // Eliminar puntos (separadores de miles)
              .replace(/,/g, '.'); // Reemplazar comas por puntos (para decimales)

            numericValue = parseFloat(cleanedValue);

            // Para depuración
            console.log('Valor limpiado:', { original: stringValue, limpiado: cleanedValue, numerico: numericValue });
          } else {
            numericValue = parseFloat(stringValue);
          }

          // Si no es un número válido, tratamos como 0
          if (isNaN(numericValue)) {
            numericValue = 0;
          }

          return filterValue.every((filterVal: { mode: string; value: string | number | null }) => {
            if (filterVal.value === '' || filterVal.value === null) return true;

            // Procesamos el valor del filtro
            let numericFilterValue: number;

            if (typeof filterVal.value === 'string') {
              // Limpiamos cualquier símbolo de moneda y separadores
              // Primero reemplazamos las comas por puntos para manejar el formato europeo
              const cleanedFilterValue = filterVal.value
                .replace(/[€$£¥]/g, '') // Eliminar símbolos de moneda
                .replace(/\s/g, '') // Eliminar espacios
                .replace(/\./g, '') // Eliminar puntos (separadores de miles)
                .replace(/,/g, '.'); // Reemplazar comas por puntos (para decimales)

              numericFilterValue = parseFloat(cleanedFilterValue);

              // Para depuración
              console.log('Valor de filtro limpiado:', {
                original: filterVal.value,
                limpiado: cleanedFilterValue,
                numerico: numericFilterValue,
              });

              if (isNaN(numericFilterValue)) return true;
            } else {
              numericFilterValue = filterVal.value;
            }

            // Realizamos la comparación con una pequeña tolerancia para decimales
            if (filterVal.mode === 'equal') {
              // Para depuración
              console.log('Comparando valores:', {
                numericValue,
                numericFilterValue,
                diff: Math.abs(numericValue - numericFilterValue),
                isEqual: Math.abs(numericValue - numericFilterValue) < 0.01,
              });

              // Para valores de moneda, comparamos redondeando a 2 decimales
              if ('isCurrency' in column && column.isCurrency) {
                const roundedValue = Math.round(numericValue * 100) / 100;
                const roundedFilterValue = Math.round(numericFilterValue * 100) / 100;
                return roundedValue === roundedFilterValue;
              }

              // Para otros valores numéricos, usamos una tolerancia
              return Math.abs(numericValue - numericFilterValue) < 0.01;
            }
            if (filterVal.mode === 'greater-than') return numericValue > numericFilterValue;
            if (filterVal.mode === 'less-than') return numericValue < numericFilterValue;
            return true;
          });
        }
        case 'calendar': {
          const date = column.valueFromCallBack
            ? (column.valueFromCallBack(row[column.accessor], row) ?? '-')
            : (row[column.accessor] ?? '-');
          if (!filterValue.every((v: Date | null) => v !== null)) return true;
          return isWithinInterval(startOfDay(new Date(date)), {
            start: startOfDay(new Date(filterValue[0])),
            end: startOfDay(new Date(filterValue[1])),
          });
        }
        case 'progress': {
          const percentage = column.percentageValueFromCallBack
            ? column.percentageValueFromCallBack(row[column.accessor], row)
            : row[column.accessor];
          if (!filterValue || filterValue.length !== 2) return true;
          if (typeof percentage !== 'number') return false;
          const displayValue = getProgressTruncatedValue(percentage, column.truncate);

          if (displayValue === null) return false;

          return displayValue >= filterValue[0] && displayValue <= filterValue[1];
        }
        default:
          return true;
      }
    }),
  );
};

export const paginateData = (data: any[], first: number, rows: number) => {
  return data.slice(first, first + rows);
};

export interface TagFilterOption {
  label: string;
  value: string;
  color: TagColor;
}

export const getTagFilterOptions = <T extends Record<string, any>>(data: T[], column: TagColumn<T>): TagFilterOption[] => {
  const optionsMap = new Map<string, TagFilterOption>();

  data.forEach((row) => {
    const value = column.textKeyFromCallBack ? column.textKeyFromCallBack(row[column.accessor], row) : row[column.accessor];
    if (value && value !== '-') {
      optionsMap.set(value, {
        value,
        label: value,
        color: column.colorFromCallBack(row[column.accessor], row),
      });
    }
  });

  return Array.from(optionsMap.values());
};

export const sortData = <T extends Record<string, any>>(data: T[], sortState: SortState, columns: TableColumn<T>[]): T[] => {
  if (!sortState.field || sortState.direction === 'none') return data;

  const column = columns.find((col) => col.accessor === sortState.field);
  if (!column) return data;

  return [...data].sort((a, b) => {
    let valueA: any;
    let valueB: any;
    let valuesA: any[];
    let valuesB: any[];

    switch (column.type) {
      case 'string':
      case 'avatar':
        valueA = column.valueFromCallBack ? column.valueFromCallBack(a[column.accessor], a) : a[column.accessor];
        valueB = column.valueFromCallBack ? column.valueFromCallBack(b[column.accessor], b) : b[column.accessor];
        valueA = normalizeString(valueA?.toString() || '');
        valueB = normalizeString(valueB?.toString() || '');
        break;
      case 'number':
      case 'progress':
        valueA =
          column.type === 'progress'
            ? (column.percentageValueFromCallBack?.(a[column.accessor], a) ?? 0)
            : (column.valueFromCallBack?.(a[column.accessor], a) ?? a[column.accessor] ?? 0);
        valueB =
          column.type === 'progress'
            ? (column.percentageValueFromCallBack?.(b[column.accessor], b) ?? 0)
            : (column.valueFromCallBack?.(b[column.accessor], b) ?? b[column.accessor] ?? 0);

        // Si es una columna de tipo number con formato de moneda, aseguramos que se trate como número
        if (column.type === 'number' && 'isCurrency' in column && column.isCurrency) {
          // Si valueA o valueB son strings (posiblemente por el formato de moneda), extraemos el valor numérico
          if (typeof valueA === 'string') {
            valueA = parseFloat(valueA.replace(/[^\d.-]/g, ''));
          }
          if (typeof valueB === 'string') {
            valueB = parseFloat(valueB.replace(/[^\d.-]/g, ''));
          }
        }

        valueA = Number(valueA);
        valueB = Number(valueB);
        break;
      case 'calendar':
        valueA = column.valueFromCallBack
          ? new Date(column.valueFromCallBack(a[column.accessor], a)).getTime()
          : new Date(a[column.accessor]).getTime();
        valueB = column.valueFromCallBack
          ? new Date(column.valueFromCallBack(b[column.accessor], b)).getTime()
          : new Date(b[column.accessor]).getTime();
        break;
      case 'boolean':
        valueA = column.valueFromCallBack ? column.valueFromCallBack(a[column.accessor], a) : a[column.accessor];
        valueB = column.valueFromCallBack ? column.valueFromCallBack(b[column.accessor], b) : b[column.accessor];
        break;
      case 'tag':
        valueA = column.textKeyFromCallBack ? column.textKeyFromCallBack(a[column.accessor], a) : a[column.accessor];
        valueB = column.textKeyFromCallBack ? column.textKeyFromCallBack(b[column.accessor], b) : b[column.accessor];
        valueA = normalizeString(valueA?.toString() || '');
        valueB = normalizeString(valueB?.toString() || '');
        break;
      case 'dropdown':
        valuesA = column.valueFromCallBack ? column.valueFromCallBack(a[column.accessor], a) : a[column.accessor];
        valuesB = column.valueFromCallBack ? column.valueFromCallBack(b[column.accessor], b) : b[column.accessor];
        valueA = normalizeString(Array.isArray(valuesA) ? valuesA[0] || '' : (valuesA as any)?.toString?.() || '');
        valueB = normalizeString(Array.isArray(valuesB) ? valuesB[0] || '' : (valuesB as any)?.toString?.() || '');
        break;
      default:
        return 0;
    }

    if (valueA === valueB) return 0;
    const comparison = valueA > valueB ? 1 : -1;
    return sortState.direction === 'asc' ? comparison : -comparison;
  });
};
