import React, { useMemo } from 'react';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { ExternalUserShort } from '../../types/externalUser';
import { classNames } from 'primereact/utils';
import { Avatar } from 'primereact/avatar';
import { AVATAR_PLACEHOLDER_URL } from '../../const';
import useSWR from 'swr';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { ValidationErrors } from '../../hooks/useForm';
import { UserShort } from '../../types/user';
import { getHashMap } from '../../utils/objectTransformers';

interface UserSelectItem extends UserShort {
  label: string;
  value: string;
}

const getUserOptions = (
  users?: ExternalUserShort[]
): UserSelectItem[] | undefined => {
  return users?.map((user) => ({
    label: `${user.name} ${user.surnames}`,
    value: user.id,
    ...user,
  }));
};

const userTemplate = (option: UserSelectItem) => {
  return (
    <div className='align-center half-gap'>
      <Avatar
        image={option?.avatarUrl || AVATAR_PLACEHOLDER_URL}
        shape='circle'
      />
      <div>{option.label}</div>
    </div>
  );
};

interface Props {
  onChange: (value: ExternalUserShort[]) => void;
  id?: string;
  value?: ExternalUserShort[];
  error?: string | (ValidationErrors<ExternalUserShort> | undefined)[];
  className?: string;
  placeholder?: string;
}

export const ExternalUsersSelect = ({
  onChange,
  id = 'users-dropdown',
  value,
  error,
  className,
  placeholder = 'Elige usuarios',
}: Props) => {
  const { get } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};

  const { data: externalUsers, isLoading: externalUsersLoading } = useSWR(
    organization
      ? `/organizations/${organization.id}/external-users?short=true`
      : null,
    get<ExternalUserShort[]>
  );

  const usersHashMap = useMemo(
    () => getHashMap(externalUsers ?? [], 'id'),
    [externalUsers]
  );
  const mappedValue = useMemo(() => value?.map((_) => _.id), [value]);

  const handleChange = (e: MultiSelectChangeEvent) => {
    const newExternalUsers: ExternalUserShort[] = e.value?.map(
      (externalUserId: string) => usersHashMap[externalUserId]
    );

    onChange(newExternalUsers);
  };

  return (
    <MultiSelect
      filter
      id={id}
      placeholder={placeholder}
      value={mappedValue}
      className={classNames(className, {
        'p-invalid': error,
      })}
      options={getUserOptions(externalUsers)}
      onChange={handleChange}
      itemTemplate={userTemplate}
      emptyMessage='No hay usuarios'
      emptyFilterMessage='No se han encontrado usuarios'
      loading={externalUsersLoading || organizationLoading}
    ></MultiSelect>
  );
};
