const css = `.sidebar-button.p-button {
  padding: 10px 14px;
  width: 100%;
  height: 40px;
  text-align: left;
  font-size: 14px;
  color: var(--primary600);
  transition: background-color 0.2s, box-shadow 0.2s, padding 0.5s ease;
}
.sidebar-button.p-button:focus, .sidebar-button.p-button:active {
  outline: 0 none;
  outline-offset: 0;
  transition: box-shadow 0.2s;
  box-shadow: inset var(--focus-ring);
}
.sidebar-button.p-button .p-button-label {
  font-size: 14px;
  transition: none;
  font-weight: 400;
  text-decoration: none;
  white-space: nowrap;
}
.sidebar-button.p-button .p-button-icon {
  font-size: 14px;
  margin-right: auto;
}
.sidebar-button.p-button:hover {
  background-color: var(--surface-hover);
}
.sidebar-button.p-button.selected {
  color: var(--disruptive);
}
.sidebar-button.p-button.selected span {
  font-weight: 700;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2xheW91dC9TaWRlYmFyL1NpZGViYXJCdXR0b24iLCJzb3VyY2VzIjpbIlNpZGViYXJCdXR0b24uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBLFlBQ0U7O0FBSUY7RUFFRTtFQUNBO0VBQ0E7RUFDQTs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRTs7QUFDQTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLnNpZGViYXItYnV0dG9uLnAtYnV0dG9uIHtcbiAgcGFkZGluZzogMTBweCAxNHB4O1xuICB3aWR0aDogMTAwJTtcbiAgaGVpZ2h0OiA0MHB4O1xuICB0ZXh0LWFsaWduOiBsZWZ0O1xuICBmb250LXNpemU6IDE0cHg7XG4gIGNvbG9yOiB2YXIoLS1wcmltYXJ5NjAwKTtcbiAgdHJhbnNpdGlvbjpcbiAgICBiYWNrZ3JvdW5kLWNvbG9yIDAuMnMsXG4gICAgYm94LXNoYWRvdyAwLjJzLFxuICAgIHBhZGRpbmcgMC41cyBlYXNlO1xuXG4gICY6Zm9jdXMsXG4gICY6YWN0aXZlIHtcbiAgICBvdXRsaW5lOiAwIG5vbmU7XG4gICAgb3V0bGluZS1vZmZzZXQ6IDA7XG4gICAgdHJhbnNpdGlvbjogYm94LXNoYWRvdyAwLjJzO1xuICAgIGJveC1zaGFkb3c6IGluc2V0IHZhcigtLWZvY3VzLXJpbmcpO1xuICB9XG4gIC5wLWJ1dHRvbi1sYWJlbCB7XG4gICAgZm9udC1zaXplOiAxNHB4O1xuICAgIHRyYW5zaXRpb246IG5vbmU7XG4gICAgZm9udC13ZWlnaHQ6IDQwMDtcbiAgICB0ZXh0LWRlY29yYXRpb246IG5vbmU7XG4gICAgd2hpdGUtc3BhY2U6IG5vd3JhcDtcbiAgfVxuXG4gIC5wLWJ1dHRvbi1pY29uIHtcbiAgICBmb250LXNpemU6IDE0cHg7XG4gICAgbWFyZ2luLXJpZ2h0OiBhdXRvO1xuICB9XG5cbiAgJjpob3ZlciB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tc3VyZmFjZS1ob3Zlcik7XG4gIH1cblxuICAmLnNlbGVjdGVkIHtcbiAgICBjb2xvcjogdmFyKC0tZGlzcnVwdGl2ZSk7XG4gICAgc3BhbiB7XG4gICAgICBmb250LXdlaWdodDogNzAwO1xuICAgIH1cbiAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
