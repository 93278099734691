import React, { FormEvent, useMemo } from 'react';
import './ReservationPanel.scss';

import { Sidebar } from 'primereact/sidebar';
import { Role } from '../../types/role';
import { Button } from 'primereact/button';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { FeaturePicker } from '../FeaturePicker/FeaturePicker';
import { useForm } from '../../hooks/useForm';
import { FormField } from '../FormField/FormField';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Image } from 'primereact/image';
import { es } from 'date-fns/locale';
import { NotificationMessage, RequestErrorMessages, useClient } from '../../hooks/useClient';
import { mutate } from 'swr';
import { resourceMutator } from '../../utils/resourceMutation';
import { Exercise } from '../../types/Fisiofit/exercise';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from '../FileUpload/FileUpload';
import { IMAGE_PLACEHOLDER } from '../../const/images';
import { MultiSelect } from 'primereact/multiselect';
import { SelectItemOptionsType } from 'primereact/selectitem';
import ExerciseList from '../ExerciseList/ExerciseList';
import { Routine } from '../../types/Fisiofit/routine';
import { PrimeIcons } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { add, format } from 'date-fns';
import { Avatar } from 'primereact/avatar';
import { Reservation } from '../../types/reservation';
import { UserShort } from '../../types/user';
import { BranchDisplay } from '../../types/branch';
import { ExternalUsersSelect } from '../ExternalUsersSelect/ExternalUsersSelect';
import { ExternalUser } from '../../types/externalUser';
import { InputNumber } from 'primereact/inputnumber';
import TimeSlotPicker from '../TimeSlotPicker/TimeSlotPicker';

const mockStartDate = new Date(2024, 11, 5, 3, 0);
const mockEndDate = new Date(2024, 11, 5, 18, 15);
const mockSessionDuration = 15;

const mockUsedSessions = [
  {
    startSession: new Date(2024, 11, 5, 9, 15),
    endSession: new Date(2024, 11, 5, 9, 30),
  },
  {
    startSession: new Date(2024, 11, 5, 10, 0),
    endSession: new Date(2024, 11, 5, 10, 15),
  },
];

const getReservationPanelTitle = (isCreating: boolean, isEditing: boolean) => {
  if (!isCreating && isEditing) {
    return 'Editar reserva';
  }
  if (!isCreating && !isEditing) {
    return 'Detalles de la reserva';
  }
  if (isCreating && !isEditing) {
    return 'Crear reserva de fisioterapia';
  }
};

const getInitialReservationForm = (routine?: Reservation): ReservationForm | undefined => {
  const { startDate, endDate, price, comment, reservationTypeId, user, externalUsers, branch } = routine ?? {};
  return {
    startDate,
    endDate,
    price,
    comment,
    reservationTypeId,
    user,
    externalUsers,
    branch,
  };
};

interface ReservationForm {
  startDate?: string;
  endDate?: string;

  price?: number;
  comment?: string;

  reservationTypeId?: string;
  user?: UserShort;
  externalUsers?: UserShort[];
  branch?: BranchDisplay;
}

interface Props {
  reservation: Reservation | undefined;
  visible: boolean;
  onHide: () => void;
  onIsEditingChange: (isEditing: boolean) => void;
  isEditing?: boolean;
}

export const ReservationPanel = ({ reservation, visible, onHide, onIsEditingChange, isEditing = false }: Props) => {
  const { organization } = useOrganizationContext() ?? {};
  const { post, patch } = useClient();
  const isCreating = !reservation;

  const initialReservationForm = useMemo(() => getInitialReservationForm(reservation), [reservation]);
  const { form, setFormField, isSaving, setIsSaving, hasChanged, validationErrors } = useForm<ReservationForm | undefined>(
    initialReservationForm,
    false,
  );

  const handleExerciseFormSave = async (e: FormEvent) => {
    e.preventDefault();
    /*const { name, muscleGroups } = form ?? {};
    if (!name || !muscleGroups || !organization) return;
    setIsSaving(true);

    const { successMessage, errorMessages } = getToastMessages(isEditing);
    const request = {
      body: {
        name,
        muscleGroups: Array.from(muscleGroups),
      },
      successMessage,
      errorMessages,
    };
    const responseRole =
      isEditing && exercise
        ? await patch<Role>(`/roles/${exercise.id}`, request)
        : await post<Role>(`/organizations/${organization.id}/roles`, request);

    if (responseRole) {
      mutate(
        `/organizations/${organization.id}/roles-table`,
        resourceMutator<Role>(responseRole),
        // The false arg makes it not refetch the data and rely only on our local mutation
        false
      );
    }*/
    setIsSaving(false);
    onHide();
  };

  const selectionMode = isCreating ? 'range' : 'single';

  const getDateMessage = (startDate: Date, endDate: Date) => {
    const formattedDate = format(new Date(startDate), "d 'de' MMMM 'de' yyyy", {
      locale: es,
    });
    const formattedStartTime = format(startDate, 'h:mm a', { locale: es });
    const formattedEndTime = format(endDate, 'h:mm a', { locale: es });
    return (
      <>
        {formattedDate}
        <br></br>
        {formattedStartTime} - {formattedEndTime}
      </>
    );
  };

  const employeeRowTemplate = (opt: ExternalUser) => {
    return (
      <div className='reservation-flex-row '>
        <Avatar shape='circle' image={opt.avatarUrl}></Avatar>
        <p>
          {opt.name} {opt.surnames}
        </p>
      </div>
    );
  };

  // variables
  const isSingle = true;
  const groupReservationName = 'gimnasio';
  const groupReservationLimit = 6;
  // una para el precio, x si se muestra o no
  // otra para mostrar o no el review
  // deberiamos tambien tener algo para decir que un tipo de reserva es ciclica, por ejemplo, as de gimnasio seran siempre cada hora y ya estaran puestas en el calendario x defecto

  return (
    <Sidebar className='reservation-panel' position='right' visible={visible} onHide={onHide}>
      <h3>{getReservationPanelTitle(isCreating, isEditing)}</h3>
      {!isCreating && !isEditing && (
        <div className='readonly-form'>
          {!isSingle && (
            <>
              <FormField
                labelTitle={`Reserva de ${groupReservationName} - ${
                  reservation.externalUsers.length ?? 0
                } de ${groupReservationLimit} plazas reservadas`}
                fullWidth
              >
                <ul>
                  {Array(groupReservationLimit)
                    .fill(null)
                    .map((_, index) => (
                      <li className='reservation-flex-row'>
                        {/* crear un estado Pending, que sea como el de plaza libre, pero que diga pendiente */}
                        {reservation.externalUsers.length > index ? (
                          <>
                            <Avatar shape='circle' image={reservation.externalUsers[index].avatarUrl}></Avatar>
                            <p>
                              {reservation.externalUsers[index].name} {reservation.externalUsers[index].surnames}
                            </p>
                          </>
                        ) : (
                          <>
                            <Avatar shape='circle' image={undefined} style={{ opacity: 0 }}></Avatar>
                            <p>Plaza libre</p>
                          </>
                        )}
                      </li>
                    ))}
                </ul>
              </FormField>
              <FormField labelTitle='Invitar usuarios' fullWidth>
                <ExternalUsersSelect onChange={() => {}}></ExternalUsersSelect>
              </FormField>
            </>
          )}
          {isSingle && (
            <div className='reservation-user-container'>
              <Avatar shape='circle' image={reservation.externalUsers[0].avatarUrl} size='xlarge'></Avatar>
              <h5>
                {reservation.externalUsers[0].name} {reservation.externalUsers[0].surnames}
              </h5>
            </div>
          )}
          <FormField labelTitle='Sucursal' fullWidth>
            <p>{reservation?.branch.name}</p>
          </FormField>
          <FormField labelTitle='Fecha' fullWidth>
            <p>{getDateMessage(new Date(reservation.startDate), new Date(reservation.endDate))}</p>
          </FormField>
          {isSingle && (
            <FormField labelTitle='Precio de la sesión' fullWidth>
              <p>
                {reservation.price ? (
                  <>
                    <strong>{reservation.price}</strong>&nbsp;euros
                  </>
                ) : (
                  '-'
                )}
              </p>
            </FormField>
          )}
          {isSingle && (
            <FormField labelTitle='Comentario' fullWidth>
              <p>{reservation.comment}</p>
            </FormField>
          )}
          {isSingle && (
            <FormField labelTitle='Empleado' fullWidth>
              <div className='reservation-flex-row'>
                <Avatar shape='circle' image={reservation.user.avatarUrl}></Avatar>
                <p>
                  {reservation.user.name} {reservation.user.surnames}
                </p>
              </div>
            </FormField>
          )}
        </div>
      )}
      {(isEditing || isCreating) && (
        <form
          className={classNames({
            'form-loading': isSaving,
          })}
          onSubmit={handleExerciseFormSave}
        >
          {isCreating && (
            <>
              <FormField labelTitle='Usuario' elementId='reservation-user' fullWidth>
                <Dropdown
                  id='reservation-user'
                  options={[
                    {
                      avatarUrl: undefined,
                      name: 'Jack',
                      surnames: 'content creator',
                    },
                    {
                      avatarUrl: undefined,
                      name: 'Adri',
                      surnames: 'hate figma',
                    },
                  ]}
                  itemTemplate={employeeRowTemplate}
                ></Dropdown>
              </FormField>
              <FormField labelTitle='Sucursal' elementId='reservation-branch' fullWidth>
                <Dropdown id='reservation-branch' options={['branch-1', 'branch-2']}></Dropdown>
              </FormField>
              <FormField labelTitle='Fecha' fullWidth>
                <Calendar
                  id='routine-end-date'
                  value={undefined}
                  className={classNames({
                    'p-invalid': false,
                  })}
                  placeholder='Selecciona fecha de la reserva'
                  onChange={(e: any) =>
                    setFormField({
                      endDate: e.value,
                    })
                  }
                  showIcon
                  dateFormat='dd/mm/yy'
                />
              </FormField>
              <FormField labelTitle='Hora' elementId='reservation-hour' fullWidth>
                <TimeSlotPicker
                  startDate={mockStartDate}
                  endDate={mockEndDate}
                  sessionDuration={mockSessionDuration}
                  usedSessions={mockUsedSessions}
                  onChange={() => {}}
                ></TimeSlotPicker>
              </FormField>
              <FormField labelTitle='Precio de la sesión' elementId='reservation-price' fullWidth>
                <InputNumber
                  id='reservation-price'
                  className={classNames({
                    'p-invalid': validationErrors?.price,
                  })}
                  useGrouping={false}
                  placeholder={`Escribe el precio de la reserva`}
                  value={form?.price ?? undefined}
                  onChange={(e: any) =>
                    setFormField({
                      price: e.target.value,
                    })
                  }
                />
              </FormField>
              <FormField labelTitle='Empleado' elementId='reservation-employee' fullWidth>
                <Dropdown
                  id='reservation-employee'
                  options={[
                    {
                      avatarUrl: undefined,
                      name: 'Jack',
                      surnames: 'content creator',
                    },
                    {
                      avatarUrl: undefined,
                      name: 'Adri',
                      surnames: 'hate figma',
                    },
                  ]}
                  itemTemplate={employeeRowTemplate}
                ></Dropdown>
              </FormField>
            </>
          )}
          {isEditing && !isSingle && (
            <>
              <FormField
                labelTitle={`Reserva de ${groupReservationName} - ${
                  form?.externalUsers?.length ?? 0
                } de ${groupReservationLimit} plazas reservadas`}
                fullWidth
              >
                <ul>
                  {Array(groupReservationLimit)
                    .fill(null)
                    .map((_, index) => (
                      <li className='reservation-flex-row'>
                        {/* crear un estado Pending, que sea como el de plaza libre, pero que diga pendiente */}
                        {form?.externalUsers?.length && form?.externalUsers?.length > index ? (
                          <>
                            <Avatar shape='circle' image={form.externalUsers[index].avatarUrl}></Avatar>
                            <p>
                              {form.externalUsers[index].name} {form.externalUsers[index].surnames}
                            </p>
                            <Button className='p-button-danger' text icon={PrimeIcons.TRASH}></Button>
                          </>
                        ) : (
                          <>
                            <Avatar shape='circle' image={undefined} style={{ opacity: 0 }}></Avatar>
                            <p>Plaza libre</p>
                          </>
                        )}
                      </li>
                    ))}
                </ul>
              </FormField>
              <FormField labelTitle='Invitar usuarios' fullWidth>
                <ExternalUsersSelect onChange={() => {}}></ExternalUsersSelect>
              </FormField>
              <FormField labelTitle='Sucursal' fullWidth>
                <p>{reservation?.branch.name}</p>
              </FormField>
              <FormField labelTitle='Fecha' fullWidth>
                <p>
                  {form?.startDate &&
                    form?.endDate &&
                    getDateMessage(new Date(form?.startDate ?? ''), new Date(form?.endDate ?? ''))}
                </p>
              </FormField>
            </>
          )}
          {isEditing && isSingle && (
            <>
              {form?.externalUsers && (
                <div className='reservation-user-container'>
                  <Avatar shape='circle' image={form?.externalUsers[0].avatarUrl} size='xlarge'></Avatar>
                  <h5>
                    {form?.externalUsers[0].name} {form?.externalUsers[0].surnames}
                  </h5>
                </div>
              )}
              <FormField labelTitle='Sucursal' fullWidth>
                <p>{reservation?.branch.name}</p>
              </FormField>
              <FormField labelTitle='Fecha' fullWidth>
                <p>
                  {form?.startDate &&
                    form?.endDate &&
                    getDateMessage(new Date(form?.startDate ?? ''), new Date(form?.endDate ?? ''))}
                </p>
              </FormField>
              <FormField labelTitle='Precio de la sesión' elementId='reservation-price' fullWidth>
                <InputNumber
                  id='reservation-price'
                  className={classNames({
                    'p-invalid': validationErrors?.price,
                  })}
                  useGrouping={false}
                  placeholder={`Escribe el precio de la reserva`}
                  value={form?.price ?? undefined}
                  onChange={(e: any) =>
                    setFormField({
                      price: e.target.value,
                    })
                  }
                />
              </FormField>
              <FormField labelTitle='Comentario' elementId='reservation-comment' fullWidth>
                <InputTextarea
                  id='reservation-comment'
                  className={classNames({
                    'p-invalid': validationErrors?.comment,
                  })}
                  placeholder={`Escribe un comentario sobre la reserva`}
                  autoResize
                  value={form?.comment ?? ''}
                  onChange={(e: any) =>
                    setFormField({
                      comment: e.target.value,
                    })
                  }
                  rows={3}
                />
              </FormField>
              <FormField labelTitle='Empleado' elementId='reservation-employee' fullWidth>
                <Dropdown
                  id='reservation-employee'
                  options={[
                    {
                      avatarUrl: undefined,
                      name: 'Jack',
                      surnames: 'content creator',
                    },
                    {
                      avatarUrl: undefined,
                      name: 'Adri',
                      surnames: 'hate figma',
                    },
                  ]}
                  itemTemplate={employeeRowTemplate}
                ></Dropdown>
              </FormField>
            </>
          )}
        </form>
      )}
      <div className='routine-panel-bottom-buttons'>
        {!isEditing && !isCreating && (
          <Button className='pull-right pull-down' label={'Editar reserva'} onClick={() => onIsEditingChange(true)} />
        )}

        {(isEditing || isCreating) && (
          <Button
            className='pull-right pull-down'
            type='submit'
            label={isEditing ? 'Guardar reserva' : 'Crear reserva'}
            disabled={!hasChanged}
          />
        )}
      </div>
    </Sidebar>
  );
};

const getToastMessages = (
  isEditing: boolean,
): {
  successMessage: NotificationMessage;
  errorMessages: RequestErrorMessages;
} => {
  return {
    successMessage: {
      summary: isEditing ? 'Reserva modificada' : 'Reserva creada',
      detail: isEditing ? 'La reserva se ha modificado correctamente' : 'Se ha creado una nueva reserva',
    },
    errorMessages: {
      summary: isEditing ? 'Error modificando la reserva' : 'Error al crear la reserva',
      defaultDetail: isEditing ? 'No se ha podido modificar la reserva' : 'No se ha podido crear la reserva',
    },
  };
};
