import React from 'react';
import { NoAuthPageTemplate } from '../../layout/NoAuthPageTemplate/NoAuthPageTemplate';
import { LinkButton } from '../../components/LinkButton/LinkButton';
import { Image } from 'primereact/image';
import { useAuthContext } from '../../context/AuthContext';

interface Props {
  title?: string;
  message?: string;
}

export const ErrorPage = ({
  title = 'Error',
  message = 'No se ha encontrado la página.',
}: Props) => {
  const { user } = useAuthContext() ?? {};

  return (
    <NoAuthPageTemplate icon='pi pi-fw pi-exclamation-circle' color='error'>
      <h2>{title}</h2>
      <p>{message}</p>
      <Image src='https://s3.eu-north-1.amazonaws.com/cdn.no-gravity.es/dashboard/error.svg' />
      <LinkButton to='/' icon='pi pi-arrow-left'>
        {user ? 'Volver a panel de control' : 'Volver a inicio de sesión'}
      </LinkButton>
    </NoAuthPageTemplate>
  );
};
