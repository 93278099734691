import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import './SidebarButton.scss';
import { getRouteSteps } from '../../../utils/navigation';
import { LinkButton } from '../../../components/LinkButton/LinkButton';
import { Button } from 'primereact/button';

const ROUTE_STEP_INDEX = 0;

interface Props {
  label: string;
  href?: string;
  icon?: string;
  onClick?: (e: any) => void;
}

export const SidebarButton = ({ label, href, icon = '', onClick }: Props) => {
  const location = useLocation();
  const currentPage = getRouteSteps(location)[ROUTE_STEP_INDEX];
  const routeName = href?.replace('/', '');

  return (
    <>
      {!onClick && (
        <LinkButton
          className={`sidebar-button ${currentPage === routeName ? ' selected' : ''}`}
          to={href || ''}
          label={label}
          icon={icon}
        ></LinkButton>
      )}
      {onClick && (
        <Button
          className={`sidebar-button ${currentPage === routeName ? ' selected' : ''}`}
          text
          label={label}
          icon={icon}
          onClick={onClick}
        ></Button>
      )}
    </>
  );
};
