import React, { PropsWithChildren, createContext, useContext, useState } from 'react';
import { Website } from '../types/website';
import useSWR from 'swr';
import { useClient } from '../hooks/useClient';
import { useAuthContext } from './AuthContext';

interface WebsiteContextValue {
  userWebsites: Website[];
  setWebsite: (website: Website) => void;
  website: Website | undefined;
  websitesLoading: boolean;
}

const WebsiteContext = createContext<WebsiteContextValue | null>(null);

export const WebsiteProvider = ({ children }: PropsWithChildren) => {
  const { get } = useClient();
  const { user, userLoading } = useAuthContext() ?? {};
  const [website, setWebsite] = useState<Website>();

  const fetchWebsites = async (url: string) => {
    const websites = await get<Website[]>(url);
    if (websites) {
      setWebsite(websites[0]);
    }
    return websites;
  };

  const {
    data: userWebsites = [],
    isLoading: websitesLoading,
    error: websitesError,
  } = useSWR(user ? `/users/${user.id}/websites` : null, fetchWebsites);

  return (
    <WebsiteContext.Provider
      value={{
        setWebsite,
        userWebsites,
        website,
        websitesLoading: websitesLoading || !!userLoading,
      }}
    >
      {children}
    </WebsiteContext.Provider>
  );
};

export const useWebsiteContext = () => {
  return useContext(WebsiteContext);
};
