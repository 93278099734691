const css = `.sidebar-button.p-button {
  padding: 10px 14px;
  width: 100%;
  height: 40px;
  text-align: left;
  font-size: 14px;
  color: var(--primary600);
  transition: background-color 0.2s, box-shadow 0.2s, padding 0.5s ease;
}
.sidebar-button.p-button:focus, .sidebar-button.p-button:active {
  outline: 0 none;
  outline-offset: 0;
  transition: box-shadow 0.2s;
  box-shadow: inset var(--focus-ring);
}
.sidebar-button.p-button span {
  transition: none;
  font-weight: 400;
  text-decoration: none;
  white-space: nowrap;
}
.sidebar-button.p-button .p-button-icon {
  font-size: 14px;
  margin-right: auto;
}
.sidebar-button.p-button:hover {
  background-color: var(--surface-hover);
}
.sidebar-button.p-button.selected {
  color: var(--disruptive);
}
.sidebar-button.p-button.selected span {
  font-weight: 700;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2xheW91dC9TaWRlYmFyL1NpZGViYXJCdXR0b24iLCJzb3VyY2VzIjpbIlNpZGViYXJCdXR0b24uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBRUU7RUFDQTtFQUNBO0VBQ0E7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFOztBQUNBO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuc2lkZWJhci1idXR0b24ucC1idXR0b24ge1xuICBwYWRkaW5nOiAxMHB4IDE0cHg7XG4gIHdpZHRoOiAxMDAlO1xuICBoZWlnaHQ6IDQwcHg7XG4gIHRleHQtYWxpZ246IGxlZnQ7XG4gIGZvbnQtc2l6ZTogMTRweDtcbiAgY29sb3I6IHZhcigtLXByaW1hcnk2MDApO1xuICB0cmFuc2l0aW9uOiBiYWNrZ3JvdW5kLWNvbG9yIDAuMnMsIGJveC1zaGFkb3cgMC4ycywgcGFkZGluZyAwLjVzIGVhc2U7XG5cbiAgJjpmb2N1cyxcbiAgJjphY3RpdmUge1xuICAgIG91dGxpbmU6IDAgbm9uZTtcbiAgICBvdXRsaW5lLW9mZnNldDogMDtcbiAgICB0cmFuc2l0aW9uOiBib3gtc2hhZG93IDAuMnM7XG4gICAgYm94LXNoYWRvdzogaW5zZXQgdmFyKC0tZm9jdXMtcmluZyk7XG4gIH1cbiAgc3BhbiB7XG4gICAgdHJhbnNpdGlvbjogbm9uZTtcbiAgICBmb250LXdlaWdodDogNDAwO1xuICAgIHRleHQtZGVjb3JhdGlvbjogbm9uZTtcbiAgICB3aGl0ZS1zcGFjZTogbm93cmFwO1xuICB9XG5cbiAgLnAtYnV0dG9uLWljb24ge1xuICAgIGZvbnQtc2l6ZTogMTRweDtcbiAgICBtYXJnaW4tcmlnaHQ6IGF1dG87XG4gIH1cblxuICAmOmhvdmVyIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1zdXJmYWNlLWhvdmVyKTtcbiAgfVxuXG4gICYuc2VsZWN0ZWQge1xuICAgIGNvbG9yOiB2YXIoLS1kaXNydXB0aXZlKTtcbiAgICBzcGFuIHtcbiAgICAgIGZvbnQtd2VpZ2h0OiA3MDA7XG4gICAgfVxuICB9XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
