import React, { FormEvent, useMemo } from 'react';
import './ExercisePanel.scss';

import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { useForm } from '../../hooks/useForm';
import { FormField } from '../FormField/FormField';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Image } from 'primereact/image';
import { NotificationMessage, RequestErrorMessages, useClient } from '../../hooks/useClient';
import { Exercise } from '../../types/Fisiofit/exercise';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from '../FileUpload/FileUpload';
import { IMAGE_PLACEHOLDER } from '../../const/images';
import { MultiSelect } from 'primereact/multiselect';
import { SelectItemOptionsType } from 'primereact/selectitem';

const getExercisePanelTitle = (isCreating: boolean, isEditing: boolean) => {
  if (!isCreating && isEditing) {
    return 'Editar ejercicio';
  }
  if (!isCreating && !isEditing) {
    return 'Detalles del ejercicio';
  }
  if (isCreating && !isEditing) {
    return 'Crear nuevo ejercicio';
  }
};

const getInitialExerciseForm = (exercise?: Exercise): ExerciseForm | undefined => {
  const { muscleGroups, name, description, videoUrl, imageUrl } = exercise ?? {};
  return {
    name,
    muscleGroups: new Set(muscleGroups),
    description,
    video: videoUrl,
    image: imageUrl,
  };
};

const getMuscleOptions = (muscles?: Set<string> | undefined): SelectItemOptionsType[] | undefined => {
  const result = Array.from(muscles || []).map((muscle) => ({
    label: muscle,
    value: muscle,
  }));
  return result as any[];
};

interface ExerciseForm {
  name?: string;
  description?: string;
  muscleGroups?: Set<string>;
  image?: string;
  video?: string;
}

interface Props {
  exercise: Exercise | undefined;
  visible: boolean;
  onHide: () => void;
  onIsEditingChange: (isEditing: boolean) => void;
  isEditing?: boolean;
}

export const ExercisePanel = ({ exercise, visible, onHide, onIsEditingChange, isEditing = false }: Props) => {
  const { organization } = useOrganizationContext() ?? {};
  const { post, patch } = useClient();
  const isCreating = !exercise;

  const initialExerciseForm = useMemo(() => getInitialExerciseForm(exercise), [exercise]);
  const { form, setFormField, isSaving, setIsSaving, hasChanged, validationErrors } = useForm<ExerciseForm | undefined>(
    initialExerciseForm,
    false,
  );

  const handleExerciseFormSave = async (e: FormEvent) => {
    e.preventDefault();
    /*const { name, muscleGroups } = form ?? {};
    if (!name || !muscleGroups || !organization) return;
    setIsSaving(true);

    const { successMessage, errorMessages } = getToastMessages(isEditing);
    const request = {
      body: {
        name,
        muscleGroups: Array.from(muscleGroups),
      },
      successMessage,
      errorMessages,
    };
    const responseRole =
      isEditing && exercise
        ? await patch<Role>(`/roles/${exercise.id}`, request)
        : await post<Role>(`/organizations/${organization.id}/roles`, request);

    if (responseRole) {
      mutate(
        `/organizations/${organization.id}/roles-table`,
        resourceMutator<Role>(responseRole),
        // The false arg makes it not refetch the data and rely only on our local mutation
        false
      );
    }*/
    setIsSaving(false);
    onHide();
  };

  return (
    <Sidebar className='exercise-panel' position='right' visible={visible} onHide={onHide}>
      <h3>{getExercisePanelTitle(isCreating, isEditing)}</h3>
      {!isCreating && !isEditing && (
        <div className='readonly-form'>
          <FormField labelTitle='Nombre' fullWidth>
            <p>{exercise?.name}</p>
          </FormField>
          <FormField labelTitle='Descripción' fullWidth>
            <p>{exercise?.description}</p>
          </FormField>
          <FormField labelTitle='Músculos' fullWidth>
            <p>{exercise?.muscleGroups?.join(', ')}</p>
          </FormField>

          {exercise.videoUrl && (
            <video className={classNames('exercise-panel-video')} width='100%' height='240'>
              <source src={exercise?.videoUrl || IMAGE_PLACEHOLDER} type='video/mp4'></source>
              Your browser does not support the video tag.
            </video>
          )}
          {exercise.imageUrl && (
            <Image
              className={classNames('exercise-panel-image')}
              src={exercise?.imageUrl || IMAGE_PLACEHOLDER}
              preview={!!exercise?.imageUrl}
            />
          )}
        </div>
      )}
      {(isEditing || isCreating) && (
        <form
          className={classNames({
            'form-loading': isSaving,
          })}
          onSubmit={handleExerciseFormSave}
        >
          <FormField labelTitle='Nombre' elementId='exercise-name' fullWidth>
            <InputText
              id='exercise-name'
              className={classNames({
                'p-invalid': validationErrors?.name,
              })}
              placeholder={`Escribe el nombre del ejercicio`}
              value={form?.name ?? ''}
              onChange={(e: any) =>
                setFormField({
                  name: e.target.value,
                })
              }
            />
          </FormField>
          <FormField labelTitle='Descripción' elementId='exercise-description' fullWidth>
            <InputTextarea
              id='exercise-description'
              className={classNames({
                'p-invalid': validationErrors?.description,
              })}
              placeholder={`Escribe la descripción del ejercicio`}
              autoResize
              value={form?.description ?? ''}
              onChange={(e: any) =>
                setFormField({
                  description: e.target.value,
                })
              }
              rows={3}
            />
          </FormField>
          <FormField labelTitle='Músculos implicados' elementId='exercise-muscles' fullWidth>
            <MultiSelect
              filter
              value={Array.from(form?.muscleGroups ?? [])}
              className={classNames({
                'p-invalid': validationErrors?.muscleGroups,
              })}
              options={getMuscleOptions(form?.muscleGroups)}
              onChange={(e: any) => {
                console.log('dispara');
                /*setFormField({
                  description: e.target.value,
                })*/
              }}
              emptyMessage='No hay músculos'
              emptyFilterMessage='No se han encontrado músculos'
            ></MultiSelect>
          </FormField>
          <FormField labelTitle='Vídeo' elementId='video' error={validationErrors?.video}>
            <FileUpload
              chooseLabel='Subir vídeo'
              mode='basic'
              accept='video/*'
              value={form?.video}
              invalid={!!validationErrors?.video}
              onImageUpload={(encodedVideo, file) => {
                setFormField({
                  video: form?.video,
                });
              }}
            />
          </FormField>
          {form?.video && (
            <video
              className={classNames('exercise-panel-video', {
                'placeholder-video': form && !form.video,
              })}
              width='100%'
              height='240'
            >
              <source src={form?.video || IMAGE_PLACEHOLDER} type='video/mp4'></source>
              Your browser does not support the video tag.
            </video>
          )}
          <FormField labelTitle='Imágen' elementId='image' error={validationErrors?.image}>
            <FileUpload
              chooseLabel='Subir imagen'
              mode='basic'
              accept='image/*'
              value={form?.image}
              invalid={!!validationErrors?.image}
              onImageUpload={(encodedImage, file) => {
                setFormField({
                  image: form?.image,
                });
              }}
            />
          </FormField>
          {form?.image && (
            <Image
              className={classNames('exercise-panel-image', {
                'placeholder-image': form && !form.image,
              })}
              src={form?.image || IMAGE_PLACEHOLDER}
              preview={!!form?.image}
            />
          )}
          <Button
            className='pull-right pull-down'
            type='submit'
            label={isEditing ? 'Editar ejercicio' : 'Crear ejercicio'}
            disabled={!hasChanged}
          />
        </form>
      )}
      {!isEditing && !isCreating && (
        <Button className='pull-right pull-down' label={'Editar ejercicio'} onClick={() => onIsEditingChange(true)} />
      )}
    </Sidebar>
  );
};

const getToastMessages = (
  isEditing: boolean,
): {
  successMessage: NotificationMessage;
  errorMessages: RequestErrorMessages;
} => {
  return {
    successMessage: {
      summary: isEditing ? 'Rol modificado' : 'Rol creado',
      detail: isEditing ? 'El ejercicio se ha modificado correctamente' : 'Se ha creado el nuevo ejercicio',
    },
    errorMessages: {
      summary: isEditing ? 'Error modificando el ejercicio' : 'Error al crear el ejercicio',
      defaultDetail: isEditing ? 'No se ha podido modificar el ejercicio' : 'No se ha podido crear el ejercicio',
    },
  };
};
