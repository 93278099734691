import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';

const { EMPTY } = VALIDATION_MESSAGES;

export interface SupportForm {
  message: string;
}

export const SUPPORT_FORM_VALIDATORS: FormValidators<SupportForm> = {
  message: (form) => {
    if (!form.message?.length) return EMPTY;
  },
};
