import { classNames } from 'primereact/utils';
import React, { PropsWithChildren } from 'react';

interface Props {
  position?: 'static' | 'absolute';
}

// TODO extends this so that it will work for all form helper texts and use error state for errors
export const FormElementError = ({ children, position = 'absolute' }: PropsWithChildren<Props>) => {
  return (
    <small
      className={classNames('field-error', {
        static: position === 'static',
      })}
    >
      {children}
    </small>
  );
};
