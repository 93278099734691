import React, { ReactNode, useRef, useState } from 'react';
import './ResponsiveTable.scss';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { Menu } from 'primereact/menu';
import { Paginator } from 'primereact/paginator';
import { Sidebar } from 'primereact/sidebar';
import { filterData, paginateData } from './tableUtils';
import { TableFilter } from '../TableFilter/TableFilter';
import { TableRows } from '../TableRows/TableRows';
import { TableColumn, TableAction } from '../../types/responsiveTable';
import { Card } from 'primereact/card';

interface Props<T extends Record<string, any>> {
  columns: TableColumn<T>[];
  data: T[];
  options: TableAction[];
  loading?: boolean;
  headerOptions?: ReactNode;
  onRowClick?: (row: any) => void;
}

export const ResponsiveTable = <T extends Record<string, any>>({
  columns,
  data = [],
  options,
  loading,
  headerOptions,
  onRowClick,
}: Props<T>) => {
  const menu = useRef(null);
  const optionRowId = useRef<string>('');
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [showFilters, setShowFilters] = useState(false);
  const [showSliderFilters, setShowSliderFilters] = useState(false);
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [sliderFilters, setSliderFilters] = useState<Record<string, any>>({});

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const items = [
    {
      items: options.map((opt) => {
        return {
          label: opt.label,
          command: () => opt.onClick && opt.onClick(optionRowId.current),
        };
      }),
    },
  ];

  const onOptionsClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    optionRowId.current = id;
    if (menu.current) {
      (menu.current as any).toggle(event);
    }
  };

  const handleFilterChange = (
    key: string,
    values: any[],
    isSliderFilters = false
  ) => {
    if (isSliderFilters) {
      setSliderFilters((prev) => ({
        ...prev,
        [key]: values,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [key]: values,
      }));
    }
  };

  const filteredData = filterData(data, columns, filters);
  const paginatedData = paginateData(filteredData, first, rows);

  const onHide = () => {
    setShowSliderFilters(false);
  };

  const applySliderFilters = () => {
    setFilters((prev) => ({
      ...prev,
      ...sliderFilters,
    }));
    onHide();
  };
  const clearFilters = () => {
    setFilters({});
    setSliderFilters({});
    onHide();
  };

  return (
    <Card
      className='responsive-table'
      header={
        <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
          {headerOptions}
          <Button
            label='Limpiar filtros'
            text
            icon={PrimeIcons.FILTER_SLASH}
            iconPos='right'
            onClick={clearFilters}
            className='clear-filters clear-filters-table'
          />
          <Button
            label={(!showFilters ? 'Mostrar' : 'Esconder') + ' filtros'}
            text
            icon={PrimeIcons.FILTER}
            iconPos='right'
            onClick={() => setShowFilters(!showFilters)}
            className='show-filters-button'
          />
        </div>
      }
    >
      <div style={{ position: 'relative' }}>
        {!loading && (
          <>
            <Button
              icon={PrimeIcons.FILTER}
              rounded
              className='floating-button p-button-secondary'
              onClick={() => setShowSliderFilters(true)}
              aria-label='Filter'
            />
          </>
        )}
        <div style={{ overflowY: 'auto' }}>
          <table>
            <thead>
              <tr>
                <TableFilter
                  columns={columns}
                  filters={filters}
                  data={data}
                  onFilterChange={handleFilterChange}
                  loading={loading}
                  showFilters={showFilters}
                ></TableFilter>
                {!loading && <th className='option'></th>}
              </tr>
            </thead>
            <tbody>
              <TableRows
                data={paginatedData}
                columns={columns}
                onOptionsClick={onOptionsClick}
                loading={loading}
                onRowClick={onRowClick}
              ></TableRows>
              <Menu model={items} ref={menu} popup />
            </tbody>
          </table>
        </div>
        <Paginator
          first={first}
          rows={rows}
          totalRecords={(data || []).length}
          rowsPerPageOptions={[10, 25, 50]}
          onPageChange={onPageChange}
          template={{ layout: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown' }}
        />
        {!loading && (
          <Sidebar
            className='filters-sidebar'
            position='right'
            visible={showSliderFilters}
            onHide={onHide}
          >
            <Button
              label='Limpiar filtros'
              text
              icon={PrimeIcons.FILTER_SLASH}
              iconPos='right'
              onClick={clearFilters}
              className='clear-filters'
            />
            <div className='filters-sidebar-container'>
              <TableFilter
                columns={columns}
                filters={sliderFilters}
                data={data}
                onFilterChange={handleFilterChange}
                isSlider={true}
                showFilters={true}
              ></TableFilter>
            </div>
            <Button
              label='Aplicar filtros'
              onClick={applySliderFilters}
              className='apply-filters'
            />
          </Sidebar>
        )}
      </div>
    </Card>
  );
};
