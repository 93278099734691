// Wrapper around useNavigate that scrolls to the top of the page when the route changes
import { NavigateOptions, To, useNavigate } from 'react-router-dom';

export const useNavigateScroll = () => {
  const navigate = useNavigate();
  return (to: To, options?: NavigateOptions) => {
    const contentRef = document.querySelector('.content');
    if (contentRef) {
      contentRef.scrollTo({ top: 0, behavior: 'instant' });
    }
    navigate(to, options);
  };
};
