const css = `.radio-button-group {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
}
.radio-button-group .radio-options {
  display: flex;
  gap: 32px;
  justify-content: center;
}
.radio-button-group .radio-options .radio-option {
  display: flex;
  align-items: center;
  gap: 8px;
}
.radio-button-group .radio-options .radio-option label {
  font-weight: normal;
  color: var(--primary600);
}
.radio-button-group .field-error {
  left: 50% !important;
  top: 44px !important;
  transform: translateX(-50%);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvUmFkaW9CdXR0b25Hcm91cCIsInNvdXJjZXMiOlsiUmFkaW9CdXR0b25Hcm91cC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7O0FBS047RUFDRTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIucmFkaW8tYnV0dG9uLWdyb3VwIHtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBnYXA6IDhweDtcbiAgcGFkZGluZzogMjBweDtcblxuICAucmFkaW8tb3B0aW9ucyB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBnYXA6IDMycHg7XG4gICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG5cbiAgICAucmFkaW8tb3B0aW9uIHtcbiAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgICAgZ2FwOiA4cHg7XG5cbiAgICAgIGxhYmVsIHtcbiAgICAgICAgZm9udC13ZWlnaHQ6IG5vcm1hbDtcbiAgICAgICAgY29sb3I6IHZhcigtLXByaW1hcnk2MDApO1xuICAgICAgfVxuICAgIH1cbiAgfVxuXG4gIC5maWVsZC1lcnJvciB7XG4gICAgbGVmdDogNTAlICFpbXBvcnRhbnQ7XG4gICAgdG9wOiA0NHB4ICFpbXBvcnRhbnQ7XG4gICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVYKC01MCUpO1xuICB9XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
