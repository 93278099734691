import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { UpdatePassword, User } from '../../types/user';
import {
  validateEmail,
  validateLettersAndSpaces,
  validatePassword,
} from '../../utils/validationUtils';

const { EMPTY, NOT_EMAIL, INVALID_PASSWORD, ONLY_LETTERS_AND_SPACES } = VALIDATION_MESSAGES;

export const USER_FORM_VALIDATORS: FormValidators<User> = {
  name: (user) => {
    if (!user.name?.length) return EMPTY;
    if (!validateLettersAndSpaces(user.name)) return ONLY_LETTERS_AND_SPACES;
  },
  surnames: (user) => {
    if (!user.surnames?.length) return EMPTY;
    if (!validateLettersAndSpaces(user.surnames)) return ONLY_LETTERS_AND_SPACES;
  },
  email: (user) => {
    if (!user.email || !validateEmail(user.email)) return NOT_EMAIL;
  },
  // No hace falta hacer validación de fecha porque el calendario ya bloquea fechas inválidas
  // No hace falta hacer validación de teléfono porque el input mask ya lo hace
};

export const PASSWORD_FORM_VALIDATORS: FormValidators<UpdatePassword> = {
  oldPassword: (form) => {
    if (!form.oldPassword || !validatePassword(form.oldPassword)) {
      return 'Introduce tu contraseña antigua';
    }
  },
  newPassword: (form) => {
    if (!form.newPassword || !validatePassword(form.newPassword)) {
      return INVALID_PASSWORD;
    }
  },
};
