import { Camera } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { CameraResultType } from '@capacitor/camera';

export const transformUrlToImageFile = async (url: string, name: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], name, { type: 'image/png' });
};

export const uploadNativeImage = async (fileName: string): Promise<File | undefined> => {
  if (!Capacitor.isNativePlatform()) return;

  const permission = await Camera.requestPermissions();
  if (permission.camera !== 'granted') {
    console.error('Camera permission not granted');
    return;
  }

  const result = await Camera.getPhoto({
    quality: 80,
    allowEditing: false,
    resultType: CameraResultType.DataUrl,
  });

  if (!result.dataUrl) {
    console.error('Did not obtain any image from capacitor camera plugin');
    return;
  }

  const file = await transformUrlToImageFile(result.dataUrl, fileName);
  return file;
};

export const processFileToDataUrl = (file: File, cb: (result: string) => void): void => {
  const reader = new FileReader();
  reader.onload = () => {
    cb(reader.result?.toString() ?? '');
  };
  reader.readAsDataURL(file);
};
