import React from 'react';
import './CardEmptyState.scss';

interface Props {
  text?: string;
}

export const CardEmptyState = ({ text }: Props) => {
  return (
    <div className='card-empty-state'>
      <i className='pi pi-info-circle' />
      <span>{text ?? 'No hay datos'}</span>
    </div>
  );
};
