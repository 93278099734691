import React, { useMemo, useState } from 'react';
import './UserForm.scss';

import { useOrganizationContext } from '../../context/OrganizationContext';
import { Role } from '../../types/role';
import { FormField } from '../FormField/FormField';
import { Dropdown } from 'primereact/dropdown';
import useSWR from 'swr';
import { useClient } from '../../hooks/useClient';
import { Switch } from '../Switch/Switch';
import { FeaturePicker } from '../FeaturePicker/FeaturePicker';
import { useForm } from '../../hooks/useForm';
import { Feature } from '../../config/features';
import { User } from '../../types/user';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { resourceMutator } from '../../utils/resourceMutation';
import { Branch } from '../../types/branch';
import { MultiSelect } from 'primereact/multiselect';
import { useAuthContext } from '../../context/AuthContext';
import { getBranchOptions, getRoleOptions } from '../../utils/selectOptions';

interface UserForm {
  name?: string;
  surnames?: string;
  email?: string;
  branchIds?: string[];
  roleId?: string;
  roleName?: string;
  roleFeatures?: Set<Feature>;
}

const getInitialUserForm = (user?: User): UserForm | undefined => {
  const { name, email, surnames, branches, role } = user ?? {};
  return {
    name,
    surnames,
    email,
    branchIds: branches?.map((_) => _.id),
    roleId: role?.id,
    roleFeatures: new Set(),
  };
};

interface Props {
  user?: User;
  onSave?: (user: User, role: Role) => void;
}

export const UserForm = ({ user, onSave }: Props) => {
  const { get, patch, post } = useClient();
  const { organization } = useOrganizationContext() ?? {};
  const { user: authUser } = useAuthContext() ?? {};
  const [isCreatingNewRole, setIsCreatingNewRole] = useState(false);

  const isEditing = !!user?.id;

  const {
    data: orgBranches,
    isLoading: orgBranchesLoading,
    error: branchesError,
  } = useSWR(organization ? `/organizations/${organization.id}/branches` : null, get<Branch[]>);
  const {
    data: orgRoles,
    isLoading: orgRolesLoading,
    error: orgRolesError,
    mutate: mutateOrgRoles,
  } = useSWR(organization ? `/organizations/${organization.id}/roles` : null, get<Role[]>);

  const initialUserForm = useMemo(() => getInitialUserForm(user), [user]);
  const { form, setFormField, isSaving, setIsSaving, hasChanged, validationErrors } = useForm<UserForm | undefined>(
    initialUserForm,
    false,
  );

  const createRole = async () => {
    const { roleName, roleFeatures } = form ?? {};
    if (!roleName || !roleFeatures) return;
    return await post<Role>(`/organizations/${organization?.id}/roles`, {
      body: {
        name: roleName,
        features: Array.from(roleFeatures),
      },
    });
  };

  // TODO refactor this and the fn below into a single fn
  const editUser = async () => {
    if (!form) return;
    if (isCreatingNewRole) {
      const createdRole = await createRole();
      if (!createdRole) return;
      const updatedUser = await patch<User>(`/users/${user?.id}`, {
        body: {
          roleId: createdRole.id,
          branchIds: form.branchIds,
        },
      });
      if (!updatedUser) return;
      onSave?.(updatedUser, createdRole);
      mutateOrgRoles(resourceMutator(createdRole), false);
      return;
    }

    const updatedUser = await patch<User>(`/users/${user?.id}`, {
      body: {
        roleId: form.roleId,
        branchIds: form.branchIds,
      },
    });
    const newSelectedRole = orgRoles?.find((_) => _.id === updatedUser?.role.id);
    if (!updatedUser || !newSelectedRole) return;
    onSave?.(updatedUser, newSelectedRole);
  };

  const createUser = async () => {
    if (!form) return;
    const { name, surnames, email, roleId, branchIds } = form;
    const inviterName = `${authUser?.name} ${authUser?.surnames}`;
    const inviterOrg = organization?.name;
    if (isCreatingNewRole) {
      const createdRole = await createRole();
      if (!createdRole) return;
      const createdUser = await post<User>(`/users/add-employee`, {
        body: {
          name,
          surnames,
          email,
          branchIds,
          inviterName,
          inviterOrg,
          roleId: createdRole.id,
        },
      });
      if (!createdUser) return;
      onSave?.(createdUser, createdRole);
      mutateOrgRoles(resourceMutator(createdRole), false);
      return;
    }

    const createdUser = await post<User>(`/users/add-employee`, {
      body: {
        name,
        surnames,
        email,
        branchIds,
        inviterName,
        inviterOrg,
        roleId,
      },
    });
    const newSelectedRole = orgRoles?.find((_) => _.id === createdUser?.role.id);
    if (!createdUser || !newSelectedRole) return;
    onSave?.(createdUser, newSelectedRole);
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    if (isEditing) {
      await editUser();
    } else {
      await createUser();
    }
    setIsSaving(false);
  };

  return (
    <>
      <form
        className={classNames('user-form', {
          editing: isEditing,
          'form-loading': orgRolesLoading || orgBranchesLoading || isSaving,
        })}
      >
        {!isEditing && (
          <>
            <FormField elementId='employee-name' error={validationErrors?.name} labelTitle='Nombre'>
              <InputText
                className={classNames({
                  'p-invalid': validationErrors?.name,
                })}
                id='employee-name'
                name='employee-name'
                placeholder={`Escribe el nombre del empleado`}
                value={form?.name ?? ''}
                onChange={(e: any) =>
                  setFormField({
                    name: e.target.value,
                  })
                }
              />
            </FormField>
            <FormField elementId='employee-surnames' error={validationErrors?.surnames} labelTitle='Apellidos'>
              <InputText
                className={classNames({
                  'p-invalid': validationErrors?.surnames,
                })}
                id='employee-surnames'
                name='employee-surnames'
                placeholder={`Escribe los apellidos del empleado`}
                value={form?.surnames ?? ''}
                onChange={(e: any) =>
                  setFormField({
                    surnames: e.target.value,
                  })
                }
              />
            </FormField>
            <FormField elementId='employee-email' error={validationErrors?.email} labelTitle='Correo electrónico'>
              <InputText
                className={classNames({
                  'p-invalid': validationErrors?.email,
                })}
                id='employee-email'
                name='employee-email'
                placeholder={`Escribe el correo electrónico del empleado`}
                value={form?.email ?? ''}
                onChange={(e: any) =>
                  setFormField({
                    email: e.target.value,
                  })
                }
              />
            </FormField>
          </>
        )}
        <FormField labelTitle='Asignar sucursales'>
          <MultiSelect
            options={getBranchOptions(orgBranches)}
            value={form?.branchIds}
            onChange={(e) => setFormField({ branchIds: e.value })}
            placeholder={'Asigna sucursales'}
            type='checkbox'
          />
        </FormField>
        <div className='select-role-row'>
          {isCreatingNewRole ? (
            <FormField elementId='role-name' error={validationErrors?.roleName} labelTitle='Nombre del rol'>
              <InputText
                className={classNames({
                  'p-invalid': validationErrors?.roleName,
                })}
                id='role-name'
                name='role-name'
                placeholder={`Escribe el nombre del rol`}
                value={form?.roleName ?? ''}
                onChange={(e: any) =>
                  setFormField({
                    roleName: e.target.value,
                  })
                }
              />
            </FormField>
          ) : (
            <FormField labelTitle='Elegir rol'>
              <Dropdown
                loading={orgRolesLoading}
                options={getRoleOptions(orgRoles)}
                value={form?.roleId}
                onChange={(e) => setFormField({ roleId: e.value })}
                placeholder={'Elige un rol'}
              />
            </FormField>
          )}
          <Switch
            id='create-user'
            label='Crear rol nuevo'
            primeSwitchProps={{
              checked: isCreatingNewRole,
              onChange: (e) => {
                setIsCreatingNewRole(e.value);
              },
            }}
          />
        </div>
        {isCreatingNewRole && (
          <FeaturePicker
            onChange={(newFeatures) =>
              setFormField({
                roleFeatures: newFeatures,
              })
            }
            featureSet={form?.roleFeatures}
          />
        )}
      </form>
      <Button onClick={handleSubmit} className='pull-right pull-down' label='Guardar' disabled={!hasChanged || isSaving} />
    </>
  );
};
