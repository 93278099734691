import { ALL_FEATURES } from '../config/features';

export const hasFeaturesPermission = (
  featuresToCheck: string[],
  passedContextResult: { features?: string[]; featuresLoading?: boolean },
) => {
  const { features, featuresLoading } = passedContextResult;

  if (featuresLoading || !features) return false;
  if (features.includes(ALL_FEATURES.ORG_ADMIN) || features.includes(ALL_FEATURES.SUPER_ADMIN)) return true;

  return features.some((feature) => featuresToCheck.includes(feature));
};
