import React from 'react';
import { InputText } from 'primereact/inputtext';
import { useForm } from '../../hooks/useForm';
import { FormField } from '../FormField/FormField';
import classNames from 'classnames';
import { SignatureInput } from '../SignatureInput/SignatureInput';
import { BranchesSelect } from '../BranchesSelect/BranchesSelect';
import { Branch } from '../../types/branch';
import { SignedDocumentTemplate } from './SignedDocumentTemplate';
import { FisiofitImageConsentDocumentPayload } from '../../types/signedDocument';

export interface FisiofitImageConsentForm extends FisiofitImageConsentDocumentPayload {
  signature?: string;
  signingLocationBranch?: Branch;
}

const INITIAL_IMAGE_CONSENT_FORM: FisiofitImageConsentForm = {
  fullName: '',
  signature: undefined,
  signingLocationBranch: undefined,
};

const getFormValidators = () => ({
  fullName: (form: DeepPartial<FisiofitImageConsentForm>) => (!form.fullName ? 'El nombre es obligatorio' : undefined),
  signature: (form: DeepPartial<FisiofitImageConsentForm>) => (!form.signature ? 'La firma es obligatoria' : undefined),
  signingLocationBranch: (form: DeepPartial<FisiofitImageConsentForm>) =>
    !form.signingLocationBranch?.id ? 'Debes elegir la sucursal en la que se firma' : undefined,
});

interface Props {
  visible: boolean;
  onHide: () => void;
  onSave: (data: FisiofitImageConsentForm, signature: string, signingLocation: string) => Promise<boolean>;
}

const ImageConsentDocumentText = () => (
  <>
    <section>
      <p>
        Mediante el presente documento <strong>AUTORIZO</strong> a PABLO CASTILLO QUINTANA, a utilizar las fotografías y/o videos,
        en los que aparezca mi imagen.
      </p>
      <p>
        Mi autorización no fija ningún límite de tiempo para su concesión ni para la explotación de las fotografías y videos en
        los que aparezca, por lo que mi autorización se considera concedida por un plazo de tiempo ilimitado.
      </p>
    </section>

    <section>
      <p>Asimismo soy consciente de la siguiente información, relativa al tratamiento de mis datos personales:</p>

      <p>
        <strong>Responsable:</strong> El responsable del tratamiento de mis imágenes es PABLO CASTILLO QUINTANA, CALLE JUAN CARLOS
        I, 73, ALHAURÍN DE LA TORRE, 29130 (MALAGA).
      </p>

      <p>
        <strong>Finalidad:</strong> Mis imágenes podrán ser utilizadas para promocionar a PABLO CASTILLO QUINTANA:
      </p>
      <ul>
        <li>En la página web y/o redes sociales de la PABLO CASTILLO QUINTANA.</li>
        <li>En la documentación promocional de PABLO CASTILLO QUINTANA.</li>
      </ul>
      <p>
        <strong>Legitimación:</strong> Consentimiento del interesado.
      </p>

      <p>
        <strong>Destinatarios:</strong> Las imágenes podrán ser publicadas en la web y en las cuentas en redes sociales de PABLO
        CASTILLO QUINTANA.
      </p>

      <p>
        <strong>Derechos:</strong> Tengo derecho a retirar el consentimiento prestado en cualquier momento. Puedo ejercer los
        derechos de acceso, rectificación, supresión y portabilidad de mis imágenes, y la limitación u oposición al tratamiento de
        las mismas mediante escrito acompañado de copia de documento oficial que me identifique, dirigido al Responsable del
        tratamiento. En caso de disconformidad con el tratamiento, también tengo derecho a presentar una reclamación ante la
        Agencia Española de Protección de Datos.
      </p>

      <p>
        Más información:{' '}
        <a href='https://www.adelopd.com/privacidad/fisio-fit' target='_blank' rel='noreferrer'>
          https://www.adelopd.com/privacidad/fisio-fit
        </a>
      </p>
    </section>
  </>
);

export const FisiofitImageConsent = ({ visible, onHide, onSave }: Props) => {
  const { form, setFormFields, isSaving, setIsSaving, hasChanged, validate, validationErrors } =
    useForm<FisiofitImageConsentForm>(INITIAL_IMAGE_CONSENT_FORM, !visible, getFormValidators());

  const handleSubmit = async (): Promise<boolean> => {
    const errors = validate();
    const { signature, signingLocationBranch, ...rest } = form ?? {};
    if (!form || errors || !signature || !signingLocationBranch?.city) {
      return false;
    }
    setIsSaving(true);
    const success = await onSave(rest, signature, signingLocationBranch.city);
    setIsSaving(false);
    return success;
  };

  const handleClearSignature = () => {
    setFormFields({ signature: undefined });
  };

  const handleSignatureChange = (signatureImage: string) => {
    setFormFields({ signature: signatureImage });
  };

  return (
    <SignedDocumentTemplate
      title='Autorización de uso de imágenes'
      visible={visible}
      onHide={onHide}
      onSave={handleSubmit}
      isSaving={isSaving}
      hasChanged={hasChanged}
      documentText={<ImageConsentDocumentText />}
    >
      <h3>Datos del firmante</h3>
      <FormField labelTitle='Nombre y apellidos' elementId='fullName' required error={validationErrors?.fullName}>
        <InputText
          id='fullName'
          value={form?.fullName}
          onChange={(e) => setFormFields({ fullName: e.target.value })}
          placeholder='Nombre completo'
          className={classNames({ 'p-invalid': validationErrors?.fullName })}
        />
      </FormField>

      <FormField
        labelTitle='Ubicación de la firma'
        elementId='signing-location-select'
        required
        error={validationErrors?.signingLocationBranch}
      >
        <BranchesSelect
          id='signing-location-select'
          value={form?.signingLocationBranch?.id}
          onChange={(value) => setFormFields({ signingLocationBranch: value })}
          placeholder='Sucursal donde se firma'
          error={validationErrors?.signingLocationBranch}
          isSingle={true}
          onlyAuthUserBranches
        />
      </FormField>

      <SignatureInput
        id='signature'
        isEditing
        error={validationErrors?.signature}
        onSignatureChange={handleSignatureChange}
        onClearSignature={handleClearSignature}
        hasChanged={form.signature !== undefined}
        required={true}
        noteText='Al firmar, declaro que he sido informado y doy mi consentimiento para el uso de mi imagen por parte de la empresa.'
      />
    </SignedDocumentTemplate>
  );
};
