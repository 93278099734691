import React, { useState, useMemo, useCallback } from 'react';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { Chart } from 'primereact/chart';
import { KpiCard } from '../../components/KpiCard/KpiCard';
import { PrimeIcons } from 'primereact/api';
import './BusinessAnalytics.scss';

import { getBusinessChartOptions, pieAggregatedData } from '../../utils/chart-utils';
import { Capacitor } from '@capacitor/core';
import useSWR from 'swr';
import { BusinessMetrics } from '../../types/business-metrics';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { useClient } from '../../hooks/useClient';
import { addDays, getMonth, getYear } from 'date-fns';
import { useLocaleContext } from '../../context/LocaleContext';
import { MetricsUserTable } from '../../components/MetricsUserTable/MetricsUserTable';
import { Skeleton } from 'primereact/skeleton';
import { CardEmptyState } from './components/CardEmptyState';
import { Organization } from '../../types/organization';
import { getRequestUrl } from '../../utils/urlUtils';
import { BranchesSelect } from '../../components/BranchesSelect/BranchesSelect';
import { ReservationTypeSelect } from '../../components/ReservationTypeSelect/ReservationTypeSelect';
import { fetchWithCache } from '../../utils/cacheUtils';

// 15 minutes
const CACHE_TTL = 15 * 60 * 1000;

const lineChartOptions = getBusinessChartOptions();
const barChartOptions = getBusinessChartOptions();
const currencyChartOptions = getBusinessChartOptions({ valueSuffix: '€' });
const largeValueChartOptions = getBusinessChartOptions({ largeValueChart: true });

const getUrl = (
  organization: Organization,
  selectedDate: Date,
  branchId: string | undefined,
  reservationTypeName: string | undefined,
) => {
  return getRequestUrl({
    url: `/organizations/${organization.id}/business-analytics`,
    stringParams: {
      month: getMonth(selectedDate) + 1,
      year: getYear(selectedDate),
      reservationTypeName: reservationTypeName ?? undefined,
      branchId: branchId ?? undefined,
    },
  });
};

const checkEmptyStates = (data: BusinessMetrics | undefined) => {
  if (!data) return undefined;

  const isMostFrequentParticipantsEmpty = data?.mostFrequentParticipants.length === 0;
  const isMostAbsentParticipantsEmpty = data?.mostAbsentParticipants.length === 0;
  const isUsersWithMostCancellationsEmpty = data?.usersWithMostCancellations.length === 0;
  const isUserCancellationsByReasonEmpty = data?.userCancellationsByReason.length === 0;
  const isUserCancellationsByCanceledByEmpty = data?.userCancellationsByCanceledBy.length === 0;

  return {
    isMostFrequentParticipantsEmpty,
    isMostAbsentParticipantsEmpty,
    isUsersWithMostCancellationsEmpty,
    isUserCancellationsByReasonEmpty,
    isUserCancellationsByCanceledByEmpty,
  };
};

export const BusinessAnalytics = () => {
  const { get } = useClient();
  const { locale } = useLocaleContext();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const [selectedBranchId, setSelectedBranchId] = useState<string>();
  const [selectedReservationTypeName, setSelectedReservationTypeName] = useState<string>();

  const fetcher = useCallback((url: string): Promise<BusinessMetrics> => fetchWithCache(url, () => get(url), CACHE_TTL), [get]);

  const { data, isLoading } = useSWR(
    organization ? getUrl(organization, selectedMonth, selectedBranchId, selectedReservationTypeName) : null,
    fetcher,
  );
  const loading = organizationLoading || isLoading;

  const emptyStates = checkEmptyStates(data);
  const {
    isMostFrequentParticipantsEmpty,
    isMostAbsentParticipantsEmpty,
    isUsersWithMostCancellationsEmpty,
    isUserCancellationsByReasonEmpty,
    isUserCancellationsByCanceledByEmpty,
  } = emptyStates ?? {};

  const cancellationReasonsPieData = useMemo(
    () => (data?.userCancellationsByReason ? pieAggregatedData(data.userCancellationsByReason) : null),
    [data],
  );
  const cancellationByCanceledByPieData = useMemo(
    () => (data?.userCancellationsByCanceledBy ? pieAggregatedData(data.userCancellationsByCanceledBy) : null),
    [data],
  );

  return (
    <PageTemplate className='business-analytics-page' title='Métricas de empresa'>
      <div className='filters-container'>
        <Calendar
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.value as Date)}
          view='month'
          dateFormat='MM yy'
          showIcon
          minDate={new Date(organization?.config?.minMetricsDate ?? '2018-01-01')}
          showMinMaxRange={false}
          maxDate={addDays(new Date(), 7)}
          className='month-picker'
          locale={locale}
          touchUI={Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches}
          disabled={loading}
        />
        <BranchesSelect
          selectAllOption
          id='branch-filter'
          className='branch-filter'
          placeholder='Sucursales'
          isSingle
          onlyAuthUserBranches
          value={selectedBranchId}
          onChange={(branch) => setSelectedBranchId(branch?.id)}
        />
        <ReservationTypeSelect
          selectAllOption
          id='reservation-type-filter'
          className='reservation-type-filter'
          placeholder='Tipos de reserva'
          isSingle
          value={selectedReservationTypeName}
          onChange={(reservationTypeName) => setSelectedReservationTypeName(reservationTypeName)}
        />
      </div>

      <div className='kpi-container'>
        {!loading ? (
          <>
            <KpiCard
              title='Asistencias'
              value={data?.generalActivity.monthAttendances}
              previousPercentage={data?.generalActivity.percentageDiffAttendances}
              icon={PrimeIcons.CHECK}
              iconColor='var(--green-500)'
              iconBkgColor='var(--green-100)'
            />
            <KpiCard
              title='Cancelaciones'
              value={data?.generalActivity.monthCancellations}
              previousPercentage={data?.generalActivity.percentageDiffCancellations}
              icon={PrimeIcons.TIMES}
              iconColor='var(--pink-500)'
              iconBkgColor='var(--pink-100)'
            />
            <KpiCard
              title='Ausencias'
              value={data?.generalActivity.monthAbsences}
              previousPercentage={data?.generalActivity.percentageDiffAbsences}
              icon={PrimeIcons.USER_MINUS}
              iconColor='var(--orange-500)'
              iconBkgColor='var(--orange-100)'
            />
            <KpiCard
              title='Nivel de ocupación'
              value={`${data?.averageOccupancy ?? 0}%`}
              previousPercentage={data?.averageOccupancyDiff}
              icon={'pi pi-gauge'}
              iconColor='var(--teal-500)'
              iconBkgColor='var(--teal-100)'
              tooltip='El promedio de cuántas plazas ocupadas hay en las reservas de grupo relativo al tamaño de los grupos.'
            />
            <KpiCard
              title='Ingresos'
              value={`${data?.totalIncome ?? 0}€`}
              previousPercentage={data?.totalIncomeDiff}
              icon={'pi pi-euro'}
              iconColor='var(--indigo-500)'
              iconBkgColor='var(--indigo-100)'
              tooltip='El total de ingresos generado por las reservas con precio.'
            />

            <KpiCard
              title='Nuevos clientes'
              value={data?.totalNewUsers}
              previousPercentage={data?.totalNewUsersDiff}
              icon={PrimeIcons.USER_PLUS}
              iconColor='var(--blue-500)'
              iconBkgColor='var(--blue-100)'
            />

            <KpiCard
              title='Bajas'
              value={data?.totalDeactivations}
              previousPercentage={data?.totalDeactivationsDiff}
              icon={PrimeIcons.USER_MINUS}
              iconColor='var(--pink-500)'
              iconBkgColor='var(--pink-100)'
            />

            <KpiCard
              title='Reactivaciones'
              value={data?.totalReactivations}
              previousPercentage={data?.totalReactivationsDiff}
              icon={PrimeIcons.USER_PLUS}
              iconColor='var(--green-500)'
              iconBkgColor='var(--green-100)'
              tooltip='Clientes que estaban de baja y se han vuelto a dar de alta.'
            />
          </>
        ) : (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
      </div>

      <div className='charts-container'>
        {loading ? (
          <>
            <Skeleton className='full-width-chart' />
            <Skeleton className='full-width-chart' />
            <Skeleton className='large-chart' />
            <Skeleton className='small-chart' />
            <Skeleton className='large-chart' />
            <Skeleton className='small-chart' />
            <Skeleton className='large-chart' />
            <Skeleton className='small-chart' />
            <Skeleton />
            <Skeleton />
            <Skeleton className='full-width-chart' />
            <Skeleton />
            <Skeleton />
            <Skeleton className='full-width-chart' />
          </>
        ) : (
          <>
            <Card title='Reservas' className='chart-card full-width-chart'>
              <Chart type='bar' data={data?.totalReservationsByTypeAndDate} options={barChartOptions} />
            </Card>

            <Card title='Ingresos' className='chart-card full-width-chart'>
              <Chart type='line' data={data?.sumPricesGroupedByDay} options={currencyChartOptions} />
            </Card>

            <Card title='Asistencias' className='chart-card large-chart'>
              <Chart type='line' data={data?.generalActivity.attendanceTimeSeries} options={lineChartOptions} />
            </Card>
            <MetricsUserTable
              className='chart-card small-chart'
              title='Clientes con más asistencias'
              data={data?.mostFrequentParticipants ?? []}
              isEmpty={isMostFrequentParticipantsEmpty}
              emptyStateText='No hay asistencias'
            />
            <Card title='Ausencias' className='chart-card large-chart'>
              <Chart type='line' data={data?.generalActivity.absenceTimeSeries} options={lineChartOptions} />
            </Card>
            <MetricsUserTable
              className='chart-card small-chart'
              title='Clientes con más ausencias'
              data={data?.mostAbsentParticipants ?? []}
              isEmpty={isMostAbsentParticipantsEmpty}
              emptyStateText='No hay ausencias'
            />
            <Card title='Cancelaciones' className='chart-card large-chart'>
              <Chart type='line' data={data?.generalActivity.cancellationsTimeSeries} options={lineChartOptions} />
            </Card>
            <MetricsUserTable
              className='chart-card small-chart'
              title='Clientes con más cancelaciones'
              data={data?.usersWithMostCancellations ?? []}
              isEmpty={isUsersWithMostCancellationsEmpty}
              emptyStateText='No hay cancelaciones'
            />

            <Card title='Motivos de cancelación' className='chart-card'>
              {isUserCancellationsByReasonEmpty ? (
                <CardEmptyState text='No hay cancelaciones' />
              ) : (
                <Chart
                  type='pie'
                  data={cancellationReasonsPieData?.chartData}
                  options={cancellationReasonsPieData?.chartOptions}
                />
              )}
            </Card>

            <Card title='Origen de las cancelaciones' className='chart-card'>
              {isUserCancellationsByCanceledByEmpty ? (
                <CardEmptyState text='No hay cancelaciones' />
              ) : (
                <Chart
                  type='pie'
                  data={cancellationByCanceledByPieData?.chartData}
                  options={cancellationByCanceledByPieData?.chartOptions}
                />
              )}
            </Card>

            <Card title='Nuevos clientes' className='chart-card full-width-chart'>
              <Chart type='line' data={data?.newUsersByDate} options={lineChartOptions} />
            </Card>

            <Card title='Reactivaciones' className='chart-card'>
              <Chart type='line' data={data?.reactivationsByDate} options={lineChartOptions} />
            </Card>

            <Card title='Bajas' className='chart-card'>
              <Chart type='line' data={data?.deactivationsByDate} options={lineChartOptions} />
            </Card>

            <Card title='Clientes activos' className='chart-card full-width-chart'>
              <Chart type='line' data={data?.activeUsersByDate} options={largeValueChartOptions} />
            </Card>
          </>
        )}
      </div>
    </PageTemplate>
  );
};
