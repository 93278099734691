const css = `.user-display {
  display: flex;
  align-items: center;
  gap: 12px;
}
.user-display .p-button {
  height: 32px;
  margin: unset;
}
.user-display .p-button.p-button-icon-only {
  width: 32px;
}
.user-display.clickable {
  cursor: pointer;
}
.user-display.clickable:hover {
  text-decoration: underline;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvVXNlckRpc3BsYXkiLCJzb3VyY2VzIjpbIlVzZXJEaXNwbGF5LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUNBO0VBQ0U7O0FBSUo7RUFDRTs7QUFDQTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLnVzZXItZGlzcGxheSB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGdhcDogMTJweDtcblxuICAucC1idXR0b24ge1xuICAgIGhlaWdodDogMzJweDtcbiAgICBtYXJnaW46IHVuc2V0O1xuICAgICYucC1idXR0b24taWNvbi1vbmx5IHtcbiAgICAgIHdpZHRoOiAzMnB4O1xuICAgIH1cbiAgfVxuXG4gICYuY2xpY2thYmxlIHtcbiAgICBjdXJzb3I6IHBvaW50ZXI7XG4gICAgJjpob3ZlciB7XG4gICAgICB0ZXh0LWRlY29yYXRpb246IHVuZGVybGluZTtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
