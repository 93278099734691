/* eslint-disable max-params */
import React, { useMemo, useState } from 'react';
import { Calendar as BigCalendar, Views, SlotInfo, Event } from 'react-big-calendar';
import withDragAndDrop, { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop';

import { format, set } from 'date-fns';
import { es } from 'date-fns/locale';
import './ReservationPanel.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import { Calendar } from 'primereact/calendar';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { useForm } from '../../hooks/useForm';
import { FormField } from '../FormField/FormField';
import { classNames } from 'primereact/utils';
import { NotificationMessage, RequestErrorMessages, useClient } from '../../hooks/useClient';
import useSWR, { KeyedMutator, mutate } from 'swr';
import { InputTextarea } from 'primereact/inputtextarea';
import {
  Reservation,
  EditReservationPayload,
  CANCELED_BY_OPTIONS,
  CancellationReason,
  ExternalUserReservation,
  RESERVATION_STATUSES,
  BaseCancellation,
} from '../../types/reservation';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';
import { Panel, ResourcePanelImplProps } from '../Panel/Panel';
import {
  CancellationForm,
  getCancellationFormValidators,
  getInitialCancellationForm,
  getReservationFormValidators,
  ReservationForm,
} from './validators';
import { UserDisplay } from '../UserDisplay/UserDisplay';
import { BranchesSelect } from '../BranchesSelect/BranchesSelect';
import { UsersSelect } from '../UsersSelect/UsersSelect';
import { calendarLocalizer, calendarMessages } from '../../utils/calendarUtils';
import { useReservationTypes } from '../../hooks/AppData/useReservationTypes';
import { middleOfDay } from '../../utils/DateUtils';
import { ReservationType } from '../../types/reservationType';
import { Skeleton } from 'primereact/skeleton';
import { useAuthContext } from '../../context/AuthContext';
import { User } from '../../types/user';
import { ExternalUserField } from '../ExternalUserField/ExternalUserField';
import { Dropdown } from 'primereact/dropdown';
import { Button, ButtonProps } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { ExternalUser, ExternalUserShort } from '../../types/externalUser';
import { ExternalUsersDropdownButton } from '../ExternalUsersDropdownButton/ExternalUsersDropdownButton';
import { capitalize } from '../../utils/textUtils';
import { Switch } from '../Switch/Switch';
import { CouponWithUsers, UserCoupon } from '../../types/coupon';
import { getCouponOptions } from '../../utils/selectOptions';
import { FormFieldMessage } from '../ConditionalFieldMessage/ConditionalFieldMessage';
import { CalendarRestrictions, InitialHours } from '../../pages/Reservations/Reservations';
import { CouponDisplay } from '../CouponDisplay/CouponDisplay';
import { InputText } from 'primereact/inputtext';
import { useNavigateScroll } from '../../hooks/useNavigateScroll';
import { getFirstAvailableDateToClone, isPastReservation } from '../../utils/reservationUtils';
import { Capacitor } from '@capacitor/core';
import { AllowedFeatures } from '../../types/features';
import { hasFeaturesPermission } from '../../utils/featureUtils';
import { Checkbox } from 'primereact/checkbox';
import { Organization } from '../../types/organization';
import { PhoneInput } from '../PhoneInput/PhoneInput';
import { FormElementError } from '../FormElementError/FormElementError';

const MIN_TIMES_TO_CLONE = 1;
const MAX_TIMES_TO_CLONE = 52;

const getPanelTitle = (
  isCreating: boolean,
  isEditing: boolean,
  reservationTypeName: string | undefined,
  reservation?: Reservation,
): string => {
  if (!isCreating && isEditing && reservationTypeName) {
    const now = new Date();
    const startDate = reservation?.localStartDate ? new Date(reservation.localStartDate) : null;
    if (startDate && startDate <= now) {
      return `Asistencias de ${reservationTypeName}`;
    }
    return `Editar reserva de ${reservationTypeName}`;
  }
  if (!isCreating && !isEditing) {
    return `Detalle de reserva de ${reservationTypeName}`;
  }
  if (isCreating && !isEditing) {
    return `Crear reserva de ${reservationTypeName}`;
  }
  return `Reserva de ${reservationTypeName}`;
};

export interface ReservationEvent extends Event {
  id: string;
  title: string;
  start: Date;
  end: Date;
  isBlocked?: boolean;
}

const getToastMessages = (
  isEditing: boolean,
  isGroupReservation: boolean,
): {
  successMessage: NotificationMessage;
  errorMessages: RequestErrorMessages;
} => {
  return {
    successMessage: {
      summary: isEditing ? 'Reserva modificada' : 'Reserva creada',
      detail: isEditing ? 'La reserva se ha modificado correctamente' : 'Se ha creado una nueva reserva',
    },
    errorMessages: {
      summary: isEditing ? 'Error modificando la reserva' : 'Error al crear la reserva',
      defaultDetail: isEditing ? 'No se ha podido modificar la reserva' : 'No se ha podido crear la reserva',
      EXTERNAL_USER_HAS_CONFLICTING_RESERVATIONS: isGroupReservation
        ? 'Uno de los clientes ya tiene una reserva en este horario'
        : 'El cliente ya tiene una reserva en este horario',
      RESERVATION_IS_IN_REST_HOUR: 'El horario seleccionado está en hora de descanso. La reserva debe ser en horas válidas.',
    },
  };
};

const getInitialForm = (
  reservation?: Reservation,
  authUser?: User,
  isCreating: boolean = false,
  reservationType?: ReservationType,
  initialHours?: InitialHours,
  preselectedExternalUser?: ExternalUser,
): ReservationForm => {
  const { hasAssignedUsers } = reservationType ?? {};
  const {
    localStartDate,
    localEndDate,
    price,
    comment,
    reservationTypeId,
    user,
    externalUsers,
    confirmedExternalUsers,
    queuedExternalUsers,
    branch,
  } = reservation ?? {};
  const initialDay = initialHours?.start ? new Date(initialHours.start) : middleOfDay(new Date());

  // Inicializar attendanceData basado en confirmedExternalUsers
  const initialAttendanceData =
    confirmedExternalUsers?.reduce(
      (acc, user) => ({
        ...acc,
        [user.id]: user.attendance ?? false,
      }),
      {},
    ) ?? {};

  const preselectedUserHasCoupon =
    !!preselectedExternalUser?.userCoupon &&
    !preselectedExternalUser.userCoupon.expired &&
    preselectedExternalUser.userCoupon.usesRemaining > 0;

  return {
    localStartDate: localStartDate ?? initialHours?.start,
    localEndDate: localEndDate ?? initialHours?.end,
    price: preselectedUserHasCoupon ? 0 : price,
    comment,
    reservationTypeId,
    user: hasAssignedUsers ? (isCreating ? authUser : user) : undefined,
    branch: isCreating ? authUser?.branches?.[0] : branch,
    externalUsers: preselectedExternalUser ? [preselectedExternalUser] : externalUsers,
    confirmedExternalUsers,
    queuedExternalUsers,
    selectedDay: reservation?.localStartDate ? new Date(reservation.localStartDate) : initialDay,
    couponId: reservation?.couponId ?? preselectedExternalUser?.userCoupon?.id,
    useCoupon: !!reservation?.couponId || preselectedUserHasCoupon,
    unregisteredClientName: reservation?.unregisteredClient?.name,
    unregisteredClientSurnames: reservation?.unregisteredClient?.surnames,
    unregisteredClientPhone: reservation?.unregisteredClient?.phone,
    usersBeforeSave: [],
    attendanceData: initialAttendanceData,
  };
};

const DragAndDropCalendar = withDragAndDrop<ReservationEvent, object>(BigCalendar);

const getDayDisplay = (startDate: string | undefined) => {
  if (!startDate) return null;
  const formattedDate = format(new Date(startDate), "d 'de' MMMM 'de' yyyy", {
    locale: es,
  });
  return (
    <div className='form-field-row'>
      <i className='pi pi-calendar primary-icon' />
      <span>{formattedDate}</span>
    </div>
  );
};

const getHourDisplay = (startDate: string | undefined, endDate: string | undefined) => {
  if (!startDate || !endDate) return null;
  const formattedStartTime = format(new Date(startDate), 'HH:mm', { locale: es });
  const formattedEndTime = format(new Date(endDate), 'HH:mm', { locale: es });
  return (
    <div className='form-field-row'>
      <i className='pi pi-clock primary-icon' />
      <span>{formattedStartTime}</span>
      <i className='pi pi-arrow-right primary-icon' />
      <span>{formattedEndTime}</span>
    </div>
  );
};

const isSlotAvailable = (start: Date, end: Date, existingEvents: ReservationEvent[]): boolean => {
  // No permitir reservas de duración 0
  if (start.getTime() === end.getTime()) return false;

  // Ordenar eventos por fecha de inicio
  const sortedEvents = [...existingEvents].sort((a, b) => a.start.getTime() - b.start.getTime());

  // Comprobar si hay algún evento que se solape
  return !sortedEvents.some((event) => {
    // Redondear todos los timestamps al segundo más cercano
    const eventStart = Math.floor(event.start.getTime() / 1000) * 1000;
    const eventEnd = Math.floor(event.end.getTime() / 1000) * 1000;
    const newStart = Math.floor(start.getTime() / 1000) * 1000;
    const newEnd = Math.floor(end.getTime() / 1000) * 1000;

    // Permitir eventos que empiecen exactamente cuando termina otro
    // o que terminen exactamente cuando empieza otro
    if (newStart === eventEnd || newEnd === eventStart) {
      return false;
    }

    // Hay solapamiento si el nuevo evento empieza antes de que termine el existente
    // Y termina después de que empiece el existente
    return newStart < eventEnd && newEnd > eventStart;
  });
};

// Redondea una fecha al bloque de media hora más cercano o al inicio de hora si se requiere
const roundToNearestBlock = (date: Date, startsAtBegginingOfHour: boolean = false): Date => {
  if (startsAtBegginingOfHour) {
    // Si debe comenzar al inicio de la hora, redondear a la hora completa
    return new Date(date.setMinutes(0, 0, 0));
  }
  // De lo contrario, redondear a bloques de media hora
  const minutes = date.getMinutes();
  const roundedMinutes = minutes >= 30 ? 30 : 0;
  return new Date(date.setMinutes(roundedMinutes, 0, 0));
};

// Definir una constante para el tiempo máximo del calendario
const CLICK_EVENT_PREFERRED_DURATION = 60;

// Encuentra el espacio disponible más cercano para una reserva
const findNearestAvailableSlot = (
  clickedTime: Date,
  existingEvents: ReservationEvent[],
  maxTime?: Date,
  startsAtBegginingOfHour: boolean = false,
): { start: Date; end: Date } => {
  const sortedEvents = [...existingEvents].sort((a, b) => a.start.getTime() - b.start.getTime());
  const clickedTimeMs = clickedTime.getTime();

  // Primero intentamos crear la reserva exactamente donde el usuario ha clickado
  const roundedStart = roundToNearestBlock(clickedTime, startsAtBegginingOfHour);

  // Calcular el tiempo final teniendo en cuenta el límite máximo si existe
  let endTime = new Date(roundedStart.getTime() + CLICK_EVENT_PREFERRED_DURATION * 60 * 1000);

  // Si hay un tiempo máximo definido, verificar que no lo exceda
  if (maxTime) {
    const maxTimeForDay = new Date(roundedStart);
    maxTimeForDay.setHours(maxTime.getHours(), maxTime.getMinutes(), 0, 0);

    if (endTime > maxTimeForDay) {
      endTime = maxTimeForDay;
    }
  }

  const defaultSlot = {
    start: roundedStart,
    end: endTime,
  };

  // Si este slot está disponible, lo usamos
  if (isSlotAvailable(defaultSlot.start, defaultSlot.end, existingEvents)) {
    return defaultSlot;
  }

  // Si no está disponible, buscamos el hueco más cercano
  let prevEvent: ReservationEvent | null = null;
  let nextEvent: ReservationEvent | null = null;

  for (let i = 0; i < sortedEvents.length; i++) {
    if (sortedEvents[i].start.getTime() > clickedTimeMs) {
      nextEvent = sortedEvents[i];
      prevEvent = i > 0 ? sortedEvents[i - 1] : null;
      break;
    }
    if (i === sortedEvents.length - 1) {
      prevEvent = sortedEvents[i];
    }
  }

  // Si hay eventos antes y después, encontrar el espacio máximo disponible
  if (prevEvent && nextEvent) {
    const availableSpace = nextEvent.start.getTime() - prevEvent.end.getTime();
    const duration = Math.min(availableSpace, CLICK_EVENT_PREFERRED_DURATION * 60 * 1000);

    // Si el click está más cerca del final del evento previo, crear después de él
    const distanceToPrev = clickedTimeMs - prevEvent.end.getTime();
    const distanceToNext = nextEvent.start.getTime() - clickedTimeMs;

    if (distanceToPrev < distanceToNext) {
      const start = startsAtBegginingOfHour ? roundToNearestBlock(prevEvent.end, true) : prevEvent.end;

      return {
        start,
        end: new Date(start.getTime() + duration),
      };
    }

    const start = startsAtBegginingOfHour
      ? roundToNearestBlock(new Date(nextEvent.start.getTime() - duration), true)
      : new Date(nextEvent.start.getTime() - duration);

    return {
      start,
      end: nextEvent.start,
    };
  }

  // Si hay un evento previo pero no siguiente, intentar crear después del previo
  if (prevEvent) {
    const start = startsAtBegginingOfHour ? roundToNearestBlock(prevEvent.end, true) : prevEvent.end;

    let end = new Date(start.getTime() + CLICK_EVENT_PREFERRED_DURATION * 60 * 1000);

    // Verificar límite máximo
    if (maxTime) {
      const maxTimeForDay = new Date(start);
      maxTimeForDay.setHours(maxTime.getHours(), maxTime.getMinutes(), 0, 0);

      if (end > maxTimeForDay) {
        end = maxTimeForDay;
      }
    }

    return {
      start,
      end,
    };
  }

  // Si hay un evento siguiente pero no previo, intentar crear antes del siguiente
  if (nextEvent) {
    const start = startsAtBegginingOfHour
      ? roundToNearestBlock(new Date(nextEvent.start.getTime() - CLICK_EVENT_PREFERRED_DURATION * 60 * 1000), true)
      : new Date(nextEvent.start.getTime() - CLICK_EVENT_PREFERRED_DURATION * 60 * 1000);

    return {
      start,
      end: nextEvent.start,
    };
  }

  // Si no hay eventos cercanos, usar el slot por defecto
  return defaultSlot;
};

const getHourPickerEventTitle = (
  reservationForm: ReservationForm | undefined,
  reservationType: ReservationType | undefined,
  newEvent: boolean,
) => {
  if (newEvent) return 'Nueva reserva';
  if (!reservationForm) return 'Reserva existente'; // Fallback name
  return reservationType?.groupSize === 1
    ? `${capitalize(reservationType?.name)} - ${capitalize(reservationForm.externalUsers?.[0]?.name)} ${capitalize(reservationForm.externalUsers?.[0]?.surnames)}`
    : `${capitalize(reservationType?.name)}`;
};

const getBlockedEvents = (
  reservations: Reservation[] | undefined,
  reservationTypeFromId: (reservationTypeId: string) => ReservationType | undefined,
  currentReservationId?: string,
): ReservationEvent[] => {
  if (!reservations?.length) return [];

  return reservations
    .filter((reservation) => reservation.id !== currentReservationId)
    .map((reservation) => {
      const reservationType = reservationTypeFromId(reservation.reservationTypeId);
      const title = getHourPickerEventTitle(reservation, reservationType, false);

      // Crear nuevas fechas y asegurarnos de que los milisegundos están a 0
      const start = new Date(reservation.localStartDate);
      start.setMilliseconds(0);
      const end = new Date(reservation.localEndDate);
      end.setMilliseconds(0);

      return {
        id: reservation.id,
        title,
        start,
        end,
        isBlocked: true,
      };
    });
};

const findSelectedExternalUserCoupon = (
  allUserCoupons: UserCoupon[] | undefined,
  externalUsers: ExternalUserShort[] | undefined,
  filterUsedCoupons: boolean = false,
): UserCoupon | undefined => {
  if (!allUserCoupons || !externalUsers || !externalUsers.length) return undefined;
  const filteredCoupons = filterUsedCoupons ? allUserCoupons.filter((coupon) => coupon.usesRemaining > 0) : allUserCoupons;
  return filteredCoupons.find((coupon) => coupon?.userId && coupon.userId === externalUsers[0].id);
};

interface CancellationInfo {
  canceledBy: CANCELED_BY_OPTIONS;
  cancellationReason: CancellationReason;
  customCancellationReason?: string;
}

interface ExternalUserWithCancellation extends ExternalUserShort {
  cancellation?: CancellationInfo;
}

const mutateUserProfileData = (organization: Organization, externalUserProfileId: string) => {
  mutate(`/organizations/${organization.id}/external-users/${externalUserProfileId}`);
  mutate(`/organizations/${organization?.id}/reservations?externalUserId=${externalUserProfileId}`);
  mutate(`/organizations/${organization.id}/external-users/${externalUserProfileId}/expenses`);
};

const getCancellationPayload = (
  cancellationForm: CancellationForm,
  isCancelingGroupWithMultipleUsers: boolean = false,
): CancellationInfo => {
  const canceledByAdmin =
    isCancelingGroupWithMultipleUsers || cancellationForm.canceledBy === CANCELED_BY_OPTIONS.CANCELED_BY_ADMIN;
  return {
    canceledBy: canceledByAdmin ? CANCELED_BY_OPTIONS.CANCELED_BY_ADMIN : cancellationForm.canceledBy!,
    cancellationReason: canceledByAdmin ? CancellationReason.OTHER : cancellationForm.cancellationReason!,
    ...(cancellationForm.cancellationReason === CancellationReason.OTHER || canceledByAdmin
      ? { customCancellationReason: cancellationForm.customCancellationReason }
      : {}),
  };
};

interface ReservationWithCancellation extends Reservation, BaseCancellation {}

interface Props extends Omit<ResourcePanelImplProps<Reservation>, 'onDeleteResource'> {
  createModeReservationTypeId?: string;
  initialHours: InitialHours | undefined;
  initialTempEventOverride?: ReservationEvent;
  clearInitialTemporaryEvent: () => void;
  externalUserProfileId?: string;
  preselectedExternalUser?: ExternalUser;
  mutateReservations: KeyedMutator<Reservation[] | undefined>;
  calendarRestrictions: CalendarRestrictions;
}

export const ReservationPanel = ({
  resource,
  visible,
  onHide,
  onIsEditingChange,
  mutateReservations,
  clearInitialTemporaryEvent,
  isEditing = false,
  isDeleting = false,
  createModeReservationTypeId,
  initialHours,
  initialTempEventOverride,
  externalUserProfileId,
  preselectedExternalUser,
  calendarRestrictions,
}: Props) => {
  const navigate = useNavigateScroll();
  const { organization } = useOrganizationContext() ?? {};
  const { user: authUser } = useAuthContext() ?? {};
  const { post, patch, get, delete: deleteResource } = useClient();
  const [temporaryEvent, setTemporaryEvent] = useState<ReservationEvent | null>(null);
  const isCreating = !resource;
  const isModifying = isCreating || isEditing;
  const [showQueuedUsers, setShowQueuedUsers] = useState(false);
  const [showCancellationDialog, setShowCancellationDialog] = useState(false);
  const [showGroupCancellationDialog, setShowGroupCancellationDialog] = useState(false);
  const [userToCancel, setUserToCancel] = useState<ExternalUserShort>();
  const [canceledUsers, setCanceledUsers] = useState<ExternalUserWithCancellation[]>([]);
  const [isAttendanceMode, setIsAttendanceMode] = useState(false);

  // Estado para el modal de clonación
  const [showCloneDialog, setShowCloneDialog] = useState(false);
  const [timesToClone, setTimesToClone] = useState<number | null>(1);
  const [isCloning, setIsCloning] = useState(false);

  const showRequiredFields = isModifying && !isAttendanceMode;

  const isTouchDevice = Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches;
  const { features, featuresLoading } = useAuthContext() ?? {};

  const { reservationTypeFromId, findReservationTypeByBranchAndName } = useReservationTypes();
  const reservationType = useMemo(
    () => reservationTypeFromId((isCreating ? createModeReservationTypeId : resource?.reservationTypeId) ?? ''),
    [createModeReservationTypeId, isCreating, reservationTypeFromId, resource?.reservationTypeId],
  );
  const isGroupReservation = !!reservationType && reservationType.groupSize > 1;

  const userHasWriteFeatures = reservationType?.features.manage
    ? hasFeaturesPermission([reservationType?.features.manage], { features, featuresLoading })
    : false;

  const initialTemporaryEvent = useMemo(() => {
    if (!isEditing || !resource) return initialTempEventOverride;
    const start = new Date(resource.localStartDate);
    start.setMilliseconds(0);
    const end = new Date(resource.localEndDate);
    end.setMilliseconds(0);
    return { start, end, title: getHourPickerEventTitle(resource, reservationType, !isEditing), id: 'temporary' };
  }, [isEditing, resource, initialTempEventOverride, reservationType]);

  const initialReservationForm = useMemo(() => {
    return getInitialForm(resource, authUser, isCreating, reservationType, initialHours, preselectedExternalUser);
  }, [resource, authUser, isCreating, reservationType, initialHours, preselectedExternalUser]);
  const validators = useMemo(
    () => getReservationFormValidators(reservationType, initialTemporaryEvent),
    [reservationType, initialTemporaryEvent],
  );
  const { form, setFormFields, isSaving, setIsSaving, hasChanged, validationErrors, resetForm, validate } =
    useForm<ReservationForm>(initialReservationForm, !visible, validators);

  const hasSelectedExternalUsers =
    (form?.externalUsers && form.externalUsers.length > 0) || reservationType?.hasUnregisteredClients;

  const isCancelingGroupWithMultipleUsers =
    !!resource?.externalUsers?.length && resource?.externalUsers?.length > 1 && showGroupCancellationDialog;
  const initialCancellationForm = useMemo(
    () => getInitialCancellationForm(isCancelingGroupWithMultipleUsers),
    [isCancelingGroupWithMultipleUsers],
  );
  const cancellationFormValidators = useMemo(
    () => getCancellationFormValidators(isCancelingGroupWithMultipleUsers),
    [isCancelingGroupWithMultipleUsers],
  );
  const {
    form: cancellationForm,
    setFormFields: setCancellationFormFields,
    isSaving: isCanceling,
    setIsSaving: setIsCanceling,
    validationErrors: cancellationErrors,
    resetForm: resetCancellationForm,
    validate: validateCancellationForm,
  } = useForm<CancellationForm>(initialCancellationForm, !visible, cancellationFormValidators);

  const {
    data: coupons,
    isLoading: couponsLoading,
    mutate: mutateCoupons,
  } = useSWR(
    organization && reservationType?.canUseCoupon ? `/organizations/${organization.id}/coupons` : null,
    get<CouponWithUsers[]>,
  );
  const allUserCoupons: UserCoupon[] | undefined = useMemo(() => {
    if (!coupons) return undefined;
    return coupons.flatMap((coupon) => {
      const { couponUsers, ...rest } = coupon;
      return couponUsers.map((user) => {
        return {
          ...rest,
          ...user,
        };
      });
    });
  }, [coupons]);
  const selectedExternalUserCoupon: UserCoupon | undefined = useMemo(() => {
    const userCoupon = findSelectedExternalUserCoupon(allUserCoupons, form?.externalUsers, true);
    const isCurrentReservationCouponExpired = resource?.couponId && !userCoupon?.usedInReservations?.includes(resource?.id);
    if (
      userCoupon &&
      !isCurrentReservationCouponExpired &&
      (isCreating || (isModifying && !resource.couponId) || (!isCreating && form?.couponId === userCoupon.id))
    )
      return userCoupon;
    const reservationCoupon = coupons?.find((coupon) => coupon.id === form?.couponId);
    if (!reservationCoupon || !form?.externalUsers?.[0]?.id) return undefined;
    return {
      ...reservationCoupon,
      id: reservationCoupon.id,
      userId: form.externalUsers[0].id,
      usesRemaining: 0,
      expired: true,
      purchasedDate: '',
    };
  }, [allUserCoupons, form?.externalUsers, form?.couponId, resource?.couponId, resource?.id, isCreating, isModifying, coupons]);
  const selectedCouponHasUses = selectedExternalUserCoupon?.usesRemaining && selectedExternalUserCoupon?.usesRemaining > 0;
  const couponWasPurchased = !!resource?.couponId && !!resource.price && resource.price > 0;
  const couponIsExpired = !!isEditing && !!resource?.couponId && !!selectedExternalUserCoupon?.expired;

  const canSelectHour =
    visible && isModifying && form?.selectedDay && (!reservationType?.hasAssignedUsers || form?.user?.id) && reservationType;
  const fetchReservationsUrl = reservationType?.hasAssignedUsers
    ? `/users/${form?.user?.id}/reservations?date=${form?.selectedDay?.toISOString()}&onlyAssigned=true`
    : `/branches/${form?.branch?.id}/reservations?date=${form?.selectedDay?.toISOString()}&reservationType=${reservationType?.id}`;
  const {
    data: existingReservations,
    isLoading: existingReservationsLoading,
    mutate: mutateExistingReservations,
  } = useSWR(canSelectHour ? fetchReservationsUrl : null, get<Reservation[]>);
  const blockedEvents = useMemo(
    () => getBlockedEvents(existingReservations, reservationTypeFromId, resource?.id) ?? [],
    [existingReservations, reservationTypeFromId, resource?.id],
  );

  const invalidHourSelection = useMemo(() => {
    if (!blockedEvents.length || (!temporaryEvent && !initialTemporaryEvent)) {
      return false;
    }
    const eventToValidate = temporaryEvent || initialTemporaryEvent;
    if (!eventToValidate) return false;
    return !isSlotAvailable(eventToValidate.start, eventToValidate.end, blockedEvents);
  }, [blockedEvents, temporaryEvent, initialTemporaryEvent]);
  const scrollTime = useMemo(() => {
    const eventToScroll = temporaryEvent || initialTemporaryEvent;
    return eventToScroll?.start ?? set(new Date(), { hours: 7, minutes: 0 });
  }, [temporaryEvent, initialTemporaryEvent]);

  const canceledByOptions = [
    { label: 'Empleado', value: CANCELED_BY_OPTIONS.CANCELED_BY_ADMIN },
    { label: 'Cliente', value: CANCELED_BY_OPTIONS.CANCELED_BY_USER },
  ];

  const cancellationReasonOptions = [
    { label: 'Conflicto de horarios', value: CancellationReason.SCHEDULE_CONFLICT },
    { label: 'Problema de salud', value: CancellationReason.HEALTH_ISSUE },
    { label: 'Motivo personal', value: CancellationReason.PERSONAL_REASON },
    { label: 'Ya no necesita el servicio', value: CancellationReason.NO_LONGER_NEEDED },
    { label: 'Problema de transporte', value: CancellationReason.TRANSPORT_ISSUE },
    { label: 'Otro', value: CancellationReason.OTHER },
  ];

  const isPast = useMemo(() => (resource ? isPastReservation(resource) : false), [resource]);

  const isCanceled =
    (resource as ReservationWithCancellation)?.status === RESERVATION_STATUSES.CANCELED ||
    (resource as ReservationWithCancellation)?.externalUserStatus === RESERVATION_STATUSES.CANCELED;

  const handleUserCancellation = (user: ExternalUserShort) => {
    resetCancellationForm();
    setUserToCancel(user);
    setShowCancellationDialog(true);
  };

  const handleSave = async (): Promise<boolean> => {
    const errors = validate();
    if (!form || !organization || errors || invalidHourSelection) return false;

    setIsSaving(true);

    const {
      externalUsers,
      confirmedExternalUsers,
      branch,
      user,
      selectedDay,
      useCoupon,
      couponId,
      reservationTypeId,
      localStartDate,
      localEndDate,
      unregisteredClientName,
      unregisteredClientSurnames,
      unregisteredClientPhone,
      usersBeforeSave,
      queuedExternalUsers,
      attendanceData,
      ...rest
    } = form;

    const reservationTypeForSelectedBranch = findReservationTypeByBranchAndName(branch?.id, reservationType?.name);
    if (!reservationTypeForSelectedBranch) {
      setIsSaving(false);
      return false;
    }
    let unregisteredClient = undefined;
    if (reservationType?.hasUnregisteredClients) {
      unregisteredClient = {
        name: unregisteredClientName,
        surnames: unregisteredClientSurnames,
        phone: unregisteredClientPhone,
      };
    }

    if (isEditing && resource) {
      const externalUsersToSend = externalUsers
        ?.map((user) => {
          const newUser: ExternalUserReservation = {
            externalUserId: user.id,
          };
          if (canceledUsers.some((u) => u.id === user.id)) {
            newUser.cancellation = canceledUsers.find((u) => u.id === user.id)?.cancellation;
          }
          return newUser;
        })
        .filter((user) => !queuedExternalUsers?.some((u) => u.id === user.externalUserId && !user.cancellation));

      const editBody = {
        ...rest,
        userId: user?.id,
        couponId: useCoupon ? form.couponId : undefined,
        utcStartDate: form.localStartDate ?? initialTemporaryEvent?.start,
        utcEndDate: form.localEndDate ?? initialTemporaryEvent?.end,
        externalUsers: reservationType?.hasUnregisteredClients
          ? undefined
          : externalUsersToSend?.concat(usersBeforeSave?.map((user) => ({ externalUserId: user.id })) ?? []),
        ...(reservationType?.hasUnregisteredClients ? { unregisteredClient } : {}),
      };

      const { successMessage, errorMessages } = getToastMessages(true, isGroupReservation);
      const response = await patch<EditReservationPayload>(`/reservations/${resource.id}`, {
        body: editBody,
        successMessage,
        errorMessages,
      });

      if (response) {
        mutateCoupons(undefined);
        mutateExistingReservations(undefined);
        mutateReservations();
        if (externalUserProfileId) {
          mutateUserProfileData(organization, externalUserProfileId);
        }
        // Limpiar los usuarios cancelados después de guardar
        setCanceledUsers([]);
      }

      setIsSaving(false);
      return !!response;
    }
    const createBody = {
      ...rest,
      branchId: branch?.id,
      userId: user?.id,
      ...(isCreating ? { reservationTypeId: reservationTypeForSelectedBranch?.id } : {}),
      ...(useCoupon ? { couponId: form.couponId } : {}),
      ...(reservationType?.hasUnregisteredClients ? { unregisteredClient } : {}),
      // Parse dates from UTC because the backend expects them in local TZ
      // TODO decide if we want BE to receive UTC instead to simplify some more
      utcStartDate: form.localStartDate,
      utcEndDate: form.localEndDate,
      externalUserIds: reservationType?.hasUnregisteredClients
        ? undefined
        : [...(externalUsers?.map((user) => user.id) ?? []), ...(usersBeforeSave?.map((user) => user.id) ?? [])],
    };

    const { successMessage, errorMessages } = getToastMessages(false, isGroupReservation);
    const response = await post<Reservation>('/reservations', {
      body: createBody,
      successMessage,
      errorMessages,
    });

    if (response) {
      mutateCoupons(undefined);
      mutateExistingReservations(undefined);
      mutateReservations();
      if (externalUserProfileId) {
        mutateUserProfileData(organization, externalUserProfileId);
      }
    }

    setIsSaving(false);
    return !!response;
  };

  const handleHide = () => {
    setTemporaryEvent(null);
    setIsAttendanceMode(false);
    onHide();
  };

  const handleAttendanceSave = async (): Promise<boolean> => {
    if (!resource || !organization || !form?.attendanceData) return false;

    setIsSaving(true);
    const payload = {
      reservationId: resource.id,
      attendanceData: Object.entries(form.attendanceData).map(([externalUserId, attendance]) => ({
        externalUserId,
        attendance,
      })),
    };

    const response = await post('/reservations/register-attendance', {
      body: payload,
      successMessage: {
        summary: 'Asistencias registradas',
        detail: 'Las asistencias se han registrado correctamente',
      },
      errorMessages: {
        summary: 'Error al registrar asistencias',
        defaultDetail: 'No se han podido registrar las asistencias',
      },
    });

    if (response) {
      mutateReservations();
      onHide();
      if (externalUserProfileId && organization) {
        mutateUserProfileData(organization, externalUserProfileId);
      }
    }

    setIsSaving(false);

    return !!response;
  };

  const getGroupExternalUserButtonProps = (externalUser: ExternalUserShort, isConfirmed: boolean): ButtonProps | undefined => {
    if (!isModifying) return undefined;
    if (isPast || (resource && new Date(resource.localStartDate) <= new Date())) {
      return undefined;
    }
    return {
      icon: PrimeIcons.TRASH,
      className: 'p-button-rounded p-button-danger p-button-text',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isConfirmed) {
          handleUserCancellation(externalUser);
        } else {
          setFormFields({
            usersBeforeSave: form?.usersBeforeSave?.filter((user) => user.id !== externalUser.id) ?? [],
          });
        }
      },
    };
  };

  const renderUserAttendance = (user: ExternalUserShort) => {
    if (!isPast && !(resource && new Date(resource.localStartDate) <= new Date())) return null;
    if (!isEditing || !isAttendanceMode) {
      if (resource?.confirmedExternalUsers?.find((u) => u.id === user.id)?.attendance !== undefined) {
        return (
          <span className='user-attendance'>
            {resource?.confirmedExternalUsers?.find((u) => u.id === user.id)?.attendance ? (
              <>
                <i className='pi pi-user-plus' /> Asistió
              </>
            ) : (
              <>
                <i className='pi pi-user-minus' /> No asistió
              </>
            )}
          </span>
        );
      }
      return null;
    }

    const currentAttendance = form?.attendanceData?.[user.id] ?? false;

    return (
      <div className='attendance-checkbox'>
        <Checkbox
          checked={currentAttendance}
          onChange={(e) => {
            setFormFields({
              attendanceData: {
                ...(form?.attendanceData ?? {}),
                [user.id]: !!e.checked,
              },
            });
          }}
        />
        <span>{currentAttendance ? 'Asistió' : 'No asistió'}</span>
      </div>
    );
  };

  const handleDelete = async (): Promise<boolean> => {
    if (!resource) return false;

    if (reservationType?.hasUnregisteredClients) {
      setIsCanceling(true);

      const response = await deleteResource(`/reservations/${resource.id}`, {
        successMessage: {
          summary: 'Reserva eliminada',
          detail: 'La reserva se ha eliminado correctamente',
        },
        errorMessages: {
          summary: 'Error al cancelar la reserva',
          defaultDetail: 'No se ha podido cancelar la reserva',
        },
      });

      if (response) {
        mutateReservations();
        if (externalUserProfileId && organization) {
          mutateUserProfileData(organization, externalUserProfileId);
        }
        onHide();
      }

      setIsCanceling(false);
      return !!response;
    }

    resetCancellationForm();
    setShowGroupCancellationDialog(true);
    return false;
  };

  const handleConfirmCancellation = async () => {
    const errors = validateCancellationForm();
    if (errors) return;

    setIsCanceling(true);
    if (showGroupCancellationDialog) {
      // Cancelar toda la reserva
      const payload = { cancellation: getCancellationPayload(cancellationForm, isCancelingGroupWithMultipleUsers) };
      const response = await post(`/reservations/${resource!.id}/cancel`, {
        body: payload,
        successMessage: {
          summary: 'Reserva cancelada',
          detail: 'La reserva se ha cancelado correctamente',
        },
        errorMessages: {
          summary: 'Error al cancelar la reserva',
          defaultDetail: 'No se ha podido cancelar la reserva',
        },
      });

      if (response) {
        mutateReservations();
        if (externalUserProfileId && organization) {
          mutateUserProfileData(organization, externalUserProfileId);
        }
        onHide();
      }

      setShowGroupCancellationDialog(false);
    } else {
      // Para reservas de grupo, guardamos la información de cancelación
      if (isGroupReservation && userToCancel) {
        const cancellation = getCancellationPayload(cancellationForm);
        // Actualizar la lista de usuarios confirmados y mover el siguiente de la cola si existe
        const updatedConfirmedUsers = form?.confirmedExternalUsers?.filter((u) => u.id !== userToCancel.id) ?? [];

        // Si hay usuarios en cola, mover el primero a confirmados
        let updatedQueuedUsers = [...(form?.queuedExternalUsers ?? [])];
        if (updatedQueuedUsers.length > 0) {
          const [nextUser, ...remainingQueuedUsers] = updatedQueuedUsers;
          updatedConfirmedUsers.push(nextUser);
          updatedQueuedUsers = remainingQueuedUsers;
        }

        // Guardar el usuario cancelado con su información de cancelación
        setCanceledUsers([...canceledUsers, { ...userToCancel, cancellation }]);

        setFormFields({
          confirmedExternalUsers: updatedConfirmedUsers,
          queuedExternalUsers: updatedQueuedUsers,
        });
      } else {
        // Para reservas individuales, usar el mismo endpoint que para cancelación completa
        const payload = { cancellation: getCancellationPayload(cancellationForm) };

        const response = await post(`/reservations/${resource!.id}/cancel`, {
          body: payload,
          successMessage: {
            summary: 'Reserva cancelada',
            detail: 'La reserva se ha cancelado correctamente',
          },
          errorMessages: {
            summary: 'Error al cancelar la reserva',
            defaultDetail: 'No se ha podido cancelar la reserva',
          },
        });

        if (response) {
          mutateReservations();
          if (externalUserProfileId && organization) {
            mutateUserProfileData(organization, externalUserProfileId);
          }
          onHide();
        }
      }

      setShowCancellationDialog(false);
    }
    setIsCanceling(false);

    setUserToCancel(undefined);
  };
  const getCouponNoteText = () => {
    if (!isModifying) return;
    if (isEditing && couponWasPurchased && !selectedExternalUserCoupon?.expired) {
      return 'No es posible modificar la adquisición de un bono';
    }
    if (couponIsExpired) {
      return 'No se puede modificar el uso de un bono expirado';
    }
    if (
      !resource?.couponId &&
      form?.useCoupon &&
      form?.couponId &&
      !selectedExternalUserCoupon?.expired &&
      selectedCouponHasUses
    ) {
      return 'Se gastará un uso del bono';
    }
    if (!resource?.couponId && form?.useCoupon && form?.couponId && form.couponId !== resource?.couponId) {
      return 'Se creará un nuevo bono';
    }
    if (isEditing && !form?.useCoupon && resource?.couponId) {
      return 'Se devolverá un uso del bono';
    }
    return '';
  };

  const renderCouponNote = () => {
    const text = getCouponNoteText();
    if (!text) return null;
    return (
      <small className='note coupon-note'>
        <i className={classNames(PrimeIcons.INFO_CIRCLE, 'primary-icon')} />
        {text}
      </small>
    );
  };

  const handleAttendanceClick = async (attendanceMode = true): Promise<boolean> => {
    if (attendanceMode) {
      setIsAttendanceMode(true);
      onIsEditingChange?.(true);
      return true;
    }
    return handleDelete();
  };

  const allowedFeatures: AllowedFeatures = {
    edit: [reservationType?.features.manage as string],
    create: [reservationType?.features.manage as string],
    delete: [reservationType?.features.manage as string],
  };

  // Función para manejar la clonación de la reserva
  const handleClone = async () => {
    if (!resource || !timesToClone) return;

    setIsCloning(true);

    const payload = {
      reservationId: resource.id,
      timesToClone,
    };

    try {
      await post('/reservations/clone', {
        body: payload,
        successMessage: {
          summary: 'Reserva clonada',
          detail: `La reserva ha sido clonada correctamente para las próximas ${timesToClone} semanas`,
        },
        errorMessages: {
          summary: 'Error al clonar la reserva',
          defaultDetail: 'No se ha podido clonar la reserva',
          EXTERNAL_USER_HAS_CONFLICTING_RESERVATIONS:
            'Un usuario tiene reservas de otro tipo que entran en conflicto con una de las reservas clonadas.',
          RESERVATION_FULL: 'Una de las reservas clonadas excede el límite de asistentes.',
        },
        handlers: {
          defaultSuccess: () => {
            mutateReservations();
            if (externalUserProfileId && organization) {
              mutateUserProfileData(organization, externalUserProfileId);
            }
            setShowCloneDialog(false);
            handleHide();
            setTimesToClone(1);
          },
        },
      });
    } catch (error) {
      console.error('Error al clonar la reserva', error);
    }

    setIsCloning(false);
  };

  const startDayOfWeek = resource?.localStartDate ? format(resource?.localStartDate, 'EEEE') : null;
  const startHour = resource?.localStartDate ? format(resource?.localStartDate, 'HH:mm') : null;
  const firstAvailableDateToClone = useMemo(
    () => (resource?.localStartDate && reservationType?.isCloneable ? getFirstAvailableDateToClone(resource) : null),
    [resource, reservationType?.isCloneable],
  );

  const handleTimesToCloneChange = (e: InputNumberChangeEvent) => {
    const value = e.value;
    if (value !== null) {
      const finalValue = Math.min(Math.max(value, MIN_TIMES_TO_CLONE), MAX_TIMES_TO_CLONE);
      setTimesToClone(finalValue);
      return;
    }
    setTimesToClone(1);
  };

  return (
    <>
      <Dialog
        header='Clonar reserva'
        visible={showCloneDialog}
        onHide={() => setShowCloneDialog(false)}
        className='clone-dialog big-modal'
        draggable={false}
        resizable={false}
        dismissableMask
        footer={
          <div className='dialog-footer'>
            <Button
              label='Cancelar'
              icon={PrimeIcons.TIMES}
              onClick={() => setShowCloneDialog(false)}
              className='p-button-text'
              disabled={isCloning}
            />
            <Button
              label='Clonar reserva'
              icon={PrimeIcons.COPY}
              onClick={handleClone}
              className='p-button-primary'
              loading={isCloning}
              disabled={!timesToClone}
            />
          </div>
        }
      >
        <div className='clone-dialog-content'>
          <p className='dialog-message'>
            Se creará una copia de esta reserva cada semana, los {startDayOfWeek} a las {startHour}, empezando el{' '}
            {firstAvailableDateToClone ? format(firstAvailableDateToClone, "dd 'de' MMMM 'de' yyyy") : 'hoy'}.
          </p>
          <FormField labelTitle='Número de semanas a clonar' elementId='times-to-clone' required>
            <InputNumber
              id='times-to-clone'
              value={timesToClone}
              onChange={handleTimesToCloneChange}
              showButtons
              min={MIN_TIMES_TO_CLONE}
              max={MAX_TIMES_TO_CLONE}
              disabled={isCloning}
              allowEmpty={false}
            />
          </FormField>
        </div>
      </Dialog>

      {/* Diálogo para cancelar usuario individual (existente) */}
      <Dialog
        draggable={false}
        resizable={false}
        visible={showCancellationDialog}
        onHide={() => {
          setShowCancellationDialog(false);
          setUserToCancel(undefined);
        }}
        header={`Cancelar usuario ${userToCancel ? `(${userToCancel.name} ${userToCancel.surnames})` : ''}`}
        className='cancellation-dialog big-modal'
        footer={
          <div className='dialog-footer'>
            <Button
              label='Volver'
              icon='pi pi-times'
              onClick={() => {
                setShowCancellationDialog(false);
                setUserToCancel(undefined);
              }}
              className='p-button-text'
            />
            <Button
              label='Confirmar cancelación'
              icon='pi pi-check'
              onClick={handleConfirmCancellation}
              className='p-button-danger'
              autoFocus
              loading={isCanceling}
            />
          </div>
        }
      >
        <div className='cancellation-info warning'>
          <i className={classNames(PrimeIcons.EXCLAMATION_TRIANGLE, 'warning-icon')} />
          <p className='warning-title'>Esta acción cancelará la reserva para este usuario.</p>
          <p>Se enviará un correo electrónico al cliente notificándole de la cancelación.</p>
        </div>

        <form className='cancellation-form'>
          <FormField labelTitle='¿Quién cancela?' error={cancellationErrors?.canceledBy} required>
            <Dropdown
              value={cancellationForm.canceledBy}
              options={canceledByOptions}
              onChange={(e) => setCancellationFormFields({ canceledBy: e.value })}
              placeholder='Selecciona quién cancela'
              invalid={!!cancellationErrors?.canceledBy}
            />
          </FormField>

          <FormField
            labelTitle='Motivo de cancelación'
            error={cancellationErrors?.cancellationReason}
            required={cancellationForm.canceledBy !== CANCELED_BY_OPTIONS.CANCELED_BY_ADMIN}
          >
            {cancellationForm.canceledBy === CANCELED_BY_OPTIONS.CANCELED_BY_ADMIN ? (
              <InputTextarea
                value={cancellationForm.customCancellationReason}
                onChange={(e) => setCancellationFormFields({ customCancellationReason: e.target.value })}
                rows={2}
                autoResize
                placeholder='Escribe el motivo por el que se cancela'
              />
            ) : (
              <Dropdown
                value={cancellationForm.cancellationReason}
                options={cancellationReasonOptions}
                onChange={(e) => setCancellationFormFields({ cancellationReason: e.value })}
                placeholder='Selecciona el motivo'
                invalid={!!cancellationErrors?.cancellationReason}
              />
            )}
          </FormField>

          {cancellationForm.cancellationReason === CancellationReason.OTHER &&
            cancellationForm.canceledBy === CANCELED_BY_OPTIONS.CANCELED_BY_USER && (
              <FormField labelTitle='Especifica el motivo' error={cancellationErrors?.customCancellationReason} required>
                <InputTextarea
                  value={cancellationForm.customCancellationReason}
                  onChange={(e) => setCancellationFormFields({ customCancellationReason: e.target.value })}
                  rows={2}
                  autoResize
                  invalid={!!cancellationErrors?.customCancellationReason}
                />
              </FormField>
            )}
        </form>
      </Dialog>

      {/* Diálogo para cancelar reserva completa */}
      <Dialog
        draggable={false}
        resizable={false}
        visible={showGroupCancellationDialog}
        onHide={() => {
          setShowGroupCancellationDialog(false);
        }}
        header='Cancelar reserva'
        className='cancellation-dialog big-modal'
        footer={
          <div className='dialog-footer'>
            <Button
              label='Volver'
              icon='pi pi-times'
              onClick={() => {
                setShowGroupCancellationDialog(false);
              }}
              className='p-button-text'
              disabled={isCanceling}
            />
            <Button
              label='Confirmar cancelación'
              icon='pi pi-check'
              onClick={handleConfirmCancellation}
              className='p-button-danger'
              autoFocus
              loading={isCanceling}
            />
          </div>
        }
      >
        <div className='cancellation-info warning'>
          <i className={classNames(PrimeIcons.EXCLAMATION_TRIANGLE, 'warning-icon')} />
          {isGroupReservation ? (
            <>
              <p className='warning-title'>Esta acción cancelará la reserva para todos los usuarios del grupo.</p>
              <p>Se enviará un correo electrónico a cada participante notificándole de la cancelación.</p>
            </>
          ) : (
            <>
              <p className='warning-title'>Esta acción cancelará la reserva.</p>
              <p>Se enviará un correo electrónico al cliente notificándole de la cancelación.</p>
            </>
          )}
        </div>
        <form className='cancellation-form'>
          <FormField
            labelTitle='¿Quién cancela?'
            error={cancellationErrors?.canceledBy}
            required={resource?.externalUsers?.length === 1}
          >
            {resource?.externalUsers?.length === 1 ? (
              <Dropdown
                value={cancellationForm.canceledBy}
                options={canceledByOptions}
                onChange={(e) => setCancellationFormFields({ canceledBy: e.value })}
                placeholder='Selecciona quién cancela'
                invalid={!!cancellationErrors?.canceledBy}
              />
            ) : (
              <div className='cancellation-info'>
                <p>Cuando una reserva con más de un asistente se cancela, la cancelación recae sobre la empresa.</p>
              </div>
            )}
          </FormField>

          <FormField
            labelTitle='Motivo de cancelación'
            error={cancellationErrors?.cancellationReason}
            required={cancellationForm.canceledBy !== CANCELED_BY_OPTIONS.CANCELED_BY_ADMIN && !isCancelingGroupWithMultipleUsers}
          >
            {cancellationForm.canceledBy === CANCELED_BY_OPTIONS.CANCELED_BY_ADMIN ||
            (resource?.externalUsers?.length && resource?.externalUsers?.length > 1) ? (
              <InputTextarea
                value={cancellationForm.customCancellationReason}
                onChange={(e) => setCancellationFormFields({ customCancellationReason: e.target.value })}
                rows={2}
                autoResize
                placeholder='Escribe el motivo por el que se cancela'
              />
            ) : (
              <Dropdown
                value={cancellationForm.cancellationReason}
                options={cancellationReasonOptions}
                onChange={(e) => setCancellationFormFields({ cancellationReason: e.value })}
                placeholder='Selecciona el motivo'
                invalid={!!cancellationErrors?.cancellationReason}
              />
            )}
          </FormField>

          {cancellationForm.cancellationReason === CancellationReason.OTHER &&
            cancellationForm.canceledBy === CANCELED_BY_OPTIONS.CANCELED_BY_USER && (
              <FormField labelTitle='Especifica el motivo' error={cancellationErrors?.customCancellationReason} required>
                <InputTextarea
                  value={cancellationForm.customCancellationReason}
                  onChange={(e) => setCancellationFormFields({ customCancellationReason: e.target.value })}
                  rows={2}
                  autoResize
                  placeholder='Escribe el motivo por el que se cancela'
                  invalid={!!cancellationErrors?.customCancellationReason}
                />
              </FormField>
            )}
        </form>
      </Dialog>

      <Panel
        panelType='resourcePanel'
        title={getPanelTitle(isCreating, isEditing, reservationType?.name, resource)}
        resourceName={isAttendanceMode ? 'asistencias' : 'reserva'}
        visible={visible}
        onHide={handleHide}
        allowedFeatures={allowedFeatures}
        onDelete={
          !isCreating && !isEditing && !isCanceled
            ? (isPast || (resource && new Date(resource.localStartDate) <= new Date())) &&
              !reservationType?.hasUnregisteredClients
              ? handleAttendanceClick
              : () => handleDelete()
            : undefined
        }
        isDeleting={isDeleting}
        onSave={
          isEditing && !isCanceled && (isPast || (resource && new Date(resource.localStartDate) <= new Date()))
            ? isAttendanceMode
              ? handleAttendanceSave
              : handleSave
            : !isCanceled
              ? handleSave
              : () => Promise.resolve(false)
        }
        onIsEditingChange={(editing) => {
          if (!editing) {
            setIsAttendanceMode(false);
          }
          onIsEditingChange?.(editing);
        }}
        hasChanged={hasChanged || !!initialHours}
        isSaving={isSaving}
        isEditing={isEditing}
        isCreating={isCreating}
        resetForm={resetForm}
        className='reservation-panel'
        hasCustomDelete={!reservationType?.hasUnregisteredClients}
        customDeleteLabel='Cancelar reserva'
        customDeleteConfirmationDialogProps={{
          message: `¿Estás seguro de que quieres cancelar la reserva?`,
          header: 'Cancelar reserva',
          acceptLabel: 'Sí, cancelar',
          rejectLabel: 'Volver',
        }}
        hideEditButton={isCanceled || (isPast && !(reservationType?.groupSize === 1 && !isAttendanceMode))}
        hideDeleteButton={isPast && !reservationType?.hasUnregisteredClients}
        showAttendanceButton={reservationType?.hasAttendance && !isCanceled && isPast}
        onAttendanceClick={handleAttendanceClick}
        attendanceButtonLabel={reservationType?.groupSize === 1 ? 'Asistencia' : 'Asistencias'}
        HeaderComponent={
          !isCreating && !isEditing && !isCanceled && reservationType?.isCloneable ? (
            <div className='panel-header-with-button'>
              <h4>{getPanelTitle(isCreating, isEditing, reservationType?.name, resource)}</h4>
              <Button
                label='Clonar'
                icon={PrimeIcons.COPY}
                className='p-button-primary'
                onClick={() => setShowCloneDialog(true)}
                outlined
              />
            </div>
          ) : undefined
        }
      >
        <form
          className={classNames({
            'form-loading': isSaving,
          })}
          onSubmit={handleSave}
        >
          {isCanceled && (
            <div className='cancellation-info warning'>
              <div className='warning-title'>
                <i className={classNames(PrimeIcons.EXCLAMATION_TRIANGLE, 'warning-icon')} />
                <span>Esta reserva ha sido cancelada</span>
              </div>
              <div className='cancellation-details'>
                <p>
                  <strong>Cancelada por:</strong>
                  {resource?.canceledBy === CANCELED_BY_OPTIONS.CANCELED_BY_ADMIN ? 'Empleado' : 'Cliente'}
                </p>
                <p>
                  <strong>Motivo:</strong>
                  {cancellationReasonOptions.find((option) => option.value === resource?.canceledReason)?.label ??
                    resource?.canceledReason}
                </p>
              </div>
            </div>
          )}

          {!isCanceled &&
            reservationType?.groupSize === 1 &&
            (reservationType.hasUnregisteredClients ? (
              <div className='unregistered-client-container'>
                <FormField
                  labelTitle='Nombre'
                  elementId='unregistered-client-name'
                  error={validationErrors?.unregisteredClientName}
                  required={showRequiredFields}
                >
                  {isModifying ? (
                    <InputText
                      id='unregistered-client-name'
                      value={form?.unregisteredClientName ?? ''}
                      onChange={(e) => setFormFields({ unregisteredClientName: e.target.value })}
                      placeholder='Ej: Juan'
                      className={classNames({ 'p-invalid': validationErrors?.unregisteredClientName })}
                    />
                  ) : (
                    <p>{form?.unregisteredClientName}</p>
                  )}
                </FormField>
                <FormField
                  labelTitle='Apellidos'
                  elementId='unregistered-client-surnames'
                  error={validationErrors?.unregisteredClientSurnames}
                  required={showRequiredFields}
                >
                  {isModifying ? (
                    <InputText
                      id='unregistered-client-surnames'
                      value={form?.unregisteredClientSurnames ?? ''}
                      onChange={(e) => setFormFields({ unregisteredClientSurnames: e.target.value })}
                      placeholder='Ej: García'
                      className={classNames({ 'p-invalid': validationErrors?.unregisteredClientSurnames })}
                    />
                  ) : (
                    <p>{form?.unregisteredClientSurnames}</p>
                  )}
                </FormField>
                <FormField
                  labelTitle='Teléfono'
                  elementId='unregistered-client-phone'
                  error={validationErrors?.unregisteredClientPhone}
                >
                  {isModifying ? (
                    <PhoneInput
                      id='unregistered-client-phone'
                      invalid={!!validationErrors?.unregisteredClientPhone}
                      value={form?.unregisteredClientPhone}
                      onChange={(value) => setFormFields({ unregisteredClientPhone: value })}
                    />
                  ) : (
                    <p>{form?.unregisteredClientPhone}</p>
                  )}
                </FormField>
                {!isModifying && resource && userHasWriteFeatures && (
                  <Button
                    link
                    icon={PrimeIcons.USER_PLUS}
                    label='Crear usuario con estos datos'
                    className='create-user-button'
                    onClick={() => {
                      navigate(
                        `/users?name=${form?.unregisteredClientName}&surnames=${form?.unregisteredClientSurnames}&phone=${form?.unregisteredClientPhone}&branchId=${form?.branch?.id}`,
                      );
                    }}
                  />
                )}
              </div>
            ) : (
              <FormField
                labelTitle='Cliente'
                elementId='reservation-user'
                fullWidth
                error={validationErrors?.externalUsers}
                required={showRequiredFields}
              >
                <ExternalUserField
                  isModifying={isCreating && !preselectedExternalUser}
                  user={form?.confirmedExternalUsers?.[0] ?? form?.externalUsers?.[0]}
                  onChange={(externalUser) => {
                    const externalUserCoupon = findSelectedExternalUserCoupon(allUserCoupons, [externalUser], true);
                    setFormFields({
                      externalUsers: [externalUser],
                      useCoupon: !!externalUserCoupon,
                      couponId: externalUserCoupon?.id,
                      ...(externalUserCoupon ? { price: 0 } : {}),
                    });
                  }}
                  error={validationErrors?.externalUsers}
                  {...(!isEditing && { navigateTo: `/users/profile/${resource?.confirmedExternalUsers?.[0]?.id}` })}
                />
                {!isCreating &&
                  form?.confirmedExternalUsers?.[0] &&
                  isPast &&
                  reservationType.hasAttendance &&
                  renderUserAttendance(form.confirmedExternalUsers[0])}
              </FormField>
            ))}

          {!isCanceled && isGroupReservation && (
            <FormField
              labelTitle='Clientes'
              fullWidth
              error={validationErrors?.externalUsers}
              dontShowErrorMessage
              required={showRequiredFields}
            >
              <div
                className={classNames('group-reservation-clients', {
                  'invalid-form-field': validationErrors?.externalUsers,
                })}
              >
                {Array.from({ length: reservationType.groupSize }).map((_, index) => {
                  const users = (form?.confirmedExternalUsers ?? []).concat(form?.usersBeforeSave ?? []);
                  const user = users[index];
                  return user ? (
                    <div key={user.id} className='user-display-container'>
                      <div className='user-info'>
                        <UserDisplay
                          name={user.name}
                          surnames={user.surnames}
                          avatarUrl={user.avatarUrl}
                          bookedFromApp={user.bookedFromApp}
                          buttonProps={getGroupExternalUserButtonProps(
                            user,
                            form?.confirmedExternalUsers?.some((u) => u.id === user.id) ?? false,
                          )}
                          {...(!isEditing && { navigateTo: `/users/profile/${user.id}` })}
                        />
                      </div>
                      {renderUserAttendance(user)}
                      {form?.usersBeforeSave?.some((u) => u.id === user.id) && (
                        <span className='pending-user-note'>no guardado</span>
                      )}
                    </div>
                  ) : (
                    <UserDisplay key={index} fullName='Plaza libre' isPlaceholder />
                  );
                })}
                <FormElementError position={'absolute'}>{validationErrors?.externalUsers?.toString()}</FormElementError>
              </div>
              {isModifying &&
                !isPast &&
                (form?.confirmedExternalUsers ?? []).concat(form?.usersBeforeSave ?? []).length <
                  (reservationType?.groupSize ?? 0) && (
                  <div className='button-container'>
                    <ExternalUsersDropdownButton
                      onSelect={(user) => {
                        setFormFields({ usersBeforeSave: [...(form?.usersBeforeSave ?? []), user] });
                      }}
                      excludedUserIds={[
                        ...(form?.externalUsers ?? []),
                        ...(form?.confirmedExternalUsers ?? []),
                        ...(form?.queuedExternalUsers ?? []),
                        ...(canceledUsers ?? []),
                        ...(form?.usersBeforeSave ?? []),
                      ].map((user) => user.id)}
                    />
                  </div>
                )}
              {form?.queuedExternalUsers && form.queuedExternalUsers.length > 0 && (
                <div className='queued-users-section'>
                  <div
                    className={classNames('queued-users-header', { expanded: showQueuedUsers })}
                    onClick={() => setShowQueuedUsers(!showQueuedUsers)}
                  >
                    <i className='pi pi-chevron-right' />
                    <span>
                      Lista de espera ({form.queuedExternalUsers.length}{' '}
                      {form.queuedExternalUsers.length === 1 ? 'usuario' : 'usuarios'})
                    </span>
                  </div>
                  {showQueuedUsers && (
                    <div className='queued-users-list'>
                      {form.queuedExternalUsers.map((user) => (
                        <UserDisplay
                          key={user.id}
                          name={user.name}
                          surnames={user.surnames}
                          avatarUrl={user.avatarUrl}
                          bookedFromApp={user.bookedFromApp}
                          {...(!isEditing && { navigateTo: `/users/profile/${user.id}` })}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}
            </FormField>
          )}

          {!isCanceled &&
            (isModifying || resource.couponId) &&
            reservationType?.hasPrice &&
            reservationType?.canUseCoupon &&
            (!isAttendanceMode || !isPast) && (
              <FormField labelTitle='Bono' elementId='reservation-coupon' fullWidth error={validationErrors?.couponId}>
                {hasSelectedExternalUsers && isModifying && !couponWasPurchased && !couponIsExpired && (
                  <Switch
                    id='reservation-coupon-toggle'
                    label='Pagar sesión con bono'
                    className={classNames('reservation-coupon-toggle', {
                      inactive: !form?.useCoupon,
                    })}
                    primeSwitchProps={{
                      checked: !!form?.useCoupon,
                      onChange: (e) => {
                        const isChecked = e.value;
                        let price = undefined;
                        let newCouponId;
                        if (isEditing && !resource?.couponId) {
                          const newCoupon = findSelectedExternalUserCoupon(allUserCoupons, form?.externalUsers, true);
                          newCouponId = newCoupon?.id;
                          price = newCoupon?.price;
                        }
                        if (selectedExternalUserCoupon && isChecked) {
                          price = resource?.couponId ? resource?.price : 0;
                        }
                        if (!selectedExternalUserCoupon && isChecked && form?.couponId) {
                          price = coupons?.find((coupon) => coupon.id === form.couponId)?.price;
                        }
                        setFormFields({
                          useCoupon: isChecked,
                          price,
                          ...(newCouponId ? { couponId: newCouponId } : {}),
                        });
                      },
                      invalid: !!validationErrors?.useCoupon,
                    }}
                  />
                )}
                {hasSelectedExternalUsers &&
                  form?.useCoupon &&
                  (selectedExternalUserCoupon &&
                  ((isCreating && selectedCouponHasUses) ||
                    (!isCreating && resource?.couponId) ||
                    (isEditing && !resource?.couponId && selectedCouponHasUses)) ? (
                    <CouponDisplay coupon={selectedExternalUserCoupon} />
                  ) : (
                    <Dropdown
                      id='reservation-coupon'
                      options={getCouponOptions(coupons)}
                      value={form?.couponId}
                      onChange={(e) => {
                        const couponId = e.value;
                        const price = couponId ? coupons?.find((coupon) => coupon.id === couponId)?.price : undefined;
                        setFormFields({ couponId, price });
                      }}
                      invalid={!!validationErrors?.couponId}
                      emptyMessage='No hay bonos'
                      emptyFilterMessage='No se han encontrado bonos'
                      placeholder='Elige un bono'
                      loading={couponsLoading}
                    />
                  ))}

                {renderCouponNote()}
                {!hasSelectedExternalUsers && <FormFieldMessage message='Selecciona un cliente' />}
              </FormField>
            )}

          {!isCanceled && reservationType?.hasPrice && (
            <FormField
              labelTitle='Precio de la sesión'
              elementId='reservation-price'
              fullWidth
              error={validationErrors?.price}
              required={showRequiredFields && reservationType.priceRequired}
            >
              {isModifying && (!isAttendanceMode || !isPast) ? (
                <>
                  {hasSelectedExternalUsers && (
                    <div className='p-inputgroup'>
                      <InputNumber
                        id='reservation-price'
                        invalid={!!validationErrors?.price}
                        useGrouping={false}
                        placeholder='Ej: 40'
                        value={form?.price}
                        disabled={form?.useCoupon}
                        onChange={(e) =>
                          setFormFields({
                            price: e.value ?? undefined,
                          })
                        }
                      />
                      <span className='p-inputgroup-addon'>€</span>
                    </div>
                  )}
                  {!hasSelectedExternalUsers && <FormFieldMessage message='Selecciona un cliente' />}
                </>
              ) : (
                <div className='form-field-row'>
                  <span className='price-value'>{form?.price || form?.price === 0 ? `${form.price} €` : '-'}</span>
                  {form?.couponId && (
                    <small className='price-info note'>{resource?.price ? 'Bono adquirido' : 'Pagado con bono'}</small>
                  )}
                </div>
              )}
            </FormField>
          )}

          {reservationType?.hasComment && (
            <FormField labelTitle='Comentario' elementId='reservation-comment' fullWidth error={validationErrors?.comment}>
              {isModifying && (!isAttendanceMode || !isPast) ? (
                <InputTextarea
                  id='reservation-comment'
                  className={classNames({
                    'p-invalid': validationErrors?.comment,
                  })}
                  placeholder={`Escribe un comentario sobre la reserva`}
                  autoResize
                  value={form?.comment ?? ''}
                  onChange={(e: any) =>
                    setFormFields({
                      comment: e.target.value,
                    })
                  }
                  rows={3}
                />
              ) : (
                <p>{form?.comment || '-'}</p>
              )}
            </FormField>
          )}

          <FormField
            elementId='reservation-branch'
            labelTitle='Sucursal'
            required={showRequiredFields}
            error={validationErrors?.branch}
          >
            {isCreating ? (
              <BranchesSelect
                id='reservation-branch'
                isSingle
                onlyAuthUserBranches
                error={validationErrors?.branch}
                value={form?.branch?.id}
                onChange={(branch) => {
                  // Si hay un usuario seleccionado, verificar si pertenece a la nueva sucursal
                  const userBelongsToBranch = branch?.id && form?.user?.branchIds?.includes(branch.id);
                  if (form?.user && (!branch || !userBelongsToBranch)) {
                    setTemporaryEvent(null);
                    clearInitialTemporaryEvent();
                    setFormFields({
                      branch,
                      user: undefined,
                    });
                  } else {
                    setFormFields({ branch });
                  }
                }}
              />
            ) : (
              <p>{form?.branch?.name}</p>
            )}
          </FormField>

          {!isCanceled && reservationType?.hasAssignedUsers && (
            <FormField
              labelTitle='Empleado'
              elementId='reservation-employee'
              fullWidth
              error={validationErrors?.user}
              required={showRequiredFields}
            >
              {isModifying && !isPast ? (
                <UsersSelect
                  id='reservation-employee'
                  value={form?.user?.id}
                  isSingle
                  feature={reservationType?.features.receive}
                  onChange={(user) => {
                    setFormFields({
                      user: user,
                    });
                  }}
                  error={validationErrors?.user}
                  filterByBranchIds={form?.branch?.id ? [form?.branch?.id] : undefined}
                  disabled={!form?.branch?.id}
                />
              ) : (
                <UserDisplay name={form?.user?.name} surnames={form?.user?.surnames} avatarUrl={form?.user?.avatarUrl} />
              )}
            </FormField>
          )}

          <FormField
            labelTitle='Día'
            elementId='reservation-day'
            fullWidth
            error={validationErrors?.selectedDay}
            required={showRequiredFields}
          >
            {(isCreating || (isEditing && reservationType?.canModifyDate)) && !isPast ? (
              <Calendar
                id='reservation-day'
                value={form?.selectedDay}
                invalid={!!validationErrors?.selectedDay}
                placeholder='Selecciona fecha de la reserva'
                onChange={(e) => {
                  if (e.value) {
                    const newStartDate = form?.localStartDate
                      ? set(form.localStartDate, { date: e.value.getDate() }).toISOString()
                      : undefined;
                    const newEndDate = form?.localEndDate
                      ? set(form.localEndDate, { date: e.value.getDate() }).toISOString()
                      : undefined;
                    setFormFields({
                      selectedDay: middleOfDay(e.value),
                      localStartDate: newStartDate,
                      localEndDate: newEndDate,
                    });
                    if (newStartDate && newEndDate) {
                      setTemporaryEvent({
                        id: 'temporary',
                        title: getHourPickerEventTitle(resource, reservationType, true),
                        start: new Date(newStartDate),
                        end: new Date(newEndDate),
                      });
                      clearInitialTemporaryEvent();
                    }
                  }
                }}
                showIcon
                dateFormat='dd/mm/yy'
                minDate={new Date()}
                touchUI={Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches}
              />
            ) : (
              getDayDisplay(form?.localStartDate)
            )}
          </FormField>

          <FormField
            labelTitle='Hora'
            elementId='reservation-hours'
            fullWidth
            error={
              validationErrors?.localStartDate ||
              validationErrors?.localEndDate ||
              (invalidHourSelection ? 'La hora seleccionada no está disponible' : undefined)
            }
            required={showRequiredFields}
            className='reservation-hour-container'
          >
            {isTouchDevice && (
              <span className='reservation-hour-container-touch-hint'>Pulsa y arrastra para seleccionar la hora</span>
            )}
            {(isCreating || (isEditing && reservationType?.canModifyDate)) && !isPast ? (
              canSelectHour ? (
                <div
                  className={classNames('daily-calendar-container', { 'daily-calendar-container-touch': isTouchDevice })}
                  id='reservation-hours'
                >
                  {existingReservationsLoading ? (
                    <Skeleton height='100%' />
                  ) : (
                    <DragAndDropCalendar
                      localizer={calendarLocalizer}
                      date={form?.selectedDay}
                      scrollToTime={scrollTime}
                      onNavigate={() => {}}
                      defaultView={Views.DAY}
                      views={[Views.DAY]}
                      events={[
                        ...(blockedEvents ?? []),
                        ...(temporaryEvent ? [temporaryEvent] : []),
                        ...(initialTemporaryEvent && !temporaryEvent ? [initialTemporaryEvent] : []),
                      ]}
                      step={5}
                      timeslots={isTouchDevice ? 3 : 6}
                      min={calendarRestrictions.minTime}
                      max={calendarRestrictions.maxTime}
                      selectable
                      toolbar={false}
                      eventPropGetter={(event: ReservationEvent) => ({
                        className: classNames({
                          'blocked-event': event.isBlocked,
                          'temporary-event': event.id === 'temporary',
                        }),
                      })}
                      onSelecting={() => {
                        return !reservationType?.fixedDuration || isTouchDevice;
                      }}
                      longPressThreshold={isTouchDevice ? 100 : undefined}
                      onSelectSlot={(slotInfo: SlotInfo) => {
                        // Si es un arrastre y la duración es fija, no permitimos crear el evento
                        if (slotInfo.action === 'select' && reservationType?.fixedDuration) {
                          if (!isTouchDevice) return;
                          // Si es un click, buscamos el slot más cercano disponible
                          const slot = findNearestAvailableSlot(
                            slotInfo.start,
                            blockedEvents,
                            calendarRestrictions.maxTime,
                            reservationType?.startsAtBegginingOfHour,
                          );
                          if (!slot) return;

                          // Si la duración es fija, verificar que el slot tiene exactamente 1 hora
                          if (reservationType?.fixedDuration) {
                            const duration = (slot.end.getTime() - slot.start.getTime()) / (1000 * 60); // duración en minutos
                            if (duration < 60) return; // Si el slot es más corto de 1 hora, no permitimos crear el evento
                          }

                          const newEvent = {
                            id: 'temporary',
                            title: getHourPickerEventTitle(resource, reservationType, !isEditing),
                            start: slot.start,
                            end: slot.end,
                          };

                          if (!isSlotAvailable(newEvent.start, newEvent.end, blockedEvents)) {
                            return;
                          }

                          setTemporaryEvent(newEvent);
                          clearInitialTemporaryEvent();
                          setFormFields({
                            localStartDate: newEvent.start.toISOString(),
                            localEndDate: newEvent.end.toISOString(),
                          });
                          return;
                        }
                        // Si es un arrastre y la duración no es fija, usamos el rango seleccionado
                        if (slotInfo.action === 'select' && !reservationType?.fixedDuration) {
                          const start = new Date(slotInfo.start);
                          start.setMilliseconds(0);
                          const end = new Date(slotInfo.end);
                          end.setMilliseconds(0);

                          const newEvent = {
                            id: 'temporary',
                            title: getHourPickerEventTitle(resource, reservationType, !isEditing),
                            start,
                            end,
                          };

                          if (!isSlotAvailable(newEvent.start, newEvent.end, blockedEvents)) {
                            return;
                          }

                          setTemporaryEvent(newEvent);
                          clearInitialTemporaryEvent();
                          setFormFields({
                            localStartDate: newEvent.start.toISOString(),
                            localEndDate: newEvent.end.toISOString(),
                          });
                          return;
                        }

                        // Si es un click, buscamos el slot más cercano disponible
                        const slot = findNearestAvailableSlot(
                          slotInfo.start,
                          blockedEvents,
                          calendarRestrictions.maxTime,
                          reservationType?.startsAtBegginingOfHour,
                        );
                        if (!slot) return;

                        // Si la duración es fija, verificar que el slot tiene exactamente 1 hora
                        if (reservationType?.fixedDuration) {
                          const duration = (slot.end.getTime() - slot.start.getTime()) / (1000 * 60); // duración en minutos
                          if (duration < 60) return; // Si el slot es más corto de 1 hora, no permitimos crear el evento
                        }

                        const newEvent = {
                          id: 'temporary',
                          title: getHourPickerEventTitle(resource, reservationType, !isEditing),
                          start: slot.start,
                          end: slot.end,
                        };

                        if (!isSlotAvailable(newEvent.start, newEvent.end, blockedEvents)) {
                          return;
                        }

                        setTemporaryEvent(newEvent);
                        clearInitialTemporaryEvent();
                        setFormFields({
                          localStartDate: newEvent.start.toISOString(),
                          localEndDate: newEvent.end.toISOString(),
                        });
                      }}
                      resizable={!isTouchDevice && !reservationType?.fixedDuration}
                      onEventResize={({ event, start, end }: EventInteractionArgs<ReservationEvent>) => {
                        if (isTouchDevice || event.isBlocked || reservationType?.fixedDuration) return;

                        if (start instanceof Date && end instanceof Date) {
                          if (!isSlotAvailable(start, end, blockedEvents)) {
                            return;
                          }

                          if (event.id === 'temporary') {
                            setTemporaryEvent({
                              ...event,
                              start,
                              end,
                            });
                            clearInitialTemporaryEvent();
                          }
                          setFormFields({
                            localStartDate: start.toISOString(),
                            localEndDate: end.toISOString(),
                          });
                        }
                      }}
                      onEventDrop={({ event, start, end }: EventInteractionArgs<ReservationEvent>) => {
                        if (isTouchDevice || event.isBlocked) return;

                        if (start instanceof Date && end instanceof Date) {
                          if (!isSlotAvailable(start, end, blockedEvents)) {
                            return;
                          }

                          if (event.id === 'temporary') {
                            setTemporaryEvent({
                              ...event,
                              start,
                              end,
                            });
                            clearInitialTemporaryEvent();
                          }
                          setFormFields({
                            localStartDate: start.toISOString(),
                            localEndDate: end.toISOString(),
                          });
                        }
                      }}
                      className={classNames('daily-calendar', {
                        'p-invalid':
                          !!validationErrors?.localStartDate || !!validationErrors?.localEndDate || invalidHourSelection,
                        'invalid-selection': invalidHourSelection,
                      })}
                      messages={calendarMessages}
                    />
                  )}
                </div>
              ) : (
                <FormFieldMessage message='Elige empleado y fecha' />
              )
            ) : (
              getHourDisplay(form?.localStartDate, form?.localEndDate)
            )}
          </FormField>
        </form>
      </Panel>
    </>
  );
};
