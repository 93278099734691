const css = `.kpi-container .p-card-body {
  width: 100%;
}
.kpi-container .p-card-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}
.kpi-container .kpi-value-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.kpi-container .kpi-value-container .kpi-title-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.kpi-container span.bold {
  font-weight: 800;
  display: flex;
  gap: 8px;
}
.kpi-container div.previous {
  font-weight: 500;
  color: var(--primary400);
}
.kpi-container div.previous span:nth-child(1) {
  font-size: 10px;
  font-weight: 600;
}
.kpi-container div.previous span:nth-child(2) {
  font-size: 12px;
}
.kpi-container div.previous.increase {
  color: var(--green-500);
}
.kpi-container div.previous.decrease {
  color: var(--pink-500);
}
.kpi-container .pi {
  border-radius: 8px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvS3BpQ2FyZCIsInNvdXJjZXMiOlsiS3BpQ2FyZC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUdFO0VBQ0U7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVGO0VBQ0U7RUFDQTtFQUNBOztBQUNBO0VBQ0U7RUFDQTtFQUNBOztBQUdKO0VBQ0U7RUFDQTtFQUNBOztBQUVGO0VBQ0U7RUFFQTs7QUFFQTtFQUNFO0VBQ0E7O0FBRUY7RUFDRTs7QUFHSjtFQUNFOztBQUVGO0VBQ0U7O0FBRUY7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJy4uLy4uL3N0eWxlcy9taXhpbnMnO1xuXG4ua3BpLWNvbnRhaW5lciB7XG4gIC5wLWNhcmQtYm9keSB7XG4gICAgd2lkdGg6IDEwMCU7XG4gIH1cbiAgLnAtY2FyZC1jb250ZW50IHtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiByb3c7XG4gICAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuICAgIGFsaWduLWl0ZW1zOiBzdGFydDtcbiAgfVxuICAua3BpLXZhbHVlLWNvbnRhaW5lciB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGdhcDogMTZweDtcbiAgICAua3BpLXRpdGxlLWNvbnRhaW5lciB7XG4gICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICAgIGdhcDogOHB4O1xuICAgIH1cbiAgfVxuICBzcGFuLmJvbGQge1xuICAgIGZvbnQtd2VpZ2h0OiA4MDA7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBnYXA6IDhweDtcbiAgfVxuICBkaXYucHJldmlvdXMge1xuICAgIGZvbnQtd2VpZ2h0OiA1MDA7XG5cbiAgICBjb2xvcjogdmFyKC0tcHJpbWFyeTQwMCk7XG5cbiAgICAmIHNwYW46bnRoLWNoaWxkKDEpIHtcbiAgICAgIGZvbnQtc2l6ZTogMTBweDtcbiAgICAgIGZvbnQtd2VpZ2h0OiA2MDA7XG4gICAgfVxuICAgICYgc3BhbjpudGgtY2hpbGQoMikge1xuICAgICAgZm9udC1zaXplOiAxMnB4O1xuICAgIH1cbiAgfVxuICBkaXYucHJldmlvdXMuaW5jcmVhc2Uge1xuICAgIGNvbG9yOiB2YXIoLS1ncmVlbi01MDApO1xuICB9XG4gIGRpdi5wcmV2aW91cy5kZWNyZWFzZSB7XG4gICAgY29sb3I6IHZhcigtLXBpbmstNTAwKTtcbiAgfVxuICAucGkge1xuICAgIGJvcmRlci1yYWRpdXM6IDhweDtcbiAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
