import axios, { AxiosRequestConfig, Axios, AxiosError } from 'axios';
import { ACCESS_TOKEN_STORAGE_KEY, useAuthContext } from '../context/AuthContext';
import { HOST } from '../config/client';
import { usePopups } from '../context/PopupContext';
import storage from '../storage/storage';
import { Capacitor } from '@capacitor/core';
import { REQUEST_SOURCE_HEADER, REQUEST_SOURCES } from '../config/http';
import { ErrorCode } from '../config/errorCodes';

const AXIOS_METHODS = ['get', 'put', 'post', 'patch', 'delete'];

const isNativePlatform = Capacitor.isNativePlatform();

export interface NotificationMessage {
  summary: string;
  detail: string;
}

export type RequestErrorMessages = {
  summary: string;
  defaultDetail: string;
  [key: number]: string;
} & { [key in ErrorCode]?: string };

export interface RequestFeedbackMessages {
  successMessage: NotificationMessage;
  errorMessages: RequestErrorMessages;
}

interface ServerResponse<T> {
  data?: T;
  message?: string;
  success: boolean;
}

type StatusHandler = (...args: any) => void;
type StatusHandlers = {
  defaultError?: StatusHandler;
  defaultSuccess?: StatusHandler;
  [key: number]: StatusHandler;
} & { [key in ErrorCode]?: StatusHandler };

interface ClientRequestConfig extends AxiosRequestConfig<any> {
  body?: any;
  errorMessages?: RequestErrorMessages;
  successMessage?: NotificationMessage;
  handlers?: StatusHandlers;
}

type AxiosRequest = Axios['get'] | Axios['put'] | Axios['post'] | Axios['patch'] | Axios['delete'];
export type ClientRequest = <T>(url: string, config?: ClientRequestConfig | undefined) => Promise<T | undefined>;
type Client = {
  get: ClientRequest;
  put: ClientRequest;
  post: ClientRequest;
  patch: ClientRequest;
  delete: ClientRequest;
};

export const useClient = () => {
  const { showToast } = usePopups() ?? {};
  const { signOut } = useAuthContext() ?? {};

  const client: Client = AXIOS_METHODS.reduce((acc, method) => {
    return {
      ...acc,
      [method]: async <T,>(url: string, config?: ClientRequestConfig | undefined) => {
        const axiosMethod = axios[method as keyof Axios] as AxiosRequest;
        const { errorMessages, successMessage, handlers, body, ...restConfig } = config ?? {};

        const requestConfig = {
          ...restConfig,
          headers: {
            ...restConfig?.headers,
            Authorization: `Bearer ${await storage.getItem(ACCESS_TOKEN_STORAGE_KEY)}`,
            [REQUEST_SOURCE_HEADER]: isNativePlatform ? REQUEST_SOURCES.MOBILE_APP : '',
          },
          withCredentials: true,
        };
        const finalUrl = `${HOST}${url}`;

        try {
          const { data: serverResponse, status } =
            method === 'get' || method === 'delete'
              ? await axiosMethod<ServerResponse<T>>(finalUrl, requestConfig)
              : await axiosMethod<ServerResponse<T>>(finalUrl, body || {}, requestConfig);

          if (successMessage) {
            showToast?.({
              severity: 'success',
              summary: successMessage.summary,
              detail: successMessage.detail,
            });
          }

          if (handlers?.[status]) {
            handlers[status]();
          } else {
            handlers?.defaultSuccess?.();
          }

          return serverResponse.data;
        } catch (err) {
          if (err instanceof AxiosError && err.status) {
            console.error(err);

            if (
              (err.response?.data.message === 'Invalid token' || err.response?.status === 401) &&
              err.response?.data.message !== 'Invalid credentials'
            ) {
              await signOut?.();
              return;
            }
            console.log('errorMessages', errorMessages);
            if (errorMessages) {
              showToast?.({
                summary: errorMessages.summary,
                detail: errorMessages[err.response?.data.code] ?? errorMessages[err.status] ?? errorMessages.defaultDetail,
                severity: 'error',
              });
            }

            if (handlers?.[err.response?.data.code]) {
              handlers[err.response?.data.code]();
            } else if (handlers?.[err.status]) {
              handlers[err.status]();
            } else {
              handlers?.defaultError?.();
            }
          }
        }
      },
    };
  }, {} as Client);

  return client;
};
