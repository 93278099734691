import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState, useRef } from 'react';
import { User } from '../types/user';
import { useClient } from '../hooks/useClient';
import useSWR from 'swr';
import { Role } from '../types/role';
import { Feature } from '../config/features';
import storage from '../storage/storage';
import { notificationService } from '../services/NotificationService';
import { FORCED_LOGOUT_ROUTES } from '../config/pages';

export const ACCESS_TOKEN_STORAGE_KEY = 'accessToken';
export const USER_ID_STORAGE_KEY = 'userId';

interface LoginResponse {
  name: string;
  avatarUrl?: string;
  id: string;
  token: string;
  roles: string[];
}

interface AuthContextValue {
  signOut: () => Promise<void>;
  removeAuthTokens: () => Promise<void>;
  user: User | undefined;
  userLoading: boolean;
  login: (email: string, password: string, pushNotificationToken?: string | null) => Promise<void>;
  updateUser: (user: Partial<User>, avatarFile?: File) => Promise<User | undefined>;
  isExpectedLoggedIn: boolean;
  features: Feature[];
  featuresLoading: boolean;
  isAuthenticating: boolean;
  setUser: (newUser: User | undefined) => void;
}

const AuthContext = createContext<AuthContextValue | null>(null);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [accessTokenLoading, setAccessTokenLoading] = useState<boolean>(true);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);
  const initialPath = useRef(window.location.pathname);
  const { get, post, patch } = useClient();

  const signOut = useCallback(async () => {
    setIsAuthenticating(true);
    try {
      await storage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
      await storage.removeItem(USER_ID_STORAGE_KEY);
      setSessionId(null);
      setAccessToken(null);
      window.location.assign('/');
    } catch {
    } finally {
      setIsAuthenticating(false);
    }
  }, []);

  const removeAuthTokens = useCallback(async () => {
    try {
      await storage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
      await storage.removeItem(USER_ID_STORAGE_KEY);
      setSessionId(null);
      setAccessToken(null);
    } catch (error) {
      console.error('Error removing auth tokens:', error);
    }
  }, []);

  const fetchUser = async ({ sessionIdForUser }: { sessionIdForUser: string }): Promise<User | undefined> => {
    try {
      const user = await get<User>(`/users/${sessionIdForUser}`);
      if (!user) {
        await signOut();
        return undefined;
      }
      return user;
    } catch (error) {
      await signOut();
      return undefined;
    }
  };

  const {
    data: user,
    isLoading: userLoading,
    error: userError,
    mutate: mutateUser,
  } = useSWR(sessionId ? { sessionIdForUser: sessionId } : null, fetchUser);

  const setUser = useCallback(
    (newUser: User | undefined) => {
      mutateUser(newUser, false);
    },
    [mutateUser],
  );

  useEffect(() => {
    if (!accessTokenLoading && !userLoading) {
      setIsAuthenticating(false);
    }
  }, [accessTokenLoading, userLoading]);

  const initializeAuth = async () => {
    try {
      const isForcedLogoutRoute = FORCED_LOGOUT_ROUTES.some((route) => {
        const regex = new RegExp(`^${route.replace(/:\w+/g, '[\\w.-]+')}$`);
        return regex.test(initialPath.current);
      });

      if (isForcedLogoutRoute) {
        await removeAuthTokens();
      }

      const [storedSession, storedToken] = await Promise.all([
        storage.getItem(USER_ID_STORAGE_KEY),
        storage.getItem(ACCESS_TOKEN_STORAGE_KEY),
      ]);

      setSessionId(storedSession);
      setAccessToken(storedToken);
    } catch (error) {
      console.error('Error initializing auth:', error);
      await signOut();
    } finally {
      setAccessTokenLoading(false);
    }
  };

  useEffect(() => {
    initializeAuth();
  }, []);

  const updatePushToken = useCallback(async () => {
    if (!user) return;
    notificationService.init(user.id);
    const token = await notificationService.initialize();
    if (!token) return;
    console.log('Updating user push token: ', token);
    await post('/users/update-push-token', {
      body: {
        token,
      },
    });
  }, [post, user]);

  useEffect(() => {
    updatePushToken();
  }, [updatePushToken]);

  const fetchFeatures = async (url: string): Promise<Feature[] | undefined> => {
    const userRole = await get<Role>(url);
    return userRole?.features;
  };

  const { data: features = [], isLoading: featuresLoading } = useSWR(
    sessionId ? `/users/${sessionId}/role` : null,
    fetchFeatures,
  );

  const login = async (email: string, password: string) => {
    const loginResponse = await post<LoginResponse>('/login', {
      body: {
        email,
        password,
      },
      errorMessages: {
        summary: 'Error en el inicio de sesión',
        defaultDetail: 'No se ha podido iniciar la sesión',
        401: 'Los datos introducidos no son válidos',
      },
    });

    const { id, token } = loginResponse ?? {};
    if (id && token) {
      await storage.setItem(USER_ID_STORAGE_KEY, id);
      await storage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
      setSessionId(id);
    }
  };

  const updateUser = async (userData: Partial<User>, avatarFile?: File) => {
    const newUser: Partial<User> = { ...userData };
    delete newUser.id;
    const formData = new FormData();

    const { dateOfBirth, phone, ...rest } = newUser;

    formData.append(
      'data',
      JSON.stringify({
        ...rest,
        ...(dateOfBirth ? { dateOfBirth } : { dateOfBirth: null }),
        ...(phone ? { phone } : { phone: null }),
      }),
    );
    if (avatarFile) {
      formData.append('image', avatarFile);
    }

    const updatedUser = await patch<User>(`/users/${userData?.id}`, {
      body: formData,
      errorMessages: {
        summary: 'Error al editar perfil',
        defaultDetail: 'No se ha podido editar el perfil de usuario',
      },
      successMessage: {
        summary: 'Perfil editado',
        detail: 'Se ha editado correctamente el perfil de usuario',
      },
    });

    if (updatedUser) {
      await storage.setItem(USER_ID_STORAGE_KEY, updatedUser.id);
      setUser(updatedUser);
      setSessionId(updatedUser.id);
      return updatedUser;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signOut,
        user,
        userLoading,
        login,
        updateUser,
        isExpectedLoggedIn: !!accessToken && !accessTokenLoading,
        features,
        featuresLoading,
        isAuthenticating,
        removeAuthTokens,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
