import React from 'react';
import { EncodedImage } from '../../types/filedata';
import {
  FileUploadHandlerEvent,
  FileUploadProps,
  FileUpload as PrimeFileUpload,
} from 'primereact/fileupload';
import { classNames } from 'primereact/utils';

interface Props extends FileUploadProps {
  value?: string;
  onImageUpload?: (imageUrl: EncodedImage, file: File) => void;
  invalid?: boolean;
}

export const FileUpload = ({
  value,
  onImageUpload,
  invalid = false,
  className,
  ...props
}: Props) => {
  const handleImageChange = (e: FileUploadHandlerEvent) => {
    const file = e.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImage = { ...file, preview: reader.result as EncodedImage };
        onImageUpload?.(newImage.preview, file);
        e.options.clear();
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <PrimeFileUpload
        customUpload
        auto
        uploadHandler={handleImageChange}
        className={classNames(className, {
          'p-invalid': invalid,
        })}
        {...props}
      />
    </>
  );
};
