import React from 'react';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { Skeleton } from 'primereact/skeleton';
import { TableColumn } from '../../types/responsiveTable';
import { NumericFilter } from '../NumericFilter/NumericFilter';
import { Calendar } from 'primereact/calendar';

interface TableFilterProps<T extends Record<string, any>> {
  columns: TableColumn<T>[];
  filters: Record<string, any>;
  data: any[];
  loading?: boolean;
  onFilterChange: (
    key: string,
    values: any[],
    isSliderFilters?: boolean
  ) => void;
  isSlider?: boolean;
  showFilters: boolean;
}

export const TableFilter = <T extends Record<string, any>>({
  columns: headers,
  filters,
  data,
  loading,
  onFilterChange,
  isSlider = false,
  showFilters,
}: TableFilterProps<T>) => {
  const handleOnFilterChange = (column: TableColumn<T>, value: any, index: number) => {
    let key: string;
    let values: any[];
    switch (column.type) {
      case 'calendar':
      case 'dropdown':
      case 'tag':
        key = `${column.accessor}-${index}`;
        values = value;
        break;
      default:
        key = `${column.accessor}-${index}`;
        values = [value];
    }

    onFilterChange(key, values, isSlider);
  };

  const getDropDownOptions = (column: TableColumn<T>) => {
    const dropdownOptions: string[] = [];
    data.forEach((row) => {
      const value =
        'valueFromCallBack' in column
          ? column.valueFromCallBack?.(row[column.accessor], row) ?? ['-']
          : row[column.accessor] ?? ['-'];
      if (Array.isArray(value)) {
        value.forEach((v) => dropdownOptions.push(v));
      } else {
        dropdownOptions.push(value);
      }
    });
    return [...new Set(dropdownOptions)];
  };

  const filtersConfig = (column: TableColumn<T>, index: number) => {
    return (
      <>
        {column.type === 'string' && (
          <InputText
            value={filters[`${column.accessor}-${index}`] || ''}
            onChange={(e) => handleOnFilterChange(column, e.target.value, index)}
          />
        )}
        {column.type === 'tag' && (
          <MultiSelect
            value={filters[`${column.accessor}-${index}`] || []}
            options={[
              ...new Set(
                data.map((row) =>
                  column.textKeyFromCallBack
                    ? column.textKeyFromCallBack(row[column.accessor]) ?? '-'
                    : row[column.accessor] ?? '-'
                )
              ),
            ]}
            itemTemplate={(option) =>
              option === '-' ? (
                '-'
              ) : (
                <Tag
                  value={option}
                  className={column.colorFromCallBack(option)}
                ></Tag>
              )
            }
            onChange={(e) => handleOnFilterChange(column, e.value, index)}
          />
        )}
        {column.type === 'dropdown' && (
          <MultiSelect
            value={filters[`${column.accessor}-${index}`] || []}
            options={getDropDownOptions(column)}
            itemTemplate={(option) => (option[0] === '-' ? '-' : option.toString())}
            onChange={(e) => handleOnFilterChange(column, e.value, index)}
          />
        )}
        {column.type === 'number' && (
          <NumericFilter
            value={filters[`${column.accessor}-${index}`] || ''}
            onChange={(value) => handleOnFilterChange(column, value, index)}
          ></NumericFilter>
        )}
        {column.type === 'calendar' && (
          <Calendar
            value={filters[`${column.accessor}-${index}`] || ''}
            onChange={(e) =>
              e.value &&
              e.value.length === 2 &&
              handleOnFilterChange(column, e.value, index)
            }
            selectionMode='range'
            readOnlyInput
          />
        )}
      </>
    );
  };

  const getFilters = (header: TableColumn<T>, index: number) => (
    <div className='table-header' key={header.label}>
      {header.label}
      {showFilters && filtersConfig(header, index)}
    </div>
  );

  return loading
    ? Array.from({ length: 4 }).map((_, colIndex) => (
        <th key={colIndex}>
          <Skeleton width='70%' height='1.5rem' />
        </th>
      ))
    : headers.map((header, index) =>
        isSlider ? (
          getFilters(header, index)
        ) : (
          <th className={header.type} key={header.label}>
            {getFilters(header, index)}
          </th>
        )
      );
};
