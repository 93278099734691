import React, { useMemo, useState } from 'react';
import './EmployeesList.scss';

import { useClient } from '../../../hooks/useClient';
import { User } from '../../../types/user';
import { useOrganizationContext } from '../../../context/OrganizationContext';
import useSWR from 'swr';
import { Button } from 'primereact/button';
import { UserPanel } from '../../../components/UserPanel/UserPanel';
import { ResponsiveTable } from '../../../components/ResponsiveTable/ResponsiveTable';
import { TableColumn, TableAction } from '../../../types/responsiveTable';
import { useNavigate } from 'react-router-dom';
import { PrimeIcons } from 'primereact/api';
import { ItemDisplayDto } from '../../../types/responses';
import { useResourcePanel } from '../../../hooks/useResourcePanel';

const getTableColumns = (): TableColumn<User>[] => [
  {
    label: '',
    accessor: 'avatarUrl',
    type: 'avatar',
  },
  {
    label: 'Nombre',
    accessor: 'name',
    type: 'string',
    isTitle: true,
    valueFromCallBack: (name, row) =>
      `${name} ${row.surnames}${row.isInvitation ? ' (pendiente)' : ''}`,
  },
  {
    label: 'Rol',
    accessor: 'role',
    valueFromCallBack: (role) => [role.name],
    type: 'dropdown',
    hideBulletPoints: true,
  },
  {
    label: 'Sucursales',
    accessor: 'branches',
    type: 'dropdown',
    valueFromCallBack: (branches: ItemDisplayDto[]) =>
      branches?.map((branch) => branch?.name) || '',
  },
];

export const EmployeesList = () => {
  const { get } = useClient();
  const navigate = useNavigate();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};

  const { data, isLoading, error } = useSWR(
    organization ? `/organizations/${organization.id}/users` : null,
    get<User[]>
  );
  const {
    isEditing,
    setIsEditing,
    panelVisible,
    setPanelVisible,
    selectedResource,
    openPanel,
  } = useResourcePanel(data);

  const tableHeaders: TableColumn<User>[] = useMemo(
    () => getTableColumns(),
    []
  );

  const options: TableAction[] = [
    {
      label: 'Ver',
      onClick: (id) => {
        openPanel(false, id);
      },
    },
    {
      label: 'Editar',
      onClick: (id) => {
        openPanel(true, id);
      },
    },
    {
      label: 'Eliminar',
    },
  ];

  return (
    <>
      <ResponsiveTable
        data={data || []}
        columns={tableHeaders}
        options={options}
        loading={organizationLoading || isLoading}
        headerOptions={
          <>
            <Button
              label='Añadir empleado'
              outlined
              rounded
              icon={PrimeIcons.PLUS}
              onClick={() => {
                openPanel(false);
              }}
            />
            <Button
              label='Ver roles'
              outlined
              text
              size='large'
              onClick={() => {
                navigate('/employees/roles');
              }}
            />
          </>
        }
        onRowClick={(row) => {
          openPanel(false, row.id);
        }}
      ></ResponsiveTable>
      <UserPanel
        user={selectedResource}
        visible={panelVisible}
        onHide={() => {
          setPanelVisible(false);
        }}
        onIsEditingChange={(value) => setIsEditing(value)}
        isEditing={isEditing}
      />
    </>
  );
};
