const css = `.reservation-panel .reservation-user-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-wrap: auto;
  width: 100%;
  gap: 16px;
}

.reservation-flex-row {
  display: flex;
  align-items: center;
  text-wrap: auto;
  gap: 8px;
}
.reservation-flex-row .p-button-danger {
  border-color: transparent;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvUmVzZXJ2YXRpb25QYW5lbCIsInNvdXJjZXMiOlsiUmVzZXJ2YXRpb25QYW5lbC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVJO0VBQ0k7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUlSO0VBQ0k7RUFDQTtFQUNBO0VBQ0E7O0FBQ0E7RUFDSSIsInNvdXJjZXNDb250ZW50IjpbIi5yZXNlcnZhdGlvbi1wYW5lbCB7XG5cbiAgICAucmVzZXJ2YXRpb24tdXNlci1jb250YWluZXIge1xuICAgICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgICAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgICAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgICAgICAgdGV4dC13cmFwOiBhdXRvO1xuICAgICAgICB3aWR0aDogMTAwJTtcbiAgICAgICAgZ2FwOiAxNnB4O1xuICAgIH1cbn1cblxuLnJlc2VydmF0aW9uLWZsZXgtcm93IHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgdGV4dC13cmFwOiBhdXRvO1xuICAgIGdhcDogOHB4O1xuICAgIC5wLWJ1dHRvbi1kYW5nZXIge1xuICAgICAgICBib3JkZXItY29sb3I6IHRyYW5zcGFyZW50O1xuICAgIH1cbn0iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
