import { PrimeIcons } from 'primereact/api';
import { Reservation, RESERVATION_STATUSES } from '../types/reservation';
import { USER_ROUTINE_EVENTS } from '../types/externalUser';
import { SummaryItem } from '../components/CalendarToolbar/CalendarToolbar';
import { PaymentType } from '../components/ExpenseCalendarEvent/ExpenseCalendarEvent';

interface RoutineEvent {
  eventType: USER_ROUTINE_EVENTS;
}

interface ExpenseEventDto {
  id: string;
  title: string;
  start: string;
  amount: number;
  paymentType: PaymentType;
  reservationId: string;
  employee?: {
    id: string;
    name: string;
    surnames: string;
  };
}

interface ExpenseSummaryTotals {
  totalAmount: number;
  totalCashAmount: number;
  totalBonusAmount: number;
}

export const createReservationSummaryItems = (events: Reservation[]): SummaryItem[] => {
  const now = new Date();
  const canceledReservations = events.filter((event) => event.externalUserStatus === RESERVATION_STATUSES.CANCELED).length;
  const activeEvents = events.filter((event) => event.externalUserStatus !== RESERVATION_STATUSES.CANCELED);
  const upcomingReservations = activeEvents.filter((event) => new Date(event.localStartDate) > now).length;
  const pastReservations = activeEvents.filter((event) => new Date(event.localStartDate) <= now).length;
  const totalReservations = events.length;

  return [
    {
      icon: PrimeIcons.CALENDAR,
      label: 'Total',
      value: totalReservations,
      highlight: true,
    },
    {
      icon: PrimeIcons.CALENDAR_PLUS,
      label: 'Próximas',
      value: upcomingReservations,
    },
    {
      icon: PrimeIcons.CALENDAR_MINUS,
      label: 'Pasadas',
      value: pastReservations,
    },
    {
      icon: PrimeIcons.TIMES_CIRCLE,
      label: 'Canceladas',
      value: canceledReservations,
    },
  ];
};

export const createRoutineSummaryItems = (events: RoutineEvent[]): SummaryItem[] => {
  const totalRoutines = events.length;

  return [
    {
      icon: PrimeIcons.LIST,
      label: 'Total',
      value: totalRoutines,
      highlight: true,
    },
  ];
};

export const createExpenseSummaryItems = (events: ExpenseEventDto[], totals: ExpenseSummaryTotals): SummaryItem[] => {
  return [
    {
      icon: PrimeIcons.EURO,
      label: 'Total',
      value: totals.totalAmount,
      suffix: '€',
      highlight: true,
    },
    {
      icon: PrimeIcons.MONEY_BILL,
      label: 'Directo',
      value: totals.totalCashAmount,
      suffix: '€',
    },
    {
      icon: PrimeIcons.TICKET,
      label: 'Bonos',
      value: totals.totalBonusAmount,
      suffix: '€',
    },
  ];
};
