import { PrimeIcons } from 'primereact/api';
import React, { useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import './InfoIcon.scss';
import classNames from 'classnames';

interface Props {
  content: string;
  icon?: typeof PrimeIcons.QUESTION_CIRCLE | typeof PrimeIcons.INFO_CIRCLE;
}

export const InfoIcon = ({ content, icon = PrimeIcons.QUESTION_CIRCLE }: Props) => {
  const tooltipId = useMemo(() => `tooltip-${Math.random().toString(36).substring(2, 16)}`, []);

  return (
    <>
      <i id={tooltipId} className={classNames('info-icon', icon)} />
      <Tooltip anchorSelect={`#${tooltipId}`} content={content} />
    </>
  );
};
