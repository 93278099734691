import { PrimeIcons } from 'primereact/api';
import { MultiStateCheckbox } from 'primereact/multistatecheckbox';
import React, { useEffect, useState } from 'react';
import './NumericFilter.scss';
import { InputNumber } from 'primereact/inputnumber';

interface NumericFilterValue {
  mode: string;
  value: number | null;
}

const options = [
  { value: 'equal', icon: 'pi pi-equals' },
  { value: 'greater-than', icon: PrimeIcons.CHEVRON_RIGHT },
  { value: 'less-than', icon: PrimeIcons.CHEVRON_LEFT },
];

interface Props {
  onChange?: (value: NumericFilterValue) => void;
  value: NumericFilterValue[] | undefined;
  placeholder?: string;
  isCurrency?: boolean;
  currencyLocale?: string;
  currencyCode?: string;
}

export const NumericFilter = ({
  onChange,
  value,
  placeholder,
  isCurrency = false,
  currencyLocale = 'es-ES',
  currencyCode = 'EUR',
}: Props) => {
  const { mode = 'equal', value: filterValue = null } = value?.[0] ?? {};
  const [localValue, setLocalValue] = useState<number | null>(filterValue);
  const [localMode, setLocalMode] = useState<string>(mode);

  // Sincronizamos el valor local con el valor del prop
  useEffect(() => {
    setLocalValue(filterValue);
    setLocalMode(mode);
  }, [filterValue, mode]);

  const handleOnChange = (newMode: string, newValue: number | null) => {
    // Actualizamos el estado local
    setLocalMode(newMode);
    setLocalValue(newValue);

    // Para depuración
    console.log('Valor del filtro cambiado:', { newMode, newValue });

    // Notificamos el cambio
    onChange?.({ mode: newMode, value: newValue });
  };

  return (
    <div className='numeric-filter-container'>
      <MultiStateCheckbox
        value={localMode}
        onChange={(e) => handleOnChange(e.value, localValue)}
        options={options}
        empty={false}
        optionValue='value'
      />
      <InputNumber
        value={localValue}
        useGrouping={true}
        onChange={(e) => handleOnChange(localMode, e.value)}
        placeholder={placeholder}
        mode={isCurrency ? 'currency' : 'decimal'}
        currency={isCurrency ? currencyCode : undefined}
        locale={isCurrency ? currencyLocale : undefined}
        minFractionDigits={isCurrency ? 2 : 0}
        onBlur={() => {
          // Aseguramos que el valor se aplique correctamente al perder el foco
          if (localValue !== null) {
            handleOnChange(localMode, localValue);
          }
        }}
      />
    </div>
  );
};
