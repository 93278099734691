import React, { useRef } from 'react';
import { classNames } from 'primereact/utils';
import { processFileToDataUrl, uploadNativeImage } from '../../utils/imageUtils';
import { Button } from 'primereact/button';
import { Capacitor } from '@capacitor/core';

interface Props {
  fileName: string;
  accept: string;
  label?: string;
  onFileUpload?: (fileUrl: string, file: File) => void;
  invalid?: boolean;
  className?: string;
  disabled?: boolean;
}

export const FileUploadButton = ({ onFileUpload, invalid = false, className, fileName, accept, label, disabled }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const processFileUpload = async (file: File) => {
    processFileToDataUrl(file, (result) => {
      const newImage = { ...file, preview: result };
      onFileUpload?.(newImage.preview, file);
    });
  };

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0];
    if (!file) return;
    await processFileUpload(file);
  };

  const handleButtonClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (Capacitor.isNativePlatform()) {
      const file = await uploadNativeImage(fileName ?? '');
      if (file) await processFileUpload(file);
    } else if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className='file-upload-button'>
      <Button
        {...(invalid && { severity: 'danger' })}
        label={label ?? 'Subir archivo'}
        className={classNames(className)}
        onClick={handleButtonClick}
        disabled={disabled}
        outlined
      />
      <input
        ref={fileInputRef}
        type='file'
        accept={accept}
        style={{ display: 'none' }}
        onChange={handleFileUpload}
        disabled={disabled}
      />
    </div>
  );
};
