import { BranchDisplay } from './branch';
import { ExternalUserShort } from './externalUser';
import { UserShort } from './user';

export interface ReservationUnregisteredClient {
  name: string;
  surnames: string;
  phone?: string;
}

export enum RESERVATION_STATUSES {
  CONFIRMED = 'confirmed',
  IN_QUEUE = 'in_queue',
  CANCELED = 'canceled',
}

export enum CANCELED_BY_OPTIONS {
  CANCELED_BY_USER = 'user',
  CANCELED_BY_ADMIN = 'admin',
}

export enum CancellationReason {
  SCHEDULE_CONFLICT = 'schedule_conflict', // Conflict of schedules
  HEALTH_ISSUE = 'health_issue', // Health-related problem
  PERSONAL_REASON = 'personal_reason', // Personal matters
  NO_LONGER_NEEDED = 'no_longer_needed', // Service is no longer required
  TRANSPORT_ISSUE = 'transport_issue', // Problems with transportation
  OTHER = 'other', // Custom reason provided by user
}

export interface BaseCancellation {
  canceledBy?: CANCELED_BY_OPTIONS;
  cancellationReason: CancellationReason;
  customCancellationReason?: string;
}

export interface MandatoryCancellationBy extends BaseCancellation {
  canceledBy: CANCELED_BY_OPTIONS;
}

export interface ExternalUserReservation {
  externalUserId: string;
  cancellation?: MandatoryCancellationBy;
}

export interface ReservationBranch {
  id: string;
  name: string;
}

export interface Reservation {
  id: string;
  localStartDate: string;
  localEndDate: string;

  price?: number;
  comment?: string;
  rating?: number;

  unregisteredClient?: ReservationUnregisteredClient;

  reservationTypeId: string;
  user: UserShort;
  externalUsers?: ExternalUserShort[];
  confirmedExternalUsers?: ExternalUserShort[];
  queuedExternalUsers?: ExternalUserShort[];
  branch: BranchDisplay;
  couponId?: string;
  externalUserStatus?: RESERVATION_STATUSES;
  status?: RESERVATION_STATUSES;
  canceledBy?: CANCELED_BY_OPTIONS;
  canceledReason?: CancellationReason | string;
}

export interface EditReservationPayload
  extends Omit<Reservation, 'externalUsers' | 'confirmedExternalUsers' | 'queuedExternalUsers'> {
  externalUsers: ExternalUserReservation[];
}
