import { Image } from 'primereact/image';
import React from 'react';

import './LoadingScreen.scss';

export const LoadingScreen = () => {
  return (
    <div className='loading-screen'>
      <Image
        src='https://no-gravity.s3.eu-north-1.amazonaws.com/Dashboard/no-gravity-logo-black.webp'
        alt='No Gravity logo'
        className='loading-logo'
      />
    </div>
  );
};
