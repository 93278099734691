import React, { useMemo } from 'react';
import { ExternalUserShort } from '../../types/externalUser';
import useSWR from 'swr';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { getHashMap } from '../../utils/objectTransformers';
import { ValidationErrors } from '../../hooks/useForm';
import { UserDisplay } from '../UserDisplay/UserDisplay';
import { AdaptiveSelect } from '../AdaptiveSelect/AdaptiveSelect';
import { getRequestUrl } from '../../utils/urlUtils';
import { classNames } from 'primereact/utils';
import { trim } from '../../utils/textUtils';

const getExternalUsersUrl = (
  organizationId: string,
  params: { short?: boolean; forRoutine?: boolean; couponData?: boolean; onlyWithEmail?: boolean; onlyAppUsers?: boolean },
) => {
  return getRequestUrl({ url: `/organizations/${organizationId}/external-users`, booleanParams: params });
};

interface UserSelectItem extends ExternalUserShort {
  label: string;
  value: string;
}

const getUserOptions = (users?: ExternalUserShort[]): UserSelectItem[] | undefined => {
  if (!users) return undefined;
  return users.map((user) => ({
    label: `${trim(user.name)} ${trim(user.surnames)}`,
    value: user.id,
    ...user,
  }));
};

interface BaseProps {
  onChange: ((externalUsers: ExternalUserShort[]) => void) | ((externalUser: ExternalUserShort) => void);
  value?: string[] | string;
  id?: string;
  error?: string | (ValidationErrors<ExternalUserShort> | undefined)[];
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  searchPlaceholder?: string;
  isSingle: boolean;
  excludedUserIds?: string[];
  filterAvailableForNewRoutine?: boolean;
  filterOnlyWithEmail?: boolean;
  filterOnlyInvitedToApp?: boolean;
  withCouponData?: boolean;
}

interface MultiProps extends BaseProps {
  isSingle: false;
  onChange: (externalUsers: ExternalUserShort[]) => void;
  value?: string[];
}

interface SingleSelectProps extends BaseProps {
  isSingle: true;
  onChange: (externalUser: ExternalUserShort) => void;
  value?: string;
}

type Props = MultiProps | SingleSelectProps;

export const ExternalUsersSelect = ({
  onChange,
  id = 'users-dropdown',
  value,
  error,
  className,
  placeholder,
  searchPlaceholder = 'Busca usuarios',
  isSingle,
  filterAvailableForNewRoutine = false,
  filterOnlyWithEmail = false,
  filterOnlyInvitedToApp = false,
  disabled = false,
  excludedUserIds,
  withCouponData,
}: Props) => {
  const { get } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};

  const { data: externalUsers, isLoading: externalUsersLoading } = useSWR(
    organization
      ? getExternalUsersUrl(organization.id, {
          short: true,
          forRoutine: filterAvailableForNewRoutine,
          couponData: withCouponData,
          onlyWithEmail: filterOnlyWithEmail,
          onlyAppUsers: filterOnlyInvitedToApp,
        })
      : null,
    get<ExternalUserShort[]>,
  );

  const { options, usersHashMap } = useMemo(() => {
    if (!externalUsers) return { options: [], usersHashMap: {} };
    const excludedUsers = excludedUserIds ? externalUsers.filter((user) => !excludedUserIds?.includes(user.id)) : externalUsers;
    const options = getUserOptions(excludedUsers);
    const usersHashMap = getHashMap(excludedUsers ?? [], 'id');
    return { options, usersHashMap };
  }, [externalUsers, excludedUserIds]);

  const handleChange = (selectedValue: string | string[]) => {
    if (isSingle) {
      const newExternalUser = usersHashMap[selectedValue as string];
      if (!newExternalUser) return;
      (onChange as (user: ExternalUserShort) => void)(newExternalUser);
    } else {
      const newExternalUsers: ExternalUserShort[] = (selectedValue as string[])
        .map((userId) => usersHashMap[userId])
        .filter((user): user is ExternalUserShort => user !== undefined);
      (onChange as (users: ExternalUserShort[]) => void)(newExternalUsers);
    }
  };

  const userTemplate = (option: UserSelectItem | null) => {
    if (!option) return null;
    return <UserDisplay fullName={option.label} avatarUrl={option.avatarUrl} />;
  };

  return (
    <AdaptiveSelect
      id={id}
      isMultiple={!isSingle}
      value={value}
      onChange={handleChange}
      options={options}
      itemTemplate={userTemplate}
      placeholder={placeholder ?? (isSingle ? 'Elige un cliente' : 'Elige clientes')}
      modalTitle={isSingle ? 'Elegir cliente' : 'Elegir clientes'}
      searchPlaceholder={searchPlaceholder}
      className={classNames(className, { 'p-invalid': error })}
      invalid={!!error}
      loading={externalUsersLoading || organizationLoading}
      disabled={disabled}
      emptyMessage='No hay usuarios'
      emptyFilterMessage='No se han encontrado usuarios'
      virtualScrollerOptions={{
        itemSize: 56,
      }}
    />
  );
};
