export const capitalize = (str: string | undefined): string => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const unCapitalize = (str: string): string => {
  if (!str) return '';
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const removeFroalaHtml = (postContent: string): string => {
  const regex = /<p[^>]*data-f-id="pbf"[^>]*>.*?<\/p>/gi;
  return postContent.replace(regex, '');
};

export const trim = (str: string): string => {
  return str.trim().replace(/\s+/g, ' ');
};
