export enum NOTIFICATION_TYPES {
  RESERVATION_CANCELLED = 'RESERVATION_CANCELLED',
  ROUTINE_REMINDER = 'ROUTINE_REMINDER',
}

export interface Notification {
  id: string;
  type: NOTIFICATION_TYPES;
  title: string;
  body: string;
  link: string;
  isRead: boolean;
  createdAt: Date;

  // Refs
  userId: string;
}

export interface MarkAsReadRequest {
  notificationIds: string[];
}
