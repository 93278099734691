export enum FISIOFIT_SIGNED_DOCUMENT_TYPES {
  FISIOFIT_PHYSIOTHERAPY_CONSENT = 'fisiofit-physiotherapy-consent',
  FISIOFIT_GDPR = 'fisiofit-gdpr',
  FISIOFIT_GDPR_CHILD = 'fisiofit-gdpr-child',
  FISIOFIT_IMAGE_CONSENT = 'fisiofit-image-consent',
}

export const SIGNED_DOCUMENT_TYPES = { ...FISIOFIT_SIGNED_DOCUMENT_TYPES } as const;

export type SignedDocumentType = (typeof SIGNED_DOCUMENT_TYPES)[keyof typeof SIGNED_DOCUMENT_TYPES];

export interface FisiofitGdprDocumentPayload {
  fullName: string;
  nationalId: string;
  consentImages: boolean;
  consentReceiveInformation: boolean;
}

export interface FisiofitGdprChildDocumentPayload {
  childName: string;
  parent1Name: string;
  parent1NationalId: string;
  parent2Name?: string;
  parent2NationalId?: string;
  parent2Signature?: string;
  consentImages: boolean;
  consentReceiveInformation: boolean;
  singleParentResponsibilityDeclaration?: boolean;
}

export interface FisiofitPhysiotherapyConsentDocumentPayload {
  fullName: string;
  nationalId: string;

  guardianName?: string;
  guardianNationalId?: string;
  guardianRelation?: string;
  guardianSignature?: string;
}

export interface FisiofitImageConsentDocumentPayload {
  fullName: string;
}

export type DocumentPayload =
  | FisiofitPhysiotherapyConsentDocumentPayload
  | FisiofitGdprDocumentPayload
  | FisiofitGdprChildDocumentPayload
  | FisiofitImageConsentDocumentPayload;

export interface CreateSignedDocumentPayload {
  documentType: SignedDocumentType;
  signedAt: string;
  signingLocation: string;
  ipAddress: string;
  documentPayload: DocumentPayload;
  signature: string;
  externalUserId: string;
  organizationId: string;
}

export interface SignedDocument {
  id: string;
  documentType: SignedDocumentType;
  signedAt: string;
  ipAddress: string;
  pdfUrl: string;
  isRevoked?: boolean;

  // References
  organizationId: string;
  externalUserId: string;
  userId?: string;
}

export interface ExternalUserSignedDocument extends Partial<SignedDocument> {
  documentType: SignedDocumentType;
  isSigned: boolean;
  downloadUrl?: string;
  title: string;
}
