const css = `.alert {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid;
}
.alert.info {
  border-color: var(--blue-500);
  background-color: var(--blue-50);
  color: var(--blue-800);
}
.alert.info .alert-icon {
  color: var(--blue-500);
}
.alert.warning {
  border-color: var(--yellow-500);
  background-color: var(--yellow-50);
  color: var(--yellow-800);
}
.alert.warning .alert-icon {
  color: var(--yellow-500);
}
.alert.error {
  border-color: var(--pink-500);
  background-color: var(--pink-50);
  color: var(--pink-800);
}
.alert.error .alert-icon {
  color: var(--pink-500);
}
.alert.secondary {
  border-color: var(--gray-500);
  background-color: var(--gray-50);
}
.alert.secondary .alert-icon {
  color: var(--gray-500);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvQWxlcnQiLCJzb3VyY2VzIjpbIkFsZXJ0LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7RUFDQTs7QUFDQTtFQUNFOztBQUlKO0VBQ0U7RUFDQTtFQUNBOztBQUNBO0VBQ0U7O0FBSUo7RUFDRTtFQUNBO0VBQ0E7O0FBQ0E7RUFDRTs7QUFJSjtFQUNFO0VBQ0E7O0FBQ0E7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5hbGVydCB7XG4gIHdpZHRoOiAxMDAlO1xuICBkaXNwbGF5OiBmbGV4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBnYXA6IDhweDtcbiAgYm9yZGVyLXJhZGl1czogNHB4O1xuICBwYWRkaW5nOiA4cHg7XG4gIGJvcmRlcjogMXB4IHNvbGlkO1xuXG4gICYuaW5mbyB7XG4gICAgYm9yZGVyLWNvbG9yOiB2YXIoLS1ibHVlLTUwMCk7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tYmx1ZS01MCk7XG4gICAgY29sb3I6IHZhcigtLWJsdWUtODAwKTtcbiAgICAuYWxlcnQtaWNvbiB7XG4gICAgICBjb2xvcjogdmFyKC0tYmx1ZS01MDApO1xuICAgIH1cbiAgfVxuXG4gICYud2FybmluZyB7XG4gICAgYm9yZGVyLWNvbG9yOiB2YXIoLS15ZWxsb3ctNTAwKTtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS15ZWxsb3ctNTApO1xuICAgIGNvbG9yOiB2YXIoLS15ZWxsb3ctODAwKTtcbiAgICAuYWxlcnQtaWNvbiB7XG4gICAgICBjb2xvcjogdmFyKC0teWVsbG93LTUwMCk7XG4gICAgfVxuICB9XG5cbiAgJi5lcnJvciB7XG4gICAgYm9yZGVyLWNvbG9yOiB2YXIoLS1waW5rLTUwMCk7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tcGluay01MCk7XG4gICAgY29sb3I6IHZhcigtLXBpbmstODAwKTtcbiAgICAuYWxlcnQtaWNvbiB7XG4gICAgICBjb2xvcjogdmFyKC0tcGluay01MDApO1xuICAgIH1cbiAgfVxuXG4gICYuc2Vjb25kYXJ5IHtcbiAgICBib3JkZXItY29sb3I6IHZhcigtLWdyYXktNTAwKTtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1ncmF5LTUwKTtcbiAgICAuYWxlcnQtaWNvbiB7XG4gICAgICBjb2xvcjogdmFyKC0tZ3JheS01MDApO1xuICAgIH1cbiAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
