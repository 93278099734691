export enum EXERCISE_CATEGORIES {
  FUERZA = 'fuerza',
  CARDIO = 'cardio',
  FLEXIBILIDAD = 'flexibilidad',
  MOVILIDAD = 'movilidad',
  RESISTENCIA = 'resistencia',
  EQUILIBRIO = 'equilibrio',
  REHABILITACION = 'rehabilitación',
}

export enum EXERCISE_DIFFICULTIES {
  PRINCIPIANTE = 'principiante',
  INTERMEDIO = 'intermedio',
  AVANZADO = 'avanzado',
}

export enum MUSCLE_GROUPS {
  PECHO = 'pecho',
  ESPALDA = 'espalda',
  HOMBROS = 'hombros',
  BICEPS = 'bíceps',
  TRICEPS = 'tríceps',
  CUADRICEPS = 'cuádriceps',
  ISQUIOTIBIALES = 'isquiotibiales',
  GLUTEOS = 'glúteos',
  ABDOMINALES = 'abdominales',
  GEMELOS = 'gemelos',
  ANTEBRAZOS = 'antebrazos',
  LUMBARES = 'lumbares',
  ADUCTORES = 'aductores',
  ABDUCTORES = 'abductores',
  BODY = 'cuerpo completo',
}
