import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import './MembersList.scss';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';
import { useNavigate } from 'react-router-dom';

const users = [
  { id: 1, state: 'active', name: 'Jenny Wilson', username: '@jnnywlsn', joinedDate: 'Oct 30, 2022', services: 'Fisioterapia', imageUrl: 'path_to_image1' },
  { id: 2, state: 'active', name: 'Kathryn Murphy', username: '@murphyyy', joinedDate: 'Jul 14, 2022', services: 'Centro de fuerza', imageUrl: 'path_to_image2' },
  { id: 3, state: 'active', name: 'Leslie Alexander', username: '@leslie98', joinedDate: 'Dec 19, 2022', services: 'Fisioterapia y Centro de fuerza', imageUrl: 'path_to_image3' },
  { id: 4, state: 'inactive', name: 'Ralph Edwards', username: '@ralphadator', joinedDate: 'Feb 11, 2022', services: 'Fisioterapia', imageUrl: 'path_to_image4' },
];

enum userState {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}

const MembersList = () => {
  const menu = React.useRef(null);
  const navigate = useNavigate();

  const nameTemplate = (rowData: any) => (
    <div className="name-template">
      <Avatar image={rowData.imageUrl} shape="circle" className="mr-2" size='large' />
      <div>
        <div className="font-bold">{rowData.name}</div>
      </div>
    </div>
  );

  const actionsTemplate = () => (
    <>
      <Menu model={[{ label: 'Ver Perfil', command: () => {
                navigate(`profile/${'test'}`);
            } }, { label: 'Dar de baja' }, { label: 'Enviar cita' }]} popup ref={menu} />
      <Button icon="pi pi-ellipsis-v" className="p-button-text" onClick={(e) => (menu.current as any)?.toggle(e)} />
    </>
  );

  const stateTemplate = (rowData: any) => (
    <Tag className="members-state-tag" severity={rowData.state === userState.ACTIVE ? 'success' : 'danger'} value={rowData.state === userState.ACTIVE ? 'ACTIVO' : 'INACTIVO'} ></Tag>
  );

  return (
    <Card title="Miembros" className="members-card">
      <DataTable value={users} responsiveLayout="scroll">
        <Column field="name" header="Nombre" body={nameTemplate} />
        <Column field="services" header="Servicios" />
        <Column field="joinedDate" header="Fecha de incorporación" />
        <Column field="state" header="Estado" body={stateTemplate} />
        <Column body={actionsTemplate} style={{ width: '3em' }} />
      </DataTable>
    </Card>
  );
};

export default MembersList;
