import React, { useState, useMemo } from 'react';
import { FormField } from '../FormField/FormField';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import './ExternalUserPanel.scss';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { NotificationMessage, RequestErrorMessages, useClient } from '../../hooks/useClient';
import { mutate } from 'swr';
import { useForm } from '../../hooks/useForm';
import classNames from 'classnames';
import { EXTERNAL_USER_GENDERS, ExternalUser } from '../../types/externalUser';
import { useAuthContext } from '../../context/AuthContext';
import { Panel, ResourcePanelImplProps } from '../Panel/Panel';
import { ExternalUserForm, getExternalUserFormValidators, getInitialExternalUserForm } from './validators';
import { BranchesSelect } from '../BranchesSelect/BranchesSelect';
import { UsersSelect } from '../UsersSelect/UsersSelect';
import { Avatar } from 'primereact/avatar';
import { getUserAvatarProps } from '../../utils/avatarUtils';
import { Switch } from '../Switch/Switch';
import { subYears } from 'date-fns';
import { Capacitor } from '@capacitor/core';
import { PhoneInput } from '../PhoneInput/PhoneInput';
import { AdaptiveSelect } from '../AdaptiveSelect/AdaptiveSelect';
import { FileUploadButton } from '../FileUploadButton/FileUploadButton';

const genderOptions = [
  { label: 'Hombre', value: EXTERNAL_USER_GENDERS.MALE },
  { label: 'Mujer', value: EXTERNAL_USER_GENDERS.FEMALE },
  { label: 'Otro', value: EXTERNAL_USER_GENDERS.OTHER },
];

const getToastMessages = (
  isEditing: boolean,
): {
  successMessage: NotificationMessage;
  errorMessages: RequestErrorMessages;
} => {
  return {
    successMessage: {
      summary: isEditing ? 'Usuario modificado' : 'Usuario creado',
      detail: isEditing ? 'El usuario se ha modificado correctamente' : 'Se ha creado el nuevo usuario',
    },
    errorMessages: {
      summary: isEditing ? 'Error modificando usuario' : 'Error al crear usuario',
      defaultDetail: isEditing ? 'No se ha podido modificar el usuario' : 'No se ha podido crear el usuario',
      USER_EMAIL_EXISTS: 'Ya hay un usuario con ese correo electrónico.',
    },
  };
};

interface Props extends ResourcePanelImplProps<ExternalUser> {
  initialFormData?: Partial<ExternalUserForm>;
}

export const ExternalUserPanel = ({
  resource,
  visible,
  onHide,
  onIsEditingChange,
  onDeleteResource,
  isEditing = false,
  isDeleting = false,
  initialFormData,
}: Props) => {
  const { post } = useClient();
  const { organization } = useOrganizationContext() ?? {};
  const { user } = useAuthContext() ?? {};
  const [avatarFile, setAvatarFile] = useState<File>();

  const validators = useMemo(() => getExternalUserFormValidators(organization), [organization]);
  const initialExternalUserForm = useMemo(() => getInitialExternalUserForm(initialFormData), [initialFormData]);
  const { form, setFormFields, isSaving, setIsSaving, hasChanged, validationErrors, resetForm, validate } = useForm<
    ExternalUserForm | undefined
  >(initialExternalUserForm, !visible, validators);

  const handleSave = async (): Promise<boolean> => {
    const errors = validate();
    if (!form || !organization || !user || errors) return false;

    const { phone, supervisorId, avatarUrl, email, ...rest } = form;

    setIsSaving(true);
    const { successMessage, errorMessages } = getToastMessages(isEditing);

    const formData = new FormData();

    // Enviar los datos del formulario como JSON
    formData.append(
      'data',
      JSON.stringify({
        ...rest,
        ...(phone ? { phone } : {}),
        ...(supervisorId ? { supervisorId } : {}),
        ...(email ? { email } : {}),
      }),
    );

    // Enviar el archivo por separado
    if (avatarFile) {
      formData.append('image', avatarFile);
    }

    const response = await post<ExternalUser>(`/organizations/${organization?.id}/external-users`, {
      body: formData,
      successMessage,
      errorMessages,
    });

    if (response) {
      mutate(`/organizations/${organization?.id}/external-users`);
    }
    setIsSaving(false);
    return !!response;
  };

  const handleAvatarUpload = (imageUrl: string, file: File) => {
    setFormFields({
      avatarUrl: imageUrl,
    });
    setAvatarFile(file);
  };

  const renderGenderOption = (option: any) => {
    return <span>{option.label}</span>;
  };

  return (
    <>
      <Panel
        className='external-user-panel'
        panelType='resourcePanel'
        resourceName='usuario'
        visible={visible}
        onHide={onHide}
        onDelete={() => onDeleteResource(resource?.id)}
        isDeleting={isDeleting}
        onSave={handleSave}
        onIsEditingChange={onIsEditingChange}
        hasChanged={hasChanged}
        isSaving={isSaving}
        isEditing={false}
        isCreating={true}
        resetForm={resetForm}
      >
        <form
          className={classNames('external-user-form', {
            'form-loading': isSaving,
          })}
          onSubmit={handleSave}
        >
          <FormField elementId='user-branch' labelTitle='Sucursal' fullWidth error={validationErrors?.branchId} required>
            <BranchesSelect
              id='user-branch'
              isSingle
              error={validationErrors?.branchId}
              value={form?.branchId}
              onChange={(branch) => setFormFields({ branchId: branch?.id })}
              onlyAuthUserBranches
            />
          </FormField>

          <FormField
            elementId='user-supervisor'
            labelTitle='Supervisor'
            fullWidth
            error={validationErrors?.supervisorId}
            required={organization?.config?.supervisorRequired}
          >
            {form?.branchId ? (
              <UsersSelect
                id='user-supervisor'
                isSingle
                error={validationErrors?.supervisorId}
                value={form?.supervisorId}
                onChange={(user) => setFormFields({ supervisorId: user?.id })}
                filterByBranchIds={[form.branchId]}
              />
            ) : (
              <p className='field-message'>Selecciona una sucursal para elegir el supervisor</p>
            )}
          </FormField>

          <FormField fullWidth labelTitle='Avatar' elementId='avatar'>
            <div className='avatar-container'>
              <FileUploadButton
                fileName={`${form?.name}_${form?.surnames}_avatar.png`}
                accept='image/*'
                label='Subir imagen'
                onFileUpload={handleAvatarUpload}
              />
              {form?.avatarUrl && <Avatar {...getUserAvatarProps(form)} shape='circle' size='large' className='preview-avatar' />}
            </div>
          </FormField>

          <FormField labelTitle='Nombre' fullWidth error={validationErrors?.name} required>
            <InputText
              invalid={!!validationErrors?.name}
              value={form?.name}
              onChange={(e) => setFormFields({ name: e.target.value })}
              placeholder='Ej: Juan'
            />
          </FormField>

          <FormField labelTitle='Apellidos' fullWidth error={validationErrors?.surnames} required>
            <InputText
              invalid={!!validationErrors?.surnames}
              value={form?.surnames}
              onChange={(e) => setFormFields({ surnames: e.target.value })}
              placeholder='Ej: García'
            />
          </FormField>

          <FormField
            labelTitle='Invitación'
            elementId='send-invitation'
            infoTooltip='Si se activa, se enviará un correo al usuario para que acceda a la aplicación. Es necesario introducir el correo del usuario para poder invitarlo.'
          >
            <Switch
              id='send-invitation'
              label='Invitar a la aplicación'
              primeSwitchProps={{
                checked: form?.sendInvitation ?? true,
                onChange: (e) => setFormFields({ sendInvitation: e.value }),
              }}
            />
          </FormField>

          <FormField labelTitle='Correo electrónico' fullWidth error={validationErrors?.email} required={form?.sendInvitation}>
            <InputText
              invalid={!!validationErrors?.email}
              value={form?.email}
              onChange={(e) => setFormFields({ email: e.target.value })}
              placeholder='Ej: juan.garcia@gmail.com'
            />
          </FormField>

          <FormField labelTitle='Teléfono' fullWidth error={validationErrors?.phone}>
            <PhoneInput
              invalid={!!validationErrors?.phone}
              value={form?.phone}
              onChange={(value) => setFormFields({ phone: value })}
            />
          </FormField>

          <FormField labelTitle='DNI/NIE' fullWidth>
            <InputText
              value={form?.nationalId ?? ''}
              onChange={(e) => setFormFields({ nationalId: e.target.value })}
              placeholder='Ej: 12345678A'
            />
          </FormField>

          <FormField labelTitle='Dirección' fullWidth>
            <InputText
              value={form?.address ?? ''}
              onChange={(e) => setFormFields({ address: e.target.value })}
              placeholder='Ej: Calle Ejemplo 123, 29010, Málaga'
            />
          </FormField>

          <FormField labelTitle='Género' fullWidth error={validationErrors?.gender}>
            <AdaptiveSelect
              options={genderOptions}
              placeholder='Género del usuario'
              invalid={!!validationErrors?.gender}
              value={form?.gender}
              onChange={(value) => setFormFields({ gender: value })}
              itemTemplate={renderGenderOption}
              modalTitle='Seleccionar género'
              searchPlaceholder='Buscar género...'
              disabled={isSaving}
            />
          </FormField>

          <FormField labelTitle='Fecha de nacimiento' fullWidth error={validationErrors?.dateOfBirth}>
            <Calendar
              invalid={!!validationErrors?.dateOfBirth}
              dateFormat='dd/mm/yy'
              placeholder='Selecciona una fecha'
              showIcon
              maxDate={subYears(new Date(), 10)}
              minDate={subYears(new Date(), 110)}
              viewDate={subYears(new Date(), 18)}
              value={form?.dateOfBirth ? new Date(form.dateOfBirth) : null}
              onChange={(e) => setFormFields({ dateOfBirth: e.value?.toISOString() })}
              touchUI={Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches}
            />
          </FormField>
        </form>
      </Panel>
    </>
  );
};
