const css = `.kpi-container .p-card-body {
  width: 100%;
}
.kpi-container .p-card-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}
.kpi-container .kpi-value-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.kpi-container span.bold {
  font-weight: 800;
  display: flex;
  gap: 4px;
}
.kpi-container div.previous {
  font-weight: 400;
}
.kpi-container div.previous span:nth-child(1) {
  font-size: 8px;
}
.kpi-container div.previous span:nth-child(2) {
  font-size: 12px;
}
.kpi-container div.increase {
  color: var(--green-500);
}
.kpi-container div.decrease {
  color: var(--red-500);
}
.kpi-container .pi {
  border-radius: 8px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvS3BpQ2FyZCIsInNvdXJjZXMiOlsiS3BpQ2FyZC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUdJO0VBQ0k7O0FBRUo7RUFDSTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVKO0VBQ0k7RUFDQTtFQUNBOztBQUVKO0VBQ0k7RUFDQTtFQUNBOztBQUVKO0VBQ0k7O0FBRUE7RUFDSTs7QUFFSjtFQUNJOztBQUdSO0VBQ0k7O0FBRUo7RUFDSTs7QUFFSjtFQUNJIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnLi4vLi4vc3R5bGVzL21peGlucyc7XG5cbi5rcGktY29udGFpbmVyIHtcbiAgICAucC1jYXJkLWJvZHkge1xuICAgICAgICB3aWR0aDogMTAwJTtcbiAgICB9XG4gICAgLnAtY2FyZC1jb250ZW50IHtcbiAgICAgICAgd2lkdGg6IDEwMCU7XG4gICAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICAgIGZsZXgtZGlyZWN0aW9uOiByb3c7XG4gICAgICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgICAgICAgYWxpZ24taXRlbXM6IHN0YXJ0O1xuICAgIH1cbiAgICAua3BpLXZhbHVlLWNvbnRhaW5lciB7XG4gICAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgICAgIGdhcDogMTZweDtcbiAgICB9XG4gICAgc3Bhbi5ib2xkIHtcbiAgICAgICAgZm9udC13ZWlnaHQ6IDgwMDtcbiAgICAgICAgZGlzcGxheTogZmxleDtcbiAgICAgICAgZ2FwOiA0cHg7XG4gICAgfVxuICAgIGRpdi5wcmV2aW91cyB7XG4gICAgICAgIGZvbnQtd2VpZ2h0OiA0MDA7XG5cbiAgICAgICAgJiBzcGFuOm50aC1jaGlsZCgxKSB7XG4gICAgICAgICAgICBmb250LXNpemU6IDhweDtcbiAgICAgICAgfVxuICAgICAgICAmIHNwYW46bnRoLWNoaWxkKDIpIHtcbiAgICAgICAgICAgIGZvbnQtc2l6ZTogMTJweDtcbiAgICAgICAgfVxuICAgIH1cbiAgICBkaXYuaW5jcmVhc2Uge1xuICAgICAgICBjb2xvcjogdmFyKC0tZ3JlZW4tNTAwKTtcbiAgICB9XG4gICAgZGl2LmRlY3JlYXNlIHtcbiAgICAgICAgY29sb3I6IHZhcigtLXJlZC01MDApO1xuICAgIH1cbiAgICAucGkge1xuICAgICAgICBib3JkZXItcmFkaXVzOiA4cHg7XG4gICAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
