import React, { useMemo, useState } from 'react';
import './UserPanel.scss';

import { mutate } from 'swr';
import { User } from '../../types/user';
import { Role } from '../../types/role';
import { format, subYears } from 'date-fns';
import { Avatar } from 'primereact/avatar';
import { FormField } from '../FormField/FormField';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { Tag } from 'primereact/tag';
import { resourceMutator } from '../../utils/resourceMutation';
import { Panel, ResourcePanelImplProps } from '../Panel/Panel';
import { useClient } from '../../hooks/useClient';
import { useForm } from '../../hooks/useForm';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Switch } from '../Switch/Switch';
import { FeaturePicker } from '../FeaturePicker/FeaturePicker';
import { useAuthContext } from '../../context/AuthContext';
import { getRoleOptions } from '../../utils/selectOptions';
import useSWR from 'swr';
import { Calendar } from 'primereact/calendar';
import { employeeFormValidators, UserForm } from './validators';
import { getUserAvatarProps } from '../../utils/avatarUtils';
import { BranchesSelect } from '../BranchesSelect/BranchesSelect';
import { FeatureToggle } from '../FeatureToggle/FeatureToggle';
import { ALL_FEATURES } from '../../config/features';
import { useFeaturesToggle } from '../../hooks/useFeaturesToggle';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { AdaptiveSelect } from '../AdaptiveSelect/AdaptiveSelect';
import { Capacitor } from '@capacitor/core';
import { PhoneInput } from '../PhoneInput/PhoneInput';

const getToastMessages = (isEditing: boolean) => {
  return {
    successMessage: {
      summary: isEditing ? 'Empleado modificado' : 'Empleado creado',
      detail: isEditing ? 'El empleado se ha modificado correctamente' : 'Se ha creado el nuevo empleado',
    },
    errorMessages: {
      summary: isEditing ? 'Error modificando empleado' : 'Error al crear empleado',
      defaultDetail: isEditing ? 'No se ha podido modificar el empleado' : 'No se ha podido crear el empleado',
      USER_EMAIL_EXISTS: 'Ya hay un empleado con ese correo electrónico.',
    },
  };
};

const getInitialUserForm = (user?: User): UserForm => {
  const { name, email, surnames, branches, role, dateOfBirth, phone } = user ?? {};
  return {
    name,
    surnames,
    email,
    branchIds: branches?.map((_) => _.id),
    roleId: role?.id,
    roleFeatures: new Set(),
    dateOfBirth,
    phone,
  };
};

export const UserPanel = ({
  resource,
  visible,
  onHide,
  onIsEditingChange,
  onDeleteResource,
  isEditing = false,
  isDeleting = false,
  allowedFeatures,
}: ResourcePanelImplProps<User>) => {
  const { organization, organizationLoading } = useOrganizationContext() ?? {};
  const { user: authUser } = useAuthContext() ?? {};
  const { get, patch, post } = useClient();
  const [isCreatingNewRole, setIsCreatingNewRole] = useState(false);
  const isCreating = !resource;
  const userHasRolesFeature = useFeaturesToggle([ALL_FEATURES.ROLES]);
  const {
    data: orgRoles,
    isLoading: orgRolesLoading,
    mutate: mutateOrgRoles,
  } = useSWR(organization ? `/organizations/${organization.id}/roles` : null, get<Role[]>);
  const [isResending, setIsResending] = useState(false);

  const isCurrentUser = resource?.id === authUser?.id;

  const initialUserForm = useMemo(() => getInitialUserForm(resource), [resource]);
  const validators = useMemo(() => employeeFormValidators(isCreatingNewRole), [isCreatingNewRole]);
  const { form, setFormFields, isSaving, setIsSaving, hasChanged, validationErrors, resetForm, validate } = useForm<UserForm>(
    initialUserForm,
    !visible,
    validators,
  );

  const createRole = async () => {
    const { roleName, roleFeatures } = form ?? {};
    if (!roleName || !roleFeatures) return;
    return await post<Role>(`/organizations/${organization?.id}/roles`, {
      body: {
        name: roleName,
        features: Array.from(roleFeatures),
      },
    });
  };

  const handleHide = () => {
    onHide();
    setIsCreatingNewRole(false);
  };

  const editUser = async (): Promise<boolean> => {
    if (!form) return false;
    const { successMessage, errorMessages } = getToastMessages(true);

    if (isCreatingNewRole) {
      const createdRole = await createRole();
      if (!createdRole) return false;
      const updatedUser = await patch<User>(`/users/${resource?.id}`, {
        body: {
          roleId: createdRole.id,
          branchIds: form.branchIds,
        },
        successMessage,
        errorMessages,
      });
      if (!updatedUser) return false;
      mutate(`/organizations/${organization?.id}/users`, resourceMutator(updatedUser), false);
      mutateOrgRoles(resourceMutator(createdRole), false);
      handleHide();
      return true;
    }

    const updatedUser = await patch<User>(`/users/${resource?.id}`, {
      body: {
        roleId: form.roleId,
        branchIds: form.branchIds,
      },
      successMessage,
      errorMessages,
    });
    if (!updatedUser) return false;
    mutate(`/organizations/${organization?.id}/users`, resourceMutator(updatedUser), false);
    return true;
  };

  const createUser = async (): Promise<boolean> => {
    if (!form) return false;
    const { name, surnames, email, roleId, branchIds, dateOfBirth, phone } = form;
    const inviterName = `${authUser?.name} ${authUser?.surnames}`;
    const inviterOrg = organization?.name;
    const { successMessage, errorMessages } = getToastMessages(false);

    if (isCreatingNewRole) {
      const createdRole = await createRole();
      if (!createdRole) return false;
      const createdUser = await post<User>(`/users/add-employee`, {
        body: {
          name,
          surnames,
          email,
          branchIds,
          inviterName,
          inviterOrg,
          roleId: createdRole.id,
          dateOfBirth,
          ...(phone ? { phone } : {}),
        },
        successMessage,
        errorMessages,
      });
      if (!createdUser) return false;
      mutate(`/organizations/${organization?.id}/users`, resourceMutator(createdUser), false);
      mutateOrgRoles(resourceMutator(createdRole), false);
      handleHide();
      return true;
    }

    const createdUser = await post<User>(`/users/add-employee`, {
      body: {
        name,
        surnames,
        email,
        branchIds,
        inviterName,
        inviterOrg,
        roleId,
        dateOfBirth,
        ...(phone ? { phone } : {}),
      },
      successMessage,
      errorMessages,
    });
    if (!createdUser) return false;
    mutate(`/organizations/${organization?.id}/users`, resourceMutator(createdUser), false);
    handleHide();
    return true;
  };

  const handleSubmit = async (): Promise<boolean> => {
    const errors = validate();
    if (errors) return false;

    setIsSaving(true);
    const success = isEditing ? await editUser() : await createUser();
    setIsSaving(false);
    return !!success;
  };

  const handleResendInvitation = async (userId?: string) => {
    if (!userId) return;
    setIsResending(true);

    await post(`/users/${userId}/resend-invitation`, {
      successMessage: {
        summary: 'Invitación reenviada',
        detail: 'Se ha reenviado la invitación al empleado correctamente',
      },
      errorMessages: {
        summary: 'Error al reenviar la invitación',
        defaultDetail: 'No se ha podido reenviar la invitación al empleado',
      },
      handlers: {
        defaultSuccess: () => {
          mutate(`/organizations/${organization?.id}/users`);
        },
      },
    });

    setIsResending(false);
  };

  const renderHeaderContent = () => (
    <div className='user-panel-header'>
      <h4>{resource ? `${resource.name} ${resource.surnames}` : 'Nuevo empleado'}</h4>
      {resource && <Tag value={resource.isActive ? 'Activo' : 'Inactivo'} severity={resource.isActive ? 'success' : 'danger'} />}
    </div>
  );

  return (
    <Panel
      panelType='resourcePanel'
      resourceName='empleado'
      HeaderComponent={renderHeaderContent()}
      className='user-panel'
      visible={visible}
      onHide={handleHide}
      onDelete={() => onDeleteResource(resource?.id)}
      isDeleting={isDeleting}
      onIsEditingChange={onIsEditingChange}
      hasChanged={hasChanged}
      isEditing={isEditing}
      isCreating={isCreating}
      resetForm={() => {
        resetForm();
        setIsCreatingNewRole(false);
      }}
      onSave={handleSubmit}
      isSaving={isSaving || organizationLoading || orgRolesLoading}
      allowedFeatures={allowedFeatures}
      hideDeleteButton={isCurrentUser}
      customDeleteLabel={resource?.isActive ? 'Dar de baja' : 'Dar de alta'}
      customDeleteConfirmationDialogProps={{
        header: `Confirmar ${resource?.isActive ? 'baja' : 'alta'}`,
        message: (
          <>
            ¿Estás seguro de que quieres dar de {resource?.isActive ? 'baja' : 'alta'} a{' '}
            <b>
              {resource?.name} {resource?.surnames}
            </b>
            ?
          </>
        ),
        acceptLabel: `Sí, dar de ${resource?.isActive ? 'baja' : 'alta'}`,
        rejectLabel: 'No, volver',
        accept: () => onDeleteResource(resource?.id),
        acceptClassName: classNames('p-button-outlined', {
          'p-button-danger': resource?.isActive,
          'p-button-success': !resource?.isActive,
        }),
      }}
    >
      <form
        className={classNames('user-form', {
          editing: isEditing,
          'form-loading': orgRolesLoading || organizationLoading || isSaving,
        })}
      >
        {!isCreating && (
          <FormField className='align-center' fullWidth>
            <div style={{ position: 'relative' }}>
              <Avatar {...getUserAvatarProps(resource)} shape='circle' className='big-avatar' />
            </div>
          </FormField>
        )}

        <FormField elementId='employee-name' error={validationErrors?.name} labelTitle='Nombre' required={isCreating}>
          {isCreating ? (
            <InputText
              className={classNames({
                'p-invalid': validationErrors?.name,
              })}
              id='employee-name'
              name='employee-name'
              placeholder='Ej: Juan'
              value={form?.name ?? ''}
              onChange={(e: any) =>
                setFormFields({
                  name: e.target.value,
                })
              }
            />
          ) : (
            <p>{resource?.name}</p>
          )}
        </FormField>

        <FormField elementId='employee-surnames' error={validationErrors?.surnames} labelTitle='Apellidos' required={isCreating}>
          {isCreating ? (
            <InputText
              className={classNames({
                'p-invalid': validationErrors?.surnames,
              })}
              id='employee-surnames'
              name='employee-surnames'
              placeholder='Ej: García'
              value={form?.surnames ?? ''}
              onChange={(e: any) =>
                setFormFields({
                  surnames: e.target.value,
                })
              }
            />
          ) : (
            <p>{resource?.surnames}</p>
          )}
        </FormField>

        <FormField
          elementId='employee-email'
          error={validationErrors?.email}
          labelTitle='Correo electrónico'
          required={isCreating}
        >
          <div className='email-field-container'>
            {isCreating ? (
              <InputText
                className={classNames({
                  'p-invalid': validationErrors?.email,
                })}
                id='employee-email'
                name='employee-email'
                placeholder='Ej: juan.garcia@gmail.com'
                value={form?.email ?? ''}
                onChange={(e: any) =>
                  setFormFields({
                    email: e.target.value,
                  })
                }
              />
            ) : (
              <>
                <p>{resource?.email}</p>
                {resource?.isActive && (!resource?.isInApp || resource?.isInvitation) && userHasRolesFeature && (
                  <Button
                    icon={PrimeIcons.REPLAY}
                    label='Reenviar invitación'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleResendInvitation(resource?.id);
                    }}
                    link
                    loading={isResending}
                  />
                )}
              </>
            )}
          </div>
        </FormField>

        <FormField
          elementId='user-branches'
          labelTitle='Sucursales'
          required={isCreating || isEditing}
          error={validationErrors?.branchIds}
        >
          {isEditing || isCreating ? (
            <BranchesSelect
              id='user-branches'
              isSingle={false}
              error={validationErrors?.branchIds}
              value={form?.branchIds}
              onChange={(branches) => setFormFields({ branchIds: branches.map((branch) => branch.id) })}
              className={classNames({
                'p-invalid': validationErrors?.branchIds,
              })}
              onlyAuthUserBranches
            />
          ) : (
            <p>
              {resource?.branches?.map((branch) => resource?.branches?.find((_) => _.id === branch.id)?.name).join(', ') ?? '-'}
            </p>
          )}
        </FormField>

        {(isEditing || isCreating) && (
          <div className='select-role-row'>
            {isCreatingNewRole ? (
              <FormField elementId='role-name' error={validationErrors?.roleName} labelTitle='Nombre del rol' required>
                <InputText
                  className={classNames({
                    'p-invalid': validationErrors?.roleName,
                  })}
                  id='role-name'
                  name='role-name'
                  placeholder='Ej: Administrador'
                  value={form?.roleName ?? ''}
                  onChange={(e: any) =>
                    setFormFields({
                      roleName: e.target.value,
                    })
                  }
                />
              </FormField>
            ) : (
              <FormField labelTitle='Elegir rol' required error={validationErrors?.roleId}>
                <AdaptiveSelect
                  loading={orgRolesLoading}
                  options={getRoleOptions(orgRoles)}
                  value={form?.roleId}
                  onChange={(e) => setFormFields({ roleId: e })}
                  placeholder='Elige un rol'
                  modalTitle='Seleccionar rol'
                  searchPlaceholder='Buscar rol'
                  itemTemplate={(option) => <span>{option.label}</span>}
                  className={classNames({
                    'p-invalid': validationErrors?.roleId,
                  })}
                  emptyMessage='No hay roles'
                  emptyFilterMessage='No se han encontrado roles'
                />
              </FormField>
            )}
            <FeatureToggle featuresToCheck={[ALL_FEATURES.ROLES]}>
              <Switch
                id='create-user'
                label='Crear rol nuevo'
                primeSwitchProps={{
                  checked: isCreatingNewRole,
                  onChange: (e) => {
                    setIsCreatingNewRole(e.value);
                  },
                }}
              />
            </FeatureToggle>
          </div>
        )}

        {!isCreatingNewRole && !isEditing && !isCreating && (
          <FormField labelTitle='Rol'>
            <p>{resource?.role?.name ?? '-'}</p>
          </FormField>
        )}

        {isCreatingNewRole && userHasRolesFeature && (
          <FormField labelTitle='Permisos' required error={validationErrors?.roleFeatures}>
            <FeaturePicker
              className={classNames({
                'p-invalid': !!validationErrors?.roleFeatures,
              })}
              onChange={(newFeatures) =>
                setFormFields({
                  roleFeatures: newFeatures,
                })
              }
              featureSet={form?.roleFeatures}
            />
          </FormField>
        )}

        <FormField elementId='is-in-app' labelTitle='Usa app'>
          <div className='app-status'>
            {resource?.isInApp ? (
              <>
                <i className='pi pi-check-circle success' />
                <span>Sí</span>
              </>
            ) : (
              <>
                <i className='pi pi-times-circle error' />
                <span>No</span>
              </>
            )}
          </div>
        </FormField>

        <FormField elementId='date-of-birth' error={validationErrors?.dateOfBirth} labelTitle='Fecha de nacimiento'>
          {isCreating ? (
            <Calendar
              className={classNames({
                'p-invalid': validationErrors?.dateOfBirth,
              })}
              id='date-of-birth'
              name='date-of-birth'
              placeholder='Selecciona una fecha'
              value={form?.dateOfBirth ? new Date(form.dateOfBirth) : null}
              onChange={(e) =>
                setFormFields({
                  dateOfBirth: e.value?.toISOString(),
                })
              }
              maxDate={subYears(new Date(), 10)}
              minDate={subYears(new Date(), 110)}
              viewDate={subYears(new Date(), 18)}
              dateFormat='dd/mm/yy'
              showIcon
              touchUI={Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches}
            />
          ) : (
            <p>{resource?.dateOfBirth ? format(new Date(resource.dateOfBirth), "d 'de' LLLL, yyyy") : '-'}</p>
          )}
        </FormField>

        <FormField elementId='phone' error={validationErrors?.phone} labelTitle='Teléfono'>
          {isCreating ? (
            <PhoneInput
              invalid={!!validationErrors?.phone}
              value={form?.phone}
              onChange={(value) => setFormFields({ phone: value })}
            />
          ) : (
            <p>{resource?.phone ?? '-'}</p>
          )}
        </FormField>
      </form>
    </Panel>
  );
};
