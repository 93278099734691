import { useCallback, useMemo } from 'react';
import { useOrganizationContext } from '../../context/OrganizationContext';
import useSWR from 'swr';
import { getHashMap, HashMap } from '../../utils/objectTransformers';
import { ReservationType } from '../../types/reservationType';
import { useClient } from '../useClient';

export const RESERVATION_COLORS = ['primary', 'green', 'yellow', 'blue', 'orange', 'cyan', 'red'];

export interface ReservationTypeWithColor extends ReservationType {
  color: string;
}
export type ReservationTypeHashMap = HashMap<'id', ReservationTypeWithColor>;

export const useReservationTypes = () => {
  const { organization } = useOrganizationContext() ?? {};
  const { get } = useClient();

  const reservationTypesFetcher = useCallback(
    async (url: string): Promise<ReservationTypeWithColor[] | undefined> => {
      const reservationTypes = await get<ReservationType[] | undefined>(url);
      if (!reservationTypes) return;
      const reservationTypesWithColor = reservationTypes.map((_, index) => ({
        ..._,
        color: RESERVATION_COLORS[index % RESERVATION_COLORS.length],
      }));
      return reservationTypesWithColor;
    },
    [get]
  );

  const {
    data: reservationTypes,
    isLoading: reservationTypesLoading,
    error: reservationTypesError,
    mutate: mutateReservationTypes,
  } = useSWR(organization ? `/organizations/${organization.id}/reservation-types` : null, reservationTypesFetcher, {
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });

  const reservationTypesHashMap: ReservationTypeHashMap | undefined = useMemo(
    () => (reservationTypes ? getHashMap(reservationTypes, 'id') : undefined),
    [reservationTypes]
  );

  const reservationTypeFromId = (reservationTypeId: string) => {
    return reservationTypesHashMap?.[reservationTypeId as keyof ReservationTypeHashMap];
  };

  return {
    reservationTypes,
    reservationTypesLoading,
    reservationTypesError,
    reservationTypeFromId,
    mutateReservationTypes,
  };
};
