import React, { useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { FormField } from '../FormField/FormField';
import { useForm, FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { ExternalUser, EXTERNAL_USER_GENDERS } from '../../types/externalUser';
import { classNames } from 'primereact/utils';
import { subYears } from 'date-fns';
import './EditExternalUserModal.scss';
import { BranchesSelect } from '../BranchesSelect/BranchesSelect';
import { UsersSelect } from '../UsersSelect/UsersSelect';
import { Capacitor } from '@capacitor/core';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { Organization } from '../../types/organization';
import { PhoneInput } from '../PhoneInput/PhoneInput';
import { validatePhone } from '../../utils/validationUtils';
import { showCancelDialog } from '../../utils/dialogUtils';

const { INVALID_PHONE } = VALIDATION_MESSAGES;

export interface EditExternalUserForm {
  name: string;
  surnames: string;
  email?: string;
  phone?: string;
  nationalId?: string;
  dateOfBirth?: string;
  gender?: EXTERNAL_USER_GENDERS;
  branchId?: string;
  supervisorId?: string;
  address?: string;
}

const GENDER_OPTIONS = [
  { label: 'Hombre', value: EXTERNAL_USER_GENDERS.MALE },
  { label: 'Mujer', value: EXTERNAL_USER_GENDERS.FEMALE },
  { label: 'Otro', value: EXTERNAL_USER_GENDERS.OTHER },
];

const getUserFormValidators = (organization: Organization | undefined): FormValidators<EditExternalUserForm> => {
  return {
    name: (form) => {
      if (!form.name) return 'El nombre es obligatorio';
    },
    surnames: (form) => {
      if (!form.surnames) return 'Los apellidos son obligatorios';
    },
    email: (form) => {
      if (form.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(form.email)) return 'El email no es válido';
    },
    branchId: (form) => {
      if (!form.branchId) return 'La sucursal es obligatoria';
    },
    supervisorId: (form) => {
      if (organization?.config?.supervisorRequired && !form.supervisorId) return 'El supervisor es obligatorio';
    },
    phone: (form) => {
      if (form.phone && !validatePhone(form.phone)) return INVALID_PHONE;
    },
  };
};

const getInitialEditExternalUserForm = (user: ExternalUser): EditExternalUserForm => ({
  name: user.name,
  surnames: user.surnames,
  email: user.email,
  phone: user.phone,
  nationalId: user.nationalId,
  dateOfBirth: user.dateOfBirth,
  gender: user.gender,
  branchId: user.branchId,
  supervisorId: user.supervisor?.id,
  address: user.address,
});

interface Props {
  visible: boolean;
  onHide: () => void;
  user: ExternalUser;
  onSave: (updatedUser: EditExternalUserForm) => Promise<void>;
}

export const EditExternalUserModal = ({ visible, onHide, user, onSave }: Props) => {
  const { organization } = useOrganizationContext() ?? {};

  const initialUserForm = useMemo(() => getInitialEditExternalUserForm(user), [user]);
  const validators = useMemo(() => getUserFormValidators(organization), [organization]);
  const { form, setFormFields, isSaving, setIsSaving, hasChanged, validationErrors, resetForm, validate } =
    useForm<EditExternalUserForm>(initialUserForm, !visible, validators);

  const handleHide = () => {
    if (hasChanged) {
      showCancelDialog(() => {
        resetForm();
        onHide();
      });
    } else {
      resetForm();
      onHide();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors = validate();
    if (newErrors) return;

    setIsSaving(true);
    try {
      await onSave(form);
      resetForm();
      onHide();
    } finally {
      setIsSaving(false);
    }
  };

  const renderFooter = () => (
    <div className='dialog-footer'>
      <Button label='Cancelar' icon='pi pi-times' onClick={handleHide} className='p-button-text' disabled={isSaving} />
      <Button label='Guardar' icon='pi pi-check' onClick={handleSubmit} loading={isSaving} disabled={!hasChanged || isSaving} />
    </div>
  );

  return (
    <Dialog
      header='Editar usuario'
      visible={visible}
      onHide={handleHide}
      footer={renderFooter()}
      className='edit-external-user-modal big-modal'
      modal
      dismissableMask
      draggable={false}
      resizable={false}
    >
      <form onSubmit={handleSubmit} className='edit-external-user-form'>
        <FormField labelTitle='Sucursal' elementId='branchId' error={validationErrors?.branchId} required>
          <BranchesSelect
            id='branchId'
            isSingle
            value={form.branchId}
            onChange={(branch) => setFormFields({ branchId: branch?.id })}
            error={validationErrors?.branchId}
          />
        </FormField>

        <FormField
          labelTitle='Supervisor'
          elementId='supervisorId'
          error={validationErrors?.supervisorId}
          required={organization?.config?.supervisorRequired}
        >
          {form.branchId ? (
            <UsersSelect
              id='supervisorId'
              isSingle
              value={form.supervisorId}
              onChange={(user) => setFormFields({ supervisorId: user?.id })}
              error={validationErrors?.supervisorId}
              filterByBranchIds={[form.branchId]}
            />
          ) : (
            <p className='field-message'>Selecciona una sucursal para elegir el supervisor</p>
          )}
        </FormField>

        <FormField labelTitle='Nombre' elementId='name' error={validationErrors?.name} required>
          <InputText
            id='name'
            value={form.name}
            onChange={(e) => setFormFields({ name: e.target.value })}
            className={classNames({ 'p-invalid': validationErrors?.name })}
            placeholder='Introduce el nombre'
          />
        </FormField>

        <FormField labelTitle='Apellidos' elementId='surnames' error={validationErrors?.surnames} required>
          <InputText
            id='surnames'
            value={form.surnames}
            onChange={(e) => setFormFields({ surnames: e.target.value })}
            className={classNames({ 'p-invalid': validationErrors?.surnames })}
            placeholder='Introduce los apellidos'
          />
        </FormField>

        <FormField
          labelTitle='Email'
          elementId='email'
          error={validationErrors?.email}
          infoTooltip='Si cambias el correo electrónico, el usuario no podrá acceder con su correo anterior y deberá usar el nuevo correo para iniciar sesión.'
        >
          <InputText
            id='email'
            value={form.email}
            onChange={(e) => setFormFields({ email: e.target.value || undefined })}
            className={classNames({ 'p-invalid': validationErrors?.email })}
            placeholder='Introduce el email'
          />
        </FormField>

        <FormField labelTitle='Teléfono' elementId='phone' error={validationErrors?.phone}>
          <PhoneInput
            invalid={!!validationErrors?.phone}
            value={form?.phone}
            onChange={(value) => setFormFields({ phone: value })}
          />
        </FormField>

        <FormField labelTitle='DNI' elementId='nationalId' error={validationErrors?.nationalId}>
          <InputText
            id='nationalId'
            value={form.nationalId}
            onChange={(e) => setFormFields({ nationalId: e.target.value || undefined })}
            className={classNames({ 'p-invalid': validationErrors?.nationalId })}
            placeholder='12345678A'
          />
        </FormField>

        <FormField labelTitle='Fecha de nacimiento' elementId='dateOfBirth' error={validationErrors?.dateOfBirth}>
          <Calendar
            id='dateOfBirth'
            value={form.dateOfBirth ? new Date(form.dateOfBirth) : null}
            onChange={(e) => setFormFields({ dateOfBirth: e.value?.toISOString() })}
            className={classNames({ 'p-invalid': validationErrors?.dateOfBirth })}
            dateFormat='dd/mm/yy'
            showIcon
            maxDate={subYears(new Date(), 10)}
            minDate={subYears(new Date(), 110)}
            viewDate={form.dateOfBirth ? new Date(form.dateOfBirth) : subYears(new Date(), 18)}
            placeholder='Selecciona la fecha de nacimiento'
            touchUI={Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches}
          />
        </FormField>

        <FormField labelTitle='Género' elementId='gender'>
          <Dropdown
            id='gender'
            value={form.gender}
            onChange={(e) => setFormFields({ gender: e.value })}
            options={GENDER_OPTIONS}
            placeholder='Selecciona el género'
          />
        </FormField>

        <FormField labelTitle='Dirección' elementId='address'>
          <InputText
            id='address'
            value={form.address}
            onChange={(e) => setFormFields({ address: e.target.value || undefined })}
            placeholder='Introduce la dirección'
          />
        </FormField>
      </form>
    </Dialog>
  );
};
