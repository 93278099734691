import React, { FormEvent, useMemo } from 'react';
import './RolePanel.scss';

import { Sidebar } from 'primereact/sidebar';
import { Role } from '../../types/role';
import { Button } from 'primereact/button';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { FeaturePicker } from '../FeaturePicker/FeaturePicker';
import { useForm } from '../../hooks/useForm';
import { FormField } from '../FormField/FormField';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Feature } from '../../config/features';
import {
  NotificationMessage,
  RequestErrorMessages,
  useClient,
} from '../../hooks/useClient';
import { mutate } from 'swr';
import { resourceMutator } from '../../utils/resourceMutation';

const getRolePanelTitle = (isCreating: boolean, isEditing: boolean) => {
  if (!isCreating && isEditing) {
    return 'Editar rol';
  }
  if (!isCreating && !isEditing) {
    return 'Detalles del rol';
  }
  if (isCreating && !isEditing) {
    return 'Crear nuevo rol';
  }
};

const getInitialRoleForm = (role?: Role): RoleForm | undefined => {
  const { features, name } = role ?? {};
  return {
    name,
    features: new Set(features),
  };
};

interface RoleForm {
  name?: string;
  features?: Set<Feature>;
}

interface Props {
  role: Role | undefined;
  visible: boolean;
  onHide: () => void;
  onIsEditingChange: (isEditing: boolean) => void;
  isEditing?: boolean;
}

export const RolePanel = ({
  role,
  visible,
  onHide,
  onIsEditingChange,
  isEditing = false,
}: Props) => {
  const { organization } = useOrganizationContext() ?? {};
  const { post, patch } = useClient();
  const isCreating = !role;

  const initialRoleForm = useMemo(() => getInitialRoleForm(role), [role]);
  const {
    form,
    setFormField,
    isSaving,
    setIsSaving,
    hasChanged,
    validationErrors,
  } = useForm<RoleForm | undefined>(initialRoleForm, false);

  const handleRoleFormSave = async (e: FormEvent) => {
    e.preventDefault();
    const { name, features } = form ?? {};
    if (!name || !features || !organization) return;
    setIsSaving(true);

    const { successMessage, errorMessages } = getToastMessages(isEditing);
    const request = {
      body: {
        name,
        features: Array.from(features),
      },
      successMessage,
      errorMessages,
    };
    const responseRole =
      isEditing && role
        ? await patch<Role>(`/roles/${role.id}`, request)
        : await post<Role>(`/organizations/${organization.id}/roles`, request);

    if (responseRole) {
      mutate(
        `/organizations/${organization.id}/roles-table`,
        resourceMutator<Role>(responseRole),
        // The false arg makes it not refetch the data and rely only on our local mutation
        false
      );
    }
    setIsSaving(false);
    onHide();
  };

  return (
    <Sidebar
      className='role-panel'
      position='right'
      visible={visible}
      onHide={onHide}
    >
      <h3>{getRolePanelTitle(isCreating, isEditing)}</h3>
      {!isCreating && !isEditing && (
        <div className='readonly-form'>
          <FormField labelTitle='Nombre'>
            <p>{role?.name}</p>
          </FormField>
          <FeaturePicker featureSet={form?.features} disabled={true} />
        </div>
      )}
      {(isEditing || isCreating) && (
        <form
          className={classNames({
            'form-loading': isSaving,
          })}
          onSubmit={handleRoleFormSave}
        >
          <FormField labelTitle='Nombre del rol' elementId='role-name'>
            <InputText
              id='role-name'
              className={classNames({
                'p-invalid': validationErrors?.name,
              })}
              placeholder={`Escribe el nombre del rol`}
              value={form?.name ?? ''}
              onChange={(e: any) =>
                setFormField({
                  name: e.target.value,
                })
              }
            />
          </FormField>
          <FeaturePicker
            onChange={(newFeatures) =>
              setFormField({
                features: newFeatures,
              })
            }
            featureSet={form?.features}
          />
          <Button
            className='pull-right pull-down'
            type='submit'
            label={isEditing ? 'Editar rol' : 'Crear rol'}
            disabled={!hasChanged}
          />
        </form>
      )}
      {!isEditing && !isCreating && (
        <Button
          className='pull-right pull-down'
          label={'Editar rol'}
          onClick={() => onIsEditingChange(true)}
        />
      )}
    </Sidebar>
  );
};

const getToastMessages = (
  isEditing: boolean
): {
  successMessage: NotificationMessage;
  errorMessages: RequestErrorMessages;
} => {
  return {
    successMessage: {
      summary: isEditing ? 'Rol modificado' : 'Rol creado',
      detail: isEditing
        ? 'El rol se ha modificado correctamente'
        : 'Se ha creado el nuevo rol',
    },
    errorMessages: {
      summary: isEditing ? 'Error modificando rol' : 'Error al crear rol',
      defaultDetail: isEditing
        ? 'No se ha podido modificar el rol'
        : 'No se ha podido crear el rol',
    },
  };
};
