import React, { useMemo, useRef, useState } from 'react';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { useAuthContext } from '../../context/AuthContext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { PrimeIcons } from 'primereact/api';
import { Password } from 'primereact/password';
import { useLocaleContext } from '../../context/LocaleContext';
import './Profile.scss';
import { UpdatePassword, User } from '../../types/user';
import { useForm } from '../../hooks/useForm';
import { classNames } from 'primereact/utils';
import { subYears } from 'date-fns';
import { useClient } from '../../hooks/useClient';
import {
  PASSWORD_FORM_VALIDATORS,
  ProfileForm,
  SIGNATURE_FORM_VALIDATORS,
  SignatureForm,
  USER_FORM_VALIDATORS,
} from './validators';
import { FormField } from '../../components/FormField/FormField';
import { Nullable } from 'primereact/ts-helpers';
import { SignatureInput } from '../../components/SignatureInput/SignatureInput';
import { transformUrlToImageFile } from '../../utils/imageUtils';
import { Capacitor } from '@capacitor/core';
import { PhoneInput } from '../../components/PhoneInput/PhoneInput';
import { AvatarUpload } from '../../components/AvatarUpload/AvatarUpload';

const INITIAL_PASSWORD_FORM: UpdatePassword = {
  newPassword: '',
  oldPassword: '',
};

const getInitialSignatureForm = (user?: User): SignatureForm => {
  const { nationalId, signatureUrl } = user ?? {};

  return {
    nationalId,
    signatureUrl,
  };
};

const getInitialProfileForm = (user?: User): ProfileForm | undefined => {
  const { id, name, email, surnames, phone, dateOfBirth, avatarUrl } = user ?? {};
  return {
    id,
    name,
    email,
    surnames,
    phone,
    dateOfBirth,
    avatarUrl,
  };
};

export const Profile = () => {
  const { user, userLoading, updateUser, setUser } = useAuthContext() ?? {};
  const { locale } = useLocaleContext();
  const { patch, post } = useClient();
  const [avatarFile, setAvatarFile] = useState<File>();

  const initialUserForm = useMemo(() => getInitialProfileForm(user), [user]);
  const {
    form: userForm,
    setForm: setUserForm,
    setFormFields: setUserField,
    isSaving: userSaving,
    setIsSaving: setIsUserSaving,
    hasChanged: userChanged,
    validationErrors: userValidationErrors,
    validate: validateUser,
  } = useForm<ProfileForm | undefined>(initialUserForm, false, USER_FORM_VALIDATORS);

  const passwordFormRef = useRef(null);
  const {
    form: { newPassword, oldPassword },
    setForm: setPasswordForm,
    setFormFields: setPasswordField,
    isSaving: passwordSaving,
    setIsSaving: setIsPasswordSaving,
    hasChanged: passwordChanged,
    validate: validatePasswordForm,
    validationErrors: passwordFormValidationErrors,
  } = useForm<UpdatePassword>(INITIAL_PASSWORD_FORM, false, PASSWORD_FORM_VALIDATORS, passwordFormRef);

  const initialSignatureForm = useMemo(() => getInitialSignatureForm(user), [user]);
  const {
    form: signatureForm,
    setFormFields: setSignatureField,
    isSaving: signatureSaving,
    setIsSaving: setSignatureSaving,
    hasChanged: signatureFormChanged,
    validate: validateSignatureForm,
    validationErrors: signatureValidationErrors,
  } = useForm<SignatureForm>(initialSignatureForm, false, SIGNATURE_FORM_VALIDATORS);
  const [isEditingSignature, setIsEditingSignature] = useState(!signatureForm.signatureUrl);
  const hasSignatureChanged = signatureForm.signatureUrl !== initialSignatureForm.signatureUrl;

  const updateUserPassword = async (userId: string, updatePasswordDto: UpdatePassword) => {
    await patch<boolean>(`/users/${userId}/password`, {
      body: updatePasswordDto,
      errorMessages: {
        summary: 'Error al editar contraseña',
        defaultDetail: 'No se ha podido editar la contraseña',
        INVALID_OLD_PASSWORD: 'La contraseña antigua no es válida',
        [400]: 'La contraseña nueva no puede ser igual que la antigua',
      },
      successMessage: {
        summary: 'Contraseña cambiada',
        detail: 'Se ha modificado correctamente la contraseña',
      },
      handlers: {
        defaultSuccess: () => setPasswordForm(INITIAL_PASSWORD_FORM),
      },
    });
  };

  const updateUserSignature = async (): Promise<string | undefined> => {
    if (!signatureForm.signatureUrl) return;
    const formData = new FormData();
    const file = await transformUrlToImageFile(signatureForm.signatureUrl, `${user?.name}_${user?.surnames}_signature.png`);
    formData.append('image', file);
    formData.append(
      'data',
      JSON.stringify({
        nationalId: signatureForm.nationalId,
      }),
    );
    const updatedUser = await post<User>(`/users/${user?.id}/legal-document-data`, {
      body: formData,
      errorMessages: {
        summary: 'Error al editar firma',
        defaultDetail: 'No se ha podido editar la firma',
      },
      successMessage: {
        summary: user?.signatureUrl ? 'Firma editada' : 'Firma creada',
        detail: 'Se ha guardado correctamente la firma',
      },
    });
    if (!updatedUser) return;

    setUser?.(updatedUser);
    setIsEditingSignature(false);
    return updatedUser.signatureUrl;
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setUserField({
      [name]: value,
    });
  };

  const handleDateChange = (date: Nullable<Date>) => {
    setUserField({
      dateOfBirth: date?.toISOString(),
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newErrors = validateUser();
    if (!userForm || newErrors) return;
    const { avatarUrl, ...updateUserDto } = userForm;

    setIsUserSaving(true);
    const updatedUser = await updateUser?.(updateUserDto, avatarFile);
    setIsUserSaving(false);
    setUserForm(getInitialProfileForm(updatedUser));
  };

  const handlePasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newErrors = validatePasswordForm();
    if (!user || newErrors) return;

    setIsPasswordSaving(true);
    await updateUserPassword?.(user?.id, {
      newPassword,
      oldPassword,
    });
    setIsPasswordSaving(false);
  };

  const handleSignatureSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<string | undefined> => {
    e.preventDefault();
    const newErrors = validateSignatureForm();
    if (!signatureForm || newErrors) return;

    setSignatureSaving(true);
    const newSignatureUrl = await updateUserSignature();
    setSignatureSaving(false);
    return newSignatureUrl;
  };

  const handleAvatarUpload = (fileUrl: string, file: File) => {
    setUserField({
      avatarUrl: fileUrl,
    });
    setAvatarFile(file);
  };

  const handleSignatureChange = (newValue: string) => {
    setSignatureField({ signatureUrl: newValue });
  };

  const handleStartEditSignature: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setIsEditingSignature(true);
  };

  const handleCancelEditSignature: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setIsEditingSignature(false);
    setSignatureField({ signatureUrl: initialSignatureForm.signatureUrl });
  };

  return (
    <PageTemplate className='profile-page' title='Modificar perfil'>
      <Card>
        <form
          className={classNames({
            'form-loading': userLoading || userSaving,
          })}
          onSubmit={handleSubmit}
        >
          <FormField className='align-center' fullWidth labelTitle='Avatar' elementId='avatar'>
            <AvatarUpload
              loading={userLoading}
              onUpload={handleAvatarUpload}
              fileName={`${user?.name}_${user?.surnames}_avatar.png`}
              user={userForm}
            />
          </FormField>

          <FormField labelTitle='Nombre' elementId='name' error={userValidationErrors?.name} required>
            <InputText
              id='name'
              name='name'
              placeholder='Ej: Juan'
              value={userForm?.name ?? ''}
              onChange={handleInputChange}
              className={classNames({
                'p-invalid': userValidationErrors?.name,
              })}
            />
          </FormField>

          <FormField labelTitle='Apellidos' elementId='surnames' error={userValidationErrors?.surnames} required>
            <InputText
              id='surnames'
              name='surnames'
              placeholder='Ej: García'
              value={userForm?.surnames ?? ''}
              onChange={handleInputChange}
              className={classNames({
                'p-invalid': userValidationErrors?.surnames,
              })}
            />
          </FormField>

          <FormField labelTitle='Correo electrónico' elementId='email' error={userValidationErrors?.email} required>
            <InputText
              id='email'
              name='email'
              placeholder='Ej: juan.garcia@gmail.com'
              value={userForm?.email ?? ''}
              onChange={handleInputChange}
              className={classNames({
                'p-invalid': userValidationErrors?.email,
              })}
            />
          </FormField>

          <FormField error={userValidationErrors?.phone} labelTitle='Teléfono' elementId='phone'>
            <PhoneInput
              invalid={!!userValidationErrors?.phone}
              value={userForm?.phone}
              onChange={(value) => setUserField({ phone: value })}
            />
          </FormField>

          <FormField labelTitle='Fecha de nacimiento' elementId='dateOfBirth'>
            <Calendar
              id='dateOfBirth'
              name='dateOfBirth'
              dateFormat='dd/mm/yy'
              placeholder='Selecciona una fecha'
              maxDate={subYears(new Date(), 10)}
              minDate={subYears(new Date(), 110)}
              viewDate={subYears(new Date(), 18)}
              value={userForm?.dateOfBirth ? new Date(userForm.dateOfBirth) : null}
              onChange={(e) => handleDateChange(e.value)}
              showIcon
              touchUI={Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches}
              locale={locale}
            />
          </FormField>

          <Button type='submit' disabled={!userChanged} loading={userSaving} icon={PrimeIcons.SAVE} label='Guardar' />
        </form>
      </Card>
      <h4>Cambiar contraseña</h4>
      <Card>
        <form
          ref={passwordFormRef}
          className={classNames({
            'form-loading': userLoading || passwordSaving,
          })}
          onSubmit={handlePasswordSubmit}
        >
          <FormField
            labelTitle='Contraseña antigua'
            elementId='oldPassword'
            error={passwordFormValidationErrors?.oldPassword}
            required
          >
            <Password
              id='oldPassword'
              placeholder='Contraseña antigua'
              feedback={false}
              value={oldPassword}
              autoComplete='current-password'
              onChange={(e) => setPasswordField({ oldPassword: e.target.value })}
              toggleMask
              className={classNames({
                'p-invalid': passwordFormValidationErrors?.oldPassword,
              })}
            />
          </FormField>

          <FormField
            labelTitle='Contraseña nueva'
            elementId='newPassword'
            error={passwordFormValidationErrors?.newPassword}
            required
            errorMessageOverride='La contraseña debe tener al menos 8 caracteres, una mayúscula,
                  una minúscula, un número y un caracter especial.'
          >
            <Password
              id='newPassword'
              placeholder='Contraseña nueva'
              feedback={false}
              value={newPassword}
              autoComplete='new-password'
              onChange={(e) => setPasswordField({ newPassword: e.target.value })}
              toggleMask
              className={classNames({
                'p-invalid': passwordFormValidationErrors?.newPassword,
              })}
            />
          </FormField>

          <Button
            type='submit'
            label='Guardar'
            icon={PrimeIcons.SAVE}
            className='mt-2'
            disabled={!passwordChanged}
            loading={passwordSaving}
          />
        </form>
      </Card>
      <h4>Firma de documentos</h4>
      <Card>
        <form
          className={classNames('signature-form', { 'form-loading': userLoading || signatureSaving })}
          onSubmit={handleSignatureSubmit}
        >
          {!user?.signatureUrl && (
            <div className='signature-alert'>
              <i className={classNames(PrimeIcons.EXCLAMATION_TRIANGLE, 'signature-alert-icon error')} />
              <p className='signature-alert-message'>No tienes una firma configurada.</p>
            </div>
          )}
          <p className='signature-form-description'>
            Tu firma y DNI se utilizarán para firmar documentos de la aplicación. Es necesario configurar la firma antes de poder
            firmar documentos.
          </p>
          <FormField labelTitle='DNI' elementId='nationalId' error={signatureValidationErrors?.nationalId} required>
            {isEditingSignature ? (
              <InputText
                id='nationalId'
                name='nationalId'
                placeholder='Ej: 05432123-A'
                value={signatureForm?.nationalId ?? ''}
                onChange={(e) => setSignatureField({ nationalId: e.target.value })}
                invalid={!!signatureValidationErrors?.nationalId}
              />
            ) : (
              <p>{signatureForm?.nationalId}</p>
            )}
          </FormField>
          <SignatureInput
            isEditing={isEditingSignature}
            initialValue={initialSignatureForm?.signatureUrl}
            onSignatureChange={handleSignatureChange}
            onClearSignature={() => setSignatureField({ signatureUrl: initialSignatureForm.signatureUrl })}
            error={signatureValidationErrors?.signatureUrl}
            hasChanged={hasSignatureChanged}
            required={true}
          />
          <div className='signature-form-buttons'>
            {isEditingSignature ? (
              <>
                {initialSignatureForm.signatureUrl && (
                  <Button key='cancel' label='Cancelar' text severity='secondary' onClick={handleCancelEditSignature} />
                )}
                <Button
                  type='submit'
                  label='Guardar'
                  icon={PrimeIcons.SAVE}
                  loading={signatureSaving}
                  disabled={!signatureFormChanged || !hasSignatureChanged}
                />
              </>
            ) : (
              <Button key='sign-again' label='Volver a firmar' icon={PrimeIcons.PENCIL} onClick={handleStartEditSignature} />
            )}
          </div>
        </form>
      </Card>
    </PageTemplate>
  );
};
