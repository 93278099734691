import React, { useEffect, useState } from 'react';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import './Analytics.scss';
import { useWebsiteContext } from '../../context/WebsiteContext';
import useSWR from 'swr';
import { Skeleton } from 'primereact/skeleton';
import { KpiCard, KpiProps } from '../../components/KpiCard/KpiCard';
import { PrimeIcons } from 'primereact/api';
import { useClient } from '../../hooks/useClient';
import { Chart } from 'primereact/chart';
import { Card } from 'primereact/card';
import {
  aggregatedData as barAggregatedData,
  lineAggregatedData,
  pieAggregatedData,
} from '../../utils/chart-utils';
import { useLocaleContext } from '../../context/LocaleContext';

interface ChartDataSet {
  type: 'bar' | 'line' | 'pie' | 'doughnut' | 'kpi';
  data: any;
  chartTitle: string;
  className?: string;
}

const CACHE_TTL = 4 * 60 * 60 * 1000;

const fetchWithCache = async (key: string, fetcher: () => Promise<any>, ttl: number) => {
  const now = new Date().getTime();
  const cachedData = localStorage.getItem(key);

  if (cachedData) {
    const parsedCache = JSON.parse(cachedData);
    if (now - parsedCache.timestamp < ttl && parsedCache.data) {
      return parsedCache.data;
    } else {
      localStorage.removeItem(key);
    }
  }

  const data = await fetcher();
  localStorage.setItem(key, JSON.stringify({ data, timestamp: now }));
  return data;
};

const Analytics = () => {
  const { website } = useWebsiteContext() ?? {};
  const { locale } = useLocaleContext();
  const [dataForChart, setDataForChart] = useState<ChartDataSet[]>([]);
  const [kpiData, setKpiData] = useState<KpiProps[]>([]);
  const { get } = useClient();

  const { data, isLoading, error } = useSWR(
    website ? `/websites/${website.id}/google-analytics?locale=${locale}` : null,
    (url: string) => fetchWithCache(url, () => get(url), CACHE_TTL)
  );

  useEffect(() => {
    if (data) {
      const chartsData: ChartDataSet[] = [
        {
          type: 'line',
          data: data.usersOverTime,
          chartTitle: 'Usuarios activos diarios ',
          className: 'large-chart',
        },
        {
          type: 'doughnut',
          data: data.sessionsByCategory,
          chartTitle: 'Origen de las sesiones',
          className: 'small-chart',
        },
        { type: 'bar', data: data.countryDistribution, chartTitle: 'Usuarios por país' },
        { type: 'bar', data: data.cityDistribution, chartTitle: 'Usuarios por ciudad' },
        { type: 'doughnut', data: data.deviceCategoryByUsers, chartTitle: 'Dispositivos' },
        { type: 'bar', data: data.sessionsBySource, chartTitle: 'Fuentes del tráfico' },
        { type: 'pie', data: data.browsersByUsers, chartTitle: 'Usuarios por navegador' },
        { type: 'bar', data: data.viewsByPage, chartTitle: 'Sesiones por página' },
      ];

      // TODO coger las variables desde css (usar blue-500, green-500, etc. de prime)
      const kpiTransformedData: KpiProps[] = [
        {
          value: data.activeUsersLast7Days.value,
          previousPercentage: data.activeUsersLast7Days.percentageComparedToLastWeek,
          title: 'Usuarios activos (7d)',
          icon: PrimeIcons.CHART_LINE,
          iconBkgColor: '#d1e7ff',
          iconColor: '#1e90ff',
        },
        {
          value: data.newUsersLast7Days.value,
          previousPercentage: data.newUsersLast7Days.percentageComparedToLastWeek,
          title: 'Usuarios nuevos (7d)',
          icon: PrimeIcons.USER_PLUS,
          iconBkgColor: '#e6ffe6',
          iconColor: '#32cd32',
        },
        {
          value: `${data.engagedSessionsPercentageLast7Days.value}%`,
          previousPercentage: data.engagedSessionsPercentageLast7Days.percentageComparedToLastWeek,
          title: 'Engagement rate (7d)',
          icon: PrimeIcons.THUMBS_UP,
          iconBkgColor: '#fff7e6',
          iconColor: '#ff8c00',
        },
        {
          value: data.activeUsersYesterday.value,
          previousPercentage: data.activeUsersYesterday.percentageComparedToLastWeek,
          title: 'Usuarios activos ayer',
          icon: PrimeIcons.USERS,
          iconBkgColor: 'var(--disruptive-light)',
          iconColor: 'var(--disruptive)',
        },
      ];

      setDataForChart(chartsData);
      setKpiData(kpiTransformedData);
    }
  }, [data]);

  const getDataToShow = (dataSet: ChartDataSet) => {
    switch (dataSet.type) {
      case 'line':
        return lineAggregatedData(dataSet.data);
      case 'bar':
        return barAggregatedData(dataSet.data);
      case 'pie':
        return pieAggregatedData(dataSet.data);
      case 'doughnut':
        return pieAggregatedData(dataSet.data);
      default:
        return null;
    }
  };

  return (
    <PageTemplate className='analytics-page' title='Analíticas'>
      <div className='kpi-container'>
        {kpiData.length > 0 ? (
          kpiData.map((data: KpiProps, index) => <KpiCard {...data} key={index}></KpiCard>)
        ) : (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
      </div>

      <div className='charts-container'>
        {dataForChart.length > 0 ? (
          dataForChart.map((dataSet: ChartDataSet) => {
            const dataToShow = getDataToShow(dataSet);
            return (
              <Card
                key={dataSet.chartTitle}
                title={dataSet.chartTitle}
                className={dataSet.className}
              >
                <Chart
                  type={dataSet.type}
                  data={dataToShow?.chartData}
                  options={dataToShow?.chartOptions}
                />
              </Card>
            );
          })
        ) : (
          <>
            <Skeleton className='large-chart' />
            <Skeleton className='small-chart' />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
      </div>
    </PageTemplate>
  );
};

export default Analytics;
