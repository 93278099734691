import React, { useState } from 'react';
import './UsersList.scss';

import { useNavigate } from 'react-router-dom';
import { ResponsiveTable } from '../../../components/ResponsiveTable/ResponsiveTable';
import { TableColumn, TableAction } from '../../../types/responsiveTable';
import { ExternalUserTableEntry } from '../../../types/externalUser';
import useSWR from 'swr';
import { useOrganizationContext } from '../../../context/OrganizationContext';
import { useClient } from '../../../hooks/useClient';

interface DataType {
  id: number;
  status: string;
  name: string;
  joinedDate: string;
  lastReservation: string;
  avatarUrl: string;
}

const UsersList = () => {
  const navigate = useNavigate();
  const [routinePanelId, setRoutinePanelId] = useState<string>();
  const [routinePanelVisible, setRoutinePanelVisible] = useState(false);
  const { get } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};

  const { data, isLoading, error } = useSWR(
    organization ? `/organizations/${organization.id}/external-users` : null,
    get<ExternalUserTableEntry[]>
  );

  const tableHeaders: TableColumn<ExternalUserTableEntry>[] = [
    {
      label: '',
      accessor: 'avatarUrl',
      type: 'avatar',
    },
    {
      label: 'Nombre',
      accessor: 'name',
      type: 'string',
      isTitle: true,
      valueFromCallBack: (name, row) => `${name} ${row.surnames}`,
    },
    {
      label: 'Última reserva',
      accessor: 'latestReservationDate',
      type: 'calendar',
    },
    {
      label: 'Fecha de incorporación',
      accessor: 'joinedDate',
      type: 'calendar',
    },
    {
      label: 'Estado',
      accessor: 'status',
      textKeyFromCallBack: (status: string) =>
        status === 'active' ? 'Activo' : 'Inactivo',
      colorFromCallBack: (translatedStatus: string) => {
        return translatedStatus === 'Activo' ? 'success' : 'danger';
      },
      type: 'tag',
    },
  ];

  const options: TableAction[] = [
    {
      label: 'Ver perfil',
      onClick: () => {
        navigate(`profile/${'test'}`);
      },
    },
    {
      label: 'Ver rutina',
    },
    {
      label: 'Crear cita fisioterapia',
    },
    {
      label: 'Dar de baja',
    },
  ];

  return (
    <ResponsiveTable
      data={data || []}
      columns={tableHeaders}
      options={options}
      loading={isLoading}
      onRowClick={() => {
        navigate(`profile/${'test'}`);
      }}
    ></ResponsiveTable>
  );
};

export default UsersList;
