import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { PrimeIcons } from 'primereact/api';
import './GmailConfigInfo.scss';
import { Alert } from '../Alert/Alert';

export function GmailConfigInfo() {
  const [visible, setVisible] = useState(false);

  const onShow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setVisible(true);
  };

  return (
    <>
      <Button icon={PrimeIcons.INFO_CIRCLE} label='Cómo configurar Gmail' severity='secondary' text onClick={onShow} />

      <Dialog
        header='Configuración de Gmail para envío de correos'
        visible={visible}
        onHide={() => setVisible(false)}
        className='gmail-config-dialog big-modal'
        draggable={false}
        resizable={false}
      >
        <div className='gmail-config-content'>
          <section className='intro-section'>
            <h5>¿Por qué necesito una contraseña de aplicación?</h5>
            <p>
              Para usar Gmail como servidor de correo, necesitas generar una contraseña de aplicación específica. Esta es una
              medida de seguridad de Google que permite que aplicaciones de terceros envíen correos a través de tu cuenta de Gmail
              sin comprometer tu contraseña principal.
            </p>
          </section>

          <section className='steps-section'>
            <h5>Pasos para configurar</h5>

            <div className='step'>
              <div className='step-header'>
                <span className='step-number'>1</span>
                <h6>Activa la verificación en dos pasos</h6>
              </div>
              <p>
                Para poder generar una contraseña de aplicación, primero debes tener activada la verificación en dos pasos en tu
                cuenta de Google.
              </p>
              <ul className='substeps'>
                <li>Ve a la configuración de tu cuenta de Google</li>
                <li>Busca la sección de &apos;Seguridad&apos;</li>
                <li>Activa la &apos;Verificación en dos pasos&apos;</li>
                <li>Sigue los pasos que te indica Google para completar la configuración</li>
              </ul>
              <Alert severity='info'>
                Si ya tienes activada la verificación en dos pasos, puedes saltar directamente al siguiente paso.
              </Alert>
            </div>

            <div className='step'>
              <div className='step-header'>
                <span className='step-number'>2</span>
                <h6>Genera una contraseña de aplicación</h6>
              </div>
              <p>
                Una vez que tengas la verificación en dos pasos activada, puedes generar una contraseña específica para esta
                aplicación.
              </p>
              <ul className='substeps'>
                <li>Ve a la sección de &apos;Contraseñas de aplicación&apos; en la configuración de seguridad</li>
                <li>Selecciona &apos;Otra&apos; en el menú desplegable de aplicaciones</li>
                <li>Escribe un nombre para identificar esta aplicación (por ejemplo, &apos;Dashboard&apos;)</li>
                <li>Haz clic en &quot;Generar&quot;</li>
                <li>Google te mostrará una contraseña de 16 caracteres</li>
                <li>Copia esta contraseña y úsala en el campo de contraseña del servidor de correo</li>
              </ul>
              <Alert severity='info'>Guarda esta contraseña en un lugar seguro. Google solo te la mostrará una vez.</Alert>
            </div>
          </section>

          <section className='important-notes'>
            <h5>Notas importantes</h5>
            <ul>
              <li>
                <i className={PrimeIcons.EXCLAMATION_CIRCLE} />
                La contraseña de aplicación es diferente a tu contraseña normal de Gmail
              </li>
              <li>
                <i className={PrimeIcons.EXCLAMATION_CIRCLE} />
                No compartas esta contraseña con nadie
              </li>
              <li>
                <i className={PrimeIcons.EXCLAMATION_CIRCLE} />
                Puedes revocar el acceso en cualquier momento desde la configuración de seguridad de Google
              </li>
            </ul>
          </section>

          <section className='help-links'>
            <h5>Enlaces de ayuda</h5>
            <ul>
              <li>
                <a href='https://myaccount.google.com/security' target='_blank' rel='noopener noreferrer'>
                  <i className={PrimeIcons.LINK} />
                  Cómo activar la verificación en dos pasos
                </a>
              </li>
              <li>
                <a href='https://support.google.com/accounts/answer/185833' target='_blank' rel='noopener noreferrer'>
                  <i className={PrimeIcons.LINK} />
                  Cómo generar y usar contraseñas de aplicación
                </a>
              </li>
              <li>
                <a href='https://support.google.com/accounts/answer/6010255' target='_blank' rel='noopener noreferrer'>
                  <i className={PrimeIcons.LINK} />
                  Seguridad de la cuenta de Google
                </a>
              </li>
            </ul>
          </section>
        </div>
      </Dialog>
    </>
  );
}
