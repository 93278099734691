import { ids_v1 } from 'googleapis';

function isDateString(str: string) {
  const date = new Date(str);
  return !isNaN(date.getTime()); // `getTime` returns NaN if the date is invalid
}

export const areFormObjectsDifferent = (obj1: any, obj2: any, emptyStringIsUndefined = false) => {
  if (obj1 === obj2) return false;

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    if (emptyStringIsUndefined && obj1 === '' && obj2 === undefined) return false;
    if (emptyStringIsUndefined && obj2 === '' && obj1 === undefined) return false;
    if (isDateString(obj1 ?? '') && isDateString(obj2 ?? '')) {
      return new Date(obj1).toISOString() !== new Date(obj2).toISOString();
    }
    return obj1 !== obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    if (emptyStringIsUndefined) {
      const newKeys1 = keys1.filter((key) => !keys2.includes(key));
      const newKeys2 = keys2.filter((key) => !keys1.includes(key));
      newKeys1.forEach((key) => {
        if (obj1[key] === '') {
          delete obj1[key];
        } else {
          return true;
        }
      });
      newKeys2.forEach((key) => {
        if (obj2[key] === '') {
          delete obj2[key];
        } else {
          return true;
        }
      });
    } else {
      return true;
    }
  }

  for (const key of Object.keys(obj1)) {
    if (!keys2.includes(key) || areFormObjectsDifferent(obj1[key], obj2[key])) {
      return true;
    }
  }

  return false;
};
