const css = `.profile-page {
  --speed: 0.65s;
}
@keyframes zoom {
  from {
    object-position: 0 50%;
  }
  to {
    object-position: 100% 50%;
  }
}
.profile-page p > span {
  margin-left: 8px;
}
.profile-page button {
  align-self: end;
}
.profile-page button[type=submit] {
  margin-left: auto;
}
.profile-page .signature-form .signature-alert {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--pink-500);
  border-radius: 4px;
  padding: 8px;
  background-color: var(--pink-50);
}
.profile-page .signature-form .signature-alert-icon {
  color: var(--pink-500);
}
.profile-page .signature-form .signature-form-description {
  color: var(--primary500);
  font-style: italic;
}
.profile-page .signature-form .signature-form-buttons {
  display: flex;
  justify-content: end;
  gap: var(--gap);
  width: 100%;
}
.profile-page .signature-form .signature-form-buttons button {
  margin-left: 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL3BhZ2VzL1Byb2ZpbGUiLCJzb3VyY2VzIjpbIlByb2ZpbGUuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFOztBQUVBO0VBQ0U7SUFDRTs7RUFFRjtJQUNFOzs7QUFLRjtFQUNFOztBQUlKO0VBQ0U7O0FBR0Y7RUFDRTs7QUFJQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTs7QUFHSjtFQUNFO0VBQ0E7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFDQTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLnByb2ZpbGUtcGFnZSB7XG4gIC0tc3BlZWQ6IDAuNjVzO1xuXG4gIEBrZXlmcmFtZXMgem9vbSB7XG4gICAgZnJvbSB7XG4gICAgICBvYmplY3QtcG9zaXRpb246IDAgNTAlO1xuICAgIH1cbiAgICB0byB7XG4gICAgICBvYmplY3QtcG9zaXRpb246IDEwMCUgNTAlO1xuICAgIH1cbiAgfVxuXG4gIHAge1xuICAgICYgPiBzcGFuIHtcbiAgICAgIG1hcmdpbi1sZWZ0OiA4cHg7XG4gICAgfVxuICB9XG5cbiAgYnV0dG9uIHtcbiAgICBhbGlnbi1zZWxmOiBlbmQ7XG4gIH1cblxuICBidXR0b25bdHlwZT0nc3VibWl0J10ge1xuICAgIG1hcmdpbi1sZWZ0OiBhdXRvO1xuICB9XG5cbiAgLnNpZ25hdHVyZS1mb3JtIHtcbiAgICAuc2lnbmF0dXJlLWFsZXJ0IHtcbiAgICAgIHdpZHRoOiAxMDAlO1xuICAgICAgZGlzcGxheTogZmxleDtcbiAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgICBnYXA6IDhweDtcbiAgICAgIGJvcmRlcjogMXB4IHNvbGlkIHZhcigtLXBpbmstNTAwKTtcbiAgICAgIGJvcmRlci1yYWRpdXM6IDRweDtcbiAgICAgIHBhZGRpbmc6IDhweDtcbiAgICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXBpbmstNTApO1xuXG4gICAgICAmLWljb24ge1xuICAgICAgICBjb2xvcjogdmFyKC0tcGluay01MDApO1xuICAgICAgfVxuICAgIH1cbiAgICAuc2lnbmF0dXJlLWZvcm0tZGVzY3JpcHRpb24ge1xuICAgICAgY29sb3I6IHZhcigtLXByaW1hcnk1MDApO1xuICAgICAgZm9udC1zdHlsZTogaXRhbGljO1xuICAgIH1cbiAgICAuc2lnbmF0dXJlLWZvcm0tYnV0dG9ucyB7XG4gICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAganVzdGlmeS1jb250ZW50OiBlbmQ7XG4gICAgICBnYXA6IHZhcigtLWdhcCk7XG4gICAgICB3aWR0aDogMTAwJTtcbiAgICAgIGJ1dHRvbiB7XG4gICAgICAgIG1hcmdpbi1sZWZ0OiAwO1xuICAgICAgfVxuICAgIH1cbiAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
