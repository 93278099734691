const css = `.app-invitation-header {
  display: flex;
  align-items: center;
  gap: 8px;
}
.app-invitation-header .pi {
  font-size: 16px;
}
.app-invitation-header strong {
  margin-right: 4px;
}
.app-invitation .app-invitation-header {
  display: flex;
  align-items: center;
  gap: 8px;
}
.app-invitation .app-invitation-header strong {
  margin: 0;
}
.app-invitation .app-invitation-header .app-invitation-status.success {
  color: var(--green-500);
}
.app-invitation .app-invitation-header .app-invitation-status.error {
  color: var(--red-500);
}
.app-invitation .app-invitation-header button {
  padding: 0;
}
.app-invitation .app-invitation-header button .p-button-label {
  font-size: 14px;
}

.invitation-dialog .p-dialog-content {
  overflow: visible;
}
.invitation-dialog .dialog-message {
  margin-bottom: 16px;
}
.invitation-dialog form {
  margin-bottom: 16px;
}
.invitation-dialog .dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvVXNlclByb2ZpbGVBcHBJbnZpdGF0aW9uIiwic291cmNlcyI6WyJVc2VyUHJvZmlsZUFwcEludml0YXRpb24uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOztBQUdGO0VBQ0U7O0FBSUo7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTs7QUFJQTtFQUNFOztBQUVGO0VBQ0U7O0FBSUo7RUFDRTs7QUFDQTtFQUNFOzs7QUFPTjtFQUNFOztBQUVGO0VBQ0U7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5hcHAtaW52aXRhdGlvbiB7XG4gICYtaGVhZGVyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgZ2FwOiA4cHg7XG5cbiAgICAucGkge1xuICAgICAgZm9udC1zaXplOiAxNnB4O1xuICAgIH1cblxuICAgIHN0cm9uZyB7XG4gICAgICBtYXJnaW4tcmlnaHQ6IDRweDtcbiAgICB9XG4gIH1cblxuICAuYXBwLWludml0YXRpb24taGVhZGVyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgZ2FwOiA4cHg7XG5cbiAgICBzdHJvbmcge1xuICAgICAgbWFyZ2luOiAwO1xuICAgIH1cblxuICAgIC5hcHAtaW52aXRhdGlvbi1zdGF0dXMge1xuICAgICAgJi5zdWNjZXNzIHtcbiAgICAgICAgY29sb3I6IHZhcigtLWdyZWVuLTUwMCk7XG4gICAgICB9XG4gICAgICAmLmVycm9yIHtcbiAgICAgICAgY29sb3I6IHZhcigtLXJlZC01MDApO1xuICAgICAgfVxuICAgIH1cblxuICAgIGJ1dHRvbiB7XG4gICAgICBwYWRkaW5nOiAwO1xuICAgICAgLnAtYnV0dG9uLWxhYmVsIHtcbiAgICAgICAgZm9udC1zaXplOiAxNHB4O1xuICAgICAgfVxuICAgIH1cbiAgfVxufVxuXG4uaW52aXRhdGlvbi1kaWFsb2cge1xuICAucC1kaWFsb2ctY29udGVudCB7XG4gICAgb3ZlcmZsb3c6IHZpc2libGU7XG4gIH1cbiAgLmRpYWxvZy1tZXNzYWdlIHtcbiAgICBtYXJnaW4tYm90dG9tOiAxNnB4O1xuICB9XG5cbiAgZm9ybSB7XG4gICAgbWFyZ2luLWJvdHRvbTogMTZweDtcbiAgfVxuXG4gIC5kaWFsb2ctZm9vdGVyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogZmxleC1lbmQ7XG4gICAgZ2FwOiA4cHg7XG4gIH1cbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
