export interface GetRequestUrlOpts {
  url: string;
  booleanParams?: Record<string, boolean>;
  stringParams?: Record<string, string | number | undefined>;
}

export const getRequestUrl = ({ url, booleanParams, stringParams }: GetRequestUrlOpts) => {
  const booleanQueryParams = Object.entries(booleanParams ?? {})
    .filter(([_, value]) => value)
    .map(([key]) => `${key}=true`);

  const stringQueryParams = Object.entries(stringParams ?? {})
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${value}`);

  const queryParams = [...booleanQueryParams, ...stringQueryParams];

  return `${url}${queryParams.length ? '?' + queryParams.join('&') : ''}`;
};
