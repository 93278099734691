import React, { useMemo } from 'react';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { getHashMap } from '../../utils/objectTransformers';
import { ValidationErrors } from '../../hooks/useForm';
import { Branch } from '../../types/branch';
import { ALL_SELECT_OPTION, getBranchOptions } from '../../utils/selectOptions';
import { AdaptiveSelect } from '../AdaptiveSelect/AdaptiveSelect';
import { classNames } from 'primereact/utils';
import { useAuthContext } from '../../context/AuthContext';
import { ALL_FEATURES } from '../../config/features';

interface BaseProps {
  onChange: ((branches: Branch[]) => void) | ((branch: Branch | undefined) => void);
  value?: string[] | string;
  id?: string;
  error?: string | (ValidationErrors<Branch> | undefined)[];
  className?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  isSingle: boolean;
  selectAllOption?: boolean;
  excludeBranchIds?: string[];
  onlyAuthUserBranches?: boolean;
}

interface MultiProps extends BaseProps {
  isSingle: false;
  onChange: (branches: Branch[]) => void;
  value?: string[];
  selectAllOption?: never;
}

interface SingleSelectProps extends BaseProps {
  isSingle: true;
  onChange: (branch: Branch | undefined) => void;
  value?: string;
  selectAllOption?: boolean;
}

type Props = MultiProps | SingleSelectProps;

export const BranchesSelect = ({
  onChange,
  id = 'branches-dropdown',
  value,
  error,
  className,
  placeholder,
  searchPlaceholder = 'Buscar sucursales',
  isSingle,
  selectAllOption = false,
  excludeBranchIds = [],
  onlyAuthUserBranches = false,
}: Props) => {
  const { orgBranches, organizationLoading } = useOrganizationContext() ?? {};
  const { user, features } = useAuthContext() ?? {};

  const finalPlaceholder = placeholder ?? (isSingle ? 'Elige sucursal' : 'Elige sucursales');

  const branchesHashMap = useMemo(() => getHashMap(orgBranches ?? [], 'id'), [orgBranches]);

  const options = useMemo(() => {
    const filteredBranches =
      excludeBranchIds.length > 0 ? orgBranches?.filter((branch) => !excludeBranchIds.includes(branch.id)) : orgBranches;
    const shouldRestrictBranches = onlyAuthUserBranches && !features?.includes(ALL_FEATURES.ORG_ADMIN);
    const branchesAvailableToUser = shouldRestrictBranches
      ? filteredBranches?.filter((branch) => user?.branches?.some((b) => b.id === branch.id))
      : filteredBranches;
    return getBranchOptions(branchesAvailableToUser, selectAllOption);
  }, [excludeBranchIds, orgBranches, onlyAuthUserBranches, features, selectAllOption, user?.branches]);

  const handleMultiChange = (values: string[]) => {
    if (isSingle) return;
    const newBranches: Branch[] = values.map((branchId: string) => branchesHashMap[branchId]) as Branch[];
    onChange(newBranches);
  };

  const handleSingleChange = (value: string) => {
    if (!isSingle) return;
    if (value === ALL_SELECT_OPTION) {
      onChange(undefined);
      return;
    }
    const newBranch = branchesHashMap[value];
    if (!newBranch) return;
    onChange(newBranch);
  };

  const branchTemplate = (option: any) => {
    return <span>{option.label}</span>;
  };

  return (
    <AdaptiveSelect
      id={id}
      isMultiple={!isSingle}
      value={value}
      onChange={(newValue) => (isSingle ? handleSingleChange(newValue) : handleMultiChange(newValue))}
      options={options}
      itemTemplate={branchTemplate}
      placeholder={finalPlaceholder}
      modalTitle={isSingle ? 'Seleccionar sucursal' : 'Seleccionar sucursales'}
      searchPlaceholder={searchPlaceholder}
      className={classNames(className, { 'p-invalid': error })}
      invalid={!!error}
      loading={organizationLoading}
      emptyMessage='No hay sucursales'
      emptyFilterMessage='No se han encontrado sucursales'
    />
  );
};
