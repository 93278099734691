import React from 'react';
import { InputText } from 'primereact/inputtext';
import { FormValidators, useForm } from '../../hooks/useForm';
import { FormField } from '../FormField/FormField';
import classNames from 'classnames';
import { SignatureInput } from '../SignatureInput/SignatureInput';
import { BranchesSelect } from '../BranchesSelect/BranchesSelect';
import { Branch } from '../../types/branch';
import { RadioButtonGroup } from '../RadioButtonGroup/RadioButtonGroup';
import { SignedDocumentTemplate } from './SignedDocumentTemplate';
import { Divider } from 'primereact/divider';
import { FisiofitGdprChildDocumentPayload } from '../../types/signedDocument';

export interface FisiofitGdprChildForm
  extends Omit<FisiofitGdprChildDocumentPayload, 'consentImages' | 'consentReceiveInformation'> {
  signingLocationBranch?: Branch;
  consentImages?: boolean;
  consentReceiveInformation?: boolean;
  signature?: string;
}

const INITIAL_GDPR_CHILD_CONSENT_FORM: FisiofitGdprChildForm = {
  childName: '',
  parent1Name: '',
  parent1NationalId: '',
  parent2Name: '',
  parent2NationalId: '',
  parent2Signature: '',
};

const getFormValidators = (): FormValidators<FisiofitGdprChildForm> => ({
  childName: (form: DeepPartial<FisiofitGdprChildForm>) => (!form.childName ? 'El nombre del menor es obligatorio' : undefined),
  parent1Name: (form: DeepPartial<FisiofitGdprChildForm>) =>
    !form.parent1Name ? 'El nombre del primer progenitor es obligatorio' : undefined,
  parent1NationalId: (form: DeepPartial<FisiofitGdprChildForm>) =>
    !form.parent1NationalId ? 'El DNI del primer progenitor es obligatorio' : undefined,
  parent2Name: (form: DeepPartial<FisiofitGdprChildForm>) => {
    return (form.parent2NationalId || form.parent2Signature) && !form.parent2Name
      ? 'Si hay un segundo firmante, debes rellenar este campo'
      : undefined;
  },
  parent2NationalId: (form: DeepPartial<FisiofitGdprChildForm>) => {
    return (form.parent2Name || form.parent2Signature) && !form.parent2NationalId
      ? 'Si hay un segundo firmante, debes rellenar este campo'
      : undefined;
  },
  parent2Signature: (form: DeepPartial<FisiofitGdprChildForm>) => {
    return (form.parent2Name || form.parent2NationalId) && !form.parent2Signature
      ? 'Si hay un segundo firmante, debes rellenar este campo'
      : undefined;
  },
  signature: (form: DeepPartial<FisiofitGdprChildForm>) =>
    !form.signature ? 'La firma del primer progenitor es obligatoria' : undefined,
  signingLocationBranch: (form: DeepPartial<FisiofitGdprChildForm>) =>
    !form.signingLocationBranch?.id ? 'Debes elegir la sucursal en la que se firma' : undefined,
  consentImages: (form: DeepPartial<FisiofitGdprChildForm>) =>
    form.consentImages === undefined ? 'Debes indicar si autorizas el uso de imágenes' : undefined,
  consentReceiveInformation: (form: DeepPartial<FisiofitGdprChildForm>) =>
    form.consentReceiveInformation === undefined ? 'Debes indicar si autorizas recibir información' : undefined,
  singleParentResponsibilityDeclaration: (form: DeepPartial<FisiofitGdprChildForm>) => {
    return !form.parent2Name &&
      !form.parent2NationalId &&
      !form.parent2Signature &&
      form.singleParentResponsibilityDeclaration === undefined
      ? 'Si no hay un segundo firmante, debes rellenar este campo'
      : undefined;
  },
});

interface Props {
  visible: boolean;
  onHide: () => void;
  onSave: (data: FisiofitGdprChildDocumentPayload, signature: string, signingLocation: string) => Promise<boolean>;
}

const GdprChildDocumentText = () => (
  <>
    <section>
      <p>
        Nos comprometemos con ustedes a trabajar continuamente para garantizarle la privacidad en el tratamiento de sus datos
        personales y ofrecerles en cada momento la información más completa y clara que podamos. Les animamos a leer detenidamente
        esta sección antes de facilitarnos sus datos personales:
      </p>
    </section>

    <section>
      <ul>
        <li>
          <strong>Responsable:</strong> El responsable del tratamiento es PABLO CASTILLO QUINTANA, CALLE JUAN CARLOS I, 73, 29130,
          ALHAURÍN DE LA TORRE (MÁLAGA).
        </li>
        <li>
          <strong>Finalidad:</strong> Elaborar un historial clínico del menor y un seguimiento posterior, prestarle nuestros
          servicios, enviarles información, responder a sus consultas y peticiones mientras dure nuestra relación y/o tengamos su
          consentimiento.
        </li>
        <li>
          <strong>Legitimación:</strong> Relación contractual, interés legítimo del Responsable, y, en su caso, consentimiento del
          interesado.
        </li>
        <li>
          <strong>Plazo de Supresión:</strong> Los datos se conservarán durante el tiempo necesario para cumplir con la finalidad
          para la que se recabaron y para determinar las posibles responsabilidades que se pudieran derivar de dicha finalidad y
          del tratamiento de los datos, conforme a la Ley 58/2003, de 17 de diciembre, General Tributaria.
        </li>
        <li>
          <strong>Destinatarios:</strong> Los datos solicitados no se cederán a terceros salvo obligación legal.
        </li>
        <li>
          <strong>Derechos:</strong> Pueden ejercer los derechos de acceso, rectificación, supresión y portabilidad de sus datos,
          y la limitación u oposición al tratamiento utilizando nuestro PORTAL DE EJERCICIO DE DERECHOS, en{' '}
          <a href='https://www.adelopd.com/portalderechos/fisio-fit' target='_blank' rel='noreferrer'>
            https://www.adelopd.com/portalderechos/fisio-fit
          </a>
          . En caso de disconformidad con el tratamiento, también tienen derecho a presentar una reclamación ante la Agencia
          Española de Protección de Datos.
        </li>
      </ul>
    </section>

    <section>
      <p>
        La presente hoja informativa la hemos redactado teniendo en cuenta las exigencias de la actual legislación de protección
        de datos:
      </p>
      <ul>
        <li>
          Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las
          personas físicas (RGPD).
        </li>
        <li>
          Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos de Carácter Personal y garantía de los derechos digitales
          (LOPD).
        </li>
      </ul>
    </section>

    <section>
      <p>
        Si se produce alguna modificación de los datos personales, comuníquenoslo, para mantener los datos actualizados, asimismo,
        si nos facilitan datos de terceras personas se obligan a informarle de los presentes tratamientos.
      </p>
    </section>
  </>
);

export const FisiofitGdprChild = ({ visible, onHide, onSave }: Props) => {
  const { form, setFormFields, isSaving, setIsSaving, hasChanged, validate, validationErrors } = useForm<FisiofitGdprChildForm>(
    INITIAL_GDPR_CHILD_CONSENT_FORM,
    !visible,
    getFormValidators(),
  );

  const handleSubmit = async (): Promise<boolean> => {
    const errors = validate();
    const { signature, signingLocationBranch, consentImages, consentReceiveInformation, ...rest } = form ?? {};
    if (
      !form ||
      errors ||
      !signature ||
      !signingLocationBranch?.city ||
      consentImages === undefined ||
      consentReceiveInformation === undefined
    ) {
      return false;
    }
    const payload = { ...rest, consentImages, consentReceiveInformation };
    setIsSaving(true);
    const success = await onSave(payload, signature, signingLocationBranch.city);
    setIsSaving(false);
    return success;
  };

  return (
    <SignedDocumentTemplate
      title='Consentimiento de protección de datos - Menores'
      visible={visible}
      onHide={onHide}
      onSave={handleSubmit}
      isSaving={isSaving}
      hasChanged={hasChanged}
      documentText={<GdprChildDocumentText />}
    >
      <h3>Consentimientos</h3>
      <FormField elementId='consents' fullWidth>
        <div className='consent-options'>
          <div className='consent-option'>
            <label>
              <b>IMÁGENES:</b> Autorizamos a PABLO CASTILLO QUINTANA a utilizar las fotografías y/o videos, en los que aparezca
              nuestra imagen y/o la del menor.
            </label>
            <RadioButtonGroup
              name='consent-images'
              value={form?.consentImages}
              onChange={(value) => setFormFields({ consentImages: value })}
              error={typeof validationErrors?.consentImages === 'string' ? validationErrors.consentImages : undefined}
              options={[
                { id: 'consent-images-yes', value: true, label: 'Sí Autorizamos' },
                { id: 'consent-images-no', value: false, label: 'No Autorizamos' },
              ]}
            />
            <p className='consent-description'>
              Las imágenes podrán ser utilizadas para promocionar a PABLO CASTILLO QUINTANA: En la página web, redes sociales y/o
              en la documentación promocional de PABLO CASTILLO QUINTANA.
            </p>
          </div>

          <div className='consent-option'>
            <label>
              <b>RECIBIR INFORMACIÓN:</b> Autorizamos y consentimos recibir información que PABLO CASTILLO QUINTANA considere de
              nuestro interés por correo electrónico, Apps de mensajería (Whastapp, Telegram...) u otros medios.
            </label>
            <RadioButtonGroup
              name='consent-info'
              value={form?.consentReceiveInformation}
              onChange={(value) => setFormFields({ consentReceiveInformation: value })}
              error={
                typeof validationErrors?.consentReceiveInformation === 'string'
                  ? validationErrors.consentReceiveInformation
                  : undefined
              }
              options={[
                { id: 'consent-info-yes', value: true, label: 'Sí Autorizamos' },
                { id: 'consent-info-no', value: false, label: 'No Autorizamos' },
              ]}
            />
          </div>
        </div>
      </FormField>

      <Divider />

      <h3>Datos del menor</h3>
      <FormField labelTitle='Nombre y apellidos' elementId='childName' required error={validationErrors?.childName}>
        <InputText
          id='childName'
          value={form?.childName}
          onChange={(e) => setFormFields({ childName: e.target.value })}
          placeholder='Nombre completo del menor'
          className={classNames({ 'p-invalid': validationErrors?.childName })}
        />
      </FormField>

      <FormField
        labelTitle='Ubicación de la firma'
        elementId='signing-location-select'
        required
        error={validationErrors?.signingLocationBranch}
      >
        <BranchesSelect
          id='signing-location-select'
          value={form?.signingLocationBranch?.id}
          onChange={(value) => setFormFields({ signingLocationBranch: value })}
          placeholder='Sucursal donde se firma'
          error={validationErrors?.signingLocationBranch}
          isSingle={true}
          onlyAuthUserBranches
        />
      </FormField>

      <Divider />

      <h3>Datos del primer progenitor o tutor legal</h3>
      <FormField labelTitle='Nombre y apellidos' elementId='parent1-name' required error={validationErrors?.parent1Name}>
        <InputText
          id='parent1-name'
          value={form?.parent1Name}
          onChange={(e) => setFormFields({ parent1Name: e.target.value })}
          placeholder='Nombre completo'
          className={classNames({ 'p-invalid': validationErrors?.parent1Name })}
        />
      </FormField>

      <FormField labelTitle='DNI' elementId='parent1-dni' required error={validationErrors?.parent1NationalId}>
        <InputText
          id='parent1-dni'
          value={form?.parent1NationalId}
          onChange={(e) => setFormFields({ parent1NationalId: e.target.value })}
          placeholder='DNI'
          className={classNames({ 'p-invalid': validationErrors?.parent1NationalId })}
        />
      </FormField>

      <SignatureInput
        id='parent1-signature'
        isEditing
        error={validationErrors?.signature}
        onSignatureChange={(signatureImage) => setFormFields({ signature: signatureImage })}
        onClearSignature={() => setFormFields({ signature: undefined })}
        hasChanged={form?.signature !== undefined}
        required={true}
        noteText='Firma del primer progenitor o tutor legal'
      />

      <Divider />

      <h3>Datos del segundo progenitor o tutor legal</h3>
      <FormField labelTitle='Nombre y apellidos' elementId='parent2-name' error={validationErrors?.parent2Name}>
        <InputText
          id='parent2-name'
          value={form?.parent2Name}
          onChange={(e) => setFormFields({ parent2Name: e.target.value })}
          invalid={!!validationErrors?.parent2Name}
          placeholder='Nombre completo'
        />
      </FormField>

      <FormField labelTitle='DNI' elementId='parent2-dni' error={validationErrors?.parent2NationalId}>
        <InputText
          id='parent2-dni'
          value={form?.parent2NationalId}
          onChange={(e) => setFormFields({ parent2NationalId: e.target.value })}
          invalid={!!validationErrors?.parent2NationalId}
          placeholder='DNI'
        />
      </FormField>

      <SignatureInput
        id='parent2-signature'
        isEditing
        error={validationErrors?.parent2Signature}
        onSignatureChange={(signatureImage) => setFormFields({ parent2Signature: signatureImage })}
        onClearSignature={() => setFormFields({ parent2Signature: undefined })}
        hasChanged={form?.parent2Signature !== undefined}
        noteText='Firma del segundo progenitor o tutor legal'
      />

      <Divider />

      {!form.parent2Name && !form.parent2NationalId && !form.parent2Signature && (
        <FormField elementId='responsibility-declaration' fullWidth>
          <div className='consent-options'>
            <div className='consent-option'>
              <label>
                <b>DECLARACIÓN RESPONSABLE:</b> En el caso de no estar presente el otro titular de la patria potestad, el
                progenitor / tutor presente deberá declarar ser la persona responsable legal del menor participante y manifestar
                que el resto de representantes legales, si los hay, están conformes con el tratamiento.
              </label>
              <RadioButtonGroup
                name='responsibility-declaration'
                value={form?.singleParentResponsibilityDeclaration}
                onChange={(value) => setFormFields({ singleParentResponsibilityDeclaration: value })}
                error={
                  typeof validationErrors?.singleParentResponsibilityDeclaration === 'string'
                    ? validationErrors.singleParentResponsibilityDeclaration
                    : undefined
                }
                options={[
                  { id: 'responsibility-yes', value: true, label: 'DECLARO RESPONSABILIDAD' },
                  { id: 'responsibility-no', value: false, label: 'NO DECLARO RESPONSABILIDAD' },
                ]}
              />
            </div>
          </div>
        </FormField>
      )}
    </SignedDocumentTemplate>
  );
};
