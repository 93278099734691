import React, { useRef } from 'react';
import './SignatureInput.scss';
import ReactSignatureCanvas from 'react-signature-canvas';
import { FormField } from '../FormField/FormField';
import { Button } from 'primereact/button';
import { ValidationErrors } from '../../hooks/useForm';
import classNames from 'classnames';

interface Props {
  onSignatureChange: (signatureUrl: string) => void;
  onClearSignature: () => void;
  isEditing: boolean;
  id?: string;
  error?: string | ValidationErrors<any>[] | undefined;
  initialValue?: string;
  hasChanged?: boolean;
  noteText?: string;
  required?: boolean;
}

export const SignatureInput = ({
  error,
  onSignatureChange,
  onClearSignature,
  id = 'signature',
  initialValue,
  hasChanged = false,
  noteText,
  required = false,
  isEditing = false,
}: Props) => {
  const canvasRef = useRef<ReactSignatureCanvas>(null);

  const handleSignatureChange = () => {
    const signatureImage = canvasRef.current?.toDataURL();
    if (signatureImage) {
      onSignatureChange(signatureImage);
    }
  };

  const handleClearSignature: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    canvasRef.current?.clear();
    onClearSignature();
  };

  return (
    <FormField
      className='signature-input'
      labelTitle='Firma'
      elementId={id}
      fullWidth
      required={isEditing && required}
      error={error}
    >
      {noteText && <p className='signature-note'>{noteText}</p>}
      <div className={classNames('signature-section', { 'invalid-form-field': !!error })}>
        <div id={id} className='signature-pad-container'>
          {isEditing ? (
            <ReactSignatureCanvas
              ref={canvasRef}
              canvasProps={{
                className: 'signature-pad',
              }}
              onEnd={handleSignatureChange}
            />
          ) : (
            <img src={initialValue} alt='Firma actual' className='signature-pad signature-image' />
          )}
        </div>
        <div className='signature-actions'>
          {isEditing && <Button key='clear' label='Limpiar' onClick={handleClearSignature} outlined disabled={!hasChanged} />}
        </div>
      </div>
    </FormField>
  );
};
