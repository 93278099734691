import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { useClient } from '../../hooks/useClient';
import { useAuthContext } from '../../context/AuthContext';
import { mutate } from 'swr';
import { resourceDeleter } from '../../utils/resourceMutation';

interface Props {
  notificationId: string;
}

export const DeleteNotificationButton = ({ notificationId }: Props) => {
  const { user } = useAuthContext() ?? {};
  const { delete: del } = useClient();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    await del(`/notifications/${notificationId}`, {
      handlers: {
        defaultSuccess: () => {
          mutate(`/notifications/user/${user?.id}`, resourceDeleter(notificationId), false);
        },
      },
    });
    setIsDeleting(false);
  };

  return (
    <Button
      icon={PrimeIcons.TIMES}
      className='delete-button p-button-text p-button-rounded'
      onClick={handleDelete}
      loading={isDeleting}
    />
  );
};
