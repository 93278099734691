import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

const isNativePlatform = Capacitor.isNativePlatform();

const setItem = async (key: string, value: string): Promise<void> => {
  if (isNativePlatform) {
    await Preferences.set({ key, value });
  } else {
    localStorage.setItem(key, value);
  }
};

const getItem = async (key: string): Promise<string | null> => {
  if (isNativePlatform) {
    const { value } = await Preferences.get({ key });
    return value;
  } else {
    return localStorage.getItem(key);
  }
};

const removeItem = async (key: string): Promise<void> => {
  if (isNativePlatform) {
    await Preferences.remove({ key });
  } else {
    localStorage.removeItem(key);
  }
};

const clear = async (): Promise<void> => {
  if (isNativePlatform) {
    await Preferences.clear();
  } else {
    localStorage.clear();
  }
};

export default { getItem, setItem, removeItem, clear }