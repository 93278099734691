import { useAuthContext } from '../context/AuthContext';
import { useOrganizationContext } from '../context/OrganizationContext';
import { hasFeaturesPermission } from '../utils/featureUtils';

export const useFeaturesToggle = (featuresToCheck: string[]) => {
  const { features, featuresLoading } = useAuthContext() ?? {};
  return hasFeaturesPermission(featuresToCheck, { features, featuresLoading });
};

export const useOrganizationFeaturesToggle = (featuresToCheck: string[]) => {
  const { organization, organizationLoading } = useOrganizationContext() ?? {};
  return hasFeaturesPermission(featuresToCheck, { features: organization?.features, featuresLoading: organizationLoading });
};
