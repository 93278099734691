import React, { FormEvent, ReactNode } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { Alert } from '../Alert/Alert';
import { useAuthContext } from '../../context/AuthContext';
import classNames from 'classnames';
import './SignedDocuments.scss';
import { Divider } from 'primereact/divider';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  visible: boolean;
  onHide: () => void;
  onSave: () => Promise<boolean>;
  isSaving: boolean;
  hasChanged: boolean;
  children: ReactNode;
  documentText?: ReactNode;
  className?: string;
  requiresSignature?: boolean;
  indentText?: boolean;
}

export const SignedDocumentTemplate = ({
  title,
  visible,
  onHide,
  onSave,
  isSaving,
  hasChanged,
  children,
  documentText,
  className,
  requiresSignature = false,
  indentText = false,
}: Props) => {
  const { user } = useAuthContext() ?? {};
  const userHasSignature = user?.signatureUrl && user.nationalId;
  const canProceed = !requiresSignature || userHasSignature;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const success = await onSave();
    if (success) {
      onHide();
    }
  };

  const renderFooter = () => (
    <div className='dialog-footer'>
      <Button label='Cancelar' icon={PrimeIcons.TIMES} onClick={onHide} className='p-button-text' disabled={isSaving} />
      <Button
        label='Firmar'
        icon={PrimeIcons.CHECK}
        onClick={handleSubmit}
        className='p-button-primary'
        disabled={!hasChanged || isSaving}
        loading={isSaving}
      />
    </div>
  );

  const renderContent = () => (
    <div className='signed-document'>
      {documentText && <div className={classNames('signed-document-text', { 'indent-text': indentText })}>{documentText}</div>}
      <Divider />
      <form className={classNames('signed-document-form', { 'form-loading': isSaving })} onSubmit={handleSubmit}>
        {children}
      </form>
    </div>
  );

  return (
    <Dialog
      header={title}
      visible={visible}
      onHide={onHide}
      footer={renderFooter()}
      className={classNames('signed-document-dialog big-modal', className)}
      dismissableMask
      draggable={false}
      resizable={false}
    >
      {canProceed ? (
        renderContent()
      ) : (
        <Alert className='no-signature-alert' severity='error'>
          <span>
            Es necesario configurar tu firma para poder firmar este documento. Puedes configurar tu firma en el{' '}
            <Link to={`/user`} className='no-signature-alert-link'>
              perfil de usuario
            </Link>
            .
          </span>
        </Alert>
      )}
    </Dialog>
  );
};
