import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "./ExerciseList.scss";
import { Avatar } from "primereact/avatar";
import { PrimeIcons } from "primereact/api";
import { Dropdown } from "primereact/dropdown";

interface Exercise {
  id: number;
  name: string;
  weight: string;
  duration: string;
  series: string;
  isEditing: boolean;
}
interface Props {
  showButtons?: boolean;
  exercises?: any[];
}
const ExerciseList = ({ showButtons, exercises }: Props) => {
  const [exercisesState, setExercisesState] = useState<Exercise[]>(exercises ?? []);

  useEffect(() => {
    setExercisesState(exercises ?? []);
  }, [exercises])

  const handleEdit = (id: number) => {
    setExercisesState((prevExercises) =>
      prevExercises.map((exercise) =>
        exercise.id === id
          ? { ...exercise, isEditing: !exercise.isEditing }
          : exercise
      )
    );
  };

  const handleInputChange = (
    id: number,
    field: keyof Exercise,
    value: string
  ) => {
    setExercisesState((prevExercises) =>
      prevExercises.map((exercise) =>
        exercise.id === id ? { ...exercise, [field]: value } : exercise
      )
    );
  };

  const handleDelete = (id: number) => {
    setExercisesState((prevExercises) =>
      prevExercises.filter((exercise) => exercise.id !== id)
    );
  };

  return (
    <div className="exercise-list">
      {exercisesState.map((exercise) => (
        <div key={exercise.id} className="exercise-row">
          {/* Yo con el avatar lo que haria es q si es imagen se muestra, si hay video, se muestra el primer frame del video y si no hay nada, se muestra el modo gris este undefined */}
          <Avatar
            className="exercise-avatar"
            shape="circle"
            image={undefined}
          ></Avatar>
          <div className="exercise-details">
            {exercise.isEditing ? (
              <Dropdown
                value={['Sentadillas', 'Plank']}
                onChange={(e) =>
                  handleInputChange(exercise.id, "weight", e.target.value)
                }
                placeholder="Selecciona un ejercicio"
                className="exercise-input"
              />
            ) : (
              <strong>{exercise.name}</strong>
            )}

            {exercise.isEditing ? (
              <InputText
                value={exercise.weight}
                onChange={(e) =>
                  handleInputChange(exercise.id, "weight", e.target.value)
                }
                className="exercise-input"
              />
            ) : (
              <span>{exercise.weight}</span>
            )}
            {exercise.isEditing ? (
              <InputText
                value={exercise.duration}
                onChange={(e) =>
                  handleInputChange(exercise.id, "duration", e.target.value)
                }
                className="exercise-input"
              />
            ) : (
              <span>{exercise.duration}</span>
            )}
            {exercise.isEditing ? (
              <InputText
                value={exercise.series}
                onChange={(e) =>
                  handleInputChange(exercise.id, "series", e.target.value)
                }
                className="exercise-input"
              />
            ) : (
              <span>{exercise.series}</span>
            )}
          </div>
          {showButtons && (
            <div className="exercise-actions">
              <Button
                label=""
                className="p-button-text p-button-primary"
                text
                icon={exercise.isEditing ? PrimeIcons.SAVE : PrimeIcons.PENCIL}
                onClick={(e) => {
                  e.preventDefault();
                  handleEdit(exercise.id);
                }}
              />
              <Button
                label=""
                className="p-button-text p-button-danger"
                text
                icon={PrimeIcons.TRASH}
                onClick={() => handleDelete(exercise.id)}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ExerciseList;
