import { classNames } from 'primereact/utils';
import React, { ReactNode } from 'react';
import { FormElementError, FormElementErrorPosition } from '../FormElementError/FormElementError';
import { InfoIcon } from '../InfoIcon/InfoIcon';
import { ValidationErrors } from '../../hooks/useForm';

interface Props {
  children?: ReactNode;
  className?: string;
  elementId?: string;
  labelTitle?: ReactNode;
  helper?: ReactNode;
  infoTooltip?: string;
  error?: string | ValidationErrors<any>[];
  errorPosition?: FormElementErrorPosition;
  errorMessageOverride?: string;
  fullWidth?: boolean;
  twoThirds?: boolean;
  required?: boolean;
  smallLabel?: boolean;
  errorAsLabel?: boolean;
  dontShowErrorMessage?: boolean;
}

export const FormField = ({
  className,
  children = <></>,
  elementId: labelFor,
  labelTitle,
  helper,
  infoTooltip,
  error,
  errorPosition,
  errorMessageOverride,
  fullWidth = false,
  twoThirds = false,
  required = false,
  smallLabel = false,
  errorAsLabel = false,
  dontShowErrorMessage = false,
}: Props) => {
  const renderTooltip = () => {
    if (!infoTooltip) return <></>;
    return <InfoIcon content={infoTooltip} />;
  };

  return (
    <div
      className={classNames('form-field', className, {
        'full-width': fullWidth,
        'two-thirds': twoThirds,
        'small-label': smallLabel,
        'error-label': errorAsLabel && !!error,
        'invalid-form-field': !!error,
      })}
    >
      {labelTitle && (
        <label htmlFor={labelFor}>
          {labelTitle}
          {required && <span className='required-indicator'>*</span>}
          {renderTooltip()}
        </label>
      )}
      {children}
      {!dontShowErrorMessage && (
        <FormElementError messageOverride={errorMessageOverride} position={errorPosition}>
          {typeof error === 'string' && !errorAsLabel ? error : null}
        </FormElementError>
      )}
      {helper && (typeof helper === 'string' ? <small className='helper-text'>{helper}</small> : helper)}
    </div>
  );
};
