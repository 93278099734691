import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { LOCALES } from '../config/locales';
import * as dateFnsLocales from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';

export const DEFAULT_LOCALE = LOCALES.ES;

const initialLocaleContextValue: LocaleContextValue = {
  locale: DEFAULT_LOCALE,
  setLocale: () => {},
};

interface LocaleContextValue {
  locale: LOCALES;
  setLocale: (locale: LOCALES) => void;
}

const LocaleContext = createContext<LocaleContextValue>(initialLocaleContextValue);

export const LocaleProvider = ({ children }: PropsWithChildren) => {
  // TODO get available locales and initial value from user config
  const [locale, setLocale] = useState(DEFAULT_LOCALE);

  useEffect(() => {
    const dateFnsLocale = dateFnsLocales[locale as keyof typeof dateFnsLocales];
    if (dateFnsLocale) {
      setDefaultOptions({ locale: dateFnsLocale });
    }
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocaleContext = () => {
  return useContext(LocaleContext);
};
