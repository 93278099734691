import React, { useMemo } from 'react';
import { User } from '../../types/user';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { getHashMap } from '../../utils/objectTransformers';
import { ValidationErrors } from '../../hooks/useForm';
import { UserDisplay } from '../UserDisplay/UserDisplay';
import { AdaptiveSelect } from '../AdaptiveSelect/AdaptiveSelect';
import useSWR from 'swr';
import { useClient } from '../../hooks/useClient';
import { classNames } from 'primereact/utils';
import { trim } from '../../utils/textUtils';

interface UserOption extends User {
  label: string;
  value: string;
}

interface BaseProps {
  onChange: ((users: User[]) => void) | ((user: User | undefined) => void);
  value?: string[] | string;
  id?: string;
  error?: string | (ValidationErrors<User> | undefined)[];
  className?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  isSingle: boolean;
  feature?: string;
  filterByBranchIds?: string[];
  disabled?: boolean;
}

interface MultiProps extends BaseProps {
  isSingle: false;
  onChange: (users: User[]) => void;
  value?: string[];
}

interface SingleSelectProps extends BaseProps {
  isSingle: true;
  onChange: (user: User | undefined) => void;
  value?: string;
}

type Props = MultiProps | SingleSelectProps;

const getUsersUrl = (organizationId: string, params: { feature?: string; branches?: string }) => {
  const queryParams = Object.entries(params)
    .filter(([_, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return `/organizations/${organizationId}/users${queryParams ? `?${queryParams}` : ''}`;
};

export const UsersSelect = ({
  onChange,
  id = 'users-dropdown',
  value,
  error,
  className,
  placeholder = 'Elige empleados',
  searchPlaceholder = 'Buscar empleados',
  isSingle,
  feature,
  filterByBranchIds,
  disabled = false,
}: Props) => {
  const { get } = useClient();
  const { organization } = useOrganizationContext() ?? {};

  const { data: users, isLoading } = useSWR(
    organization ? getUsersUrl(organization.id, { feature, branches: filterByBranchIds?.join(',') }) : null,
    get<User[]>,
  );

  const options = useMemo(() => {
    if (!users) return [];
    return users.map((user: User) => ({
      ...user,
      label: `${trim(user.name)} ${trim(user.surnames)}`,
      value: user.id,
    }));
  }, [users]);

  const usersHashMap = useMemo(() => getHashMap(users ?? [], 'id'), [users]);

  const handleChange = (selectedValue: string | string[]) => {
    if (isSingle) {
      const newUser = selectedValue ? usersHashMap[selectedValue as string] : undefined;
      (onChange as (user: User | undefined) => void)(newUser);
    } else {
      const newUsers: User[] = (selectedValue as string[])
        .map((userId) => usersHashMap[userId])
        .filter((user): user is User => user !== undefined);
      (onChange as (users: User[]) => void)(newUsers);
    }
  };

  const userTemplate = (option: UserOption | null) => {
    if (!option) return null;
    return <UserDisplay fullName={option.label} avatarUrl={option.avatarUrl} />;
  };

  return (
    <AdaptiveSelect
      id={id}
      placeholder={placeholder}
      value={value}
      className={classNames(className, { 'p-invalid': error })}
      invalid={!!error}
      options={options}
      itemTemplate={userTemplate}
      onChange={handleChange}
      modalTitle={isSingle ? 'Seleccionar empleado' : 'Seleccionar empleados'}
      searchPlaceholder={searchPlaceholder}
      loading={isLoading}
      disabled={disabled}
      isMultiple={!isSingle}
      emptyMessage='No hay empleados'
      emptyFilterMessage='No se han encontrado empleados'
    />
  );
};
