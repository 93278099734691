import React, { PropsWithChildren, createContext, useContext } from 'react';
import { Organization } from '../types/organization';
import useSWR, { KeyedMutator } from 'swr';
import { useClient } from '../hooks/useClient';
import { useAuthContext } from './AuthContext';
import { Branch } from '../types/branch';
import { Role } from '../types/role';

interface OrganizationContextValue {
  organization: Organization | undefined;
  organizationLoading: boolean;
  orgBranches: Branch[] | undefined;
  orgRoles: Role[] | undefined;
  mutateOrganization: KeyedMutator<Organization | undefined>;
}

const OrganizationContext = createContext<OrganizationContextValue | null>(null);

export const OrganizationProvider = ({ children }: PropsWithChildren) => {
  const { get } = useClient();
  const { user, userLoading } = useAuthContext() ?? {};

  const {
    data: organization,
    isLoading: organizationLoading,
    mutate: mutateOrganization,
  } = useSWR(user ? `/organizations/${user.organizationId}` : null, get<Organization>);

  return (
    <OrganizationContext.Provider
      value={{
        mutateOrganization,
        organization,
        organizationLoading: organizationLoading || !!userLoading,
        orgBranches: organization?.branches,
        orgRoles: organization?.roles,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganizationContext = () => {
  return useContext(OrganizationContext);
};
