const css = `.routine-panel .add-exercise-button {
  margin-left: auto;
}
.routine-panel .routine-panel-bottom-buttons {
  display: flex;
  gap: 16px;
  margin-left: auto;
}
.routine-panel .user-using-routine {
  display: flex;
  gap: 16px;
  align-items: center;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvUm91dGluZVBhbmVsIiwic291cmNlcyI6WyJSb3V0aW5lUGFuZWwuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFSTtFQUNJOztBQUdKO0VBQ0k7RUFDQTtFQUNBOztBQUVKO0VBQ0k7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLnJvdXRpbmUtcGFuZWwge1xuXG4gICAgLmFkZC1leGVyY2lzZS1idXR0b257XG4gICAgICAgIG1hcmdpbi1sZWZ0OiBhdXRvO1xuICAgIH1cblxuICAgIC5yb3V0aW5lLXBhbmVsLWJvdHRvbS1idXR0b25zIHtcbiAgICAgICAgZGlzcGxheTogZmxleDtcbiAgICAgICAgZ2FwOiAxNnB4O1xuICAgICAgICBtYXJnaW4tbGVmdDogYXV0bztcbiAgICB9XG4gICAgLnVzZXItdXNpbmctcm91dGluZSB7XG4gICAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICAgIGdhcDogMTZweDtcbiAgICAgICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICB9XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
