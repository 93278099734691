import React from 'react';

import { createRoot } from 'react-dom/client';
import App from './App';
import './styles/styles.scss';
import './styles/flags.scss';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
