import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { User } from '../types/user';
import { useClient } from '../hooks/useClient';
import useSWR from 'swr';
import { Role } from '../types/role';
import { Feature } from '../config/features';
import storage from '../storage/storage';

export const ACCESS_TOKEN_STORAGE_KEY = 'accessToken';
export const USER_ID_STORAGE_KEY = 'userId';

interface LoginResponse {
  name: string;
  avatarUrl?: string;
  id: string;
  token: string;
  roles: string[];
}

interface AuthContextValue {
  signOut: () => Promise<void>;
  user: User | undefined;
  userLoading: boolean;
  login: (email: string, password: string) => Promise<void>;
  updateUser: (user: Partial<User>, avatarFile?: File) => Promise<User | undefined>;
  isExpectedLoggedIn: boolean;
  features: Feature[];
  featuresLoading: boolean;
}
const AuthContext = createContext<AuthContextValue | null>(null);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [accessTokenLoading, setAccessTokenLoading] = useState<boolean>(true);

  const { get, post, patch } = useClient();

  useEffect(() => {
    setAccessTokenLoading(true);
    storage.getItem(USER_ID_STORAGE_KEY).then((session) => setSessionId(session));
    storage.getItem(ACCESS_TOKEN_STORAGE_KEY).then((token) => {
      setAccessToken(token);
      setAccessTokenLoading(false);
    });
  }, []);

  const fetchUser = async ({ sessionIdForUser }: { sessionIdForUser: string }): Promise<User | undefined> => {
    const user = await get<User>(`/users/${sessionIdForUser}`);
    if (!user) {
      await storage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
      await storage.removeItem(USER_ID_STORAGE_KEY);
      setSessionId(null);
    }
    return user;
  };
  const {
    data: user,
    isLoading: userLoading,
    error: userError,
    mutate: mutateUser,
  } = useSWR(sessionId ? { sessionIdForUser: sessionId } : null, fetchUser);
  const setUser = (newUser: User | undefined) => {
    mutateUser(newUser, false);
  };

  const fetchFeatures = async ({ sessionIdForRole }: { sessionIdForRole: string }): Promise<Feature[] | undefined> => {
    const userRole = await get<Role>(`/users/${sessionIdForRole}/role`);
    return userRole?.features;
  };
  const {
    data: features = [],
    isLoading: featuresLoading,
    error: featuresError,
    mutate: mutateFeatures,
  } = useSWR(sessionId ? { sessionIdForRole: sessionId } : null, fetchFeatures);
  const setUserFeatures = (newFeatures: Feature[]) => {
    mutateFeatures(newFeatures, false);
  };

  const login = async (email: string, password: string) => {
    const loginResponse = await post<LoginResponse>('/login', {
      body: {
        email,
        password,
      },
      errorMessages: {
        summary: 'Error en el inicio de sesión',
        defaultDetail: 'No se ha podido iniciar la sesión',
        401: 'Los datos introducidos no son válidos',
      },
    });
    const { id, token, ...rest } = loginResponse ?? {};
    if (id && token) {
      await storage.setItem(USER_ID_STORAGE_KEY, id);
      await storage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
      setSessionId(id);
    }
  };

  const updateUser = async (userData: Partial<User>, avatarFile?: File) => {
    const newUser: Partial<User> = { ...userData };
    delete newUser.id;
    const formData = new FormData();
    formData.append('data', JSON.stringify(newUser));
    if (avatarFile) {
      formData.append('image', avatarFile);
    }

    const updatedUser = await patch<User>(`/users/${userData?.id}`, {
      body: formData,
      errorMessages: {
        summary: 'Error al editar perfil',
        defaultDetail: 'No se ha podido editar el perfil de usuario',
      },
      successMessage: {
        summary: 'Perfil editado',
        detail: 'Se ha editado correctamente el perfil de usuario',
      },
    });

    if (updatedUser) {
      await storage.setItem(USER_ID_STORAGE_KEY, updatedUser.id);
      setUser(updatedUser);
      setSessionId(updatedUser.id);
      return updatedUser;
    }
  };

  const signOut = async () => {
    try {
      await storage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
      await storage.removeItem(USER_ID_STORAGE_KEY);
      setUser(undefined);
      setSessionId(null);
    } catch {}
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        signOut,
        user,
        userLoading: userLoading || accessTokenLoading,
        isExpectedLoggedIn: (userLoading && !!accessToken) || accessTokenLoading,
        updateUser,
        features,
        featuresLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
