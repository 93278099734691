import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { FormEvent } from 'react';
import { NoAuthPageTemplate } from '../../layout/NoAuthPageTemplate/NoAuthPageTemplate';
import { useForm } from '../../hooks/useForm';
import { LinkButton } from '../../components/LinkButton/LinkButton';
import { useClient } from '../../hooks/useClient';
import { classNames } from 'primereact/utils';
import { PASSWORD_RECOVERY_VALIDATORS } from './validators';
import { FormElementError } from '../../components/FormElementError/FormElementError';

const INITIAL_PASSWORD_RECOVERY_FORM: PasswordRecoveryForm = {
  email: '',
};

export interface PasswordRecoveryForm {
  email: string;
}

export const PasswordRecovery = () => {
  const { post } = useClient();
  const {
    form,
    setForm,
    setFormField,
    isSaving,
    setIsSaving,
    hasChanged,
    validationErrors,
    validate,
  } = useForm<PasswordRecoveryForm>(
    INITIAL_PASSWORD_RECOVERY_FORM,
    true,
    PASSWORD_RECOVERY_VALIDATORS
  );

  const recoverPassword = async () => {
    await post(`/password-recovery`, {
      body: form,
      errorMessages: {
        summary: 'Error al recuperar cuenta',
        defaultDetail: 'No se ha podido enviar un enlace de recuperación.',
        [404]: 'No se ha encontrado un usuario con el correo introducido.',
        [409]: 'Este correo ya ha recibido un enlace de recuperación recientemente.',
      },
      successMessage: {
        summary: 'Recuperar cuenta',
        detail: 'Enlace de recuperación enviado. Revisa tu bandeja de entrada.',
      },
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const newErrors = validate();
    if (!form || newErrors) return;
    setIsSaving(true);
    await recoverPassword();
    setIsSaving(false);
    setForm(INITIAL_PASSWORD_RECOVERY_FORM);
  };

  return (
    <NoAuthPageTemplate>
      <h2>Recuperar contraseña</h2>
      <p>
        Introduce el correo electrónico asociado a tu cuenta y te enviaremos un correo con un enlace
        de recuperación. El correo expirará en una hora.
      </p>

      <form
        className={classNames({
          'form-loading': isSaving,
        })}
        onSubmit={handleSubmit}
      >
        <div className='form-field gap-bottom'>
          <label htmlFor='email'>Email</label>
          <InputText
            placeholder='Dirección de correo electrónico'
            id='email'
            name='email'
            value={form?.email ?? ''}
            autoComplete='email'
            onChange={(e) => setFormField({ email: e.target.value })}
            className={classNames({ 'p-invalid': validationErrors?.email })}
          />
          <FormElementError>{validationErrors?.email}</FormElementError>
        </div>
        <Button className='large' type='submit' loading={isSaving} disabled={!hasChanged}>
          Enviar enlace de recuperación
        </Button>
        <LinkButton to='/' icon='pi pi-arrow-left'>
          Volver a inicio de sesión
        </LinkButton>
      </form>
    </NoAuthPageTemplate>
  );
};
