import React, { useMemo } from 'react';
import './UserDisplay.scss';
import { Avatar } from 'primereact/avatar';
import { getUserAvatarProps } from '../../utils/avatarUtils';
import { Button, ButtonProps } from 'primereact/button';
import classNames from 'classnames';
import { useNavigateScroll } from '../../hooks/useNavigateScroll';

interface BaseProps {
  avatarUrl?: string;
  name?: string;
  surnames?: string;
  fullName?: string;
  buttonProps?: ButtonProps;
  isPlaceholder?: boolean;
  displayReversed?: boolean;
  bookedFromApp?: boolean;
  navigateTo?: string;
}
interface FullNameProps extends BaseProps {
  fullName: string | undefined;
  name?: never;
  surnames?: never;
}
interface NameSurnamesProps extends BaseProps {
  name: string | undefined;
  surnames: string | undefined;
  fullName?: never;
}

type Props = FullNameProps | NameSurnamesProps;

export const UserDisplay = ({
  avatarUrl,
  name,
  surnames,
  fullName,
  buttonProps,
  isPlaceholder,
  displayReversed,
  bookedFromApp,
  navigateTo,
}: Props) => {
  const navigate = useNavigateScroll();
  const [inferredName, inferredSurnames] = fullName?.split(' ') || [];

  const userAvatarProps = useMemo(
    () =>
      getUserAvatarProps({
        name: name || inferredName,
        surnames: surnames || inferredSurnames,
        avatarUrl,
        isPlaceholder,
      }),
    [name, surnames, inferredName, inferredSurnames, avatarUrl, isPlaceholder],
  );

  const handleClick = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  return (
    <div
      className={classNames('user-display align-center half-gap', {
        'flex-reverse': displayReversed,
        clickable: navigateTo,
      })}
      {...(navigateTo && { onClick: handleClick })}
    >
      <Avatar shape='circle' {...userAvatarProps} />
      <p>
        {fullName || `${name} ${surnames}`}
        {bookedFromApp ? ' (app)' : ''}
      </p>
      {buttonProps && <Button {...buttonProps} />}
    </div>
  );
};
