import React, { useState, useEffect } from 'react';
import { format, addMinutes, isBefore, isEqual } from 'date-fns';
import { Button } from 'primereact/button';
import './TimeSlotPicker.scss';

export interface TimeSlot {
    startSession: Date;
    endSession: Date;
}

export interface TimeSlotPickerProps {
    startDate: Date;
    endDate: Date;
    sessionDuration: number;
    usedSessions: TimeSlot[];
    onChange: (slot: TimeSlot) => void;
}

const TimeSlotPicker = ({ startDate, endDate, sessionDuration, usedSessions, onChange }: TimeSlotPickerProps) => {
    const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
    const [selectedSlot, setSelectedSlot] = useState<TimeSlot | null>(null);

    useEffect(() => {
        const generateTimeSlots = () => {
            const slots = [];
            let current = startDate;

            while (isBefore(current, endDate) || isEqual(current, endDate)) {
                const endSession = addMinutes(current, sessionDuration);
                if (!usedSessions.some((used) => 
                    isEqual(current, used.startSession) && isEqual(endSession, used.endSession))) {
                    slots.push({
                        startSession: current,
                        endSession,
                    });
                }
                current = addMinutes(current, sessionDuration);
            }

            setTimeSlots(slots);
        };

        generateTimeSlots();
    }, [startDate, endDate, sessionDuration, usedSessions]);

    const handleSlotClick = (e: Event, slot: TimeSlot) => {
        e.preventDefault();
        setSelectedSlot(slot);
        onChange(slot);
    };

    return (
        <div className='time-slot-picker'>
            <div className='time-slot-buttons'>
                {timeSlots.map((slot, index) => (
                    <Button
                        key={index}
                        rounded
                        label={format(slot.startSession, 'HH:mm')}
                        className={selectedSlot === slot ? 'p-button-primary' : 'p-button-outlined'}
                        onClick={(e) => handleSlotClick(e as any, slot)}
                    />
                ))}
            </div>
        </div>
    );
};

export default TimeSlotPicker;
