import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import BusinessHoursSection from '../../components/BusinessHoursSection/BusinessHoursSection';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { Card } from 'primereact/card';
import './Settings.scss';
import { FormField } from '../../components/FormField/FormField';
import { InputText } from 'primereact/inputtext';
import { PrimeIcons } from 'primereact/api';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { Branch } from '../../types/branch';
import useSWR from 'swr';
import { useForm } from '../../hooks/useForm';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { getBranchOptions } from '../../utils/selectOptions';
import { Organization } from '../../types/organization';
import { ReservationTypeWithColor, useReservationTypes } from '../../hooks/DataFetching/useReservationTypes';
import { ReservationHours } from '../../types/reservationType';
import { Password } from 'primereact/password';

interface ReservationHoursForm {
  [key: string]: ReservationHours;
}

interface PromotionSettingsForm {
  promotionsEmail?: string;
  promotionsEmailPassword?: string;
}

const getInitialReservationHoursForm = (
  branchReservationTypes: ReservationTypeWithColor[] | undefined,
): ReservationHoursForm | undefined => {
  return branchReservationTypes?.reduce<ReservationHoursForm>((acc, reservationType) => {
    acc[reservationType.id] = reservationType.hours;
    return acc;
  }, {});
};

const getInitialPromotionSettingsForm = (org?: Organization): PromotionSettingsForm => {
  const { promotionsEmail, promotionsEmailPassword } = org ?? {};
  return {
    promotionsEmail,
    promotionsEmailPassword,
  };
};

const getBranchFromId = (id: string | undefined, branches: Branch[] | undefined) => {
  if (!id || !branches) return;
  return branches.find((_) => _.id === id);
};

const getBranchReservationTypes = (branchId: string | undefined, reservationTypes: ReservationTypeWithColor[] | undefined) => {
  if (!branchId) return;
  return reservationTypes?.filter((_) => _.branchId === branchId);
};

export const Settings = () => {
  const { get, patch } = useClient();
  const { organization, organizationLoading, setOrganization } = useOrganizationContext() ?? {};
  const [selectedBranchId, setSelectedBranchId] = useState<string>();

  const {
    data: orgBranches,
    isLoading: orgBranchesLoading,
    error: branchesError,
  } = useSWR(organization ? `/organizations/${organization.id}/branches` : null, get<Branch[]>);

  const { reservationTypes, mutateReservationTypes } = useReservationTypes();
  const branchReservationTypes = useMemo(() => {
    return getBranchReservationTypes(selectedBranchId, reservationTypes);
  }, [selectedBranchId, reservationTypes]);

  useEffect(() => {
    if (!selectedBranchId && reservationTypes && orgBranches?.length) {
      const firstBranchWithReservationTypes = orgBranches.find((_) => getBranchReservationTypes(_.id, reservationTypes)?.length);
      setSelectedBranchId(firstBranchWithReservationTypes?.id);
    }
  }, [orgBranches, selectedBranchId, reservationTypes]);

  const initialReservationHoursForm = useMemo(
    () => getInitialReservationHoursForm(branchReservationTypes),
    [branchReservationTypes],
  );
  const {
    form: reservationHours,
    setFormField: setReservationHoursField,
    isSaving: reservationHoursSaving,
    setIsSaving: setReservationHoursSaving,
    hasChanged: reservationHoursChanged,
    validationErrors: reservationHoursErrors,
  } = useForm<ReservationHoursForm | undefined>(initialReservationHoursForm, false);

  const handleSaveReservationHours = async (e: FormEvent) => {
    e.preventDefault();
    const selectedBranch = getBranchFromId(selectedBranchId, orgBranches);
    if (!reservationHours || !selectedBranchId || !selectedBranch || !branchReservationTypes) return;
    setReservationHoursSaving(true);

    const updatedReservationTypes = branchReservationTypes.map((reservationType) => {
      const { color, branchId, ...rest } = reservationType;
      return {
        ...rest,
        hours: reservationHours[reservationType.id],
      };
    });

    await patch(`/branches/${selectedBranchId}/reservation-types`, {
      body: { updatedReservationTypes },
      errorMessages: {
        summary: 'Error al cambiar horario',
        defaultDetail: 'No se ha podido modificar el horario de apertura',
      },
      successMessage: {
        summary: 'Horario editado',
        detail: 'El horario de apertura se ha modificado correctamente',
      },
      handlers: {
        defaultSuccess: () => mutateReservationTypes(),
      },
    });

    setReservationHoursSaving(false);
  };

  const initialPromotionSettingsForm = useMemo(() => getInitialPromotionSettingsForm(organization), [organization]);
  const {
    form: promotionSettings,
    setFormField: setPromotionSettingsField,
    isSaving: promotionSettingsSaving,
    setIsSaving: setPromotionSettingsSaving,
    hasChanged: promotionSettingsChanged,
    validationErrors: promotionSettingsErrors,
  } = useForm<PromotionSettingsForm | undefined>(initialPromotionSettingsForm, false);

  const handlePromotionSettingsSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!promotionSettings || !organization) return;
    setPromotionSettingsSaving(true);

    const updatedOrg = await patch<Organization>(`/organizations/${organization.id}`, {
      body: { ...promotionSettings },
      errorMessages: {
        summary: 'Error al configurar promociones',
        defaultDetail: 'No se ha podido modificar la configuración de promociones',
      },
      successMessage: {
        summary: 'Configuración de promociones modificada',
        detail: 'La configuración de promociones se ha modificado correctamente',
      },
    });

    if (updatedOrg) {
      setOrganization?.(updatedOrg);
    }
    setPromotionSettingsSaving(false);
  };

  return (
    <PageTemplate className='settings-page' title='Configuración'>
      <Card>
        <form
          className={classNames({
            'form-loading': organizationLoading || orgBranchesLoading || reservationHoursSaving,
          })}
          onSubmit={handleSaveReservationHours}
        >
          <FormField fullWidth>
            <h4>Horarios de reservas</h4>
          </FormField>
          <FormField labelTitle='Sucursal'>
            <Dropdown
              options={getBranchOptions(orgBranches)}
              value={selectedBranchId}
              onChange={(e) => setSelectedBranchId(e.value)}
              placeholder={'Elige una sucursal'}
            />
          </FormField>
          <FormField />
          {branchReservationTypes?.map((reservationType) => (
            <FormField key={reservationType.id}>
              <BusinessHoursSection
                onChange={(value) => setReservationHoursField({ [reservationType.id]: value })}
                value={reservationHours?.[reservationType.id]}
                title={reservationType.name ?? 'individuales'}
              />
            </FormField>
          ))}
          <Button
            type='submit'
            disabled={!reservationHoursChanged}
            className='pull-right pull-down'
            label='Guardar'
            icon={PrimeIcons.SAVE}
          />
        </form>
      </Card>
      <Card>
        <form
          className={classNames({
            'form-loading': organizationLoading || promotionSettingsSaving,
          })}
          onSubmit={handlePromotionSettingsSubmit}
        >
          <FormField fullWidth>
            <h4>Configuración de promociones</h4>
          </FormField>
          <FormField elementId='email' labelTitle='Correo electrónico para promociones'>
            <InputText
              placeholder='Correo electrónico para promociones'
              id='email'
              value={promotionSettings?.promotionsEmail ?? ''}
              autoComplete='email'
              onChange={(e) => setPromotionSettingsField({ promotionsEmail: e.target.value })}
            />
          </FormField>
          <FormField elementId='email-server-password' labelTitle='Contraseña del servidor de correo' infoTooltip='TODO'>
            <Password
              id='email-server-password'
              toggleMask
              feedback={false}
              placeholder='Contraseña del servidor de correo'
              value={promotionSettings?.promotionsEmailPassword ?? ''}
              onChange={(e) =>
                setPromotionSettingsField({
                  promotionsEmailPassword: e.target.value,
                })
              }
            />
          </FormField>
          <Button
            type='submit'
            disabled={!promotionSettingsChanged}
            className='pull-right pull-down'
            label='Guardar'
            icon={PrimeIcons.SAVE}
          />
        </form>
      </Card>
    </PageTemplate>
  );
};
