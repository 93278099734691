import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { BranchDisplay } from '../../types/branch';
import { ExternalUserShort } from '../../types/externalUser';
import { CANCELED_BY_OPTIONS, CancellationReason } from '../../types/reservation';
import { ReservationType } from '../../types/reservationType';
import { UserShort } from '../../types/user';
import { validatePhone } from '../../utils/validationUtils';
import { ReservationEvent } from './ReservationPanel';

const { NOT_BRANCH, NOT_START_DATE, NOT_END_DATE, NOT_PRICE, INVALID_PHONE } = VALIDATION_MESSAGES;

export interface ReservationForm {
  selectedDay?: Date;

  localStartDate?: string;
  localEndDate?: string;

  price?: number;
  comment?: string;

  reservationTypeId?: string;
  user?: UserShort;
  externalUsers?: ExternalUserShort[];
  confirmedExternalUsers?: ExternalUserShort[];
  queuedExternalUsers?: ExternalUserShort[];
  branch?: BranchDisplay;

  couponId?: string;
  useCoupon?: boolean;

  // Campos para clientes no registrados
  unregisteredClientName?: string;
  unregisteredClientSurnames?: string;
  unregisteredClientPhone?: string;

  usersBeforeSave?: ExternalUserShort[];
  attendanceData?: Record<string, boolean>;
}

export const getReservationFormValidators = (
  reservationType: ReservationType | undefined,
  initialTemporaryEvent: ReservationEvent | undefined,
): FormValidators<ReservationForm> => {
  const { priceRequired, hasAssignedUsers, hasUnregisteredClients } = reservationType ?? {};
  return {
    user: (reservation) => {
      if (!reservation.user && hasAssignedUsers) return 'Debes seleccionar un empleado';
    },
    externalUsers: (reservation) => {
      if (
        !hasUnregisteredClients &&
        (!reservation.externalUsers || !reservation.externalUsers.length) &&
        (!reservation.usersBeforeSave || !reservation.usersBeforeSave.length) &&
        (!reservation.confirmedExternalUsers || !reservation.confirmedExternalUsers.length)
      ) {
        if (reservationType && reservationType?.groupSize > 1) {
          return 'Debes elegir al menos un cliente';
        }
        return 'Debes elegir un cliente';
      }
    },
    unregisteredClientName: (reservation) => {
      if (hasUnregisteredClients && !reservation.unregisteredClientName) return 'Debes introducir el nombre del cliente';
    },
    unregisteredClientSurnames: (reservation) => {
      if (hasUnregisteredClients && !reservation.unregisteredClientSurnames) return 'Debes introducir los apellidos del cliente';
    },
    branch: (reservation) => {
      if (!reservation.branch) return NOT_BRANCH;
    },
    localStartDate: (reservation) => {
      if (!reservation.localStartDate && !initialTemporaryEvent) return NOT_START_DATE;
    },
    localEndDate: (reservation) => {
      if (!reservation.localEndDate && !initialTemporaryEvent) return NOT_END_DATE;
    },
    selectedDay: (reservation) => {
      if (!reservation.selectedDay) return 'Debes seleccionar una fecha';
    },
    price: (reservation) => {
      if (
        priceRequired &&
        (hasUnregisteredClients ||
          (!hasUnregisteredClients && reservation.externalUsers && reservation.externalUsers.length > 0)) &&
        !reservation.price &&
        reservation.price !== 0
      )
        return NOT_PRICE;
    },
    unregisteredClientPhone: (reservation) => {
      if (hasUnregisteredClients && reservation.unregisteredClientPhone && !validatePhone(reservation.unregisteredClientPhone))
        return INVALID_PHONE;
    },
  };
};

export interface CancellationForm {
  canceledBy?: CANCELED_BY_OPTIONS;
  cancellationReason?: CancellationReason;
  customCancellationReason?: string;
}

export const getCancellationFormValidators = (
  isCancelingGroupWithMultipleUsers: boolean = false,
): FormValidators<CancellationForm> => {
  return {
    canceledBy: (form) => {
      if (!form.canceledBy && !isCancelingGroupWithMultipleUsers) return 'Debes seleccionar quién cancela la reserva';
    },
    cancellationReason: (form) => {
      if (form.canceledBy === CANCELED_BY_OPTIONS.CANCELED_BY_USER && !form.cancellationReason) {
        return 'Debes seleccionar un motivo de cancelación';
      }
    },
    customCancellationReason: (form) => {
      if (
        form.cancellationReason === CancellationReason.OTHER &&
        form.canceledBy === CANCELED_BY_OPTIONS.CANCELED_BY_USER &&
        !form.customCancellationReason
      ) {
        return 'Debes especificar el motivo de cancelación';
      }
    },
  };
};

export const getInitialCancellationForm = (isCancelingGroupWithMultipleUsers: boolean): CancellationForm => {
  return {
    canceledBy: isCancelingGroupWithMultipleUsers ? CANCELED_BY_OPTIONS.CANCELED_BY_ADMIN : undefined,
    cancellationReason: isCancelingGroupWithMultipleUsers ? CancellationReason.OTHER : undefined,
  };
};
