const css = `.edit-external-user-modal {
  width: 90vw;
  max-width: 800px;
}

@media (max-width: 768px) {
  .edit-external-user-modal {
    width: 100vw;
    height: 100vh;
    margin: 0;
  }
  .edit-external-user-modal .edit-external-user-form {
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 0 8px;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvRWRpdEV4dGVybmFsVXNlck1vZGFsIiwic291cmNlcyI6WyJFZGl0RXh0ZXJuYWxVc2VyTW9kYWwuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7SUFDRTtJQUNBO0lBQ0E7O0VBRUE7SUFDRTtJQUNBO0lBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuZWRpdC1leHRlcm5hbC11c2VyLW1vZGFsIHtcbiAgd2lkdGg6IDkwdnc7XG4gIG1heC13aWR0aDogODAwcHg7XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA3NjhweCkge1xuICAuZWRpdC1leHRlcm5hbC11c2VyLW1vZGFsIHtcbiAgICB3aWR0aDogMTAwdnc7XG4gICAgaGVpZ2h0OiAxMDB2aDtcbiAgICBtYXJnaW46IDA7XG5cbiAgICAuZWRpdC1leHRlcm5hbC11c2VyLWZvcm0ge1xuICAgICAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiAxZnI7XG4gICAgICBnYXA6IDE2cHg7XG4gICAgICBwYWRkaW5nOiAwIDhweDtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
