import React from 'react';
import { useNavigateScroll } from '../../hooks/useNavigateScroll';
import { Button, ButtonProps } from 'primereact/button';

interface Props extends ButtonProps {
  to: string;
  iconBadge?: string;
}

export const LinkButton = ({ to, onClick, iconBadge, ...restProps }: Props) => {
  const navigate = useNavigateScroll();

  return (
    <Button
      {...restProps}
      link
      onClick={(e) => {
        onClick?.(e);
        navigate(to);
      }}
      badge={iconBadge}
    ></Button>
  );
};
