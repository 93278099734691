import React, { useEffect, useRef, useState } from 'react';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { useAuthContext } from '../../context/AuthContext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Calendar } from 'primereact/calendar';
import { PrimeIcons } from 'primereact/api';
import { Password } from 'primereact/password';
import './Profile.scss';
import { UpdatePassword, User } from '../../types/user';
import { useForm } from '../../hooks/useForm';
import { classNames } from 'primereact/utils';
import { Skeleton } from 'primereact/skeleton';
import { subYears } from 'date-fns';
import { useClient } from '../../hooks/useClient';
import { PASSWORD_FORM_VALIDATORS, USER_FORM_VALIDATORS } from './validators';
import { FormElementError } from '../../components/FormElementError/FormElementError';
import { AVATAR_PLACEHOLDER_URL } from '../../const';

const INITIAL_PASSWORD_FORM: UpdatePassword = {
  newPassword: '',
  oldPassword: '',
};

export const Profile = () => {
  const { patch } = useClient();

  const { user, userLoading, updateUser } = useAuthContext() ?? {};
  const [avatarFile, setAvatarFile] = useState<File>();
  const fileInputRef = useRef(null);
  const {
    form: userForm,
    setForm: setUserForm,
    setFormField: setUserField,
    isSaving: userSaving,
    setIsSaving: setIsUserSaving,
    hasChanged: userChanged,
    validationErrors: userValidationErrors,
    validate: validateUser,
  } = useForm<User | undefined>(user, false, USER_FORM_VALIDATORS);

  const passwordFormRef = useRef(null);
  const {
    form: { newPassword, oldPassword },
    setForm: setPasswordForm,
    setFormField: setPasswordField,
    isSaving: passwordSaving,
    setIsSaving: setIsPasswordSaving,
    hasChanged: passwordChanged,
    validate: validatePasswordForm,
    validationErrors: passwordFormValidationErrors,
  } = useForm<UpdatePassword>(
    INITIAL_PASSWORD_FORM,
    false,
    PASSWORD_FORM_VALIDATORS,
    passwordFormRef
  );

  const updateUserPassword = async (userId: string, updatePasswordDto: UpdatePassword) => {
    await patch<boolean>(`/users/${userId}/password`, {
      body: updatePasswordDto,
      errorMessages: {
        summary: 'Error al editar contraseña',
        defaultDetail: 'No se ha podido editar la contraseña',
        [403]: 'La contraseña antigua no es válida',
        [400]: 'La contraseña nueva no puede ser igual que la antigua',
      },
      successMessage: {
        summary: 'Contraseña cambiada',
        detail: 'Se ha modificado correctamente la contraseña',
      },
      handlers: {
        defaultSuccess: () => setPasswordForm(INITIAL_PASSWORD_FORM),
      },
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setUserField({
      [name]: value,
    });
  };

  const handleDateChange = (date: Date) => {
    setUserForm({
      ...userForm,
      dateOfBirth: date,
    } as User);
  };

  const handleAvatarUpload = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setUserField({
        avatarUrl: reader.result?.toString(),
      });
      setAvatarFile(file);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newErrors = validateUser();
    if (!userForm || newErrors) return;
    const { avatarUrl, ...updateUserDto } = userForm;

    setIsUserSaving(true);
    const updatedUser = await updateUser?.(updateUserDto, avatarFile);
    setIsUserSaving(false);
    setUserForm(updatedUser);
  };

  const handlePasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newErrors = validatePasswordForm();
    if (!user || newErrors) return;

    setIsPasswordSaving(true);
    await updateUserPassword?.(user?.id, {
      newPassword,
      oldPassword,
    });
    setIsPasswordSaving(false);
  };

  const handleAvatarClick = () => {
    if (fileInputRef?.current) {
      (fileInputRef?.current as any).click();
    }
  };

  return (
    <PageTemplate className='profile-page' title='Modificar perfil'>
      {
        <>
          <Card>
            <form
              className={classNames({
                'form-loading': userLoading || userSaving,
              })}
              onSubmit={handleSubmit}
            >
              <div
                className='form-field'
                style={{
                  gap: '16px',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <label>Avatar</label>
                <div className='avatar-preview' style={{ position: 'relative' }}>
                  {userLoading ? (
                    <Skeleton height='100px' width='100px' shape='circle' className='avatar-img' />
                  ) : (
                    <img
                      src={userForm?.avatarUrl || AVATAR_PLACEHOLDER_URL}
                      onClick={handleAvatarClick}
                      alt='Avatar'
                      className='avatar-img'
                    />
                  )}

                  <input
                    ref={fileInputRef}
                    type='file'
                    accept='image/*'
                    style={{ display: 'none' }}
                    onChange={handleAvatarUpload}
                  />
                </div>
              </div>

              <div className='form-field'>
                <label htmlFor='name'>Nombre</label>
                <InputText
                  id='name'
                  name='name'
                  placeholder='Nombre'
                  value={userForm?.name ?? ''}
                  onChange={handleInputChange}
                  className={classNames({ 'p-invalid': userValidationErrors?.name })}
                />
                <FormElementError>{userValidationErrors?.name}</FormElementError>
              </div>

              <div className='form-field'>
                <label htmlFor='surnames'>Apellidos</label>
                <InputText
                  id='surnames'
                  name='surnames'
                  placeholder='Apellidos'
                  value={userForm?.surnames ?? ''}
                  onChange={handleInputChange}
                  className={classNames({ 'p-invalid': userValidationErrors?.surnames })}
                />
                <FormElementError>{userValidationErrors?.surnames}</FormElementError>
              </div>

              <div className='form-field'>
                <label htmlFor='email'>Correo electrónico</label>
                <InputText
                  id='email'
                  name='email'
                  placeholder='Dirección de correo electrónico'
                  value={userForm?.email ?? ''}
                  onChange={handleInputChange}
                  className={classNames({ 'p-invalid': userValidationErrors?.email })}
                />
                <FormElementError>{userValidationErrors?.email}</FormElementError>
              </div>

              <div className='form-field'>
                <label htmlFor='dateOfBirth'>Fecha de nacimiento</label>
                <Calendar
                  id='dateOfBirth'
                  name='dateOfBirth'
                  dateFormat='dd/mm/yy'
                  placeholder='dd/mm/aaaa'
                  maxDate={subYears(new Date(), 10)}
                  minDate={subYears(new Date(), 110)}
                  viewDate={subYears(new Date(), 10)}
                  value={userForm?.dateOfBirth ? new Date(userForm.dateOfBirth) : null}
                  onChange={(e) => handleDateChange(e.value as any)}
                  showIcon
                />
              </div>
              <div className='form-field'>
                <label htmlFor='phone'>Teléfono</label>
                <InputMask
                  id='phone'
                  name='phone'
                  mask='(+99) 999-999-999'
                  placeholder='(+__) ___-___-___'
                  value={userForm?.phone ?? ''}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <Button
                type='submit'
                disabled={!userChanged}
                loading={userSaving}
                icon={PrimeIcons.SAVE}
                label='Guardar'
              />
            </form>
          </Card>
          <h4>Cambiar contraseña</h4>
          <Card>
            <form
              ref={passwordFormRef}
              className={classNames({
                'form-loading': userLoading || passwordSaving,
              })}
              onSubmit={handlePasswordSubmit}
            >
              <div className='form-field'>
                <label htmlFor='oldPassword'>Contraseña Antigua</label>
                <Password
                  id='oldPassword'
                  placeholder='Contraseña antigua'
                  feedback={false}
                  value={oldPassword}
                  autoComplete='current-password'
                  onChange={(e) => setPasswordField({ oldPassword: e.target.value })}
                  toggleMask
                  className={classNames({ 'p-invalid': passwordFormValidationErrors?.oldPassword })}
                />
                <FormElementError position='static'>
                  {passwordFormValidationErrors?.oldPassword}
                </FormElementError>
              </div>
              <div className='form-field'>
                <label htmlFor='newPassword'>Nueva contraseña</label>
                <Password
                  id='newPassword'
                  placeholder='Nueva contraseña'
                  feedback={false}
                  value={newPassword}
                  autoComplete='new-password'
                  onChange={(e) => setPasswordField({ newPassword: e.target.value })}
                  toggleMask
                  className={classNames({ 'p-invalid': passwordFormValidationErrors?.newPassword })}
                />
                <small
                  className={classNames('p-info', {
                    'field-error static': passwordFormValidationErrors?.newPassword,
                  })}
                >
                  La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un
                  número y un caracter especial.
                </small>
              </div>
              <Button
                type='submit'
                label='Guardar'
                icon={PrimeIcons.SAVE}
                className='mt-2'
                disabled={!passwordChanged}
                loading={passwordSaving}
              />
            </form>
          </Card>
        </>
      }
    </PageTemplate>
  );
};
