import { useEffect, useState, RefObject } from 'react';

interface DropdownPosition {
  top: string;
  left: string;
  right: string;
  bottom: string;
  marginBottom: string;
  marginTop: string;
}

export const useDropdownPosition = (buttonRef: RefObject<HTMLElement>, isVisible: boolean): DropdownPosition => {
  const [position, setPosition] = useState<DropdownPosition>({
    top: '100%',
    left: '0',
    right: 'auto',
    bottom: 'auto',
    marginBottom: '0',
    marginTop: '5px',
  });

  useEffect(() => {
    if (isVisible && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // Comprobar si hay suficiente espacio a la derecha
      const rightSpace = windowWidth - buttonRect.right;
      const leftSpace = buttonRect.left;

      // Comprobar si hay suficiente espacio abajo
      const bottomSpace = windowHeight - buttonRect.bottom;
      const topSpace = buttonRect.top;

      const newPosition = { ...position };

      // Si no hay suficiente espacio a la derecha, alinear a la derecha
      if (rightSpace < 250 && leftSpace > rightSpace) {
        newPosition.left = 'auto';
        newPosition.right = '0';
      } else {
        newPosition.left = '0';
        newPosition.right = 'auto';
      }

      // Si no hay suficiente espacio abajo, mostrar arriba
      if (bottomSpace < 300 && topSpace > 300) {
        newPosition.top = 'auto';
        newPosition.bottom = '100%';
        newPosition.marginBottom = '5px';
        newPosition.marginTop = '0';
      } else {
        newPosition.top = '100%';
        newPosition.bottom = 'auto';
        newPosition.marginBottom = '0';
        newPosition.marginTop = '5px';
      }

      setPosition(newPosition);
    }
  }, [isVisible, buttonRef]);

  return position;
};
