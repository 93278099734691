const css = `.copy-hours-dialog {
  width: 90vw;
  max-width: 500px;
}
.copy-hours-dialog .copy-hours-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.copy-hours-dialog .copy-hours-content .dialog-message {
  color: var(--primary500);
  margin: 0;
}
.copy-hours-dialog .copy-hours-content .copy-options .copy-options-label {
  font-weight: 600;
  margin-bottom: 12px;
  color: var(--primary);
}
.copy-hours-dialog .copy-hours-content .copy-options .options-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.copy-hours-dialog .copy-hours-content .copy-options .options-container .p-field-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}
.copy-hours-dialog .copy-hours-content .copy-options .options-container .p-field-checkbox label {
  margin: 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: var(--primary700);
}

@media (max-width: 768px) {
  .copy-hours-dialog {
    width: 100vw;
    height: 100vh;
    margin: 0;
  }
  .copy-hours-dialog .p-dialog-content {
    padding: 16px;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvQ29weUhvdXJzRGlhbG9nIiwic291cmNlcyI6WyJDb3B5SG91cnNEaWFsb2cuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUlBO0VBQ0U7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBUVo7RUFDRTtJQUNFO0lBQ0E7SUFDQTs7RUFFQTtJQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLmNvcHktaG91cnMtZGlhbG9nIHtcbiAgd2lkdGg6IDkwdnc7XG4gIG1heC13aWR0aDogNTAwcHg7XG5cbiAgLmNvcHktaG91cnMtY29udGVudCB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGdhcDogMjRweDtcblxuICAgIC5kaWFsb2ctbWVzc2FnZSB7XG4gICAgICBjb2xvcjogdmFyKC0tcHJpbWFyeTUwMCk7XG4gICAgICBtYXJnaW46IDA7XG4gICAgfVxuXG4gICAgLmNvcHktb3B0aW9ucyB7XG4gICAgICAuY29weS1vcHRpb25zLWxhYmVsIHtcbiAgICAgICAgZm9udC13ZWlnaHQ6IDYwMDtcbiAgICAgICAgbWFyZ2luLWJvdHRvbTogMTJweDtcbiAgICAgICAgY29sb3I6IHZhcigtLXByaW1hcnkpO1xuICAgICAgfVxuXG4gICAgICAub3B0aW9ucy1jb250YWluZXIge1xuICAgICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgICAgICBnYXA6IDE2cHg7XG5cbiAgICAgICAgLnAtZmllbGQtY2hlY2tib3gge1xuICAgICAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICAgICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICAgICAgICBnYXA6IDhweDtcblxuICAgICAgICAgIGxhYmVsIHtcbiAgICAgICAgICAgIG1hcmdpbjogMDtcbiAgICAgICAgICAgIGN1cnNvcjogcG9pbnRlcjtcbiAgICAgICAgICAgIGZvbnQtd2VpZ2h0OiA0MDA7XG4gICAgICAgICAgICBmb250LXNpemU6IDE0cHg7XG4gICAgICAgICAgICBjb2xvcjogdmFyKC0tcHJpbWFyeTcwMCk7XG4gICAgICAgICAgfVxuICAgICAgICB9XG4gICAgICB9XG4gICAgfVxuICB9XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA3NjhweCkge1xuICAuY29weS1ob3Vycy1kaWFsb2cge1xuICAgIHdpZHRoOiAxMDB2dztcbiAgICBoZWlnaHQ6IDEwMHZoO1xuICAgIG1hcmdpbjogMDtcblxuICAgIC5wLWRpYWxvZy1jb250ZW50IHtcbiAgICAgIHBhZGRpbmc6IDE2cHg7XG4gICAgfVxuICB9XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
