import { Branch } from '../types/branch';
import { Role } from '../types/role';

export const getRoleOptions = (roles?: Role[]) => {
  return roles?.map((role) => ({
    label: role.name,
    value: role.id,
  }));
};

export const getBranchOptions = (branches?: Branch[]) => {
  return branches?.map((branch) => ({
    label: branch.name,
    value: branch.id,
  }));
};
