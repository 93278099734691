import React, { useState } from 'react';
import { getPages } from '../../config/pages';
import { SidebarButton } from './SidebarButton/SidebarButton';

import './Sidebar.scss';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { PrimeIcons } from 'primereact/api';
import { useNavigateScroll } from '../../hooks/useNavigateScroll';
import { useAuthContext } from '../../context/AuthContext';
import { Skeleton } from 'primereact/skeleton';
import { Sidebar as SidebarPrime } from 'primereact/sidebar';
import { AVATAR_PLACEHOLDER_URL } from '../../const';
import { useNotificationContext } from '../../context/NotificationContext';
import { hasFeaturesPermission } from '../../utils/featureUtils';
import { Capacitor } from '@capacitor/core';

export const Sidebar = () => {
  const isTouchDevice = Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches;
  const hasLessThan768px = window.innerWidth < 768;
  const [isOpen, setIsOpen] = useState(!isTouchDevice || hasLessThan768px);
  const navigate = useNavigateScroll();
  const { signOut, user, features, featuresLoading } = useAuthContext() ?? {};
  const { notifications } = useNotificationContext() ?? {};

  const handleSignOut = async () => {
    await signOut?.();
    navigate('/');
  };

  const getAvatarSize = () => {
    return isOpen ? '75px' : '40px';
  };

  const getSidebarContent = (closeOnClick?: boolean) => {
    return (
      <>
        <div className='sidebar-image-container'>
          <img src={user?.avatarUrl || AVATAR_PLACEHOLDER_URL}></img>
        </div>
        <ul>
          {getPages(
            notifications,
            features?.filter((f) => f.includes('rt-')),
          ).map(({ label, elements }) => {
            const hasNoFeatureCodes = elements.every(({ featureCodes }) => !featureCodes);
            const hasActiveFeatures =
              hasNoFeatureCodes ||
              elements.some(({ featureCodes }) => hasFeaturesPermission(featureCodes ?? [], { features, featuresLoading }));

            if (!hasActiveFeatures) return;
            return (
              <li className='sidebar-section' key={label}>
                <p className='sidebar-section-title'>{label}</p>
                <div className='section-divider'></div>
                <ul>
                  {elements.map(({ url, label = '', icon, iconBadge, featureCodes }) => (
                    <li key={url} onClick={() => closeOnClick && setIsOpen(true)}>
                      {!featureCodes || hasFeaturesPermission(featureCodes ?? [], { features, featuresLoading }) ? (
                        <SidebarButton href={url} label={label} icon={icon} iconBadge={iconBadge}></SidebarButton>
                      ) : (
                        <></>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
            );
          })}
          <li>
            <SidebarButton
              key='sign-out'
              onClick={handleSignOut}
              label={'Cerrar sesión'}
              icon={PrimeIcons.SIGN_OUT}
            ></SidebarButton>
          </li>
        </ul>
      </>
    );
  };

  return (
    <nav
      className={classNames('sidebar', {
        open: isOpen,
      })}
    >
      <Button onClick={() => setIsOpen(!isOpen)} icon='pi pi-bars'></Button>
      {featuresLoading ? (
        <>
          <Skeleton height={getAvatarSize()} width={getAvatarSize()} className='avatar-skeleton' shape='circle' />
          {Array.from({ length: 12 }).map((_, index) => (
            <Skeleton key={index} width='unset' height='20px' className='nav-skeleton' />
          ))}
        </>
      ) : (
        <>{getSidebarContent()}</>
      )}
      <SidebarPrime className='mobile-sidebar' visible={!isOpen} onHide={() => setIsOpen(true)} position='left'>
        {getSidebarContent(true)}
      </SidebarPrime>
    </nav>
  );
};
