import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import BusinessHoursSection from '../../components/BusinessHoursSection/BusinessHoursSection';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { Card } from 'primereact/card';
import './Settings.scss';
import { FormField } from '../../components/FormField/FormField';
import { InputText } from 'primereact/inputtext';
import { PrimeIcons } from 'primereact/api';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { Branch } from '../../types/branch';
import { useForm } from '../../hooks/useForm';
import { classNames } from 'primereact/utils';
import { Organization } from '../../types/organization';
import { ReservationTypeWithColor, useReservationTypes } from '../../hooks/AppData/useReservationTypes';
import { Password } from 'primereact/password';
import { Skeleton } from 'primereact/skeleton';
import { AutoComplete } from 'primereact/autocomplete';

import {
  BranchSettingsForm,
  getBranchSettingsFormValidators,
  getInitialBranchSettingsForm,
  getInitialOrgEmailSettingsForm,
  getInitialReservationHoursForm,
  ORG_EMAIL_SETTINGS_FORM_VALIDATORS,
  OrgEmailSettingsForm,
  RESERVATION_HOURS_FORM_VALIDATORS,
  ReservationHoursForm,
} from './validators';
import { BranchesSelect } from '../../components/BranchesSelect/BranchesSelect';
import { HolidaysDialog } from '../../components/HolidaysDialog/HolidaysDialog';
import { HolidaysList } from '../../components/HolidaysList/HolidaysList';
import { ALL_FEATURES } from '../../config/features';
import { useFeaturesToggle, useOrganizationFeaturesToggle } from '../../hooks/useFeaturesToggle';
import { Divider } from 'primereact/divider';
import { SIGNED_DOCUMENT_TYPES } from '../../types/signedDocument';
import { useLocaleContext } from '../../context/LocaleContext';
import { CopyHoursDialog } from '../../components/CopyHoursDialog/CopyHoursDialog';
import { GmailConfigInfo } from '../../components/GmailConfigInfo/GmailConfigInfo';

const getBranchFromId = (id: string | undefined, branches: Branch[] | undefined) => {
  if (!id || !branches) return;
  return branches.find((_) => _.id === id);
};

const getBranchReservationTypes = (branchId: string | undefined, reservationTypes: ReservationTypeWithColor[] | undefined) => {
  if (!branchId) return;
  return reservationTypes?.filter((_) => _.branchId === branchId);
};

const SettingsSkeleton = () => {
  return (
    <PageTemplate className='settings-page' title='Configuración'>
      <Card>
        <div className='settings-skeleton'>
          <div className='settings-section'>
            <Skeleton width='100%' height='40px' className='mb-4' />

            <div className='business-hours-skeleton'>
              {Array.from({ length: 7 }).map((_, index) => (
                <div key={index} className='business-day-skeleton'>
                  <div className='time-range'>
                    <div className='time-range-content'>
                      <Skeleton width='120px' height='40px' className='switch' />
                      <Skeleton width='100%' height='40px' className='time-input' />
                      <Skeleton width='100%' height='40px' className='time-input' />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className='holiday-chips-skeleton'>
              <Skeleton width='100%' height='40px' className='mb-2' />
              <Skeleton width='100%' height='80px' />
            </div>
          </div>
        </div>
      </Card>

      <Card>
        <div className='settings-skeleton'>
          <div className='settings-section'>
            <Skeleton width='100%' height='40px' className='mb-2' />
            <Skeleton width='100%' height='40px' className='mb-2' />
          </div>
        </div>
      </Card>
    </PageTemplate>
  );
};

interface CityOption {
  name: string;
  fullName: string;
}

const searchCity = async (query: string, locale: string): Promise<CityOption[]> => {
  if (!query || query.length < 2) return [];

  const geonamesApiUrl = process.env.ENVIRONMENT === 'dev' ? 'http://api.geonames.org' : 'https://secure.geonames.org';

  try {
    const response = await fetch(
      `${geonamesApiUrl}/searchJSON?` +
        `name_startsWith=${encodeURIComponent(query)}` +
        `&maxRows=15` +
        `&orderby=population` +
        `&cities=cities500` + // ciudades con población > 500
        `&lang=${locale}` +
        `&username=nogravity`, // TODO: mover a variable de entorno
    );
    const data = await response.json();

    if (!data.geonames) return [];

    return data.geonames.map((city: any) => ({
      name: city.name,
      fullName: `${city.name}, ${city.adminName1 || city.countryName}`,
    }));
  } catch (error) {
    console.error('Error buscando ciudades:', error);
    return [];
  }
};

export const Settings = () => {
  const { patch } = useClient();
  const { locale } = useLocaleContext();
  const { organization, organizationLoading, orgBranches, mutateOrganization } = useOrganizationContext() ?? {};
  const [selectedBranchId, setSelectedBranchId] = useState<string>();
  const [showHolidaysDialog, setShowHolidaysDialog] = useState(false);
  const [showCopyHoursDialog, setShowCopyHoursDialog] = useState(false);
  const [selectedReservationType, setSelectedReservationType] = useState<ReservationTypeWithColor>();
  const [cities, setCities] = useState<CityOption[]>([]);

  const userHasEditFeature = useFeaturesToggle([ALL_FEATURES.BUSINESS_CONFIG_WRITE]);
  const orgHasAnySignedDocumentsFeature = useOrganizationFeaturesToggle(Object.values(SIGNED_DOCUMENT_TYPES));
  const selectedBranch = useMemo(() => getBranchFromId(selectedBranchId, orgBranches), [selectedBranchId, orgBranches]);
  const { reservationTypes, mutateReservationTypes, reservationTypesLoading } = useReservationTypes();
  const branchReservationTypes = useMemo(() => {
    return getBranchReservationTypes(selectedBranchId, reservationTypes);
  }, [selectedBranchId, reservationTypes]);
  const reservationTypesWithRestrictedDates = useMemo(
    () => branchReservationTypes?.filter((_) => !_.allowAnyDate),
    [branchReservationTypes],
  );

  useEffect(() => {
    // TODO We should probably just get the first branch, and if it hasnt got reservation types,
    // dont show the reservation hours section, or show a message saying that there are no reservation types
    if (!selectedBranchId && reservationTypes && orgBranches?.length) {
      const firstBranchWithReservationTypes = orgBranches.find((_) => getBranchReservationTypes(_.id, reservationTypes)?.length);
      setSelectedBranchId(firstBranchWithReservationTypes?.id);
    }
  }, [orgBranches, selectedBranchId, reservationTypes]);

  const initialBranchSettingsForm = useMemo(() => getInitialBranchSettingsForm(selectedBranch), [selectedBranch]);
  const branchSettingsFormValidators = useMemo(
    () => getBranchSettingsFormValidators(orgHasAnySignedDocumentsFeature),
    [orgHasAnySignedDocumentsFeature],
  );
  const branchSettingsFormRef = useRef(null);
  const {
    form: branchSettings,
    setFormFields: setBranchSettingsField,
    isSaving: branchSettingsSaving,
    setIsSaving: setBranchSettingsSaving,
    hasChanged: branchSettingsChanged,
    validate: validateBranchSettings,
    validationErrors: branchSettingsErrors,
  } = useForm<BranchSettingsForm | undefined>(
    initialBranchSettingsForm,
    false,
    branchSettingsFormValidators,
    branchSettingsFormRef,
  );
  const handleSaveBranchSettings = async (e: FormEvent) => {
    e.preventDefault();
    const errors = validateBranchSettings();
    if (errors || !branchSettings || !selectedBranchId) return;
    setBranchSettingsSaving(true);

    const updatedBranch = await patch<Branch>(`/branches/${selectedBranchId}`, {
      body: { ...branchSettings },
      errorMessages: {
        summary: 'Error al cambiar datos de la sucursal',
        defaultDetail: 'No se ha podido modificar los datos de la sucursal',
      },
      successMessage: {
        summary: 'Datos de la sucursal modificados',
        detail: 'Los datos de la sucursal se han modificado correctamente',
      },
    });

    if (updatedBranch) {
      mutateOrganization?.();
    }
    setBranchSettingsSaving(false);
  };

  const reservationHoursFormRef = useRef(null);
  const initialReservationHoursForm = useMemo(
    () => getInitialReservationHoursForm(branchReservationTypes),
    [branchReservationTypes],
  );
  const {
    form: reservationHours,
    setFormFields: setReservationHoursField,
    isSaving: reservationHoursSaving,
    setIsSaving: setReservationHoursSaving,
    hasChanged: reservationHoursChanged,
    validate: reservationHoursValidate,
  } = useForm<ReservationHoursForm | undefined>(
    initialReservationHoursForm,
    false,
    RESERVATION_HOURS_FORM_VALIDATORS,
    reservationHoursFormRef,
  );

  const handleSaveReservationHours = async (e: FormEvent) => {
    e.preventDefault();
    const errors = reservationHoursValidate();
    if (errors) return;
    if (!reservationHours || !selectedBranchId || !selectedBranch || !branchReservationTypes) return;
    setReservationHoursSaving(true);

    const updatedReservationTypes = branchReservationTypes.map((reservationType) => {
      const { color, branchId, tagColor, createdAt, ...rest } = reservationType;
      return {
        ...rest,
        hours: reservationHours[reservationType.id].hours,
        closedDays: reservationHours[reservationType.id].closedDays,
      };
    });

    await patch(`/branches/${selectedBranchId}/reservation-types`, {
      body: { updatedReservationTypes },
      errorMessages: {
        summary: 'Error al cambiar horario',
        defaultDetail: 'No se ha podido modificar el horario de apertura',
      },
      successMessage: {
        summary: 'Horario editado',
        detail: 'El horario de apertura se ha modificado correctamente',
      },
      handlers: {
        defaultSuccess: () => {
          mutateReservationTypes();
        },
      },
    });

    setReservationHoursSaving(false);
  };

  const orgEmailSettingsFormRef = useRef(null);
  const initialOrgEmailSettingsForm = useMemo(() => getInitialOrgEmailSettingsForm(organization), [organization]);
  const {
    form: orgEmailSettings,
    setFormFields: orgEmailSettingsField,
    isSaving: orgEmailSettingsSaving,
    setIsSaving: setOrgEmailSettingsSaving,
    hasChanged: orgEmailSettingsChanged,
    validationErrors: orgEmailSettingsErrors,
    validate: validateOrgEmailSettings,
  } = useForm<OrgEmailSettingsForm | undefined>(
    initialOrgEmailSettingsForm,
    false,
    ORG_EMAIL_SETTINGS_FORM_VALIDATORS,
    orgEmailSettingsFormRef,
  );

  const handleOrgEmailSettingsSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const errors = validateOrgEmailSettings();
    if (errors) return;
    if (!orgEmailSettings || !organization) return;
    setOrgEmailSettingsSaving(true);

    const updatedOrg = await patch<Organization>(`/organizations/${organization.id}`, {
      body: { ...orgEmailSettings },
      errorMessages: {
        summary: 'Error al configurar el correo electrónico',
        defaultDetail: 'No se ha podido modificar el correo electrónico de la empresa.',
      },
      successMessage: {
        summary: 'Configuración de correo electrónico modificada',
        detail: 'El correo electrónico de la empresa se ha modificado correctamente.',
      },
    });

    if (updatedOrg) {
      mutateOrganization?.(updatedOrg, false);
    }
    setOrgEmailSettingsSaving(false);
  };

  const handleOpenHolidaysDialog = (reservationType: ReservationTypeWithColor) => {
    setSelectedReservationType(reservationType);
    setShowHolidaysDialog(true);
  };

  const handleHolidaysChange = (dates: Date[]) => {
    if (!selectedReservationType) return;

    const currentHours = reservationHours?.[selectedReservationType.id]?.hours;
    if (!currentHours) return;

    setReservationHoursField({
      [selectedReservationType.id]: {
        hours: currentHours,
        closedDays: dates,
      },
    });
  };

  const handleRemoveHoliday = (reservationType: ReservationTypeWithColor, dateToRemove: Date) => {
    const currentHours = reservationHours?.[reservationType.id]?.hours;
    const currentHolidays = reservationHours?.[reservationType.id]?.closedDays ?? [];
    if (!currentHours) return;

    setReservationHoursField({
      [reservationType.id]: {
        hours: currentHours,
        closedDays: currentHolidays.filter((date) => date.toISOString() !== dateToRemove.toISOString()),
      },
    });
  };

  const handleCitySearch = async (event: { query: string }) => {
    const results = await searchCity(event.query, locale);
    setCities(results);
  };

  const handleOpenCopyHoursDialog = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowCopyHoursDialog(true);
  };

  const handleCloseCopyHoursDialog = () => {
    setShowCopyHoursDialog(false);
  };

  const handleCopyHours = (
    sourceBranchId: string,
    options: { copyReservationHours: boolean; copyClosedDays: boolean; copyBreakHours: boolean },
  ) => {
    if (!selectedBranchId || !sourceBranchId || !reservationTypes) return;

    const sourceBranchReservationTypes = reservationTypes.filter((type) => type.branchId === sourceBranchId);
    const targetBranchReservationTypes = reservationTypes.filter((type) => type.branchId === selectedBranchId);

    // Copiar horarios de reserva y días de cierre
    if (options.copyReservationHours || options.copyClosedDays) {
      const newReservationHours: ReservationHoursForm = {};

      targetBranchReservationTypes.forEach((targetType) => {
        const sourceType = sourceBranchReservationTypes.find((sourceType) => sourceType.name === targetType.name);

        if (sourceType) {
          newReservationHours[targetType.id] = {
            hours: options.copyReservationHours
              ? sourceType.hours
              : (reservationHours?.[targetType.id]?.hours ?? targetType.hours),
            closedDays: options.copyClosedDays
              ? (reservationHours?.[sourceType.id]?.closedDays ?? sourceType.closedDays.map((date) => new Date(date)))
              : (reservationHours?.[targetType.id]?.closedDays ?? []),
          };
        }
      });

      setReservationHoursField(newReservationHours);
    }
  };

  if (organizationLoading || reservationTypesLoading) {
    return <SettingsSkeleton />;
  }

  return (
    <PageTemplate className='settings-page' title='Configuración de sucursales'>
      <Card className='branch-settings-card'>
        <FormField
          helper='La configuración se aplicará a la sucursal seleccionada'
          className='settings-branch-select'
          elementId='config-branch'
          labelTitle='Sucursal'
        >
          <BranchesSelect
            id='config-branch'
            isSingle
            value={selectedBranchId}
            onChange={(branch) => branch && setSelectedBranchId(branch.id)}
          />
        </FormField>
        <Divider />
        <form
          className={classNames({
            'form-loading': organizationLoading || branchSettingsSaving,
          })}
          onSubmit={handleSaveBranchSettings}
          ref={branchSettingsFormRef}
        >
          <FormField fullWidth>
            <h4 className='branch-settings-title'>Datos de la sucursal</h4>
          </FormField>
          <FormField
            labelTitle='Nombre'
            elementId='branch-name'
            required
            error={branchSettingsErrors?.name}
            infoTooltip='Es el nombre con el que se mostrará la sucursal, tanto en el panel de control como en las aplicaciones externas.'
          >
            <InputText
              id='branch-name'
              placeholder='Ej: Sucursal Alameda'
              value={branchSettings?.name}
              onChange={(e) => setBranchSettingsField({ name: e.target.value })}
              invalid={!!branchSettingsErrors?.name}
            />
          </FormField>
          {orgHasAnySignedDocumentsFeature && (
            <FormField
              labelTitle='Ciudad'
              elementId='branch-signed-documents-location'
              required
              error={branchSettingsErrors?.city}
              infoTooltip='La ciudad de la sucursal se usará para autocompletar documentos firmados.'
            >
              <AutoComplete
                id='branch-signed-documents-location'
                placeholder='Ej: Málaga'
                value={branchSettings?.city}
                onChange={(e) => setBranchSettingsField({ city: e.value?.name || e.value })}
                invalid={!!branchSettingsErrors?.city}
                suggestions={cities}
                completeMethod={handleCitySearch}
                field='name'
                forceSelection
                itemTemplate={(item: CityOption) => (
                  <div className='city-item'>
                    <span>{item.fullName}</span>
                  </div>
                )}
              />
            </FormField>
          )}
          {userHasEditFeature && (
            <Button
              type='submit'
              disabled={!branchSettingsChanged}
              className='pull-right pull-down'
              label='Guardar'
              icon={PrimeIcons.SAVE}
            />
          )}
        </form>
        <Divider />
        <form
          className={classNames({
            'form-loading': organizationLoading || reservationHoursSaving,
          })}
          onSubmit={handleSaveReservationHours}
          ref={reservationHoursFormRef}
        >
          <FormField fullWidth>
            <div className='reservation-hours-header'>
              <h4 className='reservation-hours-title'>Horarios de reservas</h4>
              {userHasEditFeature && orgBranches && orgBranches.length > 1 && selectedBranchId && (
                <Button
                  icon={PrimeIcons.COPY}
                  label='Copiar horarios'
                  onClick={handleOpenCopyHoursDialog}
                  className='copy-hours-button'
                  text
                  severity='secondary'
                />
              )}
            </div>
          </FormField>
          {reservationTypesWithRestrictedDates?.map((reservationType) => (
            <FormField
              className={classNames({ 'full-width': reservationTypesWithRestrictedDates.length === 1 })}
              key={reservationType.id}
            >
              <BusinessHoursSection
                onChange={(value) =>
                  setReservationHoursField({
                    [reservationType.id]: {
                      ...reservationHours?.[reservationType.id],
                      hours: value,
                    },
                  })
                }
                value={reservationHours?.[reservationType.id]?.hours}
                title={reservationType.name ?? 'individuales'}
                isEditable={userHasEditFeature}
              />
              <FormField fullWidth>
                <h4 className='closed-days-title'>Días de cierre</h4>
              </FormField>
              <FormField fullWidth>
                {userHasEditFeature && (
                  <Button
                    type='button'
                    text
                    className='holidays-selector-button'
                    onClick={() => handleOpenHolidaysDialog(reservationType)}
                    icon='pi pi-calendar'
                    label='Seleccionar días de cierre'
                  />
                )}
                <HolidaysList
                  holidays={reservationHours?.[reservationType.id]?.closedDays ?? []}
                  onRemove={(date) => handleRemoveHoliday(reservationType, date)}
                  isEditable={userHasEditFeature}
                  showPastDates={false}
                />
              </FormField>
            </FormField>
          ))}
          {userHasEditFeature && (
            <Button
              type='submit'
              disabled={!reservationHoursChanged}
              className='pull-right pull-down'
              label='Guardar'
              icon={PrimeIcons.SAVE}
            />
          )}
        </form>
      </Card>
      <h3>Configuración de la empresa</h3>
      <Card>
        <form
          className={classNames({
            'form-loading': organizationLoading || orgEmailSettingsSaving,
          })}
          onSubmit={handleOrgEmailSettingsSubmit}
          ref={orgEmailSettingsFormRef}
        >
          <FormField fullWidth>
            <div className='email-settings-header'>
              <h4>Correo electrónico de la empresa</h4>
              <GmailConfigInfo />
            </div>
          </FormField>
          <p className='org-email-settings-description'>
            No Gravity usará este correo electrónico para enviar correos electrónicos a los usuarios. Esto puede incluir
            notificaciones, promociones, etc.
          </p>
          <FormField
            elementId='email'
            labelTitle='Dirección de correo electrónico'
            error={orgEmailSettingsErrors?.email}
            required
          >
            <InputText
              placeholder='Correo electrónico de la empresa'
              id='email'
              className={classNames({ 'p-invalid': orgEmailSettingsErrors?.email })}
              value={orgEmailSettings?.email ?? ''}
              autoComplete='email'
              onChange={(e) => orgEmailSettingsField({ email: e.target.value })}
              disabled={!userHasEditFeature}
            />
          </FormField>
          <FormField
            elementId='email-server-password'
            labelTitle='Contraseña del servidor de correo'
            error={orgEmailSettingsErrors?.emailServerPassword}
          >
            <Password
              id='email-server-password'
              toggleMask
              feedback={false}
              placeholder='Contraseña del servidor de correo'
              value={orgEmailSettings?.emailServerPassword ?? ''}
              className={classNames({ 'p-invalid': orgEmailSettingsErrors?.emailServerPassword })}
              onChange={(e) =>
                orgEmailSettingsField({
                  emailServerPassword: e.target.value,
                })
              }
              disabled={!userHasEditFeature}
            />
          </FormField>
          {userHasEditFeature && (
            <Button
              type='submit'
              disabled={!orgEmailSettingsChanged}
              className='pull-right pull-down'
              label='Guardar'
              icon={PrimeIcons.SAVE}
            />
          )}
        </form>
      </Card>
      <HolidaysDialog
        visible={showHolidaysDialog}
        onHide={() => setShowHolidaysDialog(false)}
        value={selectedReservationType ? (reservationHours?.[selectedReservationType.id]?.closedDays ?? []) : []}
        onChange={handleHolidaysChange}
        title={`Días de cierre - ${selectedReservationType?.name ?? ''}`}
        otherReservationTypes={branchReservationTypes?.filter((rt) => rt.id !== selectedReservationType?.id && !rt.allowAnyDate)}
        reservationHours={reservationHours}
      />
      <CopyHoursDialog
        visible={showCopyHoursDialog}
        onHide={handleCloseCopyHoursDialog}
        onCopy={handleCopyHours}
        currentBranchId={selectedBranchId ?? ''}
      />
    </PageTemplate>
  );
};
