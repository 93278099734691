const css = `.user-form.editing {
  margin: var(--gap) 0;
}
.user-form .select-role-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.user-form .select-role-row .form-field {
  width: 50%;
}
.user-form .select-role-row .switch {
  align-items: start;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvVXNlckZvcm0iLCJzb3VyY2VzIjpbIlVzZXJGb3JtLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7QUFDQTtFQUNFOztBQUVGO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIudXNlci1mb3JtIHtcbiAgJi5lZGl0aW5nIHtcbiAgICBtYXJnaW46IHZhcigtLWdhcCkgMDtcbiAgfVxuXG4gIC5zZWxlY3Qtcm9sZS1yb3cge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuICAgIC5mb3JtLWZpZWxkIHtcbiAgICAgIHdpZHRoOiA1MCU7XG4gICAgfVxuICAgIC5zd2l0Y2gge1xuICAgICAgYWxpZ24taXRlbXM6IHN0YXJ0O1xuICAgIH1cbiAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
