import React from 'react';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { NavElement } from '../../types/navigation';
import { Route } from 'react-router-dom';
import MembersList from './MembersList/MembersList';
import MemberProfile from './MemberProfile/MemberProfile';

const membersRoutes: NavElement[] = [
  {
    label: 'Miembros',
    element: <MembersList />,
    url: '/',
  },
  {
    label: 'Ver perfil',
    element: <MemberProfile />,
    url: '/profile/:id',
  },
];

export const Members = () => {
  return (
    <PageTemplate title='Miembros' parentRoute='/members' routes={membersRoutes} withPageLocaleContext>
      {membersRoutes.map(({ element, url }) => (
        <Route key={url} path={url} element={element} />
      ))}
    </PageTemplate>
  );
};
