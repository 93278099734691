import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { areFormObjectsDifferent } from '../utils/object-comparer';
import { useConfirmBeforeExit } from './useConfirmBeforeExit';

export const VALIDATION_MESSAGES = {
  EMPTY: 'El campo no puede estar vacío',
  EMPTY_IMAGE: 'Debes añadir una imagen',
  NOT_EMAIL: 'Debes introducir un correo electrónico',
  INVALID_PASSWORD: 'La contraseña no es válida',
  MAX_LENGTH: (max: number) => `El campo no puede exceder ${max} caracteres`,
  ONLY_LETTERS_AND_SPACES: 'El campo solo puede contener letras y espacios',
} as const;

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

type Validator<T> = (form: DeepPartial<T>) => string | undefined;

export type FormValidators<T> = {
  [K in keyof T]?: T[K] extends object ? FormValidators<T[K]> : Validator<T>;
};

export type ValidationErrors<T> = {
  [K in keyof T]?: T[K] extends object ? ValidationErrors<T[K]> : string | undefined;
};

type UseFormReturn<T> = {
  form: T;
  setForm: Dispatch<SetStateAction<T>>;
  setFormField: (updatedField: DeepPartial<T>) => void;
  isSaving: boolean;
  setIsSaving: Dispatch<SetStateAction<boolean>>;
  hasChanged: boolean;
  validationErrors: ValidationErrors<T> | undefined;
  validate: (newForm?: T) => ValidationErrors<T> | undefined;
};

const getValidationErrors = <T,>(
  formToValidate: T,
  validatorsToCheck: FormValidators<T> | undefined
): ValidationErrors<T> | undefined => {
  if (!validatorsToCheck) return;

  const errors = Object.keys(validatorsToCheck).reduce<ValidationErrors<T>>((errorObj, key) => {
    const keyToValidate = key as keyof T;
    const validator = validatorsToCheck[keyToValidate];

    if (typeof validator === 'function') {
      const message = validator(formToValidate);
      if (message) {
        errorObj[keyToValidate] = message as any;
      }
    } else {
      const nestedField = formToValidate[keyToValidate];
      if (nestedField) {
        const nestedErrors = getValidationErrors(nestedField, validator as FormValidators<T[keyof T]>);
        if (nestedErrors) {
          errorObj[keyToValidate] = nestedErrors as any;
        }
      }
    }

    return errorObj;
  }, {});

  if (!Object.keys(errors).length) return;

  return errors;
};

const focusFirstError = (formRef?: React.MutableRefObject<HTMLFormElement | null>) => {
  const queryTarget = formRef?.current ? formRef.current : document;
  const firstErrorElement = queryTarget.querySelector('.p-invalid') as HTMLElement | null;

  const isInputWrapper = firstErrorElement?.classList.contains('p-inputwrapper');
  if (isInputWrapper) {
    const input = firstErrorElement?.querySelector('input');
    input?.focus();
  } else {
    firstErrorElement?.focus();
  }
};

export const useForm = <T,>(
  initialValue: T,
  noExitPrompt = false,
  validators?: FormValidators<T>,
  formRef?: React.MutableRefObject<HTMLFormElement | null>
): UseFormReturn<T> => {
  const [form, setForm] = useState<T>(initialValue);
  const [isSaving, setIsSaving] = useState(false);
  const [validating, setValidating] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors<T>>();
  const [shouldFocusErrors, setShouldFocusErrors] = useState(false);

  useLayoutEffect(() => {
    if (shouldFocusErrors) {
      focusFirstError(formRef);
      setShouldFocusErrors(false);
    }
  }, [shouldFocusErrors, setShouldFocusErrors]);

  const hasChanged = useMemo(
    () => !!form && !!initialValue && areFormObjectsDifferent(form, initialValue, true),
    [form, initialValue, areFormObjectsDifferent]
  );

  useConfirmBeforeExit(hasChanged && !noExitPrompt);

  useEffect(() => {
    if (initialValue) {
      setForm(initialValue);
    }
  }, [initialValue]);

  const validate = (newForm?: T) => {
    if (!newForm) {
      setShouldFocusErrors(true);
    }
    const newErrors = getValidationErrors(newForm ?? form, validators);
    setValidationErrors(newErrors);

    if (newErrors) {
      setValidating(true);
    } else {
      setValidating(false);
    }

    return newErrors;
  };

  const setFormField = (updatedField: DeepPartial<T>) => {
    if (!form || !updatedField) return;
    const newForm = { ...form, ...updatedField };
    if (validating) {
      validate(newForm);
    }
    setForm(newForm);
  };

  return {
    form,
    setForm,
    setFormField,
    isSaving,
    setIsSaving,
    hasChanged,
    validationErrors,
    validate,
  };
};
