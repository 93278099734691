import React, { useRef } from 'react';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/languages/es.js';

import Froala from 'react-froala-wysiwyg';
import { Capacitor } from '@capacitor/core';
import { useClient } from '../../hooks/useClient';
import './RichTextEditor.scss';
import { useWebsiteContext } from '../../context/WebsiteContext';
import { usePopups } from '../../context/PopupContext';

export interface UploadImageResponse {
  imageUrl: string;
}

interface Props {
  value?: string;
  id?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
  hideFontSize?: boolean;
  useBase64Images?: boolean;
  disabled?: boolean;
}

const plugins = [
  'table',
  'spell',
  'quote',
  'save',
  'quickInsert',
  'paragraphFormat',
  'paragraphStyle',
  'help',
  'draggable',
  'align',
  'link',
  'lists',
  'file',
  'image',
  'emoticons',
  'fontSize',
  'url',
  'video',
  'embedly',
  'colors',
  'entities',
  'inlineClass',
  'inlineStyle',
];
const moreMisc = ['undo', 'redo', 'help'];
const moreMiscFullScreen = ['undo', 'redo', 'fullscreen', 'help'];

export const RichTextEditor = ({
  value,
  id,
  onChange,
  placeholder = '',
  hideFontSize = false,
  useBase64Images = false,
  disabled = false,
}: Props) => {
  const { showToast } = usePopups() ?? {};
  const { website } = useWebsiteContext() ?? {};
  const client = useClient();
  const editorRef = useRef<Froala | null>(null);

  const handleModelChange = (updatedModel: string) => {
    onChange?.(updatedModel);
  };

  const isNativePlatform = Capacitor.isNativePlatform();

  const addIdToEditor = () => {
    if (disabled) {
      editorRef.current?.getEditor().edit.off();
      return;
    }
    const editorElement = editorRef.current?.getEditor().el;
    if (!editorElement) return;
    editorElement.id = id;
  };

  const handleImageBeforeUpload = (image: any) => {
    if (useBase64Images) {
      const reader = new FileReader();
      reader.onload = () => {
        const editor = editorRef.current?.getEditor();
        editor?.image.insert(reader.result as string);
      };
      reader.readAsDataURL(image[0]);
      return false;
    }

    const formData = new FormData();
    formData.append('image', image[0]);
    const editor = editorRef.current?.getEditor();

    client
      .post<UploadImageResponse>(`/websites/${website?.id}/upload`, {
        body: formData,
      })
      .then((response) => {
        editor?.image.insert(response?.imageUrl);
      });

    return false;
  };

  const handleImageRemoved = ($img: any) => {
    if (useBase64Images) return;

    const imageUrl = $img.attr('src');
    const body = {
      imageUrls: [imageUrl],
    };
    client.post(`/websites/${website?.id}/delete-images`, { body });
  };

  const handleDrop = (event: any) => {
    const dt = event.originalEvent.dataTransfer;
    if (!dt || !dt.files.length) return false;

    const files: File[] = Array.from(dt.files);
    for (const file of files) {
      if (!file.type.startsWith('image/')) {
        event.preventDefault();
        showToast?.({
          severity: 'error',
          summary: 'Archivo inválido',
          detail: 'Solo se pueden subir imágenes',
        });
        return false;
      }
    }

    return true;
  };

  const handlePasteImage = (event: any) => {
    if (!useBase64Images) return true;

    // Si estamos usando base64Images, manejamos el pegado de imágenes manualmente
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;

    if (!items) return true;

    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        const blob = item.getAsFile();
        if (!blob) continue;

        const reader = new FileReader();
        reader.onload = () => {
          const editor = editorRef.current?.getEditor();
          if (editor) {
            // Insertar la imagen una sola vez
            editor.image.insert(reader.result as string, null, null, editor.image.get());
          }
        };
        reader.readAsDataURL(blob);

        // Prevenir el comportamiento predeterminado para evitar la doble inserción
        event.preventDefault();
        return false;
      }
    }

    return true;
  };

  return (
    <Froala
      // pass react key so that if website id changes the config is updated
      // if config becomes dependent on other values they need to be added to key
      key={website?.id}
      ref={editorRef}
      model={value}
      onModelChange={handleModelChange}
      tag='textarea'
      config={{
        toolbarSticky: false,
        language: 'es',
        placeholderText: placeholder,
        attribution: false,
        imageUpload: !useBase64Images,
        imageUploadParam: 'file',
        pasteImage: !useBase64Images,
        imageInsertButtons: useBase64Images ? [] : ['imageUpload', 'imageByURL'],
        videoUpload: false,
        videoInsertButtons: ['videoByURL', 'videoEmbed'],
        htmlAllowedTags: ['.*'],
        htmlAllowedAttrs: ['.*'],
        pastePlain: true,
        dragInline: false,
        htmlDoNotWrapTags: ['script', 'style', 'input', 'label', 'textarea', 'select', 'button', 'form'],
        htmlExecuteScripts: false,
        pasteDeniedTags: useBase64Images ? [] : ['img'],
        pasteDeniedAttrs: useBase64Images ? [] : ['src'],
        events: {
          'image.beforeUpload': handleImageBeforeUpload,
          'image.removed': handleImageRemoved,
          drop: handleDrop,
          initialized: addIdToEditor,
          'paste.beforeCleanup': function (e: any, clipboard_html: string) {
            if (useBase64Images && clipboard_html.indexOf('<img') >= 0) {
              return clipboard_html.replace(/<img[^>]*>/g, '');
            }
            return clipboard_html;
          },
          'paste.before': handlePasteImage,
        },
        useClasses: true,
        toolbarButtons: {
          moreText: {
            buttons: [
              'paragraphFormat',
              hideFontSize ? [] : ['fontSize'],
              'bold',
              'italic',
              'underline',
              'textColor',
              'strikeThrough',
              'clearFormatting',
            ],
            buttonsVisible: 6,
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'alignRight',
              'alignJustify',
              'formatOL',
              'formatUL',
              'lineHeight',
              'outdent',
              'indent',
              'quote',
            ],
            buttonsVisible: 4,
          },
          moreRich: {
            buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertHR'],
            buttonsVisible: 4,
          },
          moreMisc: {
            buttons: isNativePlatform ? moreMisc : moreMiscFullScreen,
            align: 'right',
            buttonsVisible: 4,
          },
        },
        pluginsEnabled: isNativePlatform ? plugins : [...plugins, 'fullscreen'],
        fontSize: ['10', '12', '14', '16', '18', '20', '22', '24', '26', '28', '30', '36', '40'],
      }}
    />
  );
};
