import React from 'react';
import { ReactNode } from 'react';

import './Alert.scss';
import { PrimeIcons } from 'primereact/api';
import classNames from 'classnames';

export type AlertSeverity = 'info' | 'warning' | 'error' | 'secondary';

interface Props {
  children: ReactNode;
  className?: string;
  severity?: AlertSeverity;
}

const getAlertIcon = (severity: AlertSeverity) => {
  switch (severity) {
    case 'info':
      return PrimeIcons.INFO_CIRCLE;
    case 'warning':
      return PrimeIcons.EXCLAMATION_TRIANGLE;
    case 'error':
      return PrimeIcons.TIMES;
    case 'secondary':
      return PrimeIcons.INFO_CIRCLE;
  }
};

export const Alert = ({ children, severity = 'info', className }: Props) => {
  return (
    <div className={classNames('alert', severity, className)}>
      <i className={classNames(getAlertIcon(severity), 'alert-icon')} />
      {children}
    </div>
  );
};
