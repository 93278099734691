import React from 'react';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';

import './Routines.scss';

import { Button } from 'primereact/button';
import { ResponsiveTable } from '../../components/ResponsiveTable/ResponsiveTable';
import { TableColumn, TableAction } from '../../types/responsiveTable';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import useSWR from 'swr';
import { Routine } from '../../types/Fisiofit/routine';
import { addDays, differenceInDays } from 'date-fns';
import { PrimeIcons } from 'primereact/api';
import { RoutinePanel } from '../../components/RoutinePanel/RoutinePanel';
import { useResourcePanel } from '../../hooks/useResourcePanel';

export const Routines = () => {
  const { get } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};

  const { data, isLoading, error } = useSWR(organization ? `/organizations/${organization.id}/routines` : null, get<Routine[]>);

  const tableHeaders: TableColumn<Routine>[] = [
    {
      label: 'Nombre',
      accessor: 'name',
      type: 'string',
      isTitle: true,
    },
    {
      label: 'Duración (días)',
      accessor: 'duration',
      type: 'number',
    },
    {
      label: 'Días restantes',
      accessor: 'startDate',
      type: 'number',
      valueFromCallBack: (startDate, row) => {
        if (!startDate) return '0';
        const endDate = addDays(startDate, row.duration);
        const today = new Date();
        return Math.max(differenceInDays(endDate, today), 0).toString();
      },
    },
    {
      label: 'Tipo',
      accessor: 'startDate',
      colorFromCallBack: (value: string) => {
        return value === 'usuario' ? 'primary' : 'secondary';
      },
      textKeyFromCallBack: (startDate: string) => {
        return startDate ? 'usuario' : 'plantilla';
      },
      type: 'tag',
    },
  ];

  const options: TableAction[] = [
    {
      label: 'Ver',
      onClick: (id) => {
        openPanel(false, id);
      },
    },
    {
      label: 'Editar',
      onClick: (id) => {
        openPanel(true, id);
      },
    },
    {
      label: 'Eliminar',
    },
  ];

  const { isEditing, setIsEditing, panelVisible, setPanelVisible, selectedResource, openPanel } = useResourcePanel(data);

  return (
    <PageTemplate className='employees-page' title='Rutinas'>
      <ResponsiveTable
        data={data || []}
        columns={tableHeaders}
        options={options}
        loading={organizationLoading || isLoading}
        headerOptions={
          <Button
            label='Añadir rutina'
            outlined
            rounded
            icon={PrimeIcons.PLUS}
            onClick={() => {
              openPanel(false);
            }}
          />
        }
        onRowClick={(row) => {
          openPanel(false, row.id);
        }}
      ></ResponsiveTable>
      <RoutinePanel
        routine={selectedResource}
        visible={panelVisible}
        onHide={() => {
          setPanelVisible(false);
        }}
        onIsEditingChange={(value) => setIsEditing(value)}
        isEditing={isEditing}
      ></RoutinePanel>
    </PageTemplate>
  );
};
