import React, { FormEvent, useMemo } from "react";
import "./RoutinePanel.scss";

import { Sidebar } from "primereact/sidebar";
import { Role } from "../../types/role";
import { Button } from "primereact/button";
import { useOrganizationContext } from "../../context/OrganizationContext";
import { FeaturePicker } from "../FeaturePicker/FeaturePicker";
import { useForm } from "../../hooks/useForm";
import { FormField } from "../FormField/FormField";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";
import {
  NotificationMessage,
  RequestErrorMessages,
  useClient,
} from "../../hooks/useClient";
import { mutate } from "swr";
import { resourceMutator } from "../../utils/resourceMutation";
import { Exercise } from "../../types/Fisiofit/exercise";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "../FileUpload/FileUpload";
import { IMAGE_PLACEHOLDER } from "../../const/images";
import { MultiSelect } from "primereact/multiselect";
import { SelectItemOptionsType } from "primereact/selectitem";
import ExerciseList from "../ExerciseList/ExerciseList";
import { Routine } from "../../types/Fisiofit/routine";
import { PrimeIcons } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { add } from "date-fns";
import { Avatar } from "primereact/avatar";

const getExercisePanelTitle = (isCreating: boolean, isEditing: boolean) => {
  if (!isCreating && isEditing) {
    return "Editar ejercicio";
  }
  if (!isCreating && !isEditing) {
    return "Detalles del ejercicio";
  }
  if (isCreating && !isEditing) {
    return "Crear nuevo ejercicio";
  }
};

const exercises = [
  {
    id: 1,
    name: "Sentadillas",
    weight: "10kg",
    duration: "10 reps",
    series: "3 series",
    isEditing: false,
  },
  {
    id: 2,
    name: "Plank",
    weight: "0kg",
    duration: "1 min",
    series: "2 series",
    isEditing: false,
  },
  {
    id: 3,
    name: "Sentadillas",
    weight: "10kg",
    duration: "10 reps",
    series: "3 series",
    isEditing: false,
  },
  {
    id: 4,
    name: "Sentadillas",
    weight: "10kg",
    duration: "8 reps",
    series: "3 series",
    isEditing: false,
  },
];

const getInitialRoutineForm = (routine?: Routine): RoutineForm | undefined => {
  const { name, exercises, startDate, duration } = routine ?? {};
  return {
    name,
    exercises,
    // cambiar esto x startDate + duration
    endDate: startDate ? add(startDate, { days: duration }) : undefined,
  };
};

interface RoutineForm {
  name?: string;
  exercises?: any[];
  endDate?: Date;
}

interface Props {
  routine: Routine | undefined;
  visible: boolean;
  onHide: () => void;
  onIsEditingChange: (isEditing: boolean) => void;
  isEditing?: boolean;
}

export const RoutinePanel = ({
  routine,
  visible,
  onHide,
  onIsEditingChange,
  isEditing = false,
}: Props) => {
  const { organization } = useOrganizationContext() ?? {};
  const { post, patch } = useClient();
  const isCreating = !routine;

  const initialRoutineForm = useMemo(
    () => getInitialRoutineForm(routine),
    [routine]
  );
  const {
    form,
    setFormField,
    isSaving,
    setIsSaving,
    hasChanged,
    validationErrors,
  } = useForm<RoutineForm | undefined>(initialRoutineForm, false);

  const handleExerciseFormSave = async (e: FormEvent) => {
    e.preventDefault();
    /*const { name, muscleGroups } = form ?? {};
    if (!name || !muscleGroups || !organization) return;
    setIsSaving(true);

    const { successMessage, errorMessages } = getToastMessages(isEditing);
    const request = {
      body: {
        name,
        muscleGroups: Array.from(muscleGroups),
      },
      successMessage,
      errorMessages,
    };
    const responseRole =
      isEditing && exercise
        ? await patch<Role>(`/roles/${exercise.id}`, request)
        : await post<Role>(`/organizations/${organization.id}/roles`, request);

    if (responseRole) {
      mutate(
        `/organizations/${organization.id}/roles-table`,
        resourceMutator<Role>(responseRole),
        // The false arg makes it not refetch the data and rely only on our local mutation
        false
      );
    }*/
    setIsSaving(false);
    onHide();
  };

  const selectionMode = isCreating ? "range" : "single";

  return (
    <Sidebar
      className="routine-panel"
      position="right"
      visible={visible}
      onHide={onHide}
    >
      <h3>{getExercisePanelTitle(isCreating, isEditing)}</h3>
      {!isCreating && !isEditing && (
        <div className="readonly-form">
          <p>
            {routine?.startDate?.toString()} - {form?.endDate?.toString()}
          </p>
          <FormField labelTitle="Nombre" fullWidth>
            <p>{routine?.name}</p>
          </FormField>
          <FormField labelTitle="Duración" fullWidth>
            <p>{routine?.duration} días</p>
          </FormField>
          <ExerciseList exercises={exercises}></ExerciseList>
          <FormField labelTitle="Usuario usando la rutina" fullWidth>
            <div className="user-using-routine">
            <Avatar shape="circle" image={undefined}></Avatar><p>User name</p>
            </div>
          </FormField>
        </div>
      )}
      {(isEditing || isCreating) && (
        <form
          className={classNames({
            "form-loading": isSaving,
          })}
          onSubmit={handleExerciseFormSave}
        >
          <p>
            {routine?.startDate?.toString()} - {form?.endDate?.toString()}
          </p>
          {isCreating && (
            <div className="user-using-routine">
            <FormField labelTitle="Desea usar de base una rutina ya creada?" fullWidth>
            <Dropdown value={''} options={['test 1', 'test 2']}></Dropdown>
          </FormField>
          <Button label="Usar"></Button>
          </div>
          )}
          <FormField labelTitle="Nombre" elementId="exercise-name" fullWidth>
            <InputText
              id="exercise-name"
              className={classNames({
                "p-invalid": validationErrors?.name,
              })}
              placeholder={`Escribe el nombre del ejercicio`}
              value={form?.name ?? ""}
              onChange={(e: any) =>
                setFormField({
                  name: e.target.value,
                })
              }
            />
            <FormField labelTitle="Duración" fullWidth>
              <Calendar
                id="routine-end-date"
                value={form?.endDate ?? null}
                className={classNames({
                  "p-invalid": validationErrors?.endDate,
                })}
                placeholder="Selecciona una fecha de finalización"
                selectionMode={selectionMode as any}
                onChange={(e: any) =>
                  setFormField({
                    endDate: e.value,
                  })
                }
                showIcon
                dateFormat="dd/mm/yy"
              />{" "}
            </FormField>
          </FormField>
          <Button
            label="Añadir"
            className="add-exercise-button"
            icon={PrimeIcons.PLUS}
            outlined
          ></Button>
          <ExerciseList showButtons={true} exercises={exercises}></ExerciseList>
        </form>
      )}
      <div className="routine-panel-bottom-buttons">
        <Button
          className="pull-right pull-down"
          label={"Próxima rutina"}
          onClick={() => {}}
        />
        {!isEditing && !isCreating && (
          <Button
            className="pull-right pull-down"
            label={"Editar ejercicio"}
            onClick={() => onIsEditingChange(true)}
          />
        )}

        {(isEditing || isCreating) && (
          <Button
            className="pull-right pull-down"
            type="submit"
            label={isEditing ? "Guardar ejercicio" : "Crear ejercicio"}
            disabled={!hasChanged}
          />
        )}
      </div>
    </Sidebar>
  );
};

const getToastMessages = (
  isEditing: boolean
): {
  successMessage: NotificationMessage;
  errorMessages: RequestErrorMessages;
} => {
  return {
    successMessage: {
      summary: isEditing ? "Rol modificado" : "Rol creado",
      detail: isEditing
        ? "El ejercicio se ha modificado correctamente"
        : "Se ha creado el nuevo ejercicio",
    },
    errorMessages: {
      summary: isEditing
        ? "Error modificando el ejercicio"
        : "Error al crear el ejercicio",
      defaultDetail: isEditing
        ? "No se ha podido modificar el ejercicio"
        : "No se ha podido crear el ejercicio",
    },
  };
};
