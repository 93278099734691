import { SIGNED_DOCUMENT_TYPES } from '../types/signedDocument';

enum COMMON_FEATURES { // ORG AND ROLE
  ANALYTICS = 'analytics',
  BLOG = 'blog',
  BLOG_WRITE = 'blog-write',
  EXTERNAL_USERS = 'external-users',
  EXTERNAL_USERS_WRITE = 'external-users-write',
  RESERVATIONS = 'reservations',
  PROMOTIONS = 'promotions',
  BUSINESS_CONFIG = 'business-config',
  BUSINESS_CONFIG_WRITE = 'business-config-write',
  NOTIFICATIONS = 'notifications',
  BONUSES = 'bonuses',
  BUSINESS_ANALYTICS = 'business-analytics',
  USERS = 'users',
  USERS_WRITE = 'users-write',
  ROLES = 'roles',
  ROUTINES = 'routines',
  ROUTINES_WRITE = 'routines-write',
  EXERCISES = 'exercises',
  EXERCISES_WRITE = 'exercises-write',
}

enum ONLY_ROLE_FEATURES {
  ORG_ADMIN = 'org-admin', // all branches data
}

enum SYSTEM_FEATURES { // ORG AND ROLE
  SUPER_ADMIN = 'super-admin', // all organizations data
}

export enum SPECIAL_FEATURES {
  FISIO_RESERVATIONS_WRITE = 'rt-fisioterapia-manage',
  FISIO_RESERVATIONS_READ = 'rt-fisioterapia-view',
}

export const ALL_FEATURES = {
  ...COMMON_FEATURES,
  ...ONLY_ROLE_FEATURES,
  ...SYSTEM_FEATURES,
  ...SIGNED_DOCUMENT_TYPES,
} as const;

export type Feature = (typeof ALL_FEATURES)[keyof typeof ALL_FEATURES] | string;
