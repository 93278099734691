import React, { useMemo } from 'react';
import { ReservationType } from '../../types/reservationType';
import './CompactReservationTypeSelect.scss';
import { AdaptiveSelect } from '../AdaptiveSelect/AdaptiveSelect';

interface Props {
  value: ReservationType[] | ReservationType | null;
  onChange: (types: ReservationType[] | ReservationType | null) => void;
  reservationTypes: ReservationType[];
  multiple?: boolean;
  className?: string;
  appendTo?: HTMLElement | 'self' | (() => HTMLElement) | null;
  isFilter?: boolean;
}

interface TypeOption {
  label: string;
  value: string;
  original?: ReservationType;
}

export const CompactReservationTypeSelect = ({
  value,
  onChange,
  reservationTypes,
  multiple = false,
  className,
  appendTo = 'self',
  isFilter = false,
}: Props) => {
  const typeOptions = useMemo(() => {
    return reservationTypes.map((type) => ({
      label: type.name.charAt(0).toUpperCase() + type.name.slice(1),
      value: type.id,
      original: type,
    }));
  }, [reservationTypes]);

  const itemTemplate = (option: TypeOption | null) => {
    if (!option) return null;

    return (
      <div className='compact-type-item'>
        <span className='compact-type-name'>{option.label}</span>
      </div>
    );
  };

  const selectedItemTemplate = (option: TypeOption | null) => {
    if (!option) return null;

    if (isFilter) {
      return null;
    }

    return (
      <div className='compact-type-item'>
        <span className='compact-type-name'>{option.label}</span>
      </div>
    );
  };

  const handleChange = (selectedValue: string | string[] | null) => {
    if (selectedValue === null) {
      onChange(null);
      return;
    }

    if (Array.isArray(selectedValue)) {
      const selectedTypes = selectedValue
        .map((id) => {
          const option = typeOptions.find((opt) => opt.value === id);
          return option?.original || null;
        })
        .filter(Boolean) as ReservationType[];

      onChange(selectedTypes);
    } else {
      const option = typeOptions.find((opt) => opt.value === selectedValue);
      onChange(option?.original || null);
    }
  };

  const convertedValue = useMemo(() => {
    if (value === null) return null;

    if (Array.isArray(value)) {
      return value.map((type) => type.id);
    }

    return value.id;
  }, [value]);

  return (
    <AdaptiveSelect
      value={convertedValue}
      onChange={handleChange}
      options={typeOptions}
      className={className}
      itemTemplate={itemTemplate}
      selectedItemTemplate={selectedItemTemplate}
      isMultiple={multiple}
      emptyFilterMessage='No se encontraron tipos de reserva'
      placeholder='Buscar tipo de reserva...'
      modalTitle='Seleccionar tipo de reserva'
      searchPlaceholder='Buscar tipo de reserva'
      isFilter={isFilter}
    />
  );
};
