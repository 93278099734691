import React from 'react';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';

import './Exercises.scss';

import { Button } from 'primereact/button';
import { ResponsiveTable } from '../../components/ResponsiveTable/ResponsiveTable';
import { TableColumn, TableAction } from '../../types/responsiveTable';
import { Exercise, EXERCISE_TYPE } from '../../types/Fisiofit/exercise';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import useSWR, { mutate } from 'swr';
import { PrimeIcons } from 'primereact/api';
import { ExercisePanel } from '../../components/ExercisePanel/ExercisePanel';
import { useResourcePanel } from '../../hooks/useResourcePanel';
import { showDeleteDialog } from '../../utils/dialogUtils';
import { resourceDeleter } from '../../utils/resourceMutation';
import { capitalize } from '../../utils/textUtils';
import { ALL_FEATURES } from '../../config/features';
import { AllowedFeatures } from '../../types/features';
import { hasFeaturesPermission } from '../../utils/featureUtils';
import { useAuthContext } from '../../context/AuthContext';

export const getExerciseDeleteConfirmationMessage = (exercise: Exercise | undefined) => {
  const routineUsesText = exercise?.usesCount
    ? `Actualmente está siendo usado en ${exercise?.usesCount > 1 ? `${exercise?.usesCount} rutinas. Desaparecerá de todas ellas` : 'una rutina. Desaparecerá de ella'}.\n\n`
    : '';
  return (
    <>
      ¿Estás seguro de que quieres eliminar el ejercicio <b>{exercise?.name}</b>?{'\n\n'}
      {routineUsesText}Seguirá apareciendo en rutinas del historial.
    </>
  );
};

const tableHeaders: TableColumn<Exercise>[] = [
  {
    label: 'Nombre',
    accessor: 'name',
    type: 'string',
    isTitle: true,
  },
  {
    label: 'Usos en rutina',
    accessor: 'usesCount',
    type: 'number',
  },
  {
    label: 'Músculos',
    accessor: 'muscleGroups',
    type: 'dropdown',
    valueFromCallBack: (_, row) => {
      return row.muscleGroups?.map(capitalize) ?? [];
    },
  },
  {
    label: 'Tipo',
    accessor: 'exerciseType',
    type: 'tag',
    colorFromCallBack: (_, { exerciseType }) => {
      return exerciseType === EXERCISE_TYPE.REPS ? 'info' : 'secondary';
    },
    textKeyFromCallBack: (_, { exerciseType }) => {
      return exerciseType === EXERCISE_TYPE.REPS ? 'Repeticiones' : 'Tiempo';
    },
  },
];

export const Exercises = () => {
  const { get, delete: del } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};
  const { features, featuresLoading } = useAuthContext() ?? {};
  const { data, isLoading } = useSWR(organization ? `/organizations/${organization.id}/exercises` : null, get<Exercise[]>);
  const {
    isEditing,
    setIsEditing,
    panelVisible,
    closePanel,
    selectedResource,
    openPanel,
    isDeleting,
    setIsDeleting,
    getResourceById,
  } = useResourcePanel(data);

  const userHasCreateFeature = hasFeaturesPermission([ALL_FEATURES.EXERCISES_WRITE], { features, featuresLoading });

  const allowedFeatures: AllowedFeatures = {
    edit: [ALL_FEATURES.EXERCISES_WRITE],
    delete: [ALL_FEATURES.EXERCISES_WRITE],
    create: [ALL_FEATURES.EXERCISES_WRITE],
  };

  const handleDelete = async (id: string | undefined) => {
    if (!id || !organization?.id) return false;
    setIsDeleting(true);
    const url = `/exercises/${id}`;
    const response = await del<Exercise>(url, {
      errorMessages: {
        summary: 'No se pudo eliminar el ejercicio',
        defaultDetail: 'Hubo un error al intentar borrar el ejercicio',
      },
      successMessage: {
        summary: 'Ejercicio eliminado',
        detail: 'El ejercicio se ha eliminado correctamente',
      },
      handlers: {
        defaultSuccess: () => {
          mutate(`/organizations/${organization.id}/exercises`, resourceDeleter(id));
        },
      },
    });

    setIsDeleting(false);
    return !!response;
  };

  const options: TableAction[] = [
    {
      label: 'Ver',
      onClick: (id) => {
        openPanel(false, id);
      },
      hasPermission: hasFeaturesPermission([ALL_FEATURES.EXERCISES], { features, featuresLoading }),
    },
    {
      label: 'Editar',
      onClick: (id) => {
        openPanel(true, id);
      },
      hasPermission: userHasCreateFeature,
    },
    {
      label: 'Eliminar',
      onClick: (id) => {
        const exerciseToDelete = getResourceById(id);
        showDeleteDialog(() => handleDelete(id), {
          header: 'Eliminar ejercicio',
          message: getExerciseDeleteConfirmationMessage(exerciseToDelete),
        });
      },
      hasPermission: userHasCreateFeature,
    },
  ];

  return (
    <PageTemplate className='employees-page' title='Ejercicios'>
      <ResponsiveTable
        disabled={isDeleting}
        data={data || []}
        columns={tableHeaders}
        options={options}
        loading={organizationLoading || isLoading}
        headerOptions={
          userHasCreateFeature && (
            <Button
              label='Añadir ejercicio'
              outlined
              rounded
              icon={PrimeIcons.PLUS}
              onClick={() => {
                openPanel(false);
              }}
            />
          )
        }
        onRowClick={(row) => {
          openPanel(false, row.id);
        }}
      ></ResponsiveTable>
      <ExercisePanel
        resource={selectedResource}
        visible={panelVisible}
        onHide={closePanel}
        onIsEditingChange={setIsEditing}
        onDeleteResource={handleDelete}
        isDeleting={isDeleting}
        isEditing={isEditing}
        allowedFeatures={allowedFeatures}
      ></ExercisePanel>
    </PageTemplate>
  );
};
