import React, { useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import './ExternalUserSignedDocuments.scss';
import { usePopups } from '../../context/PopupContext';
import {
  CreateSignedDocumentPayload,
  DocumentPayload,
  SIGNED_DOCUMENT_TYPES,
  SignedDocument,
  SignedDocumentType,
} from '../../types/signedDocument';
import { FisiofitPhysiotherapyConsent } from '../SignedDocuments/FisiofitPhysiotherapyConsent';
import { FisiofitGdpr } from '../SignedDocuments/FisiofitGdpr';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { ExternalUser } from '../../types/externalUser';
import { Tooltip } from 'react-tooltip';
import { hasFeaturesPermission } from '../../utils/featureUtils';
import { useAuthContext } from '../../context/AuthContext';
import { useFeaturesToggle } from '../../hooks/useFeaturesToggle';
import { confirmDialog } from 'primereact/confirmdialog';
import { mutate } from 'swr';
import { downloadFile } from '../../utils/downloadUtils';
import { FisiofitGdprChild } from '../SignedDocuments/FisiofitGdprChild';
import { FisiofitImageConsent } from '../SignedDocuments/FisiofitImageConsent';
import { Badge } from 'primereact/badge';
import classNames from 'classnames';

interface Props {
  externalUser: ExternalUser | undefined;
}

const getIp = async (): Promise<string> => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error fetching IP:', error);
    return '0.0.0.0'; // Fallback IP
  }
};

export const ExternalUserSignedDocuments = ({ externalUser }: Props) => {
  const { organization } = useOrganizationContext() ?? {};
  const { features, featuresLoading } = useAuthContext() ?? {};
  const { showToast } = usePopups() ?? {};
  const { post, patch } = useClient();

  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<SignedDocumentType | null>(null);

  const handleOpenConsent = (documentType: SignedDocumentType) => {
    setSelectedDocument(documentType);
  };

  const handleCloseConsent = () => {
    setSelectedDocument(null);
  };

  const documentTypes = useMemo(
    () => externalUser?.signedDocuments?.map((document) => document.documentType) ?? [],
    [externalUser?.signedDocuments],
  );

  const hasAnyDocumentPermission = useFeaturesToggle(documentTypes);

  const handleSaveConsent = async (
    documentPayload: DocumentPayload,
    signature: string,
    signingLocation: string,
  ): Promise<boolean> => {
    const ipAddress = await getIp();
    if (!selectedDocument || !externalUser || !organization) return false;

    const payload: CreateSignedDocumentPayload = {
      documentType: selectedDocument,
      signedAt: new Date().toISOString(),
      signingLocation,
      ipAddress,
      documentPayload,
      signature,
      externalUserId: externalUser.id,
      organizationId: organization.id,
    };

    const response = await post<SignedDocument>('/signed-documents', {
      body: payload,
      successMessage: {
        summary: 'Documento guardado',
        detail: 'El documento se ha guardado correctamente',
      },
      errorMessages: {
        summary: 'Error al guardar documento',
        defaultDetail: 'No se ha podido guardar el documento',
      },
    });

    if (response) {
      setSelectedDocument(null);
      mutate(`/organizations/${organization.id}/external-users/${externalUser.id}`);
    }

    return !!response;
  };

  const renderConsentForm = () => {
    switch (selectedDocument) {
      case SIGNED_DOCUMENT_TYPES.FISIOFIT_PHYSIOTHERAPY_CONSENT:
        return <FisiofitPhysiotherapyConsent visible={true} onHide={handleCloseConsent} onSave={handleSaveConsent} />;
      case SIGNED_DOCUMENT_TYPES.FISIOFIT_GDPR:
        return <FisiofitGdpr visible={true} onHide={handleCloseConsent} onSave={handleSaveConsent} />;
      case SIGNED_DOCUMENT_TYPES.FISIOFIT_GDPR_CHILD:
        return <FisiofitGdprChild visible={true} onHide={handleCloseConsent} onSave={handleSaveConsent} />;
      case SIGNED_DOCUMENT_TYPES.FISIOFIT_IMAGE_CONSENT:
        return <FisiofitImageConsent visible={true} onHide={handleCloseConsent} onSave={handleSaveConsent} />;
      default:
        return null;
    }
  };

  const handleDownloadDocument = async (documentTitle: string | undefined, downloadUrl: string | undefined) => {
    if (!documentTitle || !organization || !downloadUrl) return;

    await downloadFile({
      url: downloadUrl,
      fileName: `${documentTitle} - ${externalUser?.name} ${externalUser?.surnames}.pdf`,
      onSuccess: () => {
        showToast?.({
          severity: 'info',
          summary: 'Documento descargado',
        });
      },
      onError: () => {
        showToast?.({
          severity: 'error',
          summary: 'Error al descargar',
          detail: 'No se ha podido descargar el documento. Por favor, inténtalo de nuevo.',
        });
      },
    });
  };

  const revokeSignedDocument = async (documentId: string) => {
    if (!organization || !externalUser) return;
    const response = await patch(`/signed-documents/${documentId}/revoke`, {
      successMessage: {
        summary: 'Documento revocado',
        detail: 'El documento se ha revocado correctamente',
      },
      errorMessages: {
        summary: 'Error al revocar documento',
        defaultDetail: 'No se ha podido revocar el documento',
      },
    });

    if (response) {
      mutate(`/organizations/${organization.id}/external-users/${externalUser.id}`);
    }
  };

  const handleRevokeDocument = (documentId: string | undefined) => {
    if (!documentId) return;
    confirmDialog({
      message:
        '¿Estás seguro de que quieres revocar este documento? No se podrá visualizar, pero se mantendrán los datos por requisitos legales. El usuario podrá volver a firmarlo.',
      header: 'Revocar documento firmado',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sí, revocar',
      rejectLabel: 'No',
      acceptClassName: 'p-button-danger',
      accept: () => revokeSignedDocument(documentId),
      draggable: false,
      resizable: false,
    });
  };

  if (!hasAnyDocumentPermission) return null;

  const signedDocumentsCount = externalUser?.signedDocuments?.filter((doc) => doc.isSigned && !doc.isRevoked)?.length ?? 0;
  const totalDocuments = externalUser?.signedDocuments?.length ?? 0;

  return (
    <div className='external-user-signed-documents'>
      <div className='signed-documents' onClick={() => setIsExpanded(!isExpanded)}>
        <div className='header-content'>
          <i
            className={classNames('pi', {
              'pi-chevron-down': isExpanded,
              'pi-chevron-right': !isExpanded,
            })}
          />
          <strong>Consentimientos</strong>
          <Badge
            value={`${signedDocumentsCount}/${totalDocuments}`}
            severity={signedDocumentsCount === totalDocuments ? 'success' : 'info'}
          />
        </div>
      </div>

      {isExpanded && (
        <div className='document-list'>
          {externalUser?.signedDocuments?.map((signedDocument) => {
            const hasPermission = hasFeaturesPermission([signedDocument.documentType], {
              features,
              featuresLoading,
            });
            const tooltipId = `${signedDocument.documentType}-tooltip`;

            return (
              <div key={signedDocument.documentType} className='document-item'>
                <div className='document-info'>
                  <i
                    className={classNames('pi', {
                      'pi-check-circle': signedDocument.isSigned,
                      'pi-file': !signedDocument.isSigned,
                      success: signedDocument.isSigned,
                      primary: !signedDocument.isSigned,
                    })}
                  />
                  <span className='document-title'>{signedDocument.title}</span>
                  {signedDocument.isSigned && (
                    <div className='signed-document-message'>
                      <i className='pi pi-check' />
                      <p>Firmado</p>
                    </div>
                  )}
                </div>
                {signedDocument.isSigned ? (
                  <div className='signed-document-buttons'>
                    <Tooltip
                      hidden={hasPermission}
                      anchorSelect={`#${tooltipId}-download`}
                      content='No tienes permiso para descargar este documento'
                    />
                    <div id={`${tooltipId}-download`}>
                      {signedDocument.downloadUrl && (
                        <Button
                          className='signed-document-button'
                          icon={PrimeIcons.DOWNLOAD}
                          onClick={() => handleDownloadDocument(signedDocument.title, signedDocument.downloadUrl)}
                          text
                          rounded
                          disabled={!hasPermission || !signedDocument.downloadUrl}
                        />
                      )}
                    </div>
                    <Tooltip
                      hidden={hasPermission}
                      anchorSelect={`#${tooltipId}-revoke`}
                      content='No tienes permiso para revocar este documento'
                    />
                    <div id={`${tooltipId}-revoke`}>
                      {!signedDocument.isRevoked && (
                        <Button
                          className='signed-document-button'
                          icon={PrimeIcons.TIMES}
                          onClick={() => handleRevokeDocument(signedDocument.id)}
                          text
                          rounded
                          severity='danger'
                          disabled={!hasPermission}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <Tooltip
                      hidden={hasPermission}
                      anchorSelect={`#${tooltipId}-sign`}
                      content='No tienes permiso para firmar este documento'
                    />
                    <div id={`${tooltipId}-sign`}>
                      <Button
                        label='Firmar'
                        icon='pi pi-pen-to-square'
                        onClick={() => handleOpenConsent(signedDocument.documentType)}
                        text
                        size='small'
                        disabled={!hasPermission}
                      />
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      )}

      {selectedDocument && renderConsentForm()}
    </div>
  );
};
