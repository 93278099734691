import React, { useRef } from 'react';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/languages/es.js';

import Froala from 'react-froala-wysiwyg';
import { Capacitor } from '@capacitor/core';
import { useClient } from '../../hooks/useClient';
import './RichTextEditor.scss';
import { useWebsiteContext } from '../../context/WebsiteContext';

export interface UploadImageResponse {
  imageUrl: string;
}

interface Props {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
}

const plugins = [
  'table',
  'spell',
  'quote',
  'save',
  'quickInsert',
  'paragraphFormat',
  'paragraphStyle',
  'help',
  'draggable',
  'align',
  'link',
  'lists',
  'file',
  'image',
  'emoticons',
  'fontSize',
  'url',
  'video',
  'embedly',
  'colors',
  'entities',
  'inlineClass',
  'inlineStyle',
];
const moreMisc = ['undo', 'redo', 'help'];
const moreMiscFullScreen = ['undo', 'redo', 'fullscreen', 'help'];

export const RichTextEditor = ({ value, onChange, placeholder }: Props) => {
  const { website } = useWebsiteContext() ?? {};
  const client = useClient();
  const editorRef = useRef<Froala | null>(null);

  const handleModelChange = (updatedModel: string) => {
    onChange?.(updatedModel);
  };

  const isNativePlatform = Capacitor.isNativePlatform();

  const handleImageBeforeUpload = (image: any) => {
    const formData = new FormData();
    formData.append('image', image[0]);
    const editor = editorRef.current?.getEditor();

    client
      .post<UploadImageResponse>(`/websites/${website?.id}/upload`, {
        body: formData,
      })
      .then((response) => {
        editor.image.insert(response?.imageUrl);
      });

    return false;
  };

  const handleImageRemoved = ($img: any) => {
    const imageUrl = $img.attr('src');
    const body = {
      imageUrls: [imageUrl],
    };
    client.post(`/websites/${website?.id}/delete-images`, { body });
  };

  return (
    <Froala
      // pass react key so that if website id changes the config is updated
      // if config becomes dependent on other values they need to be added to key
      key={website?.id}
      ref={editorRef}
      model={value}
      onModelChange={handleModelChange}
      tag='textarea'
      config={{
        toolbarSticky: false,
        language: 'es',
        attribution: false,
        imageUpload: true,
        imageUploadParam: 'file',
        videoUpload: false,
        videoInsertButtons: ['videoByURL', 'videoEmbed'],
        events: {
          'image.beforeUpload': handleImageBeforeUpload,
          'image.removed': handleImageRemoved,
        },
        useClasses: true,
        placeholder,
        toolbarButtons: {
          moreText: {
            buttons: [
              'paragraphFormat',
              'fontSize',
              'bold',
              'italic',
              'underline',
              'textColor',
              'strikeThrough',
              'clearFormatting',
            ],
            buttonsVisible: 6,
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'alignRight',
              'alignJustify',
              'formatOL',
              'formatUL',
              'lineHeight',
              'outdent',
              'indent',
              'quote',
            ],
            buttonsVisible: 4,
          },
          moreRich: {
            buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertHR'],
            buttonsVisible: 4,
          },
          moreMisc: {
            buttons: isNativePlatform ? moreMisc : moreMiscFullScreen,
            align: 'right',
            buttonsVisible: 4,
          },
        },
        pluginsEnabled: isNativePlatform ? plugins : [...plugins, 'fullscreen'],
        fontSize: ['16', '18', '20', '22', '24'],
      }}
    />
  );
};
