import React, { useState } from 'react';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { MultiSelect, MultiSelectProps } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Capacitor } from '@capacitor/core';
import { classNames } from 'primereact/utils';
import { UserDisplay } from '../UserDisplay/UserDisplay';
import './AdaptiveSelect.scss';

const normalizeText = (text: string): string => {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

interface SelectOption {
  value: string;
  label: string;
}

interface AdaptiveSelectProps extends Omit<DropdownProps & MultiSelectProps, 'onChange'> {
  onChange: (value: any) => void;
  itemTemplate: (option: any) => React.ReactNode;
  searchPlaceholder?: string;
  modalTitle?: string;
  placeholder?: string;
  isMultiple?: boolean;
  selectedItemTemplate?: (option: any) => React.ReactNode;
  invalid?: boolean;
  loading?: boolean;
  disabled?: boolean;
  emptyMessage?: string;
  emptyFilterMessage?: string;
  isFilter?: boolean;
}

export const AdaptiveSelect: React.FC<AdaptiveSelectProps> = ({
  options = [],
  value,
  onChange,
  itemTemplate,
  searchPlaceholder = 'Buscar...',
  modalTitle = 'Seleccionar',
  placeholder = 'Seleccionar',
  className,
  isMultiple = false,
  selectedItemTemplate,
  invalid,
  loading,
  disabled,
  emptyMessage = 'No hay opciones disponibles',
  emptyFilterMessage = 'No se han encontrado resultados',
  isFilter = false,
  ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const isTouchDevice = Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches;

  const filteredOptions = options?.filter((option) => {
    if (!searchValue) return true;
    return normalizeText(option.label || '').includes(normalizeText(searchValue));
  });

  const selectedOptions = isMultiple
    ? options?.filter((opt) => Array.isArray(value) && value.includes(opt.value))
    : options?.find((opt) => opt.value === value);

  const handleOptionClick = (option: any) => {
    if (isMultiple) {
      const currentValue = Array.isArray(value) ? value : [];
      const newValue = currentValue.includes(option.value)
        ? currentValue.filter((v) => v !== option.value)
        : [...currentValue, option.value];
      onChange(newValue);
    } else {
      onChange(option.value);
      setModalVisible(false);
    }
  };

  const handleSelectAll = () => {
    if (!isMultiple) return;
    const allSelected = options.length === (Array.isArray(value) ? value.length : 0);
    onChange(allSelected ? [] : options.map((opt) => opt.value));
  };

  if (!isTouchDevice) {
    return isMultiple ? (
      <MultiSelect
        {...props}
        options={options}
        value={value}
        onChange={(e) => onChange(e.value)}
        itemTemplate={itemTemplate}
        selectedItemTemplate={selectedItemTemplate}
        placeholder={placeholder}
        className={classNames(className, { 'p-invalid': invalid })}
        loading={loading}
        disabled={disabled}
        filter
        filterPlaceholder={searchPlaceholder}
        emptyMessage={emptyMessage}
        emptyFilterMessage={emptyFilterMessage}
      />
    ) : (
      <Dropdown
        {...props}
        options={options}
        value={value}
        onChange={(e) => onChange(e.value)}
        itemTemplate={itemTemplate}
        placeholder={placeholder}
        className={classNames(className, { 'p-invalid': invalid })}
        loading={loading}
        disabled={disabled}
        filter
        filterPlaceholder={searchPlaceholder}
        emptyMessage={emptyMessage}
        emptyFilterMessage={emptyFilterMessage}
      />
    );
  }

  const renderTriggerContent = () => {
    if (isFilter) {
      return null;
    }

    if (isMultiple) {
      if (!Array.isArray(value) || value.length === 0) return placeholder;

      interface SelectedItemContent {
        type: 'avatar' | 'text';
        content: JSX.Element | string;
      }

      const selectedItems = selectedOptions.map((option: any) => {
        const content = selectedItemTemplate ? selectedItemTemplate(option) : itemTemplate(option);
        if (React.isValidElement(content) && content.type === UserDisplay) {
          return {
            type: 'avatar',
            content: (
              <div key={option.value} className='selected-item with-avatar'>
                {content}
              </div>
            ),
          } as SelectedItemContent;
        }
        return {
          type: 'text',
          content: option.label,
        } as SelectedItemContent;
      });

      const textItems = selectedItems
        .filter((item: SelectedItemContent) => item.type === 'text')
        .map((item: SelectedItemContent) => item.content as string);
      const avatarItems = selectedItems
        .filter((item: SelectedItemContent) => item.type === 'avatar')
        .map((item: SelectedItemContent) => item.content as JSX.Element);

      return (
        <div className='selected-items'>
          {textItems.length > 0 && (
            <div className='text-items'>
              {textItems.map((text: string, index: number) => (
                <span key={index}>{text}</span>
              ))}
            </div>
          )}
          {avatarItems}
        </div>
      );
    }

    if (selectedItemTemplate && selectedOptions) {
      return selectedItemTemplate(selectedOptions);
    }

    return selectedOptions ? itemTemplate(selectedOptions) : placeholder;
  };

  return (
    <>
      <div
        className={classNames(
          'adaptive-select-trigger',
          {
            placeholder: isMultiple ? !value?.length : !value,
            'p-invalid': invalid,
            disabled,
            'is-filter': isFilter,
          },
          className,
        )}
        onClick={() => !disabled && setModalVisible(true)}
      >
        {renderTriggerContent()}
      </div>

      <Dialog
        visible={modalVisible}
        onHide={() => {
          setModalVisible(false);
          setSearchValue('');
        }}
        header={modalTitle}
        className='adaptive-select-modal big-modal'
        draggable={false}
        resizable={false}
      >
        <div className='adaptive-select-content'>
          <div className='adaptive-select-search'>
            <InputText value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={searchPlaceholder} />
          </div>
          <div className='adaptive-select-options'>
            {isMultiple && options.length > 0 && (
              <div className='select-all-option' onClick={() => handleSelectAll()}>
                <Checkbox
                  checked={options.length === (Array.isArray(value) ? value.length : 0)}
                  onChange={() => handleSelectAll()}
                />
                <span>Seleccionar todos</span>
              </div>
            )}
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div
                  key={option.value}
                  className={classNames('adaptive-select-option', {
                    selected: isMultiple ? Array.isArray(value) && value.includes(option.value) : option.value === value,
                  })}
                  onClick={() => handleOptionClick(option)}
                >
                  {isMultiple && (
                    <Checkbox
                      checked={Array.isArray(value) && value.includes(option.value)}
                      onChange={() => handleOptionClick(option)}
                    />
                  )}
                  {itemTemplate(option)}
                </div>
              ))
            ) : (
              <div className='adaptive-select-empty'>{searchValue ? emptyFilterMessage : emptyMessage}</div>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};
