import React, { useRef, useState } from "react";
import {
  Calendar,
  dateFnsLocalizer,
  Views,
  EventProps,
} from "react-big-calendar";
import { format, parse, startOfWeek, getDay, parseISO } from "date-fns";
import { es } from "date-fns/locale/es";
import { ContextMenu } from "primereact/contextmenu";
import { Tooltip } from "primereact/tooltip";
import "./Reservations.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Card } from "primereact/card";
import { PageTemplate } from "../../layout/PageTemplate/PageTemplate";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import useSWR from "swr";
import { Reservation } from "../../types/reservation";
import { useOrganizationContext } from "../../context/OrganizationContext";
import { useClient } from "../../hooks/useClient";
import { useAuthContext } from "../../context/AuthContext";
import { ReservationType } from "../../types/reservationType";
import { capitalize } from "../../utils/textUtils";
import {
  RESERVATION_COLORS,
  ReservationTypeWithColor,
  useReservationTypes,
} from "../../hooks/DataFetching/useReservationTypes";
import { ReservationPanel } from "../../components/ReservationPanel/ReservationPanel";
import { useResourcePanel } from "../../hooks/useResourcePanel";

interface MenuItem {
  label: string;
  icon?: string;
  command: () => void;
}

const getSingleEventTitle = (
  reservation: Reservation,
  reservationType: ReservationType
) => {
  const eventExternalUser = reservation.externalUsers[0];
  return `${capitalize(reservationType.name)} - ${eventExternalUser.name} ${
    eventExternalUser.surnames
  }`;
};
const getGroupEventTitle = (
  reservation: Reservation,
  reservationType: ReservationType
) => {
  return `${capitalize(reservationType.name)} - (${
    reservation.externalUsers.length
  }/${reservationType.groupSize})`;
};

const locales = { es };

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const messsagesLocale = {
  week: "Semana",
  work_week: "Semana de trabajo",
  day: "Día",
  month: "Mes",
  previous: "Anterior",
  next: "Siguiente",
  today: "Hoy",
  agenda: "Agenda",
  showMore: (total: number) => `+${total} más`,
};

export const Reservations = () => {
  const menu = useRef<ContextMenu>(null);
  const { get } = useClient();
  // TODO loading states for this page. Does the calendar component have a loading prop?
  const { user, userLoading } = useAuthContext() ?? {};
  const { organization, organizationLoading } = useOrganizationContext() ?? {};

  const [selectedReservation, setSelectedReservation] = useState<Reservation>();

  const { reservationTypesLoading, reservationTypeFromId } =
    useReservationTypes();

  const { data, isLoading } = useSWR(
    organization ? `/organizations/${organization.id}/reservations` : null,
    get<Reservation[]>
  );

  const minTime = new Date();
  minTime.setHours(7, 0, 0);
  const maxTime = new Date();
  maxTime.setHours(22, 0, 0);

  const defaultDate = new Date();

  const menuItems: MenuItem[] = [
    {
      label: "Ver",
      icon: undefined,
      command: () => onView(selectedReservation),
    },
    {
      label: "Editar",
      command: () => onEdit(selectedReservation),
    },
    {
      label: "Eliminar",
      command: () => onDelete(selectedReservation),
    },
  ];

  const onView = (reservation: Reservation | undefined) => {
    if (reservation) openPanel(false, reservation.id);
  };
  const onEdit = (reservation: Reservation | undefined) => {
    if (reservation) openPanel(true, reservation.id);
  };
  const onDelete = (reservation: Reservation | undefined) => {
    if (reservation) alert(`Eliminar evento: ${reservation.externalUsers}`);
  };

  const eventPropGetter = (reservation: Reservation) => {
    const reservationType = reservationTypeFromId(
      reservation.reservationTypeId
    );
    const color = reservationType?.color ?? RESERVATION_COLORS[0];
    return {
      style: {
        backgroundColor: `var(--${color}-400)`,
        border: `1px solid var(--${color}-500)`,
      },
    };
  };

  const eventRenderer = ({ event: reservation }: EventProps<Reservation>) => {
    const tooltipId = `event-${reservation.id}`;
    const reservationType = reservationTypeFromId(
      reservation.reservationTypeId
    );

    if (!reservationType) return <></>;
    const title =
      reservationType.groupSize === 1
        ? getSingleEventTitle(reservation, reservationType)
        : getGroupEventTitle(reservation, reservationType);

    return (
      <div id={tooltipId} className="calendar-event-container">
        <div className="calendar-event-inside">{title}</div>
        <Tooltip
          target={`#${tooltipId}`}
          position="top"
          style={{ maxWidth: "300px" }}
        >
          <div>
            <strong>{title}</strong>
            <div>
              Inicio:{" "}
              {parseISO(reservation.startDate).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
            <div>
              Fin:{" "}
              {parseISO(reservation.endDate).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
          </div>
        </Tooltip>
      </div>
    );
  };

  const {
    isEditing,
    setIsEditing,
    panelVisible,
    setPanelVisible,
    selectedResource,
    openPanel,
  } = useResourcePanel(data);

  return (
    <PageTemplate className="reservation-page" title="Reservas">
      <Card
        className="reservation-card"
        header={
          <Button
            label="Crear reserva"
            outlined
            rounded
            icon={PrimeIcons.PLUS}
            onClick={() => {
              openPanel(false);
            }}
          />
        }
      >
        <ContextMenu model={menuItems} ref={menu} />
        <Calendar
          dayLayoutAlgorithm="no-overlap"
          localizer={localizer}
          events={data ?? []}
          startAccessor={(reservation) => parseISO(reservation.startDate)}
          endAccessor={(reservation) => parseISO(reservation.endDate)}
          step={30}
          timeslots={1}
          messages={messsagesLocale}
          className="reservation-calendar"
          defaultView={Views.WEEK}
          max={maxTime}
          min={minTime}
          defaultDate={defaultDate}
          scrollToTime={(() => {
            const adjustedTime = new Date(
              new Date().setHours(new Date().getHours() - 1, 0, 0, 0)
            );
            return adjustedTime < minTime ? minTime : adjustedTime;
          })()}
          eventPropGetter={eventPropGetter}
          components={{
            event: eventRenderer,
            //toolbar: CustomToolbar,
          }}
          onSelectEvent={(event, e) => {
            setSelectedReservation(event);
            menu.current?.show(e);
          }}
          dayPropGetter={(date) => {
            // NOTA: 0 es domingo, 6 es sábado
            const day = getDay(date);
            if (day === 0 || day === 6) {
              return {
                className: "disabled-day",
                style: { backgroundColor: "#f0f0f0", color: "#ccc" },
              };
            }
            return {};
          }}
        />
      </Card>
      <ReservationPanel
        reservation={selectedResource}
        visible={panelVisible}
        onHide={() => {
          setPanelVisible(false);
        }}
        onIsEditingChange={(value) => setIsEditing(value)}
        isEditing={isEditing}
      ></ReservationPanel>
    </PageTemplate>
  );
};
