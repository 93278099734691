import React, { useMemo, useEffect, useState } from 'react';
import './UsersList.scss';

import { useLocation } from 'react-router-dom';
import { ResponsiveTable } from '../../../components/ResponsiveTable/ResponsiveTable';
import { TableColumn, TableAction, TagColor } from '../../../types/responsiveTable';
import { EXTERNAL_USER_STATUSES, ExternalUserTableEntry, USER_CURRENT_ROUTINE_STATUS } from '../../../types/externalUser';
import useSWR, { mutate } from 'swr';
import { useOrganizationContext } from '../../../context/OrganizationContext';
import { useClient } from '../../../hooks/useClient';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { ExternalUserPanel } from '../../../components/ExternalUserPanel/ExternalUserPanel';
import { useResourcePanel } from '../../../hooks/useResourcePanel';
import { Organization } from '../../../types/organization';
import { ALL_FEATURES, Feature, SPECIAL_FEATURES } from '../../../config/features';
import { useAuthContext } from '../../../context/AuthContext';
import { AllowedFeatures } from '../../../types/features';
import { Chip } from '../../../components/Chip/Chip';
import { hasFeaturesPermission } from '../../../utils/featureUtils';
import { format } from 'date-fns';
import { ReservationPanel } from '../../../components/ReservationPanel/ReservationPanel';
import { useReservationTypes } from '../../../hooks/AppData/useReservationTypes';
import { ExternalUser } from '../../../types/externalUser';
import { RoutinePanel } from '../../../components/RoutinePanel/RoutinePanel';
import { Routine } from '../../../types/Fisiofit/routine';
import { useNavigateScroll } from '../../../hooks/useNavigateScroll';
import { getCommonRestrictions } from '../../Reservations/Reservations';

const ROUTINE_STATUS_LABELS: Record<USER_CURRENT_ROUTINE_STATUS, string> = {
  [USER_CURRENT_ROUTINE_STATUS.NOT_STARTED]: 'No iniciada',
  [USER_CURRENT_ROUTINE_STATUS.IN_PROGRESS]: 'En curso',
  [USER_CURRENT_ROUTINE_STATUS.EXPIRED]: 'Caducada',
  [USER_CURRENT_ROUTINE_STATUS.NO_ROUTINE]: 'Sin rutina',
};

const ROUTINE_STATUS_COLORS: Record<USER_CURRENT_ROUTINE_STATUS, TagColor> = {
  [USER_CURRENT_ROUTINE_STATUS.NOT_STARTED]: 'contrast',
  [USER_CURRENT_ROUTINE_STATUS.IN_PROGRESS]: 'info',
  [USER_CURRENT_ROUTINE_STATUS.EXPIRED]: 'danger',
  [USER_CURRENT_ROUTINE_STATUS.NO_ROUTINE]: 'warning',
};

const getTableColumns = (organization: Organization | undefined, features: Feature[] | undefined) => {
  const tableColumns: TableColumn<ExternalUserTableEntry>[] = [
    {
      label: '',
      accessor: 'avatarUrl',
      type: 'avatar',
    },
    {
      label: 'Nombre',
      accessor: 'name',
      type: 'string',
      isTitle: true,
      valueFromCallBack: (name, row) => `${name} ${row.surnames}`,
      renderSuffix: (name, row) => (row.pendingInvitation ? <Chip>Pendiente</Chip> : null),
    },
    {
      label: 'Sucursal',
      accessor: 'branch',
      type: 'dropdown',
      valueFromCallBack: (branch) => branch?.name,
    },
    {
      label: 'Supervisor',
      accessor: 'supervisor',
      type: 'dropdown',
      valueFromCallBack: (supervisor) => supervisor?.name,
      hideColumn: !organization?.config?.hasSupervisor,
    },
    {
      label: 'Rutina',
      accessor: 'currentRoutine',
      type: 'tag',
      textKeyFromCallBack: (_, { currentRoutine }) => {
        if (!currentRoutine) return;
        return ROUTINE_STATUS_LABELS[currentRoutine?.status];
      },
      colorFromCallBack: (_, { currentRoutine }) => {
        if (!currentRoutine) return;
        return ROUTINE_STATUS_COLORS[currentRoutine?.status];
      },
      hideColumn: !organization?.features.includes(ALL_FEATURES.ROUTINES) || !features?.includes(ALL_FEATURES.ROUTINES),
    },
    {
      label: 'Última visita',
      accessor: 'latestReservationDate',
      type: 'calendar',
      valueFromCallBack: (_, row) => {
        return row.latestReservationDate ? format(new Date(row.latestReservationDate), "d 'de' MMMM") : '-';
      },
    },
    {
      label: 'Estado',
      accessor: 'status',
      textKeyFromCallBack: (status: EXTERNAL_USER_STATUSES) => (status === EXTERNAL_USER_STATUSES.ACTIVE ? 'Activo' : 'Inactivo'),
      colorFromCallBack: (status: EXTERNAL_USER_STATUSES) => {
        return status === EXTERNAL_USER_STATUSES.ACTIVE ? 'success' : 'danger';
      },
      type: 'tag',
    },
    {
      label: 'Usa app',
      accessor: 'isAppUser',
      type: 'boolean',
    },
  ];

  return tableColumns;
};

const UsersList = () => {
  const navigate = useNavigateScroll();
  const { get } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};
  const { features, featuresLoading } = useAuthContext() ?? {};
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const userHasEditFeature = hasFeaturesPermission([ALL_FEATURES.EXTERNAL_USERS_WRITE], { features, featuresLoading });

  const { data, isLoading } = useSWR(
    organization ? `/organizations/${organization.id}/external-users` : null,
    get<ExternalUserTableEntry[]>,
  );
  const { isEditing, setIsEditing, panelVisible, closePanel, openPanel, isDeleting } = useResourcePanel(data);

  // Estados para el ReservationPanel
  const [reservationPanelVisible, setReservationPanelVisible] = useState(false);
  const [editingReservation, setEditingReservation] = useState(false);
  const [preselectedExternalUser, setPreselectedExternalUser] = useState<ExternalUser | undefined>();

  // Estados para el RoutinePanel
  const [routinePanelVisible, setRoutinePanelVisible] = useState(false);
  const [selectedRoutine, setSelectedRoutine] = useState<Routine | undefined>();
  const [editingRoutine, setEditingRoutine] = useState(false);

  // Obtener tipos de reserva
  const { reservationTypes, getMainReservationType } = useReservationTypes();
  const mainReservationType = useMemo(() => {
    return getMainReservationType();
  }, [getMainReservationType]);

  const calendarRestrictions = useMemo(() => {
    return getCommonRestrictions(reservationTypes, null, mainReservationType ?? null);
  }, [reservationTypes, mainReservationType]);

  const initialFormData = useMemo(() => {
    const name = searchParams.get('name');
    const surnames = searchParams.get('surnames');
    const phone = searchParams.get('phone');
    const branchId = searchParams.get('branchId');

    if (!name && !surnames && !phone) return undefined;

    return {
      name: name ?? undefined,
      surnames: surnames ?? undefined,
      phone: phone ?? undefined,
      branchId: branchId ?? undefined,
    };
  }, [searchParams]);

  useEffect(() => {
    if (initialFormData) {
      openPanel(false);
    }
  }, [initialFormData, openPanel]);

  const tableColumns = useMemo(() => {
    return getTableColumns(organization, features);
  }, [features, organization]);

  const options: TableAction[] = useMemo(() => {
    const baseOptions: TableAction[] = [
      {
        label: 'Ver perfil',
        onClick: (id) => {
          navigate(`profile/${id}`);
        },
        hasPermission: hasFeaturesPermission([ALL_FEATURES.EXTERNAL_USERS], { features, featuresLoading }),
      },
      {
        label: 'Crear cita',
        onClick: (id: string) => {
          const user = data?.find((u) => u.id === id);
          if (user && mainReservationType && organization?.id) {
            // Crear un objeto con solo las propiedades necesarias para ExternalUser
            const externalUser: Pick<
              ExternalUser,
              'id' | 'name' | 'surnames' | 'email' | 'phone' | 'avatarUrl' | 'branchId' | 'organizationId' | 'userCoupon'
            > = {
              id: user.id,
              name: user.name,
              surnames: user.surnames,
              branchId: user.branch.id,
              organizationId: organization.id,
              email: undefined,
              phone: undefined,
              avatarUrl: user.avatarUrl,
              userCoupon: user.userCoupon,
            };
            setPreselectedExternalUser(externalUser as ExternalUser);
            setReservationPanelVisible(true);
          }
        },
        hasPermission: hasFeaturesPermission([SPECIAL_FEATURES.FISIO_RESERVATIONS_WRITE], { features, featuresLoading }),
      },
    ];

    // Solo añadir la opción de ver rutina si el usuario tiene una rutina
    const hasRoutineOption: TableAction = {
      label: 'Ver rutina',
      onClick: async (id: string) => {
        const user = data?.find((u) => u.id === id);
        if (user?.currentRoutine?.id && organization?.id) {
          const routine = await get<Routine>(`/routines/${user.currentRoutine.id}`);
          if (routine) {
            setSelectedRoutine(routine);
            setRoutinePanelVisible(true);
          }
        }
      },
      hasPermission: (id: string) => {
        const user = data?.find((u) => u.id === id);
        return (
          user?.currentRoutine !== undefined &&
          user?.currentRoutine?.status !== USER_CURRENT_ROUTINE_STATUS.NO_ROUTINE &&
          hasFeaturesPermission([ALL_FEATURES.ROUTINES], { features, featuresLoading })
        );
      },
    };

    // Añadimos la opción de ver rutina a las opciones base
    baseOptions.push(hasRoutineOption);

    return baseOptions;
  }, [data, features, featuresLoading, mainReservationType, navigate, organization?.id, get]);

  const handleClose = () => {
    closePanel();
    if (initialFormData) {
      navigate('/users', { replace: true });
    }
  };

  const allowedFeatures: AllowedFeatures = {
    edit: [ALL_FEATURES.EXTERNAL_USERS_WRITE],
    delete: [ALL_FEATURES.EXTERNAL_USERS_WRITE],
    create: [ALL_FEATURES.EXTERNAL_USERS_WRITE],
  };

  const allowedRoutineFeatures: AllowedFeatures = {
    edit: [ALL_FEATURES.ROUTINES_WRITE],
    delete: [ALL_FEATURES.ROUTINES_WRITE],
    create: [ALL_FEATURES.ROUTINES_WRITE],
  };

  return (
    <>
      <ResponsiveTable
        disabled={isDeleting}
        data={data || []}
        columns={tableColumns}
        options={options}
        loading={organizationLoading || isLoading || featuresLoading}
        headerOptions={
          userHasEditFeature && (
            <Button label='Añadir usuario' outlined rounded icon={PrimeIcons.PLUS} onClick={() => openPanel(false)} />
          )
        }
        onRowClick={(externalUser: ExternalUserTableEntry) => {
          navigate(`profile/${externalUser.id}`);
        }}
      />

      <ExternalUserPanel
        resource={undefined}
        visible={panelVisible}
        onHide={handleClose}
        onIsEditingChange={setIsEditing}
        isEditing={isEditing}
        onDeleteResource={() => Promise.resolve(true)}
        isDeleting={isDeleting}
        initialFormData={initialFormData}
        allowedFeatures={allowedFeatures}
      />

      <ReservationPanel
        visible={reservationPanelVisible}
        onHide={() => {
          setReservationPanelVisible(false);
          setPreselectedExternalUser(undefined);
        }}
        onIsEditingChange={setEditingReservation}
        initialHours={undefined}
        clearInitialTemporaryEvent={() => {}}
        isEditing={editingReservation}
        resource={undefined}
        isDeleting={false}
        createModeReservationTypeId={mainReservationType?.id}
        preselectedExternalUser={preselectedExternalUser}
        mutateReservations={mutate}
        calendarRestrictions={calendarRestrictions}
      />

      <RoutinePanel
        resource={selectedRoutine}
        visible={routinePanelVisible}
        onHide={() => {
          setRoutinePanelVisible(false);
          setSelectedRoutine(undefined);
        }}
        onIsEditingChange={setEditingRoutine}
        isEditing={editingRoutine}
        isDeleting={false}
        allowedFeatures={allowedRoutineFeatures}
      />
    </>
  );
};

export default UsersList;
