export enum COMMON_FEATURES {
  ANALYTICS = 'analytics',
  BLOG = 'blog',
  USERS = 'users',
  RESERVATIONS = 'reservations',
  EMPLOYEES = 'employees',
}

export enum FISIOFIT_FEATURES {
  ROUTINES = 'routines',
  FISIO_RESERVATIONS = 'fisio_reservations',
}

export type Feature = FISIOFIT_FEATURES | COMMON_FEATURES;
