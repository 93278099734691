import React from 'react';
import { Chip } from 'primereact/chip';
import { format, isAfter, startOfDay, isSameDay } from 'date-fns';
import './HolidaysList.scss';

interface Props {
  holidays: Date[];
  onRemove?: (date: Date) => void;
  isEditable?: boolean;
  showPastDates?: boolean;
}

export const HolidaysList = ({ holidays, onRemove, isEditable = true, showPastDates = false }: Props) => {
  if (!holidays.length) return null;

  const today = startOfDay(new Date());

  const filteredHolidays = showPastDates ? holidays : holidays.filter((date) => isAfter(date, today) || isSameDay(date, today));

  const pastDaysCount = holidays.length - filteredHolidays.length;

  if (!filteredHolidays.length) {
    if (pastDaysCount > 0) {
      return (
        <div className='holiday-info-message'>
          Solo hay días de cierre pasados. Estos días se muestran en el calendario pero no en esta lista.
        </div>
      );
    }
    return null;
  }

  const sortedHolidays = [...filteredHolidays].sort((a, b) => a.getTime() - b.getTime());

  return (
    <>
      {pastDaysCount > 0 && !showPastDates && (
        <div className='holiday-info-message'>
          {pastDaysCount === 1
            ? 'Hay 1 día de cierre pasado que se muestra en el calendario pero no en esta lista.'
            : `Hay ${pastDaysCount} días de cierre pasados que se muestran en el calendario pero no en esta lista.`}
        </div>
      )}
      <div className='holiday-chips'>
        {sortedHolidays.map((date) => (
          <Chip
            key={date.toISOString()}
            label={format(date, "d 'de' MMMM")}
            removable={!!onRemove && isEditable}
            onRemove={onRemove ? () => onRemove(date) : undefined}
          />
        ))}
      </div>
    </>
  );
};
