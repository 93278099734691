const css = `.compact-type-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
}
.compact-type-item i {
  font-size: 16px;
  color: var(--text-color-secondary);
}
.compact-type-item .compact-type-name {
  font-size: 16px;
  font-weight: 500;
}

.adaptive-select-option .compact-type-item {
  padding: 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvQ29tcGFjdFJlc2VydmF0aW9uVHlwZVNlbGVjdCIsInNvdXJjZXMiOlsiQ29tcGFjdFJlc2VydmF0aW9uVHlwZVNlbGVjdC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTs7O0FBS0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5jb21wYWN0LXR5cGUtaXRlbSB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGdhcDogOHB4O1xuICBwYWRkaW5nOiA0cHggMDtcblxuICBpIHtcbiAgICBmb250LXNpemU6IDE2cHg7XG4gICAgY29sb3I6IHZhcigtLXRleHQtY29sb3Itc2Vjb25kYXJ5KTtcbiAgfVxuXG4gIC5jb21wYWN0LXR5cGUtbmFtZSB7XG4gICAgZm9udC1zaXplOiAxNnB4O1xuICAgIGZvbnQtd2VpZ2h0OiA1MDA7XG4gIH1cbn1cblxuLmFkYXB0aXZlLXNlbGVjdC1vcHRpb24ge1xuICAuY29tcGFjdC10eXBlLWl0ZW0ge1xuICAgIHBhZGRpbmc6IDA7XG4gIH1cbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
