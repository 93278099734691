const css = `.compact-branch-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
}
.compact-branch-item i {
  font-size: 16px;
  color: var(--text-color-secondary);
}
.compact-branch-item .compact-branch-name {
  font-size: 16px;
  font-weight: 500;
}

.adaptive-select-option .compact-branch-item {
  padding: 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvQ29tcGFjdEJyYW5jaFNlbGVjdCIsInNvdXJjZXMiOlsiQ29tcGFjdEJyYW5jaFNlbGVjdC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTs7O0FBS0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5jb21wYWN0LWJyYW5jaC1pdGVtIHtcbiAgZGlzcGxheTogZmxleDtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgZ2FwOiA4cHg7XG4gIHBhZGRpbmc6IDRweCAwO1xuXG4gIGkge1xuICAgIGZvbnQtc2l6ZTogMTZweDtcbiAgICBjb2xvcjogdmFyKC0tdGV4dC1jb2xvci1zZWNvbmRhcnkpO1xuICB9XG5cbiAgLmNvbXBhY3QtYnJhbmNoLW5hbWUge1xuICAgIGZvbnQtc2l6ZTogMTZweDtcbiAgICBmb250LXdlaWdodDogNTAwO1xuICB9XG59XG5cbi5hZGFwdGl2ZS1zZWxlY3Qtb3B0aW9uIHtcbiAgLmNvbXBhY3QtYnJhbmNoLWl0ZW0ge1xuICAgIHBhZGRpbmc6IDA7XG4gIH1cbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
