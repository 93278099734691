import React from 'react';
import './KpiCard.scss';
import { Card } from 'primereact/card';
import { classNames } from 'primereact/utils';
import { PrimeIcons } from 'primereact/api';

export interface KpiProps {
  icon: string;
  title: string;
  iconColor: string;
  iconBkgColor: string;
  value: string;
  previousPercentage?: number;
}

export const KpiCard = ({ icon, title, iconColor, iconBkgColor, value, previousPercentage }: KpiProps) => {
  return (
    <Card className='kpi-container'>
      <div className='kpi-value-container'>
        <span>{title}</span>
        <span className='bold'>
          {value}
          {previousPercentage !== undefined && (
          <div className={classNames('previous', {'increase': previousPercentage > 0 , 'decrease': previousPercentage < 0})}>
            <span className={previousPercentage > 0 ? PrimeIcons.ARROW_UP : PrimeIcons.ARROW_DOWN} style={{marginRight: '2px'}}></span>
            <span>{previousPercentage}%</span>
          </div>
          )}
          </span>
      </div>
      <span className={icon} style={{color: iconColor, backgroundColor: iconBkgColor, padding: '8px'}}></span>
    </Card>
  );
};
