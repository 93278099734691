import React, { useMemo } from 'react';
import './EmployeesList.scss';
import { FeatureToggle } from '../../../components/FeatureToggle/FeatureToggle';
import { useClient } from '../../../hooks/useClient';
import { User } from '../../../types/user';
import { useOrganizationContext } from '../../../context/OrganizationContext';
import useSWR, { mutate } from 'swr';
import { Button } from 'primereact/button';
import { UserPanel } from '../../../components/UserPanel/UserPanel';
import { ResponsiveTable } from '../../../components/ResponsiveTable/ResponsiveTable';
import { TableColumn, TableAction } from '../../../types/responsiveTable';
import { useNavigateScroll } from '../../../hooks/useNavigateScroll';
import { PrimeIcons } from 'primereact/api';
import { ItemDisplay } from '../../../types/responses';
import { useResourcePanel } from '../../../hooks/useResourcePanel';
import { ALL_FEATURES } from '../../../config/features';
import { Chip } from '../../../components/Chip/Chip';
import { hasFeaturesPermission } from '../../../utils/featureUtils';
import { useAuthContext } from '../../../context/AuthContext';
import { confirmDialog } from 'primereact/confirmdialog';
import classNames from 'classnames';

const getTableColumns = (): TableColumn<User>[] => [
  {
    label: '',
    accessor: 'avatarUrl',
    type: 'avatar',
  },
  {
    label: 'Nombre',
    accessor: 'name',
    type: 'string',
    isTitle: true,
    valueFromCallBack: (name, row) => `${name} ${row.surnames}`,
    renderSuffix: (name, row) => (row.isInvitation ? <Chip>Pendiente</Chip> : null),
  },
  {
    label: 'Rol',
    accessor: 'role',
    valueFromCallBack: (role) => [role.name],
    type: 'dropdown',
    hideBulletPoints: true,
  },
  {
    label: 'Sucursales',
    accessor: 'branches',
    type: 'dropdown',
    valueFromCallBack: (branches: ItemDisplay[]) => branches?.map((branch) => branch?.name) || '',
  },
  {
    label: 'Usa app',
    accessor: 'isInApp',
    type: 'boolean',
  },
  {
    label: 'Estado',
    accessor: 'isActive',
    textKeyFromCallBack: (isActive: boolean) => (isActive ? 'Activo' : 'Inactivo'),
    colorFromCallBack: (isActive: boolean) => {
      return isActive ? 'success' : 'danger';
    },
    type: 'tag',
  },
];

export const EmployeesList = () => {
  const { get, patch } = useClient();
  const navigate = useNavigateScroll();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};
  const { features, featuresLoading, user: currentUser } = useAuthContext() ?? {};
  const { data, isLoading } = useSWR(organization ? `/organizations/${organization.id}/users` : null, get<User[]>);
  const {
    isEditing,
    setIsEditing,
    panelVisible,
    closePanel,
    selectedResource,
    openPanel,
    isDeleting: isTogglingStatus,
    setIsDeleting: setIsTogglingStatus,
  } = useResourcePanel(data);

  const handleToggleStatus = async (id: string | undefined) => {
    if (!id || !organization?.id) return false;
    setIsTogglingStatus(true);
    const user = data?.find((u) => u.id === id);
    const isActive = user?.isActive;
    const action = isActive ? 'baja' : 'alta';

    const response = await patch(`/users/${id}/toggle-active`, {
      successMessage: {
        summary: `Se ha dado de ${action} a ${user?.name} ${user?.surnames}`,
        detail: `El empleado se ha dado de ${action} correctamente`,
      },
      errorMessages: {
        summary: `No se ha podido dar de ${action} a ${user?.name} ${user?.surnames}`,
        defaultDetail: `No se ha podido dar de ${action} al usuario`,
        USER_HAS_FUTURE_RESERVATIONS: `El empleado tiene reservas futuras. No se puede dar de baja hasta que se hayan cancelado.`,
        USER_IS_SUPERVISOR:
          'El empleado es supervisor de algún cliente. No se le puede dar de baja hasta que no tenga ningún cliente asignado.',
      },
      handlers: {
        defaultSuccess: () => {
          mutate(`/organizations/${organization.id}/users`);
        },
      },
    });

    setIsTogglingStatus(false);
    return !!response;
  };

  const showToggleStatusDialog = (id: string) => {
    const user = data?.find((u) => u.id === id);
    const isActive = user?.isActive;
    const action = isActive ? 'baja' : 'alta';

    confirmDialog({
      message: (
        <>
          ¿Estás seguro de que quieres dar de {action} a{' '}
          <b>
            {user?.name} {user?.surnames}
          </b>
          ?
        </>
      ),
      header: `Confirmar ${action}`,
      icon: PrimeIcons.EXCLAMATION_CIRCLE,
      acceptLabel: `Sí, dar de ${action}`,
      rejectLabel: 'No, volver',
      accept: () => handleToggleStatus(id),
      acceptClassName: classNames('p-button-outlined', {
        'p-button-danger': isActive,
        'p-button-success': !isActive,
      }),
      defaultFocus: 'reject',
      draggable: false,
      resizable: false,
    });
  };

  const userHasEditFeature = hasFeaturesPermission([ALL_FEATURES.USERS_WRITE], { features, featuresLoading });

  const tableHeaders: TableColumn<User>[] = useMemo(() => getTableColumns(), []);

  // Creamos las opciones base
  const baseOptions: TableAction[] = [
    {
      label: 'Ver',
      onClick: (id) => {
        openPanel(false, id);
      },
      hasPermission: hasFeaturesPermission([ALL_FEATURES.USERS], { features, featuresLoading }),
    },
    {
      label: 'Editar',
      onClick: (id) => {
        openPanel(true, id);
      },
      hasPermission: userHasEditFeature,
    },
  ];

  // Añadimos la opción de dar de baja/alta solo si no es el usuario actual
  const options = useMemo(() => {
    // Opciones base que siempre están disponibles
    const result = [...baseOptions];

    // Añadimos la opción de dar de baja/alta solo si el usuario tiene permisos
    if (userHasEditFeature) {
      result.push({
        label: (id: string) => {
          const user = data?.find((u) => u.id === id);
          return user?.isActive ? 'Dar de baja' : 'Dar de alta';
        },
        onClick: (id) => {
          // No permitimos dar de baja/alta al usuario actual
          if (id === currentUser?.id) return;
          showToggleStatusDialog(id);
        },
        hasPermission: (id: string) => {
          return id !== currentUser?.id && userHasEditFeature;
        },
      });
    }

    return result;
  }, [data, currentUser?.id, userHasEditFeature, baseOptions, showToggleStatusDialog]);

  const allowedFeatures = useMemo(() => {
    return {
      edit: [ALL_FEATURES.USERS_WRITE],
      create: [ALL_FEATURES.USERS_WRITE],
      delete: [ALL_FEATURES.USERS_WRITE],
    };
  }, []);

  return (
    <>
      <ResponsiveTable
        disabled={isTogglingStatus}
        data={data || []}
        columns={tableHeaders}
        options={options}
        loading={organizationLoading || isLoading}
        headerOptions={
          <>
            <FeatureToggle featuresToCheck={[ALL_FEATURES.USERS_WRITE]}>
              <Button
                label='Añadir empleado'
                outlined
                rounded
                icon={PrimeIcons.PLUS}
                onClick={() => {
                  openPanel(false);
                }}
              />
            </FeatureToggle>
            <FeatureToggle featuresToCheck={[ALL_FEATURES.ROLES]}>
              <Button
                label='Ver roles'
                outlined
                text
                size='large'
                onClick={() => {
                  navigate('/employees/roles');
                }}
              />
            </FeatureToggle>
          </>
        }
        onRowClick={(row) => {
          openPanel(false, row.id);
        }}
      />
      <UserPanel
        resource={selectedResource}
        visible={panelVisible}
        onHide={closePanel}
        onIsEditingChange={setIsEditing}
        onDeleteResource={handleToggleStatus}
        isDeleting={isTogglingStatus}
        isEditing={isEditing}
        allowedFeatures={allowedFeatures}
      />
    </>
  );
};
