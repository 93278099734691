import React from 'react';
import { InputText } from 'primereact/inputtext';
import { useForm } from '../../hooks/useForm';
import { FormField } from '../FormField/FormField';
import classNames from 'classnames';
import { FisiofitPhysiotherapyConsentDocumentPayload } from '../../types/signedDocument';
import { SignatureInput } from '../SignatureInput/SignatureInput';
import { Divider } from 'primereact/divider';
import { BranchesSelect } from '../BranchesSelect/BranchesSelect';
import { Branch } from '../../types/branch';
import { SignedDocumentTemplate } from './SignedDocumentTemplate';

export interface FisiofitPhysiotherapyConsentForm extends FisiofitPhysiotherapyConsentDocumentPayload {
  signature?: string;
  signingLocationBranch?: Branch;
}

const INITIAL_PHYSIOTHERAPY_CONSENT_FORM: FisiofitPhysiotherapyConsentForm = {
  fullName: '',
  nationalId: '',
  guardianName: '',
  guardianNationalId: '',
  guardianRelation: '',
  guardianSignature: undefined,
  signature: undefined,
  signingLocationBranch: undefined,
};

const getFormValidators = () => ({
  fullName: (form: Partial<FisiofitPhysiotherapyConsentForm>): string | undefined =>
    !form.fullName ? 'El nombre es obligatorio' : undefined,
  nationalId: (form: Partial<FisiofitPhysiotherapyConsentForm>): string | undefined =>
    !form.nationalId ? 'El DNI es obligatorio' : undefined,
  signature: (form: Partial<FisiofitPhysiotherapyConsentForm>): string | undefined =>
    !form.signature ? 'La firma es obligatoria' : undefined,
  signingLocationBranch: (form: Partial<FisiofitPhysiotherapyConsentForm>): string | undefined =>
    !form.signingLocationBranch?.id ? 'Debes elegir la sucursal en la que se firma' : undefined,
});

interface Props {
  visible: boolean;
  onHide: () => void;
  onSave: (data: FisiofitPhysiotherapyConsentDocumentPayload, signature: string, signingLocation: string) => Promise<boolean>;
}

const PhysiotherapyDocumentText = () => (
  <>
    <section>
      <h3>Introducción</h3>
      <p>
        Esta documentación ha sido elaborada conforme a las previsiones contenidas en la Ley General de Sanidad (14/1986, de 25 de
        Abril) y la Ley 41/2002, de 14 de Noviembre, reguladora esta última de los derechos y obligaciones en materia de
        información y documentación clínica.
      </p>
    </section>

    <section>
      <h3>Concepto general de fisioterapia</h3>
      <p>
        Tratamiento de la persona para evaluar, impedir, corregir, aliviar y limitar o disminuir la incapacidad física, alteración
        del movimiento, funcionalidad y postura, así como el dolor, que se deriven de los desórdenes congénitos y de las
        condiciones del envejecimiento, lesión o enfermedad (daño o procedimiento quirúrgico). La Fisioterapia tiene como fin más
        significativo restaurar las funciones físicas perdidas o deterioradas.
      </p>
      <p>
        Utiliza como tratamiento los agentes y medios físicos como la electricidad, el movimiento, el masaje o manipulación de los
        tejidos y las articulaciones, el agua, la luz, el calor, el frío, etcétera y dependiendo del agente que se emplee se
        denomina:
      </p>
      <ul>
        <li>
          <strong>Cinesiterapia</strong> que se define como el conjunto de procedimientos terapéuticos cuyo fin es el tratamiento
          de las enfermedades mediante el movimiento: ya sean activos, pasivos o comunicados mediante algún medio externo.
        </li>
        <li>
          <strong>Masaje/Masoterapia</strong> se trata del conjunto de manipulaciones, practicadas normalmente sin ayuda de
          instrumentos, sobre una parte o totalidad del organismo, con el objeto de movilizar los tejidos para provocar en el
          organismo modificaciones de orden, directas o reflejas, que se traduzcan en efectos terapéuticos.
        </li>
        <li>
          <strong>Terapias y procedimientos manuales</strong>, entendidos como la localización y tratamiento de trastornos del
          sistema locomotor, utilizando las manos para desbloquear articulaciones y sus fijaciones musculares y reflejas por medio
          de la aplicación de técnicas y modelos terapéuticos de partes blandas. Incluye la cinesiterapia, masoterapia y sus
          derivados.
        </li>
        <li>
          <strong>Hidroterapia</strong> es la utilización terapéutica del agua por sus propiedades físicas; podemos definirla
          también como la rama de la hidrología que estudia la aplicación externa del agua sobre el cuerpo humano, siempre que sea
          con fines terapéuticos y principalmente pos sus efectos mecánicos y térmicos.
        </li>
        <li>
          <strong>Mecanoterapia</strong> es la utilización terapéutica e higiénica de aparatos mecánicos destinados a provocar y
          dirigir movimientos corporales regulados en su fuerza, trayectoria y amplitud.
        </li>
        <li>
          <strong>Electroterapia</strong> se define como el tratamiento de determinadas enfermedades mediante la electricidad o de
          las radiaciones electromagnéticas (espectroterapia) sobre el organismo para mejorar el estado de los tejidos (efectos
          tróficos), para aliviar el dolor (efecto analgésico), y ayudar a la recuperación motora (efecto excitomotor).
        </li>
      </ul>
    </section>

    <section>
      <h3>Riesgos</h3>
      <p>
        La Fisioterapia tiene muy pocos riesgos y los mayores peligros son su incorrecta indicación y su mala ejecución. Por lo
        tanto, es necesario, sobre todo en las técnicas más agresivas, un perfecto conocimiento de sus fundamentos, de la base de
        su utilización, de las técnicas de realización y de sus posibles riesgos, por ello, siempre debe ser aplicada por
        profesionales de la Fisioterapia. En cualquier caso, queda matizar algunas consecuencias que se pueden producir durante el
        desarrollo de los tratamientos y que el paciente tiene que ser consciente, tales como:
      </p>
      <ul>
        <li>
          <strong>Dolor:</strong> con el ejercicio es posible experimentar dolor muscular en los primeros tratamientos.
        </li>
        <li>
          <strong>Mareo:</strong> los síntomas temporales como el mareo y la náusea pueden ocurrir, pero son relativamente raros.
        </li>
        <li>
          <strong>Fractura/lesión articular:</strong> en casos esporádicos, los defectos físicos subyacentes, las deformidades o
          las patologías, como huesos débiles por la osteoporosis, pueden volver al paciente susceptible de lesión. Cuando la
          osteoporosis, el disco intervertebral degenerado, u otra anormalidad son detectados, el fisioterapeuta seguirá con
          cautela adicional.
        </li>
      </ul>
    </section>

    <section>
      <h3>Contraindicaciones generales</h3>
      <p>
        <strong>A) Absolutas</strong>
      </p>
      <ul>
        <li>Los tumores malignos.</li>
        <li>Cardiopatías descompensadas, endocarditis activas, hemopatías, tuberculosis (para la cinesiterapia activa).</li>
        <li>Bronquitis crónica descompensada.</li>
        <li>Trombosis o hemorragias activas.</li>
        <li>Marcapasos y/o dispositivos intracardiacos (Electroterapia).</li>
      </ul>
      <p>
        <strong>B) Relativas</strong>
      </p>
      <ul>
        <li>Derrame sinovial, hemartros y heridas recientes de partes blandas (para la cinesiterapia pasiva y termoterapia).</li>
        <li>Artritis infecciosa (hidroterapia).</li>
        <li>Dermatología: micosis y dermatitis piógena.</li>
        <li>Epilépticos no controlados y síndromes coréicos.</li>
        <li>Cualquier herida abierta, en la zona a tratar.</li>
        <li>Hipertensión arterial y varices sin control médico.</li>
        <li>Pacientes que puedan propagar algún tipo de infección debido a la patología que sufren.</li>
        <li>Enfermedades agudas con fiebre.</li>
        <li>Estados febriles y/o de debilidad extrema.</li>
        <li>Enfermos terminales (hidroterapia y termoterapia profunda).</li>
        <li>Incontinencia de esfínteres (hidroterapia).</li>
        <li>Enfermedades de la piel en caso de aguas sulfatadas (hidroterapia).</li>
        <li>
          Implantes metálicos internos como endoprótesis, material de osteosíntesis, etc. (electroterapia de media y alta
          frecuencia).
        </li>
        <li>Alteraciones de la sensibilidad (electroterapia, hidroterapia).</li>
        <li>Aplicaciones abdominales y lumbares durante la menstruación (electroterapia de media y alta frecuencia).</li>
      </ul>
    </section>

    <section>
      <h3>Resultados del tratamiento</h3>
      <p>
        Hay efectos beneficiosos asociados con estos métodos de tratamiento incluyendo disminución del dolor, función y movilidad
        mejorada, y reducción del espasmo del músculo. Sin embargo, no hay certeza de estos beneficios. La práctica sanitaria, no
        es una ciencia exacta y no existe ninguna garantía sobre el resultado certero de estos métodos.
      </p>
      <p>
        Dependiendo de la técnica, su fisioterapeuta le indicará que se desprenda de objetos metálicos que porte (medallas,
        cadenas, llaveros, monedas, cinturones, etc.) o le facilitará gafas protectoras.
      </p>
      <p>
        Tiene derecho tanto a prestar consentimiento para su tratamiento previa información, como a consentir sin recibir
        información y, en cualquier caso, a retirar su consentimiento en cualquier momento previo a la realización de la técnica o
        durante ella.
      </p>
    </section>
  </>
);

export const FisiofitPhysiotherapyConsent = ({ visible, onHide, onSave }: Props) => {
  const { form, setFormFields, isSaving, setIsSaving, hasChanged, validate, validationErrors } =
    useForm<FisiofitPhysiotherapyConsentForm>(INITIAL_PHYSIOTHERAPY_CONSENT_FORM, !visible, getFormValidators());

  const handleSubmit = async (): Promise<boolean> => {
    const errors = validate();
    const { signature, signingLocationBranch, ...rest } = form ?? {};
    if (!form || errors || !signature || !signingLocationBranch?.city) {
      return false;
    }
    setIsSaving(true);
    const success = await onSave(rest, signature, signingLocationBranch.city);
    setIsSaving(false);
    return success;
  };

  const handleClearSignature = () => {
    setFormFields({ signature: undefined });
  };

  const handleSignatureChange = (signatureImage: string) => {
    setFormFields({ signature: signatureImage });
  };

  const handleGuardianSignatureChange = (signatureImage: string) => {
    setFormFields({ guardianSignature: signatureImage });
  };

  const handleClearGuardianSignature = () => {
    setFormFields({ guardianSignature: undefined });
  };

  return (
    <SignedDocumentTemplate
      title='Consentimiento informado - Fisioterapia general'
      visible={visible}
      onHide={onHide}
      onSave={handleSubmit}
      isSaving={isSaving}
      hasChanged={hasChanged}
      documentText={<PhysiotherapyDocumentText />}
      indentText
      requiresSignature
    >
      <h3>Datos del paciente</h3>
      <FormField labelTitle='Nombre y apellidos' elementId='patient-name' required error={validationErrors?.fullName}>
        <InputText
          id='patient-name'
          value={form?.fullName}
          onChange={(e) => setFormFields({ fullName: e.target.value })}
          placeholder='Nombre completo'
          className={classNames({ 'p-invalid': validationErrors?.fullName })}
        />
      </FormField>

      <FormField labelTitle='DNI' elementId='patient-dni' required error={validationErrors?.nationalId}>
        <InputText
          id='patient-dni'
          value={form?.nationalId}
          onChange={(e) => setFormFields({ nationalId: e.target.value })}
          placeholder='DNI'
          className={classNames({ 'p-invalid': validationErrors?.nationalId })}
        />
      </FormField>

      <FormField
        labelTitle='Ubicación de la firma'
        elementId='signing-location-select'
        required
        error={validationErrors?.signingLocationBranch}
      >
        <BranchesSelect
          id='signing-location-select'
          value={form?.signingLocationBranch?.id}
          onChange={(value) => setFormFields({ signingLocationBranch: value })}
          placeholder='Sucursal donde se firma'
          error={validationErrors?.signingLocationBranch}
          isSingle={true}
          onlyAuthUserBranches
        />
      </FormField>

      <SignatureInput
        key='client-signature'
        id='client-signature'
        isEditing
        error={validationErrors?.signature}
        onSignatureChange={handleSignatureChange}
        onClearSignature={handleClearSignature}
        hasChanged={form.signature !== undefined}
        required={true}
        noteText='Al firmar, declaro haber leído y entendido la información proporcionada, no encontrarme en ninguno de los casos de
        contraindicaciones especificadas, y dar mi conformidad al tratamiento.'
      />

      <Divider />

      <h3>Autorización del familiar o tutor (si es necesario)</h3>
      <FormField labelTitle='Nombre y apellidos del tutor' elementId='guardian-name'>
        <InputText
          id='guardian-name'
          value={form?.guardianName}
          onChange={(e) => setFormFields({ guardianName: e.target.value })}
          placeholder='Nombre completo del tutor'
        />
      </FormField>

      <FormField labelTitle='DNI del tutor' elementId='guardian-dni'>
        <InputText
          id='guardian-dni'
          value={form?.guardianNationalId}
          onChange={(e) => setFormFields({ guardianNationalId: e.target.value })}
          placeholder='DNI del tutor'
        />
      </FormField>

      <FormField labelTitle='En calidad de' elementId='guardian-relation'>
        <InputText
          id='guardian-relation'
          value={form?.guardianRelation}
          onChange={(e) => setFormFields({ guardianRelation: e.target.value })}
          placeholder='Relación con el paciente (padre, madre, tutor legal, etc.)'
        />
      </FormField>

      <SignatureInput
        key='guardian-signature'
        id='guardian-signature'
        isEditing
        error={validationErrors?.guardianSignature}
        onSignatureChange={handleGuardianSignatureChange}
        onClearSignature={handleClearGuardianSignature}
        hasChanged={form.guardianSignature !== undefined}
        noteText='Al firmar, declaro haber leído y entendido la información proporcionada, no encontrarme en ninguno de los casos de
        contraindicaciones especificadas, y dar mi conformidad al tratamiento.'
      />
    </SignedDocumentTemplate>
  );
};
