import React, { useEffect, useRef } from 'react';
import './ExerciseList.scss';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { RoutineExercise } from '../../types/Fisiofit/routine';
import { EXERCISE_TYPE, ExerciseShort } from '../../types/Fisiofit/exercise';
import useSWR from 'swr';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { useClient } from '../../hooks/useClient';
import { ExerciseListExercise } from './ExerciseListExercise';
import { classNames } from 'primereact/utils';
import { FormElementError } from '../FormElementError/FormElementError';
import { ValidationErrors } from '../../hooks/useForm';
import { OrderList } from 'primereact/orderlist';

interface Props {
  exercises: RoutineExercise[];
  onExercisesChange: (exercises: RoutineExercise[]) => void;
  isEditing?: boolean;
  error?: string | ValidationErrors<RoutineExercise>[];
  selectedExerciseIndex?: number | null;
  onMoveExercise?: (fromIndex: number, toIndex: number) => void;
}

// Extendemos RoutineExercise para añadir el ID temporal
type ExerciseWithTempId = RoutineExercise & {
  _tempId?: string;
};

export const ExerciseList = ({ exercises, onExercisesChange, isEditing = false, error }: Props) => {
  const { organization } = useOrganizationContext() ?? {};
  const { get } = useClient();
  const tempIdCounter = useRef(1);

  const { data: organizationExercises } = useSWR(
    isEditing && organization?.id ? `/organizations/${organization?.id}/exercises?short=true` : null,
    get<ExerciseShort[]>,
  );

  // Aseguramos que todos los ejercicios tengan un ID temporal
  useEffect(() => {
    const exercisesWithIds = exercises.map((exercise) => {
      const exerciseWithId = exercise as ExerciseWithTempId;
      if (!exerciseWithId._tempId) {
        exerciseWithId._tempId = `temp_${Date.now()}_${tempIdCounter.current++}`;
      }
      return exerciseWithId;
    });

    if (JSON.stringify(exercisesWithIds) !== JSON.stringify(exercises)) {
      onExercisesChange(exercisesWithIds as RoutineExercise[]);
    }
  }, [exercises, onExercisesChange]);

  const handleInputChange = (index: number, changes: Partial<RoutineExercise>) => {
    const newExercises = exercises.map((exercise, i) => (i === index ? { ...exercise, ...changes } : exercise));
    onExercisesChange(newExercises);
  };

  const handleDelete = (index: number) => {
    const newExercises = exercises.filter((_, i) => i !== index);
    onExercisesChange(newExercises);
  };

  const addEmptyExercise = () => {
    const newExercise: ExerciseWithTempId = {
      exerciseId: '',
      name: '',
      exerciseType: EXERCISE_TYPE.REPS,
      _tempId: `temp_${Date.now()}_${tempIdCounter.current++}`,
    } as ExerciseWithTempId;

    onExercisesChange([...exercises, newExercise] as RoutineExercise[]);
  };

  const handleReorder = (e: { value: RoutineExercise[] }) => {
    onExercisesChange(e.value);
  };

  const itemTemplate = (exercise: RoutineExercise) => {
    const index = exercises.findIndex((e) => e === exercise);
    return (
      <div className='exercise-row'>
        <ExerciseListExercise
          exercise={exercise}
          isEditing={isEditing}
          index={index}
          organizationExercises={organizationExercises}
          onDelete={handleDelete}
          onInputChange={handleInputChange}
          error={error as any}
        />
      </div>
    );
  };

  return (
    <div className={classNames('exercise-list', { 'p-invalid': !!error })}>
      {exercises.length ? (
        <OrderList
          value={exercises}
          onChange={handleReorder}
          itemTemplate={itemTemplate}
          header='Ejercicios'
          className='exercise-orderlist'
          dataKey='_tempId'
          dragdrop={false}
        />
      ) : (
        <p className='empty-exercise-list-message'>No hay ejercicios</p>
      )}
      <FormElementError position='absolute' className='exercise-list-card-error'>
        {Object.values(error ?? {}).join(', ')}
      </FormElementError>
      {isEditing && (
        <Button
          text
          label='Añadir ejercicio'
          icon={PrimeIcons.PLUS}
          className='add-exercise-button'
          onClick={(e) => {
            e.preventDefault();
            addEmptyExercise();
          }}
        />
      )}
    </div>
  );
};
