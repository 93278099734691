import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { ExerciseList } from '../ExerciseList/ExerciseList';
import { ExerciseDay, RoutineExercise } from '../../types/Fisiofit/routine';
import './ExerciseDayModal.scss';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { confirmDialog } from 'primereact/confirmdialog';
import { DropdownButton } from '../DropdownButton/DropdownButton';

interface Props {
  visible: boolean;
  onHide: () => void;
  day: ExerciseDay;
  allDays: ExerciseDay[];
  onSave: (updatedDays: ExerciseDay[]) => void;
  isEditing: boolean;
}

export const ExerciseDayModal = ({ visible, onHide, day, allDays, onSave, isEditing }: Props) => {
  const [currentExercises, setCurrentExercises] = useState<RoutineExercise[]>(day.exercises);
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedExerciseIndex, setSelectedExerciseIndex] = useState<number | null>(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setCurrentExercises(day.exercises);
    setHasChanges(false);
    setSelectedExerciseIndex(null);
  }, [day.exercises]);

  const handleExercisesChange = (exercises: RoutineExercise[]) => {
    setCurrentExercises(exercises);
    setHasChanges(true);
  };

  const handleMoveExercise = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0 || toIndex >= currentExercises.length) return;

    const updatedExercises = [...currentExercises];
    const [movedExercise] = updatedExercises.splice(fromIndex, 1);
    updatedExercises.splice(toIndex, 0, movedExercise);

    handleExercisesChange(updatedExercises);
    setSelectedExerciseIndex(toIndex);
  };

  const handleSaveClick = () => {
    const hasExercises = currentExercises.length > 0;
    if (!hasExercises) {
      setHasError(true);
      return;
    }
    const hasName = currentExercises.every((e) => e.name);
    if (!hasName) {
      setHasError(true);
      return;
    }

    const updatedDays = allDays.map((d) =>
      d.dayNumber === day.dayNumber
        ? {
            ...d,
            exercises: currentExercises.map((e) => {
              // @ts-ignore
              const { _tempId, ...rest } = e;
              return rest;
            }),
          }
        : d,
    );
    onSave(updatedDays);
    setHasChanges(false);
    setHasError(false);
    onHide();
  };

  const handleCancelClick = () => {
    if (hasChanges) {
      confirmDialog({
        message: '¿Estás seguro de que quieres cancelar? Se perderán los cambios no guardados.',
        header: 'Confirmar cancelación',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sí, cancelar',
        rejectLabel: 'No, continuar editando',
        accept: () => {
          setCurrentExercises(day.exercises);
          setHasChanges(false);
          setHasError(false);
          onHide();
        },
        acceptClassName: 'p-button-danger p-button-text',
        rejectClassName: 'p-button-text',
        draggable: false,
        resizable: false,
      });
    } else {
      setHasError(false);
      onHide();
    }
  };

  const handleCopyExercises = (selectedDay: ExerciseDay) => {
    confirmDialog({
      message: 'Los ejercicios actuales serán reemplazados por los ejercicios del día seleccionado. ¿Deseas continuar?',
      header: 'Confirmar copia de ejercicios',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sí, copiar',
      rejectLabel: 'No, volver',
      accept: () => {
        const exercisesToCopy = [...selectedDay.exercises];
        handleExercisesChange(exercisesToCopy);
      },
      acceptClassName: 'p-button-text',
      rejectClassName: 'p-button-secondary p-button-text',
      draggable: false,
      resizable: false,
    });
  };

  const renderHeader = () => (
    <div className='exercise-day-modal-header'>
      <div className='header-content'>
        <h3>Día {day.dayNumber}</h3>
        <span className='exercise-count'>
          {currentExercises.length} ejercicio{currentExercises.length !== 1 ? 's' : ''}
        </span>
      </div>
      {isEditing && (
        <div className='header-actions'>
          <DropdownButton
            buttonProps={{
              icon: PrimeIcons.COPY,
              text: true,
              label: 'Copiar ejercicios',
            }}
            dropdownProps={{
              options: allDays
                .filter((d) => d.dayNumber !== day.dayNumber)
                .map((d) => ({
                  label: `Día ${d.dayNumber} - ${d.exercises.length} ejercicio${d.exercises.length !== 1 ? 's' : ''}`,
                  value: d,
                })),
              placeholder: 'Selecciona un día',
              onChange: (e) => handleCopyExercises(e.value),
              filter: true,
              emptyMessage: 'No hay días disponibles',
              emptyFilterMessage: 'No se han encontrado días',
              filterPlaceholder: 'Buscar día...',
            }}
          />
        </div>
      )}
    </div>
  );

  const renderFooter = () => {
    if (!isEditing) return null;

    return (
      <>
        <Button severity='secondary' label='Cancelar' onClick={handleCancelClick} className='p-button-text' />
        <Button label='Guardar' icon='pi pi-check' onClick={handleSaveClick} disabled={!hasChanges} />
      </>
    );
  };

  return (
    <Dialog
      header={renderHeader}
      visible={visible}
      onHide={handleCancelClick}
      modal
      className='exercise-day-modal big-modal'
      dismissableMask
      closeOnEscape
      footer={renderFooter}
      draggable={false}
      resizable={false}
    >
      <div className='exercise-day-modal-content'>
        {isEditing ? (
          <>
            <div className='modal-instructions'>
              <i className={PrimeIcons.INFO_CIRCLE} />
              <span>
                Añade ejercicios a este día copiándolos de otro día o usando el botón de añadir ejercicio. Usa los botones para
                reordenar los ejercicios.
              </span>
            </div>
            <ExerciseList
              exercises={currentExercises}
              onExercisesChange={handleExercisesChange}
              isEditing={isEditing}
              selectedExerciseIndex={selectedExerciseIndex}
              onMoveExercise={handleMoveExercise}
              error={hasError ? ({ exerciseId: 'Debes elegir un ejercicio' } as any) : undefined}
            />
          </>
        ) : (
          <div className='view-mode-content'>
            {currentExercises.length > 0 ? (
              <ExerciseList exercises={currentExercises} onExercisesChange={handleExercisesChange} isEditing={isEditing} />
            ) : (
              <div className='no-exercises'>
                <i className={PrimeIcons.INFO_CIRCLE} />
                <span>No hay ejercicios asignados para este día</span>
              </div>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};
