import React from 'react';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { RoutineDurationExercise, RoutineExercise, RoutineRepetitionExercise } from '../../types/Fisiofit/routine';
import { EXERCISE_TYPE, ExerciseShort } from '../../types/Fisiofit/exercise';
import { getExerciseOptions } from '../../utils/selectOptions';
import { classNames } from 'primereact/utils';
import { FormField } from '../FormField/FormField';
import { format } from 'date-fns';
import { FormEvent } from 'primereact/ts-helpers';
import { InputNumber } from 'primereact/inputnumber';
import { ValidationErrors } from '../../hooks/useForm';
import { AdaptiveSelect } from '../AdaptiveSelect/AdaptiveSelect';
import { Capacitor } from '@capacitor/core';

const MAX_WEIGHT = 999;
const MAX_REPETITIONS = 1000;
const MAX_SETS = 100;

const getSelectedExercise = (selectedId: string, organizationExercises: ExerciseShort[] | undefined) => {
  return organizationExercises?.find((_) => _.id === selectedId);
};

interface Props {
  exercise: RoutineExercise;
  isEditing: boolean;
  index: number;
  organizationExercises?: ExerciseShort[];
  onDelete: (index: number) => void;
  onInputChange: (index: number, changes: Partial<RoutineRepetitionExercise | RoutineDurationExercise>) => void;
  error?: ValidationErrors<RoutineExercise>;
}

export function ExerciseListExercise({
  exercise,
  isEditing,
  index,
  organizationExercises,
  onDelete,
  onInputChange,
  error,
}: Props) {
  const handleExerciseSelect = (selectedId: string) => {
    const newSelectedExercise = getSelectedExercise(selectedId, organizationExercises);
    if (newSelectedExercise) {
      onInputChange(index, {
        name: newSelectedExercise.name,
        exerciseId: newSelectedExercise.id,
        exerciseType: newSelectedExercise.exerciseType,
        ...(newSelectedExercise.exerciseType === EXERCISE_TYPE.TIME ? { repetitions: undefined } : {}),
        ...(newSelectedExercise.exerciseType === EXERCISE_TYPE.REPS ? { duration: undefined } : {}),
      });
    }
  };

  const handleDurationChange = (event: FormEvent<Date>) => {
    if (event.originalEvent?.type === 'blur') {
      // For some reason the blur event is not exposing the value so we need to get it from the input
      const timeString = (event.originalEvent.target as any).value;
      const [minutes, seconds] = timeString.split(':').map(Number);
      const totalSeconds = minutes * 60 + seconds;
      onInputChange(index, { duration: timeString === null ? undefined : totalSeconds });
      return;
    }

    const date = event.value;
    if (!date) {
      onInputChange(index, { duration: undefined });
      return;
    }
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const totalSeconds = minutes * 60 + seconds;

    onInputChange(index, { duration: totalSeconds });
  };

  const getDurationDate = (totalSeconds?: number): Date | null => {
    if (!totalSeconds) return null;
    const date = new Date(0);
    date.setMinutes(Math.floor(totalSeconds / 60));
    date.setSeconds(totalSeconds % 60);
    return date;
  };

  if (!isEditing) {
    return (
      <div className='exercise-content'>
        <FormField className='exercise-dropdown'>
          <strong>{exercise.name ?? '-'}</strong>
        </FormField>

        {exercise.exerciseId && (
          <FormField className='exercise-field weight-field' labelTitle='Peso' smallLabel>
            <p>{exercise.weight ? `${exercise.weight} kg` : '-'}</p>
          </FormField>
        )}

        {exercise.exerciseType === EXERCISE_TYPE.REPS && exercise.exerciseId && (
          <FormField className='exercise-field duration-reps-field' labelTitle='Repeticiones' smallLabel>
            <p>{'repetitions' in exercise ? (exercise.repetitions ?? '-') : '-'}</p>
          </FormField>
        )}

        {exercise.exerciseType === EXERCISE_TYPE.TIME && exercise.exerciseId && (
          <FormField className='exercise-field duration-reps-field' labelTitle='Duración' smallLabel>
            <p>
              {'duration' in exercise && exercise.duration ? `${format(new Date(exercise.duration * 1000), 'mm:ss')} min` : '-'}
            </p>
          </FormField>
        )}

        {exercise.exerciseId && (
          <FormField className='exercise-field' labelTitle='Series' smallLabel>
            <p>{exercise.sets ?? '-'}</p>
          </FormField>
        )}

        {exercise.exerciseId && (
          <FormField className='exercise-field' labelTitle='rir' smallLabel>
            <p>{exercise.rir ?? '-'}</p>
          </FormField>
        )}
      </div>
    );
  }

  return (
    <div className='exercise-content'>
      <FormField
        labelTitle='Ejercicio'
        smallLabel
        elementId='exercise-dropdown'
        className={classNames('exercise-dropdown', { 'empty-exercise': !exercise.exerciseId })}
        error={error?.exerciseId && !exercise.exerciseId ? error?.exerciseId : undefined}
        errorAsLabel
      >
        <AdaptiveSelect
          id='exercise-dropdown'
          value={exercise.exerciseId}
          options={organizationExercises ? getExerciseOptions(organizationExercises) : []}
          onChange={(e) => handleExerciseSelect(e)}
          placeholder='Selecciona un ejercicio'
          invalid={!!error?.exerciseId && !exercise.exerciseId}
          itemTemplate={(option) => <span>{option.label}</span>}
          modalTitle='Seleccionar ejercicio'
          searchPlaceholder='Buscar ejercicio'
          emptyMessage='No hay ejercicios'
          emptyFilterMessage='No se han encontrado ejercicios'
        />
      </FormField>

      {exercise.exerciseId && (
        <FormField className='exercise-field weight-field' labelTitle='Peso' smallLabel>
          <div className='p-inputgroup'>
            <InputNumber
              value={exercise.weight ?? null}
              onValueChange={(e) => {
                onInputChange(index, { weight: e.value ?? undefined });
              }}
              min={0}
              max={MAX_WEIGHT}
              useGrouping={false}
              minFractionDigits={1}
              maxFractionDigits={1}
            />
            <span className='p-inputgroup-addon'>kg</span>
          </div>
        </FormField>
      )}

      {exercise.exerciseType === EXERCISE_TYPE.REPS && exercise.exerciseId && (
        <FormField className='exercise-field duration-reps-field' labelTitle='Repeticiones' smallLabel>
          <InputNumber
            value={'repetitions' in exercise ? exercise.repetitions : undefined}
            onChange={(e) => onInputChange(index, { repetitions: e.value ?? undefined })}
            min={1}
            max={MAX_REPETITIONS}
            maxLength={1}
            useGrouping={false}
            maxFractionDigits={0}
          />
        </FormField>
      )}

      {exercise.exerciseType === EXERCISE_TYPE.TIME && exercise.exerciseId && (
        <FormField className='exercise-field duration-reps-field' labelTitle='Duración' smallLabel>
          <div className='p-inputgroup'>
            <Calendar
              value={getDurationDate('duration' in exercise ? exercise.duration : undefined)}
              onChange={handleDurationChange}
              timeOnly
              showSeconds
              hourFormat='24'
              showTime
              panelClassName='minute-seconds-only'
              placeholder='__:__'
              formatDateTime={(value) => (value ? format(value, 'mm:ss') : '')}
              mask='99:99'
              viewDate={new Date(0, 0, 0, 0, 0, 0)}
              stepSecond={5}
              touchUI={Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches}
            />
            <span className='p-inputgroup-addon'>min</span>
          </div>
        </FormField>
      )}

      {exercise.exerciseId && (
        <FormField className='exercise-field' labelTitle='Series' smallLabel>
          <InputNumber
            value={exercise.sets}
            onChange={(e) => onInputChange(index, { sets: e.value ?? undefined })}
            min={1}
            max={MAX_SETS}
            maxLength={1}
            useGrouping={false}
            maxFractionDigits={0}
          />
        </FormField>
      )}

      {exercise.exerciseId && (
        <FormField className='exercise-field rir-field' labelTitle='rir' smallLabel>
          <InputNumber value={exercise.rir} onChange={(e) => onInputChange(index, { rir: e.value ?? undefined })} />
        </FormField>
      )}

      <Button
        className='p-button-text p-button-danger'
        text
        icon={PrimeIcons.TRASH}
        onClick={(e) => {
          e.preventDefault();
          onDelete(index);
        }}
      />
    </div>
  );
}
