import React, { useMemo } from 'react';
import { User } from '../../types/user';
import { useOrganizationContext } from '../../context/OrganizationContext';
import useSWR from 'swr';
import { useClient } from '../../hooks/useClient';
import './CompactEmployeeSelect.scss';
import { AdaptiveSelect } from '../AdaptiveSelect/AdaptiveSelect';

interface Props {
  value: User[] | User | null;
  onChange: (employee: User[] | User | null) => void;
  multiple?: boolean;
  className?: string;
  appendTo?: HTMLElement | 'self' | (() => HTMLElement) | null;
  isFilter?: boolean;
}

interface EmployeeOption extends Partial<User> {
  label: string;
  value: string;
}

export const CompactEmployeeSelect = ({
  value,
  onChange,
  multiple = false,
  className,
  appendTo = 'self',
  isFilter = false,
}: Props) => {
  const { get } = useClient();
  const { organization } = useOrganizationContext() ?? {};

  const { data: employees, isLoading: employeesLoading } = useSWR(
    organization ? `/organizations/${organization.id}/users` : null,
    get<User[]>,
  );

  const employeeOptions = useMemo(() => {
    return (
      employees?.map((employee) => ({
        ...employee,
        label: `${employee.name} ${employee.surnames}`,
        value: employee.id,
      })) || []
    );
  }, [employees]);

  const itemTemplate = (option: EmployeeOption | null) => {
    if (!option) return null;

    return (
      <div className='compact-employee-item'>
        <div className='compact-employee-avatar'>
          {option.avatarUrl ? <img src={option.avatarUrl} alt={option.label} /> : <span>{option.label.charAt(0)}</span>}
        </div>
        <span className='compact-employee-name'>{option.label}</span>
      </div>
    );
  };

  const selectedItemTemplate = (option: EmployeeOption | null) => {
    if (!option) return null;

    if (isFilter) {
      return null;
    }

    return (
      <div className='compact-employee-item'>
        <div className='compact-employee-avatar'>
          {option.avatarUrl ? <img src={option.avatarUrl} alt={option.label} /> : <span>{option.label.charAt(0)}</span>}
        </div>
        <span className='compact-employee-name'>{option.label}</span>
      </div>
    );
  };

  const handleChange = (selectedValue: string | string[] | null) => {
    if (selectedValue === null) {
      onChange(null);
      return;
    }

    if (Array.isArray(selectedValue)) {
      const selectedEmployees = selectedValue
        .map((id) => employees?.find((emp) => emp.id === id) ?? null)
        .filter(Boolean) as User[];

      onChange(selectedEmployees);
    } else {
      const selectedEmployee = employees?.find((emp) => emp.id === selectedValue) ?? null;
      onChange(selectedEmployee);
    }
  };

  const convertedValue = useMemo(() => {
    if (value === null) return null;

    if (Array.isArray(value)) {
      return value.map((emp) => emp.id);
    }

    return value.id;
  }, [value]);

  return (
    <AdaptiveSelect
      value={convertedValue}
      onChange={handleChange}
      options={employeeOptions}
      className={className}
      itemTemplate={itemTemplate}
      selectedItemTemplate={selectedItemTemplate}
      loading={employeesLoading}
      isMultiple={multiple}
      emptyFilterMessage='No se encontraron empleados'
      placeholder='Buscar empleado...'
      modalTitle='Seleccionar empleado'
      searchPlaceholder='Buscar empleado'
      isFilter={isFilter}
    />
  );
};
