import React from 'react';
import './BusinessHoursSection.scss';
import TimeRange from '../TimeRange/TimeRange';
import { ReservationHours, TimeSlot } from '../../types/reservationType';

// When we add loc we can get rid of this and use the days as translation keys
export const weekdayDisplayNames = {
  monday: 'lunes',
  tuesday: 'martes',
  wednesday: 'miércoles',
  thursday: 'jueves',
  friday: 'viernes',
  saturday: 'sábado',
  sunday: 'domingo',
};

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const DEFAULT_START_TIME = '00:00';
const DEFAULT_END_TIME = '01:00';

interface Props {
  title: string;
  value: ReservationHours | undefined;
  onChange: (value: ReservationHours | undefined) => void;
  isEditable: boolean;
}

const BusinessHoursSection = ({ title, value, onChange, isEditable = true }: Props) => {
  const { restTime, ...weekdays } = value ?? {};

  const handleTimeRangeChange = (keyName: keyof ReservationHours) => (newValue: Partial<TimeSlot> | undefined) => {
    if (!newValue) {
      const valueCopy = { ...value };
      delete valueCopy[keyName];
      onChange(valueCopy);
    } else {
      const finalValue = !value?.[keyName]
        ? {
            start: DEFAULT_START_TIME,
            end: DEFAULT_END_TIME,
            ...newValue,
          }
        : newValue;

      onChange({
        ...value,
        [keyName]: finalValue,
      });
    }
  };

  return (
    <>
      <h5 className='reservation-hour-picker-title'>{title}</h5>
      {days.map((day) => (
        <TimeRange
          key={day}
          toggleLabel={weekdayDisplayNames[day as keyof typeof weekdayDisplayNames] ?? ''}
          value={weekdays[day as keyof typeof weekdays]}
          onChange={handleTimeRangeChange(day as keyof ReservationHours)}
          isEditable={isEditable}
        />
      ))}

      <label>Horas de descanso</label>
      <TimeRange value={restTime} onChange={handleTimeRangeChange('restTime')} isEditable={isEditable} />
    </>
  );
};

export default BusinessHoursSection;
