import { LOCALES } from '../../../config/locales';
import { LocalePost } from '../../../types/post';

export const EMPTY_POST: LocalePost = {
  content: '',
  description: '',
  title: '',
  meta: { description: '', title: '', url: '' },
  image: {
    alt: '',
    dashboardUrl: '',
    url: '',
  },
  readTime: 0,
} as LocalePost;
