import { ChartData, ChartOptions } from 'chart.js';
import { DimensionWithMetric, UsersOverTime } from '../types/analytics';

const CHART_COLORS = [
  // '#6366f1',
  // '#a5a7fc',
  // '#4649e5',
  // '#e0e1ff',
  // '#8184f8',
  // '#383bca',
  // '#c7c8fe',
  // '#3032a3',
  // '#2e3081',
  // '#1b1c4b',

  // '#4649e5',
  // '#6366f1',
  // '#8184f8',
  // '#a5a7fc',
  // '#c7c8fe',
  // '#e0e1ff',
  // '#eeeeff',

  '#6366f1',
  '#7073e9',
  '#8c90df',
  '#a5a8d5',
  '#c1c4e3',
  '#d8daf2',
  '#f0f1fa',

  // '#6366f1',
  // '#7073e9',
  // '#8c90e0',
  // '#adb0dc',
  // '#c7c8eb',
  // '#e1e2f4',
  // '#f0f1fb',
];

const getPieColors = () => {
  const documentStyle = getComputedStyle(document.documentElement);

  return [
    documentStyle.getPropertyValue('--primary-400'),
    documentStyle.getPropertyValue('--green-300'),
    documentStyle.getPropertyValue('--yellow-300'),
    documentStyle.getPropertyValue('--blue-300'),
    documentStyle.getPropertyValue('--orange-300'),
    documentStyle.getPropertyValue('--cyan-300'),
    documentStyle.getPropertyValue('--red-300'),
  ];
};

const getColors = () => {
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue('--text-color');
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
  const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

  return { textColor, textColorSecondary, surfaceBorder };
};

export const lineAggregatedData = (data: UsersOverTime[]) => {
  const { surfaceBorder, textColor, textColorSecondary } = getColors();

  // TODO refactorizar y hacer en un solo loop
  const chartData: ChartData = {
    labels: data.map((item) => item.date),
    datasets: [
      {
        label: '',
        data: data.map((item) => item.activeUsers),
        borderColor: CHART_COLORS[0],
        pointBorderColor: CHART_COLORS[0],
        pointBackgroundColor: CHART_COLORS[0],
        backgroundColor: 'transparent',
        pointStyle: false,
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options: ChartOptions = {
    interaction: {
      intersect: false, // Tooltip appears even when not directly over a point
      mode: 'index',
    },
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false,
        // labels: {
        //   color: textColor,
        // },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
          autoSkip: true,
        },
        grid: {
          color: (ctx) => {
            const index = ctx.tick.value;
            return index % 3 === 0 ? surfaceBorder : 'transparent'; // Show grid line for every 3 ticks
          },
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: (ctx) => {
            const index = ctx.tick.value;
            return index % 3 === 0 ? surfaceBorder : 'transparent'; // Show grid line for every 3 ticks
          },
        },
      },
    },
  };

  return { chartData, chartOptions: options };
};

export const aggregatedData = (data: DimensionWithMetric[]) => {
  const { surfaceBorder, textColor, textColorSecondary } = getColors();

  // TODO refactorizar y hacer en un solo loop
  const colors = data?.map((_: any, index) => CHART_COLORS[index] ?? CHART_COLORS[0]) || [];
  const chartData = {
    labels: data?.map((item: any) => `${item.dimension} (${item.metric})`) || [],
    datasets: [
      {
        label: '',
        data: data?.map((item: any) => item.metric) || [],
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions: ChartOptions = {
    interaction: {
      intersect: false, // Tooltip appears even when not directly over a point
      mode: 'index',
    },
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false,
        // labels: {
        //   fontColor: textColor,
        // },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
          font: {
            weight: 500,
          },
          callback: function (value) {
            const label = this.getLabelForValue(Number(value));
            return label?.length > 16 ? label.substring(0, 16) + '...' : label;
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
    },
  };

  return { chartData, chartOptions };
};

export const pieAggregatedData = (data: DimensionWithMetric[]) => {
  const { surfaceBorder, textColor, textColorSecondary } = getColors();

  const pieColors = getPieColors();

  // TODO refactorizar y hacer en un solo loop
  const colors = data?.map((_: any, index) => pieColors[index] ?? pieColors[0]) || [];
  const chartData = {
    labels: data?.map((item: any) => `${item.dimension} (${item.metric})`) || [],
    datasets: [
      {
        label: '',
        data: data?.map((item: any) => item.metric) || [],
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions: ChartOptions = {
    interaction: {
      intersect: false, // Tooltip appears even when not directly over a point
      mode: 'nearest',
    },
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: textColor,
        },
      },
    },
  };

  return { chartData, chartOptions };
};
