import React, { useRef } from 'react';
import { processFileToDataUrl, uploadNativeImage } from '../../utils/imageUtils';
import { Avatar } from 'primereact/avatar';
import { Skeleton } from 'primereact/skeleton';
import { Capacitor } from '@capacitor/core';
import { getUserAvatarProps } from '../../utils/avatarUtils';
import { User } from '../../types/user';

interface Props {
  fileName: string;
  onUpload: (fileUrl: string, file: File) => void;
  user?: Partial<User>;
  loading?: boolean;
}

export const AvatarUpload = ({ loading, onUpload, fileName, user }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const processUpload = async (file: File) => {
    processFileToDataUrl(file, (result) => {
      const newImage = { ...file, preview: result };
      onUpload?.(newImage.preview, file);
    });
  };

  const handleUpload = async (e: any) => {
    const file = e.target.files[0];
    if (!file) return;
    await processUpload(file);
  };

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (Capacitor.isNativePlatform()) {
      const file = await uploadNativeImage(fileName ?? '');
      if (file) await processUpload(file);
    } else if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {loading ? (
        <Skeleton height='100px' width='100px' shape='circle' className='avatar-img' />
      ) : (
        <Avatar onClick={handleClick} {...getUserAvatarProps(user)} shape='circle' className='big-avatar' />
      )}
      <input id='avatar' ref={fileInputRef} type='file' accept='image/*' style={{ display: 'none' }} onChange={handleUpload} />
    </div>
  );
};
