import React from 'react';
import { EventProps } from 'react-big-calendar';
import { USER_ROUTINE_EVENTS } from '../../types/externalUser';
import { PrimeIcons } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { format, subDays } from 'date-fns';
import { es } from 'date-fns/locale';
import './RoutineCalendarEvent.scss';
import { Capacitor } from '@capacitor/core';

interface RoutineEvent {
  id: string;
  title: string;
  eventType: USER_ROUTINE_EVENTS;
  routineId: string;
  fieldsUpdated?: string[];
  start: Date;
  end: Date;
}

const getRoutineEventIcon = (eventType: USER_ROUTINE_EVENTS) => {
  if (eventType === USER_ROUTINE_EVENTS.NEXT_ROUTINE) return PrimeIcons.CALENDAR_PLUS;
  if (eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE) return PrimeIcons.CALENDAR;
  if (eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE_NOT_STARTED) return PrimeIcons.CALENDAR_PLUS;
  if (eventType === USER_ROUTINE_EVENTS.PAST_ROUTINE) return PrimeIcons.CALENDAR_MINUS;
  if (eventType === USER_ROUTINE_EVENTS.EXPIRED_ROUTINE) return PrimeIcons.CALENDAR_MINUS;
  if (eventType === USER_ROUTINE_EVENTS.UPDATE) return PrimeIcons.COG;
  return '';
};

const getEventTypeLabel = (eventType: USER_ROUTINE_EVENTS) => {
  switch (eventType) {
    case USER_ROUTINE_EVENTS.CURRENT_ROUTINE:
      return 'Rutina actual';
    case USER_ROUTINE_EVENTS.NEXT_ROUTINE:
      return 'Próxima rutina';
    case USER_ROUTINE_EVENTS.PAST_ROUTINE:
      return 'Rutina pasada';
    case USER_ROUTINE_EVENTS.EXPIRED_ROUTINE:
      return 'Rutina caducada';
    case USER_ROUTINE_EVENTS.CURRENT_ROUTINE_NOT_STARTED:
      return 'Nueva rutina';
    case USER_ROUTINE_EVENTS.UPDATE:
      return 'Actualización';
    default:
      return 'Rutina';
  }
};

export const RoutineCalendarEvent = ({ event }: EventProps<RoutineEvent>) => {
  const isTouchDevice = Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches;
  const getEventColor = (eventType: USER_ROUTINE_EVENTS) => {
    switch (eventType) {
      case USER_ROUTINE_EVENTS.CURRENT_ROUTINE:
        return 'green';
      case USER_ROUTINE_EVENTS.NEXT_ROUTINE:
        return 'blue';
      case USER_ROUTINE_EVENTS.PAST_ROUTINE:
        return 'teal';
      case USER_ROUTINE_EVENTS.EXPIRED_ROUTINE:
        return 'pink';
      case USER_ROUTINE_EVENTS.UPDATE:
        return 'yellow';
      default:
        return 'primary';
    }
  };

  const color = getEventColor(event.eventType);
  const tooltipId = `routine-${event.routineId}-${event.start.getTime()}`;

  const getUpdatesDescription = (fieldsUpdated?: string[]) => {
    if (!fieldsUpdated?.length) return '';
    const updates = [];
    if (fieldsUpdated.includes('exercises')) updates.push('ejercicios');
    if (fieldsUpdated.includes('duration')) updates.push('duración');
    return updates.length > 0 ? `Se actualizaron: ${updates.join(' y ')}` : '';
  };

  return (
    <>
      <div id={tooltipId} className='routine-calendar-event' style={{ borderColor: `var(--${color}-500)` }}>
        <div className='event-type' style={{ backgroundColor: `var(--${color}-500)` }}>
          <i className={getRoutineEventIcon(event.eventType)} />
        </div>
        <div className='event-content'>
          <span>{event.title}</span>
        </div>
      </div>
      {!isTouchDevice && (
        <Tooltip target={`#${tooltipId}`} position='right' showDelay={50} hideDelay={0} className='calendar-event-tooltip'>
          <div className='event-tooltip'>
            <strong>{getEventTypeLabel(event.eventType)}</strong>
            <div className='event-details'>
              <div>
                <i className='pi pi-calendar' />
                <span>
                  <b>Desde: </b>
                  {format(event.start, "EEEE, d 'de' MMMM", { locale: es })}
                </span>
              </div>
              <div>
                <i className={PrimeIcons.CALENDAR_TIMES} />
                <span>
                  <b>Hasta: </b>
                  {format(subDays(event.end, 1), "EEEE, d 'de' MMMM", { locale: es })}
                </span>
              </div>
              {event.eventType === USER_ROUTINE_EVENTS.UPDATE && (
                <div>
                  <i className='pi pi-cog' />
                  <span>{getUpdatesDescription(event.fieldsUpdated)}</span>
                </div>
              )}
            </div>
          </div>
        </Tooltip>
      )}
    </>
  );
};
