import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { Organization } from '../../types/organization';
import { validateEmail, validateLettersSpacesAndCharacters, validatePhone } from '../../utils/validationUtils';

const { NOT_BRANCH, NOT_NAME, NOT_SURNAMES, INVALID_PHONE } = VALIDATION_MESSAGES;

const NOT_SUPERVISOR = 'Debes seleccionar un supervisor';

export interface ExternalUserForm {
  branchId?: string;
  name?: string;
  surnames?: string;
  supervisorId?: string;
  sendInvitation?: boolean;
  email?: string;
  gender?: string;
  dateOfBirth?: string;
  avatarUrl?: string;
  phone?: string;
  nationalId?: string;
  address?: string;
}

export const getExternalUserFormValidators = (organization: Organization | undefined): FormValidators<ExternalUserForm> => {
  return {
    branchId: (form) => {
      if (!form.branchId) return NOT_BRANCH;
    },
    name: (form) => {
      if (!form.name) return NOT_NAME;
      if (!validateLettersSpacesAndCharacters(form.name)) return NOT_NAME;
    },
    surnames: (form) => {
      if (!form.surnames) return NOT_SURNAMES;
      if (!validateLettersSpacesAndCharacters(form.surnames)) return NOT_SURNAMES;
    },
    email: (form) => {
      if (form.sendInvitation && !form.email) return 'El correo es obligatorio cuando se envía invitación';
      if (form.sendInvitation && form.email && !validateEmail(form.email)) return 'Introduce un correo electrónico válido';
    },
    supervisorId: (form) => {
      if (organization?.config?.supervisorRequired && !form.supervisorId) return NOT_SUPERVISOR;
    },
    phone: (form) => {
      if (form.phone && !validatePhone(form.phone)) return INVALID_PHONE;
    },
  };
};

export const getInitialExternalUserForm = (initialData?: Partial<ExternalUserForm>): ExternalUserForm => ({
  branchId: initialData?.branchId ?? '',
  name: initialData?.name ?? '',
  surnames: initialData?.surnames ?? '',
  email: initialData?.email ?? '',
  supervisorId: initialData?.supervisorId ?? '',
  sendInvitation: initialData?.sendInvitation ?? true,
});
