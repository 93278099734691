import React, { useState } from 'react';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';

import './Exercises.scss';

import { Button } from 'primereact/button';
import { ResponsiveTable } from '../../components/ResponsiveTable/ResponsiveTable';
import { TableColumn, TableAction } from '../../types/responsiveTable';
import { Exercise } from '../../types/Fisiofit/exercise';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import useSWR from 'swr';
import { PrimeIcons } from 'primereact/api';
import { ExercisePanel } from '../../components/ExercisePanel/ExercisePanel';
import { useResourcePanel } from '../../hooks/useResourcePanel';

const tableHeaders: TableColumn<Exercise>[] = [
  {
    label: 'Nombre',
    accessor: 'name',
    type: 'string',
    isTitle: true,
  },
  {
    label: 'Usos en rutina',
    accessor: 'usesCount',
    type: 'number',
  },
  {
    label: 'Músculos',
    accessor: 'muscleGroups',
    type: 'dropdown',
  },
  {
    label: 'recursos',
    accessor: 'imageUrl',
    type: 'dropdown',
    valueFromCallBack: (imageUrl, row) => {
      const resources = [];
      if (imageUrl) resources.push('Imagen');
      if (row.videoUrl) resources.push('Vídeo');
      if (row.description) resources.push('Descripción');
      return resources;
    },
  },
];

export const Exercises = () => {
  const { get } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};

  const { data, isLoading, error } = useSWR(
    organization ? `/organizations/${organization.id}/exercises` : null,
    get<Exercise[]>
  );

  const options: TableAction[] = [
    {
      label: 'Ver',
      onClick: (id) => {
        openPanel(false, id);
      },
    },
    {
      label: 'Editar',
      onClick: (id) => {
        openPanel(true, id);
      },
    },
    {
      label: 'Eliminar',
    },
  ];

  const {
    isEditing,
    setIsEditing,
    panelVisible,
    setPanelVisible,
    selectedResource,
    openPanel,
  } = useResourcePanel(data);

  return (
    <PageTemplate className='employees-page' title='Ejercicios'>
      <ResponsiveTable
        data={data || []}
        columns={tableHeaders}
        options={options}
        loading={organizationLoading || isLoading}
        headerOptions={
          <Button
            label='Añadir ejercicio'
            outlined
            rounded
            icon={PrimeIcons.PLUS}
            onClick={() => {
              openPanel(false);
            }}
          />
        }
        onRowClick={(row) => {
          openPanel(false, row.id);
        }}
      ></ResponsiveTable>
      <ExercisePanel
        exercise={selectedResource}
        visible={panelVisible}
        onHide={() => {
          setPanelVisible(false);
        }}
        onIsEditingChange={(value) => setIsEditing(value)}
        isEditing={isEditing}
      ></ExercisePanel>
    </PageTemplate>
  );
};
