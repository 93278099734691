import { Dispatch, SetStateAction, useState } from 'react';

export interface UseResourcePanelValue<T extends Record<string, any>> {
  selectedResource: T | undefined;
  setSelectedResource: Dispatch<SetStateAction<T | undefined>>;
  panelVisible: boolean;
  setPanelVisible: Dispatch<SetStateAction<boolean>>;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  openPanel: (isEditing: boolean, id?: string) => void;
}

export const useResourcePanel = <T extends Record<string, any>>(data?: T[]) => {
  // TODO unify into one single state but maintain the same api for consumers
  const [selectedResource, setSelectedResource] = useState<T>();
  const [panelVisible, setPanelVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const openPanel = (isEditing: boolean, id?: string) => {
    setPanelVisible(true);
    setIsEditing(isEditing);
    setSelectedResource(id ? data?.find((user) => user.id === id) : undefined);
  };

  return {
    selectedResource,
    setSelectedResource,
    panelVisible,
    setPanelVisible,
    isEditing,
    setIsEditing,
    openPanel,
  };
};
