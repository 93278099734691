import { Reservation } from '../types/reservation';
import { addWeeks, parseISO } from 'date-fns';

export const isPastReservation = (reservation: Reservation): boolean => {
  const endDate = parseISO(reservation.localStartDate);
  return endDate <= new Date();
};

export const getFirstAvailableDateToClone = (reservation: Reservation) => {
  const isReservationInPast = isPastReservation(reservation);
  let found: boolean = false;
  let j = 0;
  if (!isReservationInPast) {
    return addWeeks(reservation.localStartDate, 1);
  }
  while (isReservationInPast && !found) {
    const nextLocalStartDate = addWeeks(reservation.localStartDate, j + 1);

    if (nextLocalStartDate > new Date()) {
      found = true;
      return nextLocalStartDate;
    }
    j++;
  }
};
