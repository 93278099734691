interface Resource {
  id: string;
}

export const resourceMutator =
  <T extends Resource>(resourceToUpdate: T) =>
  (resources: T[] | undefined) => {
    if (!resources) return;
    const resourceIndex = resources.findIndex((resource) => resource.id === resourceToUpdate.id);

    if (resourceIndex === -1) {
      // Resource not found, add it to the array
      return [...resources, resourceToUpdate];
    }

    // Resource found, update it
    return resources.map((resource) =>
      resource.id === resourceToUpdate.id ? { ...resource, ...resourceToUpdate } : resource
    );
  };

export const resourceDeleter =
  <T extends Resource>(idToDelete: string) =>
  (resources: T[] | undefined) => {
    if (!resources) return;
    return resources.filter((resource) => resource.id !== idToDelete);
  };
