const css = `.time-range {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}
.time-range:not(.time-range:last-of-type) {
  margin-bottom: 8px;
}
.time-range .time-range-title {
  margin: 0;
  color: var(--text-color);
  font-weight: 500;
}
.time-range .time-range-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.time-range .switch-text {
  margin-left: auto;
}
.time-range .switch {
  align-self: center;
  text-transform: capitalize;
  width: 140px;
  min-width: 140px;
}
.time-range .form-field {
  margin: 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvVGltZVJhbmdlIiwic291cmNlcyI6WyJUaW1lUmFuZ2Uuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi50aW1lLXJhbmdlIHtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgZ2FwOiA4cHg7XG4gIG1hcmdpbi1ib3R0b206IDE2cHg7XG5cbiAgJjpub3QoJjpsYXN0LW9mLXR5cGUpIHtcbiAgICBtYXJnaW4tYm90dG9tOiA4cHg7XG4gIH1cblxuICAudGltZS1yYW5nZS10aXRsZSB7XG4gICAgbWFyZ2luOiAwO1xuICAgIGNvbG9yOiB2YXIoLS10ZXh0LWNvbG9yKTtcbiAgICBmb250LXdlaWdodDogNTAwO1xuICB9XG5cbiAgLnRpbWUtcmFuZ2UtY29udGVudCB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgZ2FwOiAxNnB4O1xuICB9XG5cbiAgLnN3aXRjaC10ZXh0IHtcbiAgICBtYXJnaW4tbGVmdDogYXV0bztcbiAgfVxuXG4gIC5zd2l0Y2gge1xuICAgIGFsaWduLXNlbGY6IGNlbnRlcjtcbiAgICB0ZXh0LXRyYW5zZm9ybTogY2FwaXRhbGl6ZTtcbiAgICB3aWR0aDogMTQwcHg7XG4gICAgbWluLXdpZHRoOiAxNDBweDtcbiAgfVxuXG4gIC5mb3JtLWZpZWxkIHtcbiAgICBtYXJnaW46IDA7XG4gIH1cbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
