import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import useSWR from 'swr';
import { ClientRequest, useClient } from '../../hooks/useClient';
import { Routine } from '../../types/Fisiofit/routine';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { PrimeIcons } from 'primereact/api';
import { ItemDisplay } from '../../types/responses';
import { usePopups } from '../../context/PopupContext';
import { ToastMessage } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Capacitor } from '@capacitor/core';
import { useDropdownPosition } from '../../hooks/useDropdownPosition';
import './CopyRoutineButton.scss';

interface RoutineListItem extends ItemDisplay {
  externalUserId?: string;
}

interface RoutineDropdownOption {
  value: string;
  label: string;
  externalUserId?: string;
}

const fetchRoutine = async (
  routineDisplay: ItemDisplay,
  get: ClientRequest,
  showToast: ((message: ToastMessage | ToastMessage[]) => void) | undefined,
) => {
  const routine = await get<Routine>(`/routines/${routineDisplay.id}`);

  if (!routine) {
    showToast?.({
      severity: 'error',
      summary: 'Error copiando ejercicios',
      detail: `No se pudieron copiar los ejercicios de la rutina "${routineDisplay.name}"`,
    });
  }

  return routine;
};

const getMenuOptions = (routines: RoutineListItem[] | undefined): RoutineDropdownOption[] => {
  if (!routines) return [];
  return routines.map((routine) => ({
    value: routine.id,
    label: routine.name,
    externalUserId: routine.externalUserId ?? undefined,
  }));
};

interface Props {
  onSelectRoutine: (routine: Routine) => void;
  showConfirmation?: boolean;
}

export const CopyRoutineButton = ({ onSelectRoutine, showConfirmation = false }: Props) => {
  const { showToast } = usePopups() ?? {};
  const { organization } = useOrganizationContext() ?? {};
  const { get } = useClient();
  const [isCopying, setIsCopying] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const isTouchDevice = Capacitor.isNativePlatform() || window.matchMedia('(hover: none)').matches;
  const buttonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownPosition = useDropdownPosition(buttonRef, dropdownVisible);

  const { data: routines, isLoading } = useSWR(`/organizations/${organization?.id}/routines?short=true`, get<RoutineListItem[]>);

  const routineOptions = useMemo(() => getMenuOptions(routines), [routines]);

  const filteredOptions = useMemo(() => {
    return routineOptions?.filter((option) => {
      if (!searchValue) return true;
      return option.label?.toLowerCase().includes(searchValue.toLowerCase());
    });
  }, [routineOptions, searchValue]);

  // Cerrar el dropdown cuando se hace clic fuera
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownVisible &&
        !isTouchDevice &&
        buttonRef.current &&
        dropdownRef.current &&
        !buttonRef.current.contains(event.target as Node) &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownVisible, isTouchDevice]);

  const selectRoutine = useCallback(
    async (routine: ItemDisplay) => {
      setIsCopying(true);
      const fullRoutine = await fetchRoutine(routine, get, showToast);
      if (fullRoutine) {
        const routineToPass = {
          ...fullRoutine,
          exerciseDays: fullRoutine.exerciseDays || [],
        };

        onSelectRoutine(routineToPass);
        if (routine) {
          showToast?.({
            severity: 'info',
            summary: 'Ejercicios copiados',
            detail: `Se han copiado los ejercicios de la rutina "${routine.name}"`,
          });
        }
      }
      setIsCopying(false);
      setSelectedValue(null);
      setIsModalVisible(false);
      setDropdownVisible(false);
    },
    [get, onSelectRoutine, showToast],
  );

  const handleChange = (value: string) => {
    const routine = routines?.find((r) => r.id === value);
    if (!routine) return;

    if (showConfirmation) {
      confirmDialog({
        message: '¿Deseas copiar los ejercicios de otra rutina? Esto sobreescribirá los ejercicios actuales.',
        header: 'Confirmar copia de ejercicios',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sí, copiar',
        rejectLabel: 'No, cancelar',
        accept: () => {
          selectRoutine(routine);
        },
        acceptClassName: 'p-button p-button-text',
        rejectClassName: 'p-button-secondary p-button-text',
        draggable: false,
        resizable: false,
      });
    } else {
      selectRoutine(routine);
    }
  };

  const routineOptionTemplate = (option: RoutineDropdownOption | null) => {
    if (!option) return null;

    return (
      <div className='routine-option-item'>
        <span className={`routine-type-dot ${option.externalUserId ? 'user' : 'template'}`} />
        <span>{option.label}</span>
      </div>
    );
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (isTouchDevice) {
      setIsModalVisible(true);
    } else {
      setDropdownVisible(!dropdownVisible);
    }
  };

  const handleOptionClick = (option: RoutineDropdownOption, e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    handleChange(option.value);
    setIsModalVisible(false);
    setDropdownVisible(false);
  };

  return (
    <div className='copy-routine-button'>
      <div ref={buttonRef} className='button-container'>
        <Button
          label='Copiar ejercicios'
          icon={PrimeIcons.COPY}
          text
          onClick={handleButtonClick}
          disabled={isLoading || isCopying}
          loading={isLoading || isCopying}
          className='copy-button'
        />
      </div>

      {isTouchDevice ? (
        <Dialog
          visible={isModalVisible}
          onHide={() => {
            setIsModalVisible(false);
            setSearchValue('');
          }}
          header='Seleccionar rutina para copiar'
          className='routine-select-modal'
          draggable={false}
          resizable={false}
        >
          <div className='routine-select-content'>
            <div className='routine-select-search'>
              <InputText
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder='Buscar rutina...'
                disabled={isLoading || isCopying}
                onClick={(e) => e.stopPropagation()}
              />
            </div>

            <div className='routine-options-list'>
              {filteredOptions && filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <div key={option.value} className='routine-option-wrapper' onClick={(e) => handleOptionClick(option, e)}>
                    {routineOptionTemplate(option)}
                  </div>
                ))
              ) : (
                <div className='empty-message'>{searchValue ? 'No se han encontrado rutinas' : 'No hay rutinas disponibles'}</div>
              )}
            </div>
          </div>
        </Dialog>
      ) : (
        dropdownVisible && (
          <div
            className='custom-dropdown-container'
            style={{
              top: dropdownPosition.top,
              left: dropdownPosition.left,
              right: dropdownPosition.right,
              bottom: dropdownPosition.bottom,
              marginBottom: dropdownPosition.marginBottom,
              marginTop: dropdownPosition.marginTop,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className='custom-dropdown' ref={dropdownRef}>
              <div className='dropdown-search'>
                <InputText
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder='Buscar rutina...'
                  disabled={isLoading || isCopying}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
              <div
                className='dropdown-options-list'
                style={{
                  maxHeight: '300px',
                }}
              >
                {filteredOptions && filteredOptions.length > 0 ? (
                  filteredOptions.map((option) => (
                    <div key={option.value} className='dropdown-option-wrapper' onClick={(e) => handleOptionClick(option, e)}>
                      {routineOptionTemplate(option)}
                    </div>
                  ))
                ) : (
                  <div className='empty-message'>
                    {searchValue ? 'No se han encontrado rutinas' : 'No hay rutinas disponibles'}
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};
