export enum COMMON_FEATURES {
  ANALYTICS = 'analytics',

  BLOG = 'blog',
  BLOG_WRITE = 'blog-write',

  EXTERNAL_USERS = 'external-users',
  EXTERNAL_USERS_WRITE = 'external-users-write',

  RESERVATIONS = 'reservations',
  SINGLE_RESERVATIONS = 'single-reservations',
  SINGLE_RESERVATIONS_WRITE = 'single-reservations-write',
  GROUP_RESERVATIONS = 'group-reservations',
  GROUP_RESERVATIONS_WRITE = 'group-reservations-write',

  PROMOTIONS = 'promotions',

  BUSINESS_CONFIG = 'business-config',
  BUSINESS_CONFIG_WRITE = 'business-config-write',

  USERS = 'users',
  USERS_WRITE = 'users-write',

  NOTIFICATIONS = 'notifications',
}

export enum FISIOFIT_FEATURES {
  ROUTINES = 'routines',
  ROUTINES_WRITE = 'routines-write',

  EXERCISES = 'exercises',
  EXERCISES_WRITE = 'exercises-write',
}

export const ALL_FEATURES = {
  ...COMMON_FEATURES,
  ...FISIOFIT_FEATURES,
} as const;

export type Feature = (typeof ALL_FEATURES)[keyof typeof ALL_FEATURES];
