const css = `.info-icon {
  cursor: help;
  font-size: 14px;
  color: var(--primary500);
  transition: color 0.2s;
}
.info-icon:hover {
  color: var(--disruptive);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvSW5mb0ljb24iLCJzb3VyY2VzIjpbIkluZm9JY29uLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7RUFFQTs7QUFDQTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLmluZm8taWNvbiB7XG4gIGN1cnNvcjogaGVscDtcbiAgZm9udC1zaXplOiAxNHB4O1xuICBjb2xvcjogdmFyKC0tcHJpbWFyeTUwMCk7XG5cbiAgdHJhbnNpdGlvbjogY29sb3IgMC4ycztcbiAgJjpob3ZlciB7XG4gICAgY29sb3I6IHZhcigtLWRpc3J1cHRpdmUpO1xuICB9XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
